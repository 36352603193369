import React, { useCallback } from 'react'
import './Notification.scss'
import Avatar from 'components/Avatar/Avatar'
import { DateTime } from 'luxon'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { POST_ATTACHMENT_TYPES } from 'store/constants/post'
import {
  CommentSvg,
  LiveSvg,
  PollSvg,
  ThumbUpSvg,
  VideoSvg,
  PersonSvg,
} from 'assets/svg'
import PencilSvg from 'assets/svg/PencilSvg'

const Notification = ({ notification, onClick }) => {
  const id = notification.get('id')
  const opened = notification.get('openedAt') != null
  const notifiablePath = notification.get('notifiablePath')

  const onNotificationClick = useCallback(() => {
    onClick(id, opened)
  }, [onClick, id, opened])

  const getIcon = () => {
    switch (notification.get('key')) {
      case 'event.created':
        if (notification.getIn(['notifiable', 'live'])) {
          return (
            <div className="notification-icon notification-icon-red">
              <LiveSvg />
            </div>
          )
        } else {
          return (
            <div className="notification-icon notification-icon-grey">
              <VideoSvg />
            </div>
          )
        }
      case 'like.created':
        return (
          <div className="notification-icon notification-icon-green">
            <ThumbUpSvg />
          </div>
        )
      case 'comment.created':
        if (notification.getIn(['notifiable', 'parentId']) != null) {
          return (
            <div className="notification-icon notification-icon-blue">
              <CommentSvg />
            </div>
          )
        }
        return (
          <div className="notification-icon notification-icon-blue">
            <CommentSvg />
          </div>
        )
      case 'post.created':
        return (
          <div className="notification-icon notification-icon-blue">
            <PencilSvg />
          </div>
        )
      case 'poll_vote.created':
        return (
          <div className="notification-icon notification-icon-blue">
            <PollSvg />
          </div>
        )
      case 'subscription.created':
        return (
          <div className="notification-icon notification-icon-green">
            <PersonSvg />
          </div>
        )
      default:
        return null
    }
  }

  const getReason = () => {
    switch (notification.get('key')) {
      case 'event.created':
        if (notification.getIn(['notifiable', 'live'])) {
          return (
            <>
              published <b>a new live event</b>
            </>
          )
        } else {
          return (
            <>
              uploaded <b>a new video</b>
            </>
          )
        }
      case 'like.created':
        return (
          <>
            liked{' '}
            <b>
              your{' '}
              {notification.getIn(['notifiable', 'likeableType']).toLowerCase()}
            </b>
          </>
        )
      case 'comment.created':
        if (notification.getIn(['notifiable', 'parentId']) != null) {
          return (
            <>
              replied to <b>your comment</b>
            </>
          )
        }
        if (
          notification.getIn(['notifiable', 'postUserId']) ===
          notification.getIn(['target', 'id'])
        ) {
          return (
            <>
              commented on <b>your post</b>
            </>
          )
        }
        return (
          <>
            commented on a post <b>you also commented on</b>
          </>
        )
      case 'post.created':
        switch (notification.getIn(['notifiable', 'attachmentType'])) {
          case POST_ATTACHMENT_TYPES.POLL:
            return (
              <>
                posted <b>a new poll</b>
              </>
            )
          case POST_ATTACHMENT_TYPES.PHOTO:
            return (
              <>
                posted <b>a new photo</b>
              </>
            )
          case POST_ATTACHMENT_TYPES.DOCUMENT:
            return (
              <>
                uploaded <b>a new file</b>
              </>
            )
          default:
            return (
              <>
                posted <b>a new post</b>
              </>
            )
        }
      case 'poll_vote.created':
        return (
          <>
            voted on <b>your poll</b>
          </>
        )
      case 'subscription.created':
        return (
          <>
            has joined{' '}
            <b>{notification.getIn(['notifiable', 'product', 'name'])}</b>
          </>
        )
      default:
        return 'Unknown notification'
    }
  }

  const renderInner = () => {
    const othersCount = notification.get('groupMemberNotifierCount')
    return (
      <>
        <Avatar
          url={notification.getIn(['notifier', 'profileImageUrl'])}
          width={32}
          height={32}
          className={'notification-user-avatar'}
          userId={notification.getIn(['notifier', 'id'])}
        />
        {getIcon()}
        <div className="notification-wrapper">
          <div className="notification-content">
            <span className="notification-user-name">
              {notification.getIn(['notifier', 'name'])}{' '}
              {othersCount > 0 && (
                <span className="notification-others">
                  and {othersCount} {othersCount > 1 ? 'others' : 'other'}{' '}
                </span>
              )}
            </span>

            <span className="notification-reason">{getReason()}</span>
          </div>
          <div className="notification-date">
            {DateTime.fromISO(notification.get('createdAt')).toRelative()}
          </div>
        </div>
        <div className={clsx('notification-unopened', { hidden: opened })} />
      </>
    )
  }

  if (notifiablePath === '') {
    return (
      <div className="Notification" onClick={onNotificationClick}>
        {renderInner()}
      </div>
    )
  } else {
    return (
      <Link
        className="Notification"
        to={notifiablePath}
        onClick={onNotificationClick}
      >
        {renderInner()}
      </Link>
    )
  }
}

export default Notification
