import React from 'react'
import './CreatorSettingsProfile.scss'
import { useMount } from 'react-use'
import { URL_FRONT_PAGE } from 'urls'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { isAuthenticated, getMe } from 'store/selectors'
import { Col, Row } from 'components/Grid'
import CreatorSetupForm from 'routes/CreatorSetupPage/CreatorSetupForm'
import * as Yup from 'yup'
import { getMeObject, updateUser, useGetUserApi } from 'api'
import { removeNullObjectValues, getUserUrl, getApiError } from 'utils'
import { updateMeStore } from 'store/utils/auth'
import Spinner from 'components/Spinner'
import { Formik } from 'formik'
import CreatorSettingsHeader from './CreatorSettingsHeader'
import { useToasts } from 'react-toast-notifications'

const CreatorSettingsProfileValidation = Yup.object().shape({
  name: Yup.string().required('Required'),
  username: Yup.string()
    .required('Required')
    .matches(
      /^[a-z0-9-]+$/,
      'Usernames must consist only of lower case letters, numbers and dashes.'
    ),
  title: Yup.string().required('Required'),
})

const CreatorSettingsProfile = () => {
  const history = useHistory()
  const { addToast } = useToasts()
  const hasAuth = useSelector((state) => isAuthenticated(state))
  const me = useSelector((state) => getMe(state))
  const { data: user } = useGetUserApi({
    userId: me.get('id'),
  })

  useMount(async () => {
    if (!hasAuth) {
      history.push(URL_FRONT_PAGE)
    }
  })

  const onSubmit = async (values, { setSubmitting, setErrors }) => {
    try {
      const {
        name,
        username,
        title,
        bio,
        homepageUrl,
        instagramUrl,
        twitterUrl,
        facebookUrl,
        youtubeUrl,
        linkedinUrl,
        profileImageId,
        coverImageId,
        coverVideoUploadId,
        currency,
        colorScheme,
      } = values
      const data = {
        name,
        username,
        title,
        bio,
        homepageUrl,
        instagramUrl,
        twitterUrl,
        facebookUrl,
        youtubeUrl,
        linkedinUrl,
        profileImageId,
        coverImageId,
        coverVideoUploadId,
        currency,
      }
      removeNullObjectValues(data)
      data['colorScheme'] = colorScheme // Can be null, skip the null-filter
      const user = await updateUser({ id: me.get('id'), data })

      try {
        const apiMe = await getMeObject()
        updateMeStore({ me: apiMe })
      } catch (e) {
        // Pass
      }

      setSubmitting(false)
      addToast('Changes have been saved', { appearance: 'success' })
      history.replace(getUserUrl(user))
    } catch (e) {
      console.log(e)
      setErrors(getApiError(e))
      setSubmitting(false)
    }
  }

  if (user == null) {
    return <Spinner light />
  }

  return (
    <div className="CreatorSettingsProfile">
      <Formik
        initialValues={{
          name: user.get('name'),
          username: user.get('username'),
          title: user.get('title'),
          bio: user.get('bio') ?? '',
          homepageUrl: user.get('homepageUrl'),
          instagramUrl: user.get('instagramUrl'),
          twitterUrl: user.get('twitterUrl'),
          facebookUrl: user.get('facebookUrl'),
          youtubeUrl: user.get('youtubeUrl'),
          linkedinUrl: user.get('linkedinUrl'),
          profileImageFile: null,
          profileImageId: null,
          profileImageUrl: user.get('profileImageUrl'),
          coverImageFile: null,
          coverImageId: null,
          coverImageUrl: user.get('coverImageUrl'),
          coverVideoUrl: user.get('coverVideoUrl'),
          currency: user.get('currency'),
          colorScheme: user.get('colorScheme'),
        }}
        onSubmit={onSubmit}
        validationSchema={CreatorSettingsProfileValidation}
      >
        {(props) => (
          <>
            <CreatorSettingsHeader description="These are the basic details your audience will see on your profile page." />
            <Row className="justify-content-md-center">
              <Col sm={12} lg={12}>
                <CreatorSetupForm {...props} edit />
              </Col>
            </Row>
          </>
        )}
      </Formik>
    </div>
  )
}

export default CreatorSettingsProfile
