import { fromJS } from 'immutable'
import { useGetApi, callApi } from './base'
import queryString from 'query-string'

/*
  Data getter hooks.

  NOTE:
  Data mappers are not anonymous functions,
  as that would change the signature of the function,
  thus causing a re-render loop in the useCallback function.
*/

const useGetNotificationsApiDataMapper = (data) => fromJS(data.notifications)
export const useGetNotificationsApi = ({ userId }, deps) =>
  useGetApi(
    {
      url: `users/${userId}/notifications`,
      dataMapper: useGetNotificationsApiDataMapper,
    },
    deps
  )

const useGetNotificationSubscriptionsApiDataMapper = (data) =>
  fromJS(data.subscriptions)
export const useGetNotificationSubscriptionsApi = ({ userId }, deps) =>
  useGetApi(
    {
      url: `users/${userId}/subscriptions`,
      dataMapper: useGetNotificationSubscriptionsApiDataMapper,
    },
    deps
  )

const useGetNotificationsCountApiDataMapper = (data) => data.notifications.count
export const useGetNotificationsCountApi = ({ userId }, deps) =>
  useGetApi(
    {
      url: `users/${userId}/notifications_count`,
      dataMapper: useGetNotificationsCountApiDataMapper,
    },
    deps
  )

const useGetLatestLiveEventsApiDataMapper = (data) => fromJS(data.events)
export const useGetLatestLiveEventsApi = ({ userId, params }, deps) =>
  useGetApi(
    {
      url: `users/${userId}/latest_live_events?${queryString.stringify(
        params
      )}`,
      dataMapper: useGetLatestLiveEventsApiDataMapper,
    },
    deps
  )

const useGetRecentEventsApiDataMapper = (data) => fromJS(data.events)
export const useGetRecentEventsApi = ({ userId, count = 2 }, deps) =>
  useGetApi(
    {
      url: `users/${userId}/recent_events?count=${count}`,
      dataMapper: useGetRecentEventsApiDataMapper,
    },
    deps
  )

const useGetNewestMembersApiDataMapper = (data) => fromJS(data.users)
export const useGetNewestMembersApi = ({ userId }, deps) =>
  useGetApi(
    {
      url: `users/${userId}/members/newest`,
      dataMapper: useGetNewestMembersApiDataMapper,
    },
    deps
  )

export const moveProductApi = ({ id, direction }) =>
  callApi({
    url: `products/${id}/move`,
    method: 'POST',
    data: {
      direction,
    },
  })

export const deleteProductApi = ({ id }) =>
  callApi({
    url: `products/${id}`,
    method: 'DELETE',
  })

const useGetProductsApiDataMapper = (data) => fromJS(data.products)
export const useGetProductsApi = (deps) =>
  useGetApi(
    {
      url: `products`,
      dataMapper: useGetProductsApiDataMapper,
    },
    deps
  )

const useGetLegacyProductsApiDataMapper = (data) => fromJS(data.products)
export const useGetLegacyProductsApi = (deps) =>
  useGetApi(
    {
      url: `products/legacy`,
      dataMapper: useGetLegacyProductsApiDataMapper,
    },
    deps
  )

const useGetUserProductsApiDataMapper = (data) => fromJS(data.products)
export const useGetUserProductsApi = ({ userId }, deps) =>
  useGetApi(
    {
      url: `users/${userId}/products`,
      dataMapper: useGetUserProductsApiDataMapper,
    },
    deps
  )

const useGetEventApiDataMapper = (data) => fromJS(data.event)
export const useGetEventApi = ({ userSlug, eventSlug, eventId }, deps) =>
  useGetApi(
    {
      url:
        userSlug == null && eventSlug == null
          ? `events/${eventId}`
          : `users/${userSlug}/events/${eventSlug}`,
      dataMapper: useGetEventApiDataMapper,
    },
    deps
  )

const useGetEventCheckoutApiDataMapper = (data) => fromJS(data)
export const useGetEventCheckoutApi = (
  { userSlug, eventSlug, eventId, data, disabled = false },
  deps
) =>
  useGetApi(
    {
      url:
        userSlug == null && eventSlug == null
          ? `events/${eventId}/checkout`
          : `users/${userSlug}/events/${eventSlug}/checkout`,
      method: 'POST',
      disabled,
      data,
      dataMapper: useGetEventCheckoutApiDataMapper,
    },
    deps
  )

const useGetEventParticipantsApiDataMapper = (data) => fromJS(data.participants)
export const useGetEventParticipantsApi = ({ eventId }, deps) =>
  useGetApi(
    {
      url: `events/${eventId}/participants`,
      dataMapper: useGetEventParticipantsApiDataMapper,
    },
    deps
  )

const useGetEventEngagementsApiDataMapper = (data) => fromJS(data)
export const useGetEventEngagementsApi = ({ eventId }, deps) =>
  useGetApi(
    {
      url: `events/${eventId}/engagement`,
      dataMapper: useGetEventEngagementsApiDataMapper,
    },
    deps
  )

const useGetUserApiDataMapper = (data) => fromJS(data.user)
export const useGetUserApi = ({ userSlug, userId }, deps) =>
  useGetApi(
    {
      url: `users/${userId || userSlug}`,
      dataMapper: useGetUserApiDataMapper,
    },
    deps
  )

const useGetUserCurrentPollPostsApiDataMapper = (data) => fromJS(data.posts)
export const useGetUserCurrentPollPostsApi = (
  { userSlug, userId, count = 6 },
  deps
) =>
  useGetApi(
    {
      url: `users/${userId || userSlug}/current_poll_posts?count=${count}`,
      dataMapper: useGetUserCurrentPollPostsApiDataMapper,
    },
    deps
  )

const useGetUserSetupIntentApiDataMapper = (data) => fromJS(data.setupIntent)
export const useGetUserSetupIntentApi = ({ userSlug, userId }, deps) =>
  useGetApi(
    {
      url: `users/${userId || userSlug}/setup_intent`,
      dataMapper: useGetUserSetupIntentApiDataMapper,
    },
    deps
  )

const useGetPaymentMethodsApiDataMapper = (data) => fromJS(data)
export const useGetPaymentMethodsApi = (deps) =>
  useGetApi(
    {
      url: `users/me/payment_methods`,
      dataMapper: useGetPaymentMethodsApiDataMapper,
    },
    deps
  )

const useGetPlansApiDataMapper = (data) => fromJS(data)
export const useGetPlansApi = ({ disabled = false }, deps) =>
  useGetApi(
    {
      url: `plans`,
      dataMapper: useGetPlansApiDataMapper,
      disabled,
    },
    deps
  )

const useGetPlanApiDataMapper = (data) => fromJS(data)
export const useGetPlanApi = ({ planId, disabled = false }, deps) =>
  useGetApi(
    {
      url: `plans/${planId}`,
      dataMapper: useGetPlanApiDataMapper,
      disabled,
    },
    deps
  )

const useGetPlanCheckoutApiDataMapper = (data) => fromJS(data)
export const useGetPlanCheckoutApi = (
  { planId, data, disabled = false },
  deps
) =>
  useGetApi(
    {
      url: `plans/${planId}/checkout`,
      method: 'POST',
      dataMapper: useGetPlanCheckoutApiDataMapper,
      disabled,
      data,
    },
    deps
  )

const useGetSubscriptionApiDataMapper = (data) => fromJS(data.subscription)
export const useGetSubscriptionApi = ({ subscriptionId }, deps) =>
  useGetApi(
    {
      url: `subscriptions/${subscriptionId}`,
      dataMapper: useGetSubscriptionApiDataMapper,
    },
    deps
  )

const useGetSubscriptionsApiDataMapper = (data) => fromJS(data.subscriptions)
export const useGetSubscriptionsApi = ({ disabled = false }, deps) =>
  useGetApi(
    {
      url: `subscriptions`,
      dataMapper: useGetSubscriptionsApiDataMapper,
      disabled,
    },
    deps
  )

const useGetSubscriptionPreviewApiDataMapper = (data) => fromJS(data)
export const useGetSubscriptionPreviewApi = (
  { planId, disabled = false },
  deps
) =>
  useGetApi(
    {
      url: `plans/${planId}/prorate`,
      dataMapper: useGetSubscriptionPreviewApiDataMapper,
      disabled,
    },
    deps
  )

const useGetUserEventsApiDataMapper = (data) => fromJS(data.events)
export const useGetUserEventsApi = ({ userSlug, disabled = false }, deps) =>
  useGetApi(
    {
      url: `users/${userSlug}/events`,
      dataMapper: useGetUserEventsApiDataMapper,
      disabled,
    },
    deps
  )

const useGetUserHostEventsApiDataMapper = (data) => fromJS(data.events)
export const useGetUserHostEventsApi = (
  { userSlug, section, disabled = false },
  deps
) =>
  useGetApi(
    {
      url: `users/${userSlug}/host_events?${section}=true`,
      dataMapper: useGetUserHostEventsApiDataMapper,
      disabled,
    },
    deps
  )

const useGetMyEventsApiDataMapper = (data) => fromJS(data.events)
export const useGetMyEventsApi = ({ disabled = false }, deps) =>
  useGetApi(
    {
      url: `me/events`,
      dataMapper: useGetMyEventsApiDataMapper,
      disabled,
    },
    deps
  )

export const useGetMyUpcomingEventsApi = ({ userId, disabled = false }, deps) =>
  useGetApi(
    {
      url: `users/${userId}/upcoming_purchased_events`,
      dataMapper: useGetMyEventsApiDataMapper,
      disabled,
    },
    deps
  )
export const useGetMyPastEventsApi = ({ userId, disabled = false }, deps) =>
  useGetApi(
    {
      url: `users/${userId}/past_purchased_events`,
      dataMapper: useGetMyEventsApiDataMapper,
      disabled,
    },
    deps
  )

const useGetCuratedEventsApiDataMapper = (data) => fromJS(data.events)
export const useGetCuratedEventsApi = ({ section = null }, deps) =>
  useGetApi(
    {
      url: section != null ? `curated_events/${section}` : 'curated_events',
      dataMapper: useGetCuratedEventsApiDataMapper,
      withoutAuth: true,
    },
    deps
  )

const useGetCuratedUsersApiDataMapper = (data) => fromJS(data.users)
export const useGetCuratedUsersApi = ({ section = null }, deps) =>
  useGetApi(
    {
      url: section != null ? `curated_users/${section}` : 'curated_users',
      dataMapper: useGetCuratedUsersApiDataMapper,
      withoutAuth: true,
    },
    deps
  )

const useGetFeedApiDataMapper = (data) => fromJS(data.events)
export const useGetFeedApi = (_, deps) =>
  useGetApi(
    {
      url: 'feed',
      dataMapper: useGetFeedApiDataMapper,
    },
    deps
  )

const useGetCreatorConsentsApiDataMapper = (data) =>
  fromJS(data.creatorConsents)
export const useGetCreatorConsentsApi = (_, deps) =>
  useGetApi(
    {
      url: 'creator_consents',
      dataMapper: useGetCreatorConsentsApiDataMapper,
    },
    deps
  )

const useGetPayoutsApiDataMapper = (data) => fromJS(data.payouts)
export const useGetPayoutsApi = (_, deps) =>
  useGetApi(
    {
      url: `accountings/payouts`,
      dataMapper: useGetPayoutsApiDataMapper,
    },
    deps
  )

const useGetPayoutApiDataMapper = (data) => fromJS(data.payout)
export const useGetPayoutApi = ({ payoutId }, deps) =>
  useGetApi(
    {
      url: `accountings/payouts/${payoutId}`,
      dataMapper: useGetPayoutApiDataMapper,
    },
    deps
  )

const useGetBalanceApiDataMapper = (data) => fromJS(data)
export const useGetBalanceApi = (_, deps) =>
  useGetApi(
    {
      url: `accountings/balance`,
      dataMapper: useGetBalanceApiDataMapper,
    },
    deps
  )

const useGetPendingBalanceTransactionsApiDataMapper = (data) =>
  fromJS(data.balanceTransactions)
export const useGetPendingBalanceTransactionsApi = (_, deps) =>
  useGetApi(
    {
      url: `accountings/pending_balance_transactions`,
      dataMapper: useGetPendingBalanceTransactionsApiDataMapper,
    },
    deps
  )

const useGetPendingPayoutApiDataMapper = (data) => fromJS(data)
export const useGetPendingPayoutApi = (_, deps) =>
  useGetApi(
    {
      url: `accountings/pending_payout`,
      dataMapper: useGetPendingPayoutApiDataMapper,
    },
    deps
  )

const useGetCompanyApiDataMapper = (data) => fromJS(data.company)
export const useGetCompanyApi = (_, deps) =>
  useGetApi(
    {
      url: `company`,
      dataMapper: useGetCompanyApiDataMapper,
    },
    deps
  )

const useGetInsightsRevenueApiDataMapper = (data) => fromJS(data.revenue)
export const useGetInsightsRevenueApi = (_, deps) =>
  useGetApi(
    {
      url: `insight/revenue`,
      dataMapper: useGetInsightsRevenueApiDataMapper,
    },
    deps
  )

const useGetInsightsMembersApiDataMapper = (data) => fromJS(data.members)
export const useGetInsightsMembersApi = (_, deps) =>
  useGetApi(
    {
      url: `insight/members`,
      dataMapper: useGetInsightsMembersApiDataMapper,
    },
    deps
  )

const useGetInsightsMrrApiDataMapper = (data) => fromJS(data.mrr)
export const useGetInsightsMrrApi = (_, deps) =>
  useGetApi(
    {
      url: `insight/mrr`,
      dataMapper: useGetInsightsMrrApiDataMapper,
    },
    deps
  )

const useGetInsightsCurrencyApiDataMapper = (data) => data.currency
export const useGetInsightsCurrencyApi = (_, deps) =>
  useGetApi({
    url: `insight/currency`,
    dataMapper: useGetInsightsCurrencyApiDataMapper,
  })

const useGetInvoicesApiDataMapper = (data) => fromJS(data.invoices)
export const useGetInvoicesApi = ({ disabled = false }, deps) =>
  useGetApi(
    {
      url: `invoices`,
      dataMapper: useGetInvoicesApiDataMapper,
      disabled,
    },
    deps
  )

const useGetReceiptsApiDataMapper = (data) => fromJS(data.receipts)
export const useGetReceiptsApi = ({ disabled = false }, deps) =>
  useGetApi(
    {
      url: `receipts`,
      dataMapper: useGetReceiptsApiDataMapper,
      disabled,
    },
    deps
  )

const useGetEventsCountApiDataMapper = (data) => data.count
export const useGetEventsCountApi = (_, deps) =>
  useGetApi(
    {
      url: `events/count`,
      dataMapper: useGetEventsCountApiDataMapper,
    },
    deps
  )

const useGetAccountTotalsApiDataMapper = (data) => fromJS(data)
export const useGetAccountTotalsApi = (_, deps) =>
  useGetApi(
    {
      url: `accountings/totals`,
      dataMapper: useGetAccountTotalsApiDataMapper,
    },
    deps
  )

const useGetRelationsTotalsApiDataMapper = (data) => fromJS(data)
export const useGetRelationsTotalsApi = (_, deps) =>
  useGetApi(
    {
      url: `relations/totals`,
      dataMapper: useGetRelationsTotalsApiDataMapper,
    },
    deps
  )

const useGetCategoriesApiDataMapper = (data) => fromJS(data.categories)
export const useGetCategoriesApi = (_, deps) =>
  useGetApi(
    {
      url: `categories`,
      dataMapper: useGetCategoriesApiDataMapper,
    },
    deps
  )

const useGetPostApiDataMapper = (data) => fromJS(data.post)
export const useGetPostApi = ({ postId }, deps) =>
  useGetApi(
    {
      url: `posts/${postId}`,
      dataMapper: useGetPostApiDataMapper,
    },
    deps
  )

const useGetEventPostApiDataMapper = (data) => fromJS(data.post)
export const useGetEventPostApi = ({ eventId }, deps) =>
  useGetApi(
    {
      url: `events/${eventId}/post`,
      dataMapper: useGetEventPostApiDataMapper,
    },
    deps
  )

const useGetPostLikesApiDataMapper = (data) => fromJS(data.likes)
export const useGetPostLikesApi = ({ postId }, deps) =>
  useGetApi(
    {
      url: `posts/${postId}/likes`,
      dataMapper: useGetPostLikesApiDataMapper,
    },
    deps
  )

/*
  Data get/edit/delete action functions
*/

const getSearchMembersApiDataMapper = (data) => fromJS(data.users)
export const getSearchMembersApi = ({ userId, query }) =>
  callApi({
    url: `users/${userId}/members/search/${query}`,
    method: 'GET',
    dataMapper: getSearchMembersApiDataMapper,
  })

const useGetMembersApiDataMapper = (data) => fromJS(data)
export const useGetMembersApi = (
  { hostId, userId, type, disabled = false },
  deps
) =>
  useGetApi(
    {
      url: `insights/members/${hostId}/show/${userId}?type=${type}`,
      dataMapper: useGetMembersApiDataMapper,
      disabled,
    },
    deps
  )

const useGetTransactionApiDataMapper = (data) => fromJS(data.balanceTransaction)
export const useGetTransactionApi = (
  { transactionId, disabled = false },
  deps
) =>
  useGetApi(
    {
      url: `insights/transactions/${transactionId}`,
      dataMapper: useGetTransactionApiDataMapper,
      disabled,
    },
    deps
  )

const getEventDataMapper = (data) => fromJS(data.event)
export const getEvent = ({ id }) =>
  callApi({
    url: `events/${id}`,
    method: 'GET',
    dataMapper: getEventDataMapper,
  })

const createEventDataMapper = (data) => fromJS(data.event)
export const createEvent = ({ data }) =>
  callApi({
    url: `events`,
    method: 'POST',
    data,
    dataMapper: createEventDataMapper,
  })

const updateEventDataMapper = (data) => fromJS(data.event)
export const updateEvent = ({ id, data }) =>
  callApi({
    url: `events/${id}`,
    method: 'PUT',
    data,
    dataMapper: updateEventDataMapper,
  })

export const deleteEvent = ({ id, data }) =>
  callApi({
    url: `events/${id}`,
    method: 'DELETE',
    data,
  })
export const archiveEvent = ({ id, data }) =>
  callApi({
    url: `events/${id}/archive`,
    method: 'PUT',
    data,
  })
export const unArchiveEvent = ({ id, data }) =>
  callApi({
    url: `events/${id}/un_archive`,
    method: 'PUT',
    data,
  })

export const endEvent = ({ id }) =>
  callApi({
    url: `events/${id}/end_event`,
    method: 'POST',
  })

const getEventViewerCountDataMapper = (data) => fromJS(data.chatUserEvents)
export const getEventViewerCount = ({ id }) =>
  callApi({
    url: `events/${id}/viewer_count`,
    dataMapper: getEventViewerCountDataMapper,
  })

const getEventChatApiDataMapper = (data) => fromJS(data)
export const getEventChatApi = ({ id, createdAt }) =>
  callApi({
    url: `events/${id}/chats${
      createdAt != null ? `?created_at=${createdAt}` : ''
    }`,
    dataMapper: getEventChatApiDataMapper,
  })

const updateUserDataMapper = (data) => fromJS(data.user)
export const updateUser = ({ id, data }) =>
  callApi({
    url: `users/${id}`,
    method: 'PUT',
    data,
    dataMapper: updateUserDataMapper,
  })

const updatePaymentMethodApiDataMapper = (data) => fromJS(data.user)
export const updatePaymentMethodApi = ({ data }) =>
  callApi({
    url: `payments/update_payment_method`,
    method: 'POST',
    data,
    dataMapper: updatePaymentMethodApiDataMapper,
  })

export const getImageUploadUrl = ({ filename }) =>
  callApi({
    url: 'photos/presigned_url',
    method: 'POST',
    data: {
      filename,
    },
  })

export const getDocumentUploadUrl = ({ filename }) =>
  callApi({
    url: 'documents/presigned_url',
    method: 'POST',
    data: {
      filename,
    },
  })

export const getVideoUploadUrl = () =>
  callApi({
    url: 'videos/presigned_url',
    method: 'POST',
  })

export const forgotPassword = ({ email }) =>
  callApi({
    url: 'users/password',
    method: 'POST',
    withoutAuth: true,
    data: {
      user: {
        email,
      },
    },
  })

export const resetPassword = ({ token, password }) =>
  callApi({
    url: 'users/password',
    method: 'PUT',
    withoutAuth: true,
    data: {
      user: {
        reset_password_token: token,
        password,
      },
    },
  })

export const signIn = ({ email, password }) =>
  callApi({
    url: 'users/sign_in',
    method: 'POST',
    withoutAuth: true,
    data: {
      user: {
        email,
        password,
      },
    },
  })

export const signInFacebook = ({ accessToken, exchangeCode, redirectUri }) =>
  callApi({
    url: 'auth/facebook',
    method: 'POST',
    withoutAuth: true,
    data: {
      accessToken,
      exchangeCode,
      redirectUri,
    },
  })

export const signUp = ({ realName, name, email, password }) =>
  callApi({
    url: 'users',
    method: 'POST',
    withoutAuth: true,
    data: {
      user: {
        realName,
        name,
        email,
        password,
      },
    },
  })

export const getUserFeedback = ({ eventId, userId }) =>
  callApi({
    url: `events/${eventId}/users/${userId}/feedback_items`,
    method: 'GET',
  })

const createFeedbackDataMapper = (data) => fromJS(data.feedback)
export const createFeedback = ({ eventId, rating, description }) =>
  callApi({
    url: `events/${eventId}/feedback_items`,
    method: 'POST',
    data: {
      feedback_item: {
        rating,
        description,
      },
    },
    dataMapper: createFeedbackDataMapper,
  })

export const followUser = ({ userId }) =>
  callApi({
    url: `users/${userId}/follow`,
    method: 'POST',
  })
export const unFollowUser = ({ userId }) =>
  callApi({
    url: `users/${userId}/unfollow`,
    method: 'POST',
  })

export const registerParticipant = ({ eventId }) =>
  callApi({
    url: `events/${eventId}/register_participant`,
    method: 'POST',
  })

const getMeObjectDataMapper = (data) => fromJS(data.user)
export const getMeObject = () =>
  callApi({
    dataMapper: getMeObjectDataMapper,
    url: `users/me`,
  })

const getPaymentIntentDataMapper = (data) => data.clientSecret
export const getPaymentIntent = () =>
  callApi({
    url: `payments/intent`,
    method: 'POST',
    dataMapper: getPaymentIntentDataMapper,
    data: {
      event_id: 'de810fd6-5bf7-499a-a3bb-73b50a021295',
    },
  })

export const cancelSubscription = ({ subscriptionId }) =>
  callApi({
    url: `subscriptions/${subscriptionId}`,
    method: 'DELETE',
  })

export const changeSubscription = ({ oldPlanId, newPlanId }) =>
  callApi({
    url: `subscriptions/${oldPlanId}`,
    method: 'PUT',
    data: {
      newPlanId,
    },
  })

export const reactivateSubscription = ({ subscriptionId }) =>
  callApi({
    url: `subscriptions/${subscriptionId}/reactivate`,
    method: 'PUT',
  })

export const stripeActivateAccount = ({ authorizationCode }) =>
  callApi({
    url: `accounts/activate`,
    method: 'POST',
    data: {
      authorizationCode,
    },
  })

const getStripeAccountLoginLinkDataMapper = (data) => fromJS(data)
export const getStripeAccountLoginLink = () =>
  callApi({
    url: `me/stripe_account_login_link`,
    method: 'GET',
    dataMapper: getStripeAccountLoginLinkDataMapper,
  })

export const createCreatorConsent = ({ creatorId, consent }) =>
  callApi({
    url: `creator_consents`,
    method: 'POST',
    data: {
      hostId: creatorId,
      consent,
    },
  })

export const updateCreatorConsent = ({ id, consent }) =>
  callApi({
    url: `creator_consents/${id}`,
    method: 'PUT',
    data: {
      id: id,
      consent,
    },
  })

const createProductApiDataMapper = (data) => fromJS(data.product)
export const createProductApi = ({ data }) =>
  callApi({
    url: `products`,
    method: 'POST',
    data,
    dataMapper: createProductApiDataMapper,
  })

const updateProductApiDataMapper = (data) => fromJS(data.product)
export const updateProductApi = ({ productId, data }) =>
  callApi({
    url: `products/${productId}`,
    method: 'PUT',
    data,
    dataMapper: updateProductApiDataMapper,
  })

const createPlanApiDataMapper = (data) => fromJS(data.plan)
export const createPlanApi = ({ data }) =>
  callApi({
    url: `plans`,
    method: 'POST',
    data,
    dataMapper: createPlanApiDataMapper,
  })

const updatePlanApiDataMapper = (data) => fromJS(data.plan)
export const updatePlanApi = ({ planId, data }) =>
  callApi({
    url: `plans/${planId}`,
    method: 'PUT',
    data,
    dataMapper: updatePlanApiDataMapper,
  })

const upsertPlansApiDataMapper = (data) => fromJS(data.product)
export const upsertPlansApi = ({ productId, data }) =>
  callApi({
    url: `products/${productId}/upsert_plans`,
    method: 'PUT',
    data,
    dataMapper: upsertPlansApiDataMapper,
  })

export const getPayoutInvoicePdf = ({ payoutId }) =>
  callApi({
    url: `accountings/payouts/${payoutId}/pdf`,
    method: 'GET',
    responseType: 'blob',
  })

export const updateCompany = ({ data }) =>
  callApi({
    url: `company`,
    method: 'POST',
    data,
  })

export const createCommentReactionApi = ({ commentId, reaction }) =>
  callApi({
    url: `comments/${commentId}/reactions`,
    method: 'POST',
    data: {
      reaction,
    },
  })

export const deleteCommentReactionApi = ({ commentId, reactionId }) =>
  callApi({
    url: `comments/${commentId}/reactions/${reactionId}`,
    method: 'DELETE',
  })

const prepareProfileApiDataMapper = (data) => fromJS(data.user)
export const prepareProfileApi = () =>
  callApi({
    url: `users/prepare_profile`,
    method: 'POST',
    dataMapper: prepareProfileApiDataMapper,
  })

const launchProfileApiDataMapper = (data) => fromJS(data.user)
export const launchProfileApi = () =>
  callApi({
    url: `users/launch_profile`,
    method: 'POST',
    dataMapper: launchProfileApiDataMapper,
  })

export const registerFreePaymentApi = ({ eventId, couponCode }) =>
  callApi({
    url: `payments/register`,
    method: 'POST',
    data: {
      eventId,
      couponCode,
    },
  })

const getNotificationsApiDataMapper = (data) => fromJS(data)
export const getNotificationsApi = ({ userId, params }) =>
  callApi({
    url: `users/${userId}/notifications?${queryString.stringify(params)}`,
    method: 'GET',
    dataMapper: getNotificationsApiDataMapper,
  })

const createCategoryDataMapper = (data) => fromJS(data.category)
export const createCategory = ({ data }) =>
  callApi({
    url: `categories`,
    method: 'POST',
    data,
    dataMapper: createCategoryDataMapper,
  })

const updateCategoryDataMapper = (data) => fromJS(data.category)
export const updateCategory = ({ categoryId, data }) =>
  callApi({
    url: `categories/${categoryId}`,
    method: 'PUT',
    data,
    dataMapper: updateCategoryDataMapper,
  })

export const deleteCategory = ({ categoryId }) =>
  callApi({
    url: `categories/${categoryId}`,
    method: 'DELETE',
  })

const getUserPostsApiDataMapper = (data) => fromJS(data)
export const getUserPostsApi = ({ userId, params }) =>
  callApi({
    url: `users/${userId}/posts?${queryString.stringify(params)}`,
    method: 'GET',
    dataMapper: getUserPostsApiDataMapper,
  })

const getPinnedPostsDataMapper = (data) => fromJS(data)
export const getPinnedPosts = ({ userId }) =>
  callApi({
    url: `users/${userId}/posts/pinned_posts`,
    method: 'GET',
    dataMapper: getPinnedPostsDataMapper,
  })

const getUserEventsApiDataMapper = (data) => fromJS(data)
export const getUserEventsApi = ({ userSlug, params }) =>
  callApi({
    url: `users/${userSlug}/events?${queryString.stringify(params)}`,
    method: 'GET',
    dataMapper: getUserEventsApiDataMapper,
  })

const getUserEventsProgressApiDataMapper = (data) => fromJS(data)
export const getUserEventsProgressApi = ({ hostId }) =>
  callApi({
    url: `me/${hostId}/progress_events`,
    method: 'GET',
    dataMapper: getUserEventsProgressApiDataMapper,
  })

const getBalanceTransactionsApiDataMapper = (data) => fromJS(data)
export const getBalanceTransactionsApi = ({ payoutId, params }, deps) =>
  callApi(
    {
      url: `accountings/payouts/${payoutId}/balance_transactions?${queryString.stringify(
        params
      )}`,
      dataMapper: getBalanceTransactionsApiDataMapper,
    },
    deps
  )

const getPostCommentsApiDataMapper = (data) => fromJS(data.comments)
export const getPostCommentsApi = ({ postId }) =>
  callApi({
    url: `posts/${postId}/comments`,
    method: 'GET',
    dataMapper: getPostCommentsApiDataMapper,
  })

const createUserPostApiDataMapper = (data) => fromJS(data.post)
export const createUserPostApi = ({ userId, data }) =>
  callApi({
    url: `users/${userId}/posts`,
    method: 'POST',
    data,
    dataMapper: createUserPostApiDataMapper,
  })

const updatePostApiDataMapper = (data) => fromJS(data.post)
export const updatePostApi = ({ postId, data }) =>
  callApi({
    url: `posts/${postId}`,
    method: 'PUT',
    data,
    dataMapper: updatePostApiDataMapper,
  })

export const deletePostApi = ({ postId }) =>
  callApi({
    url: `posts/${postId}`,
    method: 'DELETE',
  })

const createPostCommentApiDataMapper = (data) => fromJS(data.comment)
export const createPostCommentApi = ({ postId, data }) =>
  callApi({
    url: `posts/${postId}/comments`,
    method: 'POST',
    data,
    dataMapper: createPostCommentApiDataMapper,
  })

export const deleteCommentApi = ({ commentId }) =>
  callApi({
    url: `comments/${commentId}`,
    method: 'DELETE',
  })

export const createLikeApi = ({ id, type }) =>
  callApi({
    url: `likes`,
    method: 'POST',
    data: {
      id,
      type,
    },
  })

export const deleteLikeApi = ({ id, type }) =>
  callApi({
    url: `likes`,
    method: 'DELETE',
    data: {
      id,
      type,
    },
  })

const createPollApiDataMapper = (data) => fromJS(data.poll)
export const createPollApi = ({ data }) =>
  callApi({
    url: `polls`,
    method: 'POST',
    data,
    dataMapper: createPollApiDataMapper,
  })

const createPollOptionApiDataMapper = (data) => fromJS(data.pollOption)
export const createPollOptionApi = ({ pollId, data }) =>
  callApi({
    url: `polls/${pollId}/poll_options`,
    method: 'POST',
    data,
    dataMapper: createPollOptionApiDataMapper,
  })

const pollVoteApiDataMapper = (data) => fromJS(data.poll)
export const pollVoteApi = ({ pollId, optionIds }) =>
  callApi({
    url: `polls/${pollId}/poll_votes`,
    method: 'POST',
    data: {
      pollOptionIds: optionIds,
    },
    dataMapper: pollVoteApiDataMapper,
  })

export const markNotificationAsReadApi = ({ userId, notificationId }) =>
  callApi({
    url: `users/${userId}/notifications/${notificationId}/open`,
    method: 'PUT',
  })

export const markAllNotificationsAsReadApi = ({ userId }) =>
  callApi({
    url: `users/${userId}/notifications/open_all`,
    method: 'POST',
  })

export const createNotificationSubscription = ({ userId, data }) =>
  callApi({
    url: `users/${userId}/subscriptions`,
    method: 'POST',
    data,
  })

export const notificationSubscriptionEmailSubscribe = ({
  userId,
  subscriptionId,
}) =>
  callApi({
    url: `users/${userId}/subscriptions/${subscriptionId}/subscribe_to_email`,
    method: 'PUT',
  })

export const notificationSubscriptionEmailUnsubscribe = ({
  userId,
  subscriptionId,
}) =>
  callApi({
    url: `users/${userId}/subscriptions/${subscriptionId}/unsubscribe_to_email`,
    method: 'PUT',
  })

export const createSubscriptionApi = ({ data }) =>
  callApi({
    url: `subscriptions`,
    method: 'POST',
    data,
  })

export const createSubscriptionFeedbackApi = ({ data }) =>
  callApi({
    url: `subscription_feedbacks`,
    method: 'POST',
    data: {
      subscription_feedback: data,
    },
  })

const draftEventApiDataMapper = (data) => fromJS(data.event)
export const draftEventApi = ({ eventId, data }) =>
  callApi({
    url: `events/${eventId}/draft`,
    method: 'PUT',
    data,
    dataMapper: draftEventApiDataMapper,
  })

const scheduleEventApiDataMapper = (data) => fromJS(data.event)
export const scheduleEventApi = ({ eventId, data }) =>
  callApi({
    url: `events/${eventId}/schedule`,
    method: 'PUT',
    data,
    dataMapper: scheduleEventApiDataMapper,
  })

const publishEventApiDataMapper = (data) => fromJS(data.event)
export const publishEventApi = ({ eventId, data }) =>
  callApi({
    url: `events/${eventId}/publish`,
    method: 'PUT',
    data,
    dataMapper: publishEventApiDataMapper,
  })

export const downloadBlobApi = ({ url }) =>
  callApi({
    url: url,
    responseType: 'blob',
  })

const useGetInviteCodeApiDataMapper = (data) => fromJS(data.inviteCode)
export const useGetInviteCodeApi = (
  { userSlug, inviteCodeString, disabled },
  deps
) =>
  useGetApi(
    {
      url: `invite_codes/${userSlug}/${inviteCodeString}`,
      dataMapper: useGetInviteCodeApiDataMapper,
      disabled: disabled,
    },
    deps
  )

const useGetInviteCodesApiDataMapper = (data) => fromJS(data.inviteCodes)
export const useGetInviteCodesApi = ({ userId }, deps) =>
  useGetApi(
    {
      url: `users/${userId}/invite_codes`,
      dataMapper: useGetInviteCodesApiDataMapper,
    },
    deps
  )

export const deleteBookmark = ({ eventId }) =>
  callApi({
    url: `bookmarks/${eventId}`,
    method: 'DELETE',
  })

export const createBookmark = ({ eventId }) =>
  callApi({
    url: `bookmarks`,
    data: {
      eventId: eventId,
    },
    method: 'POST',
  })

const createCheckoutSessionApiDataMapper = (data) => fromJS(data.session)
export const createCheckoutSessionApi = (data) =>
  callApi({
    url: `checkout_sessions`,
    method: 'POST',
    data,
    dataMapper: createCheckoutSessionApiDataMapper,
  })

const useCreateCheckoutSessionApiDataMapper = (data) => fromJS(data.session)
export const useCreateCheckoutSessionApi = ({ data, disabled = false }, deps) =>
  useGetApi(
    {
      url: `checkout_sessions`,
      method: 'POST',
      data,
      dataMapper: useCreateCheckoutSessionApiDataMapper,
      disabled,
    },
    deps
  )

const useGetUserCategoriesApiDataMapper = (data) => fromJS(data.categories)
export const useGetUserCategoriesApi = (
  { userSlug, productId, attachmentType },
  deps
) =>
  useGetApi(
    {
      url: `users/${userSlug}/categories?${queryString.stringify({
        product_id: productId ?? undefined,
        attachment_type: attachmentType ?? undefined,
      })}`,
      method: 'GET',
      dataMapper: useGetUserCategoriesApiDataMapper,
    },
    deps
  )

const useGetCouponCodesApiDataMapper = (data) => fromJS(data.coupons)
export const useGetCouponCodesApi = (_, deps) =>
  useGetApi(
    {
      url: 'coupons',
      method: 'GET',
      dataMapper: useGetCouponCodesApiDataMapper,
    },
    deps
  )

const createCouponDataMapper = (data) => fromJS(data.coupon)
export const createCoupon = ({ data }) =>
  callApi({
    url: `coupons`,
    method: 'POST',
    data,
    dataMapper: createCouponDataMapper,
  })

const updateCouponDataMapper = (data) => fromJS(data.coupon)
export const updateCoupon = ({ couponId, data }) =>
  callApi({
    url: `coupons/${couponId}`,
    method: 'PUT',
    data,
    dataMapper: updateCouponDataMapper,
  })

const useGetPlanCheckoutDataMapper = (data) => fromJS(data)
export const useGetPlanCheckout = ({ planId, data, disabled = false }) =>
  callApi({
    url: `plans/${planId}/checkout`,
    method: 'POST',
    dataMapper: useGetPlanCheckoutDataMapper,
    disabled,
    data,
  })
