import React, { useEffect, useState } from 'react'
import './LeadQualificationPage.scss'
import clsx from 'clsx'
import { Form, Formik, useFormikContext } from 'formik'
import { Link, useHistory, useRouteMatch } from 'react-router-dom'
import {
  URL_CREATOR,
  URL_CREATOR_SETUP,
  URL_LEAD_QUALIFICATION,
  URL_LEAD_QUALIFICATION_CATEGORIES,
  URL_LEAD_QUALIFICATION_PROFILE,
  URL_LEAD_QUALIFICATION_SOCIAL,
} from 'urls'
import BoonFormGroup from 'components/BoonInputs/BoonFormGroup'
import { BoonFormikTextField } from 'components/BoonInputs'
import { getApiError, removeNullObjectValues, sluggify } from 'utils'
import { SETUP_SOCIAL_ITEMS } from 'routes/CreatorSetupPage/CreatorSetupForm'
import { CheckSvg } from 'assets/svg'
import { getMeObject, prepareProfileApi, updateUser, useGetUserApi } from 'api'
import { useSelector } from 'react-redux'
import { getMe, isAuthenticated } from 'store/selectors'
import { CREATOR_STATUS } from 'store/constants'
import { useMount } from 'react-use'
import Spinner from 'components/Spinner'
import { updateMeStore } from 'store/utils/auth'
import StyledApiErrorMessage from 'components/StyledApiErrorMessage'
import { trackCreatorCategory } from 'tracking'
import LeadQualificationAddSocialModal from 'routes/LeadQualificationPage/LeadQualificationAddSocialModal'
import CloseSvg from 'assets/svg/CloseSvg'

const LEAD_QUALIFIATION_STEP_COUNT = 3

const LEAD_QUALIFICATION_CATEGORIES = [
  ['coaching', 'Coaching'],
  ['craftsmanship', 'Craftsmanship'],
  ['dancing', 'Dancing'],
  ['diy', 'DIY'],
  ['finess_health', 'Fitness & Health'],
  ['food', 'Food'],
  ['gaming', 'Gaming'],
  ['music', 'Music'],
  ['podcasts', 'Podcasts'],
  ['other', 'Other'],
]

const LEAD_QUALIFICATION_CATEGORIES_ANALYTICS = {
  coaching: 'Coaching',
  craftsmanship: 'Craftsmanship',
  dancing: 'Dancing',
  diy: 'DIY',
  finess_health: 'FitnessHealth',
  food: 'Food',
  gaming: 'Gaming',
  music: 'Music',
  podcasts: 'Podcasts',
  other: 'Other',
}

const LeadQualificationPage = () => {
  const { path } = useRouteMatch()
  const history = useHistory()
  const hasAuth = useSelector((state) => isAuthenticated(state))
  const me = useSelector((state) => getMe(state))
  const { data: user, setMappedData: setUserData } = useGetUserApi({
    userId: me.get('id'),
  })

  useMount(() => {
    if (!hasAuth) {
      history.push(URL_CREATOR)
    }
  })

  useEffect(() => {
    if (user != null) {
      if (user.get('creatorStatus') === CREATOR_STATUS.CREATOR) {
        history.push(URL_CREATOR_SETUP)
      }
    }
  }, [user, history])

  useEffect(() => {
    if (path === URL_LEAD_QUALIFICATION) {
      history.replace(URL_LEAD_QUALIFICATION_CATEGORIES)
    }
  }, [path, history])

  if (user == null) {
    return <Spinner light />
  }

  const saveUserValues = async (values, { setSubmitting, setErrors }) => {
    removeNullObjectValues(values)
    try {
      if (Object.keys(values).length > 0) {
        const updatedUser = await updateUser({
          id: me.get('id'),
          data: values,
        })
        setSubmitting(false)
        return updatedUser
      } else {
        setSubmitting(false)
        return user
      }
    } catch (e) {
      setErrors(getApiError(e))
      setSubmitting(false)
    }
  }

  return (
    <div className="LeadQualificationPage">
      <LeadQualificationStep
        active={path === URL_LEAD_QUALIFICATION_CATEGORIES}
        title="To get to know you a bit better, tell us what your content is about."
        key="categories"
        stepNumber={1}
        initialValues={{ vertical: user.get('vertical') }}
        onSubmit={async (values, otherProps) => {
          if (values.vertical == null) {
            otherProps.setErrors({ message: 'Please choose category' })
            return null
          }

          const updatedUser = await saveUserValues(
            { creatorStatus: CREATOR_STATUS.INTERESTED, ...values },
            otherProps
          )
          if (updatedUser) {
            setUserData(updatedUser)
            history.push(URL_LEAD_QUALIFICATION_SOCIAL)

            try {
              if (values.vertical) {
                const categoryName =
                  LEAD_QUALIFICATION_CATEGORIES_ANALYTICS[values.vertical]
                trackCreatorCategory(categoryName)
              }
            } catch (e) {
              // Pass, tracking can never crash UX
            }
          }
        }}
      >
        <LeadQualifiactionStepCategories />
      </LeadQualificationStep>
      <LeadQualificationStep
        active={path === URL_LEAD_QUALIFICATION_SOCIAL}
        backUrl={URL_LEAD_QUALIFICATION_CATEGORIES}
        title="What is your primary Social Media channel?"
        key="social"
        stepNumber={2}
        initialValues={{
          homepageUrl: user.get('homepageUrl'),
          instagramUrl: user.get('instagramUrl'),
          twitterUrl: user.get('twitterUrl'),
          facebookUrl: user.get('facebookUrl'),
          youtubeUrl: user.get('youtubeUrl'),
          linkedinUrl: user.get('linkedinUrl'),
          primarySocial: user.get('primarySocial'),
          primarySocialFollowersCount: user.get('primarySocialFollowersCount'),
        }}
        onSubmit={async (values, otherProps) => {
          if (values.primarySocial == null) {
            otherProps.setErrors({
              message: 'Please select your primary social media',
            })
            return null
          }

          const updatedUser = await saveUserValues(values, otherProps)
          if (updatedUser) {
            setUserData(updatedUser)
            history.push(URL_LEAD_QUALIFICATION_PROFILE)
          }
        }}
      >
        <LeadQualificationStepSocial />
      </LeadQualificationStep>
      <LeadQualificationStep
        active={path === URL_LEAD_QUALIFICATION_PROFILE}
        backUrl={URL_LEAD_QUALIFICATION_SOCIAL}
        title="What name are you known by?"
        key="profile"
        stepNumber={3}
        initialValues={{ name: '', username: '' }}
        onSubmit={async (values, otherProps) => {
          const success = await saveUserValues(values, otherProps)

          if (success) {
            try {
              otherProps.setSubmitting(true)
              await prepareProfileApi()
              const apiMe = await getMeObject()
              updateMeStore({ me: apiMe })

              history.push(URL_CREATOR_SETUP)
            } catch (e) {
              otherProps.setErrors(getApiError(e))
            }
            otherProps.setSubmitting(false)
          }
        }}
      >
        <LeadQualificationStepProfile />
      </LeadQualificationStep>
    </div>
  )
}

export default LeadQualificationPage

const LeadQualificationStep = ({
  title,
  active,
  backUrl,
  stepNumber,
  children,
  initialValues,
  validationSchema,
  onSubmit,
}) => {
  return (
    <div className={clsx('LeadQualificationStep', { active })}>
      <div className="lead-qualification-step-progress">
        Step {stepNumber} of {LEAD_QUALIFIATION_STEP_COUNT}
      </div>
      <div className="lead-qualification-step-title">{title}</div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, isValid }) => (
          <Form>
            <div className="lead-qualification-step-box">
              {children}
              <StyledApiErrorMessage />
            </div>
            <div className="lead-qualification-step-below">
              {backUrl && (
                <Link to={backUrl} className="lead-qualification-step-back">
                  ← Back
                </Link>
              )}
              <button
                type="submit"
                disabled={isSubmitting || !isValid}
                className={clsx(
                  'button primary loader lead-qualification-step-submit',
                  {
                    loading: isSubmitting,
                  }
                )}
              >
                Continue
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

const LeadQualifiactionStepCategories = () => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext()

  return (
    <div className="lead-qualification-step-categories">
      <div className="lead-qualification-step-subtitle">Pick one category.</div>
      <div className="lead-qualification-step-categories-list">
        {LEAD_QUALIFICATION_CATEGORIES.map(([id, name]) => (
          <div key={id}>
            <input
              id={id}
              type="radio"
              className="boon-dark-radio"
              checked={values.vertical === id}
              onChange={(e) => {
                setFieldTouched('vertical')
                setFieldValue('vertical', id)
              }}
            />
            <label className="product-info" htmlFor={id}>
              {name}
            </label>
          </div>
        ))}
      </div>
    </div>
  )
}

const LeadQualificationStepSocial = () => {
  const { values, resetForm } = useFormikContext()
  const [chosenSocial, setChosenSocial] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)

  const openModal = (key) => {
    setChosenSocial(key)
    setModalOpen(true)
  }

  const onRemoveClick = (e) => {
    resetForm()
    e.stopPropagation()
  }

  const renderSocialItems = () => {
    return SETUP_SOCIAL_ITEMS.map(({ key, type, name, icon: Icon }) => {
      const someSelected = values.primarySocial != null
      const selected = values.primarySocial === type
      return (
        <div
          className={clsx('lead-qualification-step-social-item', {
            success: selected,
            disabled: !selected && someSelected,
          })}
          onClick={() => openModal(key)}
          key={key}
        >
          <div className="lead-qualification-step-social-item-icon">
            {selected ? <CheckSvg /> : <Icon />}
          </div>
          <div className="lead-qualification-step-social-item-details">
            <div className="lead-qualification-step-social-item-name">
              {name}
            </div>
          </div>
          {selected && (
            <div
              onClick={(e) => onRemoveClick(e, key)}
              className="lead-qualification-step-social-item-remove"
              data-testid="lead-qualification-social-remove"
            >
              <CloseSvg />
            </div>
          )}
        </div>
      )
    })
  }

  return (
    <div className="lead-qualification-step-social">
      <LeadQualificationAddSocialModal
        onClose={() => setModalOpen(false)}
        chosenSocial={chosenSocial}
        active={modalOpen}
      />
      <div className="lead-qualification-step-social-description lead-qualification-step-subtitle">
        Pick one media.
      </div>
      <div className="lead-qualification-step-social-list">
        {renderSocialItems()}
      </div>
    </div>
  )
}

const LeadQualificationStepProfile = () => {
  const { values, setFieldValue, touched } = useFormikContext()

  useEffect(() => {
    if (!touched.username && values.name) {
      const username = sluggify(values.name)
      setFieldValue('username', username)
    }
  }, [values.name, touched.username, setFieldValue])

  return (
    <div className="lead-qualification-step-profile">
      <BoonFormGroup
        label="Creator name"
        required
        helperText="Choose the name that your audience knows you by."
      >
        <BoonFormikTextField
          type="text"
          name="name"
          tooltip="This is the name that will show on your creator page. What do your fans call you? You can always change this later."
          placeholder="Creator name"
          className="setup-name"
          dark
        />
      </BoonFormGroup>

      <BoonFormGroup
        label="Page URL"
        required
        helperText="Create a custom URL for your Boon page to share across your social and digital channels."
      >
        <BoonFormikTextField
          type="text"
          name="username"
          prepend="https://boon.tv/"
          tooltip="This is used to create your unique creator page on Boon and make sure unique event links look nice when you post them in your social channels. Typically people put in their creator name."
          placeholder="your-username"
          className="setup-username"
          dark
          required
        />
      </BoonFormGroup>
    </div>
  )
}
