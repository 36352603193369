import React from 'react'
import './CreatorSettingsPayoutAccount.scss'
import { getStripeAccountLoginLink } from 'api'
import { STRIPE_CLIENT_ID } from 'appConstants'
import { useRouteMatch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getMe } from 'store/selectors'

const CreatorSettingsPayoutAccount = ({ returnUrl }) => {
  const { path } = useRouteMatch()
  const me = useSelector((state) => getMe(state))

  const launchPayoutDashboard = async () => {
    try {
      const loginLink = await getStripeAccountLoginLink()
      window.open(loginLink.get('url'), '_blank')
    } catch (e) {
      alert(
        'An error was encountered while loading the payout dashboard, please try again later'
      )
    }
  }

  const renderInner = () => {
    if (me.get('hasPayoutAccount')) {
      return (
        <div>
          <div className="creator-settings-payout-account-description">
            Payout account successfully connected.
          </div>
          <button className="button primary" onClick={launchPayoutDashboard}>
            Edit Payout Information
          </button>
        </div>
      )
    } else {
      return (
        <div>
          <div className="creator-settings-payout-account-description">
            To start earning money, you need to set up an account.
          </div>

          <a
            className="button primary"
            href={`https://connect.stripe.com/express/oauth/authorize?client_id=${STRIPE_CLIENT_ID}&response_type=code&state=${
              returnUrl || path
            }`}
          >
            Setup Payout Account
          </a>
        </div>
      )
    }
  }

  return (
    <div className="CreatorSettingsPayoutAccount" id="payoutAccount">
      <div className="creator-settings-payout-account-header">
        Payout account
      </div>
      {renderInner()}
    </div>
  )
}

export default CreatorSettingsPayoutAccount
