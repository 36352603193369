import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, Link } from 'react-router-dom'
import { openAuthModal } from 'store/actions'
import { Formik, Form } from 'formik'
import { AUTH_MODAL_STATE } from 'store/constants'
import clsx from 'clsx'
import * as Yup from 'yup'
import StyledApiErrorMessage from 'components/StyledApiErrorMessage'
import { URL_FRONT_PAGE } from 'urls'
import queryString from 'query-string'
import './ResetPasswordPage.scss'
import { resetPassword } from 'api'
import { Container, Row, Col } from 'components/Grid'
import { BoonFormikTextField } from 'components/BoonInputs'
import { getApiError } from 'utils'

import { CheckSvg } from 'assets/svg'

const ResetPasswordSchema = Yup.object().shape({
  password: Yup.string().required('Required'),
})

const ResetPasswordPage = () => {
  const dispatch = useDispatch()
  const [success, setSuccess] = useState(false)
  const location = useLocation()
  const { token } = queryString.parse(location.search)

  const handleSuccessClick = () => {
    dispatch(openAuthModal({ state: AUTH_MODAL_STATE.SIGNIN }))
  }

  if (token == null) {
    return <p>No valid reset credentials were found</p>
  }

  const renderSuccess = () => {
    return (
      <div className="reset-password-page-success">
        <p className="reset-password-page-success-title">Password updated</p>
        <CheckSvg />
        <p>Your password has been updated.</p>
        <Link
          onClick={handleSuccessClick}
          to={URL_FRONT_PAGE}
          className="button primary"
        >
          Log in
        </Link>
      </div>
    )
  }

  const renderForm = () => {
    return (
      <div className="reset-password-page-form">
        <Formik
          initialValues={{ password: '', confirmPassword: '' }}
          validationSchema={ResetPasswordSchema}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            if (values.password !== values.confirmPassword) {
              setSubmitting(false)
              return setErrors({ confirmPassword: 'Passwords must match' })
            }

            setSubmitting(true)
            resetPassword({
              token,
              password: values.password,
            })
              .then(() => {
                setSubmitting(false)
                setSuccess(true)
              })
              .catch((e) => {
                setSubmitting(false)
                setErrors(getApiError(e).errors)
              })
          }}
        >
          {({ isSubmitting, errors }) => (
            <Form>
              <div className="reset-password-page-title">Update password</div>
              <p>Must be at least 6 characters.</p>
              <BoonFormikTextField
                type="password"
                name="password"
                label="Password"
                className="reset-password-page-field"
              />
              <BoonFormikTextField
                type="password"
                name="confirmPassword"
                label="Re-enter your password"
                className="reset-password-page-field"
              />
              <button
                type="submit"
                disabled={isSubmitting}
                className={clsx(
                  'reset-password-page-button button primary medium loader',
                  {
                    loading: isSubmitting,
                  }
                )}
              >
                Update
              </button>
              <StyledApiErrorMessage />
            </Form>
          )}
        </Formik>
      </div>
    )
  }

  return (
    <section className="ResetPasswordPage">
      <Container>
        <Row className="justify-content-md-center">
          <Col md={6} lg={4}>
            {success ? renderSuccess() : renderForm()}
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default ResetPasswordPage
