import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { openAuthModal, closeAuthModal } from 'store/actions'
import { Formik, Form } from 'formik'
import { AUTH_MODAL_STATE } from 'store/constants'
import clsx from 'clsx'
import * as Yup from 'yup'
import StyledApiErrorMessage from 'components/StyledApiErrorMessage'
import { signInStore } from 'store/utils/auth'
import { BoonFormikTextField } from 'components/BoonInputs'

const SignInEmailSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  password: Yup.string().required('Required'),
})

const SignInView = ({ dispatch }) => {
  const handleSignUp = () => {
    dispatch(openAuthModal({ state: AUTH_MODAL_STATE.SIGNUP }))
  }
  const handleForgotPassword = () => {
    dispatch(openAuthModal({ state: AUTH_MODAL_STATE.FORGOT_PASSWORD }))
  }

  return (
    <section>
      <Formik
        initialValues={{ email: '', password: '' }}
        validationSchema={SignInEmailSchema}
        onSubmit={(values, { setSubmitting, setErrors }) => {
          signInStore(values)
            .then(
              () => {
                dispatch(closeAuthModal())
              },
              (error) => {
                console.error(error)
                setErrors(
                  error?.response?.data ?? 'An error occurred, please try again'
                )
              }
            )
            .then(() => {
              setSubmitting(false)
            })
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <BoonFormikTextField
              type="email"
              name="email"
              placeholder="Email"
            />
            <BoonFormikTextField
              type="password"
              name="password"
              placeholder="Password"
            />
            <button
              type="submit"
              disabled={isSubmitting}
              className={clsx('button primary medium loader', {
                loading: isSubmitting,
              })}
            >
              Login
            </button>
            <StyledApiErrorMessage />
          </Form>
        )}
      </Formik>

      <p className="forgot-password">
        <span onClick={handleForgotPassword} className="textlink">
          Forgot password?
        </span>
      </p>

      <p className="signup">
        Don’t have an account?&nbsp;
        <span onClick={handleSignUp} className="textlink">
          Sign up
        </span>
      </p>
    </section>
  )
}

export default withRouter(connect()(SignInView))
