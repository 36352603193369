import React from 'react'
import './EventHostPanel.scss'
import { endEvent } from 'api'

const EventHostPanel = ({ event, refreshEvent }) => {
  const onEndEvent = async () => {
    if (window.confirm('Are you sure you want to end the event?')) {
      try {
        await endEvent({ id: event.get('id') })
        refreshEvent()
      } catch (e) {
        window.alert('Something went wrong ending the event')
      }
    }
  }

  return (
    <div className="EventHostPanel">
      <div className="event-host-panel-title">You're hosting this event</div>
      <button
        className="button primary light small"
        onClick={onEndEvent}
        disabled={event.get('ended')}
      >
        {event.get('ended') ? 'Event ended' : 'End event'}
      </button>
    </div>
  )
}

export default EventHostPanel
