import React, { useCallback } from 'react'
import PollBasic from 'components/Poll/PollBasic'
import { POST_ATTACHMENT_ACCESS } from 'store/constants/post'

const Poll = ({
  poll,
  access,
  onNoAccess,
  interactive,
  onPostAttachmentUpdate,
  onVoteClick: parentOnVoteClick,
  headerOptionComponent,
}) => {
  const hasAccess = access !== POST_ATTACHMENT_ACCESS.NONE

  const onVoteClick = useCallback(() => {
    if (!hasAccess) {
      onNoAccess()
    } else if (parentOnVoteClick != null) {
      parentOnVoteClick()
    }
  }, [parentOnVoteClick, hasAccess, onNoAccess])

  return (
    <PollBasic
      poll={poll}
      hasAccess={hasAccess}
      onNoAccess={onNoAccess}
      interactive={interactive}
      onPostAttachmentUpdate={onPostAttachmentUpdate}
      onVoteClick={onVoteClick}
      headerOptionComponent={headerOptionComponent}
    />
  )
}

export default Poll
