import React, { useEffect } from 'react'
import './ManageSubscriptionsPage.scss'
import { Col, NarrowContainer, Row } from 'components/Grid'
import { useGetSubscriptionsApi } from 'api'
import Spinner from 'components/Spinner'
import { useHistory } from 'react-router-dom'
import { URL_MANAGE_SUBSCRIPTIONS } from 'urls'
import { useSelector } from 'react-redux'
import { isAuthenticated } from 'store/selectors'
import PurchasesPageHeader from 'components/PurchasesPageHeader'
import SubscriptionCard from 'components/SubscriptionCard'

const ManageSubscriptionsPage = () => {
  const history = useHistory()
  const {
    data: subscriptions,
    refresh: refreshSubscriptions,
  } = useGetSubscriptionsApi({})
  const hasAuth = useSelector((state) => isAuthenticated(state))

  useEffect(() => {
    if (!hasAuth) {
      history.push(
        `/sign-in?await_auth=true&redirect_url=${URL_MANAGE_SUBSCRIPTIONS}`
      )
    }
  }, [history, hasAuth])

  const renderSubscriptionCards = () => {
    if (subscriptions == null) {
      return <Spinner light />
    }

    return subscriptions.map((subscription, idx) => (
      <Col lg={4} key={idx}>
        <SubscriptionCard
          subscription={subscription}
          refreshSubscriptions={refreshSubscriptions}
        />
      </Col>
    ))
  }

  return (
    <div className="ManageSubscriptionsPage">
      <NarrowContainer>
        <PurchasesPageHeader />
        <div className="subscription-list">
          <Row>{renderSubscriptionCards()}</Row>
        </div>
      </NarrowContainer>
    </div>
  )
}

export default ManageSubscriptionsPage
