import React, { useEffect } from 'react'
import './PayoutPage.scss'
import PayoutDetail from './PayoutDetail'
import { ExtendedContainer } from 'components/Grid'
import { useRouteMatch, useHistory } from 'react-router-dom'
import PendingDetail from './PendingDetail'
import { URL_ACCOUNTING, URL_ACCOUNTING_PAYOUT } from 'urls'
import { Link } from 'react-router-dom'
import { ChevronDownSvg } from 'assets/svg'
import { useSelector } from 'react-redux'
import { isAuthenticated } from 'store/selectors'
import CreatorSidebar from 'components/CreatorSidebar'

const PayoutPage = () => {
  const {
    params: { payoutId },
  } = useRouteMatch()
  const history = useHistory()
  const hasAuth = useSelector((state) => isAuthenticated(state))

  useEffect(() => {
    if (!hasAuth) {
      history.push(
        `/sign-in?await_auth=true&redirect_url=${URL_ACCOUNTING_PAYOUT(
          payoutId
        )}`
      )
    }
  }, [history, hasAuth, payoutId])

  const renderPayoutDetail = () => {
    return <PayoutDetail payoutId={payoutId} />
  }

  return (
    <CreatorSidebar>
      <div className="PayoutPage">
        <ExtendedContainer>
          <Link to={URL_ACCOUNTING} className="payout-title">
            <ChevronDownSvg className="payout-title-back" />
            Payout
          </Link>
          {renderPayoutDetail()}
        </ExtendedContainer>
      </div>
    </CreatorSidebar>
  )
}

export default PayoutPage
