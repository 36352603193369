import React from 'react'
import PinnedModal from 'components/PinnedModal'
import './NamedPinnedModal.scss'
import CloseSvg from 'assets/svg/CloseSvg'
import clsx from 'clsx'

const NamedPinnedModal = ({
  title,
  children,
  onClose,
  className,
  ...otherProps
}) => {
  return (
    <PinnedModal
      className={clsx('NamedPinnedModal', className)}
      onClose={onClose}
      {...otherProps}
    >
      <div className="named-pinned-modal-header">
        <span className="named-pinned-modal-header-title">{title}</span>

        <div className="named-pinned-modal-header-close" onClick={onClose}>
          <CloseSvg
            onClick={onClose}
            className="named-pinned-modal-header-close-icon"
          />
        </div>
      </div>
      {children}
    </PinnedModal>
  )
}

export default NamedPinnedModal
