import React from 'react'
import './Gallery.scss'
import Masonry from 'react-masonry-css'
import PhotoItem from 'components/Gallery/PhotoItem'
import DocumentItem from 'components/Gallery/DocumentItem'
import PostCompose from 'components/PostCompose'
import EmptyList from 'components/EmptyList'

const Gallery = ({
  posts,
  onNoneAccessPostClick,
  showCompose,
  user,
  dispatchPosts,
  filters,
  dispatchFilter,
  onPostDeleted,
  onPostUpdated,
}) => {
  const userId = user.get('id')
  const items = posts
    .sortBy((post) => post.get('lastActivityAt'))
    .reverse()
    .toList()
    .map((post, idx) => {
      switch (post.get('attachmentType')) {
        case 'Photo':
          return (
            <PhotoItem
              key={idx}
              post={post}
              onPostDeleted={onPostDeleted}
              onPostUpdated={onPostUpdated}
              hostId={userId}
              onNoneAccessPostClick={onNoneAccessPostClick}
            />
          )
        case 'Document':
          return (
            <DocumentItem
              key={idx}
              post={post}
              onNoneAccessPostClick={onNoneAccessPostClick}
            />
          )
        default:
          return null
      }
    })
    .toArray()
    .filter(Boolean)

  const renderMasonry = () => {
    if (items.length === 0) {
      return <EmptyList filters={filters} dispatchFilter={dispatchFilter} />
    }

    return (
      <>
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="gallery-masonry-grid"
          columnClassName="gallery-masonry-grid-column"
        >
          {items}
        </Masonry>
      </>
    )
  }

  const breakpointColumnsObj = {
    default: 4,
    1100: 3,
    700: 2,
    500: 2,
  }

  return (
    <div className="Gallery">
      {showCompose && (
        <PostCompose
          user={user}
          dispatchPosts={dispatchPosts}
          defaultProductId={filters.product_id}
        />
      )}
      {renderMasonry()}
    </div>
  )
}

export default Gallery
