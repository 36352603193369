import React, { useState } from 'react'
import NamedPinnedModal from 'components/NamedPinnedModal'
import { Form, Formik } from 'formik'
import { BoonFormikTextField } from 'components/BoonInputs'
import { StarBorderSvg } from 'assets/svg'
import './WatchEventFeedback.scss'
import clsx from 'clsx'
import StyledApiErrorMessage from 'components/StyledApiErrorMessage'
import { createFeedback, getUserFeedback } from 'api'
import { useMount } from 'react-use'
import { getApiError } from 'utils'

const WatchEventFeedbackForm = ({
  values,
  setFieldValue,
  isSubmitting,
  event,
}) => {
  const renderStars = () => {
    const amount = 5

    return Array.apply(null, Array(5)).map((_, i) => {
      const star = amount - i

      return (
        <span
          key={star}
          className={clsx('watch-event-feedback-stars-star', {
            active: values.rating >= star,
          })}
          onClick={() => setFieldValue('rating', star)}
        >
          <StarBorderSvg />
        </span>
      )
    })
  }

  return (
    <Form>
      <div className="watch-event-feedback-event-title">
        {event.get('title')}
      </div>
      <div className="watch-event-feedback-stars">{renderStars()}</div>

      <BoonFormikTextField
        name="description"
        placeholder={`Tell ${event.getIn([
          'host',
          'name',
        ])} about your experience`}
        multiline
      />

      <StyledApiErrorMessage />
      <button
        type="submit"
        disabled={isSubmitting}
        className={clsx('watch-event-feedback-submit button primary loader', {
          loading: isSubmitting,
        })}
      >
        Send feedback
      </button>
    </Form>
  )
}

const WatchEventFeedback = ({ event, meId }) => {
  const [isActive, setActive] = useState(false)

  useMount(() => {
    getUserFeedback({ eventId: event.get('id'), userId: meId })
      .then((data) => {
        if (!data) {
          setActive(true)
        }
      })
      .catch((e) => {
        // Pass
      })
  })

  const onSubmit = (values, { setSubmitting, setErrors }) => {
    setSubmitting(true)
    createFeedback({
      eventId: event.get('id'),
      rating: values.rating,
      description: values.description,
    })
      .then(() => {
        setSubmitting(false)
        setActive(false)
      })
      .catch((e) => {
        setSubmitting(false)
        setErrors(getApiError(e))
      })
  }

  return (
    <NamedPinnedModal
      title="Rate your experience"
      active={isActive}
      onClose={() => setActive(false)}
      className="WatchEventFeedback"
    >
      <Formik
        initialValues={{
          rating: null,
          description: '',
        }}
        onSubmit={onSubmit}
      >
        {(props) => <WatchEventFeedbackForm {...props} event={event} />}
      </Formik>
    </NamedPinnedModal>
  )
}

export default WatchEventFeedback
