import React from 'react'
import { Link } from 'react-router-dom'
import { Container, Row, Col } from 'components/Grid'
import underlay from 'assets/vid/notfound_underlay.mp4'
import underlayPoster from 'assets/vid/notfound_underlay.jpg'
import { NotFoundSeeThroughSvg } from 'assets/svg'
import './NotFoundPage.scss'
import { URL_FRONT_PAGE } from 'urls'
import { Helmet } from 'react-helmet-async'
import { useNotFound, useDarkHeader } from 'utils'

const NotFoundPage = () => {
  useNotFound()
  useDarkHeader(false)

  return (
    <div className="NotFoundPage">
      <Helmet>
        <title>Not Found | Boon</title>
      </Helmet>
      <Container>
        <Row className="justify-content-md-center">
          <Col lg={9}>
            <div className="video-overlay">
              <video
                width="100%"
                height="auto"
                muted
                autoPlay
                playsInline
                loop
                src={underlay}
                poster={underlayPoster}
              />
              <NotFoundSeeThroughSvg alt="404" />
            </div>
            <div className="not-found-page-cta">
              <h4>Ooops! The page you were looking for doesn’t exist</h4>
              <p>You may have mistyped the address or the page has moved.</p>
              <Link to={URL_FRONT_PAGE} className="button secondary">
                Go to homepage
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default NotFoundPage
