import React, { useRef, useEffect, useState } from 'react'
import './MembershipNavigation.scss'

import { usePushHistoryWithSavedState } from 'utils'
import MembershipElement from 'components/MembershipNavigation/MembershipElement'
import ProductCard from 'components/ProductCard'
import { HomeSvg } from 'assets/svg'
import { useSelector } from 'react-redux'
import { selectActiveSubscriptions } from 'store/selectors'

import clsx from 'clsx'

const MembershipNavigation = ({ user, activeFilters, dispatchFilter }) => {
  const ref = useRef(null)
  const pushHistory = usePushHistoryWithSavedState()

  useEffect(() => {
    setTimeout(() => {
      if (ref && ref.current) {
        ref.current.scrollIntoView({
          behavior: 'smooth',
          inline: 'center',
          block: 'nearest',
        })
      }
    }, 200)
  }, [ref, activeFilters])

  const [showProductCard, setShowProductCard] = useState(false)

  const handleOnClick = () => {
    setActiveProductId(null)
    setShowProductCard(false)
  }

  const activeSubscriptions = useSelector((state) =>
    selectActiveSubscriptions(state)
  )
  const productOrder = (product) => [
    !activeSubscriptions.some(
      (s) => s.getIn(['product', 'id']) === product.get('id')
    ),
    product.get('order'),
  ]
  const products = user.get('products').sortBy(productOrder)

  if (products.size === 0) {
    return ''
  }

  const activeProductId = activeFilters.get('productId')
  const setActiveProductId = (productId) => {
    dispatchFilter({ group: 'productId', key: productId })
    dispatchFilter({ group: 'categoryId', key: null })
    pushHistory(null, { productId, categoryId: null }, true)
  }
  const activeProduct = products
    .toList()
    .find((product) => product.get('id') === activeProductId)

  return (
    <>
      <div className="MembershipNavigation">
        <div className="membership-navigation-inner">
          <div
            className={clsx('MembershipElement', { active: !activeProductId })}
            onClick={handleOnClick}
          >
            <div className="membership-element-image">
              <div className="membership-element-clipped-box"></div>
              <div className="avatar-placeholder" />
              <HomeSvg className="membership-element-all" />
            </div>
            <div className="membership-element-title">All</div>
          </div>

          {products.toList().map((product) => (
            <MembershipElement
              user={user}
              scrollRef={activeProductId === product.get('id') ? ref : null}
              key={product.get('id')}
              product={product}
              activeProductId={activeProductId}
              setActiveProductId={setActiveProductId}
              setShowProductCard={setShowProductCard}
            />
          ))}
        </div>
      </div>
      {showProductCard && activeProduct && (
        <ProductCard product={activeProduct} user={user} />
      )}
    </>
  )
}

export default MembershipNavigation
