import React from 'react'
import { useSelector } from 'react-redux'

import InsightsContentLoader from 'components/InsightsContentLoader/InsightsContentLoader'

const InsightsTableMeta = ({
  page,
  totalPages,
  totalResults,
  results,
  loading,
}) => {
  const browser = useSelector((state) => state.get('browser'))

  if (loading) {
    return (
      <InsightsContentLoader width={120} height={20}>
        <rect x="0" y="5" rx="6" ry="6" width="80" height="10" />
      </InsightsContentLoader>
    )
  }

  if (
    ![page, totalPages, totalResults, results].every(
      (n) => typeof n === 'number'
    )
  ) {
    return null
  }

  let from = 10 * (page - 1) + 1
  let to = Math.min(from + results - 1, from + 9)

  return (
    <span className="insights-table-widget-meta">
      {browser.greaterThan.md ? 'Showing ' : ''}
      {from}-{to} out of {totalResults}
    </span>
  )
}

export default InsightsTableMeta
