import React from 'react'
import { StarBorderSvg } from 'assets/svg'
import 'components/InsightsVideoFeedbackCard/InsightsVideoFeedbackCard.scss'
import InsightsGenericFeedbackCard from 'components/InsightsGenericFeedbackCard'
import clsx from 'clsx'

const InsightsVideoFeedbackCard = ({
  user,
  headline,
  headlineLink,
  date,
  rating,
  feedback,
  onNameClick = null,
}) => {
  const renderStars = () => {
    const amount = 5

    return Array.apply(null, Array(5)).map((_, i) => {
      const star = amount - i

      return (
        <span
          key={star}
          className={clsx('insights-video-feedback-card-stars-star', {
            active: rating >= star,
          })}
        >
          <StarBorderSvg />
        </span>
      )
    })
  }

  const renderContent = () => {
    return (
      <div className="InsightsVideoFeedbackCard">
        <div className="insights-feedback-content-rating-label">Rating</div>
        <div className="insights-video-feedback-card-stars">
          {renderStars(rating)}
        </div>
        <div className="insights-video-feedback-card-text">{feedback}</div>
      </div>
    )
  }

  return (
    <InsightsGenericFeedbackCard
      modalTitle="Video Rating"
      user={user}
      headline={headline}
      headlineLabel={'Video'}
      headlineLink={headlineLink}
      date={date}
      onNameClick={onNameClick}
    >
      {renderContent()}
    </InsightsGenericFeedbackCard>
  )
}

export default InsightsVideoFeedbackCard
