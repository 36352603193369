import React from 'react'
import './CreatorSettingsHeader.scss'

const CreatorSettingsHeader = ({ title, description }) => {
  return (
    <div className="CreatorSettingsHeader">
      <div className="creator-settings-header-inner">
        <h2 className="creator-settings-header-title">{title}</h2>
        <div className="creator-settings-header-description">{description}</div>
      </div>
    </div>
  )
}

export default CreatorSettingsHeader
