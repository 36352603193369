import React from 'react'

import { displayPrice } from 'utils'
import clsx from 'clsx'
import { DateTime } from 'luxon'

export const CANCELLATION_REASONS_SHORT = {
  time_use: 'No time',
  not_satisfied: 'Not as expected',
  not_relevant: 'Not relevant',
  too_expensive: 'Price',
  financial_reasons: 'Cannot afford anymore',
  creator_competitor: 'Other profile',
  competitive_platform: 'Other platform',
  circumstantial: 'Need a break',
  offline: 'Offline alternative',
  other: 'Other',
}

const RelationsMenuMembership = ({ membership }) => {
  const formatDate = (date) => DateTime.fromISO(date).toFormat('MMM d, y')

  const subscriptionFeedback = (feedbacks) => {
    if (feedbacks.size === 0) {
      return null
    }

    return feedbacks.map((feedback, idx) => (
      <tr key={idx}>
        <td className="relations-menu-modal-feedback" colSpan="2">
          <span className="relations-menu-modal-tag">
            {feedback.get(0).map((key) => CANCELLATION_REASONS_SHORT[key]) ||
              'Other'}
          </span>
          <div className="relations-menu-modal-feedback-comment">
            {feedback.get(1) || 'No comment'}
          </div>
        </td>
      </tr>
    ))
  }

  const nextChargeRow = (membership) => {
    if (membership.get('status') === 'canceled') {
      return null
    }

    return (
      <tr key="chargeRow">
        <td>Next Charge Date</td>
        <td>{formatDate(membership.get('next_charge_date'))}</td>
      </tr>
    )
  }

  const cancelledRows = (membership) => {
    if (membership.get('status') === 'active') {
      return null
    }

    return (
      <>
        <tr key="cancelledRows0">
          <td>Cancel Date</td>
          <td>{formatDate(membership.get('cancel_date'))}</td>
        </tr>
        <tr key="cancelledRows1">
          <td>Expiration Date</td>
          <td>{formatDate(membership.get('expiration_date'))}</td>
        </tr>
        {subscriptionFeedback(membership.get('subscription_feedbacks'))}
      </>
    )
  }

  return (
    <div className="relations-menu-modal-memberships-membership">
      <div className="relations-menu-modal-memberships-membership-name">
        {membership.get('name')}
      </div>
      <div
        className={clsx(
          'relations-menu-modal-memberships-membership-status relations-menu-modal-tag',
          membership.get('status')
        )}
      >
        {membership.get('status') === 'active' ? 'Active' : 'Cancelled'}
      </div>
      <div className="relations-menu-modal-memberships-membership-price">
        {displayPrice(
          membership.getIn(['billing_amount', 0]),
          membership.getIn(['billing_amount', 1])
        )}{' '}
        {membership.get('billing_name')}
      </div>
      <table className="relations-menu-modal-table">
        <tbody>
          {cancelledRows(membership)}
          {nextChargeRow(membership)}
          <tr>
            <td>Join Date</td>
            <td>{formatDate(membership.get('join_date'))}</td>
          </tr>
          <tr>
            <td>Lifetime Days</td>
            <td>{membership.get('lifetime_days')}</td>
          </tr>
          <tr>
            <td>Lifetime Value</td>
            <td>
              {displayPrice(
                membership.getIn(['lifetime_value', 0]),
                membership.getIn(['lifetime_value', 1])
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default RelationsMenuMembership
