import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { NarrowContainer } from 'components/Grid'
import * as Yup from 'yup'
import { CREATOR_STATUS, EVENT_STATUS } from 'store/constants'
import { Helmet } from 'react-helmet-async'
import EventEditForm from 'components/EventEditForm'
import { asyncModal, getApiError, getEventUrl } from 'utils'
import { createEvent, useGetUserApi } from 'api'
import { useMount } from 'react-use'
import { useSelector } from 'react-redux'
import { getMe, isAuthenticated } from 'store/selectors'
import { URL_FRONT_PAGE, URL_LEAD_QUALIFICATION } from 'urls'
import {
  trackCreatorCreateEventComplete,
  trackCreatorCreateEventInit,
} from 'tracking'
import './SchedulePage.scss'
import Spinner from 'components/Spinner'
import EventHostHeader from 'components/EventHostHeader'
import HeaderProfile from 'components/HeaderProfile'
import {
  availabilityInitialValues,
  availabilityParseValues,
  availabilityValidations,
} from 'routes/UploadPage/availabilityUtils'
import {
  shouldPromptForPublish,
  updateEventVisibility,
  visibilityInitialValues,
  visibilityValidations,
} from 'components/VisibilityPanel/visibilityUtils'
import { useToasts } from 'react-toast-notifications'
import EventPublishModal from 'modals/EventPublishModal'
import EventVisibilityModal from 'modals/EventVisibilityModal'

const SchedulePageValidation = Yup.object().shape({
  title: Yup.string().required('Required'),
  slug: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
  ...availabilityValidations,
  ...visibilityValidations,
  duration: Yup.number().required('Required').typeError('Required'),
  startTime: Yup.date()
    .min(
      new Date(new Date().getTime() + 10 * 60000),
      'Must be at least 10 minutes from now'
    )
    .required('Required'),
})

const SchedulePage = () => {
  const history = useHistory()
  const hasAuth = useSelector((state) => isAuthenticated(state))
  const { addToast } = useToasts()
  const me = useSelector((state) => getMe(state))
  const { data: user } = useGetUserApi({
    userId: me.get('id'),
  })

  useMount(() => {
    if (!hasAuth) {
      history.push(URL_FRONT_PAGE)
    }
  })

  useEffect(() => {
    if (user != null) {
      if (user.get('creatorStatus') !== CREATOR_STATUS.CREATOR) {
        history.replace(URL_LEAD_QUALIFICATION)
      }
    }
  }, [user, history])

  useMount(() => {
    trackCreatorCreateEventInit()
  })

  const handleFormSubmit = async (values, { setSubmitting, setErrors }) => {
    let notifyFollowers
    if (shouldPromptForPublish(initialValues.visibility, values.visibility)) {
      const publishResult = await asyncModal(
        ({ active, onSubmit, onDismiss }) => (
          <EventPublishModal
            active={active}
            values={values}
            onClose={onDismiss}
            onPublish={onSubmit}
          />
        )
      )

      if (publishResult.error) {
        return null
      }

      notifyFollowers = publishResult.result
    }

    setSubmitting(true)
    const data = {
      title: values.title,
      slug: values.slug,
      startTime: values.startTime,
      duration: values.duration,
      ...availabilityParseValues(values),
      description: values.description,
      coverImageId: values.coverImageId,
      coverVideoUploadId: values.coverVideoUploadId,
      live: true,
      status: EVENT_STATUS.DRAFT,
      test: values.test,
      categoryIds: values.categoryIds,
    }
    createEvent({ data })
      .then(async (event) => {
        try {
          const updatedEvent = await updateEventVisibility(
            event.get('id'),
            initialValues.visibility,
            values.visibility,
            initialValues.publishAt,
            values.publishAt,
            notifyFollowers
          )

          if (updatedEvent) {
            asyncModal(({ active, onSubmit }) => (
              <EventVisibilityModal
                active={active}
                onClose={onSubmit}
                event={updatedEvent}
              />
            ))
          }
        } catch (e) {
          addToast(`Could change visibility on the video`, {
            appearance: 'error',
          })
        }

        setSubmitting(false)
        trackCreatorCreateEventComplete()
        history.replace(getEventUrl(event))
      })
      .catch((e) => {
        setSubmitting(false)
        setErrors(getApiError(e))
      })
  }

  if (user == null) {
    return (
      <>
        <HeaderProfile user={me} />
        <Spinner light />
      </>
    )
  }

  const initialValues = {
    title: '',
    slug: '',
    startTime: '',
    duration: '',
    ...availabilityInitialValues(null, me),
    description: '',
    coverImageFile: null,
    coverImageUrl: null,
    coverImageId: null,
    live: true,
    test: false,
    categoryIds: [],
    ...visibilityInitialValues(null),
  }

  return (
    <div className="SchedulePage">
      <HeaderProfile user={me} />
      <Helmet>
        <title>Create event | Boon</title>
      </Helmet>
      <EventHostHeader />
      <NarrowContainer>
        <div className="schedule-page-title">Create event</div>
        <EventEditForm
          initialValues={initialValues}
          validationSchema={SchedulePageValidation}
          onSubmit={handleFormSubmit}
        />
      </NarrowContainer>
    </div>
  )
}

export default SchedulePage
