import React, { useState } from 'react'
import './AccessSelector.scss'
import { ArrowDropDownSvg, LockOpenSvg, LockSvg } from 'assets/svg'
import AccessSelectorModal from 'components/AccessSelector/AccessSelectorModal'

const AccessSelector = ({
  title = 'Who can see this post?',
  products,
  productIds,
  setProductIds,
  publicValue,
  setPublic,
}) => {
  const [modalOpen, setModalOpen] = useState(false)

  const getSelectedText = () => {
    if (publicValue) {
      return 'Everybody'
    } else if (productIds.length === 0) {
      return 'All followers and members'
    } else {
      const filtered = products.filter((p) => productIds.includes(p.get('id')))
      return filtered.map((p) => p.get('name')).join(', ')
    }
  }

  const getSelectedIcon = () => {
    if (productIds.length === 0) {
      return LockOpenSvg
    } else {
      return LockSvg
    }
  }

  const Icon = getSelectedIcon()

  return (
    <div className="AccessSelector" onClick={() => setModalOpen(true)}>
      <AccessSelectorModal
        title={title}
        active={modalOpen}
        onClose={() => setModalOpen(false)}
        products={products}
        productIds={productIds}
        setProductIds={setProductIds}
        publicValue={publicValue}
        setPublic={setPublic}
      />

      <div className="access-selector-inner">
        <Icon className="access-selector-icon" />
        <div className="access-selector-text">{getSelectedText()}</div>
        <ArrowDropDownSvg className="access-selector-chevron" />
      </div>
    </div>
  )
}

export default AccessSelector
