import React from 'react'
import InsightsChartWidget from './InsightsChartWidget'
import { useGetApi } from 'api'

const InsightsConnectedChartWidget = ({
  url,
  dataMapper,
  title,
  timeScale,
  chartType,
  labels = null,
}) => {
  const { data, loading, error } = useGetApi({
    url,
    dataMapper,
  })

  return (
    <InsightsChartWidget
      title={title}
      timeScale={timeScale}
      loading={loading}
      error={error}
      data={data}
      chartType={chartType}
      labels={labels}
    />
  )
}

export default InsightsConnectedChartWidget
