import React from 'react'
import clsx from 'clsx'

import Avatar from 'components/Avatar'
import { getSearchMembersApi } from 'api'

export const mentionUsers = (hostId, me) => async (query, callback) => {
  if (!query) return

  const users = await getSearchMembersApi(
    {
      userId: hostId,
      query,
    },
    [me?.get('id')]
  )

  const data = users.toJS().map(({ id, name, profileImageUrl }) => ({
    id,
    display: name,
    img: profileImageUrl,
  }))
  callback(data)
}

export const renderMentionUserSuggestion = (divClassName, avatarClassName) => (
  { img, id },
  search,
  highlightedDisplay,
  index,
  focused
) => {
  return (
    <div key={index} className={clsx(divClassName, focused)}>
      <Avatar
        url={img}
        userId={id}
        width={24}
        height={24}
        className={avatarClassName}
      />
      {highlightedDisplay}
    </div>
  )
}
