import store from 'store'
import axios from 'axios'
import { isAccessTokenExpiringSoon, getRefreshToken } from 'store/selectors'
import { TOKEN_REFRESH, CLEAR_AUTH } from 'store/constants'
import { BACKEND_URL } from 'appConstants'

let ValidTokenLock = null
export const ensureValidToken = async () => {
  if (ValidTokenLock != null) {
    return await ValidTokenLock
  }

  ValidTokenLock = new Promise(async (resolve, reject) => {
    const state = store.getState()
    if (isAccessTokenExpiringSoon(state)) {
      // Refresh token
      try {
        const response = await axios({
          url: `${BACKEND_URL}/auth/refresh`,
          method: 'POST',
          data: {
            refreshToken: getRefreshToken(state),
          },
        })

        await store.dispatch({
          type: TOKEN_REFRESH,
          payload: response.data,
        })
      } catch (e) {
        await store.dispatch({
          type: CLEAR_AUTH,
        })
        return reject()
      }
    }
    return resolve()
  })
  try {
    await ValidTokenLock
    ValidTokenLock = null
  } catch (e) {
    ValidTokenLock = null
    throw new Error('Error during token refresh')
  }
}
