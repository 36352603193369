import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react'
import { fromJS, Map } from 'immutable'
import { cableConnect } from 'store/utils/cable'
import { useMount, usePrevious, useUnmount } from 'react-use'
import { getEventChatApi, getEventViewerCount } from 'api'
import ChatMessage, {
  createServiceChatMessage,
  ServiceChatMessage,
} from 'routes/WatchPage/components/ChatMessage'
import Chat from 'routes/WatchPage/components/Chat'
import clsx from 'clsx'
import { ChatSvg } from 'assets/svg'
import './NewEventChat.scss'
import { EVENT_PURCHASE_STATE, EVENT_TYPE } from 'store/constants'
import { hasAccessToEvent } from 'utils'
import * as Sentry from '@sentry/browser'

const DISPATCH_MESSAGES = {
  ADD: 'add',
  MERGE: 'merge',
  DELETE: 'delete',
}

const CONTROL_MESSAGE_TYPES = {
  EVENT_ENDED: 'EVENT_ENDED',
}

const CHAT_MESSAGE_TYPES = {
  NEW_COMMENT: 'NEW_COMMENT',
  NEW_COMMENT_REACTION: 'NEW_COMMENT_REACTION',
  DESTROY_COMMENT_REACTION: 'DESTROY_COMMENT_REACTION',
  SUBSCRIBED: 'VIEWER_SUBSCRIBED',
  HOST_SUBSCRIBED: 'HOST_VIEWER_SUBSCRIBED',
  HOST_UNSUBSCRIBED: 'HOST_VIEWER_UNSUBSCRIBED',
  USER_LIST_MERGE: 'USER_LIST_MERGE',
}

const addMessage = (message) => ({
  type: DISPATCH_MESSAGES.ADD,
  message,
})

const mergeMessages = (messages) => ({
  type: DISPATCH_MESSAGES.MERGE,
  messages,
})

const deleteMessage = (id) => ({
  type: DISPATCH_MESSAGES.DELETE,
  id,
})

const userSubscribed = (user) => ({
  type: CHAT_MESSAGE_TYPES.HOST_SUBSCRIBED,
  user,
})

const userUnsubscribed = (user) => ({
  type: CHAT_MESSAGE_TYPES.HOST_UNSUBSCRIBED,
  user,
})

const mergeUserList = (users) => ({
  type: CHAT_MESSAGE_TYPES.USER_LIST_MERGE,
  users,
})

const NewEventChat = ({
  event,
  me,
  updateViewerCount,
  onPurchase,
  onEventEnded,
  eventChatActive,
  switchToComments,
}) => {
  const eventId = event.get('id')
  const eventEnded = event.get('ended')
  const [connected, setConnected] = useState(false)
  const eventChatActiveRef = useRef(eventChatActive)
  const retryConnectionRef = useRef(true)
  const previousEventChatActive = usePrevious(eventChatActive)

  useEffect(() => {
    eventChatActiveRef.current = eventChatActive
  }, [eventChatActive])

  const [isLoadingPreviousMessages, setLoadingPreviousMessages] = useState(
    false
  )
  const [loading, setLoading] = useState(false)
  const [hasMore, setHasMore] = useState(false)
  const [paginationCreatedAt, setPaginationCreatedAt] = useState(null)
  const connectionRef = useRef(null)
  const subscriptionRef = useRef(null)
  const [messages, dispatchMessages] = useReducer((state, action) => {
    switch (action.type) {
      case DISPATCH_MESSAGES.ADD:
        if (state.size > 100) {
          const oldestKey = state
            .sortBy((m) => m.get('createdAt'))
            .keySeq()
            .first()
          setHasMore(true)
          setPaginationCreatedAt(state.getIn([oldestKey, 'createdAt']))
          return state
            .set(action.message.get('id'), action.message)
            .delete(oldestKey)
        } else {
          return state.set(action.message.get('id'), action.message)
        }

      case DISPATCH_MESSAGES.MERGE:
        return state.merge(action.messages.map((v) => [v.get('id'), v]))
      case DISPATCH_MESSAGES.DELETE:
        return state.delete(action.id)
      default:
        return state
    }
  }, Map())
  const [userList, dispatchUserList] = useReducer((state, action) => {
    switch (action.type) {
      case CHAT_MESSAGE_TYPES.HOST_SUBSCRIBED:
        return state.set(action.user.get('id'), action.user)
      case CHAT_MESSAGE_TYPES.USER_LIST_MERGE:
        return state.merge(
          action.users.map((v) => [v.get('user').get('id'), v])
        )
      case CHAT_MESSAGE_TYPES.HOST_UNSUBSCRIBED:
        return state.delete(action.user.get('id'))
      default:
        return state
    }
  }, Map())

  const onFocus = useCallback(() => {
    const hasAccess = hasAccessToEvent(event)
    if (
      !hasAccess &&
      event.get('purchaseState') === EVENT_PURCHASE_STATE.PURCHASABLE
    ) {
      onPurchase()
    }
  }, [event, onPurchase])

  const connect = async () => {
    const chatConnection = await cableConnect()

    chatConnection.subscriptions.create(
      {
        channel: 'ControlChannel',
        event_id: event.get('id'),
      },
      {
        received: (data) => {
          switch (data.type) {
            case CONTROL_MESSAGE_TYPES.EVENT_ENDED:
              onEventEnded()
              console.log('Event ended!')
              break
            default:
              console.log('Found unknown control event: ', data.type)
          }
        },
      }
    )

    const sub = chatConnection.subscriptions.create(
      {
        channel: 'ChatChannel',
        event_id: event.get('id'),
      },
      {
        connected: () => {
          console.log('Connected')
          setConnected(true)
          connectionRef.current = chatConnection
          subscriptionRef.current = sub
          loadPreviousMessages()
          if (event.get('userType') === EVENT_TYPE.HOST) {
            loadViewerCount()
          }
        },
        disconnected: (e) => {
          console.log('Disconnected', e)
          setConnected(false)
        },
        rejected: () => {
          console.log('We were rejected')
          setConnected(false)
          setTimeout(() => retryConnection(), 5000)
        },
        received: (data) => {
          switch (data.type) {
            case CHAT_MESSAGE_TYPES.NEW_COMMENT:
            case CHAT_MESSAGE_TYPES.NEW_COMMENT_REACTION:
            case CHAT_MESSAGE_TYPES.DESTROY_COMMENT_REACTION:
              dispatchMessages(addMessage(fromJS(data.message)))
              break

            case CHAT_MESSAGE_TYPES.SUBSCRIBED:
              if (eventChatActiveRef.current) {
                dispatchMessages(
                  addMessage(
                    createServiceChatMessage({
                      id: `joined_${data.message.user.id}`,
                      message: `${data.message.user.name} has joined the chat`,
                      closeable: true,
                    })
                  )
                )
              }
              break
            case CHAT_MESSAGE_TYPES.HOST_SUBSCRIBED:
              dispatchUserList(userSubscribed(fromJS(data.message.user)))
              break
            case CHAT_MESSAGE_TYPES.HOST_UNSUBSCRIBED:
              dispatchUserList(userUnsubscribed(fromJS(data.message.user)))
              break
            default:
              return null
          }
        },
      }
    )
  }

  const loadPreviousMessages = () => {
    setLoadingPreviousMessages(true)
    getEventChatApi({ id: event.get('id') })
      .then((data) => {
        dispatchMessages(mergeMessages(data.get('chatMessages')))
        setHasMore(
          data.getIn(['meta', 'count']) === data.getIn(['meta', 'items']) &&
            data.getIn(['meta', 'count']) > 0
        )
        setPaginationCreatedAt(data.getIn(['meta', 'createdAt']))
      })
      .catch((e) => console.error("Couldn't load the chat", e))
      .then(() => setLoadingPreviousMessages(false))
  }

  const loadMore = useCallback(async () => {
    setLoading(true)
    let data
    try {
      data = await getEventChatApi({
        id: eventId,
        createdAt: paginationCreatedAt,
      })
    } catch (e) {
      setLoading(false)
      return null
    }
    setLoading(false)

    dispatchMessages(mergeMessages(data.get('chatMessages')))
    setHasMore(
      data.getIn(['meta', 'count']) === data.getIn(['meta', 'items']) &&
        data.getIn(['meta', 'count']) !== 0
    )
    setPaginationCreatedAt(data.getIn(['meta', 'createdAt']))
  }, [eventId, paginationCreatedAt])

  const loadViewerCount = () => {
    getEventViewerCount({ id: event.get('id') })
      .then((chatUsers) => {
        dispatchUserList(mergeUserList(chatUsers))
      })
      .catch((e) => console.error("Couldn't load viewer count", e))
  }

  const sendMessage = async (text) => {
    if (subscriptionRef.current) {
      try {
        await subscriptionRef.current.perform('speak', {
          message: text,
          event_id: event.get('id'),
        })
        return true
      } catch (e) {
        console.error(e)
        Sentry.captureException(e)
        Sentry.captureException(new Error('Caught chat send message error'))
        return false
      }
    } else {
      return false
    }
  }

  useEffect(() => {
    if (event.get('userType') === EVENT_TYPE.HOST) {
      updateViewerCount(userList.size)
    }
  }, [event, userList, updateViewerCount])

  useMount(() => {
    connect()
  })

  useUnmount(() => {
    retryConnectionRef.current = false
    if (connectionRef.current != null) {
      connectionRef.current.disconnect()
    }
  })

  const retryConnection = () => {
    if (!retryConnectionRef.current) {
      return
    }

    if (connectionRef.current != null) {
      connectionRef.current.disconnect()
    }
    connect()
  }

  useEffect(() => {
    if (connectionRef.current != null) {
      connectionRef.current.disconnect()
      connect()
    }
  }, [me.get('id')]) // eslint-disable-line react-hooks/exhaustive-deps

  const eventUserType = event.get('userType')
  useEffect(() => {
    if (eventChatActive) {
      if (eventUserType === EVENT_TYPE.HOST) {
        dispatchMessages(
          addMessage(
            createServiceChatMessage({
              id: 'status-message',
              message:
                'This is the chat. We suggest you kickstart the conversation by writing a short message. Say hello to the people who bought access.',
              closeable: true,
              createdAt: '0000',
            })
          )
        )
      } else {
        dispatchMessages(
          addMessage(
            createServiceChatMessage({
              id: 'status-message',
              message:
                'Welcome! Feel free to ask questions and take part in the conversation. Remember to be kind and keep a good tone. Enjoy!',
              closeable: true,
              createdAt: '0000',
            })
          )
        )
      }
    } else {
      if (previousEventChatActive || eventEnded) {
        // Open -> Closed
        dispatchMessages(
          addMessage(
            createServiceChatMessage({
              id: 'status-message',
              message:
                'The live event has ended. Continue the conversation in the comments.',
              actionText: 'Go to comments',
              onAction: switchToComments,
              closeable: true,
            })
          )
        )
      } else {
        // Closed -> Closed
        dispatchMessages(
          addMessage(
            createServiceChatMessage({
              id: 'status-message',
              message:
                'The live chat is available 15 minutes before the event start.',
              closeable: true,
              createdAt: '0000',
            })
          )
        )
      }
    }
  }, [
    eventChatActive,
    previousEventChatActive,
    eventUserType,
    eventEnded,
    switchToComments,
  ])

  const renderConversation = () => {
    return messages
      .sortBy((m) => m.get('createdAt'))
      .valueSeq()
      .map((message) => {
        if (message.get('service') === true) {
          return (
            <ServiceChatMessage
              key={message.get('id')}
              message={message}
              onClose={() => dispatchMessages(deleteMessage(message.get('id')))}
            />
          )
        } else {
          return (
            <ChatMessage
              message={message}
              //me={message.getIn(['user', 'id']) === me.get('id')}
              key={message.get('id')}
              actionText="Action"
              hasReactions
            />
          )
        }
      })
  }

  const noAccess = !hasAccessToEvent(event)
  const renderEmptyPlaceholder = () => {
    if (
      connected &&
      !isLoadingPreviousMessages &&
      messages.size === 0 &&
      !noAccess
    ) {
      return (
        <div className="new-event-chat-empty">
          <ChatSvg />
          <div>No comments yet</div>
        </div>
      )
    }

    return null
  }

  return (
    <div className={clsx('NewEventChat', { 'no-access': noAccess })}>
      <Chat
        messages={renderConversation()}
        connecting={!connected}
        loadingPreviousMessages={isLoadingPreviousMessages}
        onMessageSend={sendMessage}
        onFocus={onFocus}
        hideCompose={!eventChatActive}
        me={me}
        hostId={event.getIn(['host', 'id'])}
        className={clsx({
          host: me && me.get('id') === event.getIn(['host', 'id']),
        })}
      >
        {hasMore && (
          <button
            className={clsx(
              'button secondary light small load new-event-chat-load-more',
              { loading: loading }
            )}
            disabled={loading}
            onClick={loadMore}
          >
            Load more
          </button>
        )}
      </Chat>
      {renderEmptyPlaceholder()}
    </div>
  )
}

export default NewEventChat
