import React from 'react'
import './PostComposeModalAttachmentPoll.scss'
import PollForm from 'components/PollForm'

const PostComposeModalAttachmentPoll = ({ removeAttachment }) => {
  return (
    <div className="PostComposeModalAttachmentPoll">
      <PollForm removeAttachment={removeAttachment} />
    </div>
  )
}

export default PostComposeModalAttachmentPoll
