import React, { useCallback } from 'react'
import PostComposeModalAttachmentPhoto from './PostComposeModalAttachmentPhoto'
import { useFormikContext } from 'formik'
import { POST_ATTACHMENT_TYPES } from 'store/constants/post'
import PostComposeModalAttachmentDocument from 'components/PostCompose/PostComposeModalAttachmentDocument'
import PostComposeModalAttachmentPoll from 'components/PostCompose/PostComposeModalAttachmentPoll'

const PostComposeModalAttachment = ({ isEdit }) => {
  const { values, setFieldValue } = useFormikContext()

  const removeAttachment = useCallback(() => {
    setFieldValue('attachmentType', null)
    setFieldValue('attachmentId', null)

    setFieldValue('attachmentPhotoUrl', null)
  }, [setFieldValue])

  const renderAttachment = () => {
    switch (values.attachmentType) {
      case POST_ATTACHMENT_TYPES.PHOTO:
        return (
          <PostComposeModalAttachmentPhoto
            removeAttachment={removeAttachment}
          />
        )
      case POST_ATTACHMENT_TYPES.DOCUMENT:
        return (
          <PostComposeModalAttachmentDocument
            removeAttachment={removeAttachment}
          />
        )
      case POST_ATTACHMENT_TYPES.POLL:
        if (isEdit) {
          return (
            <div className="post-compose-modal-attachment-error">
              A poll cannot be edited once posted
            </div>
          )
        }

        return (
          <PostComposeModalAttachmentPoll removeAttachment={removeAttachment} />
        )
      default:
        return null
    }
  }

  return (
    <>
      {/*<PostComposeModalAttachmentHeader removeAttachment={removeAttachment} />*/}
      {renderAttachment()}
    </>
  )
}

export default PostComposeModalAttachment
