import React, { useState, useRef } from 'react'
import './DashboardCreateButton.scss'
import { Link } from 'react-router-dom'
import { URL_SCHEDULE, URL_UPLOAD } from 'urls'
import { VideoSvg, LiveSvg } from 'assets/svg'
import { useOutsideAlerter } from 'utils'
import clsx from 'clsx'

const DashboardCreateButton = React.memo(({ className, children }) => {
  const [open, setOpen] = useState(false)
  const wrapperRef = useRef()
  useOutsideAlerter(wrapperRef, open, () => {
    setOpen(false)
  })

  return (
    <div className={clsx('DashboardCreateButton', className)} ref={wrapperRef}>
      <button onClick={() => setOpen(!open)} className="button secondary">
        {children}
      </button>
      <div
        className={clsx('dashboard-create-button-menu', { open })}
        onClick={() => setOpen(false)}
      >
        <Link to={URL_UPLOAD} className="dashboard-create-button-menu-item">
          <VideoSvg /> Upload video
        </Link>
        <Link to={URL_SCHEDULE} className="dashboard-create-button-menu-item">
          <LiveSvg /> Schedule live
        </Link>
      </div>
    </div>
  )
})

export default DashboardCreateButton
