import React, { useCallback, useState } from 'react'
import './PostCardEngagement.scss'
import Avatar from 'components/Avatar'
import PostLikesModal from 'modals/PostLikesModal'
import clsx from 'clsx'

const PostCardEngagement = ({ post, specific }) => {
  const [modalActive, setModalActive] = useState(false)
  const onModalClose = useCallback(() => {
    setModalActive(false)
  }, [setModalActive])

  const postId = post.get('id')
  const likesCount = post.get('likesCount')
  const likedBy = post.get('likedBy')
  if (likesCount === 0 || likedBy == null || likedBy.size === 0) {
    return null
  }

  const renderLikedBy = () => {
    const profileImages = likedBy
      .reverse()
      .map((user) => (
        <Avatar
          height={24}
          width={24}
          className="post-card-engagement-users-avatar"
          url={user.get('profileImageUrl')}
          userId={user.get('id')}
          key={user.get('id')}
        />
      ))

    return <div className="post-card-engagement-users">{profileImages}</div>
  }

  const highlightedName = post.getIn(['likedBy', 0, 'name'])
  const message =
    likesCount === 1 ? (
      <div>
        Liked by <span>{highlightedName}</span>
      </div>
    ) : (
      <div>
        Liked by <span>{highlightedName}</span> and{' '}
        <span>
          {likesCount - 1} {likesCount - 1 > 1 ? 'others' : 'other'}
        </span>
      </div>
    )
  return (
    <div className={clsx('PostCardEngagement', { specific })}>
      <PostLikesModal
        postId={postId}
        active={modalActive}
        onClose={onModalClose}
      />
      <div
        className="post-card-engagement-wrapper"
        onClick={() => setModalActive(true)}
      >
        {renderLikedBy()}
        <div className="post-card-engagement-message">{message}</div>
      </div>
    </div>
  )
}

export default PostCardEngagement
