// Must be a function not an arrow function
export const yupTestPriceCurrency = [
  'priceCurrency',
  'Price too low',
  function () {
    const { price, currency } = this.parent

    if (price != null && currency != null) {
      // https://stripe.com/docs/currencies#minimum-and-maximum-charge-amounts
      const minValues = {
        DKK: 2.5,
        SEK: 3.0,
        NOK: 3.0,
        USD: 0.5,
        GBP: 0.3,
        EUR: 0.5,
      }

      const minValue = minValues[currency.toUpperCase()]

      if (minValue != null) {
        if (price < minValue) {
          return this.createError({
            message: `A minimum price of ${currency.toUpperCase()} ${minValue} is required`,
          })
        }
      }
    }

    return true
  },
]
