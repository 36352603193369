import React, { useState, useEffect, useRef } from 'react'

import { useSelector } from 'react-redux'

import { capitalize } from 'utils'
import {
  SettingsSvg,
  CheckBoxCheckedSvg,
  CheckBoxUncheckedSvg,
} from 'assets/svg'

import clsx from 'clsx'

const InsightsTableColumnSelector = ({ headers, setHeaders }) => {
  const browser = useSelector((state) => state.get('browser'))
  const wrapperRef = useRef(null)

  let [menuOpen, setMenuOpen] = useState(false)

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setMenuOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  })

  const handleCheck = (key) => {
    headers = headers.slice()

    let updatedHeaderIdx = headers.findIndex(
      ({ key: innerKey }) => innerKey === key
    )

    if (updatedHeaderIdx === 0) {
      return
    }

    if (browser.greaterThan.md) {
      const updatedHeader = {
        ...headers[updatedHeaderIdx],
        active: !headers[updatedHeaderIdx].active,
      }

      headers[updatedHeaderIdx] = updatedHeader
    } else {
      headers = headers.map((header) => {
        return { ...header, active: false }
      })
      headers[0].active = true
      headers[updatedHeaderIdx].active = true
    }

    setHeaders(headers)
  }

  const headerOption = ({ key, name }, disabled) => {
    const checked = headers.find(({ key: innerKey }) => innerKey === key).active
    const Check = checked ? CheckBoxCheckedSvg : CheckBoxUncheckedSvg

    return (
      <li
        key={key}
        className={clsx('insights-table-widget-selector-list-item', {
          disabled,
        })}
      >
        <Check
          className="insights-table-widget-selector-checkbox"
          onClick={() => handleCheck(key)}
        />
        <input
          type="checkbox"
          id={key}
          name={key}
          checked={checked}
          onChange={() => handleCheck(key)}
        />
        <label htmlFor={key}>
          {name === undefined ? capitalize(key) : capitalize(name)}
        </label>
      </li>
    )
  }

  const renderMenu = () => {
    if (!menuOpen) {
      return null
    }

    return (
      <div className="insights-table-widget-selector-menu">
        <strong>Choose Columns</strong>
        <ul className="insights-table-widget-selector-list">
          {headers.map((header, idx) => headerOption(header, idx === 0))}
        </ul>
      </div>
    )
  }

  const renderButton = () => {
    return (
      <SettingsSvg
        onClick={() => setMenuOpen(!menuOpen)}
        className="insights-table-widget-column-selector"
      />
    )
  }

  return (
    <span className="insights-table-widget-selector" ref={wrapperRef}>
      {renderButton()}
      {renderMenu()}
    </span>
  )
}

export default InsightsTableColumnSelector
