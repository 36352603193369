import React, { useMemo, useState } from 'react'
import './EventCountdown.scss'
import { DateTime } from 'luxon'
import Countdown from './Countdown'
import InfoTooltip from 'components/InfoTooltip'
import StaticNoise from 'assets/img/static-noise.gif'
import { useInterval } from 'utils'

const EventCountdown = ({
  event,
  short = false,
  hasStatic = true,
  noDate = false,
}) => {
  const eventStartTime = event.get('startTime')
  const dateTime = useMemo(() => DateTime.fromISO(eventStartTime), [
    eventStartTime,
  ])
  const getTimeLeft = () => dateTime.diffNow('seconds').seconds > 0
  const [timeLeft, setTimeLeft] = useState(getTimeLeft())

  useInterval(() => {
    setTimeLeft(getTimeLeft())
  }, [1000])

  const renderTime = () => {
    if (noDate) {
      return null
    }

    if (short) {
      return (
        <div className="event-countdown-time">
          {dateTime.toFormat('MMM d, H:mm')}
        </div>
      )
    } else {
      return (
        <div className="event-countdown-time">
          {dateTime.toFormat('cccc, MMM d, H:mm')}
          <span className="event-countdown-time-duration">
            ({event.get('duration')} min)
          </span>
          <InfoTooltip left>
            {dateTime.toFormat('H:mm')} {DateTime.local().offsetNameShort}
            <br />
            {DateTime.local().zoneName}
          </InfoTooltip>
        </div>
      )
    }
  }

  const renderCountdown = () => {
    if (!timeLeft) {
      return (
        <div className="event-countdown-live">
          <div className="event-countdown-live-dots">
            <div />
            <div />
            <div />
          </div>
          Live now
        </div>
      )
    } else {
      return <Countdown startTime={event.get('startTime')} />
    }
  }

  return (
    <div className="EventCountdown">
      {hasStatic && (
        <div
          className="event-countdown-static"
          style={{ backgroundImage: `url(${StaticNoise})` }}
        />
      )}
      {renderTime()}
      <div className="event-countdown-countdown">{renderCountdown()}</div>
    </div>
  )
}

export default EventCountdown
