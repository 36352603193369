import React from 'react'

const PlaySvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    viewBox="0 0 48 48"
    {...props}
  >
    <path d="M-838-2232H562v3600H-838z" fill="none" />
    <path d="M16 10v28l22-14z" />
    <path d="M0 0h48v48H0z" fill="none" />
  </svg>
)

export default PlaySvg
