import React, { useEffect } from 'react'
import './PinnedPosts.scss'
import PostCard from 'components/PostCard'

import { postReducerMerge } from 'components/ProfileFeed'

import { getPinnedPosts } from 'api'

const PinnedPosts = ({
  user,
  posts,
  filters,
  dispatchPosts,
  onPostDeleted,
  onPostUpdated,
}) => {
  useEffect(() => {
    const run = async () => {
      try {
        const data = await getPinnedPosts({ userId: user.get('id') }, [
          user.get('id'),
          dispatchPosts,
        ])

        dispatchPosts(postReducerMerge(data.get('posts')))
      } catch (e) {
        return null
      }
    }

    run()
  }, [user, dispatchPosts])

  const { product_id } = filters

  const pinnedPosts = posts
    .filter((post) => {
      return (
        post.get('pinned') &&
        (!product_id || post.get('productIds').includes(product_id))
      )
    })
    .toList()

  if (pinnedPosts.size === 0) {
    return null
  }

  return (
    <div className="PinnedPosts">
      <div className="pinned-title">Pinned</div>
      {pinnedPosts.map((post) => (
        <PostCard
          key={post.get('id')}
          post={post}
          hostId={user.get('id')}
          dispatchPosts={dispatchPosts}
          onPostDeleted={onPostDeleted}
          onPostUpdated={onPostUpdated}
        />
      ))}
    </div>
  )
}

export default PinnedPosts
