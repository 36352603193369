import React, { useState } from 'react'
import InsightsTableWidget from './InsightsTableWidget'
import { useGetApi } from 'api'
import queryString from 'query-string'

const FIELDS_TO_CONSIDER_WHEN_SORTING = 2

const InsightsConnectedTableWidget = ({
  baseUrl,
  dataMapper,
  dataKey,
  headers,
  title,
  timeScale = '',
  defaultSortBy = 'created_at asc',
  defaultPage = 1,
  defaultItems = 10,
  defaultFilters = null,
  clickableRow = null,
  optionsMenu = null,
}) => {
  const [sortBy, setSortBy] = useState(defaultSortBy)
  const [page, setPage] = useState(defaultPage)
  const [items] = useState(defaultItems)
  const [query, setQuery] = useState(null)
  const [filters, setFilters] = useState(defaultFilters)
  const changeSortBy = (newSortBy) => {
    let newSortByKey = newSortBy.split(' ')[0]
    let sortByArray = [newSortBy].concat(
      sortBy
        .split(',')
        .filter(
          (elem) =>
            !(elem === `${newSortByKey} asc` || elem === `${newSortByKey} desc`)
        )
    )

    while (sortByArray.length > FIELDS_TO_CONSIDER_WHEN_SORTING) {
      sortByArray.pop()
    }

    setSortBy(sortByArray.join(','))
    setPage(1)
  }

  let activeFilters =
    filters &&
    filters
      .filter(({ active }) => active)
      .map((j) => (j.key !== undefined ? j.key : j.value.toLowerCase()))
      .join(',')

  const url = (format = null) => {
    let urlObject = {
      filters: activeFilters,
      search: query,
      items: items,
      sort: sortBy,
      page: page,
    }
    let urlObjectCsv = {
      filters: activeFilters,
      search: query,
      sort: sortBy,
    }

    return format === null
      ? `${baseUrl}?${queryString.stringify(urlObject)}`
      : `${baseUrl}.${format}?${queryString.stringify(urlObjectCsv)}`
  }
  const { data, loading, error } = useGetApi({
    url: url(),
    dataMapper,
  })

  return (
    <InsightsTableWidget
      title={title}
      timeScale={timeScale}
      headers={headers}
      data={data ? data[dataKey] : []}
      page={page}
      totalPages={data ? data['meta']['pages'] : 0}
      totalResults={data ? data['meta']['count'] : 0}
      sortBy={sortBy}
      changeSortBy={changeSortBy}
      changePage={setPage}
      onSearch={setQuery}
      filters={filters}
      setFilters={setFilters}
      loading={loading}
      error={error}
      clickableRow={clickableRow}
      optionsMenu={optionsMenu}
      csvUrl={url('csv')}
    />
  )
}

export default InsightsConnectedTableWidget
