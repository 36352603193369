import { combineReducers } from 'redux-immutable'
import auth from './auth'
import browser from './browser'
import ui from './ui'

export default combineReducers({
  auth,
  browser,
  ui,
})
