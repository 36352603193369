import React, { useCallback, useState } from 'react'
import './UpcomingLiveEvent.scss'

import { DateTime } from 'luxon'
import { BackgroundImage } from 'components/Image'
import { ArrowRightSvg } from 'assets/svg'
import clsx from 'clsx'
import EventPageModal from 'modals/EventPageModal'

const UpcomingLiveEvent = ({ event }) => {
  const [showEventModal, setShowEventModal] = useState(false)
  const datetime = DateTime.fromISO(event.get('startTime'))
  const live =
    event.get('live') &&
    event.get('endTime') === null &&
    datetime < DateTime.local()
  const handleClick = () => setShowEventModal(true)
  const onCloseEventModal = useCallback(() => {
    setShowEventModal(false)
  }, [setShowEventModal])

  const renderDate = () => {
    return (
      <div className="upcoming-live-date-container">
        <div className="upcoming-live-date-month">{datetime.monthShort}</div>
        <div className="upcoming-live-date-day">{datetime.day}</div>
      </div>
    )
  }

  const renderContent = () => {
    return (
      <>
        <div className="upcoming-live-content">
          <div className="upcoming-live-title">{event.get('title')}</div>
          <span className={clsx('upcoming-live-status', { live })}>
            {live ? 'Live Now' : 'Live'}
          </span>{' '}
          <span className="upcoming-live-datetime">
            {datetime.toFormat('ccc HH:mm')}
          </span>
          {' ‧ '}
          <span className="upcoming-live-duration">
            {event.get('duration')} min
          </span>
          {event.get('isFree') === true && (
            <span className="free-badge">Free</span>
          )}
        </div>
        <div className="upcoming-live-cover-image-container">
          <BackgroundImage
            className="upcoming-live-cover-image"
            src={event.get('coverImageUrl')}
          />
        </div>
        <ArrowRightSvg className="upcoming-live-link" />
      </>
    )
  }

  return (
    <>
      {showEventModal && (
        <EventPageModal
          onClose={onCloseEventModal}
          userSlug={
            event.getIn(['host', 'username']) || event.getIn(['host', 'id'])
          }
          eventSlug={event.get('slug') || event.get('id')}
        />
      )}
      <div className="UpcomingLiveEvent" onClick={handleClick}>
        {renderDate()}
        {renderContent()}
      </div>
    </>
  )
}

export default UpcomingLiveEvent
