import React, { useCallback, useRef } from 'react'
import './ProfileFeed.scss'
import clsx from 'clsx'
import PostCard from 'components/PostCard'
import EmptyList from 'components/EmptyList'
import PostCompose from 'components/PostCompose'
import UpcomingLiveEventsContainer from 'components/UpcomingLiveEventsContainer'
import PinnedPosts from 'components/PinnedPosts'

const ProfileFeed = ({
  user,
  showCompose,
  posts,
  onPostDeleted,
  onPostUpdated,
  dispatchPosts,
  filters,
  dispatchFilter,
  orderPostsBy,
  setOrderPostsBy,
}) => {
  const userId = user.get('id')
  const titleRef = useRef()

  const onPostCreated = useCallback(() => {
    try {
      if (titleRef.current != null) {
        const elementPosition = titleRef.current.getBoundingClientRect().top

        window.scrollTo({
          top: elementPosition - 75,
          behavior: 'smooth',
        })
      }
    } catch (e) {
      // Pass
    }
  }, [titleRef])

  // Rendering
  const renderPosts = () => {
    let components = posts
      .filter((post) => !post.get('pinned'))
      .toList()
      .sortBy((t) => t.get('lastActivityAt'))
      .reverse()
      .map((post) => {
        return (
          <PostCard
            key={post.get('id')}
            post={post}
            hostId={userId}
            dispatchPosts={dispatchPosts}
            onPostDeleted={onPostDeleted}
            onPostUpdated={onPostUpdated}
          />
        )
      })

    if (components.size === 0) {
      return <EmptyList filters={filters} dispatchFilter={dispatchFilter} />
    }

    return <>{components}</>
  }

  return (
    <div className={clsx('ProfileFeed')}>
      <UpcomingLiveEventsContainer user={user} filters={filters} />

      <PinnedPosts
        user={user}
        posts={posts}
        filters={filters}
        dispatchPosts={dispatchPosts}
        onPostDeleted={onPostDeleted}
        onPostUpdated={onPostUpdated}
      />

      <div className="profile-feed-title" ref={titleRef}>
        <div className="profile-feed-post-sorting">
          <span
            className={clsx({ active: orderPostsBy === 'created_at' })}
            onClick={() => setOrderPostsBy('created_at')}
          >
            Latest
          </span>
          <span
            className={clsx({ active: orderPostsBy === 'last_activity_at' })}
            onClick={() => setOrderPostsBy('last_activity_at')}
          >
            Popular
          </span>
        </div>

        {showCompose && (
          <PostCompose
            user={user}
            dispatchPosts={dispatchPosts}
            onPostCreated={onPostCreated}
            defaultProductId={filters.product_id}
            categoryId={filters.category_id}
          />
        )}
      </div>

      <div className="profile-feed-list">{renderPosts()}</div>
    </div>
  )
}

export default ProfileFeed
