import React, { useState, useEffect, useRef } from 'react'
import { InfoOutlineSvg } from 'assets/svg'
import './InfoTooltip.scss'
import clsx from 'clsx'

const InfoTooltip = ({ children, left }) => {
  const wrapperRef = useRef(null)
  const [isOpen, setOpen] = useState(false)

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  })

  return (
    <div
      className={clsx('InfoTooltip', { open: isOpen, left })}
      ref={wrapperRef}
    >
      <InfoOutlineSvg
        className="info-tooltip-icon"
        onClick={() => setOpen(!isOpen)}
      />
      <div className="info-tooltip-box">{children}</div>
    </div>
  )
}

export default InfoTooltip
