const Currencies = [
  'dkk',
  'nok',
  'sek',
  'usd',
  'cad',
  'gbp',
  'eur',
  'jpy',
  'chf',
]

export default Currencies
