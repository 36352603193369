import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useReducer,
  useRef,
} from 'react'
import './UserPost.scss'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getMe } from 'store/selectors'
import { useGetPostApi } from 'api'
import {
  postReducer,
  postReducerMerge,
  setPostAttributes,
} from 'components/ProfileFeed'
import { fromJS, List, Map } from 'immutable'
import { cableConnect } from 'store/utils/cable'
import Spinner from 'components/Spinner'
import PostCard from 'components/PostCard/PostCard'
import { getUserUrl } from 'utils'
import { POST_COMMENT_ACCESS } from 'store/constants/post'

const UserPost = ({ user }) => {
  const {
    params: { postId, commentId },
  } = useRouteMatch()
  const history = useHistory()
  const userUrl = getUserUrl(user)
  const socketConnectionRef = useRef(null)
  const me = useSelector((state) => getMe(state))
  const meId = me.get('id')
  const { data: postData, error: postError } = useGetPostApi({
    postId,
  })
  const [posts, dispatchPosts] = useReducer(postReducer, Map())
  const post = posts.get(postId)
  const commentAccess =
    post && post.get('commentsAccess') !== POST_COMMENT_ACCESS.NONE

  useEffect(() => {
    if (postData != null) {
      dispatchPosts(postReducerMerge(List([postData])))
    }
  }, [postData])

  const postLoaded = post != null
  useLayoutEffect(() => {
    if (postLoaded && commentId != null) {
      try {
        const element = document.querySelector(`#comment-${commentId}`)
        const yOffset = -80
        const y =
          element.getBoundingClientRect().top + window.pageYOffset + yOffset
        window.scrollTo({ top: y, behavior: 'smooth' })
        element.setAttribute('highlight', true)
      } catch (e) {
        // Pass
      }
    }
  }, [postLoaded, commentId])

  useEffect(() => {
    const run = async () => {
      // Connect to socket
      socketConnectionRef.current = await cableConnect()
      socketConnectionRef.current.subscriptions.create(
        {
          channel: 'PostChannel',
          post_id: postId,
        },
        {
          rejected: () => {
            console.log('We were rejected')
          },
          received: (data) => {
            dispatchPosts(fromJS(data))
          },
        }
      )
    }

    if (postId) {
      run()
    }

    return () => {
      if (socketConnectionRef.current != null) {
        socketConnectionRef.current.disconnect()
      }
    }
  }, [meId, postId])

  const onPostDeleted = useCallback(() => {
    history.replace(userUrl)
  }, [history, userUrl])

  const onPostUpdated = useCallback(
    ({ postId, post }) => {
      dispatchPosts(setPostAttributes({ postId, post }))
    },
    [dispatchPosts]
  )

  const renderPost = () => {
    if (postError != null) {
      return (
        <div className="no-post">
          Could not find post. <br /> It has probably been deleted
        </div>
      )
    }

    if (post == null) {
      return <Spinner light small />
    }

    return (
      <PostCard
        post={post}
        dispatchPosts={dispatchPosts}
        hostId={user.get('id')}
        onPostDeleted={onPostDeleted}
        onPostUpdated={onPostUpdated}
        unpackReplyId={commentId}
        defaultShowCommentCompose={commentAccess}
        noReason
      />
    )
  }

  return (
    <div className="UserPost">
      <div className="user-page-sub-container">
        <div className="user-page-sub-content">{renderPost()}</div>
      </div>
    </div>
  )
}

export default UserPost
