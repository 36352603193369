import React, { useCallback, useEffect, useReducer, useState } from 'react'
import './PayoutDetail.scss'
import Spinner from 'components/Spinner'
import { DateTime } from 'luxon'
import {
  getPayoutInvoicePdf,
  useGetCompanyApi,
  useGetPayoutApi,
  getBalanceTransactionsApi,
} from 'api'
import { CloudDownloadSvg } from 'assets/svg'
import BalanceTransactionList from './BalanceTransactionList'
import { useHistory, useLocation } from 'react-router-dom'
import { URL_SETTINGS_BUSINESS_LEGACY } from 'urls'
import BalanceTransactionSum from './BalanceTransactionSum'
import LoadingError from 'components/LoadingError'
import { useInfiniteScrollBody, getApiError } from 'utils'
import { useAsync } from 'react-use'
import { Map } from 'immutable'

const balanceTransactionReducer = (state, transactions) => {
  return state.merge(transactions.map((v) => [v.get('id'), v]))
}

const PayoutDetail = ({ payoutId }) => {
  const [hasMore, setHasMore] = useState(false)
  const [paginationLastCreatedAt, setPaginationLastCreatedAt] = useState(null)
  const [error, setError] = useState(null)
  const [balanceTransactions, setBalanceTransactions] = useReducer(
    balanceTransactionReducer,
    Map()
  )
  const [page, bottomRef] = useInfiniteScrollBody({ hasMore })

  const history = useHistory()
  const location = useLocation()
  const {
    data: payout,
    loading,
    error: payoutError,
    refresh: payoutRefresh,
  } = useGetPayoutApi({ payoutId }, [payoutId])

  useEffect(() => {
    const run = async () => {
      // Fetch initial data
      setError(null)
      try {
        const data = await getBalanceTransactionsApi({
          payoutId,
          params: {},
        })
        setBalanceTransactions(data.get('balanceTransactions'))

        setHasMore(
          data.getIn(['meta', 'count']) === data.getIn(['meta', 'items']) &&
            data.getIn(['meta', 'count']) > 0
        )
        setPaginationLastCreatedAt(data.getIn(['meta', 'createdAt']))
      } catch (e) {
        setError(getApiError(e))
        return null
      }
    }

    setBalanceTransactions([])
    run()
  }, [payoutId])

  const { data: company, companyLoading } = useGetCompanyApi()
  const [
    downloadingPayoutInvoicePdf,
    setDownloadingPayoutInvoicePdf,
  ] = useState(false)

  const loadMore = useCallback(async () => {
    setError(null)

    let data
    try {
      data = await getBalanceTransactionsApi({
        payoutId,
        params: {
          created_at: paginationLastCreatedAt,
        },
      })
    } catch (e) {
      setError(getApiError(e))
      return null
    }

    setBalanceTransactions(data.get('balanceTransactions'))
    setHasMore(
      data.getIn(['meta', 'count']) === data.getIn(['meta', 'items']) &&
        data.getIn(['meta', 'count']) !== 0
    )
    setPaginationLastCreatedAt(data.getIn(['meta', 'createdAt']))
  }, [paginationLastCreatedAt, payoutId])

  useAsync(async () => {
    if (page === 0) {
      return null
    }

    loadMore()
  }, [page])

  if (payoutError != null) {
    return <LoadingError refresh={payoutRefresh} />
  }

  if (payout == null || loading || companyLoading) {
    return <Spinner />
  }

  const downloadPayoutInvoicePdf = async () => {
    if (company == null) {
      if (
        window.confirm(
          'To download invoices, you must first enter some additional information. Would you like to do that now?'
        )
      ) {
        history.push(
          `${URL_SETTINGS_BUSINESS_LEGACY}?redirect_url=${location.pathname}#businessInformation`
        )
      }
      return null
    }

    setDownloadingPayoutInvoicePdf(true)
    try {
      const data = await getPayoutInvoicePdf({ payoutId })
      const src = URL.createObjectURL(data)
      const link = document.createElement('a')
      link.href = src
      link.target = '_blank'
      link.setAttribute('download', `${payoutId}.pdf`)
      document.body.appendChild(link)
      link.click()
    } catch (e) {
      alert(
        'We encountered an error while preparing the invoice PDF, please contact support'
      )
    }
    setDownloadingPayoutInvoicePdf(false)
  }

  const renderStatus = () => {
    switch (payout.get('status')) {
      case 'paid':
        return (
          <span className="payout-detail-status-tag payout-detail-status-tag-paid">
            Paid
          </span>
        )

      case 'in_transit':
        return (
          <span className="payout-detail-status-tag payout-detail-status-tag-pending">
            In Transit
          </span>
        )

      default:
        return (
          <span className="payout-detail-status-tag payout-detail-status-tag-pending">
            Pending
          </span>
        )
    }
  }

  const arrivalDate = DateTime.fromISO(payout.get('arrivalDate')).toFormat(
    'MMM d, yyyy'
  )

  return (
    <div className="PayoutDetail">
      <div className="payout-detail-header">
        <div>
          <div className="payout-detail-header-title">
            <b>{arrivalDate}</b>
          </div>
        </div>
        <div className="payout-detail-header-right">{renderStatus()}</div>
      </div>
      <BalanceTransactionSum payout={payout} />
      <button
        className="button secondary small payout-detail-download"
        disabled={downloadingPayoutInvoicePdf}
        onClick={downloadPayoutInvoicePdf}
      >
        <CloudDownloadSvg />{' '}
        {downloadingPayoutInvoicePdf
          ? 'Downloading...'
          : 'Download self-billing invoice'}
      </button>
      <BalanceTransactionList
        balanceTransactions={balanceTransactions.valueSeq()}
      />
      <div ref={bottomRef}></div>
      {hasMore && error == null && (
        <div className="balance-transaction-list-loader">
          <span className="balance-transaction-list-loader-inner">
            Loading...
          </span>
        </div>
      )}
    </div>
  )
}

export default PayoutDetail
