import React from 'react'
import Imgix from 'react-imgix'
import { transformSrc } from './ImgixUtils'
import { useWasInView } from 'utils'

export const Image = ({ src, ...props }) => {
  const [ref, inView] = useWasInView()
  const imgIxUrl = transformSrc(src)

  if (!inView) {
    return <img ref={ref} {...props}></img> // eslint-disable-line
  }

  if (src !== imgIxUrl) {
    return (
      <Imgix
        src={imgIxUrl}
        imgixParams={{ q: 75, auto: 'format' }}
        {...props}
      />
    )
  } else {
    return <img src={src} {...props} /> // eslint-disable-line
  }
}
