import React, { useCallback, useState } from 'react'
import './CreatorMembershipUpsert.scss'
import { useGetProductsApi } from 'api'
import Spinner from 'components/Spinner'
import { Col, Row } from 'components/Grid'
import CreatorMembershipUpsertPlaceholder from 'components/CreatorMembershipUpsert/CreatorMembershipUpsertPlaceholder'
import ProductCard from 'components/ProductCard'
import { CreatorMembershipUpsertProductModal } from 'components/CreatorMembershipUpsert/CreatorMembershipUpsertProduct'

const CreatorMembershipUpsert = ({ user }) => {
  const { data: products, refresh: productsRefresh } = useGetProductsApi()
  const [modalOpen, setModalOpen] = useState(false)
  const [modalProduct, setModalProduct] = useState(null)
  const closeModal = useCallback(() => {
    setModalOpen(false)
  }, [setModalOpen])
  const upsertProduct = useCallback(
    (product) => {
      setModalProduct(product)
      setModalOpen(true)
    },
    [setModalProduct, setModalOpen]
  )

  if (products == null) {
    return <Spinner light small />
  }

  let productCards = products
    .filter((product) => product.get('plans').size > 0)
    .map((product) => (
      <Col sm={6} lg={4} key={product.get('id')}>
        <ProductCard
          product={product}
          productsAmount={products.size}
          user={user}
          onEdit={upsertProduct}
          refresh={productsRefresh}
        />
      </Col>
    ))
  const placeholder = (
    <Col sm={6} lg={4} key="placeholder">
      <CreatorMembershipUpsertPlaceholder
        upsertProduct={upsertProduct}
        productsRefresh={productsRefresh}
      />
    </Col>
  )
  productCards = productCards.push(placeholder)

  if (products.size === 0) {
    const placeholder2 = (
      <Col sm={6} lg={4} key="placeholder2">
        <CreatorMembershipUpsertPlaceholder
          upsertProduct={upsertProduct}
          productsRefresh={productsRefresh}
        />
      </Col>
    )
    productCards = productCards.push(placeholder2)
  }

  return (
    <div className="CreatorMembershipUpsert">
      <CreatorMembershipUpsertProductModal
        active={modalOpen}
        onClose={closeModal}
        product={modalProduct}
        productsRefresh={productsRefresh}
      />
      <Row className="slim-gutters creator-membership-upsert-items">
        {productCards}
      </Row>
    </div>
  )
}

export default CreatorMembershipUpsert
