import React from 'react'
import './CreatorSidebar.scss'
import { useSelector } from 'react-redux'
import { getMe } from 'store/selectors'
import HeaderProfile from 'components/HeaderProfile'

const CreatorSidebar = ({ children }) => {
  const me = useSelector((state) => getMe(state))

  return (
    <div className="CreatorSidebar">
      <HeaderProfile user={me} />
      <div className="creator-sidebar-page">{children}</div>
    </div>
  )
}

export default CreatorSidebar
