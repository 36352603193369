import React from 'react'
import {
  FacebookSvg,
  InstagramSvg,
  WebsiteSvg,
  YouTubeSvg,
  LinkedInSvg,
  TwitterSvg,
} from 'assets/svg'
import './UserSocial.scss'

const UserSocial = ({ user }) => {
  const socialItems = [
    {
      key: 'facebookUrl',
      name: 'Facebook',
      icon: FacebookSvg,
    },
    {
      key: 'instagramUrl',
      name: 'Instagram',
      icon: InstagramSvg,
    },
    {
      key: 'twitterUrl',
      name: 'Twitter',
      icon: TwitterSvg,
    },
    {
      key: 'linkedinUrl',
      name: 'LinkedIn',
      icon: LinkedInSvg,
    },
    {
      key: 'youtubeUrl',
      name: 'YouTube',
      icon: YouTubeSvg,
    },
    {
      key: 'homepageUrl',
      name: 'Website',
      icon: WebsiteSvg,
    },
  ]

  const items = socialItems.map(({ key, icon: Icon }) => {
    let url = user.get(key)
    if (url) {
      if (url.indexOf('http') !== 0) {
        url = `//${url}`
      }

      return (
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          className="user-social-item"
          key={key}
        >
          <Icon />
        </a>
      )
    }

    return null
  })

  if (items.filter((i) => i != null).size === 0) {
    return null
  }

  return (
    <div className="UserSocial">
      <div className="user-social-items">{items}</div>
    </div>
  )
}

export default UserSocial
