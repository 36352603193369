import React from 'react'
import './PostAttachmentViewPhoto.scss'
import { Image } from 'components/Image'
import { InsertPhotoSvg } from 'assets/svg'
import { POST_ATTACHMENT_ACCESS } from 'store/constants/post'
import PhotoBlurred from 'assets/img/photo-blurred.jpg'
import clsx from 'clsx'

const PostAttachmentViewPhoto = ({ photo, access, onNoAccess }) => {
  const noAccess = access === POST_ATTACHMENT_ACCESS.NONE

  return (
    <div className={clsx('PostAttachmentViewPhoto', { 'no-access': noAccess })}>
      <Image
        className="post-attachment-view-photo-image"
        src={photo.get('fullPath') || PhotoBlurred}
      />
      {noAccess && (
        <div className="post-attachment-view-photo-locked">
          <InsertPhotoSvg />
          <div>1 photo</div>
          <button
            onClick={onNoAccess}
            className="button primary light post-attachment-view-photo-locked-button"
          >
            Unlock
          </button>
        </div>
      )}
    </div>
  )
}

export default PostAttachmentViewPhoto
