import React from 'react'
import './SettingsHeader.scss'
import { NavLink } from 'react-router-dom'
import { URL_SETTINGS_ACCOUNT, URL_SETTINGS_NOTIFICATIONS } from 'urls'

const SettingsHeader = () => {
  return (
    <div className="SettingsHeader">
      <div className="settings-header-menu">
        <SettingsHeaderLink to={URL_SETTINGS_ACCOUNT}>
          Account
        </SettingsHeaderLink>
        <SettingsHeaderLink to={URL_SETTINGS_NOTIFICATIONS}>
          Email preferences
        </SettingsHeaderLink>
      </div>
    </div>
  )
}

export default SettingsHeader

const SettingsHeaderLink = React.memo(({ to, children }) => (
  <NavLink exact className="settings-header-menu-item" to={to}>
    {children}
  </NavLink>
))
