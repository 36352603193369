import { ENVIRONMENT, ENVIRONMENTS } from 'appConstants'

const transformations = [
  ['img.boon.tv', 'boon.imgix.net'],
  ['/static/media/', 'https://boon-web.imgix.net/static/media/'],
]

export const transformSrc = (src) => {
  if (src == null || ENVIRONMENT !== ENVIRONMENTS.PRODUCTION) {
    return src
  }

  return transformations.reduce((str, [from, to]) => str.replace(from, to), src)
}
