import React from 'react'
import clsx from 'clsx'
import './CategoryCard.scss'

const CategoryCard = ({
  icon,
  title,
  recentAmount = 0,
  postAmount = 0,
  onClick,
}) => {
  const onlyTitle = !icon && recentAmount === 0 && postAmount === 0

  const renderTopRow = () => {
    if (!icon && recentAmount === 0) {
      return null
    }

    return (
      <div className="category-card-top-row">
        {icon && <span className="category-card-top-row-emoji">{icon}</span>}
        {recentAmount > 0 && (
          <span className="category-card-top-row-recent">
            {recentAmount} recent
          </span>
        )}
      </div>
    )
  }

  return (
    <div className="CategoryCard" onClick={onClick}>
      {renderTopRow()}
      <div className={clsx('category-card-title', { 'only-title': onlyTitle })}>
        {title}
      </div>
      {postAmount > 0 && (
        <div className="category-card-posts">
          {postAmount} post{postAmount !== 1 && 's'}
        </div>
      )}
    </div>
  )
}

export default CategoryCard
