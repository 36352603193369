import { useEffect } from 'react'

const TEXT_PRIMARY_WHITE = '#ffffff'
//const TEXT_PRIMARY_BLACK = '#000000'

export const useCreatorStyles = (user) => {
  const colorScheme = user?.get('colorScheme')

  useEffect(() => {
    const colors = CREATOR_COLORS[colorScheme]
    if (colors != null) {
      document.documentElement.style.setProperty(
        '--color-creator-primary',
        colors.primary
      )
      document.documentElement.style.setProperty(
        '--color-creator-primary-rgb',
        colors.primaryRGB
      )
      document.documentElement.style.setProperty(
        '--color-creator-text-primary',
        colors.textPrimary
      )
    }

    return () => {
      document.documentElement.style.removeProperty('--color-creator-primary')
      document.documentElement.style.removeProperty(
        '--color-creator-primary-rgb'
      )
      document.documentElement.style.removeProperty(
        '--color-creator-text-primary'
      )
    }
  }, [colorScheme])

  return null
}

export const CREATOR_COLORS = {
  red: {
    primary: '#f44336',
    primaryRGB: '244, 67, 54',
    textPrimary: TEXT_PRIMARY_WHITE,
  },
  pink: {
    primary: '#f44336',
    primaryRGB: '244, 67, 54',
    textPrimary: TEXT_PRIMARY_WHITE,
  },
  purple: {
    primary: '#9c27b0',
    primaryRGB: '156, 39, 176',
    textPrimary: TEXT_PRIMARY_WHITE,
  },
  deep_purple: {
    primary: '#673ab7',
    primaryRGB: '103, 58, 183',
    textPrimary: TEXT_PRIMARY_WHITE,
  },
  indigo: {
    primary: '#3f51b5',
    primaryRGB: '63, 81, 181',
    textPrimary: TEXT_PRIMARY_WHITE,
  },
  blue: {
    primary: '#2196f3',
    primaryRGB: '33, 150, 243',
    textPrimary: TEXT_PRIMARY_WHITE,
  },
  light_blue: {
    primary: '#03a9f4',
    primaryRGB: '3, 169, 244',
    textPrimary: TEXT_PRIMARY_WHITE,
  },
  cyan: {
    primary: '#00bcd4',
    primaryRGB: '0, 188, 212',
    textPrimary: TEXT_PRIMARY_WHITE,
  },
  teal: {
    primary: '#009688',
    primaryRGB: '0, 150, 136 ',
    textPrimary: TEXT_PRIMARY_WHITE,
  },
  green: {
    primary: '#4caf50',
    primaryRGB: '76, 175, 80',
    textPrimary: TEXT_PRIMARY_WHITE,
  },
  light_green: {
    primary: '#8bc34a',
    primaryRGB: '139, 195, 74',
    textPrimary: TEXT_PRIMARY_WHITE,
  },
  lime: {
    primary: '#c0ca33',
    primaryRGB: '192, 202, 51',
    textPrimary: TEXT_PRIMARY_WHITE,
  },
  yellow: {
    primary: '#fdd835',
    primaryRGB: '253, 216, 53',
    textPrimary: TEXT_PRIMARY_WHITE,
  },
  amber: {
    primary: '#ffc107',
    primaryRGB: '255, 193, 7',
    textPrimary: TEXT_PRIMARY_WHITE,
  },
  orange: {
    primary: '#ff9800',
    primaryRGB: '255, 152, 0',
    textPrimary: TEXT_PRIMARY_WHITE,
  },
  deep_orange: {
    primary: '#ff5722',
    primaryRGB: '255, 87, 34',
    textPrimary: TEXT_PRIMARY_WHITE,
  },
}
