import React, { useMemo, useEffect, useRef } from 'react'
import './InsightsChartWidget.scss'
import InsightsGenericWidget from 'components/InsightsGenericWidget/InsightsGenericWidget'
import Spinner from 'components/Spinner'

import { ErrorOutlineSvg } from 'assets/svg'
import Chart from 'chart.js/auto'

import {
  INSIGHTS_PERIODS,
  INSIGHTS_PERIODS_NAMES,
} from 'routes/InsightsPage/insightsConstants'

import { DateTime } from 'luxon'
import 'chartjs-adapter-luxon'

const InsightsChartWidget = ({
  title,
  timeScale,
  loading,
  error,
  data,
  chartType = 'line',
  labels = null,
}) => {
  const numberFormatter = useMemo(
    () =>
      Intl.NumberFormat(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 1,
        notation: 'compact',
        compactDisplay: 'short',
      }),
    []
  )
  const chartRef = useRef(null)
  const timeData = data && data.length > 0 && data[0]['x'] instanceof Date

  useEffect(() => {
    if (!chartRef.current) {
      return
    }

    const chartData = data.slice()

    if (
      chartType === 'line' &&
      timeData &&
      timeScale === INSIGHTS_PERIODS_NAMES[INSIGHTS_PERIODS.TOTAL]
    ) {
      // Hack to allow for first value of all time being zero
      let d = new Date(chartData[0].x)
      let endOfFirstMonth = DateTime.fromJSDate(d)
        .minus({ month: 1 })
        .toJSDate()
      chartData.unshift({ x: endOfFirstMonth, y: 0 })
    }

    const chartLabels = chartData.map(({ x }) => x)

    const xAxesData = timeData
      ? {
          type: 'time',
          time: {
            unit:
              timeScale === INSIGHTS_PERIODS_NAMES[INSIGHTS_PERIODS.TOTAL]
                ? 'month'
                : 'day',
            round: 'day',
            displayFormats: {
              day: 'MMM d, yyyy',
              month: 'MMM yyyy',
            },
          },
        }
      : null
    const yAxesTicks = chartType === 'line' ? null : { min: 0, stepSize: 10 }

    const myChart = new Chart(chartRef.current, {
      type: chartType,
      data: {
        datasets: [
          {
            fill: false,
            data: chartData,
            pointRadius: 0,
            backgroundColor: '#4479ff',
            borderColor: '#4479ff',
            barThickness: 'flex',
            barPercentage: 0.5,
          },
        ],
        labels: chartLabels,
      },
      options: {
        defaultFontFamily: 'Inter',
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            intersect: false,
            yAlign: 'bottom',
            xAlign: 'center',
            displayColors: false,
            callbacks: {
              title: (item) => {
                if (timeData) {
                  // Remove everything after last comma
                  let time = item[0].label.replace(/,[^,]+$/, '')
                  if (
                    timeScale === INSIGHTS_PERIODS_NAMES[INSIGHTS_PERIODS.TOTAL]
                  ) {
                    // Show last day of month, or today in current month
                    let endOfMonth = DateTime.fromFormat(time, 'd LLL yyyy')
                      .plus({ month: 1 })
                      .minus({ day: 1 })
                    return DateTime.min(endOfMonth, DateTime.local()).toFormat(
                      'MMM d, yyyy'
                    )
                  } else {
                    // Input data is e.g.: `Feb 25, 2021, 1:00:00 am`
                    return time
                  }
                } else {
                  return item[0].label
                }
              },
              label: (context) => {
                let num = Intl.NumberFormat(undefined, {}).format(
                  context.parsed.y
                )
                return labels && typeof labels === 'function'
                  ? labels(num)
                  : num
              },
            },
          },
        },
        layout: { padding: { top: 30 } },
        maintainAspectRatio: false,
        scales: {
          x: {
            grid: {
              offset: false,
              display: false,
            },
            ...xAxesData,
          },
          y: {
            min:
              timeScale === INSIGHTS_PERIODS_NAMES[INSIGHTS_PERIODS.TOTAL]
                ? null
                : 0,
            ticks: {
              callback: (value) => numberFormatter.format(value),
              precision: 0,
              maxTicksLimit: 8,
              ...yAxesTicks,
            },
            grid: {
              drawBorder: false,
              color: '#f0f1f4',
            },
          },
        },
      },
    })

    return () => {
      myChart.destroy()
    }
  })

  // Hack to allow for flat lines when only one data point is available
  if (timeData && data && data.length === 1) {
    let { x, y } = data[0]
    let d = new Date(x)
    d.setUTCMinutes(1)
    data.push({ x: d, y })
  }

  const renderContent = () => {
    if (error) {
      return (
        <div className="insights-chart-widget-error">
          <ErrorOutlineSvg className="insights-chart-widget-error-icon" />
          <div>Loading failed</div>
          <div>Please try again later</div>
        </div>
      )
    } else if (loading) {
      return <Spinner />
    } else {
      // Do not render, if no data given
      if (!data || (data && data.length === 0)) {
        return null
      }
      return <canvas ref={chartRef} />
    }
  }

  return (
    <InsightsGenericWidget title={title} timeScale={timeScale}>
      <div className="InsightsChartWidget">{renderContent()}</div>
    </InsightsGenericWidget>
  )
}

export default InsightsChartWidget
