import React, { useEffect } from 'react'
import './CreatorSettingsPayout.scss'
import { useGetCompanyApi, updateCompany } from 'api'
import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { URL_EXTERNAL_REFERRAL_PROGRAM, URL_ACCOUNTING } from 'urls'
import { useSelector } from 'react-redux'
import { getMe } from 'store/selectors'
import { Formik, Field } from 'formik'
import { BoonFormikTextField } from 'components/BoonInputs'
import clsx from 'clsx'
import { getApiError } from 'utils'
import { List } from 'immutable'
import CountryCodes from 'data/CountryCodes'
import { Row, Col } from 'components/Grid'
import CreatorSettingsHeader from './CreatorSettingsHeader'
import CreatorSettingsPayoutAccount from './CreatorSettingsPayoutAccount'

const CreatorSettingsPayout = () => {
  const me = useSelector((state) => getMe(state))

  return (
    <div className="CreatorSettingsPayout">
      <CreatorSettingsHeader description="Manage your income with your personal payout account." />

      <CreatorSettingsPayoutAccount />

      {me.get('hasPayoutAccount') && <BusinessInfoBox />}

      <div className="creator-settings-section settings-business-referral">
        <div className="creator-settings-section-header">Referral</div>
        <div className="settings-business-referral-description">
          Invite fellow creators to join Boon and earn 5% of the revenue they
          generate for one year.&nbsp;
          <a
            href={URL_EXTERNAL_REFERRAL_PROGRAM}
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more
          </a>
        </div>
        <div className="settings-business-referral-value">
          YOUR INVITE CODE:&nbsp;
          <span className="settings-business-referral-value-code">
            {me.get('referralCode')}
          </span>
        </div>
      </div>
    </div>
  )
}

export default CreatorSettingsPayout

const BusinessInfoBox = () => {
  const history = useHistory()
  const location = useLocation()
  const { data: company, loading } = useGetCompanyApi()
  const highlight = location.hash === '#businessInformation'
  const { redirect_url } = queryString.parse(location.search, { decode: false })

  useEffect(() => {
    if (!loading && highlight) {
      document.querySelector('#businessInformation').scrollIntoView()
    }
  }, [loading, highlight])

  const onSubmit = async (values, { setSubmitting, setErrors }) => {
    setSubmitting(true)

    try {
      await updateCompany({ data: values })
      history.push(redirect_url || URL_ACCOUNTING())
    } catch (e) {
      setErrors(getApiError(e))
    }

    setSubmitting(false)
  }

  if (loading) {
    return null
  }

  const initialValues = company != null ? company.toJS() : {}

  return (
    <>
      <div className="creator-settings-section BusinessInfoBox">
        <div
          className="creator-settings-section-header"
          id="businessInformation"
        >
          Business information
        </div>
        {highlight && (
          <div className="business-info-box-description">
            To download invoices, you must first fill out the following
            information:
          </div>
        )}

        <Formik initialValues={initialValues} onSubmit={onSubmit}>
          {({ handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit}>
              <Field
                id="isCompany"
                name="isCompany"
                type="checkbox"
                className="boon-dark-checkbox"
              />
              <label htmlFor="isCompany">Are you a company?</label>

              <Row>
                <Col xs={12} lg={6}>
                  <BoonFormikTextField name="name" label="Business name" />
                </Col>
                <Col xs={12} lg={6}>
                  <BoonFormikTextField name="vatNumber" label="VAT Number" />
                </Col>
                <Col xs={12} lg={6}>
                  <BoonFormikTextField
                    name="addressLine1"
                    label="Address Line 1"
                  />
                </Col>
                <Col xs={12} lg={6}>
                  <BoonFormikTextField
                    name="addressLine2"
                    label="Address Line 2"
                  />
                </Col>
                <Col xs={12} lg={6}>
                  <BoonFormikTextField name="postalCode" label="Postal code" />
                </Col>
                <Col xs={12} lg={6}>
                  <BoonFormikTextField name="city" label="City" />
                </Col>
                <Col xs={12} lg={6}>
                  <BoonFormikTextField name="state" label="State" />
                </Col>
                <Col xs={12} lg={6}>
                  <div className="boon-dark-select-label">Country</div>
                  <Field
                    name="countryCode"
                    as="select"
                    className="boon-dark-select"
                    label="Country"
                  >
                    <option>Select country</option>
                    {List(Object.values(CountryCodes))
                      .sortBy((country) => country.name)
                      .map((country) => (
                        <option value={country.code} key={country.code}>
                          {country.name} {country.emoji}
                        </option>
                      ))}
                  </Field>
                </Col>
              </Row>

              <button
                type="submit"
                disabled={isSubmitting}
                className={clsx('button primary loader', {
                  loading: isSubmitting,
                })}
              >
                Save changes
              </button>
            </form>
          )}
        </Formik>
      </div>
    </>
  )
}
