import React from 'react'
import Avatar from 'components/Avatar/Avatar'
import InsightsContentLoader from 'components/InsightsContentLoader/InsightsContentLoader'

import { displayPrice, displayStars } from 'utils'
import { DateTime, Duration } from 'luxon'

const InsightsTableDataElement = ({ data, header: { key, type }, loading }) => {
  let elem = data[key]

  if (loading) {
    // Random width between 60 and 200
    let width = Math.floor(Math.random() * 140) + 60

    return (
      <InsightsContentLoader width={200} height={20}>
        <rect x="0" y="5" rx="6" ry="6" width={width} height="10" />
      </InsightsContentLoader>
    )
  }

  if (elem === '') {
    return null
  }

  switch (type) {
    case 'profile':
      let { name, userId, avatarUrl } = elem
      return (
        <>
          <Avatar
            url={avatarUrl}
            userId={userId}
            className="insights-table-widget-avatar"
          />
          <span className="insights-table-widget-avatar-name" alt={name}>
            {name}
          </span>
        </>
      )
    case 'video':
      let [title, url] = elem
      return (
        <>
          {url && (
            <img
              className="insights-table-widget-video-img"
              src={url}
              alt={title}
            />
          )}
          <span title={title}>{title}</span>
        </>
      )
    case 'yesno':
      return elem ? 'Yes' : 'No'
    case 'string':
      return elem && String(elem)
    case 'integer':
      return Intl.NumberFormat(undefined, {}).format(elem)
    case 'date':
      return elem === null
        ? null
        : DateTime.fromISO(elem)
            .setLocale('en-US')
            .toLocaleString(DateTime.DATE_MED)
    case 'currency':
      return displayPrice(elem[0], elem[1])
    case 'rating':
      return displayStars(elem)
    case 'pill':
      if (Array.isArray(elem)) {
        return elem.map((value, idx) => (
          <span key={idx} className="pill">
            {value}
          </span>
        ))
      } else if (elem === null) {
        return null
      } else {
        return <span className="pill">{elem}</span>
      }
    case 'percent':
      return `${Math.round(elem * 100.0)}%`
    case 'timespan':
      if (elem === null || elem === 0) {
        return 0
      }

      let duration = Duration.fromObject({ seconds: elem })
      let { days, hours, minutes } = duration
        .shiftTo('days', 'hours', 'minutes')
        .toObject()

      let result = []
      if (days > 0) {
        result.push(`${days}d`)
      }
      if (hours > 0) {
        result.push(`${hours}h`)
      }
      if (minutes > 0) {
        result.push(`${Math.round(minutes)}m`)
      }

      return result.join(' ')
    default:
      return elem === null ? '-' : elem
  }
}

export default InsightsTableDataElement
