import React from 'react'
import { RadioCheckedSvg, RadioUncheckedSvg } from 'assets/svg'
import clsx from 'clsx'
import './PollBasicOption.scss'

export const PollBasicOption = ({
  option,
  interactive,
  compact,
  total,
  onClick,
  ended,
  active,
  selected,
}) => {
  const votes = option.get('votesCount')
  const percentage = total === 0 ? 0 : Math.round((votes / total) * 100)

  const renderRadio = () => {
    if (!interactive || ended) {
      return null
    }

    if (selected) {
      return <RadioCheckedSvg className="poll-basic-option-radio" />
    } else {
      return <RadioUncheckedSvg className="poll-basic-option-radio" />
    }
  }

  return (
    <div
      className={clsx('PollBasicOption', {
        active,
        selected,
        compact,
        interactive: interactive && !ended,
      })}
      data-percentage={percentage}
      onClick={() => onClick(option.get('id'))}
    >
      <div
        className="poll-basic-option-bar"
        style={{ width: `${percentage}%` }}
      />
      {renderRadio()}
      <div className="poll-basic-option-title">{option.get('message')}</div>
      <div className="poll-basic-option-percentage">{percentage}%</div>
    </div>
  )
}
