import React from 'react'
import clsx from 'clsx'

const InsightsTableFilters = ({ headers, setHeaders, filters, setFilters }) => {
  const setUpdatedFilters = (filter) => {
    filters = filters.slice()

    let updatedFilterIdx = filters.findIndex(
      ({ value: innerValue }) => innerValue === filter.value
    )
    let updatedFilter = {
      ...filters[updatedFilterIdx],
      active: !filters[updatedFilterIdx].active,
    }

    filters[updatedFilterIdx] = updatedFilter

    if (filter.showHeadersOnEnable) {
      let updatedHeaders = [...headers]

      filter.showHeadersOnEnable.forEach((newHeader) => {
        let newHeaderIdx = headers.findIndex(({ key }) => key === newHeader)
        updatedHeaders[newHeaderIdx].active = true
      })

      setHeaders(updatedHeaders)
    }

    setFilters(filters)
  }

  const renderFilterButton = (filter) => {
    return (
      <button
        key={filter.value}
        className={clsx('button', 'insights-table-widget-button', {
          secondary: !filter.active,
          primary: filter.active,
        })}
        onClick={() => setUpdatedFilters(filter)}
      >
        {filter.value}
      </button>
    )
  }

  if (!filters || filters.length === 0 || !setFilters) {
    return null
  }

  return (
    <span className="insights-table-widget-filters">
      {filters.map((filter) => renderFilterButton(filter))}
    </span>
  )
}

export default InsightsTableFilters
