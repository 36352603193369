import { Map } from 'immutable'
import {
  POST_ACCESS_LEVELS,
  POST_COMMENT_ACCESS_LEVELS,
} from 'store/constants/post'

export const POST_CHANNEL_MESSAGE_TYPES = {
  NEW_POST: 'NEW_POST',
  NEW_COMMENT: 'NEW_COMMENT',
  NEW_REPLY: 'NEW_REPLY',
  UPDATED_POST: 'UPDATED_POST',
  UPDATED_COMMENT: 'UPDATED_COMMENT',
  UPDATED_REPLY: 'UPDATED_REPLY',
  DELETED_POST: 'DELETED_POST',
  DELETED_COMMENT: 'DELETED_COMMENT',
  DELETED_REPLY: 'DELETED_REPLY',
  UPDATED_POST_ATTACHMENT: 'UPDATED_POST_ATTACHMENT',
}

export const POST_REDUCER_INTERFACES = {
  MERGE_POSTS: 'MERGE_POSTS',
  CLEAR_POSTS: 'CLEAR_POSTS',
}

export const postReducerMerge = (posts) =>
  Map({
    type: POST_REDUCER_INTERFACES.MERGE_POSTS,
    posts,
  })

export const postReducerClear = () =>
  Map({
    type: POST_REDUCER_INTERFACES.CLEAR_POSTS,
  })

const hasLowerAccessLevel = (existing, incoming, levels) => {
  if (existing == null) {
    return false
  }

  return levels[existing] > levels[incoming]
}

export const postReducer = (state, action) => {
  switch (action.get('type')) {
    case POST_CHANNEL_MESSAGE_TYPES.NEW_POST:
      if (
        hasLowerAccessLevel(
          state.getIn([action.get('postId'), 'access']),
          action.getIn(['post', 'access']),
          POST_ACCESS_LEVELS
        )
      ) {
        return state
      }

      return state.set(action.get('postId'), action.get('post'))
    case POST_CHANNEL_MESSAGE_TYPES.NEW_COMMENT:
      return state.updateIn([action.get('postId'), 'comments'], (comments) => {
        if (comments == null) {
          return comments
        }

        const commentsIndex = comments.findIndex(
          (comment) => comment.get('id') === action.get('commentId')
        )

        if (commentsIndex > -1) {
          if (
            hasLowerAccessLevel(
              comments.getIn([commentsIndex, 'access']),
              action.getIn(['comment', 'access']),
              POST_COMMENT_ACCESS_LEVELS
            )
          ) {
            return comments
          }

          return comments.set(commentsIndex, action.get('comment'))
        } else {
          return comments.push(action.get('comment'))
        }
      })
    case POST_CHANNEL_MESSAGE_TYPES.NEW_REPLY:
      return state.updateIn([action.get('postId'), 'comments'], (comments) => {
        if (comments == null) {
          return comments
        }

        const commentsIndex = comments.findIndex(
          (comment) => comment.get('id') === action.get('commentId')
        )
        if (commentsIndex > -1) {
          return comments.updateIn([commentsIndex, 'replies'], (replies) => {
            if (replies == null) {
              return replies
            }

            const replyIndex = replies.findIndex(
              (comment) => comment.get('id') === action.get('replyId')
            )

            if (replyIndex > -1) {
              if (
                hasLowerAccessLevel(
                  replies.getIn([replyIndex, 'access']),
                  action.getIn(['reply', 'access']),
                  POST_COMMENT_ACCESS_LEVELS
                )
              ) {
                return replies
              }

              return replies.set(replyIndex, action.get('reply'))
            } else {
              return replies.push(action.get('reply'))
            }
          })
        }

        return comments
      })
    case POST_CHANNEL_MESSAGE_TYPES.UPDATED_POST:
      return state.update(action.get('postId'), (post) => {
        if (post != null) {
          return post.merge(action.get('post'))
        }
      })
    case POST_CHANNEL_MESSAGE_TYPES.UPDATED_POST_ATTACHMENT:
      return state.updateIn(
        [action.get('postId'), 'attachment'],
        (attachment) => {
          if (attachment != null) {
            return attachment.merge(action.get('attachment'))
          }
        }
      )
    case POST_CHANNEL_MESSAGE_TYPES.UPDATED_COMMENT:
      return state.updateIn([action.get('postId'), 'comments'], (comments) => {
        if (comments == null) {
          return comments
        }

        const commentsIndex = comments.findIndex(
          (comment) => comment.get('id') === action.get('commentId')
        )
        if (commentsIndex > -1) {
          return comments.update(commentsIndex, (comment) =>
            comment.merge(action.get('comment'))
          )
        }

        return comments
      })
    case POST_CHANNEL_MESSAGE_TYPES.UPDATED_REPLY:
      return state.updateIn([action.get('postId'), 'comments'], (comments) => {
        if (comments == null) {
          return comments
        }

        const commentsIndex = comments.findIndex(
          (comment) => comment.get('id') === action.get('commentId')
        )
        if (commentsIndex > -1) {
          return comments.updateIn([commentsIndex, 'replies'], (replies) => {
            if (replies == null) {
              return replies
            }

            const replyIndex = replies.findIndex(
              (comment) => comment.get('id') === action.get('replyId')
            )
            if (replyIndex > -1) {
              return replies.update(replyIndex, (reply) =>
                reply.merge(action.get('reply'))
              )
            }

            return replies
          })
        }

        return comments
      })

    case POST_CHANNEL_MESSAGE_TYPES.DELETED_POST:
      return state.delete(action.get('postId'))

    case POST_CHANNEL_MESSAGE_TYPES.DELETED_COMMENT:
      return state.updateIn([action.get('postId'), 'comments'], (comments) => {
        if (comments == null) {
          return comments
        }

        const commentsIndex = comments.findIndex(
          (comment) => comment.get('id') === action.get('commentId')
        )
        if (commentsIndex > -1) {
          return comments.remove(commentsIndex)
        }

        return comments
      })

    case POST_CHANNEL_MESSAGE_TYPES.DELETED_REPLY:
      return state.updateIn([action.get('postId'), 'comments'], (comments) => {
        if (comments == null) {
          return comments
        }

        const commentsIndex = comments.findIndex(
          (comment) => comment.get('id') === action.get('commentId')
        )
        if (commentsIndex > -1) {
          return comments.updateIn([commentsIndex, 'replies'], (replies) => {
            if (replies == null) {
              return replies
            }

            const replyIndex = replies.findIndex(
              (comment) => comment.get('id') === action.get('replyId')
            )
            if (replyIndex > -1) {
              return replies.remove(replyIndex)
            }

            return replies
          })
        }

        return comments
      })

    case POST_REDUCER_INTERFACES.MERGE_POSTS:
      return state.merge(action.get('posts').map((v) => [v.get('id'), v]))

    case POST_REDUCER_INTERFACES.CLEAR_POSTS:
      return Map()

    default:
      return state
  }
}

export const createNewPost = ({ postId, post }) =>
  Map({
    type: POST_CHANNEL_MESSAGE_TYPES.NEW_POST,
    postId,
    post,
  })

export const setPostAttributes = ({ postId, post }) =>
  Map({
    type: POST_CHANNEL_MESSAGE_TYPES.UPDATED_POST,
    postId,
    post,
  })

export const createNewPostComment = ({ postId, commentId, comment }) =>
  Map({
    type: POST_CHANNEL_MESSAGE_TYPES.NEW_COMMENT,
    postId,
    commentId,
    comment,
  })

export const setCommentAttributes = ({ postId, commentId, comment }) =>
  Map({
    type: POST_CHANNEL_MESSAGE_TYPES.UPDATED_COMMENT,
    postId,
    commentId,
    comment,
  })

export const createNewPostReply = ({ postId, commentId, replyId, reply }) =>
  Map({
    type: POST_CHANNEL_MESSAGE_TYPES.NEW_REPLY,
    postId,
    commentId,
    replyId,
    reply,
  })

export const setReplyAttributes = ({ postId, commentId, replyId, reply }) =>
  Map({
    type: POST_CHANNEL_MESSAGE_TYPES.UPDATED_REPLY,
    postId,
    commentId,
    replyId,
    reply,
  })

export const postReducerDeletePost = ({ postId }) =>
  Map({
    type: POST_CHANNEL_MESSAGE_TYPES.DELETED_POST,
    postId,
  })

export const postReducerDeleteComment = ({ postId, commentId }) =>
  Map({
    type: POST_CHANNEL_MESSAGE_TYPES.DELETED_COMMENT,
    postId,
    commentId,
  })

export const postReducerDeleteReply = ({ postId, commentId, replyId }) =>
  Map({
    type: POST_CHANNEL_MESSAGE_TYPES.DELETED_REPLY,
    postId,
    commentId,
    replyId,
  })

export const setPostAttachmentAttributes = ({ postId, attachment }) =>
  Map({
    type: POST_CHANNEL_MESSAGE_TYPES.UPDATED_POST_ATTACHMENT,
    postId,
    attachment,
  })
