import React from 'react'
import VideoPlayer from './VideoPlayer'
import { useVideoPlayStats } from 'routes/EventPage/components/videoPlayStats'

const WatchVideoPlayer = ({
  event,
  url,
  meId,
  onPlaybackFailed,
  onWaiting,
}) => {
  const { onPlay } = useVideoPlayStats({
    viewerId: meId,
    eventId: event.get('id'),
    hostId: event.getIn(['host', 'id']),
    live: true,
    videoUrl: url,
  })

  return (
    <VideoPlayer
      key={url}
      controls
      autoplay="play"
      playsinline
      techOrder={['chromecast', 'html5']}
      plugins={{
        chromecast: {},
      }}
      liveui
      controlBar={{
        pictureInPictureToggle: false,
      }}
      width="100%"
      height="100%"
      eventId={event.get('id')}
      onPlay={onPlay}
      sources={[
        {
          src: url,
        },
      ]}
      onPlaybackFailed={onPlaybackFailed}
      onWaiting={onWaiting}
      /*
      plugins={{
        mux: {
          debug: false,
          respectDoNotTrack: false,
          data: {
            env_key: MUX_DATA_ENV_KEY,

            player_name: 'watch',
            player_init_time: Date.now(),
            viewer_user_id: meId,
            video_title: `watch_${event.get('id')}`,
            video_stream_type: 'live',
          },
        },
      }}
      */
    />
  )
}

export default WatchVideoPlayer
