import CheckoutModal from 'modals/CheckoutModal'
import Avatar from 'components/Avatar'
import PostComposeAttachmentButton from 'components/PostCompose/PostComposeAttachmentButton'
import { AttachFileSvg, CreateSvg, InsertPhotoSvg, PollSvg } from 'assets/svg'
import React, { useCallback, useState } from 'react'
import { useFormikContext } from 'formik'
import { useSelector } from 'react-redux'
import { getMe, isAuthenticated } from 'store/selectors'
import { HOST_ACCESS } from 'store/constants'
import { POST_ATTACHMENT_TYPES } from 'store/constants/post'
import { getIsUserAdmin } from 'utils'

const PostComposeInner = ({ user, setModalActive }) => {
  const { values } = useFormikContext()
  const [showCheckout, setShowCheckout] = useState(false)
  const hasAuth = useSelector((state) => isAuthenticated(state))
  const me = useSelector((state) => getMe(state))
  const hasAttachment = values.attachmentType != null
  const isAdmin = getIsUserAdmin(user)
  const hasAccess = user.get('hostAccess') !== HOST_ACCESS.NONE
  const { setFieldValue } = useFormikContext()

  const onNoAccess = useCallback(() => {
    setShowCheckout(true)
  }, [])

  const preventClickIfNoAuth = async (e) => {
    if (!hasAccess) {
      e.preventDefault()
      e.stopPropagation()
      onNoAccess()
    }
  }

  const onFocus = async () => {
    if (hasAccess) {
      setModalActive(true)
    } else {
      onNoAccess()
    }
  }

  const onPollAdd = () => {
    setFieldValue('attachmentType', POST_ATTACHMENT_TYPES.POLL)
    onFocus()
  }

  const messageEmptyState = `Ask a question`

  return (
    <div className="PostComposeInner">
      <CheckoutModal
        active={showCheckout}
        title="Unlock to Post"
        onClose={() => setShowCheckout(false)}
        hostId={user.get('id')}
      />
      <div className="post-compose-form-wrapper">
        {hasAuth && (
          <Avatar
            url={me.get('profileImageUrl')}
            userId={me.get('id')}
            width={36}
            height={36}
            className="post-compose-form-avatar"
          />
        )}
        <div className="post-compose-form-text-field" onClick={onFocus}>
          <CreateSvg className="post-compose-form-text-field-icon" />
          <div className="post-compose-form-message">{messageEmptyState}</div>
          {!hasAccess && (
            <div className="post-compose-form-text-field-bogus">
              <InsertPhotoSvg />
            </div>
          )}
        </div>
      </div>
      <div className="post-compose-form-footer">
        {!hasAttachment && hasAccess && (
          <div className="post-compose-form-footer-attachments-bar">
            <PostComposeAttachmentButton
              onClick={preventClickIfNoAuth}
              icon={InsertPhotoSvg}
              component="label"
              htmlFor="postComposePhoto"
              title="Photo"
            />
            {isAdmin && (
              <PostComposeAttachmentButton
                id="postComposeAddPoll"
                icon={PollSvg}
                title="Poll"
                onClick={onPollAdd}
              />
            )}
            <PostComposeAttachmentButton
              onClick={preventClickIfNoAuth}
              icon={AttachFileSvg}
              component="label"
              htmlFor="postComposeDocument"
              title="File"
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default PostComposeInner
