import { DateTime } from 'luxon'
import { CREATOR_STATUS, PROFILE_STATUS } from 'store/constants'

export const isHydrated = (state) => state.getIn(['ui', 'isHydrated']) === true

export const isAuthenticated = (state) => getAccessToken(state) != null

export const getAccessToken = (state) => state.getIn(['auth', 'accessToken'])
export const getAccessTokenExpires = (state) =>
  state.getIn(['auth', 'accessTokenExpires'])
export const getRefreshToken = (state) => state.getIn(['auth', 'refreshToken'])
export const getMe = (state) => state.getIn(['auth', 'me'])

export const isAccessTokenExpiringSoon = (state) => {
  const diff = DateTime.fromISO(getAccessTokenExpires(state)).diff(
    DateTime.local(),
    'minutes'
  )
  return diff.toObject().minutes < 5
}

export const getFollowing = (state) => state.getIn(['auth', 'me', 'following'])
export const getIsFollowing = (userId) => (state) =>
  state.getIn(['auth', 'me', 'following', userId]) != null

export const getSubscriptions = (state) =>
  state.getIn(['auth', 'me', 'subscriptionAccounts'])
export const getIsSubscribed = (userId) => (state) =>
  state.getIn(['auth', 'me', 'subscriptionAccounts', userId]) != null
export const selectIsSubscribedProduct = (productId) => (state) =>
  state
    .getIn(['auth', 'me', 'activeSubscriptions'])
    .some((s) => s.getIn(['product', 'id']) === productId)

export const selectProductSubscription = (productId) => (state) =>
  state
    .getIn(['auth', 'me', 'activeSubscriptions'])
    .find((s) => s.getIn(['product', 'id']) === productId)

export const isMeCreator = (state) =>
  getMe(state)?.get('creatorStatus') === CREATOR_STATUS.CREATOR

export const selectIsMeDraftCreator = (state) => {
  const me = getMe(state)
  return (
    me?.get('creatorStatus') === CREATOR_STATUS.CREATOR &&
    me?.get('profileStatus') === PROFILE_STATUS.DRAFT
  )
}

export const selectActiveSubscriptions = (state) =>
  state.getIn(['auth', 'me', 'activeSubscriptions'])

export const selectPastDueSubscriptions = (state) =>
  state.getIn(['auth', 'me', 'pastDueSubscriptions'])

export const selectorIsSudo = () => (state) =>
  state.getIn(['auth', 'sudo']) === true

export const selectorHeaderDark = () => (state) =>
  state.getIn(['ui', 'header', 'dark'])

export const selectorNotFound = () => (state) =>
  state.getIn(['ui', 'isNotFound'])
