import React, { forwardRef } from 'react'
import './ChannelHeader.scss'

import { BackgroundImage } from 'components/Image'
import Avatar from 'components/Avatar'
import FollowButton from 'components/FollowButton'

const ChannelHeader = ({ user, showFollower }, ref) => {
  return (
    <div className="ChannelHeader">
      <BackgroundImage
        className="channel-header-image"
        src={user.get('coverImageUrl')}
      />
      <Avatar
        className="channel-header-profile"
        url={user.get('profileImageUrl')}
        userId={user.get('id')}
      />
      <div className="channel-header-content" ref={ref}>
        <h1 className="channel-header-name">{user.get('name')}</h1>
        {user.get('title') && (
          <h2 className="channel-header-title">{user.get('title')}</h2>
        )}
        {showFollower && (
          <FollowButton
            user={user}
            className={'channel-header-follow-button'}
          />
        )}
      </div>
    </div>
  )
}

export default forwardRef(ChannelHeader)
