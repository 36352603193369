import { DateTime } from 'luxon'
import { EVENT_PURCHASE_STATE, EVENT_VISIBILITY } from 'store/constants'

// Gets a slug from an event for use in URLs, uses id as fallback
export const getEventSlug = (event) => {
  return event.get('slug') || event.get('id')
}

// Splits a List of events into upcoming and past
export const splitEventList = (list) => {
  list = list.map((e) => [
    DateTime.fromISO(
      e.get('startTime') || e.get('publishedAt') || e.get('createdAt')
    ).diffNow('seconds').seconds + (e.get('live') ? e.get('duration') * 60 : 0),
    e,
  ])
  const upcoming = list.filter(([s, e]) => s >= 0).sortBy(([s]) => s)
  const past = list
    .filter(([s, e]) => s < 0)
    .sortBy(([s]) => s)
    .reverse()

  return { upcoming: upcoming.map(([, e]) => e), past: past.map(([, e]) => e) }
}

/*
  Uses event list splitting to perform tricky sorting of events,
  where both upcoming and past events coexist in one single list.
*/
export const sortEventList = (list) => {
  const { upcoming, past } = splitEventList(list)

  return upcoming.concat(past)
}

// Event helper functions

export const isEventUpcoming = (event) => {
  const diff = DateTime.fromISO(event.get('startTime')).diffNow('seconds')
    .seconds

  return diff > 0
}

export const isEventUpcomingOrLive = (event) => {
  const dateTime = DateTime.fromISO(event.get('startTime'))
  const seconds = Math.floor(dateTime.diffNow('seconds').seconds)
  return seconds > -(event.get('duration') * 60)
}

export const isEventDraft = (event) => {
  return event.get('isDraft')
}

export const isEventScheduled = (event) => {
  return event.get('isScheduled')
}

export const isEventPublished = (event) => {
  return event.get('isPublished')
}

export const getEventVisibility = (event) => {
  if (isEventPublished(event)) return EVENT_VISIBILITY.PUBLISHED
  if (isEventScheduled(event)) return EVENT_VISIBILITY.SCHEDULED
  return EVENT_VISIBILITY.DRAFT
}

export const hasAccessToEvent = (event) =>
  event.get('purchaseState') === EVENT_PURCHASE_STATE.IS_PARTICIPANT ||
  event.get('purchaseState') === EVENT_PURCHASE_STATE.IS_HOST

export const isEventPast = (event) => !isEventUpcoming(event)

export const isEventPurchasable = (event) =>
  event.get('purchaseState') === EVENT_PURCHASE_STATE.PURCHASABLE

export const isEventPurchased = (event) =>
  event.get('purchaseState') === EVENT_PURCHASE_STATE.IS_PARTICIPANT
