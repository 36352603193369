import React, { useCallback } from 'react'
import { DateTime } from 'luxon'
import './ChatMessage.scss'
import Avatar from 'components/Avatar'
import clsx from 'clsx'
import { BotAvatarSvg } from 'assets/svg'
import CloseSvg from 'assets/svg/CloseSvg'
import { Map } from 'immutable'
import ReactionList from 'components/ReactionList'
import { createCommentReactionApi, deleteCommentReactionApi } from 'api'
import ReactionButton from 'components/ReactionButton'
import Linkify from 'components/Linkify'

const ChatMessage = ({ message, me, threaded, hasReactions }) => {
  const dateTime = DateTime.fromISO(message.get('createdAt'))
  const avatarSize = threaded ? 20 : 30

  const onReactionCreate = useCallback(
    (reaction) => {
      createCommentReactionApi({ commentId: message.get('id'), reaction })
    },
    [message]
  )

  const onReactionDelete = useCallback(
    (reactionId) => {
      deleteCommentReactionApi({ commentId: message.get('id'), reactionId })
    },
    [message]
  )

  const formattedDateTime =
    dateTime.toRelativeCalendar() === 'today'
      ? dateTime.toFormat('T')
      : dateTime.toFormat('MMM d, H:mm')

  const msg = message
    .get('message')
    .split('^^^')
    .map((msg, idx) => {
      if (/\^\^/.test(msg)) {
        let [name] = msg.split('^^')
        return <strong key={idx}>{name}</strong>
      } else {
        return msg
      }
    })

  return (
    <div
      className={clsx('ChatMessage', {
        other: !me,
        me,
        threaded,
        reactions: hasReactions,
      })}
    >
      <Avatar
        className="chatmessage-avatar"
        url={message.getIn(['user', 'profileImageUrl'])}
        userId={message.getIn(['user', 'id'])}
        height={avatarSize}
        width={avatarSize}
      />
      <div className="chatmessage-content">
        <div className="chatmessage-content-wrapper">
          <div className="chatmessage-header">
            <div className="chatmessage-author">
              {message.getIn(['user', 'name'])}
            </div>
            <div className="chatmessage-datetime">{formattedDateTime}</div>
          </div>
          <div className="chatmessage-message">
            <Linkify>{msg}</Linkify>
          </div>
          {hasReactions && message.get('reactions')?.size > 0 && (
            <ReactionList
              reactions={message.get('reactions')}
              onReactionCreate={onReactionCreate}
              onReactionDelete={onReactionDelete}
              wraps
            />
          )}
          {hasReactions && (
            <ReactionButton onReactionSelected={onReactionCreate} overlay />
          )}
        </div>
      </div>
    </div>
  )
}

export const createServiceChatMessage = ({
  id,
  message,
  actionText,
  onAction,
  closeable,
  createdAt,
}) => {
  return Map({
    id,
    service: true,
    message,
    action: {
      text: actionText,
      onAction,
    },
    closeable,
    createdAt: createdAt != null ? createdAt : new Date().toISOString(),
  })
}

export const ServiceChatMessage = ({ message, onClose }) => {
  return (
    <div className="ChatMessage service">
      <div className="chatmessage-avatar chatmessage-bot-avatar">
        <BotAvatarSvg />
      </div>
      <div className="chatmessage-content">
        <div className="chatmessage-content-wrapper">
          <div className="chatmessage-header">
            <div className="chatmessage-author">Boon</div>
            <div className="chatmessage-datetime">only you</div>
          </div>
          <div className="chatmessage-message">{message.get('message')}</div>
        </div>
        {message.getIn(['action', 'onAction']) && (
          <div
            className="chatmessage-action"
            onClick={message.getIn(['action', 'onAction'])}
          >
            {message.getIn(['action', 'text'])}
          </div>
        )}
        {message.get('closeable') && (
          <div className="chatmessage-close" onClick={onClose}>
            <CloseSvg />{' '}
          </div>
        )}
      </div>
    </div>
  )
}

export default ChatMessage
