import React, { useState } from 'react'
import './EventInsightsHeader.scss'
import { NavLink } from 'react-router-dom'
import {
  URL_EVENT_INSIGHTS_OVERVIEW,
  URL_EVENT_INSIGHTS_SALES,
  URL_EVENT_INSIGHTS_VIEWS,
} from 'urls'
import { BackgroundImage } from 'components/Image'
import { getMuxThumbnail, isEventPublished } from 'utils'
import { DateTime } from 'luxon'
import InsightsContentLoader from 'components/InsightsContentLoader'
import EventPageModal from 'modals/EventPageModal'
import EventInsightsMenu from 'routes/InsightsPage/EventInsightsMenu'

const EventInsightsHeader = ({ event, userSlug, eventSlug, refreshEvent }) => {
  const [showEventModal, setShowEventModal] = useState(false)

  const renderEventModal = () => {
    return (
      <EventPageModal
        onClose={() => setShowEventModal(false)}
        userSlug={
          event.getIn(['host', 'username']) || event.getIn(['host', 'id'])
        }
        eventSlug={event.get('slug') || event.get('id')}
      />
    )
  }

  const renderEvent = () => {
    if (event == null) {
      return (
        <div className="event-insights-header-event">
          <InsightsContentLoader speed={1} width={300} height={67.5}>
            <rect x="0" y="0" rx="4" ry="4" width="120" height="67.5" />
            <rect x="144" y="4" rx="12" ry="12" width="120" height="28" />
            <rect x="144" y="41" rx="6" ry="6" width="80" height="12" />
          </InsightsContentLoader>
        </div>
      )
    }

    const coverImageUrl =
      event.get('coverImageUrl') ||
      getMuxThumbnail(event.get('trailerVideoUrl')) ||
      getMuxThumbnail(event.get('coverVideoUrl'))

    return (
      <div className="event-insights-header-event">
        <div className="event-insights-header-event-cover">
          <BackgroundImage
            className="event-insights-header-event-cover-image"
            src={coverImageUrl}
            onClick={() => setShowEventModal(true)}
          />
        </div>
        <div className="event-insights-header-event-content">
          <EventInsightsMenu
            eventId={event.get('id')}
            eventTitle={event.get('title')}
            eventLive={event.get('live')}
            eventArchived={event.get('archived')}
            refreshEvent={refreshEvent}
          />
          <h1 className="event-insights-header-event-title">
            {event.get('title')}
          </h1>
          <div className="event-insights-header-event-details">
            {isEventPublished(event) && (
              <>
                Published&nbsp;
                {DateTime.fromISO(event.get('publishedAt')).toFormat(
                  'MMM d, yyyy'
                )}
                &nbsp;&nbsp;·&nbsp;&nbsp;
              </>
            )}
            {event.get('duration')} min
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="EventInsightsHeader">
      {renderEvent()}
      {showEventModal && renderEventModal()}
      <div className="event-insights-page-menu">
        <NavLink
          exact
          to={URL_EVENT_INSIGHTS_OVERVIEW(userSlug, eventSlug)}
          className="event-insights-page-menu-item"
        >
          Overview
        </NavLink>
        <NavLink
          exact
          to={URL_EVENT_INSIGHTS_SALES(userSlug, eventSlug)}
          className="event-insights-page-menu-item"
        >
          Sales
        </NavLink>
        <NavLink
          exact
          to={URL_EVENT_INSIGHTS_VIEWS(userSlug, eventSlug)}
          className="event-insights-page-menu-item"
        >
          Views
        </NavLink>
      </div>
    </div>
  )
}

export default EventInsightsHeader
