import React, { useState } from 'react'
import './PostCommentCompose.scss'
import { createPostCommentApi, getImageUploadUrl } from 'api'
import clsx from 'clsx'
import { useSelector } from 'react-redux'
import { getMe } from 'store/selectors'
import Avatar from 'components/Avatar'
import {
  fixImageOrientation,
  getApiError,
  uploadToS3,
  mentionUsers,
  renderMentionUserSuggestion,
} from 'utils'
import { InsertPhotoSvg, SendSvg } from 'assets/svg'
import Spinner from 'components/Spinner'
import { Image } from 'components/Image'
import CloseSvg from 'assets/svg/CloseSvg'
import { POST_ATTACHMENT_TYPES } from 'store/constants/post'
import {
  createNewPostComment,
  createNewPostReply,
} from 'components/ProfileFeed'
import { MentionsInput, Mention } from 'react-mentions'

const PostCommentCompose = ({
  hostId,
  postId,
  commentId,
  small,
  innerRef,
  requireAccess,
  dispatchPosts,
}) => {
  const [error, setError] = useState(null)
  const [posting, setPosting] = useState(false)
  const [message, setMessage] = useState('')
  const [isPhotoUploading, setIsPhotoUploading] = useState(false)
  const [photoId, setPhotoId] = useState(null)
  const [photoUrl, setPhotoUrl] = useState(null)
  const me = useSelector((state) => getMe(state))

  const onSubmit = async () => {
    if (message.length < 1 || isPhotoUploading || posting) {
      return null
    }

    setPosting(true)
    setError(null)

    const data = {
      message,
      parentId: commentId,
    }

    if (photoId) {
      data['attachmentType'] = POST_ATTACHMENT_TYPES.PHOTO
      data['attachmentId'] = photoId
    }

    let newObject
    try {
      newObject = await createPostCommentApi({
        postId,
        data,
      })
      setMessage('')
      setPhotoUrl(null)
      setPhotoId(null)
    } catch (e) {
      setError(getApiError(e))
    }

    if (newObject != null) {
      if (commentId) {
        dispatchPosts(
          createNewPostReply({
            postId: postId,
            commentId: commentId,
            replyId: newObject.get('id'),
            reply: newObject,
          })
        )
      } else {
        dispatchPosts(
          createNewPostComment({
            postId: postId,
            commentId: newObject.get('id'),
            comment: newObject,
          })
        )
      }
    }

    setPosting(false)
  }

  const onFocus = () => {
    requireAccess()
  }

  const onPhotoSelect = async (e) => {
    try {
      setIsPhotoUploading(true)
      const inputFile = e.target.files[0]
      const file = await fixImageOrientation(inputFile)
      const { url, urlFields, photoId } = await getImageUploadUrl({
        filename: inputFile.name,
      })
      await uploadToS3({
        url,
        urlFields,
        file,
      })

      setPhotoUrl(URL.createObjectURL(file))
      setPhotoId(photoId)
    } catch (e) {
      // Pass
    }

    setIsPhotoUploading(false)
  }

  const onPhotoRemove = () => {
    setPhotoId(null)
    setPhotoUrl(null)
  }

  const photoInputId = `${postId}-${commentId}-photo`

  const mentionStyle = {
    '&multiLine': {
      input: {
        height: null,
        minHeight: 20,
        padding: '8px 14px',
        border: 'none',
        outline: 'none',
      },
    },
    highlighter: {
      outline: 'none',
      border: 'none',
    },
  }

  const mentionInnerStyle = {
    backgroundColor: '#68a0f2',
  }

  return (
    <div
      className={clsx('PostCommentCompose', {
        empty: message === '',
        small,
      })}
    >
      <div className="post-comment-compose-wrapper">
        <div className="post-comment-compose-avatar-wrapper">
          <Avatar
            url={me.get('profileImageUrl')}
            userId={me.get('id')}
            width={32}
            height={32}
            className="post-comment-compose-avatar"
          />
        </div>
        <div className="post-comment-compose-message-wrapper">
          <div className="post-comment-compose-message">
            <div className="post-comment-compose-message-row">
              <MentionsInput
                inputRef={innerRef}
                className="post-comment-compose-message-field"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                onFocus={onFocus}
                disabled={posting}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && e.shiftKey === false) {
                    onSubmit()
                  }
                }}
                placeholder={small ? 'Reply...' : 'Comment...'}
                style={mentionStyle}
                allowSpaceInQuery={true}
              >
                <Mention
                  trigger="@"
                  markup="^^^__display__^^__id__^^^"
                  data={mentionUsers(hostId, me)}
                  renderSuggestion={renderMentionUserSuggestion(
                    'post-comment-compose-message-field__suggestions-user',
                    'post-comment-compose-tag-suggestion-avatar'
                  )}
                  style={mentionInnerStyle}
                />
              </MentionsInput>
              {!photoId && (
                <div className="post-comment-compose-attachment-bar">
                  <label
                    htmlFor={photoInputId}
                    className="post-comment-compose-attachment-bar-attachment post-comment-compose-attachment-bar-attachment-photo"
                  >
                    {isPhotoUploading ? (
                      <Spinner tiny light />
                    ) : (
                      <InsertPhotoSvg />
                    )}
                  </label>
                  <input
                    type="file"
                    className="post-comment-compose-attachment-bar-attachment-photo-input"
                    disabled={isPhotoUploading}
                    onChange={onPhotoSelect}
                    id={photoInputId}
                    accept="image/*"
                  />
                </div>
              )}
            </div>
            {photoId && (
              <div className="photo-comment-compose-attachment-photo">
                <Image
                  className="photo-comment-compose-attachment-photo-image"
                  src={photoUrl}
                />
                <CloseSvg
                  className="photo-comment-compose-attachment-photo-remove"
                  onClick={onPhotoRemove}
                />
              </div>
            )}
          </div>
          {error != null && <div className="input-error">{error?.message}</div>}
        </div>
        <div className="post-comment-compose-right">
          {posting && <Spinner light tiny />}
          {message.length > 0 && (
            <SendSvg
              onClick={onSubmit}
              className={clsx('post-comment-compose-right-send', {
                disabled: posting,
              })}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default PostCommentCompose
