import React from 'react'
import { useHistory } from 'react-router-dom'
import './UserAboutBox.scss'
import { getMuxGif, getMuxThumbnail, getUserUrl } from 'utils'
import clsx from 'clsx'
import UserSocial from './UserSocial'
import { BackgroundImage } from 'components/Image'
import Giffer, { useGiffer } from 'components/EventCard/Giffer'
import PlayButton from 'components/PlayButton'
import LimitedReactMarkdown from 'components/LimitedReactMarkdown'

const UserAboutBox = ({ user }) => {
  const history = useHistory()
  const videoUrl = user.get('coverVideoUrl')
  const trailerGif = getMuxGif(videoUrl)
  let { onMouseEnter, onMouseLeave, playing: playingGif } = useGiffer({
    src: trailerGif,
  })
  const poster = getMuxThumbnail(videoUrl)
  const hasVideo = videoUrl != null

  const handleOnClick = () => {
    history.push(`${getUserUrl(user)}/about`)
  }

  const renderMedia = () => {
    return (
      <div
        className="user-about-box-cover-wrapper"
        onClick={handleOnClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <BackgroundImage
          className="user-about-box-cover"
          src={poster}
          imgixParams={{ crop: 'faces' }}
        >
          {trailerGif && <Giffer src={trailerGif} playing={playingGif} />}
          <PlayButton className="user-about-box-cover-play-button" />
        </BackgroundImage>
      </div>
    )
  }

  return (
    <div className="UserAboutBox">
      {hasVideo && <div className="user-about-box-media">{renderMedia()}</div>}
      <div
        className={clsx('user-about-box-container', { 'no-video': !hasVideo })}
      >
        <div className="user-about-box-info">
          <div className="user-about-box-description">
            <LimitedReactMarkdown>{user.get('bio')}</LimitedReactMarkdown>
          </div>
        </div>
      </div>
      <div className="user-about-box-continue-reading" onClick={handleOnClick}>
        Continue Reading
      </div>
      <UserSocial user={user} />
    </div>
  )
}

export default UserAboutBox
