// Extracts generic API error messages
export const getApiError = (e) => {
  return (
    e.response?.data ?? {
      message: 'Something went wrong, check your internet connection',
    }
  )
}

// WARNING: this method is NOT immutable
export const removeNullObjectValues = (obj) =>
  Object.keys(obj).forEach((key) => obj[key] == null && delete obj[key])
