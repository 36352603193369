import { Record, Map, List } from 'immutable'
import { AUTH_MODAL_STATE } from 'store/constants'

export const AuthMeSchema = Record(
  {
    id: null,
    email: null,
    name: null,
    realName: null,
    nameIsSetAt: null,
    bio: null,
    countryCode: null,
    profileImageUrl: null,
    creatorStatus: null,
    createdAt: null,
    username: null,
    following: Map(),
    followerCount: null,
    memberCount: null,
    subscriptionAccounts: Map(),
    activeSubscriptions: List(),
    pastDueSubscriptions: List(),
    hasPayoutAccount: null,
    referralCode: null,
    currency: null,
    profileStatus: null,
  },
  'authMe'
)

export const AuthSchema = Record(
  {
    accessToken: null,
    accessTokenExpires: null,
    refreshToken: null,
    me: AuthMeSchema(),
    sudo: false,
  },
  'auth'
)

export const UiSchema = Record(
  {
    isHydrated: false,
    isNotFound: false,
    modals: Map({
      auth: Map({
        isActive: false,
        state: AUTH_MODAL_STATE.SIGNIN,
        newlyCreated: false,
        message: null,
      }),
    }),
    header: Map({
      dark: false,
    }),
  },
  'ui'
)
