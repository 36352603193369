import React, { useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { getMe } from 'store/selectors'
import './EventMedia.scss'
import { DateTime } from 'luxon'
import { useInterval } from 'utils'
import axios from 'axios'
import Spinner from 'components/Spinner'
import WatchWaitingRoom from 'routes/WatchPage/components/WatchWaitingRoom'
import WatchVideoPlayer from 'routes/WatchPage/components/WatchVideoPlayer'
import EventStartWaitingRoom from './EventStartWaitingRoom'
import { GroupSvg } from 'assets/svg'
import { usePrevious } from 'react-use'

const VIDEO_STATE = {
  UNKNOWN: 'UNKNOWN',
  NO_URL: 'NO_URL',
  NOT_STARTED: 'NOT_STARTED',
  DISCONNECTED: 'DISCONNECTED',
  NETWORK_ERROR: 'NETWORK_ERROR',
  CONNECTED: 'CONNECTED',
}

const EventMedia = ({ event, refreshEvent, viewerCount, liveEventEnded }) => {
  const eventId = event.get('id')
  const [videoState, setVideoState] = useState(VIDEO_STATE.UNKNOWN)
  const [videoKey, setVideoKey] = useState(1)
  const [isWaiting, setWaiting] = useState(false)
  const wasWaiting = usePrevious(isWaiting)
  const [isEnded, setEnded] = useState(false)
  const [url, setUrl] = useState(null)
  const me = useSelector((state) => getMe(state))

  const getIsWaiting = useCallback(() => {
    return event
      ? DateTime.fromISO(event.get('startTime')).diffNow('milliseconds')
          .milliseconds > 0
      : true
  }, [event])

  const getUrl = useCallback(() => {
    return (
      event != null && (event.get('recordedVideoUrl') || event.get('streamUrl'))
    )
  }, [event])

  // When the event changes, set waiting and url
  useEffect(() => {
    if (event != null) {
      setWaiting(getIsWaiting())
      setUrl(getUrl())
      setEnded(event.get('ended'))

      if (url == null) {
        setVideoState(VIDEO_STATE.NO_URL)
      }
    }
  }, [event, getIsWaiting, getUrl, url])

  useEffect(() => {
    if (liveEventEnded) {
      setEnded(true)
    }
  }, [liveEventEnded])

  // Every second, see if waiting changes
  useInterval(
    () => {
      setWaiting(getIsWaiting())
    },
    event != null ? 1000 : null
  )

  // If no longer waiting, refetch event url
  useEffect(() => {
    if (!isWaiting && wasWaiting) {
      refreshEvent()
    }
  }, [isWaiting, wasWaiting, eventId, refreshEvent])

  // If we're not waiting anymore and there's no url,
  // go get the URL every n seconds
  useInterval(
    () => {
      if (url == null) {
        refreshEvent()
      }
    },
    url == null && !isWaiting ? 10 * 1000 : null
  )

  useEffect(() => {
    if (
      isEnded &&
      (videoState === VIDEO_STATE.DISCONNECTED ||
        videoState === VIDEO_STATE.NOT_STARTED)
    ) {
      refreshEvent()
    }
  }, [isEnded, videoState, refreshEvent])

  const investigatePlaylist = useCallback(() => {
    axios
      .get(url)
      .then((r) => {
        const rel = r.data.split('\n').find((e) => e.includes('.m3u8'))
        const chunkUrl = new URL(rel, url).href
        axios
          .get(chunkUrl)
          .then((r) => {
            if (videoState !== VIDEO_STATE.CONNECTED) {
              setVideoKey(videoKey + 1)
              setVideoState(VIDEO_STATE.CONNECTED)
            }
          })
          .catch(() => {
            // Video was started before
            setVideoState(VIDEO_STATE.DISCONNECTED)
          })
      })
      .catch((e) => {
        if (e.response) {
          // HTTP response code, 404 etc.
          setVideoState(VIDEO_STATE.NOT_STARTED)
        } else {
          // Network error
          setVideoState(VIDEO_STATE.NETWORK_ERROR)
        }
      })
  }, [url, videoKey, videoState])

  // If we have an URL, but the player is not CONNECTED yet
  // test the URL
  useInterval(
    investigatePlaylist,
    videoState !== VIDEO_STATE.CONNECTED && url != null ? 2 * 1000 : null
  )

  const renderPlayerFrame = () => {
    if (event.get('recordedVideoUrl') != null) {
      return (
        <WatchVideoPlayer
          key={url}
          event={event}
          url={url}
          meId={me.get('id')}
        />
      )
    }

    if (isWaiting && videoState !== VIDEO_STATE.CONNECTED) {
      return <EventStartWaitingRoom event={event} />
    }

    switch (videoState) {
      case VIDEO_STATE.CONNECTED:
        return (
          <WatchVideoPlayer
            key={url + videoKey}
            event={event}
            url={url}
            meId={me.get('id')}
            onPlaybackFailed={investigatePlaylist}
            onWaiting={investigatePlaylist}
          />
        )
      case VIDEO_STATE.UNKNOWN:
      case VIDEO_STATE.NO_URL:
        return (
          <>
            <Spinner light />
          </>
        )
      case VIDEO_STATE.NOT_STARTED:
        return (
          <WatchWaitingRoom
            event={event}
            title="Going live any minute"
            subtitle={`Waiting for ${event.getIn(['host', 'name'])} to go live`}
            loading
          />
        )
      case VIDEO_STATE.DISCONNECTED:
        return (
          <WatchWaitingRoom
            event={event}
            title="Host has disconnected, waiting"
            subtitle={`Waiting for ${event.getIn([
              'host',
              'name',
            ])} to go live again`}
            loading
          />
        )
      case VIDEO_STATE.NETWORK_ERROR:
        return (
          <WatchWaitingRoom
            event={event}
            title="Connection lost, reconnecting"
            subtitle="Please check your internet connection"
            loading
          />
        )
      default:
        return <WatchWaitingRoom event={event} />
    }
  }

  const renderViewerCount = () => {
    if (viewerCount == null) {
      return null
    }

    return (
      <div className="event-media-viewer-count">
        <GroupSvg /> {viewerCount}
      </div>
    )
  }

  return (
    <div className="EventMedia">
      <div className="event-media-inner">
        {renderPlayerFrame()}
        {renderViewerCount()}
      </div>
    </div>
  )
}

export default EventMedia
