import React, { useCallback, useReducer, useState } from 'react'
import './PollBasic.scss'
import { Map, Set } from 'immutable'
import clsx from 'clsx'
import { ArrowRightSvg } from 'assets/svg'
import { pollVoteApi } from 'api'
import Avatar from 'components/Avatar'
import { PollBasicOption } from 'components/Poll/PollBasicOption'
import {
  LIMIT_NON_INTERACTIVE,
  POLL_BASIC_THEME_CLASS_NAMES,
  SELECT_ONLY_OPTION,
  SELECT_OPTION,
  TOGGLE_OPTION,
  UNSELECT_OPTION,
} from 'components/Poll/pollBasicConstants'
import { PollBasicAddOption } from 'components/Poll/PollBasicAddOption'
import { DateTime } from 'luxon'

const PollBasic = ({
  poll,
  hasAccess,
  onClick,
  onNoAccess,
  interactive,
  compact,
  onVoteClick,
  onPostAttachmentUpdate,
  headerOptionComponent,
}) => {
  const pollId = poll.get('id')
  const total = poll.get('votesCount')
  const votersCount = poll.get('votersCount')
  const canAddMoreOptions = poll.get('canAddMoreOptions')
  const multipleVotesPerUser = poll.get('multipleVotesPerUser')
  const ended = poll.get('ended')
  const pollTheme = poll.get('theme')
  const [selectedOptions, dispatchOption] = useReducer((state, action) => {
    switch (action.type) {
      case SELECT_OPTION:
        return state.set(action.id, true)
      case UNSELECT_OPTION:
        return state.remove(action.id)
      case TOGGLE_OPTION:
        return state.update(action.id, (s) => !s, false)
      case SELECT_ONLY_OPTION:
        return Map({ [action.id]: true })
      default:
        return state
    }
  }, Map(poll.get('myVotes').map((id) => [id, true])))
  const selectedOptionsList = selectedOptions.filter((v) => v).keySeq()
  const isClean = Set(selectedOptionsList).equals(Set(poll.get('myVotes')))

  const onOptionClick = useCallback(
    (optionId) => {
      if (!hasAccess) {
        onNoAccess()
        return
      }

      dispatchOption({
        type: multipleVotesPerUser ? TOGGLE_OPTION : SELECT_ONLY_OPTION,
        id: optionId,
      })

      if (onVoteClick) {
        onVoteClick()
      }
    },
    [onVoteClick, multipleVotesPerUser, hasAccess, onNoAccess, dispatchOption]
  )

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const onSubmitVotesClick = useCallback(async () => {
    const options = selectedOptionsList.toArray()
    setLoading(true)
    setError(false)

    let poll
    try {
      poll = await pollVoteApi({ pollId, optionIds: options })
    } catch (e) {
      setLoading(false)
      setError(true)
      return
    }

    onPostAttachmentUpdate(poll)
    setLoading(false)
  }, [pollId, onPostAttachmentUpdate, selectedOptionsList])

  const renderOptions = () => {
    const sortedOptions = poll.get('pollOptions').sortBy(
      (o) => [o.get('votesCount'), o.get('order'), o.get('createdAt')],
      ([aVotes, aOrder, aCreatedAt], [bVotes, bOrder, bCreatedAt]) => {
        if (aVotes === bVotes) {
          if (aOrder != null && bOrder != null) {
            return aOrder - bOrder
          } else if (aOrder != null && bOrder == null) {
            return aOrder
          } else if (bOrder != null && aOrder == null) {
            return bOrder
          }
          return aCreatedAt - bCreatedAt
        } else {
          return bVotes > aVotes ? 1 : -1
        }
      }
    )

    return (interactive
      ? sortedOptions
      : sortedOptions.take(LIMIT_NON_INTERACTIVE)
    ).map((option) => (
      <PollBasicOption
        key={option.get('id')}
        active={poll.get('myVotes').includes(option.get('id'))}
        selected={selectedOptions.get(option.get('id')) === true}
        option={option}
        ended={ended}
        total={total}
        interactive={interactive}
        compact={compact}
        onClick={onOptionClick}
      />
    ))
  }

  const renderExtra = () => {
    if (interactive) {
      if (!canAddMoreOptions || ended) {
        return null
      }

      return (
        <PollBasicAddOption
          pollId={pollId}
          poll={poll}
          onPostAttachmentUpdate={onPostAttachmentUpdate}
          dispatchOption={dispatchOption}
          multipleVotesPerUser={multipleVotesPerUser}
        />
      )
    } else {
      const diff = poll.get('pollOptions').size - LIMIT_NON_INTERACTIVE
      if (poll.get('pollOptions').size === 0) {
        return (
          <div className="poll-basic-options-more" onClick={onVoteClick}>
            + Add option
          </div>
        )
      }

      if (diff > 0) {
        return (
          <div className="poll-basic-options-more" onClick={onVoteClick}>
            + {diff} {diff > 1 ? 'options' : 'option'}
          </div>
        )
      }
    }
  }

  const renderSubmit = () => {
    if (!interactive || ended) {
      return null
    }

    return (
      <button
        className={clsx('button primary light load poll-basic-submit', {
          loading,
        })}
        onClick={onSubmitVotesClick}
        disabled={isClean}
      >
        Submit
      </button>
    )
  }

  const renderTime = () => {
    if (ended) {
      return 'Ended'
    }

    if (poll.get('endTime') == null) {
      return null
    }

    return (
      <>
        <div className="poll-basic-live" />
        {DateTime.fromISO(poll.get('endTime'))
          .toRelative()
          .replace('in ', '')}{' '}
        left
      </>
    )
  }

  const renderFooterCta = () => {
    if (!interactive) {
      return (
        <div className="poll-basic-vote-cta" onClick={onVoteClick}>
          {ended ? 'Results' : 'Vote'} <ArrowRightSvg />
        </div>
      )
    }

    return null
  }

  const renderVoters = () => {
    const profileImages = poll
      .get('voters')
      .reverse()
      .map((user) => (
        <Avatar
          height={24}
          width={24}
          className="poll-basic-voters-avatar"
          url={user.get('profileImageUrl')}
          userId={user.get('id')}
          key={user.get('id')}
        />
      ))

    return <div className="poll-basic-voters">{profileImages}</div>
  }

  return (
    <div
      className={clsx(
        'PollBasic',
        { interactive, compact },
        POLL_BASIC_THEME_CLASS_NAMES[pollTheme]
      )}
      onClick={onClick}
    >
      <div className="poll-basic-wrapper">
        <div className="poll-basic-header">
          <div className="poll-basic-header-options">
            {headerOptionComponent}
          </div>
        </div>
        <div className="poll-basic-title">{poll.get('message')}</div>
        {renderOptions()}
        {renderExtra()}
        {renderSubmit()}
        {votersCount > 0 && (
          <div className="poll-basic-voters-wrapper">
            {renderVoters()}
            <div className="poll-basic-votes">
              {votersCount} {votersCount === 1 ? 'voter' : 'voters'}
            </div>
          </div>
        )}
        <div className="poll-basic-footer">
          <div className="poll-basic-time">{renderTime()}</div>
          {renderFooterCta()}
        </div>
        {error && (
          <div className="input-error">An error occurred, please try again</div>
        )}
      </div>
    </div>
  )
}

export default PollBasic
