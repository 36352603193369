import React, { useEffect } from 'react'
import './AccountingPage.scss'
import PayoutListItem from './PayoutListItem'
import { NarrowContainer } from 'components/Grid'
import { useHistory } from 'react-router-dom'
import Spinner from 'components/Spinner'
import { useGetPayoutsApi, useGetBalanceApi } from 'api'
import { Map } from 'immutable'
import { useSelector } from 'react-redux'
import { isAuthenticated } from 'store/selectors'
import { URL_ACCOUNTING, URL_CREATOR_SETTINGS_PAYOUT } from 'urls'
import LoadingError from 'components/LoadingError'
import CreatorSidebar from 'components/CreatorSidebar'
import { Link } from 'react-router-dom'

const AccountingPage = () => {
  const { data: balance } = useGetBalanceApi()
  const {
    data: payouts,
    error: payoutsError,
    refresh: payoutsRefresh,
  } = useGetPayoutsApi()
  const history = useHistory()
  const hasAuth = useSelector((state) => isAuthenticated(state))

  useEffect(() => {
    if (!hasAuth) {
      history.push(`/sign-in?await_auth=true&redirect_url=${URL_ACCOUNTING()}`)
    }
  }, [history, hasAuth])

  const renderPayoutList = () => {
    if (payouts == null || balance == null) {
      return <Spinner />
    }

    let payoutCards = payouts
      .sortBy((p) => p.get('arrivalDate'))
      .reverse()
      .map((payout) => (
        <PayoutListItem payout={payout} key={payout.get('id')} />
      ))

    return <div className="accounting-payout-list">{payoutCards}</div>
  }

  if (payoutsError) {
    if (
      payoutsError?.response?.data?.message === 'No Stripe Account was found'
    ) {
      return (
        <CreatorSidebar>
          <LoadingError
            refresh={payoutsRefresh}
            message="A Payout account is required"
          >
            <Link
              to={URL_CREATOR_SETTINGS_PAYOUT}
              className="button primary light"
            >
              Go to Payout settings
            </Link>
          </LoadingError>
        </CreatorSidebar>
      )
    } else {
      return (
        <CreatorSidebar>
          <LoadingError refresh={payoutsRefresh}>
            {payoutsError?.response?.data?.message}
          </LoadingError>
        </CreatorSidebar>
      )
    }
  }

  return (
    <CreatorSidebar>
      <div className="AccountingPage">
        <NarrowContainer>
          <div className="accounting-title">Payouts</div>
          {renderPayoutList()}
        </NarrowContainer>
      </div>
    </CreatorSidebar>
  )
}

export default AccountingPage
