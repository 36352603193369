import React, { useState } from 'react'
import './UserDashboardEvents.scss'
import Spinner from 'components/Spinner'
import EventCard from 'components/EventCard'
import { Row, Col } from 'components/Grid'
import ShowMore from 'components/ShowMore'

const UserDashboardEvents = ({
  title,
  emptyText,
  events,
  loading,
  error,
  large,
  refreshEvent,
}) => {
  const [showAll, setShowAll] = useState(false)

  const renderChildren = () => {
    if (loading) {
      return (
        <div className="user-dashboard-events-box">
          <Spinner small light />
        </div>
      )
    }

    if (error) {
      return (
        <div className="user-dashboard-events-box">
          <div className="user-dashboard-events-error">
            We encountered an error, please try again later
          </div>
        </div>
      )
    }

    if (events != null && events.size > 0) {
      const cards = (showAll ? events : events.take(2)).map((event) => {
        return (
          <Col xs={12} lg={large ? 12 : 6} key={event.get('id')}>
            <EventCard
              key={event.get('id')}
              event={event}
              refreshEvent={refreshEvent}
            />
          </Col>
        )
      })

      return (
        <div className="user-dashboard-events-container">
          <div className="user-dashboard-events-content">
            <Row className="slim-gutters">{cards}</Row>
          </div>
          {events.size > 2 && (
            <ShowMore active={showAll} onClick={() => setShowAll(!showAll)} />
          )}
        </div>
      )
    }

    return (
      <div className="user-dashboard-events-box">
        <div className="user-dashboard-events-empty-text">{emptyText}</div>
      </div>
    )
  }

  return (
    <div className="UserDashboardEvents">
      <div className="user-dashboard-events-title">{title}</div>
      {renderChildren()}
    </div>
  )
}

export default UserDashboardEvents
