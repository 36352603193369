/*
  Used for scrolling to an anchor with react-router-hash-link.
  This function is needed as regular anchor scrolling disregards the header,
  and thus visually scrolls a bit past the desired content.
 */
export const scrollWithOffset = (offset) => (el) => {
  try {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset
    window.scroll({
      top: yCoordinate - offset,
      behavior: 'smooth',
    })
  } catch (e) {
    console.error('Scrolling failed...')
  }
}
