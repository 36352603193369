import React from 'react'
import Spinner from 'components/Spinner'

import { displayPrice } from 'utils'
import { useGetTransactionApi } from 'api'
import { DateTime } from 'luxon'

import './RelationsMenuModalTransaction.scss'

const RelationsMenuTransactionTable = ({ user, transactionId }) => {
  const formatDate = (date) => DateTime.fromISO(date).toFormat('MMM d, y')

  const { data: transaction } = useGetTransactionApi({
    transactionId,
    disabled: transactionId === null,
  })

  if (transactionId === null) {
    return null
  }

  if (transaction === null) {
    return <Spinner />
  }

  return (
    <div className="relations-menu-modal-transactions">
      <h3 className="relations-menu-modal-transactions-header">Transaction</h3>
      <div className="relations-menu-modal-transaction">
        <div className="relations-menu-modal-transaction-name">
          {transaction.get('item')}
        </div>
        <div className="relations-menu-modal-transaction-status relations-menu-modal-tag">
          Purchase
        </div>
        <div className="relations-menu-modal-transaction-date">
          {formatDate(transaction.get('createdAt'))}
        </div>
        <table className="relations-menu-modal-table">
          <tbody>
            <tr>
              <td>Billing</td>
              <td>{transaction.get('billing')}</td>
            </tr>
            <tr>
              <td>Price</td>
              <td>
                {displayPrice(
                  transaction.getIn(['amount', 0]),
                  transaction.getIn(['amount', 1])
                )}
              </td>
            </tr>
            <tr>
              <td>Discount</td>
              <td>{transaction.get('discount')}</td>
            </tr>
            <tr>
              <td>Charged</td>
              <td>
                {displayPrice(
                  transaction.getIn(['charged', 0]),
                  transaction.getIn(['charged', 1])
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default RelationsMenuTransactionTable
