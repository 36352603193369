import React from 'react'
import './PostCardAttachmentPhoto.scss'
import { Image } from 'components/Image'
import PhotoBlurred from 'assets/img/photo-blurred.jpg'
import { POST_ATTACHMENT_ACCESS } from 'store/constants/post'
import UnlockButton from 'components/UnlockButton'
import PostCardAttachmentHeader from 'components/PostCard/PostCardAttachmentHeader'

const PostCardAttachmentPhoto = ({
  photo,
  access,
  onNoAccess,
  showAttachmentModal,
  post,
}) => {
  const renderInner = () => {
    switch (access) {
      case POST_ATTACHMENT_ACCESS.NONE:
        return (
          <>
            <Image
              className="post-card-attachment-photo-image"
              src={photo.get('fullPath') || PhotoBlurred}
              width="800"
            />
            <div
              className="post-card-attachment-photo-locked"
              onClick={showAttachmentModal}
            >
              <UnlockButton
                onClick={() => onNoAccess('Unlock to view this photo')}
              />
            </div>
          </>
        )
      default:
        return (
          <div onClick={showAttachmentModal}>
            <Image
              className="post-card-attachment-photo-image"
              src={photo.get('fullPath') || PhotoBlurred}
              width="800"
            />
          </div>
        )
    }
  }

  return (
    <div className="PostCardAttachmentPhoto">
      {renderInner()}
      <PostCardAttachmentHeader post={post} onNoAccess={onNoAccess} dark />
    </div>
  )
}

export default PostCardAttachmentPhoto
