export const INTERVAL_QUARTERLY_ACTIVE_KEY = 'quarterlyActive'
export const INTERVAL_QUARTERLY_DISCOUNT_KEY = 'quarterlyDiscount'
export const INTERVAL_SEMI_ANNUALLY_ACTIVE_KEY = 'semiAnnuallyActive'
export const INTERVAL_SEMI_ANNUALLY_DISCOUNT_KEY = 'semiAnnuallyDiscount'
export const INTERVAL_ANNUALLY_ACTIVE_KEY = 'annuallyActive'
export const INTERVAL_ANNUALLY_DISCOUNT_KEY = 'annuallyDiscount'

export const getUpsertPlansFromFormValues = (values) => {
  return [
    {
      active: true,
      base: true,
      amount: Math.round(values.amount * 100),
      currency: values.currency,
      discount: null,
      interval: 2,
      interval_count: 1,
      lock_in_months: 0,
    },
    {
      active: values[INTERVAL_QUARTERLY_ACTIVE_KEY],
      base: false,
      currency: values.currency,
      discount: values[INTERVAL_QUARTERLY_DISCOUNT_KEY],
      interval: 2,
      interval_count: 3,
      lock_in_months: 0,
    },
    {
      active: values[INTERVAL_QUARTERLY_ACTIVE_KEY],
      base: false,
      currency: values.currency,
      discount: values[INTERVAL_QUARTERLY_DISCOUNT_KEY],
      interval: 2,
      interval_count: 1,
      lock_in_months: 3,
    },
    {
      active: values[INTERVAL_SEMI_ANNUALLY_ACTIVE_KEY],
      base: false,
      currency: values.currency,
      discount: values[INTERVAL_SEMI_ANNUALLY_DISCOUNT_KEY],
      interval: 2,
      interval_count: 6,
      lock_in_months: 0,
    },
    {
      active: values[INTERVAL_SEMI_ANNUALLY_ACTIVE_KEY],
      base: false,
      currency: values.currency,
      discount: values[INTERVAL_SEMI_ANNUALLY_DISCOUNT_KEY],
      interval: 2,
      interval_count: 1,
      lock_in_months: 6,
    },
    {
      active: values[INTERVAL_ANNUALLY_ACTIVE_KEY],
      base: false,
      currency: values.currency,
      discount: values[INTERVAL_ANNUALLY_DISCOUNT_KEY],
      interval: 3,
      interval_count: 1,
      lock_in_months: 0,
    },
    {
      active: values[INTERVAL_ANNUALLY_ACTIVE_KEY],
      base: false,
      currency: values.currency,
      discount: values[INTERVAL_ANNUALLY_DISCOUNT_KEY],
      interval: 2,
      interval_count: 1,
      lock_in_months: 12,
    },
  ]
}
