import React from 'react'
import './EventButton.scss'
import { EventAvailableSvg } from 'assets/svg'
import clsx from 'clsx'
import { EVENT_BUTTON_TYPE } from 'components/EventButton/EventButton'

const EventButtonAttending = ({ className, event, type }) => {
  return (
    <button className={clsx('button button-attending', className)}>
      {type === EVENT_BUTTON_TYPE.LARGE ? (
        <>
          <EventAvailableSvg /> Attending
        </>
      ) : (
        <EventAvailableSvg />
      )}
    </button>
  )
}

export default EventButtonAttending
