import React from 'react'
import './UnlockButton.scss'
import { LockSvg } from 'assets/svg'
import clsx from 'clsx'

const UnlockButton = ({ className, onClick, ...props }) => {
  const _onClick = (e) => {
    e.stopPropagation()
    e.preventDefault()
    onClick()
  }

  return (
    <button
      className={clsx('button secondary small UnlockButton', className)}
      onClick={_onClick}
      {...props}
    >
      <LockSvg className="unlock-button-icon" /> Unlock
    </button>
  )
}

export default UnlockButton
