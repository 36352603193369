import React from 'react'
import EventEditForm from 'components/EventEditForm'
import { Helmet } from 'react-helmet-async'
import { NarrowContainer } from 'components/Grid'
import { withRouter } from 'react-router-dom'
import * as Yup from 'yup'
import { updateEvent, useGetEventApi, useGetUserApi } from 'api'
import {
  asyncModal,
  getApiError,
  getEventUrl,
  isEventPast,
  isEventPublished,
} from 'utils'
import { getMe } from 'store/selectors'
import { useSelector } from 'react-redux'
import EventHostHeader from 'components/EventHostHeader'
import Spinner from 'components/Spinner'
import './EventEditPage.scss'
import UploadForm from 'routes/UploadPage/UploadForm'
import HeaderProfile from 'components/HeaderProfile'
import {
  availabilityInitialValues,
  availabilityParseValues,
  availabilityValidations,
} from 'routes/UploadPage/availabilityUtils'
import {
  shouldPromptForPublish,
  updateEventVisibility,
  visibilityInitialValues,
} from 'components/VisibilityPanel/visibilityUtils'
import EventPublishModal from 'modals/EventPublishModal'
import { useToasts } from 'react-toast-notifications'
import { Formik } from 'formik'
import EventVisibilityModal from 'modals/EventVisibilityModal'

const getLiveEventEditPageValidation = (ended) =>
  Yup.object().shape({
    description: Yup.string().required('Required'),
    ...availabilityValidations,
    startTime: ended
      ? undefined
      : Yup.date()
          .min(
            new Date(new Date().getTime() + 10 * 60000),
            'Must be at least 10 minutes from now'
          )
          .required('Required'),
  })

const OnDemandEventEditPageValidation = Yup.object().shape({
  title: Yup.string().required('Required'),
  slug: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
  ...availabilityValidations,
})

const EventEditPage = ({ match, history }) => {
  const me = useSelector((state) => getMe(state))
  const { addToast } = useToasts()
  const { data: user } = useGetUserApi({
    userId: me.get('id'),
  })
  const { data: originalEvent } = useGetEventApi({
    userSlug: match.params.userSlug,
    eventSlug: match.params.eventSlug,
  })

  if (originalEvent == null || user == null) {
    return <Spinner light />
  }

  const live = originalEvent.get('live')

  const handleFormSubmit = async (values, { setSubmitting, setErrors }) => {
    let notifyFollowers
    console.log(initialValues, values)
    if (shouldPromptForPublish(initialValues.visibility, values.visibility)) {
      const publishResult = await asyncModal(
        ({ active, onSubmit, onDismiss }) => (
          <EventPublishModal
            active={active}
            values={values}
            onClose={onDismiss}
            onPublish={onSubmit}
          />
        )
      )

      if (publishResult.error) {
        return null
      }

      notifyFollowers = publishResult.result
    }

    let data
    if (live) {
      data = {
        title: values.title,
        slug: values.slug,
        startTime: values.startTime,
        duration: values.duration,
        ...availabilityParseValues(values),
        description: values.description,
        coverImageId: values.coverImageId,
        coverVideoUploadId: values.coverVideoUploadId,
        test: values.test,
        categoryIds: values.categoryIds,
      }
    } else {
      data = {
        title: values.title,
        slug: values.slug,
        ...availabilityParseValues(values),
        description: values.description,
        recordedVideoUploadId: values.recordedVideoUploadId,
        coverImageId: values.coverImageId,
        categoryIds: values.categoryIds,
      }
    }

    try {
      const event = await updateEvent({
        id: originalEvent.get('id'),
        data,
      })

      try {
        const updatedEvent = await updateEventVisibility(
          event.get('id'),
          initialValues.visibility,
          values.visibility,
          initialValues.publishAt,
          values.publishAt,
          notifyFollowers
        )

        if (updatedEvent) {
          asyncModal(({ active, onSubmit }) => (
            <EventVisibilityModal
              active={active}
              onClose={onSubmit}
              event={updatedEvent}
            />
          ))
        }
      } catch (e) {
        addToast(`Could change visibility on the ${live ? 'event' : 'video'}`, {
          appearance: 'error',
        })
      }

      history.replace(getEventUrl(event))
    } catch (e) {
      setErrors(getApiError(e))
    }

    setSubmitting(false)
  }

  const originalEventPast = originalEvent.get('startTime')
    ? isEventPast(originalEvent)
    : false

  const initialValues = live
    ? {
        title: originalEvent.get('title'),
        slug: originalEvent.get('slug'),
        startTime: new Date(originalEvent.get('startTime')),
        duration: originalEvent.get('duration'),
        seats: originalEvent.get('seats'),
        ...availabilityInitialValues(originalEvent, me),
        description: originalEvent.get('description'),
        coverImageFile: null,
        coverImageUrl: originalEvent.get('coverImageUrl'),
        coverVideoUrl: originalEvent.get('coverVideoUrl'),
        live: true,
        test: originalEvent.get('test'),
        categoryIds: originalEvent.get('categoryIds'),
        ...visibilityInitialValues(originalEvent),
      }
    : {
        title: originalEvent.get('title'),
        slug: originalEvent.get('slug'),
        ...availabilityInitialValues(originalEvent, me),
        live: false,
        description: originalEvent.get('description'),
        recordedVideoUploadId: null,
        recordedVideoUrl: originalEvent.get('recordedVideoUrl'),
        coverImageUrl: originalEvent.get('coverImageUrl'),
        categoryIds: originalEvent.get('categoryIds'),
        ...visibilityInitialValues(originalEvent),
      }

  return (
    <div className="EventEditPage">
      <HeaderProfile user={me} />
      <Helmet>
        <title>Edit event | Boon</title>
      </Helmet>
      <EventHostHeader event={originalEvent} controls />
      <NarrowContainer>
        {live ? (
          <>
            <div className="event-edit-page-title">Edit event</div>

            <EventEditForm
              initialValues={initialValues}
              published={isEventPublished(originalEvent)}
              validationSchema={getLiveEventEditPageValidation(
                originalEvent.get('ended') || originalEventPast
              )}
              onSubmit={handleFormSubmit}
              edit
              ended={originalEvent.get('ended')}
            />
          </>
        ) : (
          <>
            <div className="event-edit-page-title">Edit video</div>
            <Formik
              initialValues={initialValues}
              validationSchema={OnDemandEventEditPageValidation}
              onSubmit={handleFormSubmit}
            >
              <UploadForm published={isEventPublished(originalEvent)} edit />
            </Formik>
          </>
        )}
      </NarrowContainer>
    </div>
  )
}

export default withRouter(EventEditPage)
