import React from 'react'
import './ToggleButton.scss'
import clsx from 'clsx'

const ToggleButton = ({ value, onChange, className }) => {
  return (
    <div
      className={clsx('ToggleButton', className, { active: value })}
      onClick={(e) => {
        e.stopPropagation()
        onChange(!value)
      }}
    >
      <div className="toggle-button-knob"></div>
    </div>
  )
}

export default ToggleButton
