import { useCallback } from 'react'
import { useToasts } from 'react-toast-notifications'
import copy from 'copy-to-clipboard'

export const useCopyToClipboard = () => {
  const { addToast } = useToasts()
  const copyToClipboard = useCallback(
    (url, toastNotification = true) => {
      const absoluteUrl = new URL(url, document.baseURI).href
      copy(absoluteUrl)

      if (toastNotification) {
        addToast('Link was copied!', { appearance: 'success' })
      }
    },
    [addToast]
  )

  return copyToClipboard
}
