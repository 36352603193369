import React from 'react'
import './CreatorSettingsMenu.scss'
import { NavLink } from 'react-router-dom'
import {
  URL_CREATOR_SETTINGS_PROFILE,
  URL_CREATOR_SETTINGS_PAYOUT,
  URL_CREATOR_SETTINGS_MEMBERSHIP,
  URL_CREATOR_SETTINGS_CATEGORIES,
  URL_CREATOR_SETTINGS_COUPONS,
} from 'urls'

const CreatorSettingsMenu = () => {
  return (
    <div className="CreatorSettingsMenu">
      <div className="creator-settings-menu">
        <CreatorSettingsMenuLink to={URL_CREATOR_SETTINGS_PROFILE}>
          Profile
        </CreatorSettingsMenuLink>
        <CreatorSettingsMenuLink to={URL_CREATOR_SETTINGS_MEMBERSHIP}>
          Memberships
        </CreatorSettingsMenuLink>
        <CreatorSettingsMenuLink to={URL_CREATOR_SETTINGS_CATEGORIES}>
          Categories
        </CreatorSettingsMenuLink>
        <CreatorSettingsMenuLink to={URL_CREATOR_SETTINGS_COUPONS}>
          Coupons
        </CreatorSettingsMenuLink>
        <CreatorSettingsMenuLink to={URL_CREATOR_SETTINGS_PAYOUT}>
          Payout
        </CreatorSettingsMenuLink>
      </div>
    </div>
  )
}

export default CreatorSettingsMenu

const CreatorSettingsMenuLink = React.memo(({ to, children }) => (
  <NavLink exact className="creator-settings-menu-item" to={to}>
    {children}
  </NavLink>
))
