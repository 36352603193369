import React from 'react'
import './CancelSubscriptionModal.scss'
import NamedPinnedModal from 'components/NamedPinnedModal'
import { DateTime } from 'luxon'
import { Form, Formik, useFormikContext } from 'formik'
import clsx from 'clsx'
import StyledApiErrorMessage from 'components/StyledApiErrorMessage'
import { BoonFormikTextField } from 'components/BoonInputs'
import {
  cancelSubscription,
  createSubscriptionFeedbackApi,
  getMeObject,
} from 'api'
import { updateMeStore } from 'store/utils/auth'
import { getApiError } from 'utils'
import * as Yup from 'yup'

const ValidationSchema = Yup.object().shape({
  cancelReasons: Yup.array().min(1).required(),
  otherText: Yup.string().required(),
})

export const CANCELLATION_REASONS = [
  ['time_use', 'I couldn’t find the time for it'],
  ['not_satisfied', 'It wasn’t what I expected'],
  ['not_relevant', 'I didn’t find the content interesting'],
  [
    'too_expensive',
    'I was on a discount, and didn’t find it valuable enough to pay full price',
  ],
  [
    'financial_reasons',
    'My financial situation changed so I can no longer afford it',
  ],
  ['creator_competitor', 'I subscribed to another channel'],
  ['competitive_platform', 'I found a platform I like better'],
  ['circumstantial', 'I need a break, but will come back later'],
  ['offline', 'I found a physical alternative'],
  ['other', 'Other reason'],
]

const CancelSubscriptionModal = ({
  subscription,
  active,
  onClose,
  onCanceled,
}) => {
  const subscriptionId = subscription.get('id')
  const displayDate = (isoString) =>
    DateTime.fromISO(isoString).toFormat('LLL d, yyyy')

  const onSubmit = async (values, { setErrors }) => {
    try {
      await createSubscriptionFeedbackApi({
        data: {
          subscriptionId,
          ...values,
        },
      })

      await cancelSubscription({ subscriptionId })
      onCanceled()
      onClose()

      const newMe = await getMeObject()
      updateMeStore({ me: newMe })
    } catch (e) {
      setErrors(getApiError(e))
      return null
    }
  }

  return (
    <NamedPinnedModal
      title="Cancel Membership"
      active={active}
      onClose={onClose}
      className="CancelSubscriptionModal"
    >
      <div className="cancel-subscription-modal-description">
        We're so sorry to see you go! Your membership will end{' '}
        <strong>{displayDate(subscription.get('lockInCancelDate'))}</strong>{' '}
        (and you will lose access to all the content).
        <br />
        <br />
        We would love to get some feedback on why you are cancelling your
        membership.
      </div>

      <Formik
        initialValues={{ cancelReasons: [], otherText: '' }}
        validationSchema={ValidationSchema}
        onSubmit={onSubmit}
      >
        <CancelSubscriptionModalForm />
      </Formik>
    </NamedPinnedModal>
  )
}

export default CancelSubscriptionModal

const CancelSubscriptionModalForm = () => {
  const {
    values,
    setFieldValue,
    setFieldTouched,
    isSubmitting,
    isValid,
    dirty,
  } = useFormikContext()

  const cancelReasons = values.cancelReasons

  return (
    <Form>
      <div className="cancel-subscription-modal-reasons">
        {CANCELLATION_REASONS.map(([id, name]) => (
          <div key={id}>
            <input
              id={id}
              type="checkbox"
              className="boon-checkbox"
              checked={values.cancelReasons.includes(id)}
              onChange={(e) => {
                setFieldTouched('cancelReasons')
                if (e.target.checked) {
                  setFieldValue('cancelReasons', [...cancelReasons, id])
                } else {
                  setFieldValue(
                    'cancelReasons',
                    cancelReasons.filter((_id) => _id !== id)
                  )
                }
              }}
            />
            <label
              className="cancel-subscription-modal-reasons-item"
              htmlFor={id}
            >
              {name}
            </label>
          </div>
        ))}

        {cancelReasons.length > 0 && (
          <div className="cancel-subscription-modal-other">
            <BoonFormikTextField
              multiline
              name="otherText"
              label="Additional comment"
              placeholder="Describe further why you would like to cancel your membership"
            />
          </div>
        )}

        <StyledApiErrorMessage />
        <button
          type="submit"
          disabled={isSubmitting || !isValid || !dirty}
          className={clsx(
            'button primary loader cancel-subscription-modal-submit',
            {
              loading: isSubmitting,
            }
          )}
        >
          Confirm Cancellation
        </button>
      </div>
    </Form>
  )
}
