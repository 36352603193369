import React from 'react'
import { Col, Row } from 'components/Grid'
import CreatorSetupForm from './CreatorSetupForm'
import { useSelector } from 'react-redux'
import { Prompt } from 'react-router-dom'
import { getMe } from 'store/selectors'
import { removeNullObjectValues, getApiError } from 'utils'
import * as Yup from 'yup'
import { getMeObject, updateUser } from 'api'
import { updateMeStore } from 'store/utils/auth'
import ProfileCompletion from './ProfileCompletion'
import './CreatorSetupProfile.scss'
import { Formik } from 'formik'
import CreatorSettingsHeader from 'routes/CreatorSettingsPage/CreatorSettingsHeader'
import {
  trackCreatorRegistrationComplete,
  trackCreatorRegistrationInit,
} from 'tracking'
import { useMount } from 'react-use'

const CreatorSetupValidation = Yup.object().shape({
  name: Yup.string(),
  username: Yup.string().matches(
    /^[a-z0-9-]+$/,
    'Usernames must consist only of lower case letters, numbers and dashes.'
  ),
  title: Yup.string(),
})

const CreatorSetupProfile = ({ user, setUserData, triedLaunching }) => {
  const me = useSelector((state) => getMe(state))

  useMount(() => {
    trackCreatorRegistrationInit()
  })

  const onSubmit = async (values, { setSubmitting, setErrors }) => {
    try {
      const {
        name,
        username,
        title,
        bio,
        homepageUrl,
        instagramUrl,
        twitterUrl,
        facebookUrl,
        youtubeUrl,
        linkedinUrl,
        profileImageId,
        coverImageId,
        inviteCode,
        coverVideoUploadId,
        currency,
        colorScheme,
      } = values
      const data = {
        name,
        username,
        title,
        bio,
        homepageUrl,
        instagramUrl,
        twitterUrl,
        facebookUrl,
        youtubeUrl,
        linkedinUrl,
        profileImageId,
        coverImageId,
        inviteCode: inviteCode !== '' ? inviteCode : null,
        coverVideoUploadId,
        currency,
      }
      removeNullObjectValues(data)
      data['colorScheme'] = colorScheme // Can be null, skip the null-filter
      const user = await updateUser({ id: me.get('id'), data })
      setUserData(user)

      try {
        const apiMe = await getMeObject()
        updateMeStore({ me: apiMe })
      } catch (e) {
        // Pass
      }

      setSubmitting(false)
      trackCreatorRegistrationComplete()
    } catch (e) {
      setErrors(getApiError(e))
      setSubmitting(false)
    }
  }

  return (
    <div className="CreatorSetupProfile">
      <Formik
        initialValues={{
          name: user.get('name') ?? '',
          username: user.get('username') ?? '',
          title: user.get('title') ?? '',
          bio: user.get('bio') ?? '',
          homepageUrl: user.get('homepageUrl'),
          instagramUrl: user.get('instagramUrl'),
          twitterUrl: user.get('twitterUrl'),
          facebookUrl: user.get('facebookUrl'),
          youtubeUrl: user.get('youtubeUrl'),
          linkedinUrl: user.get('linkedinUrl'),
          profileImageFile: null,
          profileImageId: null,
          profileImageUrl:
            user.get('profileImageUrl') ===
            'https://s3.eu-central-1.amazonaws.com/boon-email.prod/img/avatar-placeholder.png'
              ? null
              : user.get('profileImageUrl'),
          coverImageFile: null,
          coverImageId: null,
          coverImageUrl: user.get('coverImageUrl'),
          coverVideoUrl: user.get('coverVideoUrl'),
          inviteCode: '',
          currency: user.get('currency'),
          colorScheme: user.get('colorScheme'),
        }}
        onSubmit={onSubmit}
        validationSchema={CreatorSetupValidation}
      >
        {(props) => (
          <>
            <Prompt
              when={props.dirty && props.submitCount === 0}
              message="You have unsaved changes, are you sure you want to leave?"
            />
            <CreatorSettingsHeader
              title="Profile details"
              description="These are the basic details your audience will see on your profile page."
            />
            <Row className="justify-content-md-center">
              <Col sm={12} lg={9}>
                <CreatorSetupForm {...props} />
              </Col>
              <Col sm={12} lg={3}>
                <ProfileCompletion
                  user={user}
                  triedLaunching={triedLaunching}
                />
              </Col>
            </Row>
          </>
        )}
      </Formik>
    </div>
  )
}

export default CreatorSetupProfile
