import React, { useCallback, useEffect, useState } from 'react'
import {
  registerFreePaymentApi,
  useCreateCheckoutSessionApi,
  useGetEventCheckoutApi,
} from 'api'
import { isAuthenticated } from 'store/selectors'
import { useSelector } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { useApiWrapper, useRetrieveCouponCode } from 'utils'
import Spinner from 'components/Spinner'
import './EventCheckoutPage.scss'
import { URL_EVENT, URL_EVENT_CHECKOUT_SUCCESS } from 'urls'
import clsx from 'clsx'

const EventCheckoutPage = () => {
  const {
    params: { userSlug, eventSlug },
  } = useRouteMatch()
  const { couponCode: currentCouponCode } = useRetrieveCouponCode(userSlug)
  const [couponCode] = useState(currentCouponCode)
  const history = useHistory()
  const hasAuth = useSelector((state) => isAuthenticated(state))
  const {
    runApiCall: registerFreePayment,
    loading: freePaymentLoading,
    error: freePaymentError,
  } = useApiWrapper(registerFreePaymentApi)

  const {
    data: checkout,
    loading: checkoutLoading,
    error: checkoutError,
  } = useGetEventCheckoutApi({
    userSlug,
    eventSlug,
    data: {
      couponCode,
    },
  })

  const eventId = checkout?.getIn(['event', 'id'])
  const noPayment = checkout?.get('noPayment')

  const {
    data: checkoutSession,
    loading: checkoutSessionLoading,
    error: checkoutSessionError,
  } = useCreateCheckoutSessionApi({
    data: {
      eventId,
      couponCode,
    },
    disabled: noPayment !== false,
  })

  const checkoutSessionUrl = checkoutSession?.get('url')
  useEffect(() => {
    if (checkoutSessionUrl != null) {
      window.location.href = checkoutSessionUrl
    }
  }, [checkoutSessionUrl])

  useEffect(() => {
    if (!hasAuth) {
      history.replace(URL_EVENT(userSlug, eventSlug))
    }
  }, [hasAuth, history, userSlug, eventSlug])

  const onGoBackClick = useCallback(() => {
    history.goBack()
  }, [history])

  const onFreeConfirmClick = useCallback(async () => {
    const result = await registerFreePayment({ eventId, couponCode })
    if (result.error == null) {
      history.replace(URL_EVENT_CHECKOUT_SUCCESS(userSlug, eventSlug))
    }
  }, [history, registerFreePayment, eventId, couponCode, userSlug, eventSlug])

  const renderInner = () => {
    const error = checkoutError || checkoutSessionError
    if (error != null) {
      return (
        <div className="event-checkout-page-box">
          <div className="event-checkout-page-error">
            <h1 className="event-checkout-page-title">An error occurred</h1>
            <div className="input-error">
              {error?.response?.data?.message ?? error?.message}
            </div>
            <button
              className="button secondary event-checkout-page-error-back"
              onClick={onGoBackClick}
            >
              Go back
            </button>
          </div>
        </div>
      )
    }

    if (checkoutLoading || checkoutSessionLoading || checkoutSessionUrl) {
      return (
        <div className="event-checkout-page-loading">
          <h1 className="event-checkout-page-loading-title">
            Redirecting you to checkout
          </h1>
          <Spinner small />
        </div>
      )
    }

    if (noPayment === true) {
      return (
        <div className="event-checkout-page-box">
          <div className="event-checkout-page-free">
            <h1 className="event-checkout-page-title">
              You're getting it for free!
            </h1>
            {freePaymentError && (
              <div className="input-error">
                {freePaymentError?.response?.data?.message ??
                  freePaymentError?.message}
              </div>
            )}
            <button
              className={clsx(
                'button primary load event-checkout-page-free-confirm',
                { loading: freePaymentLoading }
              )}
              onClick={onFreeConfirmClick}
            >
              Confirm free purchase
            </button>
          </div>
        </div>
      )
    }
  }

  return <div className="EventCheckoutPage">{renderInner()}</div>
}

export default EventCheckoutPage
