import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import './AuthModal.scss'
import SignInView from './SignInView'
import { AUTH_MODAL_STATE } from 'store/constants'
import { closeAuthModal } from 'store/actions'
import SignUpView from 'modals/AuthModal/SignUpView'
import { getMe } from 'store/selectors'
import SimpleNamedModal from 'components/SimpleNamedModal'
import ForgotPasswordView from './ForgotPasswordView'
import { trackSignUpModal } from 'tracking'

class AuthModal extends Component {
  handleClose = () => {
    this.props.dispatch(closeAuthModal())
  }

  renderView = () => {
    switch (this.authState) {
      case AUTH_MODAL_STATE.SIGNIN:
        return <SignInView />
      case AUTH_MODAL_STATE.SIGNUP:
        return <SignUpView />
      case AUTH_MODAL_STATE.FORGOT_PASSWORD:
        return <ForgotPasswordView onClose={this.handleClose} />
      default:
        return null
    }
  }

  getTitle = () => {
    switch (this.authState) {
      case AUTH_MODAL_STATE.SIGNIN:
        return 'Login'
      case AUTH_MODAL_STATE.SIGNUP:
        return 'Sign up'
      case AUTH_MODAL_STATE.SIGNUP_FACEBOOK:
        return `Welcome ${this.getMeName}`
      case AUTH_MODAL_STATE.FORGOT_PASSWORD:
        return 'Forgot password?'
      default:
        return null
    }
  }

  get isActive() {
    return this.props.isActive
  }

  get authState() {
    return this.props.authState
  }

  get me() {
    return this.props.me
  }

  get getMeName() {
    if (this.me != null) {
      return (this.me.get('name') || '').split(' ')[0]
    }
    return ''
  }

  componentDidUpdate(prevProps) {
    if (this.props.isActive && !prevProps.isActive) {
      if (this.authState === AUTH_MODAL_STATE.SIGNUP) {
        trackSignUpModal()
      }
    }
  }

  render() {
    return (
      <SimpleNamedModal
        onClose={this.handleClose}
        title={this.getTitle()}
        active={this.isActive}
      >
        <div className="AuthModal">{this.renderView()}</div>
      </SimpleNamedModal>
    )
  }
}

function mapStateToProps(state) {
  return {
    me: getMe(state),
    isActive: state.getIn(['ui', 'modals', 'auth', 'isActive']),
    authState: state.getIn(['ui', 'modals', 'auth', 'state']),
  }
}

export default withRouter(connect(mapStateToProps)(AuthModal))
