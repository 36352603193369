import React, { useEffect } from 'react'
import './UserDashboard.scss'
import { ExtendedContainer } from 'components/Grid'
import UserDashboardFollowing from './UserDashboardFollowing'
import UserDashboardEvents from './UserDashboardEvents'
import { useSelector } from 'react-redux'
import { getMe, isAuthenticated } from 'store/selectors'
import { Link, useHistory } from 'react-router-dom'
import { URL_CREATOR, URL_FRONT_PAGE } from 'urls'
import { useGetFeedApi } from 'api'
import { splitEventList } from 'utils'

const UserDashboard = () => {
  const history = useHistory()
  const hasAuth = useSelector((state) => isAuthenticated(state))
  const me = useSelector((state) => getMe(state))
  const {
    data: feedEvents,
    loading: feedLoading,
    error: feedError,
  } = useGetFeedApi({}, [me?.get('id')])
  const { upcoming, past } =
    feedEvents != null ? splitEventList(feedEvents) : {}

  useEffect(() => {
    if (!hasAuth) {
      history.push(URL_FRONT_PAGE)
    }
  }, [hasAuth, history])

  return (
    <div className="UserDashboard">
      <ExtendedContainer className="user-dashboard-extended-container">
        <div className="user-dashboard-container">
          <div className="user-dashboard-content">
            <h1 className="user-dashboard-title">Hi {me.get('name')}</h1>
            <div className="user-dashboard-following">
              <UserDashboardFollowing />
            </div>
            <div className="user-dashboard-events">
              <UserDashboardEvents
                title="Upcoming events"
                emptyText="When you follow creators you will see their upcoming live events here"
                events={upcoming}
                loading={feedLoading}
                error={feedError}
              />
            </div>
            <div className="user-dashboard-events">
              <UserDashboardEvents
                title="Latest videos"
                emptyText="When you follow creators you will see their latest videos here"
                events={past}
                pastEvents
                loading={feedLoading}
                error={feedError}
              />
            </div>
          </div>
          <div className="user-dashboard-sidebar">
            <div className="user-dashboard-creator">
              <div className="user-dashboard-creator-title">
                Become a creator
              </div>
              <div className="user-dashboard-creator-description">
                Set up your page, price your video, share your link.
              </div>
              <Link
                to={URL_CREATOR}
                className="button secondary light user-dashboard-creator-cta"
              >
                Learn more
              </Link>
            </div>
          </div>
        </div>
      </ExtendedContainer>
    </div>
  )
}

export default UserDashboard
