import React, { useState } from 'react'
import 'components/InsightsTableWidget/InsightsTableWidget.scss'
import { useGetApi } from 'api'
import { Row } from 'components/Grid'
import InsightsGenericWidget from 'components/InsightsGenericWidget'
import InsightsTablePagination from 'components/InsightsTableWidget/InsightsTablePagination'
import { fromJS } from 'immutable'
import Spinner from 'components/Spinner'
import './InsightsConnectedPaginationList.scss'
import { ErrorOutlineSvg } from 'assets/svg'

const dataMapper = (d) => fromJS(d)

const InsightsConnectedPaginationList = ({
  title,
  baseUrl,
  dataKey,
  renderContent,
  items = 4,
}) => {
  const [page, setPage] = useState(1)
  const changePage = (page) => setPage(page)

  const { data, error } = useGetApi({
    url: baseUrl + '?items=' + items + '&page=' + page,
    dataMapper,
  })

  const renderData = () => {
    if (error) {
      return (
        <div className="insights-connected-pagination-list-error">
          <ErrorOutlineSvg className="insights-connected-pagination-list-error-icon" />
          <div>Loading failed</div>
          <div>Please try again later</div>
        </div>
      )
    }

    if (data == null) {
      return <Spinner small light />
    }

    return data.get(dataKey).map((d) => renderContent(d))
  }

  if (data && data.getIn(['meta', 'count']) === 0) {
    return null
  }

  return (
    <InsightsGenericWidget
      title={title}
      className="insights-generic-connected-pagination-list"
    >
      <div className="InsightsConnectedPaginationList">
        <Row style={{ marginTop: '20px' }}>{renderData()}</Row>
        <InsightsTablePagination
          changePage={changePage}
          page={page}
          totalPages={data && data.getIn(['meta', 'pages'], 1)}
        />
      </div>
    </InsightsGenericWidget>
  )
}

export default InsightsConnectedPaginationList
