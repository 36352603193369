import React, { useEffect } from 'react'
import './AnimatedModal.scss'
import Modal from 'components/Modal'
import clsx from 'clsx'
import { CSSTransition } from 'react-transition-group'

const AnimatedModal = ({ active, overlay, onClose, children, className }) => {
  const _onClose = (e) => {
    e.stopPropagation()
    if (onClose) {
      onClose(e)
    }
  }

  useEffect(() => {
    const listener = (e) => {
      if (e.keyCode === 27 && onClose) {
        onClose(e)
      }
    }

    if (active) {
      window.addEventListener('keydown', listener)
    }

    return () => window.removeEventListener('keydown', listener)
  }, [active, onClose])

  return (
    <CSSTransition
      in={active}
      timeout={250}
      appear={true}
      mountOnEnter
      unmountOnExit
      classNames="AnimatedModal"
    >
      <Modal
        onClose={_onClose}
        overlay={overlay}
        className={clsx('AnimatedModal', className)}
      >
        {children}
      </Modal>
    </CSSTransition>
  )
}

export default AnimatedModal
