import { useHistory } from 'react-router-dom'
import { AUTH_MODAL_STATE } from 'store/constants'
import { URL_FRONT_PAGE } from 'urls'
import { useMount, useLocation } from 'react-use'
import { requireAuth } from 'store/utils/awaitStore'
import queryString from 'query-string'

const SignUpPage = () => {
  const location = useLocation()
  const history = useHistory()

  useMount(async () => {
    const { redirect_url, await_auth } = queryString.parse(location.search)

    const url = redirect_url || URL_FRONT_PAGE
    const wait = await_auth != null

    await requireAuth(AUTH_MODAL_STATE.SIGNUP, true)
    history.replace(url)
  })

  return null
}

export default SignUpPage
