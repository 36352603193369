import React, { useCallback, useState } from 'react'
import PostCardAttachmentEvent from './PostCardAttachmentEvent'
import PostCardAttachmentPhoto from './PostCardAttachmentPhoto'
import { POST_ATTACHMENT_TYPES } from 'store/constants/post'
import DocumentItem from 'components/Gallery/DocumentItem'
import PostCardAttachmentPoll from 'components/PostCard/PostCardAttachmentPoll'
import PostAttachmentModal from 'modals/PostAttachmentModal'
import { setPostAttachmentAttributes } from 'components/ProfileFeed'
import { getUserUrl } from 'utils'

const PostCardAttachment = ({ post, onNoAccess, dispatchPosts }) => {
  const postId = post.get('id')
  const [showModal, setShowModal] = useState(false)
  const attachment = post.get('attachment')
  const attachmentType = post.get('attachmentType')
  const attachmentAccess = post.get('attachmentAccess')
  const postUrl = `${getUserUrl(post.get('host'))}/posts/${postId}`

  const showAttachmentModal = useCallback(() => {
    setShowModal(true)
  }, [setShowModal])

  const onPostAttachmentUpdate = useCallback(
    (attachment) => {
      dispatchPosts(
        setPostAttachmentAttributes({
          postId,
          attachment,
        })
      )
    },
    [postId, dispatchPosts]
  )

  if (attachment == null || attachmentType == null) {
    return null
  }

  const renderAttachment = () => {
    switch (attachmentType) {
      case POST_ATTACHMENT_TYPES.EVENT:
        return (
          <PostCardAttachmentEvent
            event={attachment}
            onPostAttachmentUpdate={onPostAttachmentUpdate}
            access={attachmentAccess}
          />
        )
      case POST_ATTACHMENT_TYPES.PHOTO:
        return (
          <PostCardAttachmentPhoto
            photo={attachment}
            access={attachmentAccess}
            postId={postId}
            onNoAccess={onNoAccess}
            showAttachmentModal={showAttachmentModal}
            post={post}
          />
        )
      case POST_ATTACHMENT_TYPES.DOCUMENT:
        return (
          <DocumentItem
            post={post}
            access={attachmentAccess}
            onNoneAccessPostClick={(_, title) => onNoAccess(title)}
          />
        )
      case POST_ATTACHMENT_TYPES.POLL:
        return (
          <PostCardAttachmentPoll
            poll={attachment}
            post={post}
            access={attachmentAccess}
            onNoAccess={onNoAccess}
            onPostAttachmentUpdate={onPostAttachmentUpdate}
            showAttachmentModal={showAttachmentModal}
          />
        )
      default:
        return null
    }
  }

  return (
    <>
      {showModal && (
        <PostAttachmentModal
          postId={postId}
          postUrl={postUrl}
          onClose={() => setShowModal(false)}
          onPostAttachmentUpdate={onPostAttachmentUpdate}
        />
      )}
      {renderAttachment()}
    </>
  )
}

export default PostCardAttachment
