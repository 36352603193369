import React from 'react'
import { ErrorOutlineSvg } from 'assets/svg'
import './BrowserSupportBanner.scss'

const BrowserSupportBanner = () => {
  const userAgent = window.navigator.userAgent
  const isInternetExplorer =
    userAgent.indexOf('MSIE ') > -1 || userAgent.indexOf('Trident/') > -1

  let message = null
  if (isInternetExplorer) {
    message = (
      <>
        Your browser might be unsupported, if you're encountering any errors,
        please try in a modern browser, eg.{' '}
        <a
          href="https://www.google.com/chrome/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Google Chrome
        </a>{' '}
        or on an other device.
      </>
    )
  }

  if (message == null) {
    return null
  }

  return (
    <div className="BrowserSupportBanner">
      <ErrorOutlineSvg className="browser-support-banner-icon" />
      <div className="browser-support-banner-message">{message}</div>
    </div>
  )
}

export default BrowserSupportBanner
