import React, { useCallback } from 'react'
import './SubscriptionCard.scss'
import Avatar from 'components/Avatar/Avatar'
import clsx from 'clsx'
import { Col, Row } from 'components/Grid'
import { DateTime } from 'luxon'
import { asyncModal, displayPrice, getUserSlug } from 'utils'
import { displayBillingName } from 'components/ProductCard/planUtils'
import { WatchLaterBlackSvg } from 'assets/svg'
import { URL_UPDATE_SUBSCRIPTION_PAYMENT } from 'urls'
import { Link } from 'react-router-dom'
import CancelSubscriptionModal from 'modals/CancelSubscriptionModal'
import ChangeSubscriptionModal from 'modals/ChangeSubscriptionModal'
import { reactivateSubscription } from 'api'
import { useToasts } from 'react-toast-notifications'
import * as Sentry from '@sentry/browser'

const SubscriptionCard = ({ subscription, refreshSubscriptions }) => {
  const { addToast } = useToasts()
  const onCanceled = useCallback(() => {
    addToast('The membership has been canceled', { appearance: 'success' })
  }, [addToast])
  const onChanged = useCallback(() => {
    addToast('The membership has been changed', { appearance: 'success' })
  }, [addToast])
  const onReactivated = useCallback(() => {
    addToast('The membership has been reactivated', { appearance: 'success' })
  }, [addToast])

  const renderStatus = () => {
    switch (subscription.get('status')) {
      case 'active':
        return 'Active'
      case 'incomplete':
        return 'Payment incomplete'
      case 'incomplete_expired':
        return 'Payment incomplete'
      case 'trialing':
        return 'Trialing'
      case 'past_due':
        return 'Past due'
      case 'canceled':
        return 'Canceled'
      case 'unpaid':
        return 'Unpaid'
      default:
        return 'Unknown'
    }
  }

  const formatDate = (dateTime) => {
    return DateTime.fromISO(dateTime).toFormat('MMM d, y')
  }

  const renderCancelPeriod = () => {
    return subscription.get('lockInMonths') === 0
      ? '-'
      : `${subscription.get('lockInMonths')} months`
  }

  const renderCanceledAt = () => {
    if (subscription.get('canceledAt') == null) {
      return null
    }
    return (
      <Row>
        <Col lg={6}>
          <div className="subscription-card-label">Canceled at</div>
          {formatDate(subscription.get('canceledAt'))}
        </Col>
      </Row>
    )
  }
  const renderEnds = () => {
    return subscription.get('cancelAt') === null
      ? '-'
      : formatDate(subscription.get('cancelAt'))
  }

  const activeButCanceled =
    subscription.get('status') === 'active' &&
    subscription.get('cancelAt') !== null

  const renderCancelsAtTag = () => {
    if (activeButCanceled) {
      return (
        <div className={clsx('status-tag')}>
          <WatchLaterBlackSvg />
          Cancels{' '}
          {DateTime.fromISO(subscription.get('cancelAt')).toFormat('MMM d')}
        </div>
      )
    }
  }

  const handleCancel = async () => {
    await asyncModal(({ active, onDismiss }) => (
      <CancelSubscriptionModal
        active={active}
        subscription={subscription}
        onClose={onDismiss}
        onCanceled={onCanceled}
      />
    ))
    refreshSubscriptions()
  }

  const handleChange = async () => {
    await asyncModal(({ active, onDismiss }) => (
      <ChangeSubscriptionModal
        active={active}
        subscription={subscription}
        onClose={onDismiss}
        onCanceled={onCanceled}
        onChanged={onChanged}
      />
    ))
    refreshSubscriptions()
  }

  const handleReactivate = async () => {
    try {
      await reactivateSubscription({
        subscriptionId: subscription.get('id'),
      })
      onReactivated()
      refreshSubscriptions()
    } catch (e) {
      Sentry.captureException(e)
      addToast('Could not reactivate. Please contact support', {
        appearance: 'error',
      })
    }
  }

  const hasBeenCanceled = subscription.get('hasBeenCanceled') === true
  const renderAction = () => {
    if (subscription.get('status') === 'active' && hasBeenCanceled) {
      return (
        <button
          className="subscription-card-button button secondary"
          onClick={handleReactivate}
        >
          Don't cancel
        </button>
      )
    } else if (subscription.get('status') === 'canceled') {
      return null
    } else if (
      subscription.get('status') === 'incomplete_expired' ||
      subscription.get('status') === 'incomplete'
    ) {
      return <div>Please update your payment method</div>
    } else if (
      subscription.get('alternativePlans').size > 1 &&
      !hasBeenCanceled &&
      subscription.get('lockInMonths') === 0
    ) {
      return (
        <button
          className="subscription-card-button button secondary"
          onClick={handleChange}
        >
          Change plan
        </button>
      )
    } else if (!hasBeenCanceled) {
      return null
    }
  }

  const renderDoNotRenew = () => {
    if (hasBeenCanceled) {
      return null
    } else {
      return (
        <div
          className="subscription-card-link do-not-renew"
          onClick={handleCancel}
        >
          Do not renew
        </div>
      )
    }
  }

  const userSlug = getUserSlug(subscription.get('host'))
  const updateSubscriptionPaymentUrl =
    URL_UPDATE_SUBSCRIPTION_PAYMENT(userSlug, subscription.get('id')) +
    `?redirect_url=${window.location.pathname}`

  return (
    <div className="SubscriptionCard">
      <div className="subscription-card-header">
        <Avatar
          className="creator-avatar"
          url={subscription.getIn(['host', 'profileImageUrl'])}
          userId={subscription.getIn(['host', 'id'])}
        />
        <div className="subscription-card-header-creator">
          {subscription.getIn(['host', 'name'])}
        </div>
      </div>
      <div className="subscription-card-title">
        {subscription.getIn(['product', 'name'])}
      </div>
      <div className="subscription-card-status">
        <div className={clsx('status-tag', subscription.get('status'))}>
          {renderStatus()}
        </div>
        {renderCancelsAtTag()}
      </div>
      <Row>
        <Col lg={6} xs={6}>
          <div className="subscription-card-label">Started</div>
          {formatDate(subscription.get('createdAt'))}
        </Col>
        <Col lg={6} xs={6}>
          <div className="subscription-card-label">Ends</div>
          {renderEnds()}
        </Col>
      </Row>
      <Row>
        <Col lg={6} xs={6}>
          <div className="subscription-card-label">Subscription</div>
          {displayPrice(
            subscription.getIn(['plan', 'amount']) / 100,
            subscription.getIn(['plan', 'currency'])
          )}
          {displayBillingName(
            subscription.getIn(['plan', 'interval']),
            subscription.getIn(['plan', 'intervalCount'])
          )}
        </Col>
        <Col lg={6} xs={6}>
          <div className="subscription-card-label">Cancel period</div>
          {renderCancelPeriod()}
        </Col>
      </Row>
      <Row>
        <Col lg={6} xs={6}>
          <div className="subscription-card-label">Next invoice</div>
          {formatDate(subscription.get('nextPayment'))}
        </Col>
        <Col lg={6} xs={6}>
          <div className="subscription-card-label">Payment Method</div>
          ***{subscription.get('last4')}{' '}
          <Link
            className="subscription-card-edit-payment"
            to={updateSubscriptionPaymentUrl}
          >
            Edit
          </Link>
        </Col>
      </Row>
      {renderCanceledAt()}
      <div className="subscription-card-cta">
        {renderAction()}
        {renderDoNotRenew()}
      </div>
    </div>
  )
}

export default SubscriptionCard
