import React from 'react'
import Spinner from 'components/Spinner'
import queryString from 'query-string'
import { useMount } from 'react-use'
import { stripeActivateAccount, getMeObject } from 'api'
import { useHistory } from 'react-router-dom'
import { URL_FRONT_PAGE } from 'urls'
import { updateMe } from 'store/actions'
import { useDispatch } from 'react-redux'

const StripeActivateAccountPage = ({ location, match }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { code: authorizationCode, state: returnUrl } = queryString.parse(
    location.search
  )

  useMount(async () => {
    try {
      await stripeActivateAccount({ authorizationCode })
    } catch (e) {
      alert(
        e?.response?.data?.message ||
          'The account activation failed, please contact support.'
      )
    }

    if (returnUrl) {
      history.replace(returnUrl)
    } else {
      history.replace(URL_FRONT_PAGE)
    }

    try {
      const apiMe = await getMeObject()
      dispatch(updateMe(apiMe))
    } catch (_) {
      // Pass
    }
  })

  return <Spinner light />
}

export default StripeActivateAccountPage
