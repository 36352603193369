import React, { useState, useEffect } from 'react'
import StyledApiErrorMessage from 'components/StyledApiErrorMessage'
import { Field, Form } from 'formik'
import Avatar from 'components/Avatar'
import clsx from 'clsx'
import {
  FacebookSvg,
  InstagramSvg,
  LinkedInSvg,
  YouTubeSvg,
  WebsiteSvg,
  CameraSvg,
  AddSvg,
  CancelSvg,
  TwitterSvg,
} from 'assets/svg'
import { fixImageOrientation, uploadToS3, sluggify } from 'utils'
import './CreatorSetupForm.scss'
import { getImageUploadUrl } from 'api'
import { BoonFormikTextField } from 'components/BoonInputs'
import AddSocialModal from './AddSocialModal'
import UploadVideoField from 'components/UploadVideoField'
import BoonFormGroup from 'components/BoonInputs/BoonFormGroup'
import { MarkdownTextEditorFormik } from 'components/MarkdownTextEditor/MarkdownTextEditor'
import Currencies from 'data/Currencies'
import CreatorColorPicker from 'routes/CreatorSetupPage/CreatorColorPicker'

export const SETUP_SOCIAL_ITEMS = [
  {
    key: 'facebookUrl',
    type: 'facebook',
    name: 'Facebook',
    icon: FacebookSvg,
    placeholder: 'https://facebook.com/yourprofile',
  },
  {
    key: 'instagramUrl',
    type: 'instagram',
    name: 'Instagram',
    icon: InstagramSvg,
    placeholder: 'https://instagram.com/yourprofile',
  },
  {
    key: 'twitterUrl',
    type: 'twitter',
    name: 'Twitter',
    icon: TwitterSvg,
    placeholder: 'https://twitter.com/yourprofile',
  },
  {
    key: 'linkedinUrl',
    type: 'linkedin',
    name: 'LinkedIn',
    icon: LinkedInSvg,
    placeholder: 'https://linkedin.com/in/yourprofile',
  },
  {
    key: 'youtubeUrl',
    type: 'youtube',
    name: 'YouTube',
    icon: YouTubeSvg,
    placeholder: 'https://youtube.com/yourprofile',
  },
  {
    key: 'homepageUrl',
    type: 'website',
    name: 'Website',
    icon: WebsiteSvg,
    placeholder: 'https://your-website.com',
  },
]

const CreatorSetupForm = ({
  isSubmitting,
  values,
  touched,
  isValid,
  dirty,
  setFieldValue,
  initialValues,
  edit,
}) => {
  const [isAddSocialOpen, setAddSocialOpen] = useState(false)
  const [isUploadingAvatar, setUploadingAvatar] = useState(false)
  const [isUploadingCover, setUploadingCover] = useState(false)

  useEffect(() => {
    if (!touched.username && values.name && !initialValues.username && !edit) {
      const username = sluggify(values.name)
      setFieldValue('username', username)
    }
  }, [values.name, touched.username, setFieldValue, edit, initialValues])

  const renderSubmit = () => {
    return (
      <button
        type="submit"
        disabled={isSubmitting || !dirty}
        className={clsx('button primary medium loader setup-submit', {
          loading: isSubmitting,
        })}
      >
        {edit ? 'Save changes' : 'Save changes'}
      </button>
    )
  }

  const renderSocial = () => {
    return SETUP_SOCIAL_ITEMS.map(({ key, name, icon: Icon }) => {
      let handle = values[key]
      if (handle) {
        return (
          <div className="setup-social" key={key}>
            <div className="setup-social-icon">
              <Icon />
            </div>
            <div className="setup-social-details">
              <div className="setup-social-name">{name}</div>
            </div>
            <div
              className="setup-social-remove"
              onClick={() => setFieldValue(key, '')}
            >
              <CancelSvg />
            </div>
          </div>
        )
      }

      return null
    })
  }

  const renderAddSocial = () => {
    if (SETUP_SOCIAL_ITEMS.every((o) => values[o.key])) {
      return null
    }

    return (
      <div
        className="setup-social setup-social-add"
        onClick={() => setAddSocialOpen(true)}
      >
        <div className="setup-social-icon">
          <AddSvg />
        </div>
        <div className="setup-social-details">
          <div className="setup-social-name">Add link</div>
        </div>
      </div>
    )
  }

  return (
    <Form className="CreatorSetupForm">
      <AddSocialModal
        values={values}
        active={isAddSocialOpen}
        onClose={() => setAddSocialOpen(false)}
        setFieldValue={setFieldValue}
      />
      <section className="setup-section">
        <div className="setup-section-header">
          <div className="setup-section-header-title">Basic</div>
        </div>

        <BoonFormGroup
          label="Creator name"
          required
          helperText="Choose the name that your audience knows you by."
          id="profileName"
        >
          <BoonFormikTextField
            type="text"
            name="name"
            tooltip="This is the name that will show on your creator page. What do your fans call you? You can always change this later."
            placeholder="What name are you known by?"
            className="setup-name"
          />
        </BoonFormGroup>

        <BoonFormGroup
          label="Page URL"
          required
          helperText="Create a custom URL for your Boon page to share across your social and digital channels."
          id="profileUsername"
        >
          <BoonFormikTextField
            type="text"
            name="username"
            prepend="https://boon.tv/"
            tooltip="This is used to create your unique creator page on Boon and make sure unique event links look nice when you post them in your social channels. Typically people put in their creator name."
            placeholder="your-username"
            className="setup-username"
            required
          />
        </BoonFormGroup>

        <BoonFormGroup
          label="Profile photo"
          required
          helperText="To make it personal, we always recommend a portrait. Make sure that it's recognisable and looks good in small sizes."
          id="profilePhoto"
        >
          <label className="setup-avatar" htmlFor="setupAvatarUpload">
            <Avatar url={values.profileImageUrl} />
            <div
              className={clsx('setup-photo-button setup-avatar-upload', {
                loading: isUploadingAvatar,
              })}
            >
              <CameraSvg />
            </div>
          </label>
          <input
            type="file"
            onChange={async (e) => {
              try {
                setUploadingAvatar(true)
                const inputFile = e.target.files[0]
                const file = await fixImageOrientation(inputFile)
                const { url, urlFields, photoId } = await getImageUploadUrl({
                  filename: inputFile.name,
                })
                await uploadToS3({
                  url,
                  urlFields,
                  file,
                })

                setFieldValue('profileImageFile', file)
                setFieldValue('profileImageUrl', URL.createObjectURL(file))
                setFieldValue('profileImageId', photoId)
                setUploadingAvatar(false)
              } catch (e) {
                setUploadingAvatar(false)
              }
            }}
            id="setupAvatarUpload"
          />
        </BoonFormGroup>
        <BoonFormGroup
          label="Cover photo"
          required
          helperText="Your cover photo is intended as a theme to personalise your profile. We recommend a simple environmental picture that gives your visitors and members a good idea of what you do. Avoid text and graphics and make sure it looks good on all screen sizes. Recommended minimum size 1280x360 pixels."
          id="profileCover"
        >
          <label
            className="setup-cover"
            style={{ backgroundImage: `url(${values.coverImageUrl})` }}
            htmlFor="setupCoverUpload"
          >
            <div
              className={clsx('setup-photo-button setup-cover-upload', {
                loading: isUploadingCover,
              })}
            >
              <CameraSvg />
            </div>
          </label>
          <input
            type="file"
            onChange={async (e) => {
              try {
                setUploadingCover(true)
                const inputFile = e.target.files[0]
                const file = await fixImageOrientation(inputFile)
                const { url, urlFields, photoId } = await getImageUploadUrl({
                  filename: inputFile.name,
                })
                await uploadToS3({
                  url,
                  urlFields,
                  file,
                })

                setFieldValue('coverImageFile', file)
                setFieldValue('coverImageUrl', URL.createObjectURL(file))
                setFieldValue('coverImageId', photoId)
                setUploadingCover(false)
              } catch (e) {
                setUploadingCover(false)
              }
            }}
            id="setupCoverUpload"
          />
        </BoonFormGroup>
        <BoonFormGroup label="Currency" id="paymentCurrency" required>
          <Field name="currency" as="select" className="boon-dark-select">
            <option value={null}>Select currency</option>
            {Currencies.map((c) => (
              <option value={c} key={c}>
                {c.toUpperCase()}
              </option>
            ))}
          </Field>
        </BoonFormGroup>
        <BoonFormGroup label="Color Theme">
          <CreatorColorPicker />
        </BoonFormGroup>
      </section>

      <section className="setup-section">
        <div className="setup-section-header">
          <div className="setup-section-header-title">About</div>
          <div className="setup-section-header-subtitle">
            This is the info your audience will see on your "About" page.
          </div>
        </div>

        <BoonFormGroup label="Headline" id="profileTitle" required>
          <BoonFormikTextField
            type="text"
            name="title"
            placeholder="e.g. Abstract Painter, Musician, Chef…"
            className="setup-title"
            required
          />
        </BoonFormGroup>

        <BoonFormGroup
          label="Description"
          id="profileDescription"
          required
          helperText="Tell your audience a bit about yourself. Set clear expectations of what they can expect from you on Boon."
        >
          <MarkdownTextEditorFormik
            name="bio"
            placeholder="Write your bio..."
          />
        </BoonFormGroup>

        <BoonFormGroup
          label="Intro video"
          helperText="Upload a video to add a personal touch to your introduction. Share your story, your mission, and your vision for your Boon community. "
        >
          <UploadVideoField
            slim
            videoId={values.coverVideoUploadId}
            videoUrl={values.coverVideoUrl}
            onSetId={(id) => setFieldValue('coverVideoUploadId', id)}
            onSetUrl={(url) => setFieldValue('coverVideoUrl', url)}
          />
        </BoonFormGroup>

        <BoonFormGroup
          label="Social"
          helperText="Add links to your social channels so your audience can follow you there too. "
        >
          {renderSocial()}
          {renderAddSocial()}
        </BoonFormGroup>
      </section>

      {!edit && (
        <section className="setup-section">
          <BoonFormGroup
            label="Invite code"
            helperText="If you got referred by another creator, add your invite code."
          >
            <BoonFormikTextField
              type="text"
              name="inviteCode"
              label="Invite code"
              placeholder="Invite code"
              className="setup-title"
            />
          </BoonFormGroup>
        </section>
      )}

      <StyledApiErrorMessage />
      {renderSubmit()}
    </Form>
  )
}

export default CreatorSetupForm
