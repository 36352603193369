import React, { useCallback, useEffect, useState } from 'react'
import 'modals/CheckoutModal/CheckoutModal.scss'
import AnimatedModal from 'components/AnimatedModal'
import { useGetUserApi, useGetUserProductsApi } from 'api'
import Spinner from 'components/Spinner'
import ProductCard from 'components/ProductCard'
import TicketCard from 'components/TicketCard'
import CloseSvg from 'assets/svg/CloseSvg'
import { useSelector } from 'react-redux'
import { isAuthenticated } from 'store/selectors'
import { requireAuth } from 'store/utils/awaitStore'
import { useLockBodyScroll } from 'react-use'
import FreeMembershipCard from 'components/FreeMembershipCard'
import { EVENT_PURCHASE_STATE } from 'store/constants'
import { useCreatorStyles } from 'creatorColorConstants'

const CheckoutModal = (props) => {
  const { active } = props
  const browser = useSelector((state) => state.get('browser'))
  return (
    <AnimatedModal active={active} overlay className="CheckoutModal">
      <CheckoutModalInner browser={browser} {...props} />
    </AnimatedModal>
  )
}

export default CheckoutModal

export const EXPANDED_CARD_TICKET_KEY = 'TICKET'

const CheckoutModalInner = ({
  active,
  hostId,
  event,
  title,
  onClose,
  productIds,
  browser,
}) => {
  const hasAuth = useSelector((state) => isAuthenticated(state))

  const { data: user } = useGetUserApi({ userId: hostId })
  const { data: products, refresh: refreshProducts } = useGetUserProductsApi({
    userId: hostId,
  })
  const [expandedCard, setExpandedCard] = useState(null)
  useCreatorStyles(user)

  useEffect(() => {
    const initial = document.body.style.overflow
    return () => {
      document.body.style.overflow = initial
    }
  }, [])
  useLockBodyScroll(active)

  const filteredProducts =
    productIds != null
      ? products?.filter((p) => productIds.includes(p.get('id')))
      : products

  const sortedProducts = filteredProducts?.sort(
    (a, b) => a.getIn(['plans', 0, 'amount']) - b.getIn(['plans', 0, 'amount'])
  )

  useEffect(() => {
    if (sortedProducts != null && expandedCard == null) {
      if (sortedProducts.size > 0) {
        setExpandedCard(sortedProducts.first().get('id'))
      } else {
        setExpandedCard(EXPANDED_CARD_TICKET_KEY)
      }
    }
  }, [expandedCard, sortedProducts])

  const onCardExpand = useCallback((newExpandedCard) => {
    setExpandedCard(newExpandedCard)
  }, [])

  const onSignInClick = () => {
    requireAuth()
  }

  const renderHeader = () => {
    return (
      <div className="checkout-modal-header">
        {title && <div className="checkout-modal-header-title">{title}</div>}
        <div className="checkout-modal-header-subtitle">
          {isEmpty
            ? 'Unfortunately this post is not available for purchase at this moment'
            : 'Choose option to get access'}
        </div>
      </div>
    )
  }

  const renderProducts = (isMobile) => {
    if (user == null || products == null) {
      return (
        <div className="checkout-modal-mock-card">
          <Spinner small />
        </div>
      )
    }

    if (productIds != null && productIds.size === 0 && event == null) {
      return <FreeMembershipCard user={user} onClose={onClose} />
    }

    return sortedProducts.map((product, i) => (
      <ProductCard
        product={product}
        productsAmount={sortedProducts.size}
        key={product.get('id')}
        user={user}
        refresh={refreshProducts}
        collapsible={isMobile}
        collapsed={isMobile && expandedCard !== product.get('id')}
        onExpand={onCardExpand}
      />
    ))
  }

  const renderTicket = (isMobile) => {
    if (user == null || event == null) {
      return null
    }

    return (
      <TicketCard
        event={event}
        user={user}
        collapsible={isMobile}
        collapsed={isMobile && expandedCard !== EXPANDED_CARD_TICKET_KEY}
        onExpand={onCardExpand}
      />
    )
  }

  const onClosePrevent = (e) => {
    e.preventDefault()
    e.stopPropagation()
    onClose()
  }

  const ticketPriceAvailable = () => {
    if (user == null || event == null) {
      return false
    }

    if (
      event.get('purchaseState') !== EVENT_PURCHASE_STATE.PURCHASABLE &&
      !(
        event.get('purchaseState') === EVENT_PURCHASE_STATE.SOLD_OUT &&
        event.get('availableOnDemand')
      )
    ) {
      return false
    }

    return event.get('price') != null
  }

  const isEmpty =
    user !== null &&
    !ticketPriceAvailable() &&
    products !== null &&
    products.size === 0 &&
    event !== null

  const renderCarouselProducts = () => {
    if (browser.lessThan.md) {
      return (
        <div className="checkout-modal-mobile-wrapper">
          {ticketPriceAvailable() && renderTicket(true)}
          {renderProducts(true)}
        </div>
      )
    } else {
      return (
        <div className="checkout-modal-desktop-wrapper">
          <div className="checkout-modal-options">
            {ticketPriceAvailable() && renderTicket()}
            {renderProducts()}
          </div>
        </div>
      )
    }
  }

  const renderEmpty = () => (
    <div
      className="button primary light checkout-modal-back-button"
      onClick={onClose}
    >
      Back
    </div>
  )

  return (
    <>
      <div className="checkout-modal-close" onClick={onClosePrevent}>
        <CloseSvg className="checkout-modal-close-icon" />
      </div>
      {!hasAuth && (
        <div className="checkout-modal-sign-in" onClick={onSignInClick}>
          Login
        </div>
      )}
      <div className="checkout-modal-centered">
        {renderHeader()}
        {isEmpty && renderEmpty()}
        {renderCarouselProducts()}
      </div>
    </>
  )
}
