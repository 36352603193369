import React from 'react'
import './HybridMenu.scss'
import { useSelector } from 'react-redux'
import MenuModal from 'components/MenuModal'
import HybridMenuBox from 'components/HybridMenu/HybridMenuBox'

const HybridMenu = ({ open, onClose, children, outerRef }) => {
  const browser = useSelector((state) => state.get('browser'))
  const shouldUseModal = browser.lessThan.lg

  if (shouldUseModal) {
    return (
      <MenuModal className="HybridMenu" active={open} onClose={onClose}>
        {children}
      </MenuModal>
    )
  } else {
    if (!open) {
      return null
    }

    return (
      <HybridMenuBox onClose={onClose} outerRef={outerRef}>
        {children}
      </HybridMenuBox>
    )
  }
}

export default HybridMenu
