import React, { Suspense, useCallback } from 'react'
import './MarkdownTextEditor.scss'
import Spinner from 'components/Spinner'
import { useField } from 'formik'

const MarkdownTextEditorInner = React.lazy(() =>
  import('./MarkdownTextEditorInner')
)

const MarkdownTextEditor = ({
  value,
  onChange,
  onBlur,
  placeholder = 'Describe...',
}) => {
  const loader = (
    <div className="markdown-text-editor-loader">
      <Spinner tiny light />
    </div>
  )

  return (
    <div className="MarkdownTextEditor">
      <MarkdownTextEditorErrorBoundary>
        <Suspense fallback={loader}>
          <MarkdownTextEditorInner
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            placeholder={placeholder}
          />
        </Suspense>
      </MarkdownTextEditorErrorBoundary>
    </div>
  )
}

export default MarkdownTextEditor

export const MarkdownTextEditorFormik = (props) => {
  const [{ name, onChange, value, onBlur }] = useField(props)
  const onChangeAdapter = useCallback(
    (value, e) => {
      onChange({ target: { name, value } })
    },
    [onChange, name]
  )
  const onBlurAdapter = useCallback(() => {
    onBlur({ target: { name } })
  }, [onBlur, name])

  return (
    <MarkdownTextEditor
      value={value}
      onChange={onChangeAdapter}
      onBlur={onBlurAdapter}
      {...props}
    />
  )
}

export class MarkdownTextEditorErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="markdown-text-editor-error">
          <div className="markdown-text-editor-error-title">
            Component failed loading
          </div>
          <div className="markdown-text-editor-error-description">
            Please refresh the page and try again
          </div>
        </div>
      )
    }

    return this.props.children
  }
}
