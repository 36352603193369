import { useCallback, useEffect, useReducer, useRef, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import isEqualDeep from 'react-fast-compare'
import { useMount, useUnmount } from 'react-use'
import { render, unmountComponentAtNode } from 'react-dom'
import { useDispatch } from 'react-redux'
import { setHeaderDark, setNotFound } from 'store/actions'

/*
  This file contains more generic hook functions that will be slowly
  extracted into other utils files as more categories appear.
 */

export const useRerenderInterval = (interval, paused = false) => {
  const [time, setTime] = useState(0)

  useEffect(() => {
    if (!paused) {
      const timeout = setTimeout(() => {
        setTime(new Date())
      }, interval)

      return () => clearTimeout(timeout)
    }
  })

  return time
}

export const useInterval = (callback, delay) => {
  const savedCallback = useRef()

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    const handler = (...args) => savedCallback.current(...args)

    if (delay !== null) {
      const id = setInterval(handler, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}

// Alert if clicked on outside of element
export const useOutsideAlerter = (ref, active, onClick) => {
  function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
      onClick()
    }
  }

  useEffect(() => {
    if (active) {
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }
  })
}

export const useWasInView = () => {
  const [ref, inView] = useInView({ rootMargin: '250px' })
  const [visible, setVisible] = useState(
    typeof window.IntersectionObserver === 'undefined'
  )

  useEffect(() => {
    if (inView) {
      setVisible(true)
    }
  }, [inView])

  return [ref, visible]
}

export const useInfiniteScrollBody = ({
  hasMore,
  rootMargin = '0px 0px 300px 0px',
  threshold = 1,
}) => {
  const [page, incrementPage] = useReducer((state) => state + 1, 0)
  const [bottomRef, inView] = useInView({
    triggerOnce: false,
    threshold,
    rootMargin,
  })

  useEffect(() => {
    if (inView && hasMore) {
      incrementPage()
    }
  }, [inView, hasMore])

  return [page, bottomRef, incrementPage]
}

export const useWindowResize = (onResize) => {
  useEffect(() => {
    window.addEventListener('resize', onResize, false)

    return () => {
      window.removeEventListener('resize', onResize, false)
    }
  }, [onResize])
}

export function useDeepEqualMemo(value) {
  const ref = useRef(undefined)

  if (!isEqualDeep(ref.current, value)) {
    ref.current = value
  }

  return ref.current
}

export const useDeepCompareEffect = (effect, deps) => {
  const ref = useRef()
  if (!ref.current || !isEqualDeep(deps, ref.current)) {
    ref.current = deps
  }
  useEffect(effect, ref.current) // eslint-disable-line react-hooks/exhaustive-deps
}

export const useTemporaryModalUrl = (temporaryUrl, onClose) => {
  const hasChangedRouteRef = useRef(false)
  const hasClosedBackButton = useRef(false)

  useMount(() => {
    window.history.pushState(null, null, temporaryUrl)
    hasChangedRouteRef.current = true
    hasClosedBackButton.current = false
  })

  useEffect(() => {
    const listener = () => {
      if (hasChangedRouteRef.current) {
        hasClosedBackButton.current = true
        onClose()
      }
    }

    window.addEventListener('popstate', listener)

    return () => window.removeEventListener('popstate', listener)
  }, [onClose])

  useUnmount(() => {
    if (
      !hasClosedBackButton.current &&
      window.location.pathname === temporaryUrl
    ) {
      window.history.back()
    }
  })
}

export const useExitPrompt = (showExitPrompt) => {
  const beforeUnload = (event) => {
    if (event != null) {
      event.returnValue = ''
      event.preventDefault()
    }
    return ''
  }

  useEffect(() => {
    if (showExitPrompt) {
      window.addEventListener('beforeunload', beforeUnload)
      return () => window.removeEventListener('beforeunload', beforeUnload)
    }
  }, [showExitPrompt])
}

// Async modal
// From: https://github.com/prezly/react-promise-modal
const noop = () => {}

const DEFAULT_DESTRUCTION_DELAY = 300
const DEFAULT_OPTIONS = {
  destructionDelay: DEFAULT_DESTRUCTION_DELAY,
  animated: true,
}

export const asyncModal = (renderModal, options = {}) => {
  const { destructionDelay, animated } = { ...DEFAULT_OPTIONS, ...options }
  const container = document.createElement('div')
  document.body.appendChild(container)

  function displayModal({ onSubmit, onDismiss }) {
    render(renderModal({ onSubmit, onDismiss, active: true }), container)
  }

  function hideModal({ onSubmit, onDismiss }, callback) {
    render(
      renderModal({ onSubmit, onDismiss, active: false }),
      container,
      callback
    )
  }

  function destroyModal() {
    unmountComponentAtNode(container)
    document.body.removeChild(container)
  }

  const confirmation = new Promise((resolve) => {
    const onSubmit = (value = true) => resolve({ result: value, error: null })
    const onDismiss = (value) => resolve({ result: null, error: value })
    displayModal({ onSubmit, onDismiss })
  })

  return confirmation.finally(() => {
    const onSubmit = noop
    const onDismiss = noop
    hideModal({ onSubmit, onDismiss }, () => {
      if (animated) {
        setTimeout(destroyModal, destructionDelay)
      } else {
        destroyModal()
      }
    })
  })
}

export const useCreatorGetStartedCta = () => {
  return useCallback(() => {
    window.location = 'mailto:sales@boon.tv'
  }, [])
}

export const useDarkHeader = (value = true) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setHeaderDark(value))

    return () => {
      dispatch(setHeaderDark(false))
    }
  }, [dispatch, value])
}

export const useNotFound = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setNotFound(true))

    return () => {
      dispatch(setNotFound(false))
    }
  }, [dispatch])
}

export const useApiWrapper = (apiCall) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const runApiCall = useCallback(
    async (...params) => {
      setLoading(true)
      setError(null)
      try {
        const data = await apiCall(...params)
        setLoading(false)
        return {
          data,
          error: null,
        }
      } catch (e) {
        setError(e)
        setLoading(false)
        return {
          data: null,
          error: e,
        }
      }
    },
    [apiCall]
  )

  return {
    runApiCall,
    loading,
    error,
  }
}
