import React from 'react'
import './UserCard.scss'
import { BackgroundImage } from 'components/Image'
import Avatar from 'components/Avatar'
import { getUserUrl, displayPrice } from 'utils'
import { useHistory } from 'react-router-dom'
import { VerifiedSvg } from 'assets/svg'
import clsx from 'clsx'

const UserCard = ({ user, simple, small }) => {
  const history = useHistory()

  const onClick = () => {
    history.push(getUserUrl(user))
  }

  let membershipPrice =
    user.get('products') && user.get('products').toArray().length > 0
      ? `${displayPrice(
          user.getIn(['products', 0, 'plans', 0, 'amount']) / 100,
          user.getIn(['products', 0, 'plans', 0, 'currency'])
        )}/mo`
      : ''

  return (
    <div className={clsx('UserCard', { small })} onClick={onClick}>
      <div className="user-card-wrapper">
        <BackgroundImage
          src={user.get('coverImageUrl') || user.get('profileImageUrl')}
          className="user-card-cover"
        >
          <Avatar
            url={user.get('profileImageUrl')}
            userId={user.get('id')}
            className="user-card-avatar"
            width={80}
            height={80}
          />
        </BackgroundImage>
        <div className="user-card-profile">
          <div className="user-card-profile-left">
            <div className="user-card-name">
              <div className="user-card-name-text">{user.get('name')}</div>
              {!simple && <VerifiedSvg className="user-card-verified" />}
            </div>
            <div className="user-card-category">{user.get('title') || ' '}</div>
          </div>
          <div className="user-card-profile-right">
            {membershipPrice && (
              <div className="user-card-price">From {membershipPrice}</div>
            )}
            {/*<div className="user-card-followers">
              {user.get('followerCount')} followers
            </div>*/}
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserCard
