import React from 'react'
import InsightsNumberWidget from './InsightsNumberWidget'
import { useGetApi } from 'api'

const InsightsConnectedNumberWidget = ({
  url,
  dataMapper,
  title,
  prefix,
  suffix,
  timeScale,
  reverse,
  removeEmpty = false,
  setEmpty = null,
  customNumberFormatter = null,
}) => {
  const { data, loading, error } = useGetApi({
    url,
    dataMapper,
  })

  const { value, previousValue } = data ?? {}

  if (removeEmpty && value <= 0e-10 && previousValue <= 0e-10) {
    setEmpty && setEmpty(true)
    return null
  }

  return (
    <InsightsNumberWidget
      prefix={
        typeof prefix === 'function' && data != null ? prefix(data) : prefix
      }
      suffix={suffix}
      title={title}
      value={value}
      previousValue={previousValue}
      loading={loading}
      error={error}
      timeScale={timeScale}
      reverse={reverse}
      customNumberFormatter={customNumberFormatter}
    />
  )
}

export default InsightsConnectedNumberWidget
