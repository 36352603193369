import React, { useState } from 'react'
import './SubscribePageSummary.scss'
import { displayPrice } from 'utils'
import clsx from 'clsx'
import { ChevronDownSvg, DeleteSvg } from 'assets/svg'
import { BoonFormikTextField } from 'components/BoonInputs'
import { Form, Formik, useFormikContext } from 'formik'
import * as Yup from 'yup'

const COUPON_ERROR_CODES = {
  NO_COUPON: 'NO_COUPON',
  COUPON_INVALID: 'COUPON_INVALID',
  INVITE_NOT_VALID_FOR_LOYALTY: 'INVITE_NOT_VALID_FOR_LOYALTY',
}

const SubscribePageSummary = ({ checkout, couponCode, onSubmit, onDelete }) => {
  const [isFieldOpened, setIsFieldOpened] = useState(false)
  const currency = checkout.get('currency').toUpperCase()

  const renderCouponError = () => {
    const error = checkout.get('couponError')
    if (error == null) {
      return null
    }

    let message
    switch (error) {
      case COUPON_ERROR_CODES.NO_COUPON:
        message = `We were unable to find your coupon ${couponCode}.`
        break
      case COUPON_ERROR_CODES.COUPON_INVALID:
        message = `Coupon code ${couponCode} is no longer valid.`
        break
      case COUPON_ERROR_CODES.INVITE_NOT_VALID_FOR_LOYALTY:
        message = ``
        break
      default:
        message = error
        break
    }

    return <div className="checkout-payment-coupon-error">{message}</div>
  }

  const applyCoupon = () => {
    return (
      checkout.get('coupon') != null &&
      (checkout.getIn(['coupon', 'type']) === 'invite'
        ? checkout.getIn(['plan', 'base'])
        : true)
    )
  }

  const renderCouponSummaryForm = () => {
    const initialValues = {
      newCode: '',
    }

    const CouponApplySchema = Yup.object().shape({
      newCode: Yup.string()
        .required('Required')
        .max(30, 'Field is too long. Maximum length is 30')
        .matches(/^(\S+$)/, 'Not allow white space'),
    })

    return (
      <div className="subscribe-page-summary-coupon-form-wrapper">
        <div
          className={clsx('subscribe-page-summary-coupon-form-title', {
            hidden: isFieldOpened,
          })}
          onClick={() => setIsFieldOpened(!isFieldOpened)}
        >
          <p
            className={clsx('subscribe-page-summary-coupon-form-title-text', {
              opened: isFieldOpened,
            })}
          >
            Apply coupon code
          </p>
          <ChevronDownSvg
            className={clsx('subscribe-page-summary-coupon-form-svg', {
              opened: isFieldOpened,
            })}
          />
        </div>
        {isFieldOpened && (
          <div className="subscribe-page-summary-coupon-form-content">
            <Formik
              onSubmit={onSubmit}
              initialValues={initialValues}
              validationSchema={CouponApplySchema}
            >
              <CouponSummaryForm />
            </Formik>
          </div>
        )}
      </div>
    )
  }

  const renderCouponSummary = () => {
    if (applyCoupon()) {
      return (
        <div className="subscribe-page-summary-coupon-summary">
          <SubscribePageSummaryLine
            title={checkout.getIn(['product', 'name'])}
            value={displayPrice(checkout.get('subTotal') / 100, currency)}
            data-testid="summary-subtotal"
          />
          <SubscribePageSummaryLine
            title={couponTitle}
            value={`-${displayPrice(
              checkout.get('discountAmount') / 100,
              currency
            )}`}
            withIcon
            onDelete={onDelete}
            data-testid="summary-discount"
          />
        </div>
      )
    } else {
      return renderCouponSummaryForm()
    }
  }

  const percentOff = checkout.getIn(['coupon', 'percentOff'])
  const couponName = checkout.getIn(['coupon', 'name'])
  const duration = checkout.getIn(['coupon', 'duration'])

  const couponTitle =
    checkout.get('coupon') != null
      ? checkout.getIn(['coupon', 'type']) === 'invite'
        ? `${couponName} (${parseFloat(percentOff)}% ${duration})`
        : `Discount ${
            percentOff
              ? `(${parseFloat(percentOff)}% ${duration})`
              : `(${duration})`
          }`
      : ''

  return (
    <div className="SubscribePageSummary">
      {renderCouponSummary()}
      {renderCouponError()}
      <SubscribePageSummaryLine
        title="Payment"
        value={displayPrice(checkout.get('total') / 100, currency)}
        big
        data-testid="summary-total"
      />
      <SubscribePageSummaryLine
        title={`VAT`}
        value={displayPrice(checkout.get('vat') / 100, currency)}
        small
        data-testid="summary-vat"
      />
    </div>
  )
}

export default SubscribePageSummary

const SubscribePageSummaryLine = ({
  title,
  value,
  big,
  small,
  withIcon,
  onDelete,
  ...props
}) => {
  return (
    <div
      className={clsx('SubscribePageSummaryLine', { big, small })}
      {...props}
    >
      <div className="subscribe-page-summary-line-title">{title}</div>
      {withIcon && (
        <DeleteSvg
          className="subscribe-page-summary-coupon-cancel-svg"
          onClick={onDelete}
        />
      )}
      <div className="subscribe-page-summary-line-value">{value}</div>
    </div>
  )
}

const CouponSummaryForm = () => {
  const { isSubmitting } = useFormikContext()

  return (
    <Form>
      <div className="subscribe-page-summary-coupon-form-field">
        <BoonFormikTextField
          name="newCode"
          type="text"
          placeholder="Code"
          field={{
            'data-lpignore': true,
          }}
        />
        <button
          type="submit"
          className={clsx(
            'button primary load subscribe-page-summary-coupon-form-field-button',
            {
              loading: isSubmitting,
            }
          )}
          disabled={isSubmitting}
        >
          Apply
        </button>
      </div>
    </Form>
  )
}
