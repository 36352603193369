import React from 'react'
import './SubscriptionUnpaidBanner.scss'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectPastDueSubscriptions } from 'store/selectors'
import { getUserSlug } from 'utils'
import { URL_UPDATE_SUBSCRIPTION_PAYMENT } from 'urls'

const SubscriptionUnpaidBanner = ({ userId }) => {
  const pastDueSubscriptions = useSelector((state) =>
    selectPastDueSubscriptions(state)
  )

  if (pastDueSubscriptions.size === 0) {
    return null
  }

  const filteredSubscriptions = pastDueSubscriptions.filter(
    (s) => s.getIn(['host', 'id']) === userId
  )
  if (filteredSubscriptions.size === 0) {
    return null
  }

  const firstSubscription = filteredSubscriptions.first()
  const userSlug = getUserSlug(firstSubscription.get('host'))
  const updateSubscriptionPaymentUrl = `${URL_UPDATE_SUBSCRIPTION_PAYMENT(
    userSlug,
    firstSubscription.get('id')
  )}?redirect_url=${window.location.pathname}`

  return (
    <div className="SubscriptionUnpaidBanner">
      <Link to={updateSubscriptionPaymentUrl}>
        We're unable to charge your card, click here to update your payment
        details
      </Link>
    </div>
  )
}

export default SubscriptionUnpaidBanner
