import React from 'react'
import clsx from 'clsx'

import InsightsTableDataElement from 'components/InsightsTableWidget/InsightsTableDataElement'

const InsightsTableData = ({
  headers,
  data,
  loading,
  clickableRow,
  optionsMenu,
}) => {
  const renderRow = (rowObj, idx) => {
    return (
      <tr className={clsx('', { clickable: clickableRow !== null })} key={idx}>
        {headers
          .filter(({ active }) => active)
          .map((header, jdx) => (
            <td
              key={jdx}
              onClick={() => {
                clickableRow && clickableRow(rowObj)
              }}
            >
              <InsightsTableDataElement
                loading={loading}
                data={rowObj}
                header={header}
              />
            </td>
          ))}
        {optionsMenu && <td>{optionsMenu(rowObj)}</td>}
      </tr>
    )
  }

  if (!data) {
    return null
  }

  return <tbody>{data.map((rowObj, idx) => renderRow(rowObj, idx))}</tbody>
}

export default InsightsTableData
