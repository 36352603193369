import React from 'react'
import './MembershipElement.scss'

import { displayPrice, useMe } from 'utils'
import Avatar from 'components/Avatar'
import { LockSvg } from 'assets/svg'

import { useSelector } from 'react-redux'
import { selectIsSubscribedProduct } from 'store/selectors'

import clsx from 'clsx'

const MembershipElement = ({
  user,
  product,
  scrollRef,
  activeProductId,
  setActiveProductId,
  setShowProductCard,
}) => {
  const me = useMe()
  const productId = product?.get('id')
  const isSubscribed = useSelector((state) =>
    selectIsSubscribedProduct(productId)(state)
  )

  if (!product) {
    return ''
  }

  const plan = product.get('plans').find((f) => {
    return f.get('interval') === 'month' && f.get('intervalCount') === 1
  })

  const locked = !isSubscribed && !(me.get('id') === user.get('id'))
  const active = product.get('id') === activeProductId

  const handleOnClick = () => {
    setShowProductCard(false)
    if (product.get('id') === activeProductId) {
      locked && setShowProductCard(false)
    } else {
      locked && setShowProductCard(true)
    }
    setActiveProductId(product.get('id'))
  }

  return (
    <div
      ref={scrollRef}
      className={clsx('MembershipElement', { active })}
      onClick={handleOnClick}
    >
      <div className={clsx('membership-element-image', { locked })}>
        {locked && <LockSvg className="membership-element-lock" />}
        <div className="membership-element-clipped-box"></div>
        <Avatar
          url={product.get('productImageUrl') || user.get('profileImageUrl')}
        />
      </div>
      <div className="membership-element-title">{product.get('name')}</div>
      <div className="membership-element-subtitle">
        {locked &&
          displayPrice(
            parseFloat(plan.get('amount')) / 100,
            plan.get('currency')
          )}
      </div>
    </div>
  )
}

export default MembershipElement
