import {
  UPDATE_ME,
  SET_AUTH,
  CLEAR_AUTH,
  FOLLOW_USER,
  UN_FOLLOW_USER,
  MERGE_ME,
} from 'store/constants'

export const setAuth = (auth) => ({
  type: SET_AUTH,
  payload: {
    auth,
  },
})

export const clearAuth = () => ({
  type: CLEAR_AUTH,
})

export const updateMe = (me) => ({
  type: UPDATE_ME,
  payload: {
    me,
  },
})

export const mergeMe = (me) => ({
  type: MERGE_ME,
  payload: {
    me,
  },
})

export const followUserStore = (user) => ({
  type: FOLLOW_USER,
  payload: {
    user,
  },
})

export const unFollowUserStore = (user) => ({
  type: UN_FOLLOW_USER,
  payload: {
    user,
  },
})
