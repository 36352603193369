import React, { useCallback, useState } from 'react'
import './FreeMembershipCard.scss'
import { requireAuth } from 'store/utils/awaitStore'
import { AUTH_MODAL_STATE } from 'store/constants'
import { CheckSvg, ChevronDownSvg } from 'assets/svg'
import { isAuthenticated } from 'store/selectors'
import clsx from 'clsx'
import { useDispatch, useSelector } from 'react-redux'
import { followUser } from 'api'
import { followUserStore } from 'store/actions'

const FreeMembershipCard = ({ user, onClose }) => {
  const dispatch = useDispatch()
  const [collapsed, setCollapsed] = useState(true)
  const hasAuth = useSelector((state) => isAuthenticated(state))

  const onCtaClick = useCallback(async () => {
    await requireAuth(AUTH_MODAL_STATE.SIGNUP, true)

    try {
      await followUser({ userId: user.get('id') })
      dispatch(followUserStore(user))
    } catch (e) {
      // Pass
    }

    if (onClose) {
      onClose()
    }
  }, [onClose, dispatch, user])

  const renderCta = () => {
    if (hasAuth) {
      return (
        <div className="free-membership-card-cta free-membership-card-cta-active">
          Active
        </div>
      )
    } else {
      return (
        <button
          className="button primary light free-membership-card-cta"
          onClick={onCtaClick}
        >
          Join
        </button>
      )
    }
  }

  return (
    <div className={clsx('FreeMembershipCard', { collapsed })}>
      <div className="free-membership-card-wrapper">
        <div
          className="free-membership-card-header"
          style={{ backgroundImage: `url(${user.get('coverImageUrl')})` }}
        >
          <div className="free-membership-card-header-inner">
            <div className="free-membership-card-header-title">Membership</div>
          </div>
        </div>
        <div className="free-membership-card-body">
          <div className="free-membership-card-price">Free</div>
          <div className="free-membership-card-type">Membership</div>
          {renderCta()}
        </div>
        <div className="free-membership-card-perks">
          <div className={clsx('free-membership-card-perk')}>
            <CheckSvg /> Email updates
          </div>
          <div className={clsx('free-membership-card-perk')}>
            <CheckSvg /> Access to buy tickets
          </div>
          <div className={clsx('free-membership-card-perk')}>
            <CheckSvg /> Engage with free posts
          </div>
        </div>
        <div className="free-membership-card-description"></div>
      </div>
      {collapsed && (
        <div
          className="free-membership-card-expander"
          onClick={() => setCollapsed(false)}
        >
          <ChevronDownSvg />
        </div>
      )}
    </div>
  )
}

export default FreeMembershipCard
