import React from 'react'
import 'modals/CategoryUpsertModal/CategoryUpsertModal.scss'
import { createCategory, updateCategory } from 'api'
import { getApiError } from 'utils'
import { Form, Formik, useFormikContext } from 'formik'
import {
  BoonFormikInputAddonEmoji,
  BoonFormikTextField,
} from 'components/BoonInputs'
import clsx from 'clsx'
import NamedAnimatedModal from 'components/NamedAnimatedModal'

const CategoryUpsertModal = ({
  active,
  onClose,
  onCategoryUpsert,
  category: existingCategory,
}) => {
  const isEdit = existingCategory != null

  return (
    <NamedAnimatedModal
      active={active}
      className="CategoryUpsertModal"
      title={isEdit ? 'Edit category' : 'Create category'}
      overlay
      onClose={onClose}
    >
      <CategoryUpsertModalInner
        onCategoryUpsert={onCategoryUpsert}
        isEdit={isEdit}
        existingCategory={existingCategory}
      />
    </NamedAnimatedModal>
  )
}

export default CategoryUpsertModal

const CategoryUpsertModalInner = ({
  onCategoryUpsert,
  isEdit,
  existingCategory,
}) => {
  const categoryId = existingCategory != null && existingCategory.get('id')

  const onSubmit = async (values, { setSubmitting, setErrors }) => {
    try {
      setSubmitting(true)
      if (isEdit) {
        const category = await updateCategory({ categoryId, data: values })
        onCategoryUpsert(category)
      } else {
        const category = await createCategory({ data: values })
        onCategoryUpsert(category)
      }
    } catch (e) {
      setErrors(getApiError(e))
    }
    setSubmitting(false)
  }

  const initialValues = isEdit
    ? {
        name: existingCategory.get('name'),
        emoji: existingCategory.get('emoji'),
      }
    : {
        name: '',
        emoji: '#️⃣',
      }

  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues}>
      <CategoryUpsertModalForm isEdit={isEdit} />
    </Formik>
  )
}

const validateTitle = (value) => {
  if (!value) {
    return 'Title is required'
  } else if (value.length > 30) {
    return 'Title is too long. Maximum length is 30'
  }
}

const CategoryUpsertModalForm = ({ isEdit }) => {
  const { dirty, isSubmitting } = useFormikContext()

  return (
    <Form>
      <BoonFormikTextField
        prepend={<BoonFormikInputAddonEmoji name="emoji" />}
        name="name"
        dark
        placeholder="Title"
        validate={validateTitle}
      />

      <button
        type="submit"
        className={clsx('button primary load category-upsert-modal-submit', {
          loading: isSubmitting,
        })}
        disabled={!dirty || isSubmitting}
      >
        Save
      </button>
    </Form>
  )
}
