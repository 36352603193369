import React, { useState } from 'react'
import './UserMemberships.scss'
import ProductCard from 'components/ProductCard'
import { Col, Row } from 'components/Grid'
import { useBrowser } from 'utils'

const UserMemberships = ({ user, refreshUser }) => {
  const browser = useBrowser()
  const count = user.get('products').size
  const [showAll, setShowAll] = useState(false)

  const renderCards = () => {
    const products = user
      .get('products')
      .filter((product) =>
        product.get('plans').some((plan) => plan.get('base'))
      )

    const productCardSize = products.size === 1 ? 7 : 4

    const cards = products
      .slice(0, browser.lessThan.md && !showAll ? 3 : products.size)
      .map((product, index) => (
        <Col md={6} xl={productCardSize} key={index}>
          <ProductCard
            product={product}
            productsAmount={count}
            user={user}
            refresh={refreshUser}
          />
        </Col>
      ))

    const shouldShowMoreButton =
      browser.lessThan.md && products.size > 3 && !showAll

    return (
      <>
        {cards}
        {shouldShowMoreButton && (
          <button
            className="button creator-primary button-show-more"
            onClick={() => setShowAll(true)}
          >
            Show all memberships
          </button>
        )}
      </>
    )
  }

  if (!user.get('hasMembership')) {
    return null
  }

  return (
    <div className="UserMemberships">
      <Row className="slim-gutters justify-content-center">{renderCards()}</Row>
    </div>
  )
}

export default UserMemberships
