export const EVENT_TYPE = {
  HOST: 'host',
  PARTICIPANT: 'participant',
  NONPARTICIPANT: 'nonparticipant',
}

export const EVENT_PURCHASE_STATE = {
  PURCHASABLE: 'purchasable',
  IS_HOST: 'is_host',
  IS_PARTICIPANT: 'is_participant',
  NOT_ON_DEMAND: 'not_on_demand',
  SOLD_OUT: 'sold_out',
}

export const EVENT_STATUS = {
  DRAFT: 'draft',
  PUBLISHED: 'published',
}

export const EVENT_VISIBILITY = {
  DRAFT: 'draft',
  SCHEDULED: 'scheduled',
  PUBLISHED: 'published',
}
