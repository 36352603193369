import { displayPrice } from 'utils'

export const getBillingName = (plan) => {
  const names = {
    month: {
      1: 'month',
      3: 'quarter',
      6: 'semi-annual',
    },
    year: {
      1: 'annual',
    },
  }
  return names[plan.get('interval')][parseInt(plan.get('intervalCount'))]
}

export const BILLING_NAMES = {
  'month-3': ['Quarterly', 'quarter'],
  'month-6': ['Semi-annually', 'bi-annual'],
  'year-1': ['Annually', 'year'],
  '-': ['', ''],
}

export const displayBillingName = (interval, intervalCount) => {
  const names = {
    month: {
      1: '/month',
      3: '/quarter',
      6: '/bi-annual',
    },
    year: {
      1: '/annual',
    },
  }
  if (interval === '') {
    return ''
  } else {
    return names[interval][parseInt(intervalCount)]
  }
}

export const displayPriceWithBillingName = (
  price,
  interval,
  intervalCount,
  currency
) => {
  const displayedPrice = displayPrice(price, currency)

  if (interval === '') {
    return `From ${displayedPrice}`
  }

  return `${displayedPrice}${displayBillingName(interval, intervalCount)}`
}
