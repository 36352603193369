import React, { useState } from 'react'
import './PostCommentBodyAttachmentPhoto.scss'
import { POST_ATTACHMENT_ACCESS } from 'store/constants/post'
import { Image } from 'components/Image'
import PhotoBlurred from 'assets/img/photo-blurred.jpg'
import { InsertPhotoSvg } from 'assets/svg'
import LightModal from 'components/LightModal'

const PostCommentBodyAttachmentPhoto = ({
  hostId,
  photo,
  access,
  requireAccess,
}) => {
  const [showPhotoModal, setShowPhotoModal] = useState(false)

  const renderInner = () => {
    switch (access) {
      case POST_ATTACHMENT_ACCESS.NONE:
        return (
          <>
            <Image
              className="post-comment-body-attachment-photo-image"
              src={photo.get('fullPath') || PhotoBlurred}
              width="300"
            />
            <div
              className="post-comment-body-attachment-photo-locked"
              onClick={requireAccess}
            >
              <InsertPhotoSvg />
              <div>1 photo</div>
            </div>
          </>
        )
      default:
        return (
          <div onClick={() => setShowPhotoModal(true)}>
            <Image
              className="post-comment-body-attachment-photo-image"
              src={photo.get('fullPath') || PhotoBlurred}
              width="300"
            />
          </div>
        )
    }
  }

  return (
    <div className="PostCommentBodyAttachmentPhoto">
      {showPhotoModal && (
        <LightModal onClose={() => setShowPhotoModal(false)}>
          <Image src={photo.get('fullPath') || PhotoBlurred} />
        </LightModal>
      )}
      {renderInner()}
    </div>
  )
}

export default PostCommentBodyAttachmentPhoto
