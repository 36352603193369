import Avatar from 'components/Avatar'
import React, { Fragment, useCallback, useState } from 'react'
import RelationsMenuModal, {
  RELATION_TYPE,
} from 'routes/InsightsPage/components/RelationsMenuModal'
import { useMe } from 'utils'
import './RelationAvatar.scss'

const RelationAvatar = ({
  userId,
  hostId,
  children,
  className,
  ...otherProps
}) => {
  const [isOpen, setOpen] = useState(false)
  const me = useMe()
  const active = hostId != null && hostId === me?.id && hostId !== userId
  const handleClick = useCallback(() => {
    if (active) {
      setOpen(true)
    }
  }, [active, setOpen])

  return (
    <Fragment>
      <Avatar
        userId={userId}
        onClick={handleClick}
        className={active ? 'RelationAvatar ' + (className ?? '') : className}
        {...otherProps}
      >
        {children}
      </Avatar>
      {isOpen && (
        <RelationsMenuModal
          hostId={hostId}
          userId={userId}
          onClose={() => setOpen(false)}
          type={RELATION_TYPE.RELATION}
        />
      )}
    </Fragment>
  )
}

export default RelationAvatar
