import React, { useCallback } from 'react'
import './CreatorMembershipUpsertPlaceholder.scss'
import { useMe } from 'utils'
import { useToasts } from 'react-toast-notifications'
import { PROFILE_STATUS } from 'store/constants'
import { URL_CREATOR_SETTINGS_PROFILE, URL_CREATOR_SETUP_PROFILE } from 'urls'
import { useHistory } from 'react-router-dom'

const CreatorMembershipUpsertPlaceholder = ({ upsertProduct }) => {
  const me = useMe()
  const { addToast } = useToasts()
  const history = useHistory()
  const currency = me?.get('currency')
  const profileStatus = me?.get('profileStatus')

  const onAdd = useCallback(() => {
    if (currency != null) {
      upsertProduct()
    } else {
      addToast('To create a membership, first set your currency', {
        appearance: 'error',
      })
      if (profileStatus === PROFILE_STATUS.PUBLISHED) {
        history.push(URL_CREATOR_SETTINGS_PROFILE)
      } else {
        history.push(URL_CREATOR_SETUP_PROFILE)
      }
    }
  }, [upsertProduct, history, addToast, currency, profileStatus])

  return (
    <div className="CreatorMembershipUpsertPlaceholder">
      <button className="button secondary" onClick={onAdd}>
        Add
      </button>
    </div>
  )
}

export default CreatorMembershipUpsertPlaceholder
