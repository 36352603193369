import React from 'react'
import 'modals/CheckoutModal/CheckoutModal.scss'
import './FollowerModal.scss'
import AnimatedModal from 'components/AnimatedModal'
import CloseSvg from 'assets/svg/CloseSvg'
import { useDispatch, useSelector } from 'react-redux'
import { requireAuth } from 'store/utils/awaitStore'
import { useLockBodyScroll } from 'react-use'
import Avatar from 'components/Avatar/Avatar'
import { followUser, registerParticipant } from 'api'
import { followUserStore } from 'store/actions'
import { getEventUrl } from 'utils'
import { AUTH_MODAL_STATE } from 'store/constants'

const FollowerModal = (props) => {
  const { active, refreshEvent } = props
  const browser = useSelector((state) => state.get('browser'))
  return (
    <AnimatedModal active={active} overlay className="CheckoutModal">
      <FollowerModalInner
        browser={browser}
        refreshEvent={refreshEvent}
        {...props}
      />
    </AnimatedModal>
  )
}

export default FollowerModal

const FollowerModalInner = ({
  active,
  event,
  host,
  onClose,
  refreshEvent,
  redirectOnFollow,
}) => {
  const dispatch = useDispatch()
  useLockBodyScroll(active)

  const onClosePrevent = (e) => {
    e.preventDefault()
    e.stopPropagation()
    onClose()
  }

  const onClickFollow = async (e) => {
    if (await requireAuth(AUTH_MODAL_STATE.SIGNUP, true)) {
      await followUser({ userId: host.get('id') })
      dispatch(followUserStore(host))
      if (event.get('live') && !event.get('ended')) {
        await registerParticipant({ eventId: event.get('id') })
      }
      onClose()
      if (getEventUrl(event) === window.location.pathname) {
        if (refreshEvent) {
          refreshEvent()
        }
      } else {
        redirectOnFollow()
      }
    }
  }

  return (
    <>
      <div className="checkout-modal-close" onClick={onClosePrevent}>
        <CloseSvg className="checkout-modal-close-icon" />
      </div>
      <div className="checkout-modal-centered">
        <div className="follower-modal-centered">
          <div className="follower-modal-wrapper">
            <div className="follower-modal-header">
              <Avatar
                url={host.get('profileImageUrl')}
                userId={host.get('id')}
                className="follower-header-avatar"
              />
            </div>
            <div className="follower-modal-body">
              Follow <strong>{host.get('name')}</strong> to get free access to{' '}
              <strong>{event.get('title')}</strong> and more!
              <ul>
                <li>Access free content</li>
                <li>Be notified when new events and posts are published</li>
                <li>Be part of the community</li>
              </ul>
              <button
                className="button primary follower-modal-button"
                onClick={onClickFollow}
              >
                Follow
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
