import LoadImage from 'blueimp-load-image'
import axios from 'axios'

/*
  Uses blueimp-load-image to rotate iOS images that are lazily rotated in
  EXIF data.

  This is due to browsers not caring about this EXIF data on the web
  and thus leaves the image in a wrong rotation.
*/
export const fixImageOrientation = (file) =>
  new Promise((resolve) => {
    if (
      ('type' in file &&
        ['image/png', 'image/gif', 'image/webp', 'application/pdf'].includes(
          file.type
        )) ||
      typeof HTMLCanvasElement.prototype.toBlob !== 'function'
    ) {
      return resolve(file)
    }

    try {
      LoadImage(
        file,
        function (canvas) {
          if (canvas != null && canvas.toBlob != null) {
            canvas.toBlob((blob) => {
              if (blob != null) {
                blob.lastModified = file.lastModified
                blob.name = file.fileName

                resolve(blob)
              } else {
                resolve(file)
              }
            }, 'image/jpeg')
          } else {
            resolve(file)
          }
        },
        { canvas: true, orientation: true }
      )
    } catch (e) {
      console.error(e)
      resolve(file)
    }
  })

// Uploads a File to an S3 endpoint
export const uploadToS3 = async ({ url, urlFields, file, options = {} }) => {
  var data = new FormData()
  for (const [key, value] of Object.entries(urlFields)) {
    data.append(key, value)
  }
  data.append('file', file)
  const response = await axios.post(url, data, {
    headers: {
      'content-type': 'multipart/form-data',
    },
    ...options,
  })

  return response
}
