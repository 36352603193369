import store from 'store'
import { createConsumer } from '@rails/actioncable'
import { ensureValidToken } from './apiMiddleware'
import { getAccessToken } from 'store/selectors'
import { BACKEND_URL } from 'appConstants'

export const cableConnect = async () => {
  await ensureValidToken()

  const state = store.getState()
  const accessToken = getAccessToken(state)

  const url = `${BACKEND_URL.replace('http', 'ws').replace(
    '/v1',
    '/cable'
  )}?token=${accessToken}`
  const connection = createConsumer(url)

  return connection
}
