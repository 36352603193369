import React, { useEffect } from 'react'
import { Formik, Form, Field } from 'formik'
import './EventEditForm.scss'
import clsx from 'clsx'
import StyledApiErrorMessage from 'components/StyledApiErrorMessage'
import { sluggify } from 'utils'
import DatePicker from 'react-datepicker'
import './DatePicker.scss'
import { BoonFormikTextField } from 'components/BoonInputs'
import DatePickerInput from './DatePickerInput'
import AvailabilityPanel from 'routes/UploadPage/AvailabilityPanel'
import { getMe } from 'store/selectors'
import { useSelector } from 'react-redux'
import BoonFormGroup from 'components/BoonInputs/BoonFormGroup'
import UploadImageField from 'components/UploadImageField'
import UploadVideoField from 'components/UploadVideoField'
import CategorySelector from 'components/CategorySelector'
import VisibilityPanel from 'components/VisibilityPanel'
import { getVisibilitySubmitButtonText } from 'components/VisibilityPanel/visibilityUtils'

const EventEditFormInner = ({
  isSubmitting,
  values,
  isValid,
  setFieldValue,
  initialValues,
  errors,
  touched,
  setFieldTouched,
  edit,
  ended,
  published,
  submitCount,
}) => {
  const me = useSelector((state) => getMe(state))

  useEffect(() => {
    if (!touched.slug && values.title && !edit) {
      const slug = sluggify(values.title)
      setFieldValue('slug', slug)
    }
  }, [values.title, touched.slug, setFieldValue, edit])

  const renderSubmit = () => {
    return (
      <button
        type="submit"
        disabled={isSubmitting}
        className={clsx(
          'button schedule-page-event-submit primary medium loader',
          {
            loading: isSubmitting,
          }
        )}
      >
        {getVisibilitySubmitButtonText(
          'event',
          edit,
          initialValues.visibility,
          values.visibility
        )}
      </button>
    )
  }

  return (
    <Form>
      <section className="edit-event-form-section">
        <BoonFormGroup
          label="Title"
          helperText="Keep it short and sweet."
          required
        >
          <BoonFormikTextField type="text" name="title" />
        </BoonFormGroup>

        {!edit && (
          <BoonFormGroup
            label="Test event"
            helperText="It will only be visible to you and we won't send any notifications to your audience"
          >
            <Field
              id="test"
              name="test"
              type="checkbox"
              className="boon-dark-checkbox"
            />
            <label className="test" htmlFor="test">
              This is a test event
            </label>
          </BoonFormGroup>
        )}

        <BoonFormGroup
          label="Event link"
          helperText="This will be used to generate the link for your event."
          required
        >
          <BoonFormikTextField
            type="text"
            name="slug"
            prepend={`https://boon.tv/${me?.get('username') || '<username>'}/`}
          />
        </BoonFormGroup>
        <BoonFormGroup
          label="Event start"
          className="event-edit-form-group-storter"
          required
        >
          <DatePicker
            showTimeSelect
            timeIntervals={15}
            timeFormat="HH:mm"
            dateFormat="yyyy/MM/dd HH:mm"
            className="edit-event-form-calendar"
            customInput={
              <DatePickerInput
                field={{
                  name: 'startTime',
                  disabled: ended === true,
                }}
                meta={{
                  touched: touched.startTime,
                  error: errors.startTime,
                }}
                label="Event start"
              />
            }
            popperPlacement="top-start"
            minDate={new Date()}
            onChange={(date) => {
              setFieldTouched('startTime')
              setFieldValue('startTime', date)
            }}
            selected={values.startTime}
          />
        </BoonFormGroup>
        <BoonFormGroup
          label="Duration"
          required
          className="event-edit-form-group-storter"
        >
          <BoonFormikTextField
            type="text"
            name="duration"
            label="Duration"
            append="Minutes"
            placeholder="0"
          />
        </BoonFormGroup>

        <BoonFormGroup
          label="Description"
          helperText="Describe the event to your audience. Don’t worry, you can always edit this if you need to."
          required
        >
          <BoonFormikTextField
            name="description"
            multiline
            className="schedule-page-event-description-input"
          />
        </BoonFormGroup>

        <BoonFormGroup
          label="Categories"
          className="edit-event-form-categories"
        >
          <CategorySelector />
        </BoonFormGroup>
      </section>

      <section className="edit-event-form-section">
        <BoonFormGroup
          label="Cover image"
          helperText="The cover image is used when sharing and listing the event. We recommend an aspect ratio of 16:9. If no image is provided, the first frame of the video will be used."
          required
        >
          <UploadImageField
            imageId={values.coverImageId}
            imageUrl={values.coverImageUrl}
            onSetId={(id) => setFieldValue('coverImageId', id)}
            onSetUrl={(url) => setFieldValue('coverImageUrl', url)}
          />
        </BoonFormGroup>

        <BoonFormGroup
          label="Trailer video"
          helperText="The event trailer is a great way to give your audience a better idea of what your event is gonna be like. We generally accept any and all video files, most commonly .mp4, .mov and .avi."
        >
          <UploadVideoField
            videoId={values.coverVideoUploadId}
            videoUrl={values.coverVideoUrl}
            onSetId={(id) => setFieldValue('coverVideoUploadId', id)}
            onSetUrl={(url) => setFieldValue('coverVideoUrl', url)}
            slim
          />
          {errors.coverVideoUploadId && touched.coverVideoUploadId && (
            <div className="input-error">{errors.coverVideoUploadId}</div>
          )}
        </BoonFormGroup>
      </section>

      <section className="edit-event-form-section">
        <AvailabilityPanel edit={edit} />
      </section>

      <div className="edit-event-form-section">
        <VisibilityPanel edit={edit} />
      </div>

      <StyledApiErrorMessage />
      {submitCount > 0 && !isValid && errors.message == null && (
        <div className="input-error">
          There were errors in the form, see above
        </div>
      )}

      <div className="edit-form-submit-bottom">{renderSubmit()}</div>
    </Form>
  )
}

const EventEditForm = (outerProps) => {
  return (
    <div className="EventEditForm">
      <Formik {...outerProps}>
        {(props) => <EventEditFormInner {...outerProps} {...props} />}
      </Formik>
    </div>
  )
}

export default EventEditForm
