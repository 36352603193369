import React, { useEffect, useState } from 'react'
import 'modals/PostLikesModal/PostLikesModal.scss'
import NamedAnimatedModal from 'components/NamedAnimatedModal'
import Spinner from 'components/Spinner'
import Avatar from 'components/Avatar/Avatar'
import { useGetPostLikesApi } from 'api'
import { getFormattedActivityDate } from 'utils'

const PostLikesModal = ({ postId, active, onClose }) => {
  const [count, setCount] = useState()

  useEffect(() => {
    if (!active) {
      setCount(null)
    }
  }, [active, setCount])

  return (
    <NamedAnimatedModal
      title={count ? (count === 1 ? '1 Like' : `${count} Likes`) : 'Likes'}
      className="PostLikesModal"
      active={active}
      onClose={onClose}
    >
      <PostLikesModalInner postId={postId} setCount={setCount} />
    </NamedAnimatedModal>
  )
}

export default PostLikesModal

const PostLikesModalInner = ({ postId, setCount }) => {
  const { data: likes } = useGetPostLikesApi({ postId })

  useEffect(() => {
    if (likes != null) {
      setCount(likes.size)
    }
  }, [likes, setCount])

  const renderList = () => {
    if (likes == null) {
      return <Spinner small />
    }

    return likes
      .sortBy((l) => l.get('createdAt'))
      .reverse()
      .map((like) => <LikeCard key={like.getIn(['user', 'id'])} like={like} />)
  }

  return <div className="post-likes-modal-list">{renderList()}</div>
}

const LikeCard = ({ like }) => {
  return (
    <div className="LikeCard">
      <Avatar
        className="like-card-avatar"
        url={like.getIn(['user', 'profileImageUrl'])}
        userId={like.getIn(['user', 'id'])}
      />
      <div className="like-card-info">
        <div className="like-card-name">{like.getIn(['user', 'name'])}</div>
        <div className="like-card-date">
          {getFormattedActivityDate(like.get('createdAt'))}
        </div>
      </div>
    </div>
  )
}
