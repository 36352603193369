import React, { useMemo } from 'react'
import 'components/InsightsSimpleNumberWidget/InsightsSimpleNumberWidget.scss'
import InsightsContentLoader from 'components/InsightsContentLoader/InsightsContentLoader'

const InsightsSimpleNumberWidget = ({
  title,
  value,
  prefix,
  suffix,
  loading,
  error,
  useFormatter = true,
}) => {
  const numberFormatter = useMemo(
    () =>
      Intl.NumberFormat(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
        notation: 'compact',
        compactDisplay: 'short',
      }),
    []
  )

  const renderContent = () => {
    if (error) {
      return (
        <div
          className="insights-simple-number-widget-error"
          data-testid="insight-error"
        >
          Loading failed
        </div>
      )
    } else if (loading) {
      return (
        <InsightsContentLoader speed={1} width={80} height={32}>
          <rect x="0" y="8" rx="10" ry="10" width="80" height="20" />
        </InsightsContentLoader>
      )
    } else {
      return (
        <div
          className="insights-simple-number-widget-value"
          data-testid="insight-value"
        >
          {prefix}
          {useFormatter ? numberFormatter.format(value) : value}
          {suffix}
        </div>
      )
    }
  }

  return (
    <div className="InsightsSimpleNumberWidget">
      <div className="insights-simple-number-widget-title">{title}</div>
      {renderContent()}
    </div>
  )
}

export default InsightsSimpleNumberWidget
