import React from 'react'
import './EventButton.scss'
import clsx from 'clsx'

const EventButtonUnavailable = ({ className }) => {
  return (
    <button className={clsx('button disabled', className)}>Not for sale</button>
  )
}

export default EventButtonUnavailable
