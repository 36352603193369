import React from 'react'
import './PostComposeModalAttachmentDocument.scss'
import { useFormikContext } from 'formik'
import { AttachFileSvg } from 'assets/svg'
import CloseSvg from 'assets/svg/CloseSvg'

const PostComposeModalAttachmentDocument = ({ removeAttachment }) => {
  const { values } = useFormikContext()
  const isUploading = values.attachmentUploading
  const uploadingProgress = values.attachmentUploadingProgress
  const filename = values.attachmentDocumentFilename

  return (
    <div className="PostComposeModalAttachmentDocument">
      <div className="post-compose-modal-attachment-document-row">
        <AttachFileSvg className="post-compose-modal-attachment-document-icon" />
        <div className="post-compose-modal-attachment-document-name">
          {filename}
        </div>
        {!isUploading && (
          <CloseSvg
            onClick={removeAttachment}
            className="post-compose-modal-attachment-document-remove"
          />
        )}
      </div>
      {isUploading && (
        <div className="post-compose-modal-attachment-document-progress">
          <div
            className="post-compose-modal-attachment-document-progress-bar"
            style={{ width: `${uploadingProgress}%` }}
          />
        </div>
      )}
    </div>
  )
}

export default PostComposeModalAttachmentDocument
