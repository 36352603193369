import { POLL_THEMES } from 'store/constants/post'

export const SELECT_ONLY_OPTION = 'SELECT_ONLY_OPTION'
export const SELECT_OPTION = 'SELECT_OPTION'
export const UNSELECT_OPTION = 'UNSELECT_OPTION'
export const TOGGLE_OPTION = 'TOGGLE_OPTION'

export const LIMIT_NON_INTERACTIVE = 3

export const POLL_BASIC_THEME_CLASS_NAMES = {
  [POLL_THEMES.DEFAULT]: 'poll-basic-theme-default',
  [POLL_THEMES.NEXT_LIVE]: 'poll-basic-theme-next-live',
  [POLL_THEMES.TELL_ME]: 'poll-basic-theme-tell-me',
  [POLL_THEMES.ASK_ME]: 'poll-basic-theme-ask-me',
}
