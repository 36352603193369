import React, {
  useCallback,
  useReducer,
  useState,
  useMemo,
  useEffect,
} from 'react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import './UserHome.scss'
import { useSelector } from 'react-redux'
import MembershipNavigation from 'components/MembershipNavigation'
import { getIsSubscribed, getIsFollowing } from 'store/selectors'
import { useMe, getUserUrl, usePushHistoryWithSavedState } from 'utils'
import UserMemberships from 'routes/UserPage/components/UserMemberships'
import clsx from 'clsx'
import NavigationTabs from 'routes/UserPage/components/NavigationTabs'
import ChannelHeader from 'components/ChannelHeader/ChannelHeader'
import PostList from 'components/PostList'
import { Map } from 'immutable'
import {
  ActivitySvg,
  InsertPhotoOutlineSvg,
  OndemandVideoSvg,
  AttachFileSvg,
} from 'assets/svg'
import { NAVIGATION_TABNAMES } from 'routes/UserPage/components/NavigationTabConstants'
import CheckoutModal from 'modals/CheckoutModal'
import Videos from 'components/Videos'
import CategoryCardContainer from 'components/CategoryCardContainer'
import UserAboutBox from 'routes/UserPage/components/UserAboutBox'
import InviteCodeReminder from 'components/InviteCodeReminder'

const NAVIGATION_TABS = [
  { icon: ActivitySvg, name: NAVIGATION_TABNAMES.ACTIVITY },
  { icon: OndemandVideoSvg, name: NAVIGATION_TABNAMES.VIDEOS },
  { icon: InsertPhotoOutlineSvg, name: NAVIGATION_TABNAMES.PHOTOS },
  { icon: AttachFileSvg, name: NAVIGATION_TABNAMES.FILES },
  // TODO: Put these back in
  //{ icon: PollSvg, name: NAVIGATION_TABNAMES.POLLS },
]

const ATTACHMENT_TYPE = {
  [NAVIGATION_TABNAMES.PHOTOS]: 'Photo',
  [NAVIGATION_TABNAMES.ACTIVITY]: undefined,
  [NAVIGATION_TABNAMES.VIDEOS]: 'Event',
  [NAVIGATION_TABNAMES.POLLS]: 'Poll',
  [NAVIGATION_TABNAMES.FILES]: 'Document',
}
const filterReducer = (state, obj) => {
  return state.get(obj.group) === obj.key
    ? state.update(obj.group, (_) => null)
    : state.update(obj.group, (_) => obj.key)
}

const UserHome = ({ user, userSlug, refreshUser, tab }) => {
  const me = useMe()
  const pushHistory = usePushHistoryWithSavedState()
  const location = useLocation()
  const browser = useSelector((state) => state.get('browser'))
  const isSubscribed = useSelector((state) =>
    getIsSubscribed(user.get('id'))(state)
  )
  const isFollowing = useSelector((state) =>
    getIsFollowing(user.get('id'))(state)
  )
  const isCreator = user.get('id') === me.get('id')
  const showNonAccessElements = !isSubscribed && !isCreator
  const showMembershipNavigation =
    !showNonAccessElements && user.get('products').size >= 2
  const [postCount, setPostCount] = useState(null)
  const defaultTab =
    NAVIGATION_TABS.find(({ name }) => name.toLowerCase() === tab) ||
    NAVIGATION_TABS[0]
  const [activeTab, setActiveTab] = useState(defaultTab.name)
  const { productId, categoryId } = queryString.parse(location.search)
  const currentProductId =
    productId ||
    (isSubscribed &&
      me
        .get('activeSubscriptions')
        .find((s) => s.getIn(['host', 'id']) === user.get('id'))
        ?.getIn(['product', 'id']))
  const [activeFilters, dispatchFilter] = useReducer(
    filterReducer,
    Map({
      attachmentType: null,
      categoryId: categoryId,
      postType: null,
      productId: currentProductId,
      videoType: null,
      watched: null,
      saved: null,
    })
  )

  useEffect(() => {
    dispatchFilter({ group: 'productId', key: currentProductId })
  }, [currentProductId])

  const [checkoutModalOpen, setCheckoutModalOpen] = useState(false)
  const [clickedPost, setClickedPost] = useState(null)
  const filters = useMemo(
    () => ({
      attachment_type: ATTACHMENT_TYPE[activeTab],
      category_id: activeFilters.get('categoryId')
        ? activeFilters.get('categoryId')
        : undefined,
      post_type: activeFilters.get('postType')
        ? activeFilters.get('postType') === 'unlisted'
          ? 'archived'
          : activeFilters.get('postType')
        : undefined,
      video_type: activeFilters.get('videoType')
        ? activeFilters.get('videoType')
        : undefined,
      watched: activeFilters.get('watched')
        ? activeFilters.get('watched')
        : undefined,
      product_id: activeFilters.get('productId')
        ? activeFilters.get('productId')
        : undefined,
      saved: activeFilters.get('saved') ? true : undefined,
    }),
    [activeFilters, activeTab]
  )

  const channelHeaderRef = useCallback(
    (elem) => {
      if (elem && browser.greaterThan.md) {
        // Scroll past header image, if present
        // 24px is difference between header and profile title
        // 80px is half the avatar
        // 24px is the amount we want to show about the avatar
        // 64px is the height of the header

        window.scrollTo({
          top: elem.getBoundingClientRect().top - 24 - 80 - 24 - 64,
        })
      }
    },
    [browser]
  )

  const onTabClicks = (tab) => {
    if (activeTab !== tab) {
      setPostCount(null)
      dispatchFilter({ group: 'categoryId', key: null })
      setActiveTab(tab)
      pushHistory(
        `${getUserUrl(user)}/nav/${tab.toLowerCase()}`,
        { categoryId: null },
        true
      )
    }
  }

  const [modalTitle, setModalTitle] = useState('Unlock to see this photo')

  const onNoneAccessPostClick = useCallback(
    (post, title) => {
      title && setModalTitle(title)
      setCheckoutModalOpen(true)
      setClickedPost(post)
    },
    [setCheckoutModalOpen, setClickedPost]
  )

  const onCheckoutModalClose = useCallback(() => {
    setCheckoutModalOpen(false)
  }, [setCheckoutModalOpen])

  const renderContent = () => {
    return (
      <>
        {activeTab === NAVIGATION_TABNAMES.VIDEOS ? (
          <Videos
            user={user}
            filters={filters}
            dispatchFilter={dispatchFilter}
            setPostCount={setPostCount}
          />
        ) : (
          <PostList
            key={activeTab}
            user={user}
            hasAccess={!showNonAccessElements}
            postType={activeTab}
            filters={filters}
            dispatchFilter={dispatchFilter}
            onNoneAccessPostClick={onNoneAccessPostClick}
            setPostCount={setPostCount}
          />
        )}
      </>
    )
  }

  return (
    <div className={clsx('UserHome', { 'has-access': !showNonAccessElements })}>
      <div className="user-home-content-container">
        <ChannelHeader
          user={user}
          ref={channelHeaderRef}
          showFollower={showNonAccessElements}
        />
        {showNonAccessElements && (
          <>
            <UserMemberships user={user} refreshUser={refreshUser} />
            {!isFollowing && <UserAboutBox user={user} />}
          </>
        )}
        <CheckoutModal
          active={checkoutModalOpen}
          hostId={user.get('id')}
          title={modalTitle}
          onClose={onCheckoutModalClose}
          productIds={clickedPost && clickedPost.get('productIds')}
        />

        {!showNonAccessElements && (
          <InviteCodeReminder user={user} isHost={isCreator} />
        )}
        {showMembershipNavigation && (
          <MembershipNavigation
            user={user}
            activeFilters={activeFilters}
            dispatchFilter={dispatchFilter}
          />
        )}
        <NavigationTabs
          tabList={NAVIGATION_TABS}
          activeTab={activeTab}
          onClick={onTabClicks}
        />
        <CategoryCardContainer
          userSlug={userSlug}
          filters={filters}
          activeFilters={activeFilters}
          dispatchFilter={dispatchFilter}
          setPostCount={setPostCount}
          postCount={postCount}
          activeTab={activeTab}
          isCreator={isCreator}
        />
        {renderContent()}
      </div>
    </div>
  )
}

export default UserHome
