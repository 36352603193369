import React from 'react'
import LinkifyLib from 'linkifyjs/react'

const Linkify = ({ children }) => {
  return (
    <LinkifyLib
      options={{ attributes: { target: '_blank', rel: 'noopener noreferrer' } }}
    >
      {children}
    </LinkifyLib>
  )
}

export default Linkify
