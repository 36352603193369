import { SET_AUTH_MODAL, SET_HEADER_DARK, SET_NOT_FOUND } from 'store/constants'

// Base Modal
export const BaseSetModal = (type, payload) => {
  return {
    type,
    payload,
  }
}
export const BaseOpenModal = (setModal, payload = {}) => {
  return setModal({ isActive: true, ...payload })
}
export const BaseCloseModal = (setModal, payload = {}) => {
  return setModal({ isActive: false, ...payload })
}

// Modals
export const setAuthModal = ({
  isActive,
  newlyCreated = false,
  state,
  message,
}) => BaseSetModal(SET_AUTH_MODAL, { isActive, newlyCreated, state, message })
export const openAuthModal = ({ state, message }) =>
  BaseOpenModal(setAuthModal, { state, message })
export const closeAuthModal = ({ newlyCreated = false } = {}) =>
  BaseCloseModal(setAuthModal, { newlyCreated })

// Header
export const setHeaderDark = (dark) => ({
  type: SET_HEADER_DARK,
  payload: dark,
})

export const setNotFound = (value) => ({
  type: SET_NOT_FOUND,
  payload: value,
})
