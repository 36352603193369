import store from 'store'
import { isAuthenticated } from 'store/selectors'
import { openAuthModal } from 'store/actions'
import { AUTH_MODAL_STATE } from 'store/constants'

// Returns: true on auth, false on no auth
export const awaitAuth = () =>
  new Promise((resolve, reject) => {
    let unsubscribe
    const handleAction = () => {
      const state = store.getState()

      if (state.getIn(['ui', 'modals', 'auth', 'isActive']) === false) {
        unsubscribe()
        if (isAuthenticated(state)) {
          resolve(true)
        } else {
          resolve(false)
        }
      }
    }
    unsubscribe = store.subscribe(handleAction)
    handleAction()
  })

export const requireAuth = async (
  defaultAuthState = AUTH_MODAL_STATE.SIGNIN,
  awaitAuthentication = false,
  message = null
) => {
  const state = store.getState()
  if (isAuthenticated(state)) {
    return true
  }

  store.dispatch(openAuthModal({ state: defaultAuthState, message }))
  if (awaitAuthentication) {
    return awaitAuth()
  } else {
    return false
  }
}

export const getIsAuthenticated = () => {
  const state = store.getState()
  return isAuthenticated(state)
}

export const getNewlyCreated = () => {
  const state = store.getState()
  return state.getIn(['ui', 'modals', 'auth', 'newlyCreated'])
}

export const getAuthMessage = () => {
  const state = store.getState()
  return state.getIn(['ui', 'modals', 'auth', 'message'])
}
