import { useHistory } from 'react-router-dom'
import { AUTH_MODAL_STATE } from 'store/constants'
import { URL_CREATOR, URL_LEAD_QUALIFICATION } from 'urls'
import { useMount } from 'react-use'
import { requireAuth, getIsAuthenticated } from 'store/utils/awaitStore'
import { trackCreatorDirectInit, trackCreatorDirectSignUp } from 'tracking'

const CreatorSignUpPage = () => {
  const history = useHistory()

  useMount(async () => {
    const wasAuthed = getIsAuthenticated()
    history.replace(URL_CREATOR)
    trackCreatorDirectInit()
    if (await requireAuth(AUTH_MODAL_STATE.SIGNUP, true)) {
      if (!wasAuthed) {
        trackCreatorDirectSignUp()
      }

      history.replace(URL_LEAD_QUALIFICATION)
    }
  })

  return null
}

export default CreatorSignUpPage
