import React from 'react'
import './CreatorSetupMenu.scss'
import { NavLink } from 'react-router-dom'
import {
  URL_CREATOR_SETUP_PROFILE,
  URL_CREATOR_SETUP_PAYOUT,
  URL_CREATOR_SETUP_MEMBERSHIP,
} from 'urls'
import clsx from 'clsx'

const CreatorSetupMenu = ({ children }) => {
  return (
    <div className="CreatorSetupMenu">
      <div className="creator-setup-menu-actions">{children}</div>
      <div className="creator-setup-menu">
        <CreatorSetupMenuLink to={URL_CREATOR_SETUP_PROFILE}>
          Profile
        </CreatorSetupMenuLink>
        <CreatorSetupMenuLink to={URL_CREATOR_SETUP_MEMBERSHIP}>
          Memberships
        </CreatorSetupMenuLink>
        <CreatorSetupMenuLink to={URL_CREATOR_SETUP_PAYOUT}>
          Payout
        </CreatorSetupMenuLink>
      </div>
    </div>
  )
}

export default CreatorSetupMenu

const CreatorSetupMenuLink = React.memo(({ to, disabled, children }) => (
  <NavLink
    exact
    className={clsx('creator-setup-menu-item', { disabled })}
    to={to}
  >
    {children}
  </NavLink>
))
