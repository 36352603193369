import React, { useState } from 'react'
import './HeaderProfile.scss'
import { getUserUrl } from 'utils'
import { Link } from 'react-router-dom'
import clsx from 'clsx'

import ProfileMenuMobileModal from 'components/ProfileMenuMobileModal'

import { OptionDotsSvg } from 'assets/svg'

const HeaderProfile = ({ user, dark }) => {
  const [menuActive, setMenuActive] = useState(false)

  const userUrl = getUserUrl(user)

  const onProfileClick = () => {
    window.scrollTo(0, 0)
  }

  const onMenuClick = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setMenuActive(true)
  }

  return (
    <div className={clsx('HeaderProfile', { dark })}>
      <Link
        className="header-profile-wrapper"
        to={userUrl}
        onClick={onProfileClick}
      >
        <div className="header-profile-name">{user.get('name')}</div>
        <OptionDotsSvg className="header-profile-menu" onClick={onMenuClick} />
      </Link>
      <ProfileMenuMobileModal
        user={user}
        active={menuActive}
        onClose={() => setMenuActive(false)}
      />
    </div>
  )
}

export default HeaderProfile
