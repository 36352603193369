import React, { useRef, useState } from 'react'
import './ChatCompose.scss'
import { useSelector } from 'react-redux'
import { getMe } from 'store/selectors'
import Avatar from 'components/Avatar'
import clsx from 'clsx'
import { SendSvg } from 'assets/svg'
import { mentionUsers, renderMentionUserSuggestion } from 'utils'
import { MentionsInput, Mention } from 'react-mentions'

const ChatCompose = ({ onMessageSend, onFocus, connecting, hostId }) => {
  const inputRef = useRef(null)
  const me = useSelector((state) => getMe(state))
  const [inputMessage, setInputMessage] = useState('')
  const [sending, setSending] = useState(false)

  const canSend = inputMessage.length > 0 && !connecting

  const handleSendMessage = async () => {
    if (canSend) {
      setSending(true)
      const result = await onMessageSend(inputMessage)
      setSending(false)

      if (result) {
        // Good
        setInputMessage('')
      } else {
        // Error
        alert("Couldn't send message")
      }
    }
  }

  const mentionStyle = {
    '&multiLine': {
      input: {
        height: null,
        backgroundColor: 'none',
        border: 'none',
        outline: 'none',
      },
    },
    highlighter: {
      outline: 'none',
      border: 'none',
    },
  }

  const mentionInnerStyle = {
    backgroundColor: '#68a0f2',
  }

  return (
    <div className="ChatCompose">
      <Avatar url={me.get('profileImageUrl')} userId={me.get('id')} />
      <div className="chat-compose-message">
        <MentionsInput
          className="chat-compose-message-field"
          inputRef={inputRef}
          placeholder="Say something..."
          value={inputMessage}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && e.shiftKey === false) {
              e.preventDefault()
              handleSendMessage()
            }
          }}
          onClick={() => inputRef?.current?.focus()}
          onChange={({ target: { value } }) => setInputMessage(value)}
          style={mentionStyle}
          allowSuggestionsAboveCursor={true}
        >
          <Mention
            trigger="@"
            markup="^^^__display__^^__id__^^^"
            data={mentionUsers(hostId, me)}
            renderSuggestion={renderMentionUserSuggestion(
              'chat-compose-message-field__suggestions-user',
              'chat-compose-tag-suggestion-avatar'
            )}
            style={mentionInnerStyle}
          />
        </MentionsInput>
      </div>
      <div
        className={clsx('chat-compose-button', {
          loading: sending,
          disabled: !canSend,
        })}
      >
        <SendSvg onClick={handleSendMessage} />
      </div>
    </div>
  )
}

export default ChatCompose
