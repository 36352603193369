import React, { useCallback, useState } from 'react'
import './EventButton.scss'
import { LockSvg } from 'assets/svg'
import clsx from 'clsx'
import CheckoutModal from 'modals/CheckoutModal'
import { EVENT_BUTTON_TYPE } from 'components/EventButton/EventButton'
import { displayPriceWithBillingName } from 'components/ProductCard/planUtils'

const EventButtonPurchaseable = ({ className, event, type }) => {
  const [showCheckout, setShowCheckout] = useState(false)
  const [checkoutTitle, setCheckoutTitle] = useState(null)

  const _onClick = async (e) => {
    e.stopPropagation()
    e.preventDefault()

    setShowCheckout(true)
    setCheckoutTitle(event.get('title'))
  }

  const showFromPrice = () => {
    const fromPrice = event.get('fromPrice').toJS()
    return (
      <span className="event-page-button-price">
        {displayPriceWithBillingName(
          parseFloat(fromPrice[0]),
          fromPrice[1],
          fromPrice[2],
          fromPrice[3]
        )}
      </span>
    )
  }

  const onCheckoutClose = useCallback(() => {
    setShowCheckout(false)
  }, [])

  return (
    <>
      <CheckoutModal
        active={showCheckout}
        title={checkoutTitle}
        event={event}
        hostId={event.getIn(['host', 'id'])}
        productIds={event.get('productIds')}
        onClose={onCheckoutClose}
      />
      <button className={clsx('button', className)} onClick={_onClick}>
        <LockSvg className="unlock-button-icon" /> Unlock{' '}
        {type === EVENT_BUTTON_TYPE.LARGE && showFromPrice()}
      </button>
    </>
  )
}

export default EventButtonPurchaseable
