import React from 'react'
import './EventButton.scss'

import { CalendarEvent2Svg } from 'assets/svg'
import clsx from 'clsx'
import { getEventUrl } from 'utils'
import { followUser, registerParticipant } from 'api'
import { useHistory } from 'react-router'
import { AUTH_MODAL_STATE } from 'store/constants'
import { followUserStore } from 'store/actions'
import { requireAuth } from 'store/utils/awaitStore'
import { useDispatch } from 'react-redux'

const EventButtonFreePurchaseableLive = ({
  className,
  event,
  refreshEvent,
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const host = event.get('host')

  const _onClick = async (e) => {
    e.stopPropagation()
    e.preventDefault()
    if (event.get('userType') === 'follower') {
      await registerParticipant({ eventId: event.get('id') })
      redirectToEvent()
    } else {
      await signupAndFollow()
    }
  }

  const signupAndFollow = async () => {
    if (await requireAuth(AUTH_MODAL_STATE.SIGNUP, true)) {
      await followUser({ userId: host.get('id') })
      dispatch(followUserStore(host))
      await registerParticipant({ eventId: event.get('id') })
      redirectToEvent()
    }
  }

  const redirectToEvent = () => {
    if (getEventUrl(event) === window.location.pathname) {
      refreshEvent && refreshEvent()
    } else {
      history.replace(getEventUrl(event))
    }
  }

  return (
    <button className={clsx('button', className)} onClick={_onClick}>
      <CalendarEvent2Svg className="attend-button-icon" /> Attend
    </button>
  )
}

export default EventButtonFreePurchaseableLive
