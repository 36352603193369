import React, { useCallback, useState } from 'react'
import LocalHeader from 'components/LocalHeader'
import './PostAttachmentView.scss'
import PostAttachmentViewPhoto from 'components/PostAttachmentView/PostAttachmentViewPhoto'
import CheckoutModal from 'modals/CheckoutModal'
import PostCardAttachmentViewSpecific from 'components/PostCard/PostCardAttachmentViewSpecific'
import { useSelector } from 'react-redux'
import { POST_ATTACHMENT_TYPES } from 'store/constants/post'
import PostAttachmentViewPoll from 'components/PostAttachmentView/PostAttachmentViewPoll'
import { setPostAttachmentAttributes } from 'components/ProfileFeed'

const PostAttachmentView = ({
  post,
  hostId,
  dispatchPosts,
  withHeader,
  onClose,
  onPostAttachmentUpdate: parentOnPostAttachmentUpdate,
}) => {
  const postId = post.get('id')
  const browser = useSelector((state) => state.get('browser'))
  const [checkoutModalOpen, setCheckoutModalOpen] = useState(false)
  const attachmentAccess = post.get('attachmentAccess')
  const attachmentType = post.get('attachmentType')
  const attachment = post.get('attachment')
  const isMobile = browser.lessThan.lg

  const onNoAccess = useCallback(() => {
    setCheckoutModalOpen(true)
  }, [])
  const onCheckoutModalClose = useCallback(() => {
    setCheckoutModalOpen(false)
  }, [setCheckoutModalOpen])

  const onPostAttachmentUpdate = useCallback(
    (attachment) => {
      dispatchPosts(
        setPostAttachmentAttributes({
          postId,
          attachment,
        })
      )

      if (parentOnPostAttachmentUpdate) {
        parentOnPostAttachmentUpdate(attachment)
      }
    },
    [parentOnPostAttachmentUpdate, postId, dispatchPosts]
  )

  const renderAttachment = () => {
    switch (attachmentType) {
      case POST_ATTACHMENT_TYPES.PHOTO:
        return (
          <PostAttachmentViewPhoto
            photo={attachment}
            access={attachmentAccess}
            onNoAccess={onNoAccess}
          />
        )
      case POST_ATTACHMENT_TYPES.POLL:
        return (
          <PostAttachmentViewPoll
            poll={attachment}
            access={attachmentAccess}
            onNoAccess={onNoAccess}
            onPostAttachmentUpdate={onPostAttachmentUpdate}
          />
        )
      default:
        return null
    }
  }

  return (
    <div className="PostAttachmentView">
      <CheckoutModal
        active={checkoutModalOpen}
        hostId={hostId}
        title="Unlock to see this photo"
        onClose={onCheckoutModalClose}
        productIds={post.get('productIds')}
      />

      {isMobile && !withHeader && <LocalHeader close onClose={onClose} />}
      <div className="post-attachment-view-wrapper">
        <div className="post-attachment-view-attachment">
          {renderAttachment()}
        </div>
        <div className="post-attachment-view-post">
          {!isMobile && !withHeader && (
            <LocalHeader title={post.getIn(['host', 'name'])} />
          )}
          <PostCardAttachmentViewSpecific
            post={post}
            hostId={hostId}
            dispatchPosts={dispatchPosts}
          />
        </div>
      </div>
    </div>
  )
}

export default PostAttachmentView
