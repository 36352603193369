import React, { useCallback } from 'react'
import './SubscribePagePlanSelector.scss'
import { useHistory } from 'react-router-dom'
import { capitalize, getSubscribePlanUrl, useGetInviteCode } from 'utils'
import { RadioCheckedSvg, RadioUncheckedSvg } from 'assets/svg'
import clsx from 'clsx'
import SubscribePagePlanSelectorItem from 'routes/SubscribePage/SubscribePagePlanSelectorItem'

const SubscribePagePlanSelector = ({ checkout, redirectUrl }) => {
  const user = checkout.get('host')
  const { inviteCode } = useGetInviteCode(user.get('username'))
  const history = useHistory()
  const isMonthlyPayment =
    checkout.getIn(['plan', 'lockInMonths']) > 0 ||
    checkout.getIn(['plan', 'base'])

  const noPayment = checkout.get('noPayment')
  // Helpers
  const changePlan = useCallback(
    (planId) => {
      history.push(getSubscribePlanUrl(user, planId, redirectUrl))
    },
    [user, history, redirectUrl]
  )

  const changeCycle = useCallback(
    (newCycleIsMonthly, newPlan) => () => {
      if (newCycleIsMonthly === isMonthlyPayment || newPlan == null) {
        return null
      }

      changePlan(newPlan.get('id'))
    },
    [changePlan, isMonthlyPayment]
  )

  // Rendering
  const renderCycleSelector = () => {
    const isBase = checkout.get('base')
    const hasAlternativePlans = checkout.get('alternativePrices').size > 1
    const displayName = checkout.getIn(['plan', 'billingName'])
    const LockInRadio = isMonthlyPayment ? RadioCheckedSvg : RadioUncheckedSvg
    const UpfrontRadio = !isMonthlyPayment ? RadioCheckedSvg : RadioUncheckedSvg

    if (!hasAlternativePlans) {
      return null
    }

    const alternativeCyclePlan = checkout
      .get('alternativePrices')
      .find(
        (p) =>
          (isMonthlyPayment
            ? p.get('lockInMonths') === 0
            : p.get('lockInMonths') > 0) &&
          p.get('billingName') === checkout.getIn(['plan', 'billingName']) &&
          p.get('id') !== checkout.getIn(['plan', 'id'])
      )
    const hasAlternativeCyclePlan = alternativeCyclePlan != null

    if (!hasAlternativeCyclePlan) {
      return null
    }

    return (
      <div
        className={clsx('subscribe-page-plan-selector-cycle-selector', {
          disabled: isBase,
        })}
      >
        <div
          className={clsx(
            'subscribe-page-plan-selector-cycle-selector-option',
            {
              disabled: !hasAlternativeCyclePlan && !isMonthlyPayment,
              active: isMonthlyPayment,
            }
          )}
          onClick={changeCycle(true, alternativeCyclePlan)}
        >
          <LockInRadio />
          <span>Monthly payment</span>
        </div>

        <div
          className={clsx(
            'subscribe-page-plan-selector-cycle-selector-option',
            {
              disabled: !hasAlternativeCyclePlan && isMonthlyPayment,
              active: !isMonthlyPayment,
            }
          )}
          onClick={changeCycle(false, alternativeCyclePlan)}
        >
          <UpfrontRadio />
          <span>
            {displayName === 'monthly'
              ? 'Upfront'
              : capitalize(displayName) + ' payment'}
          </span>
        </div>
      </div>
    )
  }

  const renderPlans = () => {
    return (
      checkout
        .get('alternativePrices')
        .sortBy((p) => p.get('discount'))
        .sortBy((p) => !p.get('base'))
        /*.sort((x, y) => {
        if (x.get('base') || y.get('base')) {
          return y.get('base') - x.get('base')
        } else {
          return x.get('amount') - y.get('amount')
        }
      })*/
        .filter(
          (p) =>
            p.get('base') ||
            (isMonthlyPayment
              ? p.get('lockInMonths') > 0
              : p.get('lockInMonths') === 0)
        )
        .filter((p) => (noPayment ? p.get('base') : true))
        .map((plan) => (
          <SubscribePagePlanSelectorItem
            key={plan.get('id')}
            active={plan.get('id') === checkout.getIn(['plan', 'id'])}
            onClick={() => changePlan(plan.get('id'))}
            plan={plan}
            inviteCode={plan.get('base') && inviteCode}
          />
        ))
    )
  }

  return (
    <div className="SubscribePagePlanSelector">
      {renderPlans()}
      {renderCycleSelector()}
    </div>
  )
}

export default SubscribePagePlanSelector
