import React, { Component } from 'react'
import Modal from 'components/Modal'
import CloseSvg from 'assets/svg/CloseSvg'
import './SimpleNamedModal.scss'
import { CSSTransition } from 'react-transition-group'
import clsx from 'clsx'

class SimpleNamedModal extends Component {
  get onClose() {
    return this.props.onClose
  }

  get isActive() {
    return this.props.active
  }

  render() {
    return (
      <CSSTransition
        in={this.isActive}
        timeout={250}
        appear={true}
        mountOnEnter
        unmountOnExit
        classNames="SimpleNamedModal"
      >
        <Modal
          onClose={this.onClose}
          overlay
          className={clsx('SimpleNamedModal', this.props.className)}
        >
          <div className="simple-named-modal-header">
            <div className="simple-named-modal-header-title">
              {this.props.title}
            </div>
            <CloseSvg onClick={this.onClose} />
          </div>

          <div className="simple-named-modal-content">
            {this.props.children}
          </div>
        </Modal>
      </CSSTransition>
    )
  }
}

export default SimpleNamedModal
