import React, { useCallback, useState } from 'react'
import './PostCompose.scss'
import { createPollApi, createUserPostApi } from 'api'
import clsx from 'clsx'
import { getApiError } from 'utils'
import { createNewPost } from 'components/ProfileFeed'
import { Formik } from 'formik'
import PostComposeForm from './PostComposeForm'
import PostComposeInner from 'components/PostCompose/PostComposeInner'
import { POST_ATTACHMENT_TYPES } from 'store/constants/post'
import { DateTime } from 'luxon'
import * as Yup from 'yup'
import { AddCircleSvg } from 'assets/svg'
import { HOST_ACCESS } from 'store/constants'
import CheckoutModal from 'modals/CheckoutModal'

const PostComposeValidation = Yup.object().shape({
  message: Yup.string().when('attachmentType', {
    is: (t) => t == null,
    then: Yup.string().required('A message is required'),
  }),
})

const PostCompose = ({
  user,
  dispatchPosts,
  onPostCreated,
  categoryId,
  defaultProductId,
  showInner,
}) => {
  const hostId = user.get('id')
  const [modalActive, setModalActive] = useState(false)
  const hasAccess = user.get('hostAccess') !== HOST_ACCESS.NONE
  const [showCheckout, setShowCheckout] = useState(false)
  const onNoAccess = useCallback(() => {
    setShowCheckout(true)
  }, [])

  const onSubmit = async (values, { setSubmitting, setErrors, resetForm }) => {
    if (values.attachmentType === POST_ATTACHMENT_TYPES.POLL) {
      try {
        const poll = await createPollApi({
          data: {
            hostId,
            message: values.pollMessage,
            pollOptionsAttributes: values.pollOptions.map((message, index) => ({
              order: index,
              message,
            })),
            canAddMoreOptions: values.pollCanAddMoreOptions,
            multipleVotesPerUser: values.pollMultipleVotesPerUser,
            endTime: values.pollEndTime,
            theme: values.pollTheme,
          },
        })

        values.attachmentId = poll.get('id')
      } catch (e) {
        setErrors(getApiError(e))
        setSubmitting(false)
        return
      }
    }

    let newPost
    try {
      newPost = await createUserPostApi({
        userId: user.get('id'),
        data: {
          ...values,
          categoryIds: [categoryId],
        },
      })
    } catch (e) {
      setErrors(getApiError(e))
      setSubmitting(false)
      return
    }

    dispatchPosts(
      createNewPost({
        postId: newPost.get('id'),
        post: newPost,
      })
    )

    if (onPostCreated) {
      onPostCreated()
    }
    setSubmitting(false)
    setModalActive(false)
    resetForm()
  }

  const initialValues = {
    message: '',
    productIds:
      defaultProductId != null
        ? [defaultProductId]
        : user
            .get('products')
            .map((p) => p.get('id'))
            .toArray(),
    pollMessage: '',
    pollOptions: ['', ''],
    pollCanAddMoreOptions: true,
    pollMultipleVotesPerUser: false,
    pollEndTime: DateTime.local().plus({ days: 7 }).toJSDate(),
    public: false,
    notifySubscribers: true,
  }

  const checkIfAuth = () => {
    if (hasAccess) {
      setModalActive(true)
    } else {
      onNoAccess()
    }
  }

  const renderButton = () => {
    return (
      <button
        onClick={checkIfAuth}
        className="button creator-primary profile-feed-post-button"
      >
        <AddCircleSvg /> Post
      </button>
    )
  }

  return (
    <div className={clsx('PostCompose')}>
      <CheckoutModal
        active={showCheckout}
        title="Unlock to Post"
        onClose={() => setShowCheckout(false)}
        hostId={user.get('id')}
      />
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={PostComposeValidation}
      >
        <PostComposeForm
          user={user}
          modalActive={modalActive}
          setModalActive={setModalActive}
        >
          {showInner ? (
            <PostComposeInner user={user} setModalActive={setModalActive} />
          ) : (
            renderButton()
          )}
        </PostComposeForm>
      </Formik>
    </div>
  )
}

export default PostCompose
