import React from 'react'
import './ToastComponent.scss'
import clsx from 'clsx'
import { CheckSvg } from 'assets/svg'
import CloseSvg from 'assets/svg/CloseSvg'

const ToastComponent = ({ appearance, children, transitionState }) => {
  const renderIcon = () => {
    switch (appearance) {
      case 'error':
        return <CloseSvg />
      case 'success':
      default:
        return <CheckSvg />
    }
  }

  return (
    <div className={clsx('ToastComponent', appearance, transitionState)}>
      <div className="toast-component-container">
        <div className="toast-component-icon">{renderIcon()}</div>
        <div className="toast-component-content">{children}</div>
      </div>
    </div>
  )
}

export default ToastComponent
