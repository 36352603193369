import React from 'react'
import './CreatorProfileIncompleteBanner.scss'
import { Link } from 'react-router-dom'
import { URL_CREATOR_SETUP_PROFILE } from 'urls'
import { useSelector } from 'react-redux'
import { selectIsMeDraftCreator } from 'store/selectors'

const CreatorProfileIncompleteBanner = () => {
  const isMeDraftCreator = useSelector((state) => selectIsMeDraftCreator(state))
  if (!isMeDraftCreator) {
    return null
  }

  return (
    <div className="CreatorProfileIncompleteBanner">
      <div className="creator-profile-incomplete-banner-container">
        <div className="creator-profile-incomplete-banner-left">
          You profile page is private and can’t be seen until launched
        </div>
        <div className="creator-profile-incomplete-banner-right">
          <Link
            to={URL_CREATOR_SETUP_PROFILE}
            className="button primary small light"
          >
            Finalise profile
          </Link>
        </div>
      </div>
    </div>
  )
}

export default CreatorProfileIncompleteBanner
