import React, { useCallback, useState } from 'react'
import './EventCard.scss'
import EventStatus from 'components/EventStatus'
import {
  asyncModal,
  getEventUrl,
  getEventEditUrl,
  getEventOverviewUrl,
  getMuxGif,
  getMuxThumbnail,
  useMe,
  useCopyToClipboard,
} from 'utils'
import { Link } from 'react-router-dom'
import { BackgroundImage } from 'components/Image'
import clsx from 'clsx'
import Giffer, { useGiffer } from './Giffer'
import { DateTime } from 'luxon'
import PlayButton from 'components/PlayButton'
import { archiveEvent, deleteEvent, unArchiveEvent } from 'api'
import EventPageModal from 'modals/EventPageModal'
import OptionsMenu from 'components/OptionsMenu'
import CheckoutModal from 'modals/CheckoutModal'
import { useToasts } from 'react-toast-notifications'
import LiveGuideModal from 'modals/LiveGuideModal'
import EventButton from 'components/EventButton/EventButton'
import Bookmark from 'components/Bookmark'

const EventCard = ({ event, progress, refreshEvent }) => {
  const [showEventModal, setShowEventModal] = useState(false)
  const [showCheckoutModal, setShowCheckoutModal] = useState(false)
  const onCloseEventModal = useCallback(() => {
    setShowEventModal(false)
  }, [setShowEventModal])
  const onCloseCheckoutModal = useCallback(() => {
    setShowCheckoutModal(false)
  }, [setShowCheckoutModal])

  const progressInPercent = Math.min(
    ((progress || 0.0) / (60.0 * event.get('duration'))) * 100.0,
    100.0
  )
  const me = useMe()
  const isHost = me.get('id') === event.getIn(['host', 'id'])

  const trailerGif = getMuxGif(
    event.get('trailerVideoUrl') || event.get('coverVideoUrl')
  )

  let { onMouseEnter, onMouseLeave, playing } = useGiffer({ src: trailerGif })
  if (event.get('archived')) {
    playing = false
  }

  const handleClick = () => setShowEventModal(true)

  const hasVideo =
    event.get('coverVideoUrl') != null || event.get('trailerVideoUrl')

  const trailerImage =
    event.get('coverImageUrl') ||
    getMuxThumbnail(event.get('trailerVideoUrl')) ||
    getMuxThumbnail(event.get('coverVideoUrl'))

  // Data
  const dateTime = DateTime.fromISO(
    event.get('startTime') || event.get('publishedAt') || event.get('createdAt')
  )

  // Utils
  const getFormattedDate = () => {
    const diffDays = dateTime.diffNow('days').days

    if (diffDays > -7) {
      return dateTime.toRelative({ style: 'narrow' })
    } else {
      if (dateTime.year !== new Date().getFullYear()) {
        return dateTime.toFormat('MMM d, y')
      } else {
        return dateTime.toFormat('MMM d')
      }
    }
  }

  const renderLiveDateTime = () => {
    if (dateTime.diffNow('seconds').seconds > 0) {
      return dateTime.toFormat('MMM d, HH:mm')
    } else {
      return getFormattedDate()
    }
  }

  const renderEventDetails = () => {
    return (
      <div className={clsx('event-card-details')}>
        <div className="event-card-details-left">
          <div className="event-card-details-title">{event.get('title')}</div>
          <div className="event-card-details-stats">
            <div className="event-card-details-date">
              {event.get('live')
                ? `${
                    dateTime < DateTime.local() ? `Was live` : `Live`
                  } ${renderLiveDateTime()}`
                : `Uploaded ${getFormattedDate()}`}
            </div>
          </div>
        </div>
        <div className="event-card-details-right">
          <EventButton event={event} className="secondary small" />
        </div>
      </div>
    )
  }

  // Render sections
  const renderMedia = () => {
    const hasCounter = dateTime.diffNow('hours').hours < 24

    return (
      <BackgroundImage className="event-card-media" src={trailerImage}>
        <div
          className={clsx(
            'event-card-media-bookmark-container',
            isHost && 'bookmark-creator'
          )}
        >
          <Bookmark
            active={event.get('bookmarked')}
            eventId={event.get('id')}
            isHost={isHost}
          />
        </div>

        <div
          className={clsx(
            'event-card-media-inner',
            hasCounter
              ? 'event-card-media-inner-extra-dimmed'
              : 'event-card-media-inner-dimmed',
            event.get('archived')
              ? 'event-card-media-inner-extra-extra-dimmed'
              : ''
          )}
        >
          {event.get('archived') ? (
            <div className="event-card-archived">Video no longer available</div>
          ) : (
            <>
              <EventStatus event={event} />
              {trailerGif && <Giffer src={trailerGif} playing={playing} />}
              {hasVideo && (
                <PlayButton className="event-card-media-play-button" />
              )}
            </>
          )}
          {isHost && (
            <EventHostOptions event={event} refreshEvent={refreshEvent} />
          )}
          <progress max="100" value={progressInPercent}></progress>
        </div>
      </BackgroundImage>
    )
  }

  return (
    <div
      className={clsx('EventCard', { playing })}
      onClick={handleClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {showEventModal && (
        <EventPageModal
          onClose={onCloseEventModal}
          userSlug={
            event.getIn(['host', 'username']) || event.getIn(['host', 'id'])
          }
          eventSlug={event.get('slug') || event.get('id')}
        />
      )}
      <CheckoutModal
        active={showCheckoutModal}
        title={`Unlock ${event.get('title')}`}
        event={event}
        hostId={event.getIn(['host', 'id'])}
        productIds={event.get('productIds')}
        onClose={onCloseCheckoutModal}
      />
      <div className="event-card-wrapper">
        <div className="event-card-media-wrapper">{renderMedia()}</div>
        <div className="event-card-content">
          <div className="event-card-content-wrapper">
            {renderEventDetails()}
          </div>
        </div>
      </div>
    </div>
  )
}

export default EventCard

export const EventHostOptions = ({ event, refreshEvent }) => {
  const [open, setOpen] = useState(false)
  const { addToast } = useToasts()
  const copyToClipboard = useCopyToClipboard()
  const resourceType = () => (event.get('live') ? 'event' : 'video')

  const onDelete = async () => {
    if (
      window.confirm(`Are you sure you want to delete "${event.get('title')}"?`)
    ) {
      setOpen(false)

      try {
        await deleteEvent({ id: event.get('id') })
      } catch (e) {
        addToast(
          'We were unable to delete this event, please try again later',
          { appearance: 'error' }
        )
        return null
      }

      if (refreshEvent) {
        refreshEvent()
      }

      addToast(`"${event.get('title')}" was successfully deleted!`, {
        appearance: 'success',
      })
    }
  }
  const onUnlist = async (archived) => {
    var aboutTo = archived ? 'list' : 'unlist'
    if (
      window.confirm(`You are about to ${aboutTo} this event. Are you sure?`)
    ) {
      setOpen(false)
      try {
        archived
          ? await unArchiveEvent({ id: event.get('id') })
          : await archiveEvent({ id: event.get('id') })
      } catch (e) {
        addToast(
          `We were unable to ${aboutTo} this event, please try again later`,
          { appearance: 'error' }
        )
        return null
      }

      if (refreshEvent) {
        refreshEvent()
      }

      addToast(`"${event.get('title')}" was successfully ${aboutTo}ed!`, {
        appearance: 'success',
      })
    }
  }

  const handleLiveGuide = () => {
    asyncModal(({ active, onSubmit }) => (
      <LiveGuideModal
        active={active}
        onClose={onSubmit}
        eventId={event.get('id')}
      />
    ))
    setOpen(false)
  }

  return (
    <OptionsMenu
      className="EventHostOptions"
      open={open}
      setOpen={setOpen}
      dark
    >
      <Link to={getEventOverviewUrl(event)}>Overview</Link>
      <div className="link" onClick={() => copyToClipboard(getEventUrl(event))}>
        Copy link
      </div>
      {event.get('recordedRendition') && (
        <a
          href={`${event.get('recordedRendition')}?download=${
            event.get('slug') || event.get('id')
          }.mp4`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Download
        </a>
      )}
      <Link to={getEventEditUrl(event)}>Edit {resourceType()}</Link>
      <div className="link" onClick={onDelete}>
        Delete {resourceType()}
      </div>
      <div className="link" onClick={() => onUnlist(event.get('archived'))}>
        {event.get('archived') ? 'Make public' : `Unlist ${resourceType()}`}
      </div>
      {event.get('live') && (
        <div className="link" onClick={handleLiveGuide}>
          How to go live
        </div>
      )}
    </OptionsMenu>
  )
}
