import React, { useLayoutEffect, useRef } from 'react'
import clsx from 'clsx'
import './Chat.scss'
import ChatCompose from './ChatCompose'
import useStayScrolled from 'react-stay-scrolled'

const Chat = ({
  messages,
  connecting,
  loadingPreviousMessages,
  onMessageSend,
  className,
  onFocus,
  children,
  hideCompose,
  hostId,
}) => {
  const listRef = useRef()
  const { stayScrolled } = useStayScrolled(listRef)

  useLayoutEffect(() => {
    stayScrolled()
  }, [stayScrolled, messages])

  return (
    <div className={clsx('Chat', className)}>
      <div className="chat-wrapper">
        <div className="chat-conversation">
          <div className="chat-conversation-scroll" ref={listRef}>
            {children}
            {messages}
          </div>
        </div>
        <div className="chat-bottom">
          {connecting && <div className="chat-status">Connecting...</div>}
          {loadingPreviousMessages && (
            <div className="chat-status">Loading previous messages...</div>
          )}

          {!hideCompose && (
            <ChatCompose
              hostId={hostId}
              onFocus={onFocus}
              onMessageSend={onMessageSend}
              connecting={connecting}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default Chat
