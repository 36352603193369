import React, { useCallback, useRef, useState } from 'react'
import './PostCard.scss'
import PostCardReason from './PostCardReason'
import PostCardHeader from './PostCardHeader'
import PostCardMessage from './PostCardMessage'
import PostCardAttachment from './PostCardAttachment'
import PostCardEngagement from './PostCardEngagement'
import PostCardActions from './PostCardActions'
import PostComments from 'components/PostComments'
import { getPostCommentsApi } from 'api'
import { setPostAttributes } from 'components/ProfileFeed/postUtils'
import { getApiError, getPostUrl } from 'utils'
import CheckoutModal from 'modals/CheckoutModal'
import {
  POST_ATTACHMENT_TYPES,
  POST_COMMENT_ACCESS,
} from 'store/constants/post'
import FollowerModal from 'modals/FollowerModal'
import { useHistory } from 'react-router'
import { useToasts } from 'react-toast-notifications'

const PostCard = ({
  post,
  hostId,
  dispatchPosts,
  onPostDeleted,
  onPostUpdated,
  unpackReplyId,
  defaultShowCommentCompose = false,
  noReason = false,
}) => {
  const { addToast } = useToasts()
  const history = useHistory()
  const commentInputRef = useRef()
  const postId = post.get('id')
  const event =
    post.get('attachmentType') === POST_ATTACHMENT_TYPES.EVENT
      ? post.get('attachment')
      : null

  const [showCheckout, setShowCheckout] = useState(false)
  const [checkoutTitle, setCheckoutTitle] = useState(null)
  const [showFollowerModal, setFollowOpen] = useState(false)
  const onNoAccess = useCallback(
    (title) => {
      if (event !== null && event.get('isFree') === true) {
        setFollowOpen(true)
      } else {
        setShowCheckout(true)
        setCheckoutTitle(title)
      }
    },
    [setShowCheckout, setFollowOpen, event]
  )

  const [showCommentCompose, setShowCommentCompose] = useState(
    defaultShowCommentCompose
  )
  const [loadingMoreComments, setLoadingMoreComments] = useState(false)
  const [moreCommentsError, setMoreCommentsError] = useState(null)
  const commentsAccess = post.get('commentsAccess')
  const loadMoreComments = useCallback(async () => {
    if (commentsAccess === POST_COMMENT_ACCESS.NONE) {
      onNoAccess('Unlock to see all comments')
      return null
    }

    setLoadingMoreComments(true)
    setMoreCommentsError(null)

    let comments
    try {
      comments = await getPostCommentsApi({ postId })
    } catch (e) {
      setMoreCommentsError(getApiError(e))
      setLoadingMoreComments(false)
      return null
    }

    setLoadingMoreComments(false)
    dispatchPosts(
      setPostAttributes({
        postId,
        post: {
          comments,
        },
      })
    )
  }, [onNoAccess, commentsAccess, dispatchPosts, postId])

  const onCheckoutClose = useCallback(() => {
    setShowCheckout(false)
  }, [setShowCheckout])

  const redirectOnFollow = useCallback(() => {
    addToast(`You are now following ${post.getIn(['host', 'name'])}`, {
      appearance: 'success',
    })
    history.replace(getPostUrl(post))
  }, [addToast, history, post])

  return (
    <div className="PostCard">
      <CheckoutModal
        active={showCheckout}
        title={checkoutTitle}
        event={event}
        hostId={hostId}
        productIds={post.get('productIds')}
        onClose={onCheckoutClose}
      />
      <FollowerModal
        active={showFollowerModal}
        event={event}
        host={event !== null && event.get('host')}
        onClose={() => setFollowOpen(false)}
        redirectOnFollow={redirectOnFollow}
      />
      {!noReason && <PostCardReason post={post} />}
      <PostCardHeader
        post={post}
        onNoAccess={onNoAccess}
        onPostDeleted={onPostDeleted}
        onPostUpdated={onPostUpdated}
        showOptions={post.get('attachmentType') !== POST_ATTACHMENT_TYPES.EVENT}
        hostId={hostId}
      />
      <PostCardMessage post={post} onNoAccess={onNoAccess} />
      <PostCardAttachment
        post={post}
        onNoAccess={onNoAccess}
        dispatchPosts={dispatchPosts}
      />
      <PostCardEngagement post={post} />
      <PostCardActions
        post={post}
        dispatchPosts={dispatchPosts}
        loadMoreComments={loadMoreComments}
        commentInputRef={commentInputRef}
        onNoAccess={onNoAccess}
        setShowCommentCompose={setShowCommentCompose}
        loadingMoreComments={loadingMoreComments}
      />
      <PostComments
        post={post}
        dispatchPosts={dispatchPosts}
        loadMoreComments={loadMoreComments}
        commentInputRef={commentInputRef}
        loadingMoreComments={loadingMoreComments}
        moreCommentsError={moreCommentsError}
        onNoAccess={onNoAccess}
        showCompose={showCommentCompose}
        unpackReplyId={unpackReplyId}
      />
    </div>
  )
}

export default React.memo(PostCard)
