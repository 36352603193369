import React, { useEffect } from 'react'

import { Image } from 'components/Image'
import { LazyMotion, domAnimation, m, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import { useBrowser } from 'utils'

const AnimatedComponent = ({
  tag,
  children,
  className,
  animateY = 40,
  duration = 0.5,
  delay = 0,
  mobileDelay = null,
  ease = 'easeInOut',
  customAnimation = { hidden: {}, visible: {} },
  ...props
}) => {
  const browser = useBrowser()
  const controls = useAnimation()
  const { ref, inView } = useInView()

  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])

  const variants = {
    hidden: {
      y: animateY,
      opacity: 0.0,
      ...customAnimation.hidden,
    },
    visible: {
      // Absolute positioned elements stagger when given `transform: none`
      // Setting `y` to a small value instead sets it to `transform: translateY(1e-10px)`
      y: 1e-10,
      opacity: 1.0,
      transition: {
        ease,
        duration,
        delay:
          browser.lessThan.md && mobileDelay !== null ? mobileDelay : delay,
        type: 'tween',
      },
      ...customAnimation.visible,
    },
  }

  const renderInner = () => {
    if (tag === 'img') {
      return (
        <m.div
          ref={ref}
          layout
          initial="hidden"
          animate={controls}
          variants={variants}
        >
          <Image className={className} {...props} />
        </m.div>
      )
    }

    const Tag = {
      h1: m.h1,
      h2: m.h2,
      div: m.div,
    }[tag]

    return (
      <Tag
        className={className}
        ref={ref}
        layout
        initial="hidden"
        animate={controls}
        variants={variants}
        {...props}
      >
        {children}
      </Tag>
    )
  }

  return (
    <LazyMotion features={domAnimation} strict>
      {renderInner()}
    </LazyMotion>
  )
}

export default AnimatedComponent
