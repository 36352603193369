import React, { Component } from 'react'
// eslint-disable-next-line import/no-webpack-loader-syntax
import videojs from '!video.js'
import clsx from 'clsx'
import './VideoPlayer.scss'
import chromecast from '@silvermine/videojs-chromecast'
//import EventLogger from './legacy/eventlogger'
// eslint-disable-next-line import/no-webpack-loader-syntax
//import '!videojs-mux'

chromecast(videojs)

export default class VideoPlayer extends Component {
  constructor(props) {
    super(props)
    this.videoPlayer = React.createRef()
    this.unmounted = false
  }

  componentDidMount() {
    videojs.log.level('off')
    //videojs.log.level('all')
    //videojs.registerPlugin('eventLogger', EventLogger)
    console.log('Setting up player')
    if (this.videoPlayer.current == null) {
      console.log('Cancelling player setup')
      return null
    }
    console.log('Proceeding with player setup!', this.unmounted)

    this.player = videojs(this.videoPlayer.current, this.props, () => {
      console.log('Setting up retryplaylist')
      this.player.on(this.player.tech_, ['retryplaylist'], () => {
        if (this.props.onPlaybackFailed) {
          this.props.onPlaybackFailed()
        }
      })
      console.log('Setting up waiting')
      this.player.on(this.player, ['waiting'], () => {
        if (this.props.onWaiting) {
          this.props.onWaiting()
        }
      })
      console.log('Setup done')
    })
    console.log('After player setup')

    //this.player.eventLogger()

    if (this.props.onPlay) {
      this.player.on('play', (e) => this.props.onPlay(e, this.player))
    }

    if (this.props.onLoaded) {
      this.player.on('loadeddata', this.props.onLoaded)
    }

    if (this.props.onError) {
      this.player.on('error', this.props.onError)
    }

    if (this.props.onTimeUpdate) {
      this.player.on('timeupdate', () => this.props.onTimeUpdate(this.player))
    }

    if (this.props.onEnded) {
      this.player.on('ended', this.props.onEnded)
    }

    console.log('After hooks setup')
  }

  // destroy player on unmount
  componentWillUnmount() {
    this.unmounted = true
    console.log('Unmounting player')
    if (this.player) {
      console.log('Player was present')
      if (!this.player.paused()) {
        this.player.pause()
      }
      this.player.dispose()
    }
    console.log('After player unmount')
  }

  // wrap the player in a div with a `data-vjs-player` attribute
  // so videojs won't create additional wrapper in the DOM
  // see https://github.com/videojs/video.js/pull/3856
  render() {
    return (
      <div className={clsx('videojs-wrapper', this.props.className)}>
        <div data-vjs-player className="videojs-player">
          <video
            ref={this.videoPlayer}
            className="video-js vjs-default-skin vjs-big-play-centered"
          ></video>
          {this.props.components}
        </div>
      </div>
    )
  }
}
