import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { openAuthModal } from 'store/actions'
import { AUTH_MODAL_STATE } from 'store/constants'
import { URL_FRONT_PAGE } from 'urls'

const ResetPasswordPage = ({ history }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(openAuthModal({ state: AUTH_MODAL_STATE.FORGOT_PASSWORD }))
    history.replace(URL_FRONT_PAGE)
  })

  return null
}

export default withRouter(ResetPasswordPage)
