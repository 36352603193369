import React from 'react'
import './UserAbout.scss'
import { Col, Row } from 'components/Grid'
import ProductCard from 'components/ProductCard'
import clsx from 'clsx'
import UserAboutPage from 'routes/UserPage/components/UserAboutPage'

const UserAbout = ({ user, refreshUser }) => {
  const renderMemberships = () => {
    if (!user.get('hasMembership')) {
      return null
    }
    const count = user.get('products').size
    const size = count === 1 ? 8 : 6

    const cards = user.get('products').map((product) => (
      <Col sm={size} lg={size} key={product.get('id')}>
        <ProductCard
          product={product}
          productsAmount={count}
          key={product.get('id')}
          user={user}
          refresh={refreshUser}
        />
      </Col>
    ))

    return (
      <div className="user-about-memberships">
        <div className="user-about-memberships-title">Memberships</div>
        <Row
          className={clsx('slim-gutters user-about-memberships-items', {
            'justify-content-center': count === 1,
          })}
        >
          {cards}
        </Row>
      </div>
    )
  }

  return (
    <div className="UserAbout">
      <div className="user-page-sub-container user-about-container">
        <div className="user-page-sub-content user-about-content">
          <UserAboutPage user={user} />
          {renderMemberships()}
        </div>
      </div>
    </div>
  )
}

export default UserAbout
