import React, { useCallback, useState } from 'react'
import './UploadVideoField.scss'
import { useDropzone } from 'react-dropzone'
import clsx from 'clsx'
import { getVideoUploadUrl } from 'api'
import ReactPlayer from 'react-player'
import { useRef } from 'react'
import { CloudUploadSvg, CheckSvg } from 'assets/svg'
import { createUpload } from '@mux/upchunk'

const UploadVideoField = ({
  dark,
  slim,
  videoId,
  videoUrl,
  onSetUrl,
  onSetId,
}) => {
  const [isUploading, setUploading] = useState(false)
  const [error, setError] = useState(null)
  const [uploadingProgress, setUploadingProgress] = useState(0)
  const [videoDuration, setVideoDuration] = useState(null)
  const playerRef = useRef()

  const onDrop = useCallback(
    async (acceptedFiles) => {
      setUploading(true)
      setUploadingProgress(0)
      setError(null)
      const file = acceptedFiles[0]
      const oldUrl = videoUrl
      onSetUrl(URL.createObjectURL(file))

      const { uploadId, url } = await getVideoUploadUrl()

      const upload = createUpload({
        endpoint: url,
        file: file,
        chunkSize: 50 * 1024, // Uploads the file in ~50mb chunks
      })

      upload.on('progress', (progress) => {
        setUploadingProgress(Math.round(progress.detail))
      })

      upload.on('success', () => {
        onSetId(uploadId)
        setUploading(false)
      })

      upload.on('error', (e) => {
        setError({
          message:
            (e?.detail?.message ?? 'Error uploading') + ', please try again',
        })
        setUploading(false)
        onSetUrl(oldUrl)
      })
    },
    [onSetId, onSetUrl, videoUrl]
  )
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  const onLoadedData = () => {
    const player = playerRef.current.getInternalPlayer()
    if (player != null) {
      setVideoDuration(player.duration)
    }
  }

  const getFormattedDuration = (duration) => {
    duration = parseInt(duration)

    if (duration > 3600) {
      return `${Math.floor(duration / 60 / 60)}h ${
        Math.floor(duration / 60) % 60
      }m`
    } else if (duration > 60) {
      return `${Math.floor(duration / 60)}m ${duration % 60}s`
    } else {
      return `${duration}s`
    }
  }

  const renderVideo = () => {
    if (videoUrl && !isUploading) {
      if (videoUrl.indexOf('blob') === -1) {
        return (
          <ReactPlayer
            className="upload-video-field-video-player"
            url={videoUrl}
            width="100%"
            height="100%"
            ref={playerRef}
            config={{
              file: {
                attributes: {
                  onLoadedData: onLoadedData,
                },
              },
            }}
          />
        )
      } else {
        return <CheckSvg />
      }
    } else {
      return <CloudUploadSvg />
    }
  }

  const renderStatus = () => {
    if (isUploading) {
      return (
        <>
          <div className="upload-video-field-status-title">
            Uploading video... {uploadingProgress}%
          </div>
          <div className="upload-video-field-status-progress">
            <div
              className="upload-video-field-status-progress-bar"
              style={{ width: `${uploadingProgress}%` }}
            />
          </div>
        </>
      )
    }

    if (videoUrl) {
      return (
        <div className="upload-video-field-status-title">
          Video uploaded
          <span className="upload-video-field-status-title-value">
            {videoDuration != null && getFormattedDuration(videoDuration)}
          </span>
        </div>
      )
    } else {
      return (
        <>
          <button
            type="button"
            className={clsx('button secondary', { light: dark })}
          >
            Upload video
          </button>
          {error && <div className="input-error">{error?.message}</div>}
        </>
      )
    }
  }

  const renderRight = () => {
    if (!isUploading && videoUrl) {
      return (
        <>
          <button
            type="button"
            className={clsx('button secondary', { light: dark })}
          >
            Reupload
          </button>
          {error && <div className="input-error">{error?.message}</div>}
        </>
      )
    }

    return null
  }

  return (
    <>
      <div
        className={clsx('UploadVideoField', {
          slim,
          active: isDragActive,
          hasVideo: videoUrl != null,
          uploading: isUploading,
        })}
        {...getRootProps()}
      >
        <div className="upload-video-field-video">
          <div className="upload-video-field-video-sizer">
            <div className="upload-video-field-video-inner">
              {renderVideo()}
            </div>
          </div>
        </div>
        <div className="upload-video-field-status">{renderStatus()}</div>
        <div className="upload-video-field-right">{renderRight()}</div>
        <input
          {...getInputProps()}
          accept="video/*,video/mp4,video/x-m4v,video/x-matroska,.mkv"
          className="upload-video-field-input"
        />
      </div>
    </>
  )
}

export default UploadVideoField
