import React from 'react'
import './AboutPage.scss'
import { BackgroundImage } from 'components/Image'

import ImageAnna from 'assets/img/about-page/Anna@2x.jpg'
import ImageChristian from 'assets/img/about-page/Christian@2x.jpg'
import ImageDaniel from 'assets/img/about-page/Daniel@2x.jpg'
import ImageFreddy from 'assets/img/about-page/Freddy@2x.jpg'
import ImageFredslund from 'assets/img/about-page/Fredslund@2x.jpg'
import ImageHannah from 'assets/img/about-page/Hannah@2x.jpg'
import ImageJohan from 'assets/img/about-page/Johan@2x.jpg'
import ImagePeter from 'assets/img/about-page/Peter@2x.jpg'
import ImageRasmus from 'assets/img/about-page/Rasmus@2x.jpg'
import ImageSaskia from 'assets/img/about-page/Saskia@2x.jpg'

import { Col, ExtendedContainer, Row } from 'components/Grid'
import { useSelector } from 'react-redux'
import { URL_EXTERNAL_CAREERS } from 'urls'
import { CarouselProvider, Slide, Slider } from 'pure-react-carousel'

const AboutPage = () => {
  const browser = useSelector((state) => state.get('browser'))

  const employees = [
    <AboutEmployee name="Peter" title="Co-founder" imageUrl={ImagePeter} />,
    <AboutEmployee name="Frederik" title="Co-founder" imageUrl={ImageFreddy} />,
    <AboutEmployee
      name="Frederik"
      title="Engineering"
      imageUrl={ImageFredslund}
    />,
    <AboutEmployee name="Daniel" title="Design" imageUrl={ImageDaniel} />,
    <AboutEmployee name="Johan" title="Engineering" imageUrl={ImageJohan} />,
    <AboutEmployee
      name="Rasmus"
      title="Creator management"
      imageUrl={ImageRasmus}
    />,
    <AboutEmployee
      name="Christian"
      title="Creator management"
      imageUrl={ImageChristian}
    />,
    <AboutEmployee
      name="Anna"
      title="Creator management"
      imageUrl={ImageAnna}
    />,
    <AboutEmployee
      name="Hannah"
      title="Creator management"
      imageUrl={ImageHannah}
    />,
    <AboutEmployee
      name="Saskia"
      title="Creator management"
      imageUrl={ImageSaskia}
    />,
  ]

  const renderEmployees = () => {
    const header = (
      <div className="about-section-header">
        <div className="about-section-header-label">Team</div>
        <div className="about-section-header-title">
          Meet the friendly faces behind the Boon rocketship.
        </div>
      </div>
    )

    if (browser.greaterThan.lg) {
      return (
        <div className="about-employees about-employees-desktop">
          <Row>
            <Col lg={6}>{header}</Col>
            {employees.map((e, i) => (
              <Col lg={3} key={i}>
                {e}
              </Col>
            ))}
          </Row>
        </div>
      )
    } else {
      let carouselProps = {
        visibleSlides: 1.1,
        step: 1,
      }

      if (browser.greaterThan.sm) {
        carouselProps = {
          visibleSlides: 2.5,
          step: 2,
        }
      }

      return (
        <div className="about-employees about-employees-desktop">
          {header}
          <CarouselProvider
            className="about-carousel"
            isIntrinsicHeight
            totalSlides={employees.length}
            {...carouselProps}
          >
            <Slider classNameAnimation="about-carousel-transition">
              {employees.map((e, i) => (
                <Slide index={i} key={i}>
                  {e}
                </Slide>
              ))}
            </Slider>
          </CarouselProvider>
        </div>
      )
    }
  }

  return (
    <div className="AboutPage">
      <ExtendedContainer>
        <div className="about-mission about-section">
          <div className="about-section-header">
            <div className="about-section-header-label">Who we are</div>
            <div className="about-section-header-title">
              Boon is on a mission to empower creators to turn their passions
              into their livelihood.
            </div>
          </div>
          <div className="about-mission-text">
            On Boon, we’re creating a place where individuality and creativity
            is celebrated. Where unique knowledge and skills can be surfaced to
            people all over the world. Where creators are fueling a new model of
            human-powered commerce.
            <br />
            <br />
            Whether you’re a creator or a participant - we hope you’ll join us
            on our journey to shape the future of creative entrepreneurship.
          </div>
        </div>
        <div className="about-section">{renderEmployees()}</div>
        <div className="about-section">
          <div className="about-cta">
            <div className="about-cta-title">We’re hiring</div>
            <div className="about-cta-description">
              It’s the people that make Boon special. Are you our new coworker?
            </div>
            <a
              target="_blank"
              href={URL_EXTERNAL_CAREERS}
              rel="noopener noreferrer"
              className="button primary light about-cta-button"
            >
              Join us
            </a>
          </div>
        </div>
      </ExtendedContainer>
    </div>
  )
}

export default AboutPage

const AboutEmployee = ({ name, title, imageUrl }) => {
  return (
    <div className="AboutEmployee">
      <BackgroundImage src={imageUrl} className="about-employee-image" />
      <div className="about-employee-name">{name}</div>
      <div className="about-employee-title">{title}</div>
    </div>
  )
}
