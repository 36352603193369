import React, { useReducer, useEffect, useRef } from 'react'
import { fromJS, List, Map } from 'immutable'
import { postReducer, postReducerMerge } from 'components/ProfileFeed/postUtils'
import { PostCardEventSpecific } from 'components/PostCard'
import Spinner from 'components/Spinner'
import { useGetEventPostApi } from 'api'
import './EventPost.scss'
import { cableConnect } from 'store/utils/cable'
import { useSelector } from 'react-redux'
import { getMe } from 'store/selectors'

export const EventPost = React.memo(
  ({ event, user, refreshEvent, onNoAccess }) => {
    const socketConnectionRef = useRef(null)
    const me = useSelector((state) => getMe(state))
    const meId = me.get('id')
    const { data: postData } = useGetEventPostApi(
      {
        eventId: event.get('id'),
      },
      [meId]
    )
    const [posts, dispatchPosts] = useReducer(postReducer, Map())
    const postId = postData?.get('id')
    const post = posts.get(postId)

    useEffect(() => {
      if (postData != null) {
        dispatchPosts(postReducerMerge(List([postData])))
      }
    }, [postData])

    useEffect(() => {
      const run = async () => {
        // Connect to socket
        socketConnectionRef.current = await cableConnect()
        socketConnectionRef.current.subscriptions.create(
          {
            channel: 'PostChannel',
            post_id: postId,
          },
          {
            rejected: () => {
              console.log('We were rejected')
            },
            received: (data) => {
              dispatchPosts(fromJS(data))
            },
          }
        )
      }

      if (postId) {
        run()
      }

      return () => {
        if (socketConnectionRef.current != null) {
          socketConnectionRef.current.disconnect()
        }
      }
    }, [meId, postId])

    if (post === null) {
      return (
        <div className="EventPost">
          <Spinner small light />
        </div>
      )
    }

    return (
      <div className="EventPost">
        <PostCardEventSpecific
          post={post}
          event={event}
          user={user}
          dispatchPosts={dispatchPosts}
          refreshEvent={refreshEvent}
          onNoAccess={onNoAccess}
        />
      </div>
    )
  }
)
