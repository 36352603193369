import React, { useEffect } from 'react'
import './PurchasesPage.scss'
import { useGetMyUpcomingEventsApi, useGetMyPastEventsApi } from 'api'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { isAuthenticated, getMe } from 'store/selectors'
import { NarrowContainer } from 'components/Grid'
import UserDashboardEvents from 'routes/UserDashboard/UserDashboardEvents'
import { URL_FRONT_PAGE } from 'urls'
import PurchasesPageHeader from 'components/PurchasesPageHeader'

const PurchasesPage = () => {
  const history = useHistory()
  const hasAuth = useSelector((state) => isAuthenticated(state))
  const me = useSelector((state) => getMe(state))
  const {
    data: upcoming,
    loading: upcomingLoading,
    error: upcomingError,
  } = useGetMyUpcomingEventsApi({
    userId: me.get('id'),
  })
  const {
    data: past,
    loading: pastLoading,
    error: pastError,
  } = useGetMyPastEventsApi({
    userId: me.get('id'),
  })

  useEffect(() => {
    if (!hasAuth) {
      history.push(URL_FRONT_PAGE)
    }
  }, [hasAuth, history])

  return (
    <div className="PurchasesPage">
      <NarrowContainer>
        <PurchasesPageHeader />
        <div className="purchases-page-events">
          <UserDashboardEvents
            title="Upcoming events"
            emptyText="You have no purchased upcoming events"
            events={upcoming}
            loading={upcomingLoading}
            error={upcomingError}
            large
          />
        </div>
        <div className="purchases-page-events">
          <UserDashboardEvents
            title="Latest videos"
            emptyText="You have no purchased past videos"
            events={past}
            pastEvents
            loading={pastLoading}
            error={pastError}
            large
          />
        </div>
      </NarrowContainer>
    </div>
  )
}

export default PurchasesPage
