import React, { useEffect, useState } from 'react'
import './CheckoutPaymentPrice.scss'
import { displayPrice } from 'utils'
import clsx from 'clsx'
import Spinner from 'components/Spinner'
import Avatar from 'components/Avatar/Avatar'

const CheckoutPaymentPrice = ({ active, onClick, price, inviteCode }) => {
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (active) {
      setLoading(false)
    }
  }, [active, setLoading])

  const changePlan = () => {
    if (!active) {
      setLoading(true)
      onClick()
    }
  }

  const renderNonMonthlyBillingPrice = (price) => {
    return (
      displayPrice(
        Math.round((price.get('amount') * 10) / 100) / 10,
        price.get('currency')
      ) +
      ' (' +
      displayPrice(
        Math.round((price.get('monthlyPrice') * 10) / 100) / 10,
        price.get('currency')
      ) +
      ' per month)'
    )
  }
  const renderBasePrice = (price) => {
    return (
      displayPrice(
        Math.round((price.get('monthlyPrice') * 10) / 100) / 10,
        price.get('currency')
      ) + ' per month'
    )
  }

  const renderLoyaltyPrice = (price) => {
    return `${price.get('lockInMonths')} months - ${displayPrice(
      Math.round((price.get('monthlyPrice') * 10) / 100) / 10,
      price.get('currency')
    )} per month`
  }

  const renderInviteCode = () => {
    if (!inviteCode) {
      return null
    }
    return (
      <div className="checkout-payment-invite-code">
        <Avatar
          url={inviteCode.getIn(['user', 'profileImageUrl'])}
          userId={inviteCode.getIn(['user', 'id'])}
          className="avatar"
        />
        50% off your first month
      </div>
    )
  }

  return (
    <div
      className={clsx('CheckoutPaymentPrice', {
        active,
      })}
      onClick={changePlan}
    >
      <div>{loading && <Spinner tiny />}</div>
      <div className="checkout-payment-price-header">
        <div className="checkout-payment-price-name">
          {price.get('base') || price.get('billingName') !== 'monthly'
            ? `${
                price.get('billingName').charAt(0).toUpperCase() +
                price.get('billingName').slice(1)
              } plan`
            : `Loyalty plan`}
        </div>
        <div className="checkout-payment-price-discount">
          {price.get('discount') > 0 &&
            `Save ${Math.round(price.get('discount') * 10) / 10}%`}
        </div>
      </div>
      <div className="checkout-payment-price-amount">
        {price.get('billingName') !== 'monthly'
          ? renderNonMonthlyBillingPrice(price)
          : price.get('base')
          ? renderBasePrice(price)
          : renderLoyaltyPrice(price)}
      </div>
      {renderInviteCode()}
    </div>
  )
}

export default CheckoutPaymentPrice
