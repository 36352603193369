import React, { useState } from 'react'
import './PostComposeModalAttachmentPhoto.scss'
import { Image } from 'components/Image'
import Spinner from 'components/Spinner'
import { useFormikContext } from 'formik'
import CloseSvg from 'assets/svg/CloseSvg'
import { ErrorOutlineSvg } from 'assets/svg'

const PostComposeModalAttachmentPhoto = ({ removeAttachment }) => {
  const { values } = useFormikContext()
  const photoUrl = values.attachmentPhotoUrl
  const [canImageLoad, setCanImageLoad] = useState(true)

  const renderInner = () => {
    if (!canImageLoad) {
      return (
        <>
          <div className="post-compose-modal-attachment-photo-image-error">
            <ErrorOutlineSvg />
            Can't load image
          </div>
          <div
            className="post-compose-modal-attachment-photo-close"
            onClick={removeAttachment}
          >
            <CloseSvg className="post-compose-modal-attachment-photo-close-icon" />
          </div>
        </>
      )
    } else if (photoUrl) {
      return (
        <>
          <Image
            className="post-compose-modal-attachment-photo-image"
            src={photoUrl}
            onError={() => setCanImageLoad(false)}
          />
          <div
            className="post-compose-modal-attachment-photo-close"
            onClick={removeAttachment}
          >
            <CloseSvg className="post-compose-modal-attachment-photo-close-icon" />
          </div>
        </>
      )
    } else {
      return (
        <div className="post-compose-modal-attachment-photo-loader">
          <Spinner light small />
        </div>
      )
    }
  }

  return <div className="PostComposeModalAttachmentPhoto">{renderInner()}</div>
}

export default PostComposeModalAttachmentPhoto
