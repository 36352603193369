import React, { useCallback, useState } from 'react'
import './CouponOptions.scss'
import OptionsMenu from '../OptionsMenu'
import CouponModal from 'modals/CouponModal'
import { updateCoupon } from 'api'
import {
  CreateSvg,
  LinkSvg,
  DeactivateSvg,
  CircleOutlineCheckedSvg,
} from 'assets/svg'
import { URL_DOMAIN } from 'urls'

const CouponOptions = ({ coupon, onCouponAction, me, copyToClipboard }) => {
  const [open, setOpen] = useState(false)
  const [editCouponActive, setEditCouponActive] = useState(false)
  const editCouponClose = useCallback(
    (e) => {
      e.stopPropagation()
      setEditCouponActive(false)
    },
    [setEditCouponActive]
  )

  const onEdit = useCallback(() => {
    setEditCouponActive(true)
    setOpen(false)
  }, [setEditCouponActive, setOpen])

  const onCopyLink = useCallback(() => {
    setOpen(false)
    const link = `${URL_DOMAIN}/${me.get('username')}?promo=${coupon.get(
      'code'
    )}`

    copyToClipboard(link)
  }, [setOpen, copyToClipboard, me, coupon])

  const onDeactivate = useCallback(async () => {
    const couponId = coupon.get('id')

    if (
      !window.confirm(
        `Are you sure you want to ${
          coupon.get('status') === 'active' ? 'deactivate' : 'activate'
        } the coupon ${coupon.get('name')}?`
      )
    ) {
      return null
    }

    try {
      await updateCoupon({
        couponId,
        data: {
          status: coupon.get('status') === 'active' ? 'inactive' : 'active',
        },
      })
      onCouponAction()
      setOpen(false)
    } catch (e) {
      alert('Updating failed, please try again')
    }
  }, [coupon, onCouponAction])

  const _onCouponAction = () => {
    onCouponAction()
    setEditCouponActive(false)
  }

  return (
    <>
      <CouponModal
        active={editCouponActive}
        onClose={editCouponClose}
        coupon={coupon}
        onCouponAction={_onCouponAction}
      />
      <OptionsMenu
        open={open}
        setOpen={setOpen}
        className="CouponOptions"
        onClick={(e) => e.preventDefault()}
      >
        <div className="link coupons-link" onClick={onEdit}>
          <CreateSvg className="links-svg" />
          Edit coupon
        </div>
        <div className="link coupons-link" onClick={onCopyLink}>
          <LinkSvg className="links-svg" />
          Copy promo link
        </div>
        <div className="link coupons-link" onClick={onDeactivate}>
          {coupon.get('status') === 'active' ? (
            <>
              <DeactivateSvg className="links-svg small-icon" /> Deactivate
              coupon
            </>
          ) : (
            <>
              <CircleOutlineCheckedSvg className="links-svg" /> Activate coupon
            </>
          )}
        </div>
      </OptionsMenu>
    </>
  )
}

export default CouponOptions
