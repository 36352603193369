import {
  TOKEN_REFRESH,
  SET_AUTH,
  CLEAR_AUTH,
  UPDATE_ME,
  FOLLOW_USER,
  UN_FOLLOW_USER,
  MERGE_ME,
} from 'store/constants'
import { fromJS, Map } from 'immutable'
import { AuthSchema, AuthMeSchema } from 'store/schemas'

const initialState = AuthSchema()

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_AUTH:
      // This hack merges in the user into the me schema
      return AuthSchema(
        Object.assign(action.payload.auth, {
          me: AuthMeSchema(processMe(fromJS(action.payload.auth.user))),
        })
      )
    case UPDATE_ME:
      return state.set('me', AuthMeSchema(processMe(action.payload.me)))
    case MERGE_ME:
      return state.mergeIn(['me'], action.payload.me)
    case TOKEN_REFRESH:
      return state.merge(fromJS(action.payload))
    case CLEAR_AUTH:
      return initialState
    case FOLLOW_USER:
      return state.setIn(
        ['me', 'following', action.payload.user.get('id')],
        action.payload.user
      )
    case UN_FOLLOW_USER:
      return state.deleteIn(['me', 'following', action.payload.user.get('id')])
    default:
      return state
  }
}

export default reducer

const processMe = (me) => {
  if (me == null) {
    return me
  }

  return me
    .update('following', (following) =>
      following != null
        ? Map(following.map((v) => [v.get('id'), v]))
        : following
    )
    .update('subscriptionAccounts', (subscriptionAccounts) =>
      subscriptionAccounts != null
        ? Map(subscriptionAccounts.map((v) => [v.get('id'), v]))
        : subscriptionAccounts
    )
}
