import React, { useState, useCallback } from 'react'
import { Link, useHistory } from 'react-router-dom'
import clsx from 'clsx'
import { SearchBox, withSearch } from '@elastic/react-search-ui'

import Avatar from 'components/Avatar'
import { CancelSvg } from 'assets/svg'
import './HeaderControlsSearchBox.scss'

const SearchInputView = ({ getAutocomplete, getInputProps }) => {
  const {
    onKeyDown,
    onBlur,
    handleOnBlur,
    handleKeyDown,
    ...inputProps
  } = getInputProps({
    placeholder: 'Find a creator',
  })

  let autocomplete = getAutocomplete()

  return (
    <div className="sui-search-box__wrapper">
      <input
        onBlur={(event) => {
          handleOnBlur()
          onBlur(event)
        }}
        onKeyDown={(event) => handleKeyDown(event, onKeyDown)}
        {...inputProps}
      />
      {autocomplete && autocomplete.props.isOpen && (
        <CancelSvg
          className="sui-search-box-cancel"
          onClick={(event) => {
            handleOnBlur()
            onBlur(event)
          }}
        />
      )}
      {autocomplete}
    </div>
  )
}

const SearchAutocompleteView = ({
  autocompletedResults,
  autocompleteResults,
}) => {
  return (
    <div className="sui-search-box__autocomplete-container">
      {autocompletedResults.map((result, index) => (
        <Link
          key={result.id.raw}
          to={result.url.raw}
          className={clsx('autocomplete-result', {
            active: index === autocompleteResults.selectedAutocomplete,
          })}
        >
          <Avatar
            url={result.profile_image_url.raw}
            className="autocomplete-avatar"
          />
          <div className="autocomplete-title">{result.name.raw}</div>
        </Link>
      ))}
    </div>
  )
}

const HeaderControlsSearchBox = ({ autocompletedResults }) => {
  const history = useHistory()
  const [selectedAutocomplete, setSelectedAutocomplete] = useState(0)

  const handleOnBlur = useCallback(() => {
    setSelectedAutocomplete(0)
  }, [setSelectedAutocomplete])

  const handleKeyDown = useCallback(
    (event, originalCallback) => {
      let autocompletedCount = autocompletedResults.length

      if (!!autocompletedCount && event.key === 'Enter') {
        history.push(autocompletedResults[selectedAutocomplete].url.raw)
      } else if (!!autocompletedCount && event.key === 'ArrowUp') {
        event.stopPropagation()
        event.preventDefault()

        setSelectedAutocomplete((v) => Math.max(v - 1, 0))
      } else if (!!autocompletedCount && event.key === 'ArrowDown') {
        event.stopPropagation()
        event.preventDefault()

        setSelectedAutocomplete((v) => Math.min(v + 1, autocompletedCount - 1))
      } else {
        setSelectedAutocomplete(0)
        originalCallback(event)
      }
    },
    [
      setSelectedAutocomplete,
      autocompletedResults,
      selectedAutocomplete,
      history,
    ]
  )

  return (
    <div className="HeaderControlsSearchBox">
      <SearchBox
        onSubmit={() => {}}
        inputProps={{ handleOnBlur, handleKeyDown }}
        inputView={SearchInputView}
        autocompleteResults={{
          titleField: 'name',
          urlField: 'url',
          selectedAutocomplete,
        }}
        autocompleteView={SearchAutocompleteView}
      />
    </div>
  )
}

export default withSearch(({ autocompletedResults }) => ({
  autocompletedResults,
}))(HeaderControlsSearchBox)
