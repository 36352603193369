import React, { useCallback } from 'react'
import './DocumentItem.scss'
import { DateTime } from 'luxon'
import { Image } from 'components/Image'
import { DownloadRoundedSvg } from 'assets/svg'
import fileImage from 'assets/img/file.png'
import { POST_ATTACHMENT_ACCESS } from 'store/constants/post'
import UnlockButton from 'components/UnlockButton'

const DocumentItem = ({ post, onNoneAccessPostClick }) => {
  const access = post.get('attachmentAccess') === POST_ATTACHMENT_ACCESS.NONE
  const onNoAccess = useCallback(
    (post, title) => {
      onNoneAccessPostClick(post, title)
    },
    [onNoneAccessPostClick]
  )

  const formattedDate = (date) => {
    const dateTime = DateTime.fromISO(date)
    if (dateTime.year !== new Date().getFullYear()) {
      return dateTime.toFormat('MMM d, y, HH:mm')
    } else {
      return dateTime.toFormat('MMM d, HH:mm')
    }
  }

  return (
    <>
      <div className="DocumentItem">
        <div className="document-item-type">
          <Image src={fileImage} />
          <span className="document-item-file-ext">
            {post.getIn(['attachment', 'fileExtension'])}
          </span>
        </div>
        <div className="document-item-content">
          <div className="document-item-details">
            <div className="document-item-title">
              {post.getIn(['attachment', 'originalFilename'])}
            </div>
            <div className="document-item-numbers">
              <div className="document-item-size"></div>
              <div className="document-item-date">
                {formattedDate(post.get('createdAt'))}
              </div>
            </div>
          </div>
          {access ? (
            <UnlockButton
              className="document-item-download-button"
              onClick={() => onNoAccess(post, 'Unlock to download file')}
            />
          ) : (
            <div className="document-item-download-button button secondary">
              <a
                href={post.getIn(['attachment', 'fullPath'])}
                target="_blank"
                rel="noopener noreferrer"
              >
                <DownloadRoundedSvg />
              </a>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default DocumentItem
