import React, { useCallback, useState } from 'react'
import './Bookmark.scss'

import { deleteBookmark } from 'api'
import { createBookmark } from 'api'
import { BookmarkBorderSvg, BookmarkSvg } from 'assets/svg'
import { AUTH_MODAL_STATE } from 'store/constants'
import { requireAuth } from 'store/utils/awaitStore'
import { useToasts } from 'react-toast-notifications'

const Bookmark = ({ active, eventId, isHost }) => {
  const { addToast } = useToasts()
  const [isActive, setIsActive] = useState(active)
  const _onClick = async (e) => {
    e.stopPropagation()
    e.preventDefault()
    isActive ? await removeBookmark() : await addBookmark()
  }

  const removeBookmark = useCallback(async () => {
    try {
      await deleteBookmark({ eventId })
      setIsActive(false)
    } catch (e) {
      addToast('Could not remove bookmark. Please try again', {
        appearance: 'error',
      })
    }
  }, [eventId, setIsActive, addToast])

  const addBookmark = useCallback(async () => {
    try {
      await createBookmark({ eventId })
      setIsActive(true)
    } catch (e) {
      if (e.response.status === 401) {
        await requireAuth(AUTH_MODAL_STATE.SIGNIN, false)
      }
    }
  }, [eventId, setIsActive])

  return (
    <div className={'Bookmark'} onClick={_onClick}>
      {isActive ? <BookmarkSvg /> : <BookmarkBorderSvg />}
    </div>
  )
}

export default Bookmark
