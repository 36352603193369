import React from 'react'
import './LoadingError.scss'

const LoadingError = ({
  message = 'We encountered an error loading the page',
  refresh,
  children,
}) => {
  const renderMessage = () => {
    if (children == null) {
      return (
        <div className="loading-error-description">
          Please check your network connection and{' '}
          <span className="loading-error-link" onClick={refresh}>
            click here to try again.
          </span>
        </div>
      )
    } else {
      return <div className="loading-error-description">{children}</div>
    }
  }

  return (
    <div className="LoadingError">
      <div className="loading-error-inner">
        <div className="loading-error-title">{message}</div>
        {renderMessage()}
      </div>
    </div>
  )
}

export default LoadingError
