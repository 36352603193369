import React from 'react'
import './BoonFormGroup.scss'
import clsx from 'clsx'

const BoonFormGroup = ({
  label,
  helperText,
  subLabel,
  required,
  children,
  className,
  ...otherProps
}) => {
  return (
    <div className={clsx('BoonFormGroup', className)} {...otherProps}>
      <div className="boon-form-group-context">
        <div className="boon-form-group-label">{label}</div>
        {required && <div className="boon-form-group-required">Required</div>}
        {subLabel && (
          <div className="boon-form-group-sub-label">{subLabel}</div>
        )}
      </div>
      <div className="boon-form-group-content">
        {children}
        {helperText && (
          <div className="boon-form-group-helper-text">{helperText}</div>
        )}
      </div>
    </div>
  )
}

export default BoonFormGroup
