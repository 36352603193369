import React from 'react'
import { displayPrice, displayStars } from 'utils'
import Avatar from 'components/Avatar'

const RelationsMenuUserTable = ({ user }) => {
  const renderUserTable = () => {
    return (
      <table className="relations-menu-modal-table">
        <tbody>
          <tr>
            <td>Name</td>
            <td>{user.get('real_name')}</td>
          </tr>
          <tr>
            <td>Email</td>
            <td className="relations-menu-modal-table-overflow">
              {user.get('email')}
            </td>
          </tr>
          <tr>
            <td>Email Permission</td>
            <td>{user.get('creator_consent') ? 'Yes' : 'No'}</td>
          </tr>
          <tr>
            <td>Lifetime Days</td>
            <td>{user.get('lifetime_days')}</td>
          </tr>
          <tr>
            <td>Lifetime Value</td>
            <td>
              {displayPrice(
                user.getIn(['lifetime_value', 0]),
                user.getIn(['lifetime_value', 1])
              )}
            </td>
          </tr>
          <tr>
            <td>Latest Charge</td>
            <td>
              {displayPrice(
                user.getIn(['latest_charge', 0]),
                user.getIn(['latest_charge', 1])
              )}
            </td>
          </tr>
          <tr>
            <td>Avg. Video Rating</td>
            <td>{displayStars(user.get('avg_video_rating'))}</td>
          </tr>
        </tbody>
      </table>
    )
  }

  return (
    <>
      <div className="relations-menu-modal-avatar-wrapper">
        <Avatar
          className="relations-menu-modal-avatar"
          url={user.get('profile_image_url')}
          userId={user.get('id')}
        />
      </div>
      <div className="relations-menu-modal-table-wrapper">
        {renderUserTable()}
      </div>
    </>
  )
}

export default RelationsMenuUserTable
