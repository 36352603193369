import React, { useState, useEffect, useMemo } from 'react'
import { DateTime } from 'luxon'
import './Countdown.scss'

const Countdown = ({ startTime }) => {
  const parsedStartTime = useMemo(() => DateTime.fromISO(startTime), [
    startTime,
  ])
  const getDateComponents = () => {
    return parsedStartTime
      .diffNow(['days', 'hours', 'minutes', 'seconds'])
      .toObject()
  }
  const [dateComponents, setDateComponents] = useState(getDateComponents())

  useEffect(() => {
    var timerID = setInterval(() => {
      setDateComponents(getDateComponents())
    }, 1000)
    return () => clearInterval(timerID)
  })

  const renderDateComponent = (name, value) => {
    value = Math.round(value)

    if (value < 0) {
      value = 0
    }

    if (String(value).length === 1) {
      value = '0' + value
    }

    return (
      <React.Fragment key={name}>
        <div className="countdown-date-component">
          <div className="countdown-date-component-value">{value}</div>
          {/*<div className="countdown-date-component-name">{name}</div>*/}
        </div>
        <div className="countdown-date-component-separator">:</div>
      </React.Fragment>
    )
  }

  return (
    <div className="Countdown">
      {Object.entries(dateComponents).map(([name, value]) =>
        renderDateComponent(name, value)
      )}
    </div>
  )
}

export default Countdown
