import React from 'react'
import './BalanceTransactionSum.scss'
import { getAccountingNumberFormatter } from './AccountingConstants'
import { displayPrice } from 'utils'

const BalanceTransactionSum = ({ payout }) => {
  const numberFormatter = getAccountingNumberFormatter()

  const currency = payout.get('currency')
  const gross = payout.get('gross')
  const vat = payout.get('invoiceTax')
  const boonFee = payout.get('boonFee')
  const stripeFee = payout.get('paymentFees')
  const net = payout.get('net')

  const formatCurrency = (amount) =>
    displayPrice(numberFormatter.format(amount / 100), currency)

  return (
    <div className="BalanceTransactionSum">
      <div className="balance-transaction-sum-amount">
        <span>Gross </span>
        <span>{formatCurrency(gross)}</span>
      </div>
      <div className="balance-transaction-sum-amount">
        <span>VAT</span>
        <span>{formatCurrency(vat)}</span>
      </div>
      <div className="balance-transaction-sum-amount">
        <span>Boon fee</span>
        <span>{formatCurrency(boonFee)}</span>
      </div>
      <div className="balance-transaction-sum-amount">
        <span>Payment fees</span>
        <span>{formatCurrency(stripeFee)}</span>
      </div>
      <div className="balance-transaction-sum-amount">
        <b>Payout</b>
        <b>{formatCurrency(net)}</b>
      </div>
    </div>
  )
}

export default BalanceTransactionSum
