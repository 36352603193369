import React, { useState } from 'react'
import './PostCardActions.scss'
import { ThumbUpSvg, CommentSvg } from 'assets/svg'
import { createLikeApi, deleteLikeApi } from 'api'
import clsx from 'clsx'
import { setPostAttributes } from 'components/ProfileFeed/postUtils'
import { Map } from 'immutable'
import { POST_ACCESS, POST_COMMENT_ACCESS } from 'store/constants/post'
import { useMe } from 'utils'

const PostCardActions = ({
  post,
  dispatchPosts,
  commentInputRef,
  onNoAccess,
  setShowCommentCompose,
  loadMoreComments,
  specific,
}) => {
  const me = useMe()
  const [likeError, setLikeError] = useState(false)
  const liked = post.get('liked')
  const loadedCount = post
    .get('comments')
    .reduce(
      (acc, comment) =>
        (comment.get('replies') ? comment.get('replies').size : 0) + 1 + acc,
      0
    )
  const totalCount = post.get('commentsCount')
  const moreComments = totalCount > loadedCount
  const onLikeClick = async () => {
    if (post.get('access') === POST_ACCESS.READ || me.get('id') === null) {
      onNoAccess('Unlock to like this post')
      return null
    }

    try {
      if (liked) {
        await deleteLikeApi({ id: post.get('id'), type: 'Post' })
      } else {
        await createLikeApi({ id: post.get('id'), type: 'Post' })
      }
    } catch (e) {
      setLikeError(true)
      setTimeout(() => {
        setLikeError(false)
      }, 1500)
      return null
    }

    dispatchPosts(
      setPostAttributes({
        postId: post.get('id'),
        post: Map({
          liked: !liked,
        }),
      })
    )
  }

  const onCommentClick = () => {
    if (
      post.get('commentsAccess') === POST_COMMENT_ACCESS.NONE ||
      me.get('id') === null
    ) {
      onNoAccess('Unlock to comment on this post')
      return null
    }

    setShowCommentCompose && setShowCommentCompose(true)

    if (commentInputRef?.current != null) {
      commentInputRef.current.focus()
    }
  }

  const commentsCount = post.get('commentsCount')

  const handleCommentClick = () => {
    if (post.get('commentsAccess') === POST_COMMENT_ACCESS.NONE) {
      onNoAccess('Unlock to see comments on this post')
      return null
    }

    return moreComments ? loadMoreComments() : null
  }

  return (
    <div className={clsx('PostCardActions', { specific })}>
      <div className="post-card-actions-list">
        <div
          className={clsx('post-card-actions-action', {
            active: liked,
            error: likeError,
          })}
          onClick={onLikeClick}
        >
          <ThumbUpSvg className="post-card-actions-action-icon" />
          {likeError ? 'Error, please try again' : 'Like'}
        </div>
        <div className="post-card-actions-action" onClick={onCommentClick}>
          <CommentSvg className="post-card-actions-action-icon" />
          Comment
        </div>
        {commentsCount > 0 && (
          <div
            className="post-card-actions-action post-card-actions-action-right"
            onClick={handleCommentClick}
          >
            {commentsCount} {commentsCount === 1 ? 'comment' : 'comments'}
          </div>
        )}
      </div>
    </div>
  )
}

export default PostCardActions
