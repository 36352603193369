import React from 'react'
import './NavigationTabs.scss'
import clsx from 'clsx'

const NavigationTabs = ({ tabList, activeTab, onClick }) => {
  return (
    <div className="NavigationTabs">
      <ol className="navigation-tabs-tablist">
        {tabList.map(({ name, icon: Icon }) => (
          <li
            className={clsx('navigation-tabs-tab', {
              active: activeTab === name,
            })}
            key={name}
            onClick={() => onClick(name)}
          >
            <Icon />
            <div className="navigation-tabs-tab-name">{name}</div>
          </li>
        ))}
      </ol>
    </div>
  )
}

export default NavigationTabs
