import React, { useMemo } from 'react'
import './ReactionList.scss'
import { List, Map } from 'immutable'
import clsx from 'clsx'
import { useSelector } from 'react-redux'
import { getMe, isAuthenticated } from 'store/selectors'
import { requireAuth } from 'store/utils/awaitStore'

const ReactionList = React.memo(
  ({ reactions, onReactionCreate, onReactionDelete, wraps }) => {
    const me = useSelector((state) => getMe(state))
    const hasAuth = useSelector((state) => isAuthenticated(state))
    const meId = me?.get('id')

    const groupedReactions = useMemo(
      () =>
        reactions.reduce(
          (map, reaction) =>
            map.update(reaction.get('reaction'), (list) =>
              (list ?? List()).push(reaction.get('userId'))
            ),
          Map()
        ),
      [reactions]
    )
    const meReactionIds = useMemo(
      () =>
        reactions.reduce((map, reaction) => {
          if (reaction.get('userId') === meId) {
            return map.set(reaction.get('reaction'), reaction.get('id'))
          }

          return map
        }, Map()),
      [reactions, meId]
    )

    const renderReactions = () => {
      return groupedReactions
        .entrySeq()
        .sortBy(([, users]) => users.size)
        .reverse()
        .map(([reaction, users]) => {
          const active = users.includes(meId)
          const onClick = (e) => {
            e.stopPropagation()
            if (!hasAuth) {
              return requireAuth()
            }

            const reactionId = meReactionIds.get(reaction)
            if (active && reactionId != null) {
              onReactionDelete(reactionId)
            } else if (!active) {
              onReactionCreate(reaction)
            }
          }

          return (
            <ReactionItem
              reaction={reaction}
              count={users.size}
              key={reaction}
              active={active}
              onClick={onClick}
            />
          )
        })
    }

    if (wraps) {
      return (
        <div className="ReactionList">
          <div className="reaction-list-inner">{renderReactions()}</div>
        </div>
      )
    } else {
      return renderReactions()
    }
  }
)

export default ReactionList

const ReactionItem = React.memo(({ reaction, count, active, onClick }) => {
  return (
    <button className={clsx('ReactionItem', { active })} onClick={onClick}>
      <span className="reaction-item-emoji">{reaction}</span>
      <span className="reaction-item-count">{count}</span>
    </button>
  )
})
