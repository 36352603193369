import React from 'react'
import './Footer.scss'
import { Col, ExtendedContainer, Row } from 'components/Grid'
import {
  URL_CONTACT,
  URL_FACEBOOK,
  URL_INSTAGRAM,
  URL_LINKEDIN,
  URL_EXTERNAL_TERMS_AND_CONDITIONS,
  URL_EXTERNAL_HELP,
  URL_EXTERNAL_PRIVACY_POLICY,
  URL_CREATOR,
  URL_EXTERNAL_CAREERS,
  URL_ABOUT,
  URL_EXTERNAL_BLOG,
  URL_FEATURE_VIDEO,
  URL_FEATURE_MEMBERSHIP,
  URL_FEATURE_COMMUNITY,
  URL_FEATURE_INSIGHTS,
  URL_FEATURE_PAYOUT,
  URL_FEATURE_PAGE,
} from 'urls'
import { Link, withRouter } from 'react-router-dom'
import clsx from 'clsx'
import { FacebookSvg, InstagramSvg, LinkedInSvg } from 'assets/svg'

const visibleList = [
  /^\/about$/,
  /^\/creator$/,
  /^\/support$/,
  /^\/music$/,
  /^\/manage-subscriptions$/,
  /^\/$/,
  /^\/product/,
]
const bigPaths = [
  /^\/about$/,
  /^\/creator$/,
  /^\/support$/,
  /^\/music$/,
  /^\/$/,
  /^\/product/,
]

const Footer = ({ location }) => {
  if (visibleList.find((r) => location.pathname.match(r) != null) == null) {
    return null
  }

  const big = bigPaths.find((r) => location.pathname.match(r) != null)

  const renderBig = () => {
    if (!big) {
      return null
    }

    return (
      <div className="footer-big">
        <Row>
          <Col className="footer-big-section" xl={{ offset: 1, span: 2 }}>
            <div className="footer-big-section-header">Product</div>
            <Link to={URL_FEATURE_PAGE}>Page</Link>
            <Link to={URL_FEATURE_MEMBERSHIP}>Membership</Link>
            <Link to={URL_FEATURE_VIDEO}>Live stream & video</Link>
            <Link to={URL_FEATURE_COMMUNITY}>Community</Link>
            <Link to={URL_FEATURE_INSIGHTS}>Insights</Link>
            <Link to={URL_FEATURE_PAYOUT}>Payout</Link>
          </Col>
          <Col className="footer-big-section" xl={2}>
            <div className="footer-big-section-header">For creators</div>
            <Link to={URL_CREATOR}>Become a creator</Link>
            <a
              href={URL_EXTERNAL_HELP}
              target="_blank"
              rel="noopener noreferrer"
            >
              Help & Support
            </a>
          </Col>
          <Col className="footer-big-section" xl={2}>
            <div className="footer-big-section-header">For audience</div>
            <a
              href={URL_EXTERNAL_HELP}
              target="_blank"
              rel="noopener noreferrer"
            >
              Help & Support
            </a>
          </Col>
          <Col className="footer-big-section" xl={2}>
            <div className="footer-big-section-header">Company</div>
            <Link to={URL_ABOUT}>About</Link>
            <a
              href={URL_EXTERNAL_CAREERS}
              target="_blank"
              rel="noopener noreferrer"
            >
              Careers
            </a>
            <a
              href={URL_EXTERNAL_BLOG}
              target="_blank"
              rel="noopener noreferrer"
            >
              Blog
            </a>
            <Link to={URL_CONTACT}>Contact</Link>
            <a
              href={URL_EXTERNAL_PRIVACY_POLICY}
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy policy
            </a>
            <a
              href={URL_EXTERNAL_TERMS_AND_CONDITIONS}
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms and conditions
            </a>
          </Col>
        </Row>
        <Row>
          <Col xl={{ offset: 1, span: 10 }}>
            <div className="footer-social">
              <a
                className="footer-social-item"
                href={URL_FACEBOOK}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FacebookSvg />
              </a>
              <a
                className="footer-social-item"
                href={URL_INSTAGRAM}
                target="_blank"
                rel="noopener noreferrer"
              >
                <InstagramSvg />
              </a>
              <a
                className="footer-social-item"
                href={URL_LINKEDIN}
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedInSvg />
              </a>
            </div>
          </Col>
        </Row>
      </div>
    )
  }

  return (
    <div className={clsx('Footer', { big })}>
      <ExtendedContainer>
        {renderBig()}
        <Row>
          <Col xl={{ offset: 1, span: 10 }}>
            <div className="footer-wrapper">
              <div className="footer-copyright">© 2022 Boon</div>
            </div>
          </Col>
        </Row>
      </ExtendedContainer>
    </div>
  )
}

export default withRouter(Footer)
