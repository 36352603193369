import React from 'react'

import { ArrowDropDownSvg } from 'assets/svg'
import InsightsContentLoader from 'components/InsightsContentLoader/InsightsContentLoader'

import clsx from 'clsx'
import { capitalize } from 'utils'

const InsightsTableHeaders = ({
  headers,
  changeSortBy = null,
  sortBy = null,
  loading,
  optionsMenu = null,
}) => {
  const renderHeader = (key, inputName, idx) => {
    let name = inputName ? inputName : capitalize(key)

    if (loading) {
      name = (
        <InsightsContentLoader width={120} height={20}>
          <rect x="0" y="5" rx="6" ry="6" width="80" height="10" />
        </InsightsContentLoader>
      )
    }

    return (
      <th
        key={idx}
        onClick={() =>
          changeSortBy &&
          changeSortBy(
            sortBy.includes(`${key} asc`) ? `${key} desc` : `${key} asc`
          )
        }
      >
        {name}
        <div className="insights-table-widget-table-sort">
          {
            <ArrowDropDownSvg
              className={clsx('insights-table-widget-table-sort-down', {
                active: sortBy.startsWith(`${key} desc`),
              })}
            />
          }
          {
            <ArrowDropDownSvg
              className={clsx('insights-table-widget-table-sort-up', {
                active: sortBy.startsWith(`${key} asc`),
              })}
            />
          }
        </div>
      </th>
    )
  }

  if (!headers) {
    return null
  }

  return (
    <thead>
      <tr>
        {headers
          .filter(({ active }) => active)
          .map(({ key, name }, idx) => renderHeader(key, name, idx))}
        {optionsMenu && (
          <th className="insights-table-widget-table-options-menu-header"></th>
        )}
      </tr>
    </thead>
  )
}

export default InsightsTableHeaders
