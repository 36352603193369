import React, { useState } from 'react'
import Cookies from 'universal-cookie'
import clsx from 'clsx'
import { URL_INVITE_FRIENDS } from 'urls'
import CloseSvg from 'assets/svg/CloseSvg'
import { Link } from 'react-router-dom'
import { GroupSvg } from 'assets/svg'

const InviteCodeReminder = ({ user, isHost }) => {
  const cookies = new Cookies()
  const [showInviteCode, setShowInviteCode] = useState(true)
  const cookieName = `${user.get('username')}-showInviteCode`
  const hideInviteCodeBox = () => {
    setTimeout(
      () => cookies.set(cookieName, false, { path: '/', maxAge: 2592000 }),
      2000
    )
    setShowInviteCode(false)
  }

  if (cookies.get(cookieName) === 'false' || !user.get('inviteCodeEnabled')) {
    return null
  }

  return (
    <div className={clsx('InviteCodeContainer', !showInviteCode && 'fadeOut')}>
      <div className="invite-code-container-inner">
        <div className="invite-code-container-left">
          <div className="invite-code-container-svg-circle">
            <GroupSvg />
          </div>
        </div>
        <div className="invite-code-container-middle">
          <div>
            {isHost
              ? 'Member get member is turned on. Tell your members that they can earn lots of credits by inviting their friends'
              : 'Invite your friends and get rewarded when they sign up for a membership'}
          </div>
          {isHost ? (
            <button className="button secondary" onClick={hideInviteCodeBox}>
              Got it
            </button>
          ) : (
            <Link to={URL_INVITE_FRIENDS} className="button secondary">
              Get your link
            </Link>
          )}
        </div>
        <div className="invite-code-container-right">
          <CloseSvg className="invite-code-close" onClick={hideInviteCodeBox} />
        </div>
      </div>
    </div>
  )
}

export default InviteCodeReminder
