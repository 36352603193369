import React, { useCallback, useEffect, useRef, useState } from 'react'
import './NewEventPage.scss'
import { asyncModal, hasAccessToEvent, useMe } from 'utils'
import VideoPlayer from 'routes/WatchPage/components/VideoPlayer'
import Div100vh from 'react-div-100vh'
import { useSelector } from 'react-redux'
import { getMe } from 'store/selectors'
import NewEventChat from './NewEventChat'
import clsx from 'clsx'
import { useLockBodyScroll, usePrevious, useUnmount } from 'react-use'
import EventHostHeader from 'components/EventHostHeader'
import { EVENT_PURCHASE_STATE, EVENT_TYPE } from 'store/constants'
import EventMedia from './EventMedia'
import BrowserSupportBanner from './BrowserSupportBanner'
import EventHostPanel from './EventHostPanel'
import EventStartWaitingRoom from './EventStartWaitingRoom'
import WatchWaitingRoom from 'routes/WatchPage/components/WatchWaitingRoom'
import WatchEventFeedback from 'routes/WatchPage/components/WatchEventFeedback'
import { BackgroundImage } from 'components/Image'
import CouponAppliedBanner from 'components/CouponAppliedBanner'
import { EventPost } from './EventPost'
import HeaderProfile from 'components/HeaderProfile'
import { useEventChatActive } from 'routes/EventPage/components/eventPageUtils'
import LocalHeader from 'components/LocalHeader'
import SubscriptionUnpaidBanner from 'components/SubscriptionUnpaidBanner'
import { requireAuth } from 'store/utils/awaitStore'
import { getUserFeedback } from 'api'
import { useVideoPlayStats } from 'routes/EventPage/components/videoPlayStats'

const NewEventPage = ({
  event,
  refreshEvent,
  user,
  userSlug,
  withHeader,
  onNoAccess,
  onClose,
}) => {
  const me = useSelector((state) => getMe(state))
  const browser = useSelector((state) => state.get('browser'))
  const hasAccess = hasAccessToEvent(event)
  const hadAccess = usePrevious(hasAccess)
  const [liveEventEnded, setLiveEventEnded] = useState(false)
  const eventChatActive = useEventChatActive(event, liveEventEnded)
  const previousEventChatActive = usePrevious(eventChatActive)
  const isLiveEvent = event.get('live')

  const [menuItem, setMenuItem] = useState(
    hasAccess
      ? eventChatActive
        ? NEW_EVENT_MENU_ITEMS.CHAT
        : NEW_EVENT_MENU_ITEMS.INFO
      : NEW_EVENT_MENU_ITEMS.INFO
  )
  const [viewerCount, setViewerCount] = useState(null)

  useEffect(() => {
    if (
      (eventChatActive && !previousEventChatActive && hasAccess) ||
      (!hadAccess && hasAccess && eventChatActive)
    ) {
      setMenuItem(NEW_EVENT_MENU_ITEMS.CHAT)
    }
  }, [eventChatActive, previousEventChatActive, hasAccess, hadAccess])

  const notAvailable = ['soldOut', 'archived', 'notOnDemand'].includes(
    event.get('attendingState')
  )
  const canBuy = !notAvailable

  useLockBodyScroll(browser.lessThan.lg)
  useUnmount(() => {
    document.body.style.overflow = null
  })

  const switchToComments = useCallback(() => {
    setMenuItem(NEW_EVENT_MENU_ITEMS.INFO)
  }, [setMenuItem])

  const renderContent = () => {
    if (!isLiveEvent) {
      return (
        <EventPost
          event={event}
          user={user}
          refreshEvent={refreshEvent}
          onNoAccess={onNoAccess}
        />
      )
    }

    switch (menuItem) {
      case NEW_EVENT_MENU_ITEMS.CHAT:
        if (hasAccess) {
          return (
            <NewEventChat
              key={event.get('id')}
              event={event}
              me={me}
              eventChatActive={eventChatActive}
              updateViewerCount={(v) => setViewerCount(v)}
              onEventEnded={() => setLiveEventEnded(true)}
              switchToComments={switchToComments}
            />
          )
        }
      // falls through
      case NEW_EVENT_MENU_ITEMS.INFO:
      default:
        return (
          <EventPost
            event={event}
            user={user}
            refreshEvent={refreshEvent}
            onNoAccess={onNoAccess}
          />
        )
    }
  }

  const onEnded = useCallback(() => {
    const hasAccess = hasAccessToEvent(event)
    if (!hasAccess) {
      if (canBuy) {
        onNoAccess()
      } else {
        requireAuth()
      }
    }
  }, [event, onNoAccess, canBuy])

  const player = (
    <NewEventPlayer
      event={event}
      viewerCount={viewerCount}
      onEnded={onEnded}
      refreshEvent={refreshEvent}
      liveEventEnded={liveEventEnded}
    />
  )

  if (browser.greaterThan.lg) {
    return (
      <div
        className={clsx('NewEventPage new-event-page-desktop-container', {
          'with-header': withHeader,
        })}
      >
        {withHeader && <HeaderProfile user={user} dark />}
        <div
          className={clsx('new-event-page-desktop', {
            host: event.get('userType') === EVENT_TYPE.HOST,
          })}
        >
          <div className="new-event-page-desktop-left">
            <EventHostHeader event={event} controls />
            <CouponAppliedBanner userSlug={userSlug} />
            <SubscriptionUnpaidBanner userId={user.get('id')} />
            {player}
          </div>
          <div className="new-event-page-desktop-right">
            <div className="new-event-page-desktop-right-inner">
              {!withHeader && (
                <LocalHeader title={event.getIn(['host', 'name'])} />
              )}
              <NewEventMenu
                isLiveEvent={isLiveEvent}
                hasAccess={hasAccess}
                selectedItem={menuItem}
                onSelectItem={setMenuItem}
                eventChatActive={eventChatActive}
              />
              {renderContent()}
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <Div100vh
        className={clsx('NewEventPage new-event-page-mobile', {
          'with-header': withHeader,
        })}
      >
        <div className="new-event-page-mobile-top">
          {withHeader && <HeaderProfile user={user} dark />}
          {!withHeader && <LocalHeader close onClose={onClose} />}
          <EventHostHeader event={event} controls />
          <CouponAppliedBanner userSlug={userSlug} />
          <SubscriptionUnpaidBanner userId={user.get('id')} />
          {player}
          <NewEventMenu
            isLiveEvent={isLiveEvent}
            hasAccess={hasAccess}
            selectedItem={menuItem}
            onSelectItem={setMenuItem}
            eventChatActive={eventChatActive}
          />
        </div>
        <div className="new-event-page-mobile-content">{renderContent()}</div>
      </Div100vh>
    )
  }
}

export default NewEventPage

const NewEventPlayer = ({
  event,
  onEnded,
  viewerCount,
  refreshEvent,
  liveEventEnded,
}) => {
  const me = useMe()
  const hasAccess = hasAccessToEvent(event)
  const isHost = event.get('purchaseState') === EVENT_PURCHASE_STATE.IS_HOST

  const renderPlayer = () => {
    if (event.get('live') && !event.get('ended')) {
      // Live video
      if (hasAccess) {
        // Has access
        const onDemandUrl = event.get('recordedVideoUrl')
        if (onDemandUrl) {
          // Re-watch is ready
          return (
            <NewEventVideoPlayer
              url={onDemandUrl}
              event={event}
              onEnded={onEnded}
              hasAccess={hasAccess}
            />
          )
        } else {
          // Live event (or waiting for re-watch)
          return (
            <EventMedia
              event={event}
              viewerCount={viewerCount}
              refreshEvent={refreshEvent}
              liveEventEnded={liveEventEnded}
            />
          )
        }
      } else {
        return <EventStartWaitingRoom event={event} />
      }
    } else {
      // Uploaded video
      const url = event.get('recordedVideoUrl') || event.get('trailerVideoUrl')

      if (event.get('archived')) {
        return (
          <WatchWaitingRoom
            event={event}
            title="Video no longer available"
            subtitle="This video has been unlisted by the creator"
          />
        )
      } else if (url != null) {
        return (
          <NewEventVideoPlayer
            url={url}
            event={event}
            onEnded={onEnded}
            hasAccess={hasAccess}
          />
        )
      } else {
        if (event.get('live')) {
          return (
            <>
              <WatchWaitingRoom
                event={event}
                title="Event ended"
                subtitle="Check back in 30 minutes for the replay"
              />
              {hasAccess && (
                <WatchEventFeedback event={event} meId={me?.get('id')} />
              )}
            </>
          )
        } else {
          return (
            <BackgroundImage
              src={event.get('coverImageUrl')}
              className="NewEventVideoProcessing"
            >
              <div className="new-event-video-processing-title">
                Video is processing
              </div>
              <div className="new-event-video-processing-subtitle">
                Please check back again soon, by refreshing the page...
              </div>
            </BackgroundImage>
          )
        }
      }
    }
  }

  return (
    <>
      <BrowserSupportBanner />
      <div className={clsx('NewEventPlayer', { 'has-access': hasAccess })}>
        <div className="new-event-player-inner">{renderPlayer()}</div>
      </div>
      {isHost &&
        event.get('live') &&
        !event.get('archived') &&
        !event.get('ended') && (
          <EventHostPanel event={event} refreshEvent={refreshEvent} />
        )}
    </>
  )
}

export const NewEventVideoPlayer = ({ event, url, onEnded, hasAccess }) => {
  const me = useSelector((state) => getMe(state))
  const meId = me?.get('id')
  const eventId = event.get('id')
  const watchedAlmostAllTheWayRef = useRef(false)
  const hasPromptedEventIdForFeedback = useRef(null)
  const { onPlay } = useVideoPlayStats({
    viewerId: meId,
    eventId,
    hostId: event.getIn(['host', 'id']),
    live: false,
    videoUrl: url,
    disabled: !hasAccess,
  })

  const promptForFeedback = useCallback(
    async (ended) => {
      if (hasAccess && hasPromptedEventIdForFeedback.current !== eventId) {
        if (ended || watchedAlmostAllTheWayRef.current) {
          try {
            const feedback = await getUserFeedback({ eventId, userId: meId })
            if (!feedback) {
              hasPromptedEventIdForFeedback.current = eventId
              asyncModal(() => <WatchEventFeedback event={event} meId={meId} />)
            }
          } catch (e) {
            // Pass
          }
        }
      }
    },
    [event, meId, hasAccess, eventId, watchedAlmostAllTheWayRef]
  )
  const _onEnded = useCallback(() => {
    onEnded()
    promptForFeedback(true)
  }, [onEnded, promptForFeedback])
  useUnmount(() => promptForFeedback())
  const onTimeUpdate = useCallback(
    (player) => {
      const time = player.currentTime()
      const duration = player.duration()

      if (duration > 0 && time / duration > 0.25) {
        watchedAlmostAllTheWayRef.current = true
      }
    },
    [watchedAlmostAllTheWayRef]
  )

  return (
    <VideoPlayer
      className={clsx('NewEventVideoPlayer', { 'has-access': hasAccess })}
      key={url}
      controls
      autoplay="play"
      techOrder={['chromecast', 'html5']}
      plugins={{
        chromecast: {},
      }}
      playsinline
      width="100%"
      height="100%"
      controlBar={{
        pictureInPictureToggle: false,
      }}
      onPlay={onPlay}
      onEnded={_onEnded}
      onTimeUpdate={onTimeUpdate}
      //components={<NewEventPlayerOverlay event={event} playerRef={playerRef} />}
      sources={[
        {
          src: url,
        },
      ]}
      /*
      plugins={{
        mux: {
          debug: false,
          respectDoNotTrack: false,
          data: {
            env_key: MUX_DATA_ENV_KEY,

            player_name: 'new_event',
            player_init_time: Date.now(),
            viewer_user_id: me != null ? me?.get('id') : null,
            video_title: `new_event_${event.get('id')}`,
            video_stream_type: 'on-demand,',
          },
        },
      }}
      */
    />
  )
}

const NEW_EVENT_MENU_ITEMS = {
  INFO: 'info',
  CHAT: 'chat',
}

const NewEventMenu = ({
  isLiveEvent,
  hasAccess,
  selectedItem,
  onSelectItem,
  eventChatActive,
}) => {
  return (
    <div className="NewEventMenu">
      <div
        className={clsx('new-event-menu-item', {
          active: selectedItem === NEW_EVENT_MENU_ITEMS.INFO,
          access: true,
        })}
        onClick={() => onSelectItem(NEW_EVENT_MENU_ITEMS.INFO)}
      >
        Info
      </div>
      {isLiveEvent && (
        <div
          className={clsx('new-event-menu-item', {
            active: selectedItem === NEW_EVENT_MENU_ITEMS.CHAT,
            access: hasAccess,
            'no-access': !hasAccess,
          })}
          onClick={() => hasAccess && onSelectItem(NEW_EVENT_MENU_ITEMS.CHAT)}
        >
          Live chat
          {eventChatActive && <div className="new-event-menu-live" />}
        </div>
      )}
    </div>
  )
}
