import React from 'react'
import './CreatorMenu.scss'
import { NavLink } from 'react-router-dom'
import {
  URL_ACCOUNTING,
  URL_CREATOR_SETTINGS,
  URL_INSIGHTS_OVERVIEW,
} from 'urls'
import clsx from 'clsx'

const CreatorMenu = ({ noActive }) => {
  return (
    <div className={clsx('CreatorMenu', { noActive })}>
      <NavLink to={URL_INSIGHTS_OVERVIEW} className="creator-menu-item">
        Insights
      </NavLink>
      <NavLink to={URL_ACCOUNTING} className="creator-menu-item">
        Payouts
      </NavLink>
      <NavLink to={URL_CREATOR_SETTINGS} className="creator-menu-item">
        Settings
      </NavLink>
    </div>
  )
}

export default CreatorMenu
