import React, { useCallback, useState } from 'react'
import './UploadImageField.scss'
import { useDropzone } from 'react-dropzone'
import clsx from 'clsx'
import { CameraSvg } from 'assets/svg'
import { fixImageOrientation, uploadToS3 } from 'utils'
import { getImageUploadUrl } from 'api'
import { BackgroundImage } from 'components/Image'

const UploadImageField = ({ imageId, imageUrl, onSetUrl, onSetId }) => {
  const [isUploading, setUploading] = useState(false)
  const [error, setError] = useState(null)

  const onDrop = useCallback(
    async (acceptedFiles) => {
      setUploading(true)
      setError(null)

      const inputFile = acceptedFiles[0]

      try {
        const file = await fixImageOrientation(inputFile)
        const { url, urlFields, photoId } = await getImageUploadUrl({
          filename: inputFile.name,
        })
        await uploadToS3({
          url,
          urlFields,
          file,
        })

        onSetId(photoId)
        onSetUrl(URL.createObjectURL(file))
      } catch (e) {
        setError({
          message: (e?.message ?? 'Error uploading') + ', please try again',
        })
      }

      setUploading(false)
    },
    [onSetUrl, onSetId]
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <>
      <div
        className={clsx('UploadImageField', {
          active: isDragActive,
          uploading: isUploading,
        })}
        {...getRootProps()}
      >
        <div className="upload-image-field-row">
          <div className="upload-image-field-outer">
            <BackgroundImage
              src={imageUrl}
              className="upload-image-field-wrapper"
            >
              <div className="upload-image-field-inner">
                {!imageUrl && (
                  <div
                    className={clsx('upload-image-field-button', {
                      loading: isUploading,
                    })}
                  >
                    <CameraSvg />
                  </div>
                )}
              </div>
            </BackgroundImage>
          </div>
          <div className="upload-image-field-right">
            <button
              type="button"
              className="button secondary upload-image-field-action"
            >
              Upload image
            </button>
          </div>
        </div>
        {error && <div className="input-error">{error?.message}</div>}
        <input {...getInputProps()} className="upload-image-field-input" />
      </div>
    </>
  )
}

export default UploadImageField
