import React from 'react'
import './HeaderMenuContent.scss'
import {
  getFollowing,
  getMe,
  getSubscriptions,
  isMeCreator,
} from 'store/selectors'
import { useSelector } from 'react-redux'
import {
  URL_CREATOR,
  URL_EXTERNAL_HELP,
  URL_FOLLOWING,
  URL_INVITE_FRIENDS,
  URL_PURCHASES,
  URL_SETTINGS,
} from 'urls'
import { Link, NavLink, useLocation } from 'react-router-dom'
import Avatar from 'components/Avatar'
import { signOutStore } from 'store/utils/auth'
import { getUserUrl } from 'utils'
import CreatorMenu from 'components/CreatorMenu'
import clsx from 'clsx'

const HeaderMenuContent = () => {
  const isCreator = useSelector((state) => isMeCreator(state))
  const subscriptions = useSelector((state) => getSubscriptions(state))
  const following = useSelector((state) => getFollowing(state))

  const renderCreator = () => {
    if (!isCreator) {
      return null
    }

    return (
      <section className="header-menu-content-section">
        <HeaderMenuContentCreator />
      </section>
    )
  }

  const renderSubscriptions = () => {
    if (subscriptions == null || following == null) {
      return null
    }

    let elements = subscriptions
      .valueSeq()
      .map((s) => (
        <HeaderMenuContentSubscription
          subscription={s}
          key={`member-${s.get('id')}`}
          type="Member"
        />
      ))
    elements = elements.concat(
      following
        .filter((f) => subscriptions.get(f.get('id')) == null)
        .valueSeq()
        .map((f) => (
          <HeaderMenuContentSubscription
            subscription={f}
            key={`follower-${f.get('id')}`}
            type="Following"
          />
        ))
    )

    if (elements.size === 0) {
      return null
    }

    return <section className="header-menu-content-section">{elements}</section>
  }

  return (
    <div className="HeaderMenuContent">
      {renderCreator()}
      {renderSubscriptions()}

      <section className="header-menu-content-section">
        <div className="header-menu-content-user-options">
          <Link className="header-menu-content-link" to={URL_INVITE_FRIENDS}>
            Invite friends
          </Link>
          <Link className="header-menu-content-link" to={URL_FOLLOWING}>
            Following
          </Link>
          <Link className="header-menu-content-link" to={URL_PURCHASES}>
            Purchases
          </Link>
          {!isCreator && (
            <Link className="header-menu-content-link" to={URL_CREATOR}>
              Become a creator
            </Link>
          )}
          <Link className="header-menu-content-link" to={URL_SETTINGS}>
            Profile settings
          </Link>
          <a
            href={URL_EXTERNAL_HELP}
            className="header-menu-content-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            Help & Support
          </a>
          <span
            className="header-menu-content-link"
            onClick={() => signOutStore()}
          >
            Sign out
          </span>
        </div>
      </section>
    </div>
  )
}

export default HeaderMenuContent

const HeaderMenuContentSubscription = ({ subscription, type }) => {
  return (
    <NavLink
      className="HeaderMenuContentSubscription"
      to={getUserUrl(subscription)}
    >
      <div className="header-menu-content-subscription-avatar">
        <Avatar
          url={subscription.get('profileImageUrl')}
          userId={subscription.get('id')}
        />
      </div>
      <div className="header-menu-content-subscription-details">
        <div className="header-menu-content-subscription-name">
          {subscription.get('name')}
        </div>
        <div className="header-menu-content-subscription-type">{type}</div>
      </div>
    </NavLink>
  )
}

const HeaderMenuContentCreator = () => {
  const me = useSelector((state) => getMe(state))
  const { pathname } = useLocation()
  const creatorUrl = getUserUrl(me)

  return (
    <div
      className={clsx('HeaderMenuContentCreator', {
        active: pathname.includes(creatorUrl),
      })}
    >
      <Link className="header-menu-content-creator-profile" to={getUserUrl(me)}>
        <div className="header-menu-content-creator-profile-avatar">
          <Avatar url={me.get('profileImageUrl')} userId={me.get('id')} />
        </div>
        <div className="header-menu-content-creator-profile-details">
          <div className="header-menu-content-creator-profile-name">
            {me.get('name')}
          </div>
          <div className="header-menu-content-creator-profile-type">Owner</div>
        </div>
      </Link>
      <CreatorMenu noActive />
    </div>
  )
}
