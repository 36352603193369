import React from 'react'
import { useSelector } from 'react-redux'
import './UserPage.scss'
import { getMe } from 'store/selectors'
import { Helmet } from 'react-helmet-async'
import { useGetUserApi } from 'api'
import Spinner from 'components/Spinner'
import UserHome from './UserHome'
import UserAbout from './UserAbout'
import LoadingError from 'components/LoadingError'
import { useCatchCouponCode } from 'utils'
import CreatorProfileIncompleteBanner from 'components/CreatorProfileIncompleteBanner'
import CouponAppliedBanner from 'components/CouponAppliedBanner'
import HeaderProfile from 'components/HeaderProfile'
import { ExtendedContainer } from 'components/Grid'
import SubscriptionUnpaidBanner from 'components/SubscriptionUnpaidBanner'
import NotFoundPage from 'routes/NotFoundPage'
import UserPost from 'routes/UserPage/components/UserPost'
import { useCreatorStyles } from 'creatorColorConstants'
import InviteAppliedBanner from 'components/InviteAppliedBanner'

const UserPage = ({ match }) => {
  const userSlug = match.params.userSlug
  const inviteCodeString = match.params.inviteCode
  useCatchCouponCode(userSlug)

  const me = useSelector((state) => getMe(state))
  const { data: user, error: userError, refresh: refreshUser } = useGetUserApi(
    {
      userSlug,
    },
    [me?.get('id')]
  )
  useCreatorStyles(user)
  //const isSubscribed = useSelector(getIsSubscribed(user && user.get('id')))

  if (userError) {
    if (userError?.response?.status === 404) {
      return <NotFoundPage />
    } else {
      return (
        <LoadingError
          refresh={() => {
            refreshUser()
          }}
        />
      )
    }
  }

  if (user == null) {
    return (
      <div className="UserPage">
        <Spinner light />
      </div>
    )
  }

  const renderPage = () => {
    switch (match.path) {
      case '/:userSlug/about':
        return <UserAbout user={user} refreshUser={refreshUser} />
      case '/:userSlug/posts/:postId':
      case '/:userSlug/posts/:postId/comments/:commentId':
        return <UserPost user={user} />
      case '/:userSlug/nav/:tab':
      case '/:userSlug/host-videos':
      case '/:userSlug/categories/:categorySlug':
      case '/:userSlug/attachments/:attachmentType':
      default:
        return (
          <UserHome
            user={user}
            userSlug={userSlug}
            refreshUser={refreshUser}
            tab={match.params.tab}
          />
        )
    }
  }

  const isHome = match.path === '/:userSlug'

  return (
    <div className="UserPage">
      <Helmet>
        <title>
          {user.get('name')} - {user.get('title') || ''} | Boon
        </title>
      </Helmet>
      {user.get('id') === me?.get('id') && <CreatorProfileIncompleteBanner />}
      <InviteAppliedBanner
        userSlug={userSlug}
        inviteCodeString={inviteCodeString}
      />
      <div className="user-page-banners">
        <CouponAppliedBanner userSlug={userSlug} />
        <SubscriptionUnpaidBanner userId={user.get('id')} />
      </div>
      <HeaderProfile back={!isHome} user={user} refreshUser={refreshUser} />
      <ExtendedContainer className="user-page-container">
        <div className="user-page-content">{renderPage()}</div>
      </ExtendedContainer>
    </div>
  )
}

export default UserPage
