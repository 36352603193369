import React, { useCallback, useState } from 'react'
import './InviteFriendsPage.scss'
import { Col, Row, NarrowContainer } from 'components/Grid'
import { FilterNoneSvg } from 'assets/svg'
import { useGetInviteCodesApi } from 'api'
import { useSelector } from 'react-redux'
import { isAuthenticated } from 'store/selectors'
import { useMount } from 'react-use'
import { URL_FRONT_PAGE, URL_INVITE_FULL, URL_USER } from 'urls'
import { Link, useHistory } from 'react-router-dom'
import Avatar from 'components/Avatar/Avatar'
import Spinner from 'components/Spinner'
import clsx from 'clsx'
import { displayPrice, useMe } from 'utils'
import copy from 'copy-to-clipboard'

const InviteFriendsPage = () => {
  const history = useHistory()
  const hasAuth = useSelector((state) => isAuthenticated(state))
  useMount(() => {
    if (!hasAuth) {
      history.push(URL_FRONT_PAGE)
    }
  })
  const me = useMe()
  const { data: inviteCodes } = useGetInviteCodesApi({ userId: me.get('id') })

  const [copied, setCopied] = useState(false)

  const handleCopyClick = useCallback(
    (copyString) => {
      copy(copyString)
      setCopied(true)
      setTimeout(() => setCopied(false), 1500)
    },
    [setCopied]
  )

  const renderInviteCodes = () => {
    if (!inviteCodes) {
      return (
        <div className="spinner-container">
          <Spinner />
        </div>
      )
    }

    if (inviteCodes && inviteCodes.size === 0) {
      return (
        <div className="invite-friends-container">
          <div className="invite-friends-empty">No active memberships</div>
        </div>
      )
    }

    const renderValidCoupon = (inviteCode) => {
      return (
        <>
          <div className="invite-friends-helper-text">
            Your personal invite link
          </div>
          <div className="invite-friends-link">
            {URL_INVITE_FULL(
              inviteCode.getIn(['creator', 'username']),
              inviteCode.get('code')
            )}
          </div>
          <button
            className={clsx('button primary', { copied })}
            onClick={() => {
              handleCopyClick(
                URL_INVITE_FULL(
                  inviteCode.getIn(['creator', 'username']),
                  inviteCode.get('code')
                )
              )
            }}
          >
            {copied ? (
              'Link copied'
            ) : (
              <>
                <FilterNoneSvg />
                Copy link
              </>
            )}
          </button>
        </>
      )
    }

    const renderDisabledCoupon = () => {
      return (
        <div className="invite-friends-helper-text">
          Creator has disabled invite codes
        </div>
      )
    }

    const renderInvalidCoupon = (inviteCode) => {
      return (
        <>
          <div className="invite-friends-helper-text">
            You are currently not a member
          </div>
          <Link
            className="button primary"
            to={URL_USER(inviteCode.getIn(['creator', 'username']))}
          >
            Become member
          </Link>
        </>
      )
    }

    const renderCouponStatus = (inviteCode) => {
      if (!inviteCode.get('creatorEnabledInviteCodes')) {
        return renderDisabledCoupon(inviteCode)
      }

      if (inviteCode.get('valid')) {
        return renderValidCoupon(inviteCode)
      } else {
        return renderInvalidCoupon(inviteCode)
      }
    }

    return inviteCodes.map((inviteCode) => (
      <div className="invite-friends-container" key={inviteCode.get('id')}>
        <div className="invite-friends-link-info">
          <div className="invite-friends-creator">
            <Avatar
              url={inviteCode.getIn(['creator', 'profileImageUrl'])}
              userId={inviteCode.getIn(['creator', 'id'])}
              className="invite-friends-creator-avatar"
            />
            <div className="invite-friends-creator-username">
              {inviteCode.getIn(['creator', 'name'])}
            </div>
          </div>
          {renderCouponStatus(inviteCode)}
        </div>

        <div className="invite-friends-earned">
          <div className="invite-friends-earned-title">Total Credit Earned</div>
          <div className="invite-friends-earned-big-number">
            {displayPrice(
              inviteCode.get('creditEarned') / 100,
              inviteCode.get('currency')
            )}
          </div>
        </div>

        {inviteCode.get('redeemedBy').size > 0 && (
          <div className="invite-friends-users">
            <div className="invite-friends-users-title">
              {inviteCode.get('redeemedBy').size} accepted
              {inviteCode.get('redeemedBy').size === 1 ? ' invite' : ' invites'}
            </div>
            {inviteCode.get('redeemedBy').map((user) => {
              return (
                <div className="invite-friends-user">
                  <Avatar
                    url={user.get('profileImageUrl')}
                    userId={user.get('id')}
                    className="invite-friends-users-avatar"
                  />
                  <div className="invite-friends-user-name">
                    {user.get('name')}
                  </div>
                </div>
              )
            })}
          </div>
        )}
      </div>
    ))
  }

  return (
    <div className="InviteFriendsPage">
      <NarrowContainer>
        <Row className="justify-content-center">
          <Col xrs={12} xl={8} className="page-col">
            <div className="title">Invite Friends</div>
            <div className="sub-title">
              Give your friends 50% off their first month and receive the same
              amount for yourself
            </div>

            {renderInviteCodes()}

            <div className="title how-it-works">How it works</div>
            <div className="invite-friends-explainer">
              <div className="invite-friends-explainer-row">
                <div className="invite-friends-explainer-left">
                  <span>1</span>
                </div>
                <div className="invite-friends-explainer-right">
                  <div className="invite-friends-explainer-works-title">
                    Share your personal link
                  </div>
                  <div>Copy your link in a personal message, SMS or email.</div>
                </div>
              </div>

              <div className="invite-friends-explainer-row">
                <div className="invite-friends-explainer-left">
                  <span>2</span>
                </div>
                <div className="invite-friends-explainer-right">
                  <div className="invite-friends-explainer-works-title">
                    Your friend gets 50% off the first month
                  </div>
                  <div>
                    When your friend signs up for a membership, they get 50% off
                    the first month.
                  </div>
                </div>
              </div>

              <div className="invite-friends-explainer-row">
                <div className="invite-friends-explainer-left">
                  <span>3</span>
                </div>
                <div className="invite-friends-explainer-right">
                  <div className="invite-friends-explainer-works-title">
                    You earn the same amount as your friend saved
                  </div>
                  <div>
                    As a reward you get credited the same amount as your friend
                    has saved.
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </NarrowContainer>
    </div>
  )
}

export default InviteFriendsPage
