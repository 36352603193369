import React, { useMemo, useState } from 'react'
import './InsightsPage.scss'
import HeaderProfile from 'components/HeaderProfile'
import { getCurrencySymbol, useMe } from 'utils'
import { Link, NavLink, useRouteMatch } from 'react-router-dom'
import {
  URL_CREATOR,
  URL_CREATOR_SETTINGS_PAYOUT,
  URL_EVENT_INSIGHTS_OVERVIEW_ROUTE,
  URL_EVENT_INSIGHTS_SALES_ROUTE,
  URL_EVENT_INSIGHTS_VIEWS_ROUTE,
  URL_INSIGHTS_INCOME,
  URL_INSIGHTS_MEMBERS,
  URL_INSIGHTS_OVERVIEW,
  URL_INSIGHTS_VIDEOS,
} from 'urls'
import InsightsMembersPage from 'routes/InsightsPage/InsightsMembersPage'
import InsightsOverviewPage from 'routes/InsightsPage/InsightsOverviewPage'
import InsightsVideosPage from 'routes/InsightsPage/InsightsVideosPage'
import InsightsIncomePage from 'routes/InsightsPage/InsightsIncomePage'
import InsightsContext from 'routes/InsightsPage/InsightsContext'
import { INSIGHTS_PERIODS } from 'routes/InsightsPage/insightsConstants'
import { getInsightsQueryObject } from 'routes/InsightsPage/insightsUtils'
import queryString from 'query-string'
import EventInsightsPage from 'routes/InsightsPage/EventInsightsPage'
import LoadingError from 'components/LoadingError'
import { CREATOR_STATUS } from 'store/constants'
import { useGetInsightsCurrencyApi } from 'api'
import CreatorProfileIncompleteBanner from 'components/CreatorProfileIncompleteBanner'

const InsightsPage = () => {
  const me = useMe()
  const { path } = useRouteMatch()
  const [period, setPeriod] = useState(INSIGHTS_PERIODS.TOTAL)
  const [productsFilter, setProductsFilter] = useState(null)
  const queryObject = useMemo(
    () => getInsightsQueryObject(period, productsFilter),
    [period, productsFilter]
  )
  const queryParams = useMemo(() => queryString.stringify(queryObject), [
    queryObject,
  ])
  const { data: currency } = useGetInsightsCurrencyApi()
  const currencySymbol = getCurrencySymbol(currency)?.trim()

  const renderPage = () => {
    switch (path) {
      case URL_INSIGHTS_MEMBERS:
        return (
          <InsightsMembersPage
            user={me}
            currency={currency}
            currencySymbol={currencySymbol}
          />
        )
      case URL_INSIGHTS_INCOME:
        return (
          <InsightsIncomePage
            currency={currency}
            currencySymbol={currencySymbol}
          />
        )
      case URL_INSIGHTS_VIDEOS:
        return (
          <InsightsVideosPage
            currency={currency}
            currencySymbol={currencySymbol}
          />
        )
      case URL_EVENT_INSIGHTS_OVERVIEW_ROUTE:
      case URL_EVENT_INSIGHTS_SALES_ROUTE:
      case URL_EVENT_INSIGHTS_VIEWS_ROUTE:
        return (
          <EventInsightsPage
            currency={currency}
            currencySymbol={currencySymbol}
          />
        )
      case URL_INSIGHTS_OVERVIEW:
      default:
        return (
          <InsightsOverviewPage
            user={me}
            currency={currency}
            currencySymbol={currencySymbol}
          />
        )
    }
  }

  if (me.get('hasPayoutAccount') !== true) {
    if (me.get('creatorStatus') === CREATOR_STATUS.CREATOR) {
      return (
        <LoadingError message="To view your insights, a payout account is required">
          <Link to={URL_CREATOR_SETTINGS_PAYOUT} className="button primary">
            Go to Payout settings
          </Link>
        </LoadingError>
      )
    } else {
      return (
        <LoadingError message="You need to be a creator to view insights">
          <Link to={URL_CREATOR} className="button primary">
            Become a creator
          </Link>
        </LoadingError>
      )
    }
  }

  return (
    <InsightsContext.Provider
      value={{
        period,
        setPeriod,
        productsFilter,
        setProductsFilter,
        queryObject,
        queryParams,
      }}
    >
      <div className="InsightsPage">
        <CreatorProfileIncompleteBanner />
        <HeaderProfile user={me} />
        <div className="insights-page-menu">
          <NavLink
            exact
            to={URL_INSIGHTS_OVERVIEW}
            className="insights-page-menu-item"
          >
            Overview
          </NavLink>
          <NavLink
            exact
            to={URL_INSIGHTS_MEMBERS}
            className="insights-page-menu-item"
          >
            Relations
          </NavLink>
          <NavLink
            exact
            to={URL_INSIGHTS_INCOME}
            className="insights-page-menu-item"
          >
            Income
          </NavLink>
          <NavLink
            exact
            to={URL_INSIGHTS_VIDEOS}
            className="insights-page-menu-item"
            isActive={(match, { pathname }) =>
              match || pathname.match(/\/[^/]+\/[^/]+\/insights/)
            }
          >
            Videos
          </NavLink>
        </div>
        <div className="insights-page-content">{renderPage()}</div>
      </div>
    </InsightsContext.Provider>
  )
}

export default InsightsPage
