import React, { useMemo } from 'react'
import './InsightsNumberWidget.scss'
import InsightsGenericWidget from 'components/InsightsGenericWidget/InsightsGenericWidget'
import InsightsContentLoader from 'components/InsightsContentLoader/InsightsContentLoader'
import clsx from 'clsx'
import { ArrowDropDownSvg } from 'assets/svg'

/*
  reverse: usually up = good, but this will invert to down = good
*/
const InsightsNumberWidget = ({
  title,
  timeScale,
  value,
  previousValue,
  prefix,
  suffix,
  reverse = false,
  loading,
  error,
  customNumberFormatter = null,
}) => {
  const numberFormatter = useMemo(
    () =>
      customNumberFormatter ||
      Intl.NumberFormat(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: value < 1000 ? 2 : 1,
        notation: 'compact',
        compactDisplay: 'short',
      }),
    [customNumberFormatter, value]
  )

  const renderValue = () => {
    return (
      <div className="insights-number-widget-value" data-testid="insight-value">
        {prefix}
        {numberFormatter.format(value)}
        {suffix}
      </div>
    )
  }

  const renderChange = () => {
    if (
      previousValue == null ||
      previousValue <= 1e-10 ||
      isNaN(previousValue) ||
      value === previousValue
    ) {
      return <div className="insights-number-widget-no-change" />
    }

    const up = value > previousValue
    const negative = reverse ? up : !up
    const changePercent = Math.round(
      Math.abs((value - previousValue) / previousValue) * 100
    )

    return (
      <div
        className={clsx('insights-number-widget-change', {
          'insights-number-widget-change-up': up,
          'insights-number-widget-change-negative': negative,
        })}
        data-testid="insight-change"
      >
        <ArrowDropDownSvg /> {changePercent}%
      </div>
    )
  }

  const renderContent = () => {
    if (error) {
      return (
        <div
          className="insights-number-widget-error"
          data-testid="insight-error"
        >
          <div>Loading failed</div>
          <div>Please try again later</div>
        </div>
      )
    } else if (loading) {
      return (
        <InsightsContentLoader speed={1} width={120} height={88}>
          <rect x="0" y="24" rx="12" ry="12" width="120" height="24" />
          <rect x="0" y="68" rx="6" ry="6" width="80" height="12" />
        </InsightsContentLoader>
      )
    } else {
      return (
        <>
          {renderValue()}
          {renderChange()}
        </>
      )
    }
  }

  return (
    <InsightsGenericWidget title={title} timeScale={timeScale}>
      <div className="InsightsNumberWidget">{renderContent()}</div>
    </InsightsGenericWidget>
  )
}

export default InsightsNumberWidget
