import React, { useContext } from 'react'
import { Col, Row } from 'components/Grid'
import { useGetApi } from 'api'
import InsightsFilters from 'routes/InsightsPage/InsightsFilters'
import { INSIGHTS_PERIODS_NAMES } from 'routes/InsightsPage/insightsConstants'
import InsightsContext from 'routes/InsightsPage/InsightsContext'
import InsightsConnectedChartWidget from 'components/InsightsChartWidget/InsightsConnectedChartWidget'
import InsightsConnectedTableWidget from 'components/InsightsTableWidget/InsightsConnectedTableWidget'
import InsightsSimpleNumberWidget from 'components/InsightsSimpleNumberWidget'
import InsightsTableDataElement from 'components/InsightsTableWidget/InsightsTableDataElement'

export const viewsChartDataMapper = (data) =>
  data.map((h) => ({ x: new Date(h['key_as_string']), y: h['doc_count'] }))

const EventInsightsViewsPage = ({ eventId }) => {
  const { period, queryParams } = useContext(InsightsContext)
  const { data, loading, error } = useGetApi({
    url: `insights/members/details/${eventId}`,
  })

  const {
    viewers,
    views,
    finishes,
    total_view_time,
    ratings,
    avg_view_time,
    avg_percent_watched,
    avg_rating,
  } = data ?? {}

  const renderNumberWidgets = () => {
    return (
      <Row className="insights-event-page-views-top">
        <Col lg={2} md={6}>
          <InsightsSimpleNumberWidget
            title={'Viewers'}
            value={viewers}
            loading={loading}
            error={error}
          />
        </Col>
        <Col lg={2} md={6}>
          <InsightsSimpleNumberWidget
            title={'Views'}
            value={views}
            loading={loading}
            error={error}
          />
        </Col>
        <Col lg={2} md={6}>
          <InsightsSimpleNumberWidget
            title={'Finishes'}
            value={finishes}
            loading={loading}
            error={error}
          />
        </Col>
        <Col lg={2} md={6}>
          <InsightsSimpleNumberWidget
            title={'View time'}
            value={
              <InsightsTableDataElement
                data={{ data: total_view_time }}
                header={{ key: 'data', type: 'timespan' }}
              />
            }
            loading={loading}
            error={error}
            useFormatter={false}
          />
        </Col>
        <Col lg={2} md={6}>
          <InsightsSimpleNumberWidget
            title={'Avg. View time'}
            value={
              <InsightsTableDataElement
                data={{ data: avg_view_time }}
                header={{ key: 'data', type: 'timespan' }}
              />
            }
            loading={loading}
            error={error}
            useFormatter={false}
          />
        </Col>
        <Col lg={2} md={6}>
          <InsightsSimpleNumberWidget
            title={'Avg. % watched'}
            value={
              <InsightsTableDataElement
                data={{ data: avg_percent_watched }}
                header={{ key: 'data', type: 'percent' }}
              />
            }
            loading={loading}
            error={error}
            useFormatter={false}
          />
        </Col>
        <Col lg={2} md={6}>
          <InsightsSimpleNumberWidget
            title={'Ratings'}
            value={ratings}
            loading={loading}
            error={error}
          />
        </Col>
        <Col lg={2} md={6}>
          <InsightsSimpleNumberWidget
            title={'Avg. Rating'}
            value={
              <InsightsTableDataElement
                data={{ data: avg_rating }}
                header={{ key: 'data', type: 'rating' }}
              />
            }
            loading={loading}
            error={error}
            useFormatter={false}
          />
        </Col>
      </Row>
    )
  }

  const renderSelectors = () => {
    return <InsightsFilters filterProducts />
  }

  const renderCharts = () => {
    return (
      <Row className="insights-members-charts">
        <Col xs={12}>
          <InsightsConnectedChartWidget
            title="Views"
            timeScale={INSIGHTS_PERIODS_NAMES[period]}
            url={`insights/members/total_views_histogram?event_id=${eventId}&${queryParams}`}
            dataMapper={viewsChartDataMapper}
            chartType={'bar'}
          />
        </Col>
      </Row>
    )
  }
  const renderTable = () => {
    const headers = [
      { key: 'name', type: 'profile', active: true },
      { key: 'email', type: 'string', active: true },
      {
        key: 'creator_consent',
        name: 'Email Consent',
        type: 'yesno',
        active: false,
      },
      {
        key: 'views',
        type: 'integer',
        active: true,
      },
      { key: 'finishes', type: 'integer', active: true },
      {
        key: 'total_view_time',
        name: 'View Time',
        type: 'timespan',
        active: true,
      },
      {
        key: 'avg_view_time',
        name: 'Avg. View Time',
        type: 'timespan',
        active: true,
      },
      {
        key: 'avg_percent_watched',
        name: 'Avg. % Watched',
        type: 'percent',
        active: true,
      },
      { key: 'rating_date', type: 'date', active: true },
      { key: 'rating', type: 'rating', active: true },
      { key: 'feedback', type: 'text', active: true },
    ]
    const availableFilters = [
      { value: 'All Rated', active: false },
      { value: '5 star', active: false },
      { value: '4 star', active: false },
      { value: '3 star', active: false },
      { value: '2 star', active: false },
      { value: '1 star', active: false },
    ]
    return (
      <Row>
        <Col xs={12}>
          <InsightsConnectedTableWidget
            title="Views"
            baseUrl={`insights/members/event_table/${eventId}`}
            headers={headers}
            dataKey={'users'}
            defaultSortBy={'name asc'}
            defaultFilters={availableFilters}
          />
        </Col>
      </Row>
    )
  }

  return (
    <div className="EventInsightsViewsPage">
      {renderNumberWidgets()}
      {renderSelectors()}
      {renderCharts()}
      {renderTable()}
    </div>
  )
}

export default EventInsightsViewsPage
