import { SET_AUTH_MODAL, SET_HEADER_DARK, SET_NOT_FOUND } from 'store/constants'
import { UiSchema } from 'store/schemas'
import * as persistTypes from 'redux-persist-immutable/constants'

const initialState = UiSchema()

const BaseUpdateModal = (payload, keys) => (current) => {
  for (const key in payload) {
    if (payload[key] !== undefined && keys.includes(key)) {
      current = current.set(key, payload[key])
    }
  }
  return current
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case persistTypes.REHYDRATE:
      return state.set('isHydrated', true)

    case SET_AUTH_MODAL:
      return state.updateIn(
        ['modals', 'auth'],
        BaseUpdateModal(action.payload, [
          'isActive',
          'newlyCreated',
          'state',
          'message',
        ])
      )

    case SET_HEADER_DARK:
      return state.setIn(['header', 'dark'], action.payload)

    case SET_NOT_FOUND:
      return state.set('isNotFound', action.payload)

    default:
      return state
  }
}

export default reducer
