import React, { useCallback, useState } from 'react'
import './RecentNotifications.scss'
import { getApiError, useInfiniteScrollBody, useMe } from 'utils'
import {
  getNotificationsApi,
  markAllNotificationsAsReadApi,
  markNotificationAsReadApi,
} from 'api'
import Spinner from 'components/Spinner'
import Notification from 'components/Notification'
import { List } from 'immutable'
import { useAsync } from 'react-use'

const RecentNotifications = ({ onClose, countRefresh }) => {
  const me = useMe()
  const meId = me.get('id')
  const [nextPage, setNextPage] = useState(null)
  const [notifications, setNotifications] = useState(List())
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [hasMore, setHasMore] = useState(false)
  const [page, bottomRef, incrementPage] = useInfiniteScrollBody({
    hasMore,
    rootMargin: '100px 0px 0px 0px',
    threshold: 0,
  })

  const loadNotifications = useCallback(async () => {
    setError(null)
    setLoading(true)
    const params = {}

    if (nextPage != null) {
      params['page'] = nextPage
    }

    let data
    try {
      data = await getNotificationsApi({
        userId: meId,
        params,
      })
    } catch (e) {
      setError(getApiError(e))
      setLoading(false)
      return null
    }

    setNotifications((oldNotifications) => {
      return oldNotifications.concat(data.get('notifications'))
    })
    setNextPage(data.getIn(['meta', 'nextPage']))
    setHasMore(data.getIn(['meta', 'nextPage']) != null)
    setLoading(false)
  }, [meId, nextPage, setNotifications])
  useAsync(async () => {
    loadNotifications()
  }, [page])

  const reloadNotifications = useCallback(() => {
    setNextPage(null)
    setNotifications(List())
    incrementPage()
  }, [setNotifications, incrementPage])

  const onMarkAllAsRead = useCallback(async () => {
    await markAllNotificationsAsReadApi({ userId: meId })
    countRefresh()
    reloadNotifications()
  }, [meId, countRefresh, reloadNotifications])

  const onNotificationClick = useCallback(
    async (notificationId, opened) => {
      onClose()
      if (!opened) {
        await markNotificationAsReadApi({ userId: meId, notificationId })
        countRefresh()
      }
    },
    [meId, onClose, countRefresh]
  )

  const renderNotifications = () => {
    if (error) {
      return (
        <div className="recent-notifications-error">
          <div className="recent-notifications-error-message">
            We encountered an error loading your notifications, please try again
          </div>

          <button
            className="button secondary light small"
            onClick={reloadNotifications}
          >
            Try again
          </button>
        </div>
      )
    }

    if (notifications.size === 0 && loading) {
      return <Spinner light small />
    }

    if (notifications.size === 0) {
      return (
        <div className="recent-notifications-empty">
          You have no recent notifications
        </div>
      )
    }

    const components = notifications.map((notification) => (
      <Notification
        notification={notification}
        key={notification.get('id')}
        onClick={onNotificationClick}
      />
    ))

    return <>{components}</>
  }

  return (
    <div className="RecentNotifications">
      <div className="recent-notifications-header">
        <div className="recent-notifications-header-title">Notifications</div>
        <div
          className="recent-notifications-header-mark-read"
          onClick={onMarkAllAsRead}
        >
          Mark all as read
        </div>
      </div>
      <div className="recent-notifications-content">
        {renderNotifications()}
        {notifications.size > 0 && hasMore && (
          <div className="recent-notifications-spinner" ref={bottomRef}>
            <Spinner light small />
          </div>
        )}
      </div>
    </div>
  )
}

export default RecentNotifications
