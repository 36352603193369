import React from 'react'
import './SubscribePageHeader.scss'
import { Image } from 'components/Image'

const SubscribePageHeader = ({ checkout }) => {
  return (
    <div className="SubscribePageHeader">
      <Image
        className="subscribe-page-header-image"
        width={56}
        height={56}
        src={
          checkout.getIn(['product', 'productImageUrl']) ||
          checkout.getIn(['host', 'profileImageUrl'])
        }
      />
      <div className="subscribe-page-header-title">
        {checkout.getIn(['product', 'name'])}
      </div>
      <div className="subscribe-page-header-subtitle">
        {checkout.getIn(['host', 'name'])}
      </div>
    </div>
  )
}

export default SubscribePageHeader
