import React, { useCallback, useState } from 'react'
import './CheckoutProfile.scss'
import { Form, Formik, useFormikContext } from 'formik'
import { fixImageOrientation, getApiError, uploadToS3, useMe } from 'utils'
import Avatar from 'components/Avatar'
import clsx from 'clsx'
import { CameraSvg } from 'assets/svg'
import { getImageUploadUrl, getMeObject, updateUser } from 'api'
import { BoonFormikTextField } from 'components/BoonInputs'
import StyledApiErrorMessage from 'components/StyledApiErrorMessage'
import { updateMeStore } from 'store/utils/auth'

const CheckoutProfile = ({ onNext }) => {
  const me = useMe()
  const meId = me.get('id')

  const onSubmit = useCallback(
    async (values, { setErrors, setSubmitting }) => {
      const data = {
        name: values.name,
        profileImageId: values.profileImageId,
        nameIsSetAt: new Date().toISOString(),
      }

      try {
        await updateUser({ id: meId, data })
        const apiMe = await getMeObject()
        updateMeStore({ me: apiMe })
      } catch (e) {
        setErrors(getApiError(e))
        return null
      }

      setSubmitting(false)
      onNext()
    },
    [onNext, meId]
  )

  const initialValues = {
    name: me.get('name'),
    profileImageUrl:
      me.get('profileImageUrl') ===
      'https://s3.eu-central-1.amazonaws.com/boon-email.prod/img/avatar-placeholder.png'
        ? null
        : me.get('profileImageUrl'),
  }

  return (
    <div className="CheckoutProfile">
      <div className="checkout-profile-title">Complete your profile</div>

      {/*<div className="checkout-profile-intro">
        A big part of Boon is the community and getting to know each other a
        bit. How do you like to appear?
      </div>*/}

      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        <CheckoutProfileForm />
      </Formik>
    </div>
  )
}

export default CheckoutProfile

const CheckoutProfileForm = () => {
  const me = useMe()
  const [isUploading, setUploading] = useState(false)
  const { values, isSubmitting, isValid, setFieldValue } = useFormikContext()

  return (
    <Form>
      <div className="checkout-profile-avatar-row">
        <label
          className="checkout-profile-avatar"
          htmlFor="checkoutProfileAvatar"
        >
          <Avatar url={values.profileImageUrl} userId={me.get('id')} />
          <div
            className={clsx(
              'checkout-profile-photo-button checkout-profile-avatar-upload',
              {
                loading: isUploading,
              }
            )}
          >
            <CameraSvg />
          </div>
        </label>
      </div>
      <input
        type="file"
        onChange={async (e) => {
          try {
            setUploading(true)
            const inputFile = e.target.files[0]
            const file = await fixImageOrientation(inputFile)
            const { url, urlFields, photoId } = await getImageUploadUrl({
              filename: inputFile.name,
            })
            await uploadToS3({
              url,
              urlFields,
              file,
            })

            setFieldValue('profileImageFile', file)
            setFieldValue('profileImageUrl', URL.createObjectURL(file))
            setFieldValue('profileImageId', photoId)
            setUploading(false)
          } catch (e) {
            setUploading(false)
          }
        }}
        id="checkoutProfileAvatar"
      />

      <BoonFormikTextField
        name="name"
        label="Nickname"
        helperText="Choose a name people know you by or use your real name."
      />

      <StyledApiErrorMessage />
      <button
        type="submit"
        disabled={isSubmitting || !isValid}
        className={clsx(
          'button checkout-profile-submit primary medium loader',
          {
            loading: isSubmitting,
          }
        )}
      >
        Continue
      </button>
    </Form>
  )
}
