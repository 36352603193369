import React, { useCallback } from 'react'
import './PostComposeForm.scss'
import { getDocumentUploadUrl, getImageUploadUrl } from 'api'
import { fixImageOrientation, getIsUserAdmin, uploadToS3, useMe } from 'utils'
import { Form, useFormikContext } from 'formik'
import PostComposeModal from './PostComposeModal'

const PostComposeForm = ({
  children,
  isEdit,
  modalActive,
  setModalActive,
  hostId,
  user,
}) => {
  const { setFieldValue, dirty, submitCount, resetForm } = useFormikContext()
  const me = useMe()
  const isAdmin = user != null ? getIsUserAdmin(user) : hostId === me.get('id')

  const onPhotoSelect = async (e) => {
    setFieldValue('attachmentUploading', true)
    setFieldValue('attachmentType', 'Photo')
    setModalActive(true)
    const inputFile = e.target.files[0]

    try {
      const file = await fixImageOrientation(inputFile)
      const { url, urlFields, photoId } = await getImageUploadUrl({
        filename: inputFile.name,
      })
      await uploadToS3({
        url,
        urlFields,
        file,
      })

      setFieldValue('attachmentPhotoUrl', URL.createObjectURL(file))
      setFieldValue('attachmentId', photoId)
    } catch (e) {
      // Pass
      setFieldValue('attachmentType', null)
    }

    setFieldValue('attachmentUploading', false)
  }

  const onDocumentSelect = async (e) => {
    const inputFile = e.target.files[0]
    setFieldValue('attachmentUploading', true)
    setFieldValue('attachmentUploadingProgress', 0)
    setFieldValue('attachmentType', 'Document')
    setFieldValue('attachmentDocumentFilename', inputFile.name)
    setModalActive(true)

    try {
      const file = await fixImageOrientation(inputFile)
      const { url, urlFields, documentId } = await getDocumentUploadUrl({
        filename: inputFile.name,
      })
      await uploadToS3({
        url,
        urlFields,
        file,
        options: {
          onUploadProgress: (progressEvent) => {
            setFieldValue(
              'attachmentUploadingProgress',
              Math.round((progressEvent.loaded * 100) / progressEvent.total)
            )
          },
        },
      })

      setFieldValue('attachmentId', documentId)
    } catch (e) {
      // Pass
      setFieldValue('attachmentType', null)
      setFieldValue('attachmentDocumentFilename', null)
    }

    setFieldValue('attachmentUploading', false)
  }

  const onClose = useCallback(() => {
    if (dirty && submitCount === 0) {
      if (
        window.confirm(
          "You haven't saved your post yet, are you sure you want to leave without saving?"
        )
      ) {
        setModalActive(false)
        resetForm()
      }
    } else {
      setModalActive(false)
    }
  }, [setModalActive, dirty, submitCount, resetForm])

  return (
    <Form className="PostComposeForm">
      {children}
      <input
        type="file"
        onChange={onPhotoSelect}
        id={isEdit ? 'postComposeUpdatePhoto' : 'postComposePhoto'}
      />
      <input
        type="file"
        onChange={onDocumentSelect}
        id={isEdit ? 'postComposeUpdateDocument' : 'postComposeDocument'}
      />
      <PostComposeModal
        isEdit={isEdit}
        active={modalActive}
        isAdmin={isAdmin}
        onClose={onClose}
        user={user}
      />
    </Form>
  )
}

export default PostComposeForm
