import React, { useState } from 'react'
import './FeedFilter.scss'

import clsx from 'clsx'
import { useBrowser } from 'utils'

import NamedAnimatedModal from 'components/NamedAnimatedModal'
import { TuneSvg, VisibilityOffSvg, EyeSvg, BookmarkSvg } from 'assets/svg'
import { isAuthenticated } from 'store/selectors'
import { useSelector } from 'react-redux'

const watchedFilter = { group: 'watched', key: 'true', name: 'Watched' }
const unwatchedFilter = { group: 'watched', key: 'false', name: 'Unwatched' }
const savedFilter = { group: 'saved', key: 'true', name: 'Saved' }

const filters = [
  { group: 'postType', key: 'unlisted', name: 'Unlisted' },
  { group: 'postType', key: 'draft', name: 'Draft' },
  { group: 'postType', key: 'test', name: 'Test' },
  { group: 'postType', key: 'scheduled', name: 'Scheduled' },
  { group: 'videoType', key: 'live', name: 'Live' },
  { group: 'videoType', key: 'uploaded', name: 'Uploaded' },
  watchedFilter,
  unwatchedFilter,
  savedFilter,
]

const FeedFilter = ({
  activeFilters,
  categories,
  dispatchFilter,
  isCreator,
  activeTab,
  postCount,
}) => {
  const browser = useBrowser()
  const [modalOpen, setModalOpen] = useState(false)
  const activeFilterCount = activeFilters.filter(
    (f, key) => f != null && key !== 'productId'
  ).size
  const isFiltering = activeFilterCount > 0
  const hasAuth = useSelector((state) => isAuthenticated(state))

  const showEventFilters = () => {
    return ['Activity', 'Videos'].includes(activeTab)
  }

  const renderModal = () => {
    return (
      <NamedAnimatedModal
        active={modalOpen}
        className="FeedFilterModal"
        overlay
        onClose={() => setModalOpen(false)}
        title={`Filter (${activeFilterCount})`}
      >
        {isCreator && showEventFilters() && (
          <>
            <div className="button-group">
              {filters
                .filter((filter) => filter.group === 'postType')
                .map((filter) => (
                  <button
                    onClick={() => dispatchFilter(filter)}
                    key={filter.name}
                    className={clsx('button secondary button-filter-shortcut', {
                      active: filter.key === activeFilters.get('postType'),
                    })}
                  >
                    {filter.name}
                  </button>
                ))}
            </div>
            <hr />
          </>
        )}

        {showEventFilters() && (
          <>
            <div className="button-group">
              {filters
                .filter((filter) => filter.group === 'videoType')
                .map((filter) => (
                  <button
                    onClick={() => dispatchFilter(filter)}
                    key={filter.name}
                    className={clsx('button secondary button-filter-shortcut', {
                      active: filter.key === activeFilters.get('videoType'),
                    })}
                  >
                    {filter.name}
                  </button>
                ))}
            </div>
            <hr />
          </>
        )}

        {showEventFilters() && (
          <div className="button-group">
            {filters
              .filter((filter) => filter.group === 'watched')
              .map((filter) => (
                <button
                  onClick={() => dispatchFilter(filter)}
                  key={filter.name}
                  className={clsx('button secondary button-filter-shortcut', {
                    active: filter.key === activeFilters.get('watched'),
                  })}
                >
                  {filter.name === 'Watched' ? (
                    <EyeSvg />
                  ) : (
                    <VisibilityOffSvg />
                  )}
                  {filter.name}
                </button>
              ))}
          </div>
        )}

        {showEventFilters() && hasAuth && (
          <div className="button-group">
            {filters
              .filter((filter) => filter.group === 'saved')
              .map((filter) => (
                <button
                  onClick={() => dispatchFilter(filter)}
                  key={filter.name}
                  className={clsx('button secondary button-filter-shortcut', {
                    active: filter.key === activeFilters.get('saved'),
                  })}
                >
                  <BookmarkSvg />
                  {filter.name}
                </button>
              ))}
          </div>
        )}

        <button
          className="button creator-primary filter-done-button"
          onClick={() => setModalOpen(false)}
        >
          Done ({postCount})
        </button>
      </NamedAnimatedModal>
    )
  }

  return (
    <div className={clsx('FeedFilter', `feed-filter-active-${activeTab}`)}>
      {renderModal()}
      {browser.greaterThan.md && (
        <>
          <button
            className={clsx('button secondary button-filter-shortcut', {
              active: activeFilters.get('watched') === watchedFilter.key,
            })}
            onClick={() => dispatchFilter(watchedFilter)}
          >
            <EyeSvg /> Watched
          </button>
          <button
            className={clsx('button secondary button-filter-shortcut', {
              active: activeFilters.get('watched') === unwatchedFilter.key,
            })}
            onClick={() => dispatchFilter(unwatchedFilter)}
          >
            <VisibilityOffSvg /> Unwatched
          </button>
        </>
      )}

      {hasAuth && (
        <button
          className={clsx('button secondary button-filter-shortcut', {
            active: activeFilters.get('saved') === savedFilter.key,
          })}
          onClick={() => dispatchFilter(savedFilter)}
        >
          <BookmarkSvg />
          {browser.greaterThan.md && 'Saved'}
        </button>
      )}

      <button
        className={clsx('button secondary', { active: isFiltering })}
        onClick={() => setModalOpen(true)}
      >
        <TuneSvg />
        {isFiltering > 0 && (
          <span className="filter-active-filter-count">
            {activeFilterCount}
          </span>
        )}
      </button>
    </div>
  )
}

export default FeedFilter
