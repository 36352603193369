import React from 'react'
import './PostCardHeader.scss'
import { getFormattedActivityDate } from 'utils'
import { POST_ACCESS, POST_ATTACHMENT_TYPES } from 'store/constants/post'
import PostOptions from 'components/PostOptions'
import PostCardLock from 'components/PostCard/PostCardLock'

import EventInsightsMenu from 'routes/InsightsPage/EventInsightsMenu'
import Bookmark from 'components/Bookmark'
import RelationAvatar from 'components/RelationAvatar/RelationAvatar'

const PostCardHeader = ({
  post,
  event = null,
  onNoAccess,
  onPostDeleted,
  onPostUpdated,
  showOptions,
  hostId,
}) => {
  const isAdmin =
    (post && post.get('access') === POST_ACCESS.ADMIN) || event?.get('isDraft')
  const showAdminOptions = isAdmin && showOptions
  const isEvent =
    event !== null ||
    post?.get('attachmentType') === POST_ATTACHMENT_TYPES.EVENT
  const date = getFormattedActivityDate(
    post?.get('createdAt') || event?.get('createdAt')
  )

  const renderInner = () => {
    return (
      <>
        <RelationAvatar
          className="post-card-header-user-avatar"
          url={post.getIn(['user', 'profileImageUrl'])}
          userId={post.getIn(['user', 'id'])}
          hostId={hostId}
        />
        <div className="post-card-header-info">
          <div className="post-card-header-user-name">
            {post.getIn(['user', 'name'])}
          </div>
          <div className="post-card-header-details">
            <div className="post-card-header-date">Posted {date}</div>
            <PostCardLock
              className="post-card-header-lock"
              post={post}
              onNoAccess={onNoAccess}
            />
          </div>
        </div>
      </>
    )
  }

  return (
    <div className="PostCardHeader">
      {post && renderInner()}
      <div className="post-card-header-right">
        {showAdminOptions && !isEvent && (
          <PostOptions
            post={post}
            onPostDeleted={onPostDeleted}
            onPostUpdated={onPostUpdated}
            hostId={hostId}
          />
        )}
        {isEvent && (
          <Bookmark
            active={event?.get('bookmarked')}
            eventId={event?.get('id')}
            isHost={true}
          />
        )}
        {showAdminOptions && isEvent && (
          <EventInsightsMenu
            eventId={post?.getIn(['attachment', 'id']) || event?.get('id')}
            eventTitle={
              post?.getIn(['attachment', 'title']) || event?.get('title')
            }
            eventLive={
              post?.getIn(['attachment', 'live']) || event?.get('live')
            }
            eventArchived={
              post?.getIn(['attachment', 'archived']) || event?.get('archived')
            }
            eventPage
          />
        )}
      </div>
    </div>
  )
}

export default PostCardHeader
