import React from 'react'
import { INSIGHTS_PERIODS } from 'routes/InsightsPage/insightsConstants'

const InsightsContext = React.createContext({
  period: INSIGHTS_PERIODS.TOTAL,
  setPeriod: () => null,
  productsFilter: null,
  setProductsFilter: () => null,
  queryObject: {},
  queryParams: '',
})
export default InsightsContext
