import React, { useCallback, useRef, useState } from 'react'
import './PostCardAttachmentViewSpecific.scss'
import PostCardHeader from './PostCardHeader'
import PostCardMessage from './PostCardMessage'
import PostCardEngagement from './PostCardEngagement'
import PostCardActions from './PostCardActions'
import PostComments from 'components/PostComments'
import CheckoutModal from 'modals/CheckoutModal'

const PostCardAttachmentViewSpecific = ({ post, hostId, dispatchPosts }) => {
  const commentInputRef = useRef()

  const [showCheckout, setShowCheckout] = useState(false)
  const [checkoutTitle, setCheckoutTitle] = useState(null)
  const onNoAccess = useCallback((title) => {
    setShowCheckout(true)
    setCheckoutTitle(title)
  }, [])

  const [showCommentCompose, setShowCommentCompose] = useState(false)

  const onCheckoutClose = useCallback(() => {
    setShowCheckout(false)
  }, [setShowCheckout])

  return (
    <div className="PostCardAttachmentViewSpecific">
      <CheckoutModal
        active={showCheckout}
        title={checkoutTitle}
        hostId={hostId}
        onClose={onCheckoutClose}
        productIds={post.get('productIds')}
      />
      <PostCardHeader post={post} onNoAccess={onNoAccess} />
      <PostCardMessage post={post} />
      <PostCardEngagement post={post} />
      <PostCardActions
        post={post}
        dispatchPosts={dispatchPosts}
        commentInputRef={commentInputRef}
        onNoAccess={onNoAccess}
        setShowCommentCompose={setShowCommentCompose}
      />
      <PostComments
        post={post}
        dispatchPosts={dispatchPosts}
        commentInputRef={commentInputRef}
        onNoAccess={onNoAccess}
        showCompose={showCommentCompose}
      />
    </div>
  )
}

export default React.memo(PostCardAttachmentViewSpecific)
