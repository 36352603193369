import React, { useRef, useState } from 'react'
import { createPollOptionApi } from 'api'
import { Map } from 'immutable'
import {
  SELECT_ONLY_OPTION,
  SELECT_OPTION,
} from 'components/Poll/pollBasicConstants'
import { AddSvg } from 'assets/svg'
import clsx from 'clsx'
import './PollBasicAddOption.scss'

export const PollBasicAddOption = ({
  pollId,
  poll,
  onPostAttachmentUpdate,
  dispatchOption,
  multipleVotesPerUser,
}) => {
  const inputRef = useRef()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [message, setMessage] = useState('')

  const onSubmit = async () => {
    if (message.length === 0) {
      return null
    }

    setLoading(true)
    setError(false)

    let pollOption
    try {
      pollOption = await createPollOptionApi({ pollId, data: { message } })
    } catch (e) {
      setError(true)
      setLoading(false)
    }

    onPostAttachmentUpdate(
      Map({
        pollOptions: poll.get('pollOptions').push(pollOption),
      })
    )
    dispatchOption({
      type: multipleVotesPerUser ? SELECT_OPTION : SELECT_ONLY_OPTION,
      id: pollOption.get('id'),
    })
    setLoading(false)
    setMessage('')
  }

  return (
    <>
      <div className="PollBasicAddOption">
        <AddSvg
          className="poll-basic-add-option-icon"
          onClick={() => inputRef.current && inputRef.current.focus()}
        />
        <input
          id="addPollItem"
          type="text"
          ref={inputRef}
          className="poll-basic-add-option-input"
          value={message}
          onKeyDown={(e) => {
            if (e.keyCode === 13 && e.shiftKey === false) {
              onSubmit()
            }
          }}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Add option..."
        />
        <button
          className={clsx(
            'button small primary load light poll-basic-add-option-button',
            { loading, hidden: message.length === 0 }
          )}
          disabled={loading}
          onClick={onSubmit}
        >
          Add
        </button>
      </div>
      {error && (
        <div className="input-error">An error occurred, please try again</div>
      )}
    </>
  )
}
