import React, { useState } from 'react'
import './TestSignInPage.scss'
import { useLocation } from 'react-router-dom'
import { useMount } from 'react-use'
import queryString from 'query-string'
import { signInStore } from 'store/utils/auth'

const TestSignInPage = () => {
  const location = useLocation()
  const [signedIn, setSignedIn] = useState(false)

  useMount(async () => {
    const { email, password } = queryString.parse(location.search, {
      decode: false,
    })
    await signInStore({ email, password })

    setSignedIn(true)
  })

  return (
    <div className="TestSignInPage">
      {signedIn && <span data-testid="test-sign-in-page-successful" />}
    </div>
  )
}

export default TestSignInPage
