import React from 'react'
import Modal from 'components/Modal'
import './PinnedModal.scss'
import clsx from 'clsx'
import { CSSTransition } from 'react-transition-group'

const PinnedModal = ({ onClose, className, children, active }) => {
  return (
    <CSSTransition
      in={active}
      timeout={250}
      appear={true}
      mountOnEnter
      unmountOnExit
      classNames="PinnedModal"
    >
      <Modal
        onClose={onClose}
        overlay
        className={clsx('PinnedModal', className)}
      >
        <div className="pinned-modal-content">{children}</div>
      </Modal>
    </CSSTransition>
  )
}

export default PinnedModal
