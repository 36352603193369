import store from 'store'
import { signIn, signInFacebook, signUp } from 'api'
import { setAuth, clearAuth, updateMe, mergeMe } from 'store/actions'

export const signInStore = ({ email, password }) => {
  return signIn({ email, password }).then((response) => {
    store.dispatch(setAuth(response))
  })
}

export const signInFacebookStore = ({
  accessToken,
  exchangeCode,
  redirectUri,
}) => {
  return signInFacebook({ accessToken, exchangeCode, redirectUri }).then(
    (response) => {
      store.dispatch(setAuth(response))
      return response
    }
  )
}

export const signUpStore = ({ realName, name, email, password }) => {
  return signUp({ realName, name, email, password }).then((response) => {
    store.dispatch(setAuth(response))
    return response
  })
}

export const signOutStore = () => {
  return store.dispatch(clearAuth())
}

export const updateMeStore = ({ me }) => {
  return store.dispatch(updateMe(me))
}

export const mergeMeStore = ({ me }) => {
  return store.dispatch(mergeMe(me))
}
