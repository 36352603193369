import React from 'react'
import './InviteAppliedBanner.scss'
import { useGetInviteCode } from 'utils'
import Avatar from 'components/Avatar/Avatar'
import { ErrorOutlineSvg } from 'assets/svg'

const InviteAppliedBanner = ({ userSlug, inviteCodeString }) => {
  const { inviteCode, error } = useGetInviteCode(userSlug, inviteCodeString)

  if (error != null) {
    return (
      <div className="InviteAppliedBanner error">
        <div className="invite-applied-banner-container error">
          <ErrorOutlineSvg />
          <div>
            Invite code{' '}
            {error['error'] === 'NO_COUPON' ? 'not found!' : 'not valid!'}
          </div>
        </div>
      </div>
    )
  }
  if (inviteCode == null) {
    return null
  }

  return (
    <div className="InviteAppliedBanner">
      <div className="invite-applied-banner-container">
        <Avatar
          url={inviteCode.getIn(['user', 'profileImageUrl'])}
          userId={inviteCode.getIn(['user', 'id'])}
          className="invite-applied-banner-avatar"
        />
        <div className="invite-applied-banner-info">
          <div className="invite-applied-banner-headline">
            Invite from {inviteCode.getIn(['user', 'name'])}
          </div>
          <div className="invite-applied-banner-subtext">
            Get 50% off your first month, when you join a membership
          </div>
        </div>
      </div>
    </div>
  )
}

export default InviteAppliedBanner
