import React from 'react'
import './BoonTextField.scss'
import clsx from 'clsx'
import InfoTooltip from 'components/InfoTooltip'
import { useField } from 'formik'

export const BoonTextField = ({
  field = {},
  meta = {},
  dark,
  helperText,
  prepend,
  append,
  tooltip,
  label,
  type,
  addonMainColor = false,
  placeholder,
  multiline = false,
  required,
  disabled,
  underText,
}) => {
  const renderTooltip = () => {
    if (tooltip == null) {
      return null
    }

    return (
      <div className="boon-text-field-tooltip">
        <InfoTooltip left>{tooltip}</InfoTooltip>
      </div>
    )
  }

  const renderLabel = () => {
    if (label == null && tooltip == null) {
      return null
    }

    return (
      <div className="boon-text-field-label">
        {label}
        {required && <span className="boon-text-field-label-required">*</span>}
        {renderTooltip()}
      </div>
    )
  }

  const renderHelperText = () => {
    if (helperText == null) {
      return null
    }

    return <div className="boon-text-field-helper-text">{helperText}</div>
  }

  const renderAddon = (name, value, isMainColor) => {
    const isString = typeof value === 'string' || value instanceof String

    return (
      <div
        className={clsx(
          isMainColor && 'boon-text-field-addon-main-color',
          `boon-text-field-${name} boon-text-field-addon`,
          {
            'boon-text-field-addon-text': isString,
          }
        )}
      >
        {value}
      </div>
    )
  }

  const renderPrepend = () => {
    if (prepend == null) {
      return null
    }

    return renderAddon('prepend', prepend, addonMainColor)
  }

  const renderAppend = () => {
    if (append == null) {
      return null
    }

    return renderAddon('append', append, addonMainColor)
  }

  const renderInput = () => {
    if (multiline) {
      return (
        <textarea
          className="boon-text-field-textarea"
          placeholder={placeholder}
          {...field}
          disabled={disabled}
          type={type}
        />
      )
    }

    return (
      <input
        className="boon-text-field-input"
        placeholder={placeholder}
        {...field}
        disabled={disabled}
        type={type}
      />
    )
  }

  const renderUnderText = () => {
    if (underText == null) {
      return null
    }

    return <p className="boon-text-field-under-text">{underText}</p>
  }

  const renderError = () => {
    if (!(meta.error && meta.touched)) {
      return null
    }

    return (
      <div className="boon-text-field-error">
        {Array.isArray(meta.error) ? meta.error.join(', ') : meta.error}
      </div>
    )
  }

  return (
    <div className={clsx('BoonTextField', { dark })}>
      {renderLabel()}
      {renderHelperText()}
      <div className="boon-text-field-wrapper">
        {renderPrepend()}
        {renderInput()}
        {renderAppend()}
      </div>
      {renderUnderText()}
      {renderError()}
    </div>
  )
}

export const BoonFormikTextField = ({ field: _field, ...props }) => {
  const [field, meta] = useField(props)
  return (
    <BoonTextField
      {...props}
      field={Object.assign(field, _field)}
      meta={meta}
    />
  )
}
