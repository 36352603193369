import React from 'react'
import './PurchasesPageHeader.scss'
import { NavLink } from 'react-router-dom'
import { URL_INVOICES, URL_MANAGE_SUBSCRIPTIONS, URL_PURCHASES } from 'urls'

const PurchasesPageHeader = () => {
  return (
    <div className="PurchasesPageHeader">
      <h1 className="purchases-page-header-title">Purchases</h1>
      <div className="purchases-page-header-menu">
        <NavLink to={URL_PURCHASES} exact>
          Content
        </NavLink>
        <NavLink to={URL_MANAGE_SUBSCRIPTIONS} exact>
          Memberships
        </NavLink>
        <NavLink to={URL_INVOICES} exact>
          Billing History
        </NavLink>
      </div>
    </div>
  )
}

export default PurchasesPageHeader
