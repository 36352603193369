import React, { useCallback, useEffect, useRef, useState } from 'react'
import './HeaderNotifications.scss'
import { NotificationsSvg } from 'assets/svg'
import HybridMenu from 'components/HybridMenu'
import RecentNotifications from 'components/RecentNotifications'
import { useGetNotificationsCountApi } from 'api'
import { useInterval, useMe } from 'utils'
import { usePrevious } from 'react-use'

const HeaderNotifications = () => {
  const me = useMe()
  const meId = me.get('id')
  const outerRef = useRef()
  const [open, setOpen] = useState(false)
  const previousOpen = usePrevious(open)
  const onClose = useCallback(() => setOpen(false), [setOpen])
  const { data: count, refresh: countRefresh } = useGetNotificationsCountApi({
    userId: meId,
  })
  useInterval(countRefresh, 5 * 60 * 1000)

  useEffect(() => {
    if (open && !previousOpen) {
      countRefresh()
    }
  }, [open, previousOpen, countRefresh])

  return (
    <div className="HeaderNotifications" ref={outerRef}>
      <div
        className="header-notifications-bell"
        onClick={() => setOpen((o) => !o)}
      >
        <NotificationsSvg className="header-notifications-bell-icon" />
        {count > 0 && (
          <div className="header-notifications-bell-count">
            {count > 99 ? '99+' : count}
          </div>
        )}
      </div>
      <HybridMenu open={open} onClose={onClose} outerRef={outerRef}>
        <RecentNotifications onClose={onClose} countRefresh={countRefresh} />
      </HybridMenu>
    </div>
  )
}

export default HeaderNotifications
