import React from 'react'
import './EmptyList.scss'

import { Image } from 'components/Image'
import noPostsImage from 'assets/img/no-posts.png'

const EmptyList = ({ filters, dispatchFilter }) => {
  const isFilterApplied = () => {
    let filterValues = Object.values(filters)
    filterValues.shift() // Remove attachment_type
    return filterValues.some((v) => v !== undefined)
  }

  const removeFilters = () => {
    dispatchFilter({ group: 'attachmentType', key: null })
    dispatchFilter({ group: 'categoryId', key: null })
    dispatchFilter({ group: 'postType', key: null })
    dispatchFilter({ group: 'videoType', key: null })
    dispatchFilter({ group: 'productId', key: null })
  }

  return (
    <div className="EmptyList">
      <Image src={noPostsImage} />
      <p>Nothing posted here yet!</p>
      {isFilterApplied() && (
        <button onClick={removeFilters} className="button secondary">
          Remove filters
        </button>
      )}
    </div>
  )
}

export default EmptyList
