import React from 'react'
import Spinner from 'components/Spinner'
import queryString from 'query-string'
import { useDispatch } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import { useMount } from 'react-use'
import { URL_FRONT_PAGE } from 'urls'
import { setAuth, updateMe } from 'store/actions/auth'
import { getMeObject } from 'api'
import './SudoPage.scss'

const SudoPage = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const history = useHistory()

  useMount(() => {
    const {
      access_token: accessToken,
      access_token_expires: accessTokenExpires,
      refresh_token: refreshToken,
      refresh_token_expires: refreshTokenExpires,
    } = queryString.parse(location.search, { decode: false })

    if (
      accessToken == null ||
      accessTokenExpires == null ||
      refreshToken == null ||
      refreshTokenExpires == null
    ) {
      alert('Something went wrong, missing parameter')
    }

    dispatch(
      setAuth({
        accessToken,
        accessTokenExpires,
        refreshToken,
        sudo: true,
      })
    )

    setTimeout(async () => {
      const apiMe = await getMeObject()
      dispatch(updateMe(apiMe))

      history.replace(URL_FRONT_PAGE)
    }, 250)
  })

  return (
    <div className="SudoPage">
      <div className="sudo-page-box">
        <h1 className="sudo-page-title">Signing in, please wait</h1>
        <Spinner small />
      </div>
    </div>
  )
}

export default SudoPage
