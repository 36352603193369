import { useMemo } from 'react'
import { DateTime } from 'luxon'
import { useRerenderInterval } from 'utils'

export const useEventChatActive = (event, liveEventEnded) => {
  const eventStartTime = event.get('startTime')
  const dateTime = useMemo(() => DateTime.fromISO(eventStartTime), [
    eventStartTime,
  ])
  useRerenderInterval(30 * 1000, !event.get('live'))

  if (!event.get('live')) {
    return false
  }

  if (event.get('ended') || liveEventEnded) {
    return false
  }

  const minutes = dateTime.diffNow('minutes').minutes
  if (minutes > 15) {
    return false
  }

  return true
}
