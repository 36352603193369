import React from 'react'
import './EventButton.scss'
import clsx from 'clsx'

const EventButtonSoldOut = ({ className }) => {
  return (
    <button className={clsx('button disabled', className)}>Sold out</button>
  )
}

export default EventButtonSoldOut
