import React, { useCallback } from 'react'
import './CreatorColorPicker.scss'
import clsx from 'clsx'
import { CheckSvg } from 'assets/svg'
import { useFormikContext } from 'formik'
import { CREATOR_COLORS } from 'creatorColorConstants'

const formKey = 'colorScheme'
const CreatorColorPicker = () => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext()
  const currentColorKey = values[formKey]

  const onPick = useCallback(
    (colorKey) => {
      setFieldValue(formKey, colorKey)
      setFieldTouched(formKey, true)
    },
    [setFieldValue, setFieldTouched]
  )

  const renderColors = () => {
    return Object.entries(
      CREATOR_COLORS
    ).map(([colorKey, { primary, textPrimary }]) => (
      <CreatorColorPickerItem
        active={currentColorKey === colorKey}
        colorKey={colorKey}
        key={colorKey}
        primary={primary}
        textPrimary={textPrimary}
        onPick={onPick}
      />
    ))
  }

  return (
    <div className="CreatorColorPicker">
      <div className="creator-color-picker-default">
        <CreatorColorPickerItem
          active={currentColorKey == null}
          colorKey={null}
          onPick={onPick}
        />
      </div>
      <div className="creator-color-picker-colors">{renderColors()}</div>
    </div>
  )
}

export default CreatorColorPicker

const CreatorColorPickerItem = ({
  className,
  active,
  onPick,
  colorKey,
  primary,
  textPrimary,
}) => {
  return (
    <div
      className={clsx('CreatorColorPickerItem', { active }, className)}
      style={{ backgroundColor: primary }}
      onClick={() => onPick(colorKey)}
    >
      {active && <CheckSvg style={{ fill: textPrimary }} />}
    </div>
  )
}
