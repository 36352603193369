import React from 'react'
import AvatarPlaceholderDefault from 'assets/img/avatar-placeholders/Default.png'
import AvatarPlaceholderColor01 from 'assets/img/avatar-placeholders/Color01.png'
import AvatarPlaceholderColor02 from 'assets/img/avatar-placeholders/Color02.png'
import AvatarPlaceholderColor03 from 'assets/img/avatar-placeholders/Color03.png'
import AvatarPlaceholderColor04 from 'assets/img/avatar-placeholders/Color04.png'
import AvatarPlaceholderColor05 from 'assets/img/avatar-placeholders/Color05.png'
import AvatarPlaceholderColor06 from 'assets/img/avatar-placeholders/Color06.png'
import AvatarPlaceholderColor07 from 'assets/img/avatar-placeholders/Color07.png'
import AvatarPlaceholderColor08 from 'assets/img/avatar-placeholders/Color08.png'
import './Avatar.scss'
import clsx from 'clsx'
import { BackgroundImage } from 'components/Image'

const placeholderColors = [
  AvatarPlaceholderColor01,
  AvatarPlaceholderColor02,
  AvatarPlaceholderColor03,
  AvatarPlaceholderColor04,
  AvatarPlaceholderColor05,
  AvatarPlaceholderColor06,
  AvatarPlaceholderColor07,
  AvatarPlaceholderColor08,
]

const Avatar = ({
  url,
  userId,
  className,
  onClick = () => null,
  children,
  ...otherProps
}) => {
  if (
    url == null ||
    url ===
      'https://s3.eu-central-1.amazonaws.com/boon-email.prod/img/avatar-placeholder.png'
  ) {
    url = AvatarPlaceholderDefault
    if (userId && (typeof userId === 'string' || userId instanceof String)) {
      const firstChar = parseInt(userId[0], 16)
      const index = firstChar > 7 ? firstChar - 8 : firstChar
      if (index < 8) {
        url = placeholderColors[index]
      }
    }
  }

  return (
    <BackgroundImage
      className={clsx('Avatar', className)}
      src={url}
      onClick={onClick}
      {...otherProps}
    >
      {children}
    </BackgroundImage>
  )
}

export default React.memo(Avatar)
