import React, { useCallback, useState } from 'react'
import './PhotoItem.scss'
import { Image } from 'components/Image'
import { CommentSvg, ThumbUpSvg } from 'assets/svg'
import UnlockButton from 'components/UnlockButton'
import {
  POST_ACCESS,
  POST_ATTACHMENT_ACCESS,
  POST_ATTACHMENT_TYPES,
} from 'store/constants/post'
import PostAttachmentModal from 'modals/PostAttachmentModal'
import { getUserUrl } from 'utils'
import PostOptions from 'components/PostOptions'

const PhotoItem = ({
  post,
  onNoneAccessPostClick,
  onPostDeleted,
  onPostUpdated,
  hostId,
}) => {
  const [showModal, setShowModal] = useState(false)
  const access = post.get('attachmentAccess') === POST_ATTACHMENT_ACCESS.NONE
  const isAdmin = post.get('access') === POST_ACCESS.ADMIN
  const postId = post.get('id')
  const postUrl = `${getUserUrl(post.get('host'))}/posts/${postId}`
  const onNoAccess = useCallback(
    (post, title) => {
      onNoneAccessPostClick(post, title)
    },
    [onNoneAccessPostClick]
  )

  return (
    <>
      {showModal && (
        <PostAttachmentModal
          postId={postId}
          postUrl={postUrl}
          onClose={() => setShowModal(false)}
        />
      )}
      <div key={post.get('id')} onClick={setShowModal} className="PhotoItem">
        <div className="masonry-item-title">{post.getIn(['user', 'name'])}</div>
        <div className="masonry-item-activity">
          <ThumbUpSvg /> {post.get('likesCount')} <CommentSvg />{' '}
          {post.get('commentsCount')}
        </div>
        {access && (
          <div className="masonry-item-photo-locked">
            <UnlockButton
              onClick={() => onNoAccess(post, 'Unlock to view this photo')}
            />
          </div>
        )}

        <Image
          className="masonry-item-image"
          src={post.getIn(['attachment', 'fullPath'])}
        />

        {isAdmin &&
          post.get('attachmentType') !== POST_ATTACHMENT_TYPES.EVENT && (
            <PostOptions
              post={post}
              dark
              onPostDeleted={onPostDeleted}
              onPostUpdated={onPostUpdated}
              hostId={hostId}
            />
          )}
      </div>
    </>
  )
}

export default PhotoItem
