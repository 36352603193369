import React, { useEffect } from 'react'
import './CreatorSettingsPage.scss'
import { useRouteMatch, useHistory } from 'react-router-dom'
import CreatorSettingsMenu from './CreatorSettingsMenu'
import { NarrowContainer } from 'components/Grid'
import {
  URL_FRONT_PAGE,
  URL_CREATOR_SETTINGS_PROFILE,
  URL_CREATOR_SETTINGS_PAYOUT,
  URL_CREATOR_SETTINGS_MEMBERSHIP,
  URL_CREATOR_SETTINGS_CATEGORIES,
  URL_CREATOR_SETTINGS_COUPONS,
} from 'urls'
import { useSelector } from 'react-redux'
import { isMeCreator, isAuthenticated } from 'store/selectors'
import CreatorSettingsMembership from './CreatorSettingsMembership'
import CreatorSettingsCategories from './CreatorSettingsCategories'
import CreatorSettingsPayout from './CreatorSettingsPayout'
import CreatorSettingsProfile from './CreatorSettingsProfile'
import CreatorSettingsCoupons from './CreatorSettingsCoupons'
import CreatorSidebar from 'components/CreatorSidebar'

const CreatorSettingsPage = () => {
  const { path } = useRouteMatch()
  const history = useHistory()
  const hasAuth = useSelector((state) => isAuthenticated(state))
  const isCreator = useSelector((state) => isMeCreator(state))

  useEffect(() => {
    if (!hasAuth || !isCreator) {
      history.push(URL_FRONT_PAGE)
    }
  }, [hasAuth, history, isCreator])

  const renderPage = () => {
    switch (path) {
      case URL_CREATOR_SETTINGS_PROFILE:
        return <CreatorSettingsProfile />
      case URL_CREATOR_SETTINGS_PAYOUT:
        return <CreatorSettingsPayout />
      case URL_CREATOR_SETTINGS_MEMBERSHIP:
        return <CreatorSettingsMembership />
      case URL_CREATOR_SETTINGS_CATEGORIES:
        return <CreatorSettingsCategories />
      case URL_CREATOR_SETTINGS_COUPONS:
        return <CreatorSettingsCoupons />
      default:
        history.replace(URL_CREATOR_SETTINGS_PROFILE)
        return null
    }
  }

  return (
    <CreatorSidebar>
      <div className="CreatorSettingsPage">
        <NarrowContainer>
          <p className="creator-settings-page-title">Creator settings</p>
          <CreatorSettingsMenu />
          {renderPage()}
        </NarrowContainer>
      </div>
    </CreatorSidebar>
  )
}

export default CreatorSettingsPage
