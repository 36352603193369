import React from 'react'
import './ShowMore.scss'
import clsx from 'clsx'
import { ChevronDownSvg } from 'assets/svg'

const ShowMore = ({ active, onClick, count }) => {
  return (
    <div className={clsx('ShowMore', { active })} onClick={onClick}>
      Show {active ? 'less' : count ? `all ${count}` : 'more'}{' '}
      <ChevronDownSvg />
    </div>
  )
}

export default ShowMore
