import React, { useCallback, useContext, useState } from 'react'
import InsightsContext from 'routes/InsightsPage/InsightsContext'
import { Col, Row } from 'components/Grid'
import InsightsConnectedNumberWidget from 'components/InsightsNumberWidget/InsightsConnectedNumberWidget'
import InsightsConnectedTableWidget from 'components/InsightsTableWidget/InsightsConnectedTableWidget'
import { displayPrice, useMe } from 'utils'
import {
  currencyPrefixDataMapper,
  genericChartDataMapper,
  usePrefixedChartDataLabelMapper,
} from 'routes/InsightsPage/insightsUtils'
import InsightsConnectedChartWidget from 'components/InsightsChartWidget/InsightsConnectedChartWidget'
import { INSIGHTS_PERIODS_NAMES } from 'routes/InsightsPage/insightsConstants'
import RelationsMenuModal, {
  RELATION_TYPE,
} from 'routes/InsightsPage/components/RelationsMenuModal'

const tableDataMapper = ({ participants, meta }) => {
  return {
    participants: participants.map(
      ({ user, invoice, balanceTransactionId, email }) => ({
        name: {
          name: user['name'],
          userId: user['id'],
          avatarUrl: user['profileImageUrl'],
        },
        email,
        purchaseDate: invoice['purchaseDate'],
        discount: displayPrice(invoice['discount'], invoice['currency']),
        invoiceAmountPaid: displayPrice(
          invoice['amountPaid'] / 100,
          invoice['currency']
        ),
        balanceTransactionId,
      })
    ),
    meta: {
      pages: meta['pagination']['pages'],
      count: meta['pagination']['count'],
    },
  }
}

const EventInsightsSalesPage = ({ eventId, currency, currencySymbol }) => {
  const me = useMe()
  const userId = me.get('id')
  const prefixedChartDataLabelMapper = usePrefixedChartDataLabelMapper(
    currencySymbol
  )
  const { period, queryParams } = useContext(InsightsContext)
  const numberWidgetDataMapper = useCallback(
    (d) => ({ value: d['meta']['pagination']['count'], previousValue: 0 }),
    []
  )
  const [menuModalUserId, setMenuModalUserId] = useState(null)
  const [menuModalTransactionId, setMenuModalTransactionId] = useState(null)

  const renderNumberWidgets = () => {
    return (
      <Row className="insights-members-top">
        <Col lg={3} md={4}>
          <InsightsConnectedNumberWidget
            title={'Tickets sold'}
            url={`events/${eventId}/participants`}
            timeScale={'Total'}
            dataMapper={numberWidgetDataMapper}
            customNumberFormatter={Intl.NumberFormat()}
          />
        </Col>
        <Col lg={3} md={4}>
          <InsightsConnectedNumberWidget
            title={'Income'}
            url={`income_stats/${userId}/revenue_ppv?event_id=${eventId}`}
            timeScale={'Total'}
            prefix={currencyPrefixDataMapper}
          />
        </Col>
      </Row>
    )
  }

  const renderCharts = () => {
    return (
      <Row>
        <Col xs={12}>
          <InsightsConnectedChartWidget
            title={`Income (${currencySymbol})`}
            timeScale={INSIGHTS_PERIODS_NAMES[period]}
            url={`income_stats/${userId}/revenue_ppv_timeseries?event_id=${eventId}&${queryParams}`}
            dataMapper={genericChartDataMapper}
            labels={prefixedChartDataLabelMapper}
            chartType={'bar'}
          />
        </Col>
      </Row>
    )
  }

  const renderTable = () => {
    const headers = [
      { key: 'name', type: 'profile', active: true },
      { key: 'email', type: 'string', active: true },
      {
        key: 'purchaseDate',
        name: 'Purchase Date',
        type: 'date',
        active: true,
      },
      {
        key: 'discount',
        name: 'Discount',
        type: 'number',
        active: true,
      },
      {
        key: 'invoiceAmountPaid',
        name: 'Charge',
        type: 'number',
        active: true,
      },
    ]
    return (
      <InsightsConnectedTableWidget
        title="Tickets Sold"
        baseUrl={`events/${eventId}/participants`}
        headers={headers}
        dataMapper={tableDataMapper}
        dataKey="participants"
        defaultSortBy={'name asc'}
        clickableRow={(obj) => {
          setMenuModalUserId(obj['name']['userId'])
          setMenuModalTransactionId(obj['balanceTransactionId'])
        }}
      />
    )
  }

  return (
    <div className="InsightsEventPageSales">
      {renderNumberWidgets()}
      {renderCharts()}
      {renderTable()}
      <RelationsMenuModal
        hostId={userId}
        userId={menuModalUserId}
        onClose={() => {
          setMenuModalUserId(null)
          setMenuModalTransactionId(null)
        }}
        type={RELATION_TYPE.TRANSACTION}
        transactionId={menuModalTransactionId}
      />
    </div>
  )
}

export default EventInsightsSalesPage
