import React from 'react'
import { useSelector } from 'react-redux'
import { Formik } from 'formik'
import CreatorSettingsHeader from './CreatorSettingsHeader'
import { getMe } from 'store/selectors'
import { useGetUserApi } from 'api'
import Spinner from 'components/Spinner'
import CreatorCoupons from 'components/CreatorCoupons'

const CreatorSettingsCoupons = () => {
  const me = useSelector((state) => getMe(state))
  const { data: user } = useGetUserApi({
    userId: me.get('id'),
  })

  const renderContent = () => {
    if (user == null) {
      return <Spinner light small />
    }

    return <CreatorCoupons />
  }
  return (
    <div className="CreatorSettingsCoupons">
      <CreatorSettingsHeader description="Coupons can be used to discount memberships and video purchases" />
      <Formik initialValues={{ couponIds: [] }}>{renderContent()}</Formik>
    </div>
  )
}

export default CreatorSettingsCoupons
