import React, { useCallback, useState } from 'react'
import './CategorySelector.scss'
import { AddSvg } from 'assets/svg'
import { useFormikContext } from 'formik'
import { useGetCategoriesApi } from 'api'
import CategoryUpsertModal from 'modals/CategoryUpsertModal'
import CategoryOptions from 'components/CategoryOptions'

const CategorySelector = ({ selectCategory = true }) => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext()
  const [addOpen, setAddOpen] = useState(false)
  const {
    data: categories,
    loading,
    refresh: categoriesRefresh,
    error: categoriesError,
  } = useGetCategoriesApi()

  const categoryIds = values.categoryIds

  const onCategoryUpsert = useCallback(
    async (category) => {
      setAddOpen(false)
      try {
        await categoriesRefresh()
        selectCategory &&
          setFieldValue('categoryIds', [...categoryIds, category.get('id')])
      } catch (e) {
        // Pass
      }
    },
    [categoriesRefresh, setAddOpen, selectCategory, setFieldValue, categoryIds]
  )

  const renderCategories = () => {
    if (categories == null) {
      return null
    }

    return categories.map((category) => (
      <div className="category-selector-item" key={category.get('id')}>
        {selectCategory && (
          <input
            id={category.get('id')}
            type="checkbox"
            className="boon-dark-checkbox"
            checked={categoryIds.includes(category.get('id'))}
            onChange={(e) => {
              setFieldTouched('categoryIds')
              if (e.target.checked) {
                setFieldValue('categoryIds', [
                  ...categoryIds,
                  category.get('id'),
                ])
              } else {
                setFieldValue(
                  'categoryIds',
                  categoryIds.filter((id) => id !== category.get('id'))
                )
              }
            }}
          />
        )}
        <label
          className="category-selector-item-info"
          htmlFor={category.get('id')}
        >
          <span className="emoji">{category.get('emoji')}</span>
          {category.get('name')}
        </label>
        <CategoryOptions
          category={category}
          onCategoryUpsert={categoriesRefresh}
        />
      </div>
    ))
  }

  if (categoriesError) {
    return (
      <div className="CategorySelector">
        <div className="category-selector-error">
          Could not load categories,{' '}
          <span onClick={categoriesRefresh}>click here to try again</span>.
        </div>
      </div>
    )
  }

  return (
    <div className="CategorySelector">
      <CategoryUpsertModal
        active={addOpen}
        onClose={() => setAddOpen(false)}
        onCategoryUpsert={onCategoryUpsert}
      />
      <div className="category-selector-list">{renderCategories()}</div>
      {loading && <div className="category-selector-loader" />}
      {!loading && (
        <div className="category-selector-add" onClick={() => setAddOpen(true)}>
          <AddSvg /> New
        </div>
      )}
    </div>
  )
}

export default CategorySelector
