import React, { useCallback, useMemo, useState } from 'react'
import './EventStartWaitingRoom.scss'
import VideoPlayer from 'routes/WatchPage/components/VideoPlayer'
import { BackgroundImage } from 'components/Image'
import { getMuxThumbnail, useRerenderInterval } from 'utils'
import EventCountdown from 'components/Event/EventCountdown'
import { PlayRoundedSvg } from 'assets/svg'
import CloseSvg from 'assets/svg/CloseSvg'
import { DateTime } from 'luxon'
import { useSelector } from 'react-redux'
import { isAuthenticated } from 'store/selectors'
import { requireAuth } from 'store/utils/awaitStore'

const useIsCloseToEventStart = (startTime) => {
  const parsedStartTime = useMemo(() => DateTime.fromISO(startTime), [
    startTime,
  ])
  useRerenderInterval(60 * 1000)
  const diffMinutes = parsedStartTime.diffNow('minutes').minutes

  return diffMinutes < 15
}

const EventStartWaitingRoom = ({ event }) => {
  const [playingTrailer, setPlayingTrailer] = useState(false)
  const hasAuth = useSelector((state) => isAuthenticated(state))
  const isCloseToEventStart = useIsCloseToEventStart(event.get('startTime'))

  const onPlayClick = useCallback(() => {
    requireAuth()
  }, [])

  const getInner = () => {
    const showPlay = isCloseToEventStart && !hasAuth
    const playButton = showPlay && (
      <button
        className="button secondary light event-start-waiting-room-trailer-button"
        onClick={onPlayClick}
      >
        <PlayRoundedSvg />
        Play
      </button>
    )
    const trailerButton = event.get('coverVideoUrl') && !showPlay && (
      <button
        className="button secondary light event-start-waiting-room-trailer-button"
        onClick={() => setPlayingTrailer(true)}
      >
        <PlayRoundedSvg />
        Teaser
      </button>
    )

    return (
      <>
        <EventCountdown hasStatic={false} event={event} short noDate />
        {playButton}
        {trailerButton}
      </>
    )
  }

  if (playingTrailer) {
    const url = event.get('coverVideoUrl')

    return (
      <VideoPlayer
        className="EventStartWaitingRoomTrailer"
        key={url}
        controls
        autoplay="play"
        techOrder={['chromecast', 'html5']}
        plugins={{
          chromecast: {},
        }}
        controlBar={{
          pictureInPictureToggle: false,
        }}
        playsinline
        width="100%"
        height="100%"
        onEnded={() => setPlayingTrailer(false)}
        components={
          <CloseSvg
            className="event-start-waiting-room-trailer-exit"
            onClick={() => setPlayingTrailer(false)}
          />
        }
        sources={[
          {
            src: url,
          },
        ]}
      />
    )
  } else {
    return (
      <BackgroundImage
        src={
          event.get('coverImageUrl') ||
          getMuxThumbnail(event.get('coverVideoUrl'))
        }
        className="EventStartWaitingRoom"
      >
        <div className="event-start-waiting-room-inner">{getInner()}</div>
      </BackgroundImage>
    )
  }
}

export default EventStartWaitingRoom
