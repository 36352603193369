import React from 'react'
import CreatorSettingsHeader from './CreatorSettingsHeader'
import { useSelector } from 'react-redux'
import { getMe } from 'store/selectors'
import { useGetUserApi } from 'api'
import Spinner from 'components/Spinner'
import { Formik } from 'formik'

import CategorySelector from 'components/CategorySelector'

const CreatorSettingsCategories = () => {
  const me = useSelector((state) => getMe(state))
  const { data: user } = useGetUserApi({
    userId: me.get('id'),
  })

  const renderContent = () => {
    if (user == null) {
      return <Spinner light small />
    }

    return <CategorySelector selectCategory={false} />
  }

  return (
    <div className="CreatorSettingsCategories">
      <CreatorSettingsHeader description="Manage your categories." />
      <Formik initialValues={{ categoriesId: [] }}>{renderContent()}</Formik>
    </div>
  )
}

export default CreatorSettingsCategories
