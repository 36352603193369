import React, { useEffect } from 'react'
import { isAuthenticated, isMeCreator } from 'store/selectors'
import { useSelector } from 'react-redux'
import CreatorPage from 'routes/CreatorPage'
import { useHistory } from 'react-router-dom'
import { getUserUrl, useMe } from 'utils'
import Spinner from 'components/Spinner'
import { URL_FOLLOWING } from 'urls'

const FrontPage = () => {
  const history = useHistory()
  const me = useMe()
  const isCreator = useSelector((state) => isMeCreator(state))
  const hasAuth = useSelector((state) => isAuthenticated(state))

  useEffect(() => {
    if (hasAuth) {
      if (isCreator) {
        return history.replace(getUserUrl(me))
      } else {
        const subscriptions = me.get('subscriptionAccounts')
        if (subscriptions.size > 0) {
          return history.replace(getUserUrl(subscriptions.first()))
        }

        const following = me.get('following')
        if (following.size > 0) {
          return history.replace(getUserUrl(following.first()))
        }

        return history.replace(URL_FOLLOWING)
      }
    } else {
      window.location = 'https://booncoach.com'
    }
  }, [history, hasAuth, isCreator, me])

  if (hasAuth) {
    return <Spinner light />
  }

  return null
}

export default FrontPage
