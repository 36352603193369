import React from 'react'
import './Spinner.scss'
import clsx from 'clsx'

const Spinner = ({ light, small, tiny }) => {
  return (
    <div
      className={clsx('Spinner', { light, small, tiny })}
      data-testid="loading-spinner"
    />
  )
}

export default Spinner
