import React, { useCallback, useLayoutEffect, useRef } from 'react'
import './PostComposeModal.scss'
import Avatar from 'components/Avatar'
import { useMe } from 'utils'
import CloseSvg from 'assets/svg/CloseSvg'
import TextareaAutosize from 'react-textarea-autosize'
import { useFormikContext } from 'formik'
import PostComposeAttachmentButton from './PostComposeAttachmentButton'
import { AttachFileSvg, InsertPhotoOutlineSvg, PollSvg } from 'assets/svg'
import PostComposeModalAttachment from './PostComposeModalAttachment'
import { useLockBodyScroll, useUnmount } from 'react-use'
import StyledApiErrorMessage from 'components/StyledApiErrorMessage'
import clsx from 'clsx'
import AnimatedModal from 'components/AnimatedModal'
import { POST_ATTACHMENT_TYPES } from 'store/constants/post'
import AccessSelector from 'components/AccessSelector'
import ToggleButton from 'components/ToggleButton'

const PostComposeModal = ({ active, onClose, isEdit, isAdmin, user }) => {
  return (
    <AnimatedModal
      active={active}
      className="PostComposeModal"
      overlay
      onClose={onClose}
    >
      <PostComposeModalInner
        active={active}
        onClose={onClose}
        isEdit={isEdit}
        isAdmin={isAdmin}
        user={user}
      />
    </AnimatedModal>
  )
}

export default PostComposeModal

const PostComposeModalInner = ({ active, isEdit, onClose, isAdmin, user }) => {
  const messageRef = useRef()
  useLockBodyScroll(active)
  useUnmount(() => {
    document.body.style.overflow = null
  })
  const {
    values,
    setFieldValue,
    isSubmitting,
    dirty,
    submitForm,
    isValid,
  } = useFormikContext()
  const me = useMe()
  const hasAttachment = values.attachmentType != null

  useLayoutEffect(() => {
    if (messageRef?.current != null) {
      messageRef.current.focus()
    }
  }, [])

  const setProductIds = useCallback(
    (productIds) => {
      setFieldValue('productIds', productIds)
    },
    [setFieldValue]
  )

  const setPublic = useCallback(
    (value) => {
      setFieldValue('public', value)
    },
    [setFieldValue]
  )

  const notifySubscribersToggle = (value) => {
    setFieldValue('notifySubscribers', value)
  }

  const onPollAdd = () => {
    setFieldValue('attachmentType', POST_ATTACHMENT_TYPES.POLL)
  }

  return (
    <>
      <div className="post-compose-modal-header">
        <Avatar
          url={me.get('profileImageUrl')}
          userId={me.get('id')}
          className="post-compose-modal-header-avatar"
        />
        <div className="post-compose-modal-header-name">{me.get('name')}</div>
        <div className="post-compose-modal-header-close" onClick={onClose}>
          <CloseSvg className="post-compose-modal-header-close-icon" />
        </div>
      </div>
      <div className="post-compose-modal-body">
        {isAdmin && user && (
          <div className="post-compose-modal-body-selector">
            <AccessSelector
              products={user.get('products')}
              productIds={values.productIds}
              setProductIds={setProductIds}
              publicValue={values.public}
              setPublic={setPublic}
            />
          </div>
        )}

        <div className="post-compose-modal-notify-wrapper">
          <div className="post-compose-modal-notify-title">
            Send this post as an email?
          </div>
          <div className="post-compose-modal-notify-toggle">
            <ToggleButton
              value={values.notifySubscribers}
              onChange={notifySubscribersToggle}
            />
          </div>
        </div>

        <TextareaAutosize
          ref={messageRef}
          placeholder="What's on your mind"
          className="post-compose-modal-message"
          value={values.message}
          onChange={(e) => setFieldValue('message', e.target.value)}
        />
      </div>
      <PostComposeModalAttachment isEdit={isEdit} />
      <div className="post-compose-modal-attachments-bar">
        {!hasAttachment && (
          <>
            <PostComposeAttachmentButton
              icon={InsertPhotoOutlineSvg}
              component="label"
              htmlFor={isEdit ? 'postComposeUpdatePhoto' : 'postComposePhoto'}
              title="Photo"
            />
            {isAdmin && (
              <PostComposeAttachmentButton
                icon={PollSvg}
                title="Poll"
                onClick={onPollAdd}
              />
            )}
            <PostComposeAttachmentButton
              icon={AttachFileSvg}
              component="label"
              htmlFor={
                isEdit ? 'postComposeUpdateDocument' : 'postComposeDocument'
              }
              title="File"
            />
          </>
        )}
        <button
          type="submit"
          disabled={isSubmitting || !dirty || !isValid}
          onClick={submitForm}
          className={clsx('button creator-primary loader ', {
            loading: isSubmitting,
          })}
        >
          {isEdit ? 'Save changes' : 'Post'}
        </button>
      </div>
      {dirty && <StyledApiErrorMessage />}
    </>
  )
}
