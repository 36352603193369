import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import clsx from 'clsx'

const ExtendedContainer = ({ className, children, ...props }) => (
  <div className={clsx('extended-container', className)} {...props}>
    {children}
  </div>
)

const NarrowContainer = ({ className, children, ...props }) => (
  <div className={clsx('narrow-container', className)} {...props}>
    {children}
  </div>
)

export { ExtendedContainer, NarrowContainer, Container, Row, Col }
