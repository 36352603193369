import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import './PostCardEventSpecificContent.scss'
import 'components/EventCard/EventCard.scss'
import { DateTime } from 'luxon'
import clsx from 'clsx'
import { ChevronDownSvg } from 'assets/svg'
import CheckoutModal from 'modals/CheckoutModal'
import Linkify from 'components/Linkify'
import EventButton, {
  EVENT_BUTTON_TYPE,
} from 'components/EventButton/EventButton'
import { EVENT_TYPE } from 'store/constants'
import { URL_EVENT_INSIGHTS_OVERVIEW } from 'urls'

const PostCardEventSpecificContent = ({ event, user, refreshEvent }) => {
  const [collapsed, setCollapsed] = useState(
    event.get('description')?.length > 120
  )
  const [purchaseModalOpen, setPurchaseModalOpen] = useState(false)
  const live = event.get('live') && !event.get('ended')
  const dateTime = DateTime.fromISO(
    event.get('startTime') || event.get('publishedAt') || event.get('createdAt')
  )

  return (
    <div className={clsx('PostCardEventSpecificContent', { collapsed })}>
      <CheckoutModal
        active={purchaseModalOpen}
        title={`Unlock ${event.get('title')}`}
        event={event}
        hostId={user.get('id')}
        onClose={() => setPurchaseModalOpen(false)}
        productIds={event.get('productIds')}
      />
      <div className="post-card-event-specific-content-inner">
        <div className="post-card-event-specific-content-title">
          {event.get('title')}
        </div>
        {live && (
          <div className="post-card-event-specific-content-live">
            <div className="post-card-event-specific-content-live-date">
              Live {dateTime.toFormat('MMM d, H:mm')}
            </div>
            &nbsp;&nbsp;·&nbsp;&nbsp;
            {event.get('duration')} min
            {event.get('isFree') && (
              <>
                &nbsp;&nbsp;·&nbsp;&nbsp;
                <span className="free-badge">Free</span>
              </>
            )}
          </div>
        )}

        {!live && (
          <div className="post-card-event-specific-content-video">
            <div className="post-card-event-specific-content-video-date">
              {event.get('live') ? 'Live ' : 'Uploaded '}
              {dateTime.toRelative()}
            </div>
            &nbsp;&nbsp;·&nbsp;&nbsp;
            {event.get('duration')} min
            {event.get('isFree') && '&nbsp;&nbsp;·&nbsp;&nbsp;' && (
              <>
                &nbsp;&nbsp;·&nbsp;&nbsp;
                <span className="free-badge">Free</span>
              </>
            )}
          </div>
        )}

        <EventButton
          event={event}
          className="light event-page-button"
          type={EVENT_BUTTON_TYPE.LARGE}
          refreshEvent={refreshEvent}
        />
        {event.get('userType') === EVENT_TYPE.HOST && (
          <Link
            to={URL_EVENT_INSIGHTS_OVERVIEW(
              event.getIn(['host', 'username']),
              event.get('slug')
            )}
            className="button surface post-card-event-specific-content-insights-button"
          >
            Insights
          </Link>
        )}
        <div className="post-card-event-specific-content-description">
          <Linkify>{event.get('description')}</Linkify>
        </div>
      </div>
      {collapsed && (
        <div
          className="post-card-event-specific-content-expander"
          onClick={() => setCollapsed(false)}
        >
          <ChevronDownSvg />
        </div>
      )}
    </div>
  )
}

export default PostCardEventSpecificContent
