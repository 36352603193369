import React from 'react'
import Countdown from 'components/Event/Countdown'
import './WatchWaitingRoom.scss'
import clsx from 'clsx'
import { BackgroundImage } from 'components/Image'

const WatchWaitingRoom = ({
  event,
  loading,
  title,
  subtitle,
  countdown = false,
}) => {
  return (
    <BackgroundImage
      className="WatchWaitingRoom"
      src={event.get('coverImageUrl')}
    >
      <div className="watch-waiting-room-content">
        {countdown && <Countdown startTime={event.get('startTime')} />}
        <span className={clsx('watch-waiting-room-title', { loading })}>
          {title}
        </span>
        <span className="watch-waiting-room-subtitle">{subtitle}</span>
      </div>
    </BackgroundImage>
  )
}

export default WatchWaitingRoom
