import React from 'react'
import './PostComposeAttachmentButton.scss'
import clsx from 'clsx'

const PostComposeAttachmentButton = ({
  icon: Icon,
  title,
  component: Component = 'div',
  className,
  ...props
}) => {
  return (
    <Component
      className={clsx('PostComposeAttachmentButton', className)}
      {...props}
    >
      {Icon && <Icon className="post-compose-attachment-button-icon" />}
      {title && (
        <div className="post-compose-attachment-button-title">{title}</div>
      )}
    </Component>
  )
}

export default PostComposeAttachmentButton
