import React, { useCallback, useEffect } from 'react'
import './CheckoutSuccessPage.scss'
import { CheckRoundSvg } from 'assets/svg'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { useGetInviteCode, useMe, useRetrieveCouponCode } from 'utils'
import CheckoutProfile from 'components/Checkout/CheckoutProfile'
import queryString from 'query-string'
import { URL_EVENT, URL_USER } from 'urls'
import { useMount } from 'react-use'
import {
  trackPPVPurchaseComplete,
  trackSubscriptionPurchaseComplete,
} from 'tracking'

const CheckoutSuccessPage = () => {
  const history = useHistory()
  const location = useLocation()
  const {
    params: { userSlug, eventSlug },
  } = useRouteMatch()
  const { redirect_url: redirectUrl } = queryString.parse(location.search)
  const { removeCouponCode } = useRetrieveCouponCode(userSlug)
  const { removeInviteCode } = useGetInviteCode(userSlug)
  const me = useMe()

  const shouldFillOutProfile = me.get('nameIsSetAt') == null

  const onNext = useCallback(() => {
    if (redirectUrl) {
      history.push(redirectUrl)
    } else {
      if (eventSlug) {
        history.push(URL_EVENT(userSlug, eventSlug))
      } else {
        history.push(URL_USER(userSlug))
      }
    }
  }, [history, redirectUrl, userSlug, eventSlug])

  useEffect(() => {
    if (eventSlug) {
      // PPV Purchase
      setTimeout(() => trackPPVPurchaseComplete(), 1000)
    } else {
      // Subs Purchase
      setTimeout(() => trackSubscriptionPurchaseComplete(), 1000)
    }
  }, [userSlug, eventSlug])

  useMount(() => {
    removeCouponCode()
    removeInviteCode()
  })

  const renderAction = () => {
    if (shouldFillOutProfile) {
      return <CheckoutProfile onNext={onNext} />
    } else {
      return (
        <button
          className="button primary checkout-success-page-continue"
          onClick={onNext}
        >
          Continue
        </button>
      )
    }
  }

  return (
    <div className="CheckoutSuccessPage">
      <div className="checkout-success-page-container">
        <div className="checkout-success-page-banner">
          <div className="checkout-success-page-banner-row">
            <CheckRoundSvg className="checkout-success-page-banner-icon" />
            <div className="checkout-success-page-banner-message">
              Purchase complete
            </div>
          </div>
          <div className="checkout-success-page-banner-email">
            You will receive a confirmation on
            <br />
            {me.get('email')}
          </div>
        </div>
        {renderAction()}
      </div>
    </div>
  )
}

export default CheckoutSuccessPage
