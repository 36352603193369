import React from 'react'

import { BoonTextField } from 'components/BoonInputs'

import { SearchSvg } from 'assets/svg'

const InsightsTableSearch = ({ setSearchTerm, searchTerm, onSearch }) => {
  if (!onSearch) {
    return null
  }

  return (
    <div className="insights-table-widget-search-text-field">
      <BoonTextField
        field={{
          value: searchTerm,
          onChange: (e) => setSearchTerm(e.target.value),
          onKeyDown: (e) => {
            if (e.key === 'Enter') {
              onSearch(searchTerm)
            }
          },
        }}
        type="text"
        name="searchTerm"
        placeholder="Search"
        dark
        prepend={
          <SearchSvg className="insights-table-widget-search-text-field-icon" />
        }
      />
    </div>
  )
}

export default InsightsTableSearch
