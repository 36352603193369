import React from 'react'
import ReactMarkdown from 'react-markdown'

const LimitedReactMarkdown = ({ children, ...props }) => {
  return (
    <ReactMarkdown
      allowedTypes={[
        'root',
        'paragraph',
        'list',
        'listItem',
        'text',
        'strong',
        'link',
        'emphasis',
      ]}
      linkTarget="_blank"
      {...props}
    >
      {children}
    </ReactMarkdown>
  )
}

export default LimitedReactMarkdown
