import React, { useState } from 'react'
import './SettingsAccount.scss'
import { Formik, Form } from 'formik'
import { useSelector } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import StyledApiErrorMessage from 'components/StyledApiErrorMessage'
import clsx from 'clsx'
import { isAuthenticated, getMe } from 'store/selectors'
import { URL_FRONT_PAGE, EMAIL_HELLO, URL_MANAGE_SUBSCRIPTIONS } from 'urls'
import Avatar from 'components/Avatar'
import {
  fixImageOrientation,
  uploadToS3,
  removeNullObjectValues,
  getApiError,
} from 'utils'
import { Helmet } from 'react-helmet-async'
import { updateUser, getImageUploadUrl, getMeObject } from 'api'
import { updateMeStore } from 'store/utils/auth'
import { BoonFormikTextField } from 'components/BoonInputs'
import { CameraSvg } from 'assets/svg'
import { useToasts } from 'react-toast-notifications'

const SettingsAccount = ({ history }) => {
  const [isUploading, setUploading] = useState(false)
  const hasAuth = useSelector((state) => isAuthenticated(state))
  const me = useSelector((state) => getMe(state))
  const { addToast } = useToasts()

  if (!hasAuth) {
    history.push(URL_FRONT_PAGE)
  }

  const renderForm = ({ isSubmitting, values, isValid, setFieldValue }) => (
    <Form>
      <div className="settings-account-avatar-row">
        <label className="settings-avatar" htmlFor="settingsAvatarUpload">
          <Avatar url={values.profileImageUrl} />
          <div
            className={clsx('settings-photo-button settings-avatar-upload', {
              loading: isUploading,
            })}
          >
            <CameraSvg />
          </div>
        </label>
        <input
          type="file"
          onChange={async (e) => {
            try {
              setUploading(true)
              const inputFile = e.target.files[0]
              const file = await fixImageOrientation(inputFile)
              const { url, urlFields, photoId } = await getImageUploadUrl({
                filename: inputFile.name,
              })
              await uploadToS3({
                url,
                urlFields,
                file,
              })

              setFieldValue('profileImageFile', file)
              setFieldValue('profileImageUrl', URL.createObjectURL(file))
              setFieldValue('profileImageId', photoId)
              setUploading(false)
            } catch (e) {
              setUploading(false)
            }
          }}
          id="settingsAvatarUpload"
        />
      </div>

      <BoonFormikTextField
        name="name"
        label="Nickname"
        helperText="Choose a name people know you by or use your real name."
      />
      <BoonFormikTextField
        name="realName"
        label="Billing Name"
        helperText="Your name, used for billing purposes."
      />
      <BoonFormikTextField name="email" label="Email" />

      <StyledApiErrorMessage />
      <button
        type="submit"
        disabled={isSubmitting || !isValid}
        className={clsx(
          'button settings-account-submit primary medium loader',
          {
            loading: isSubmitting,
          }
        )}
      >
        Save changes
      </button>
    </Form>
  )

  return (
    <div className="SettingsAccount">
      <Helmet>
        <title>Settings | Account | Boon</title>
      </Helmet>
      <Formik
        initialValues={{
          profileImageUrl: me.get('profileImageUrl'),
          profileImageFile: null,
          profileImageId: null,
          name: me.get('name') || '',
          realName: me.get('realName') || '',
          email: me.get('email') || '',
        }}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          try {
            const data = {
              realName: values.realName,
              name: values.name,
              email: values.email,
              profileImageId: values.profileImageId,
            }
            removeNullObjectValues(data)
            await updateUser({ id: me.get('id'), data })

            const apiMe = await getMeObject()
            updateMeStore({ me: apiMe })

            addToast('Your profile has been updated', { appearance: 'success' })

            setSubmitting(false)
          } catch (e) {
            setErrors(getApiError(e))
            setSubmitting(false)
          }
        }}
      >
        {renderForm}
      </Formik>

      <Link
        className="settings-account-cancel-membership"
        to={URL_MANAGE_SUBSCRIPTIONS}
      >
        Cancel membership
      </Link>

      <a
        className="settings-account-terminate"
        href={`${EMAIL_HELLO}?subject=Account Termination`}
      >
        Terminate account
      </a>
    </div>
  )
}

export default withRouter(SettingsAccount)
