import React, { useState } from 'react'
import { Row, Col } from 'components/Grid'
import { useSelector } from 'react-redux'
import { getFollowing } from 'store/selectors'
import UserCard from 'components/UserCard'
import './UserDashboardFollowing.scss'
import ShowMore from 'components/ShowMore'

const UserDashboardFollowing = () => {
  const [showAll, setShowAll] = useState(false)
  const following = useSelector((state) => getFollowing(state))
  const browser = useSelector((state) => state.get('browser'))
  const shownCards = browser.greaterThan.lg ? 3 : 4

  const renderCards = () => {
    const values = following.valueSeq()
    return (showAll ? values : values.take(shownCards)).map((u) => (
      <Col xs={6} lg={4}>
        <UserCard user={u} key={u.get('id')} simple small />
      </Col>
    ))
  }

  if (following.size === 0) {
    return (
      <div className="UserDashboardFollowing">
        <div className="user-dashboard-following-empty">
          Welcome to Boon, this is where you will find the latest content from
          creators you follow.
        </div>
      </div>
    )
  }

  return (
    <div className="UserDashboardFollowing">
      <Row className="slim-gutters">{renderCards()}</Row>
      {following.size > shownCards && (
        <ShowMore active={showAll} onClick={() => setShowAll(!showAll)} />
      )}
    </div>
  )
}

export default UserDashboardFollowing
