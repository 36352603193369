import React from 'react'
import './EventHostHeader.scss'
import { EVENT_TYPE } from 'store/constants'
import {
  getEventEditUrl,
  isEventDraft,
  isEventScheduled,
  useRerenderInterval,
} from 'utils'
import { Link } from 'react-router-dom'

const EventHostHeader = ({ event, controls, onDemand = false }) => {
  useRerenderInterval(1000 * 60)

  let statusTitle = ''

  if (event != null && event.get('userType') !== EVENT_TYPE.HOST) {
    return null
  }

  if (onDemand || (event && event.get('live') === false)) {
    if (event != null && isEventScheduled(event)) {
      statusTitle = 'Scheduled'
    } else if (event == null || isEventDraft(event)) {
      statusTitle = 'Draft'
    } else if (event && event.get('archived') === true) {
      statusTitle = 'Unlisted'
    } else {
      return null
    }
  } else {
    if (event == null) {
      statusTitle = 'Draft'
    } else if (event && event.get('archived') === true) {
      statusTitle = 'Unlisted'
    } else {
      if (isEventScheduled(event)) {
        statusTitle = 'Scheduled'
      } else if (isEventDraft(event)) {
        statusTitle = 'Draft'
      } else if (!event.get('ended')) {
        return null
      } else {
        return null
      }
    }
  }

  const renderControls = () => {
    if (!controls) {
      return null
    }

    return (
      <div className="header-controls">
        <Link to={getEventEditUrl(event)} className="header-controls-control">
          Edit
        </Link>
      </div>
    )
  }

  return (
    <div className="EventHostHeader draft">
      {event?.get('test') && (
        <div className="event-card-property event-card-property-test">Test</div>
      )}
      <span className="header-status-title">{statusTitle}</span>
      {renderControls()}
    </div>
  )
}

export default EventHostHeader
