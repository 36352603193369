import React from 'react'
import ReactDOM from 'react-dom'
import { Provider as ReduxProvider } from 'react-redux'
import store from 'store'
import App from './App'
import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'
import ReactGA from 'react-ga4'
import 'styles/app.scss'
import 'styles/font/all.scss'
import {
  ENVIRONMENT,
  ENVIRONMENTS,
  SENTRY_DSN,
  SENTRY_ENVIRONMENT,
  APP_SEARCH_ENDPOINT_BASE,
  APP_SEARCH_KEY,
  APP_SEARCH_ENGINE_NAME,
} from 'appConstants'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { Settings } from 'luxon'
import { ToastProvider } from 'react-toast-notifications'
import ToastComponent from 'components/ToastComponent'
import AppSearchAPIConnector from '@elastic/search-ui-app-search-connector'
import { SearchProvider } from '@elastic/react-search-ui'

if ([ENVIRONMENTS.PRODUCTION, ENVIRONMENTS.STAGING].includes(ENVIRONMENT)) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: SENTRY_ENVIRONMENT,
    release: process.env.REACT_APP_VERCEL_GIT_COMMIT_SHA || null,
    autoSessionTracking: true,
    integrations: (defaultIntegrations) => [
      ...defaultIntegrations,
      new Integrations.Dedupe(),
    ],
    ignoreErrors: [
      '_avast_submit',
      'vc_request_action',
      'SyntaxError: JSON Parse error: Unexpected identifier "cb"',
      'TypeError: Resource blocked by content blocker',
    ],
    denyUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      /connect\.facebook\.net/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Analytics
      /google-analytics\.com/i,
    ],
    beforeSend: (event, hint) => {
      const error = hint?.originalException

      if (
        error?.message ===
          "Failed to execute 'setAttribute' on 'Element': '2f1acc6c3a606b082e5eef5e54414ffb' is not a valid attribute name." ||
        error?.message === "null is not an object (evaluating 'this.el_[e]')"
      ) {
        return null
      }

      return event
    },
  })
}

if (ENVIRONMENT === ENVIRONMENTS.PRODUCTION) {
  ReactGA.initialize('G-58LQQMST4V')
}

Settings.defaultLocale = 'en-DK'

const searchConnector = new AppSearchAPIConnector({
  searchKey: APP_SEARCH_KEY,
  engineName: APP_SEARCH_ENGINE_NAME,
  endpointBase: APP_SEARCH_ENDPOINT_BASE,
  cacheResponses: false,
})

ReactDOM.render(
  <ReduxProvider store={store}>
    <SearchProvider config={{ apiConnector: searchConnector }}>
      <ToastProvider
        components={{ Toast: ToastComponent }}
        placement="top-right"
        autoDismiss
      >
        <HelmetProvider>
          <App />
          <Helmet>
            <title>Boon</title>
          </Helmet>
        </HelmetProvider>
      </ToastProvider>
    </SearchProvider>
  </ReduxProvider>,
  document.getElementById('root')
)
