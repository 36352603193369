import React, { useContext, useState } from 'react'
import './InsightsMembersPage.scss'
import InsightsConnectedNumberWidget from 'components/InsightsNumberWidget/InsightsConnectedNumberWidget'
import InsightsConnectedChartWidget from 'components/InsightsChartWidget/InsightsConnectedChartWidget'
import { Col, Row } from 'components/Grid'
import InsightsFilters from 'routes/InsightsPage/InsightsFilters'
import InsightsContext from 'routes/InsightsPage/InsightsContext'
import { INSIGHTS_PERIODS_NAMES } from 'routes/InsightsPage/insightsConstants'
import RelationsMenuModal, {
  RELATION_TYPE,
} from 'routes/InsightsPage/components/RelationsMenuModal'
import InsightsConnectedTableWidget from 'components/InsightsTableWidget/InsightsConnectedTableWidget'
import { genericChartDataMapper } from 'routes/InsightsPage/insightsUtils'

const barChartDataMapper = (data) => data.map((h) => ({ x: h[0], y: h[1] }))
const InsightsMembersPage = ({ user }) => {
  const { period, queryParams } = useContext(InsightsContext)
  const userId = user.get('id')
  const renderNumberWidgets = () => {
    return (
      <Row className="insights-members-top">
        <Col lg={4} md={6}>
          <InsightsConnectedNumberWidget
            title={'Active members'}
            url={`member_stats/${userId}/active_members`}
            timeScale={'Total'}
          />
        </Col>
        <Col lg={4} md={6}>
          <InsightsConnectedNumberWidget
            title={'New members'}
            url={`member_stats/${userId}/gained_members`}
            timeScale={'Last 30 days'}
          />
        </Col>
        <Col lg={4} md={6}>
          <InsightsConnectedNumberWidget
            title={'Churned members'}
            url={`member_stats/${userId}/churned_members`}
            timeScale={'Last 30 days'}
            reverse
          />
        </Col>
      </Row>
    )
  }

  const renderSelectors = () => {
    return <InsightsFilters filterProducts />
  }

  const renderCharts = () => {
    return (
      <Row className="insights-members-charts">
        <Col lg={4} md={6}>
          <InsightsConnectedChartWidget
            title="Active Members"
            timeScale={INSIGHTS_PERIODS_NAMES[period]}
            url={`member_stats/${userId}/active_members_timeseries?${queryParams}`}
            dataMapper={genericChartDataMapper}
          />
        </Col>
        <Col lg={4} md={6}>
          <InsightsConnectedChartWidget
            title="New Members"
            timeScale={INSIGHTS_PERIODS_NAMES[period]}
            url={`member_stats/${userId}/gained_members_timeseries?${queryParams}`}
            dataMapper={genericChartDataMapper}
            chartType={'bar'}
          />
        </Col>
        <Col lg={4} md={6}>
          <InsightsConnectedChartWidget
            title="Churned Members"
            timeScale={INSIGHTS_PERIODS_NAMES[period]}
            url={`member_stats/${userId}/churned_members_timeseries?${queryParams}`}
            dataMapper={genericChartDataMapper}
            chartType={'bar'}
          />
        </Col>
      </Row>
    )
  }

  const renderCancelReasons = () => {
    return (
      <Row className="insights-members-cancel-reasons">
        <Col lg={12}>
          <InsightsConnectedChartWidget
            title="Cancel Reasons"
            timeScale={INSIGHTS_PERIODS_NAMES[period]}
            url={`member_stats/${userId}/cancel_reasons?${queryParams}`}
            dataMapper={barChartDataMapper}
            chartType={'bar'}
          />
        </Col>
      </Row>
    )
  }

  const renderMemberTable = () => {
    const headers = [
      { key: 'name', type: 'profile', active: true },
      { key: 'email', type: 'string', active: true },
      {
        key: 'creatorConsent',
        name: 'Email Consent',
        type: 'yesno',
        active: false,
      },
      {
        key: 'lifetimeValue',
        name: 'Lifetime value',
        type: 'currency',
        active: true,
      },
      {
        key: 'lifetimeDays',
        name: 'Lifetime days',
        type: 'integer',
        active: true,
      },
      {
        key: 'billingName',
        name: 'Billing Cycle',
        type: 'string',
        active: false,
      },
      {
        key: 'membershipNames',
        name: 'Memberships',
        type: 'string',
        active: true,
      },
      {
        key: 'memberSince',
        name: 'Member Since',
        type: 'date',
        active: true,
      },
      {
        key: 'avgVideoRating',
        name: 'Avg. video rating',
        type: 'rating',
        active: true,
      },
      {
        key: 'latestVideoFeedback',
        name: 'Latest feedback',
        type: 'string',
        active: true,
      },
      {
        key: 'latestCancelDate',
        name: 'Cancel date',
        type: 'date',
        active: false,
      },
      {
        key: 'latestCancelReason',
        name: 'Cancel reason',
        type: 'pill',
        active: false,
      },
      {
        key: 'latestCancelFeedback',
        name: 'Cancel feedback',
        type: 'string',
        active: false,
      },
    ]
    const availableFilters = [
      { key: 'active', value: 'Active members', active: true },
      { key: 'churned', value: 'Churned members', active: false },
      { key: 'feedback', value: 'Feedback', active: false },
      {
        key: 'cancel_reason',
        value: 'Cancel Reason',
        active: false,
        showHeadersOnEnable: ['latestCancelReason'],
      },
      {
        key: 'cancel_feedback',
        value: 'Cancel Feedback',
        active: false,
        showHeadersOnEnable: ['latestCancelFeedback'],
      },
    ]

    return (
      <InsightsConnectedTableWidget
        title="Members"
        baseUrl={`users/${userId}/members`}
        dataKey={'users'}
        headers={headers}
        defaultFilters={availableFilters}
        defaultSortBy={'name asc'}
        clickableRow={(obj) => setMenuModalUserId(obj['id'])}
      />
    )
  }

  const [menuModalUserId, setMenuModalUserId] = useState(null)

  return (
    <div className="InsightsMembersPage">
      {renderNumberWidgets()}
      {renderSelectors()}
      {renderCharts()}
      {renderCancelReasons()}
      {renderMemberTable()}
      <RelationsMenuModal
        hostId={userId}
        userId={menuModalUserId}
        onClose={() => setMenuModalUserId(null)}
        type={RELATION_TYPE.RELATION}
      />
    </div>
  )
}

export default InsightsMembersPage
