import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import clsx from 'clsx'

// Styles
import './Modal.scss'

class Modal extends Component {
  static propTypes = {
    onClose: PropTypes.func,
  }

  get onClose() {
    return this.props.onClose
  }

  get children() {
    return this.props.children
  }

  get className() {
    return clsx('modal', this.props.className)
  }

  get otherProps() {
    const { onClose, children, className, overlay, ...otherProps } = this.props
    return otherProps
  }

  render() {
    const { overlay } = this.props

    return ReactDOM.createPortal(
      <div
        className={this.className}
        onClick={(e) => e.stopPropagation()}
        {...this.otherProps}
      >
        {overlay && <div className="modal-overlay" onClick={this.onClose} />}
        <div className="modal-content">{this.children}</div>
      </div>,
      document.getElementById('root')
    )
  }
}

export default Modal
