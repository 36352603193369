import React from 'react'
import './TicketCard.scss'
import { requireAuth } from 'store/utils/awaitStore'
import { AUTH_MODAL_STATE } from 'store/constants'
import { useHistory } from 'react-router-dom'
import { displayPrice, getEventCheckoutUrl } from 'utils'
import {
  RadioCheckedSvg,
  RadioUncheckedSvg,
  TicketAlternativeSvg,
} from 'assets/svg'
import clsx from 'clsx'
import { EXPANDED_CARD_TICKET_KEY } from 'modals/CheckoutModal/CheckoutModal'

const TicketCard = ({ event, collapsible, collapsed, onExpand }) => {
  const history = useHistory()
  const checkoutUrl = getEventCheckoutUrl(event)

  if (event.get('price') == null) {
    return null
  }

  const live = event.get('live') && !event.get('ended')

  const onCtaClick = async () => {
    if (
      await requireAuth(
        AUTH_MODAL_STATE.SIGNUP,
        true,
        'Sign up to complete your purchase'
      )
    ) {
      history.push(checkoutUrl)
    }
  }

  const Radio = collapsed ? RadioUncheckedSvg : RadioCheckedSvg

  return (
    <div
      className={clsx('TicketCard', {
        collapsible,
        collapsed: collapsed,
      })}
    >
      <div className="ticket-card-wrapper">
        <div
          className="ticket-card-header"
          onClick={() =>
            collapsed ? onExpand(EXPANDED_CARD_TICKET_KEY) : null
          }
        >
          <div className="ticket-card-image">
            <TicketAlternativeSvg className="ticket-card-icon" />
          </div>
          <div className="ticket-card-header-info">
            <div className="ticket-card-title">Ticket</div>
            <div className="ticket-card-price">
              {displayPrice(
                parseFloat(event.get('price')),
                event.get('currency')
              )}
            </div>
          </div>
          {collapsible && <Radio className="ticket-card-collapsible-radio" />}
        </div>
        <button
          className="button creator-primary ticket-card-cta"
          onClick={onCtaClick}
        >
          Buy
        </button>
        <ul className="ticket-card-perks">
          {live && <li>Access live event</li>}
          {live && <li>Participate in live chat and comment section</li>}
          {!live && <li>Access video on-demand</li>}
          {!live && <li>Access comment section on-demand</li>}
          <li>Replay again and again</li>
        </ul>
      </div>
    </div>
  )
}

export default TicketCard
