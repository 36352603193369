import React from 'react'
import { Background } from './ImgixBackgroundImage'
import { transformSrc } from './ImgixUtils'
import { useWasInView } from 'utils'
import { ENVIRONMENT, ENVIRONMENTS } from 'appConstants'

export const BackgroundImage = ({ src, imgixParams = {}, ...props }) => {
  const [ref, inView] = useWasInView()
  const imgIxUrl = transformSrc(src)

  if (!inView) {
    return <div ref={ref} {...props}></div>
  }

  if (
    (imgIxUrl ?? '').indexOf('imgix.net') > -1 &&
    ENVIRONMENT === ENVIRONMENTS.PRODUCTION
  ) {
    return (
      <Background
        src={imgIxUrl}
        imgixParams={{ q: 75, auto: 'format', ...imgixParams }}
        {...props}
      />
    )
  } else {
    return (
      <div
        style={{ backgroundImage: `url(${src})`, ...props.style }}
        {...props}
      />
    )
  }
}
