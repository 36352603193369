import React from 'react'
import './ProfileMenu.scss'
import { getUserUrl, useMe } from 'utils'
import { Link } from 'react-router-dom'

import Avatar from 'components/Avatar'

const ProfileMenu = ({ user, onClick }) => {
  const userUrl = getUserUrl(user)
  const me = useMe()
  const affiliation =
    me.get('id') === user.get('id')
      ? 'Owner'
      : me.get('subscriptionAccounts').has(user.get('id'))
      ? 'Member'
      : me.get('following').has(user.get('id'))
      ? 'Following'
      : null

  return (
    <div className="ProfileMenu" onClick={onClick}>
      <Avatar
        className="profile-menu-avatar"
        url={user.get('profileImageUrl')}
        userId={user.get('id')}
      />
      <div className="profile-menu-name">{user.get('name')}</div>
      <div className="profile-menu-affiliation">{affiliation}</div>
      <Link to={userUrl} className="profile-menu-link">
        Profile
      </Link>
      <Link to={userUrl + '/about'} className="profile-menu-link">
        About
      </Link>
    </div>
  )
}

export default ProfileMenu
