import React from 'react'
import { connect, useSelector } from 'react-redux'
import { openAuthModal, closeAuthModal } from 'store/actions'
import { Formik, Form, Field } from 'formik'
import { AUTH_MODAL_STATE } from 'store/constants'
import clsx from 'clsx'
import * as Yup from 'yup'
import StyledErrorMessage from 'components/StyledErrorMessage'
import StyledApiErrorMessage from 'components/StyledApiErrorMessage'
import { URL_TERMS_AND_CONDITIONS } from 'urls'
import { Link } from 'react-router-dom'
import { signUpStore } from 'store/utils/auth'
import { BoonFormikTextField } from 'components/BoonInputs'
import { trackSignUp } from 'tracking'
import { getAuthMessage } from 'store/utils/awaitStore'

const SignUpSchema = Yup.object().shape({
  realName: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  password: Yup.string().required('Required'),
})

const SignUpView = ({ dispatch }) => {
  const message = useSelector((state) => getAuthMessage(state))
  const handleSignIn = () => {
    dispatch(openAuthModal({ state: AUTH_MODAL_STATE.SIGNIN }))
  }

  return (
    <section>
      {message && <div className="signup-message">{message}</div>}
      <Formik
        initialValues={{
          realName: '',
          email: '',
          password: '',
          terms: false,
        }}
        validationSchema={SignUpSchema}
        validate={({ terms, email, repeatEmail }) => {
          const errors = {}
          if (terms !== true) {
            errors.terms = 'Please accept the terms and conditions'
          }

          if (email !== repeatEmail) {
            errors.repeatEmail = "Emails don't match"
          }

          return errors
        }}
        onSubmit={(values, { setSubmitting, setErrors }) => {
          signUpStore(values).then(
            () => {
              setSubmitting(false)
              dispatch(closeAuthModal({ newlyCreated: true }))
              trackSignUp()
            },
            (error) => {
              setErrors(
                error?.response?.data ?? 'An error occurred, please try again'
              )
              setSubmitting(false)
            }
          )
        }}
      >
        {({ isSubmitting, setFieldValue, values, errors }) => (
          <Form>
            <div className="signup-field-name">
              <BoonFormikTextField
                type="text"
                name="realName"
                placeholder="Name"
              />
            </div>
            <BoonFormikTextField
              type="email"
              name="email"
              placeholder="Email"
            />
            <BoonFormikTextField
              type="email"
              name="repeatEmail"
              placeholder="Confirm email"
            />
            <BoonFormikTextField
              type="password"
              name="password"
              placeholder="Password"
            />
            <Field
              id="terms"
              name="terms"
              type="checkbox"
              className="boon-checkbox"
            />
            <label className="terms" htmlFor="terms">
              I agree to Boon’s&nbsp;
              <Link
                to={URL_TERMS_AND_CONDITIONS}
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms & Conditions
              </Link>
            </label>
            <StyledErrorMessage name="terms" />
            <button
              type="submit"
              disabled={isSubmitting}
              className={clsx('button primary medium loader', {
                loading: isSubmitting,
              })}
            >
              Confirm
            </button>
            <StyledApiErrorMessage />
          </Form>
        )}
      </Formik>

      <p className="signup">
        Already have an account?&nbsp;
        <span onClick={handleSignIn} className="textlink">
          Log in
        </span>
      </p>
    </section>
  )
}

export default connect()(SignUpView)
