import React, { useRef, useState } from 'react'
import './HeaderControls.scss'
import HeaderMenu from 'components/Header/HeaderMenu'
import {
  AddSvg,
  ArrowDropDownSvg,
  AssessmentSvg,
  BurgerMenuSvg,
} from 'assets/svg'
import HeaderBurger from 'components/Header/HeaderBurger'
import { Link } from 'react-router-dom'
import { URL_INSIGHTS_OVERVIEW, URL_FRONT_PAGE } from 'urls'
import { useDispatch, useSelector } from 'react-redux'
import { isAuthenticated, isMeCreator } from 'store/selectors'
import { openAuthModal } from 'store/actions'
import { AUTH_MODAL_STATE } from 'store/constants'
import clsx from 'clsx'
import Avatar from 'components/Avatar'
import { useCreatorGetStartedCta, useMe } from 'utils'
import DashboardCreateButton from 'routes/FeedPage/DashboardCreateButton'
import HeaderNotifications from 'components/HeaderNotifications'
import HeaderControlsSearchBox from 'components/HeaderControls/HeaderControlsSearchBox'
import HeaderControlsBurgerMenuModal from 'components/HeaderControls/HeaderControlsBurgerMenuModal'

const HeaderControls = ({ white, landingPage }) => {
  const dispatch = useDispatch()
  const hasAuth = useSelector((state) => isAuthenticated(state))
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const browser = useSelector((state) => state.get('browser'))
  const me = useMe()
  const isCreator = useSelector((state) => isMeCreator(state))
  const onCtaClick = useCreatorGetStartedCta()
  const menuRef = useRef()

  const [burgerMenuActive, setBurgerMenuActive] = useState(false)
  const showBurgerMenu = !(browser.lessThan.md && landingPage)

  const handleSignIn = () => {
    dispatch(openAuthModal({ state: AUTH_MODAL_STATE.SIGNIN }))
  }

  const onHelpClick = () => {
    if (window.Intercom != null) {
      window.Intercom('show')
    }
  }

  const renderMenu = () => {
    if (browser.greaterThan.md) {
      return (
        <HeaderMenu
          isOpen={isMenuOpen}
          setIsOpen={setIsMenuOpen}
          menuRef={menuRef}
        />
      )
    } else {
      return <HeaderBurger isOpen={isMenuOpen} setIsOpen={setIsMenuOpen} />
    }
  }

  const renderInner = () => {
    if (hasAuth && showBurgerMenu) {
      return (
        <div className="header-menu-controls-items">
          {isCreator && (
            <DashboardCreateButton className="header-controls-menu-create">
              <AddSvg />
            </DashboardCreateButton>
          )}
          {isCreator && browser.greaterThan.md && (
            <Link
              to={URL_INSIGHTS_OVERVIEW}
              className="header-controls-menu-iconbutton"
            >
              <AssessmentSvg />
            </Link>
          )}
          <HeaderNotifications />
          <div className="header-controls-menu" ref={menuRef}>
            <div
              className="header-controls-menu-button"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              <Avatar
                className="header-controls-menu-user-image"
                url={me.get('profileImageUrl')}
                userId={me.get('id')}
              />
              <ArrowDropDownSvg className="header-controls-menu-button-icon" />
            </div>
            {renderMenu()}
          </div>
        </div>
      )
    } else if (hasAuth) {
      return (
        <Link className="header-controls-profile" to={URL_FRONT_PAGE}>
          Profile
        </Link>
      )
    } else {
      return (
        <div className="header-controls-signin" onClick={handleSignIn}>
          Login
        </div>
      )
    }
  }

  const renderBurgerMenu = () => {
    return (
      <span className="header-controls-landing-page-burger-menu">
        <BurgerMenuSvg onClick={() => setBurgerMenuActive(true)} />
        <HeaderControlsBurgerMenuModal
          active={burgerMenuActive}
          hasAuth={hasAuth}
          isCreator={isCreator}
          onClose={() => setBurgerMenuActive(false)}
          handleSignIn={handleSignIn}
          onCtaClick={onCtaClick}
        />
      </span>
    )
  }

  return (
    <div className={clsx('HeaderControls', { white })}>
      {landingPage && browser.greaterThan.md && <HeaderControlsSearchBox />}
      {landingPage && !isCreator && (
        <button
          onClick={onCtaClick}
          className="button primary header-controls-landing-page-cta"
        >
          Apply for partnership
        </button>
      )}
      <span className="header-controls-help" onClick={onHelpClick}>
        Help
      </span>
      {renderInner()}
      {landingPage && browser.lessThan.md && renderBurgerMenu()}
    </div>
  )
}

export default HeaderControls
