import React from 'react'
import './FilesGrid.scss'
import DocumentItem from 'components/Gallery/DocumentItem'
import { Col, Row } from 'components/Grid'
import PostCompose from 'components/PostCompose'
import EmptyList from 'components/EmptyList'

const FilesGrid = ({
  user,
  posts,
  dispatchPosts,
  filters,
  dispatchFilter,
  onNoneAccessPostClick,
  me,
}) => {
  const meId = me.get('id')

  const renderFiles = () => {
    if (posts.size === 0) {
      return <EmptyList filters={filters} dispatchFilter={dispatchFilter} />
    }

    return posts
      .sortBy((post) => post.get('lastActivityAt'))
      .reverse()
      .toList()
      .map((post, idx) => {
        return (
          <Col sm={12} md={4} lg={4} xl={3} key={post.get('id')}>
            <DocumentItem
              post={post}
              onNoneAccessPostClick={onNoneAccessPostClick}
            />
          </Col>
        )
      })
  }

  return (
    <div className="FilesGrid">
      {user.get('id') === meId && (
        <PostCompose
          user={user}
          dispatchPosts={dispatchPosts}
          defaultProductId={filters.product_id}
        />
      )}
      <Row className="">{renderFiles()}</Row>
    </div>
  )
}

export default FilesGrid
