import React, { useEffect } from 'react'
import './AvailabilityPanel.scss'
import { Col, Row } from 'components/Grid'
import { useGetProductsApi } from 'api'
import {
  BoonFormikInputAddonSelect,
  BoonFormikTextField,
} from 'components/BoonInputs'
import { Field, useFormikContext } from 'formik'
import Currencies from 'data/Currencies'
import clsx from 'clsx'

const AvailabilityPanel = ({ edit }) => {
  const { data: products, error: productsError } = useGetProductsApi()
  const hasMembership = products != null && products.size > 0

  const {
    values,
    errors,
    setFieldValue,
    setFieldTouched,
    touched,
  } = useFormikContext()

  useEffect(() => {
    if (values.availabilityPick === 'free') {
      setFieldValue('price', '')
      setFieldValue('productIds', [])
      setFieldValue('availableOnDemand', true)
    }
  }, [values.availabilityPick, setFieldValue])

  useEffect(() => {
    if (products != null && products.size > 0 && !edit) {
      setFieldValue('productIds', products.map((p) => p.get('id')).toArray())
    }
  }, [setFieldValue, products, edit])

  if (productsError) {
    alert('Error loading, please refresh the page')
    return null
  }

  const renderMembership = () => {
    if (!hasMembership) {
      return null
    }

    const productsList = products.map((product) => (
      <div key={product.get('id')}>
        <input
          id={product.get('id')}
          type="checkbox"
          className="boon-dark-checkbox"
          checked={values.productIds.includes(product.get('id'))}
          onChange={(e) => {
            setFieldTouched('productIds')
            if (e.target.checked) {
              setFieldValue('productIds', [
                ...values.productIds,
                product.get('id'),
              ])
            } else {
              setFieldValue(
                'productIds',
                values.productIds.filter((id) => id !== product.get('id'))
              )
            }
          }}
        />
        <label className="product-info" htmlFor={product.get('id')}>
          {product.get('name')}
        </label>
      </div>
    ))

    return (
      <Col xs={12} md={6} className="availability-section">
        <div className="availability-section-header">Membership</div>
        <div className="availability-section-subheader">
          Allow access as part of membership plan.
        </div>
        {productsList}
      </Col>
    )
  }

  const renderPayPerView = () => {
    return (
      <Col xs={12} md={6} className="availability-section">
        <div className="availability-section-header">Pay-per-view</div>
        <div className="availability-section-subheader">
          Allow people to purchase on pay-per-view basis.
        </div>
        <Field
          id="payPerViewChecked"
          name="payPerViewChecked"
          type="checkbox"
          className="boon-dark-checkbox"
          disabled={!hasMembership}
        />
        <label htmlFor="payPerViewChecked">Pay-Per-View</label>
        {values.payPerViewChecked && (
          <div className="availability-panel-indent">
            <BoonFormikTextField
              type="text"
              name="price"
              label="Price"
              placeholder="0"
              prepend={
                <BoonFormikInputAddonSelect
                  type="text"
                  name="currency"
                  label="Currency"
                >
                  {Currencies.map((c) => (
                    <option value={c} key={c}>
                      {c.toUpperCase()}
                    </option>
                  ))}
                </BoonFormikInputAddonSelect>
              }
            />
            {errors.currency && touched.currency && (
              <div className="input-error">{errors.currency}</div>
            )}
            {values.live && (
              <BoonFormikTextField
                type="text"
                name="seats"
                label="Number of live seats"
                placeholder="0"
              />
            )}
            {values.live && (
              <Field
                id="availableOnDemand"
                name="availableOnDemand"
                className="boon-dark-checkbox"
                type="checkbox"
              />
            )}
            {values.live && (
              <label className="availableOnDemand" htmlFor="availableOnDemand">
                Available for purchase after event
              </label>
            )}
          </div>
        )}
      </Col>
    )
  }

  return (
    <div className="AvailabilityPanel">
      <div className="availability-panel-header">
        <div className="availability-panel-header-title">Availability</div>
      </div>

      <div className="availability-panel-content">
        <div className="availability-item">
          <Field
            id={`availability-free`}
            name="availabilityPick"
            type="radio"
            className="boon-dark-radio"
            value="free"
          />
          <label
            htmlFor={`availability-free`}
            data-testid={`availability-label-free`}
          >
            Free
            <div className="availability-panel-item-description">
              Available for followers
            </div>
          </label>
          {values.live && (
            <Row
              className={clsx('availability-panel-row', {
                hide: values.availabilityPick === 'paid',
              })}
            >
              <div className="availability-panel-item-seats">
                <BoonFormikTextField
                  type="text"
                  name="seats"
                  label="Number of live seats"
                  placeholder="0"
                />
              </div>
            </Row>
          )}
        </div>
        <hr />
        <div className="availability-item">
          <Field
            id={`availability-paid`}
            name="availabilityPick"
            type="radio"
            className="boon-dark-radio"
            value="paid"
          />
          <label
            htmlFor={`availability-paid`}
            data-testid={`availability-label-free`}
          >
            Paid
            <div className="availability-panel-item-description">
              Only available bye pay-per-view and/or membership
            </div>
          </label>

          <Row
            className={clsx('availability-panel-row', {
              hide: values.availabilityPick === 'free',
            })}
          >
            {renderPayPerView()}
            {renderMembership()}
          </Row>
        </div>
      </div>

      {errors.productIds && touched.productIds && (
        <div className="input-error">{errors.productIds}</div>
      )}
    </div>
  )
}

export default AvailabilityPanel
