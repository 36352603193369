import React, { useCallback } from 'react'
import './AccessSelectorModal.scss'
import NamedPinnedModal from 'components/NamedPinnedModal'
import {
  CheckBoxCheckedSvg,
  CheckBoxUncheckedSvg,
  LockOpenSvg,
  LockSvg,
  RadioCheckedSvg,
  RadioUncheckedSvg,
  PublicSvg,
} from 'assets/svg'
import clsx from 'clsx'

const AccessSelectorModal = ({
  title,
  active,
  onClose,
  products,
  productIds,
  setProductIds,
  publicValue,
  setPublic,
}) => {
  const hasProducts = products.size > 0

  const onPublicClick = useCallback(() => {
    setProductIds([])
    setPublic(true)
  }, [setProductIds, setPublic])

  const onFollowersClick = useCallback(() => {
    setProductIds([])
    setPublic(false)
  }, [setProductIds, setPublic])

  const onMembersOnlyClick = useCallback(() => {
    setProductIds(products.map((p) => p.get('id')).toArray())
    setPublic(false)
  }, [products, setProductIds, setPublic])

  const onProductClick = useCallback(
    (productId) => () => {
      setPublic(false)
      if (productIds.includes(productId)) {
        setProductIds(productIds.filter((id) => id !== productId))
      } else {
        setProductIds([...productIds, productId])
      }
    },
    [setProductIds, setPublic, productIds]
  )

  const renderProducts = () => {
    if (products.size === 1) {
      return null
    }

    return products.map((product) => (
      <AccessSelectorCheckbox
        checked={productIds.includes(product.get('id'))}
        onClick={onProductClick(product.get('id'))}
        title={product.get('name')}
        key={product.get('id')}
        count={product.get('memberCount')}
      />
    ))
  }

  return (
    <NamedPinnedModal
      title={title}
      active={active}
      onClose={onClose}
      className="AccessSelectorModal"
    >
      <div>
        <AccessSelectorRadioButton
          title="Public"
          description="Post is visible to anyone"
          icon={PublicSvg}
          checked={publicValue}
          onClick={onPublicClick}
        />
        <AccessSelectorRadioButton
          title="Followers"
          description="All followers and members have access"
          icon={LockOpenSvg}
          checked={!publicValue && productIds.length === 0}
          onClick={onFollowersClick}
          count={products.reduce(
            (acc, product) => acc + product.get('memberCount'),
            0
          )}
        />
        <AccessSelectorRadioButton
          title="Members Only"
          description={
            hasProducts
              ? 'Only selected memberships have access'
              : 'You have no memberships'
          }
          icon={LockSvg}
          checked={!publicValue && productIds.length > 0}
          disabled={!hasProducts}
          onClick={onMembersOnlyClick}
          count={products.reduce(
            (acc, product) =>
              (acc += productIds.includes(product.get('id'))
                ? product.get('memberCount')
                : 0),
            0
          )}
        >
          {renderProducts()}
        </AccessSelectorRadioButton>
      </div>

      <button
        className="button creator-primary access-selector-modal-button"
        onClick={onClose}
      >
        Done
      </button>
    </NamedPinnedModal>
  )
}

export default AccessSelectorModal

const AccessSelectorRadioButton = ({
  checked,
  title,
  description,
  icon: Icon,
  disabled,
  onClick,
  children,
  count,
}) => {
  const Radio = checked ? RadioCheckedSvg : RadioUncheckedSvg

  return (
    <div className={clsx('AccessSelectorRadioButton', { disabled })}>
      <Radio
        className="access-selector-radio-button-radio"
        onClick={!disabled && onClick}
      />
      <div className="access-selector-radio-button-inner">
        <div
          className="access-selector-radio-button-header"
          onClick={!disabled && onClick}
        >
          <div>
            <div className="access-selector-radio-button-title">
              {title}
              {count > 0 && (
                <span className="access-selector-count">({count})</span>
              )}
            </div>
            <div className="access-selector-radio-button-description">
              {description}
            </div>
          </div>
          <Icon className="access-selector-radio-button-icon" />
        </div>
        {children && (
          <div className="access-selector-radio-button-content">{children}</div>
        )}
      </div>
    </div>
  )
}

const AccessSelectorCheckbox = ({
  checked,
  title,
  description,
  onClick,
  count,
}) => {
  const Check = checked ? CheckBoxCheckedSvg : CheckBoxUncheckedSvg

  return (
    <div className="AccessSelectorCheckbox" onClick={onClick}>
      <Check className="access-selector-checkbox-check" />
      <div className="access-selector-checkbox-inner">
        <div className="access-selector-checkbox-title">
          {title}
          {count > 0 && (
            <span className="access-selector-count">({count})</span>
          )}
        </div>
        <div className="access-selector-checkbox-description">
          {description}
        </div>
      </div>
    </div>
  )
}
