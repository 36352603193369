import React from 'react'
import { StarSvg, StarBorderSvg, StarHalfSvg } from 'assets/svg'

export const displayStars = (rating) => {
  if (!rating) {
    return <em>No rating available</em>
  }

  const value = parseFloat(rating)
  const wholeStars = Math.floor(value)
  const halfStar = value - wholeStars >= 0.5
  const blankStars = 5 - wholeStars - (halfStar ? 1 : 0)

  return (
    <span title={value.toFixed(1)}>
      {Array.from(Array(wholeStars)).map((_, idx) => (
        <StarSvg key={100 + idx} className="star" />
      ))}
      {halfStar && <StarHalfSvg key={150} className="half-star" />}
      {Array.from(Array(blankStars)).map((_, idx) => (
        <StarBorderSvg key={200 + idx} className="empty-star" />
      ))}
      <span key={300} className="star-rating">
        {value.toFixed(1)}
      </span>
    </span>
  )
}
