import React from 'react'
import { Col, Row } from 'components/Grid'
import './CreatorSetupMembership.scss'
import CreatorSettingsHeader from 'routes/CreatorSettingsPage/CreatorSettingsHeader'
import ProfileCompletion from './ProfileCompletion'
import CreatorMembershipUpsert from 'components/CreatorMembershipUpsert/CreatorMembershipUpsert'

const CreatorSetupMembership = ({ user, triedLaunching }) => {
  return (
    <div className="CreatorSetupMembership">
      <CreatorSettingsHeader
        title="Memberships"
        description="Charge your members on a monthly basis."
      />
      <Row>
        <Col xs={12} lg={9}>
          <CreatorMembershipUpsert user={user} />
        </Col>
        <Col xs={12} lg={3}>
          <ProfileCompletion user={user} triedLaunching={triedLaunching} />
        </Col>
      </Row>
    </div>
  )
}

export default CreatorSetupMembership
