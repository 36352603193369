import React from 'react'
import './HybridMenuBox.scss'
import { useOutsideAlerter } from 'utils'

const HybridMenuBox = ({ onClose, children, outerRef }) => {
  useOutsideAlerter(outerRef, true, onClose)

  return (
    <div className="HybridMenuBox">
      <div className="hybrid-menu-box-content">{children}</div>
    </div>
  )
}

export default HybridMenuBox
