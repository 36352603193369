import React, { useState, useEffect } from 'react'
import './LeadQualificationAddSocialModal.scss'
import NamedPinnedModal from 'components/NamedPinnedModal'
import { BoonTextField } from 'components/BoonInputs'
import { useFormikContext } from 'formik'
import { SETUP_SOCIAL_ITEMS } from 'routes/CreatorSetupPage/CreatorSetupForm'

const LeadQualificationAddSocialModal = ({ active, onClose, chosenSocial }) => {
  const [value, setValue] = useState('')
  const [
    primarySocialFollowersCount,
    setPrimarySocialFollowersCount,
  ] = useState('')
  const { setFieldValue, values } = useFormikContext()

  const chosenSocialObject =
    SETUP_SOCIAL_ITEMS.find((o) => o.key === chosenSocial) || {}

  const formUrlValue = values[chosenSocial]
  useEffect(() => {
    setValue(formUrlValue)
  }, [active, formUrlValue])

  const formCountValue = values.primarySocialFollowersCount
  useEffect(() => {
    setPrimarySocialFollowersCount(formCountValue)
  }, [active, formCountValue])

  const handleClose = () => {
    setValue('')
    setPrimarySocialFollowersCount('')
    onClose()
  }

  const onSubmit = () => {
    if (!value) {
      return null
    }

    setFieldValue(chosenSocial, value)
    setFieldValue('primarySocial', chosenSocialObject.type)
    setFieldValue('primarySocialFollowersCount', primarySocialFollowersCount)
    handleClose()
  }

  return (
    <NamedPinnedModal
      title="Social channel"
      active={active}
      onClose={handleClose}
    >
      <div className="LeadQualificationAddSocialModal">
        <div className="lead-qualification-add-social-form">
          <BoonTextField
            type="url"
            field={{
              value: value,
              name: chosenSocial,
              onChange: (e) => setValue(e.target.value),
              'data-testid': 'lead-qualification-add-social-modal-url',
            }}
            label={`${chosenSocialObject.name} Link`}
            helperText={
              chosenSocial !== 'homepageUrl'
                ? 'Write your SoMe URL to add a link to your profile.'
                : 'Write the URL of your website.'
            }
            placeholder={chosenSocialObject.placeholder}
          />

          <BoonTextField
            type="number"
            field={{
              value: primarySocialFollowersCount,
              name: 'primarySocialFollowersCount',
              onChange: (e) => setPrimarySocialFollowersCount(e.target.value),
              min: 0,
              'data-testid': 'lead-qualification-add-social-modal-followers',
            }}
            label={`${chosenSocialObject.name} followers`}
            helperText="Insert your number of followers"
            placeholder="Eg. 28000"
          />

          <button
            className="button primary lead-qualification-add-social-form-submit"
            onClick={onSubmit}
            disabled={
              !value ||
              primarySocialFollowersCount === '' ||
              primarySocialFollowersCount == null ||
              primarySocialFollowersCount < 0
            }
            data-testid="lead-qualification-add-social-modal-submit"
          >
            Add link
          </button>
        </div>
      </div>
    </NamedPinnedModal>
  )
}

export default LeadQualificationAddSocialModal
