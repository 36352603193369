import React, { useCallback, useEffect, useReducer, useState } from 'react'
import './NewPayoutDetail.scss'
import Spinner from 'components/Spinner'
import { DateTime } from 'luxon'
import {
  getPayoutInvoicePdf,
  useGetCompanyApi,
  useGetPayoutApi,
  getBalanceTransactionsApi,
} from 'api'
import { CloudDownloadSvg } from 'assets/svg'
import BalanceTransactionList from './BalanceTransactionList'
import { useHistory, useLocation } from 'react-router-dom'
import { URL_SETTINGS_BUSINESS_LEGACY } from 'urls'
import BalanceTransactionSum from './BalanceTransactionSum'
import LoadingError from 'components/LoadingError'
import { useInfiniteScrollBody, getApiError, displayPrice } from 'utils'
import { useAsync } from 'react-use'
import { Map } from 'immutable'
import { getAccountingNumberFormatter } from './AccountingConstants'

const balanceTransactionReducer = (state, transactions) => {
  return state.merge(transactions.map((v) => [v.get('id'), v]))
}

const NewPayoutDetail = ({ payoutId }) => {
  const [error, setError] = useState(null)

  const history = useHistory()
  const location = useLocation()
  const {
    data: payout,
    loading,
    error: payoutError,
    refresh: payoutRefresh,
  } = useGetPayoutApi({ payoutId }, [payoutId])

  const numberFormatter = getAccountingNumberFormatter()
  const formatCurrency = (amount) =>
    displayPrice(numberFormatter.format(amount / 100), payout.get('currency'))

  const { data: company, companyLoading } = useGetCompanyApi()
  const [
    downloadingPayoutInvoicePdf,
    setDownloadingPayoutInvoicePdf,
  ] = useState(false)

  if (payoutError != null) {
    return <LoadingError refresh={payoutRefresh} />
  }

  if (payout == null || loading || companyLoading) {
    return <Spinner />
  }

  const downloadPayoutInvoicePdf = async () => {
    if (company == null) {
      if (
        window.confirm(
          'To download invoices, you must first enter some additional information. Would you like to do that now?'
        )
      ) {
        history.push(
          `${URL_SETTINGS_BUSINESS_LEGACY}?redirect_url=${location.pathname}#businessInformation`
        )
      }
      return null
    }

    setDownloadingPayoutInvoicePdf(true)
    try {
      const data = await getPayoutInvoicePdf({ payoutId })
      const src = URL.createObjectURL(data)
      const link = document.createElement('a')
      link.href = src
      link.target = '_blank'
      link.setAttribute('download', `${payoutId}.pdf`)
      document.body.appendChild(link)
      link.click()
    } catch (e) {
      alert(
        'We encountered an error while preparing the invoice PDF, please contact support'
      )
    }
    setDownloadingPayoutInvoicePdf(false)
  }

  const renderStatus = () => {
    switch (payout.get('status')) {
      case 'paid':
        return (
          <span className="payout-detail-status-tag payout-detail-status-tag-paid">
            Paid
          </span>
        )

      case 'in_transit':
        return (
          <span className="payout-detail-status-tag payout-detail-status-tag-pending">
            In Transit
          </span>
        )

      default:
        return (
          <span className="payout-detail-status-tag payout-detail-status-tag-pending">
            Pending
          </span>
        )
    }
  }

  const arrivalDate = DateTime.fromISO(payout.get('arrivalDate')).toFormat(
    'MMM d, yyyy'
  )

  return (
    <div className="NewPayoutDetail">
      <div className="payout-warning">
        <div className="payout-warning-title">Regarding payouts</div>
        <p>We've changed our billing and payout terms and process.</p>
        <p>
          Before, when you downloaded your invoice, it was a “Self Billing
          Invoice”. As part of this process, we collected VAT from your Sales
          and transferred it to your local tax authorities.
        </p>
        <p>
          Beginning 1st of April 2024, the payout will instead be a “Invoice and
          Payout Specification”. As part of this process, we no longer collect
          VAT on your behalf. Instead it is paid out to you as part of your
          regular monthly payout, and you are now responsible for filing VAT.
        </p>
        <b>For DK creators</b>
        <p>
          DK creators will also be charged 25% VAT on our Boon fee. This is
          specified on your “Invoice and Payout Specification”.
        </p>
      </div>

      <div className="payout-detail-header">
        <div>
          <div className="payout-detail-header-title">
            <b>{arrivalDate}</b>
          </div>
          <div className="payout-detail-header-amount">
            <b>{formatCurrency(payout.get('amount'))}</b>
          </div>
        </div>
        <div className="payout-detail-header-right">{renderStatus()}</div>
      </div>
      <button
        className="button secondary small payout-detail-download"
        disabled={downloadingPayoutInvoicePdf}
        onClick={downloadPayoutInvoicePdf}
      >
        <CloudDownloadSvg />{' '}
        {downloadingPayoutInvoicePdf
          ? 'Downloading...'
          : 'Download Invoice and Payout Specification'}
      </button>
    </div>
  )
}

export default NewPayoutDetail
