import React, { useState } from 'react'

import './HeaderControlsAccordionMenu.scss'

import { ChevronDownSvg } from 'assets/svg'
import clsx from 'clsx'

import { Link } from 'react-router-dom'

import {
  URL_CONTACT,
  URL_EXTERNAL_TERMS_AND_CONDITIONS,
  URL_EXTERNAL_HELP,
  URL_EXTERNAL_PRIVACY_POLICY,
  URL_CREATOR,
  URL_EXTERNAL_CAREERS,
  URL_ABOUT,
  URL_EXTERNAL_BLOG,
  URL_FEATURE_VIDEO,
  URL_FEATURE_MEMBERSHIP,
  URL_FEATURE_COMMUNITY,
  URL_FEATURE_INSIGHTS,
  URL_FEATURE_PAYOUT,
  URL_FEATURE_PAGE,
} from 'urls'

const menuStructure = {
  Product: {
    Page: [URL_FEATURE_PAGE, false],
    Membership: [URL_FEATURE_MEMBERSHIP, false],
    'Live stream & video': [URL_FEATURE_VIDEO, false],
    Community: [URL_FEATURE_COMMUNITY, false],
    Insights: [URL_FEATURE_INSIGHTS, false],
    Payout: [URL_FEATURE_PAYOUT, false],
  },
  'For Creators': {
    'Become a creator': [URL_CREATOR, false],
    'Help & Support': [URL_EXTERNAL_HELP, true],
  },
  'For Audience': {
    'Help & Support': [URL_EXTERNAL_HELP, true],
  },
  Company: {
    About: [URL_ABOUT, false],
    Careers: [URL_EXTERNAL_CAREERS, true],
    Blog: [URL_EXTERNAL_BLOG, true],
    Contact: [URL_CONTACT, false],
    'Privacy policy': [URL_EXTERNAL_PRIVACY_POLICY, true],
    'Terms and conditions': [URL_EXTERNAL_TERMS_AND_CONDITIONS, true],
  },
}

const HeaderControlsAccordionMenu = ({ onClose }) => {
  const [accordionMenu, setAccordionMenu] = useState(['Product'])

  const toggleActive = (title) => {
    let newAccordionMenu = accordionMenu.slice()

    if (newAccordionMenu.includes(title)) {
      newAccordionMenu = newAccordionMenu.filter((menu) => menu !== title)
    } else {
      newAccordionMenu.push(title)
    }
    setAccordionMenu(newAccordionMenu)
  }

  const renderLinks = (links) => {
    return Object.keys(links).map((subtitle, jdx) => {
      const [link, external] = links[subtitle]
      if (external) {
        return (
          <a key={jdx} href={link} target="_blank" rel="noopener noreferrer">
            {subtitle}
          </a>
        )
      } else {
        return (
          <Link key={jdx} onClick={onClose} to={link}>
            {subtitle}
          </Link>
        )
      }
    })
  }

  const renderInner = () => {
    return Object.keys(menuStructure).map((title, idx) => {
      const active = accordionMenu.includes(title)
      return (
        <div
          key={idx}
          onClick={() => toggleActive(title)}
          className="header-controls-accordion-menu-elements"
        >
          <h4>{title}</h4>
          <ChevronDownSvg
            className={clsx('header-controls-accordion-menu-chevron', {
              active,
            })}
          />
          <div
            className={clsx('header-controls-accordion-menu-subelements', {
              active,
            })}
          >
            {renderLinks(menuStructure[title])}
          </div>
        </div>
      )
    })
  }

  return <div className="HeaderControlsAccordionMenu">{renderInner()}</div>
}

export default HeaderControlsAccordionMenu
