import React from 'react'
import './VisibilityPanel.scss'
import { Field, useFormikContext } from 'formik'
import DatePicker from 'react-datepicker'
import DatePickerInput from 'components/EventEditForm/DatePickerInput'
import { EVENT_VISIBILITY } from 'store/constants'
import clsx from 'clsx'

const VisibilityPanel = () => {
  const {
    values,
    initialValues,
    errors,
    touched,
    setFieldTouched,
    setFieldValue,
  } = useFormikContext()
  const previousVisibility = initialValues.visibility

  const renderStatus = () => {
    switch (previousVisibility) {
      case EVENT_VISIBILITY.DRAFT:
        return (
          <div
            className="visibility-panel-header-status visibility-panel-header-status-pending"
            data-testid="visibility-status"
          >
            Draft
          </div>
        )
      case EVENT_VISIBILITY.SCHEDULED:
        return (
          <div
            className="visibility-panel-header-status visibility-panel-header-status-pending"
            data-testid="visibility-status"
          >
            Scheduled
          </div>
        )
      case EVENT_VISIBILITY.PUBLISHED:
        return (
          <div
            className="visibility-panel-header-status visibility-panel-header-status-published"
            data-testid="visibility-status"
          >
            Published
          </div>
        )
      default:
        return null
    }
  }

  return (
    <div className="VisibilityPanel" id="visibility">
      <div className="visibility-panel-header">
        <div className="visibility-panel-header-title">Visibility</div>
        {renderStatus()}
      </div>
      {previousVisibility !== EVENT_VISIBILITY.PUBLISHED && (
        <div className="visibility-panel-description">
          You can edit these details until the {values.live ? 'event' : 'video'}{' '}
          is published.
        </div>
      )}
      {previousVisibility !== EVENT_VISIBILITY.PUBLISHED && (
        <div className="visibility-panel-content">
          <VisibilityPanelItem
            value={EVENT_VISIBILITY.DRAFT}
            title="Draft"
            description={`Your ${
              values.live ? 'event' : 'video'
            } will be visible only to you.`}
            disabled={previousVisibility === EVENT_VISIBILITY.PUBLISHED}
          />
          <VisibilityPanelItem
            value={EVENT_VISIBILITY.SCHEDULED}
            title="Schedule"
            description={`Choose when to publish your ${
              values.live ? 'event' : 'video'
            }.`}
            disabled={previousVisibility === EVENT_VISIBILITY.PUBLISHED}
          >
            {values.visibility === EVENT_VISIBILITY.SCHEDULED && (
              <DatePicker
                showTimeSelect
                timeIntervals={15}
                timeFormat="HH:mm"
                dateFormat="yyyy/MM/dd HH:mm"
                className="visibility-panel-calendar"
                customInput={
                  <DatePickerInput
                    field={{
                      name: 'publishAt',
                    }}
                    meta={{
                      touched: touched.publishAt,
                      error: errors.publishAt,
                    }}
                    dark
                  />
                }
                popperPlacement="top-start"
                minDate={new Date()}
                onChange={(date) => {
                  setFieldTouched('publishAt')
                  setFieldValue('publishAt', date)
                }}
                selected={values.publishAt}
              />
            )}
          </VisibilityPanelItem>
          <VisibilityPanelItem
            value={EVENT_VISIBILITY.PUBLISHED}
            title="Publish"
            description="Publish right away."
          />
        </div>
      )}
    </div>
  )
}

export default VisibilityPanel

const VisibilityPanelItem = ({
  hidden,
  value,
  title,
  description,
  disabled,
  children,
}) => {
  if (hidden) {
    return null
  }

  return (
    <div className={clsx('VisibilityPanelItem', { disabled })}>
      <div className="visibility-panel-item-row">
        <Field
          id={`visibility-${value}`}
          name="visibility"
          type="radio"
          className="boon-dark-radio"
          value={value}
          disabled={disabled}
        />
        <label
          htmlFor={`visibility-${value}`}
          data-testid={`visibility-label-${value}`}
        >
          {title}
          <div className="visibility-panel-item-description">{description}</div>
        </label>
      </div>
      {children && (
        <div className="visibility-panel-item-below">{children}</div>
      )}
    </div>
  )
}
