import React, { useCallback, useState } from 'react'
import './CategoryOptions.scss'
import OptionsMenu from 'components/OptionsMenu'
import CategoryUpsertModal from 'modals/CategoryUpsertModal'
import { deleteCategory } from 'api'

const CategoryOptions = ({ category, onCategoryUpsert }) => {
  const categoryId = category.get('id')
  const categoryName = category.get('name')
  const [open, setOpen] = useState(false)
  const [editCategoryActive, setEditCategoryActive] = useState(false)
  const editCategoryClose = useCallback(
    (e) => {
      e.stopPropagation()
      setEditCategoryActive(false)
    },
    [setEditCategoryActive]
  )

  const onEdit = useCallback(() => {
    setEditCategoryActive(true)
    setOpen(false)
  }, [setEditCategoryActive, setOpen])

  const onDelete = useCallback(async () => {
    if (
      !window.confirm(
        `Are you sure you want to delete the category ${categoryName}? This action is irreversible.`
      )
    ) {
      return null
    }

    try {
      await deleteCategory({ categoryId })
      onCategoryUpsert()
    } catch (e) {
      alert('Deletion failed, please try again')
    }
    setOpen(false)
  }, [categoryId, onCategoryUpsert, categoryName])

  const _onCategoryUpsert = () => {
    onCategoryUpsert()
    setEditCategoryActive(false)
  }

  return (
    <>
      <CategoryUpsertModal
        active={editCategoryActive}
        onClose={editCategoryClose}
        category={category}
        onCategoryUpsert={_onCategoryUpsert}
      />
      <OptionsMenu
        open={open}
        setOpen={setOpen}
        className="CategoryOptions"
        onClick={(e) => e.preventDefault()}
      >
        <div className="link" onClick={onEdit}>
          Edit
        </div>
        <div className="link" onClick={onDelete}>
          Delete
        </div>
      </OptionsMenu>
    </>
  )
}

export default CategoryOptions
