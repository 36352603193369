import { createResponsiveStateReducer } from 'redux-responsive'

export default createResponsiveStateReducer(
  {
    sm: 567,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1440,
    xxxl: 1600,
  },
  {
    infinity: 'xxxl',
  }
)
