import rootReducer from './reducers'
import { compose, createStore, applyMiddleware } from 'redux'
import { persistStore, autoRehydrate } from 'redux-persist-immutable'
import {
  responsiveStoreEnhancer,
  calculateResponsiveState,
} from 'redux-responsive'
import { AuthSchema, AuthMeSchema } from 'store/schemas'

// eslint-disable-next-line import/no-anonymous-default-export
export default (initialState) => {
  const middleware = []
  const createReduxStore =
    process.env.NODE_ENV !== 'production' &&
    window.__REDUX_DEVTOOLS_EXTENSION__ != null
      ? compose(
          applyMiddleware(...middleware),
          responsiveStoreEnhancer,
          autoRehydrate(),
          window.__REDUX_DEVTOOLS_EXTENSION__()
        )
      : compose(
          applyMiddleware(...middleware),
          responsiveStoreEnhancer,
          autoRehydrate()
        )

  const store = createReduxStore(createStore)(rootReducer, initialState)
  persistStore(store, {
    records: [AuthSchema, AuthMeSchema],
    whitelist: ['auth'],
  })

  // Update device detection on resize when development, for easier development
  if (process.env.NODE_ENV === 'development') {
    let debounce // Debounced to avoid clutter in the action overview

    window.addEventListener('resize', updateDeviceDetection)

    function updateDeviceDetection() {
      clearTimeout(debounce)
      debounce = setTimeout(() => {
        store.dispatch(calculateResponsiveState(window))
      }, 200)
    }
  }

  return store
}
