import { useCallback, useEffect } from 'react'
import { useGetInviteCodeApi } from 'api'
import { fromJS } from 'immutable'

// Local storage key for coupon code storage
const getLocalStorageInviteCodeKey = (userSlug) => `invite-${userSlug}`

// Used on pages where coupon codes might be in the URL, to save them
export const useGetInviteCode = (userSlug, inviteCodeString) => {
  const savedInviteInfo = window.sessionStorage.getItem(
    getLocalStorageInviteCodeKey(userSlug)
  )

  const disabled = inviteCodeString == null || userSlug == null
  const { data: inviteCodeData, error: inviteCodeError } = useGetInviteCodeApi({
    userSlug,
    inviteCodeString,
    disabled,
  })

  useEffect(() => {
    if (inviteCodeError) {
      window.sessionStorage.removeItem(getLocalStorageInviteCodeKey(userSlug))
    } else if (inviteCodeData) {
      window.sessionStorage.setItem(
        getLocalStorageInviteCodeKey(userSlug),
        JSON.stringify(inviteCodeData.toJS())
      )
    }
  }, [inviteCodeData, inviteCodeError, userSlug])

  const removeInviteCode = useCallback(() => {
    window.sessionStorage.removeItem(getLocalStorageInviteCodeKey(userSlug))
  }, [userSlug])

  if (!inviteCodeString && savedInviteInfo) {
    return {
      inviteCode: fromJS(JSON.parse(savedInviteInfo)),
      error: null,
      removeInviteCode,
    }
  }
  return {
    inviteCode: inviteCodeData,
    error: inviteCodeError && inviteCodeError.response.data['inviteCode'],
    removeInviteCode,
  }
}
