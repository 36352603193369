import React from 'react'
import './ContactPage.scss'
import { Container } from 'components/Grid'
import { EMAIL_HELLO, EMAIL_HELLO_TEXT } from 'urls'

const ContactPage = () => {
  return (
    <div className="ContactPage">
      <Container>
        <div className="contact-page-title">
          Wanna talk?
          <br />
          Reach out
        </div>
        <a className="contact-page-email" href={EMAIL_HELLO}>
          {EMAIL_HELLO_TEXT}
        </a>
        <div className="contact-page-address">
          Boon ApS
          <br />
          Billedvej 4
          <br />
          2150 Copenhagen
          <br />
          Denmark
          <br />
          CVR 40053468
        </div>
      </Container>
    </div>
  )
}

export default ContactPage
