import store from 'store'
import { BACKEND_URL } from 'appConstants'
import { getAccessToken, isAuthenticated } from 'store/selectors'
import { useState, useEffect, useCallback } from 'react'
import { ensureValidToken } from 'store/utils/apiMiddleware'
import Axios from 'axios'
import { useDeepEqualMemo } from 'utils'

export const callApi = async ({
  url,
  method = 'GET',
  data,
  responseType,
  withoutAuth = false,
  dataMapper = null,
}) => {
  try {
    if (withoutAuth !== true) {
      await ensureValidToken()
    }
  } catch (e) {
    // TODO: consider adding toast here
    console.error('Cancelling API call, bad auth', e)
    return
  }

  const state = store.getState()
  const request = {
    url: `${BACKEND_URL}/${url}`,
    headers: {
      Accept: 'application/json',
    },
    method,
    data,
    responseType,
  }
  if (isAuthenticated(state) && withoutAuth !== true) {
    request.headers.Authorization = `Bearer ${getAccessToken(state)}`
  }

  const response = await Axios(request)
  if (dataMapper != null) {
    return dataMapper(response.data)
  }

  return response.data
}

/*
  Data getter hooks.

  NOTE:
  Data mappers are not anonymous functions,
  as that would change the signature of the function,
  thus causing a re-render loop in the useCallback function.
*/

export const useGetApi = (
  {
    url,
    withoutAuth = false,
    dataMapper = null,
    disabled = false,
    method = 'GET',
    data: inputData,
  },
  deps = []
) => {
  const [data, setData] = useState(null)
  const deepComparedInputData = useDeepEqualMemo(inputData)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  const callApiLocal = useCallback(async () => {
    setError(null)
    if (disabled) {
      setLoading(false)
      return null
    }

    try {
      const response = await callApi({
        url,
        method,
        withoutAuth,
        dataMapper,
        data: deepComparedInputData,
      })
      setData(response)
      setLoading(false)
    } catch (e) {
      setError(e)
      setLoading(false)
    }
  }, [url, dataMapper, withoutAuth, disabled, method, deepComparedInputData])

  const setDataProxy = useCallback(
    (data) => {
      if (dataMapper != null) {
        setData(dataMapper(data))
      } else {
        setData(data)
      }
    },
    [setData, dataMapper]
  )

  useEffect(() => {
    setLoading(true)
    callApiLocal()
  }, [callApiLocal, ...deps]) // eslint-disable-line react-hooks/exhaustive-deps

  return {
    data,
    loading,
    error,
    refresh: callApiLocal,
    setData: setDataProxy,
    setMappedData: setData,
  }
}
