import React, { useContext, useState } from 'react'
import InsightsContext from 'routes/InsightsPage/InsightsContext'
import { Col, Row } from 'components/Grid'
import InsightsConnectedNumberWidget from 'components/InsightsNumberWidget/InsightsConnectedNumberWidget'
import { useMe } from 'utils'
import {
  INSIGHTS_PERIODS,
  INSIGHTS_PERIODS_NAMES,
} from 'routes/InsightsPage/insightsConstants'
import InsightsFilters from 'routes/InsightsPage/InsightsFilters'
import InsightsConnectedChartWidget from 'components/InsightsChartWidget/InsightsConnectedChartWidget'
import InsightsConnectedTableWidget from 'components/InsightsTableWidget/InsightsConnectedTableWidget'
import {
  currencyPrefixDataMapper,
  genericChartDataMapper,
  usePrefixedChartDataLabelMapper,
} from 'routes/InsightsPage/insightsUtils'
import RelationsMenuModal from 'routes/InsightsPage/components/RelationsMenuModal'

const InsightsIncomePage = ({ currency, currencySymbol }) => {
  const me = useMe()
  const userId = me.get('id')
  const prefixedChartDataLabelMapper = usePrefixedChartDataLabelMapper(
    currencySymbol
  )
  const { period, queryParams } = useContext(InsightsContext)
  const [menuModalUserId, setMenuModalUserId] = useState(null)
  const [menuModalTransactionId, setMenuModalTransactionId] = useState(null)

  const headers = [
    { key: 'realName', type: 'profile', name: 'Name', active: true },
    { key: 'email', type: 'string', active: true },
    {
      key: 'createdAt',
      name: 'Date',
      type: 'date',
      active: true,
    },
    {
      key: 'item',
      name: 'Item',
      type: 'string',
      active: true,
    },
    {
      key: 'amount',
      name: 'Charge',
      type: 'currency',
      active: true,
    },
    {
      key: 'discount',
      name: 'Discount',
      type: 'string',
      active: true,
    },
    {
      key: 'discountPercentage',
      name: 'Discount %',
      type: 'percent',
      active: true,
    },
    {
      key: 'discountCampaign',
      name: 'Discount Campaign',
      type: 'string',
      active: true,
    },
  ]

  const availableFilters = []

  return (
    <div className="InsightsIncomePage">
      <Row className="insights-members-top">
        <Col lg={3} md={6}>
          <InsightsConnectedNumberWidget
            title="Income"
            url={`income_stats/${userId}/revenue_total`}
            timeScale={INSIGHTS_PERIODS_NAMES[INSIGHTS_PERIODS.THIRTY_DAYS]}
            prefix={currencyPrefixDataMapper}
          />
        </Col>
        <Col lg={3} md={6}>
          <InsightsConnectedNumberWidget
            title="Ticket income"
            url={`income_stats/${userId}/revenue_ppv`}
            timeScale={INSIGHTS_PERIODS_NAMES[INSIGHTS_PERIODS.THIRTY_DAYS]}
            prefix={currencyPrefixDataMapper}
          />
        </Col>
        <Col lg={3} md={6}>
          <InsightsConnectedNumberWidget
            title="Member Income"
            url={`income_stats/${userId}/revenue_subscription`}
            timeScale={INSIGHTS_PERIODS_NAMES[INSIGHTS_PERIODS.THIRTY_DAYS]}
            prefix={currencyPrefixDataMapper}
          />
        </Col>
        <Col lg={3} md={6}>
          <InsightsConnectedNumberWidget
            title="MRR"
            url={`income_stats/${userId}/mrr`}
            timeScale="Now"
            prefix={currencyPrefixDataMapper}
          />
        </Col>
      </Row>

      <InsightsFilters filterProducts />

      <Row>
        <Col xs={12}>
          <InsightsConnectedChartWidget
            title={`Income (${currencySymbol})`}
            timeScale={INSIGHTS_PERIODS_NAMES[period]}
            url={`income_stats/${userId}/revenue_total_timeseries?${queryParams}`}
            dataMapper={genericChartDataMapper}
            labels={prefixedChartDataLabelMapper}
            chartType={'bar'}
          />
        </Col>
      </Row>

      <Row>
        <Col lg={4}>
          <InsightsConnectedChartWidget
            title={`Ticket Income (${currencySymbol})`}
            timeScale={INSIGHTS_PERIODS_NAMES[period]}
            url={`income_stats/${userId}/revenue_ppv_timeseries?${queryParams}`}
            dataMapper={genericChartDataMapper}
            labels={prefixedChartDataLabelMapper}
            chartType={'bar'}
          />
        </Col>
        <Col lg={4}>
          <InsightsConnectedChartWidget
            title={`Member Income (${currencySymbol})`}
            timeScale={INSIGHTS_PERIODS_NAMES[period]}
            url={`income_stats/${userId}/revenue_subscription_timeseries?${queryParams}`}
            dataMapper={genericChartDataMapper}
            labels={prefixedChartDataLabelMapper}
            chartType={'bar'}
          />
        </Col>
        <Col lg={4}>
          <InsightsConnectedChartWidget
            title={`MRR (${currencySymbol})`}
            timeScale={INSIGHTS_PERIODS_NAMES[period]}
            url={`income_stats/${userId}/mrr_timeseries?${queryParams}`}
            dataMapper={genericChartDataMapper}
            labels={prefixedChartDataLabelMapper}
          />
        </Col>
      </Row>

      <InsightsConnectedTableWidget
        title="Transactions"
        baseUrl={`users/${userId}/transactions`}
        dataKey="balanceTransactions"
        headers={headers}
        defaultFilters={availableFilters}
        defaultSortBy={'created_at desc'}
        clickableRow={(obj) => {
          setMenuModalUserId(obj['realName']['userId'])
          setMenuModalTransactionId(obj['id'])
        }}
      />

      <RelationsMenuModal
        hostId={userId}
        userId={menuModalUserId}
        onClose={() => {
          setMenuModalUserId(null)
          setMenuModalTransactionId(null)
        }}
        type="transaction"
        transactionId={menuModalTransactionId}
      />
    </div>
  )
}

export default InsightsIncomePage
