import React from 'react'
import 'components/InsightsCancelFeedbackCard/InsightsCancelFeedbackCard.scss'
import InsightsGenericFeedbackCard from 'components/InsightsGenericFeedbackCard'

import { CANCELLATION_REASONS } from '../../modals/CancelSubscriptionModal/CancelSubscriptionModal'

const InsightsCancelFeedbackCard = ({
  user,
  headline,
  date,
  cancelReasons,
  cancelFeedback,
  onNameClick = null,
}) => {
  const renderContent = () => {
    return (
      <div className="InsightsCancelFeedbackCard">
        <div className="insights-feedback-content-reason-label">
          Selected Reasons
        </div>
        <div className="insights-cancel-feedback-card-reasons">
          {cancelReasons.map((r) => {
            return (
              <div className="insights-cancel-feedback-card-reason" key={r}>
                {CANCELLATION_REASONS.find(([key]) => key === r)[1]}
              </div>
            )
          })}
        </div>
        <div className="insights-cancel-feedback-card-text">
          {cancelFeedback}
        </div>
      </div>
    )
  }

  return (
    <InsightsGenericFeedbackCard
      modalTitle="Cancel Feedback"
      user={user}
      headline={headline}
      headlineLabel={'Membership'}
      date={date}
      onNameClick={onNameClick}
    >
      {renderContent()}
    </InsightsGenericFeedbackCard>
  )
}

export default InsightsCancelFeedbackCard
