import { useEffect, useCallback } from 'react'
import './CustomerChat.scss'
import { useLocation } from 'react-router-dom'
import { useMount, usePrevious } from 'react-use'
import { useSelector } from 'react-redux'
import { getMe } from 'store/selectors'
import { INTERCOM_APP_ID } from 'appConstants'

const whitelist = [
  /^\/$/,
  /^\/about$/,
  /^\/terms$/,
  /^\/privacy$/,
  /^\/creator$/,
  /^\/creator\/setup/,
  /^\/qualification/,
  /^\/schedule/,
  /^\/upload/,
  /^\/settings/,
  /^\/accounting/,
  /^\/manage-subscriptions$/,
  /^\/\S+\/\S+\/overview$/,
]

const CustomerChat = () => {
  const location = useLocation()
  const me = useSelector((state) => getMe(state))
  const shouldShow =
    whitelist.find((r) => location.pathname.match(r) != null) != null
  const shouldHide = !shouldShow

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const userInformation =
    me && me.get('id') != null
      ? {
          name: me.get('name'),
          email: me.get('email'),
          created_at: me.get('createdAt')
            ? parseInt(new Date(me.get('createdAt')).getTime() / 1000)
            : null,
        }
      : null
  const previousUserInformation = usePrevious(userInformation)

  const runIntercom = () => {
    ;(function () {
      var w = window
      var ic = w.Intercom
      if (typeof ic === 'function') {
        ic('reattach_activator')
        ic('update', w.intercomSettings)
      } else {
        var d = document
        var i = function () {
          i.c(arguments)
        }
        i.q = []
        i.c = function (args) {
          i.q.push(args)
        }
        w.Intercom = i
        var l = function () {
          var s = d.createElement('script')
          s.type = 'text/javascript'
          s.async = true
          s.src = 'https://widget.intercom.io/widget/' + INTERCOM_APP_ID
          var x = d.getElementsByTagName('script')[0]
          x.parentNode.insertBefore(s, x)
        }
        if (w.attachEvent) {
          w.attachEvent('onload', l)
        } else {
          w.addEventListener('load', l, false)
        }
      }
    })()
  }

  const startIntercom = useCallback(() => {
    window.Intercom('boot', {
      app_id: INTERCOM_APP_ID,
      hide_default_launcher: shouldHide,
      ...(userInformation || {}),
    })
  }, [userInformation, shouldHide])

  useMount(() => {
    runIntercom()
    if (window.Intercom == null) {
      return null
    }
    startIntercom()
  })

  useEffect(() => {
    if (window.Intercom == null) {
      return null
    }

    window.Intercom('update', {
      hide_default_launcher: shouldHide,
    })
  }, [shouldHide])

  useEffect(() => {
    if (window.Intercom == null) {
      return null
    }

    if (userInformation) {
      window.Intercom('update', {
        ...userInformation,
      })
    } else if (previousUserInformation != null) {
      window.Intercom('shutdown')
      startIntercom()
    }
  }, [userInformation, previousUserInformation, startIntercom])

  return null
}

export default CustomerChat
