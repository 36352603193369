// Gets a mux streams id from a stream URL
export const getMuxId = (streamUrl) => {
  const matches = (streamUrl ?? '').match(/stream\.mux\.com\/(.*).m3u/)

  return matches?.length === 2 ? matches[1] : null
}

// Gets a thumbnail from a mux stream URL using our serverless proxy function
export const getMuxThumbnail = (streamUrl) => {
  const id = getMuxId(streamUrl)
  return id ? `https://boon-mux-proxy.imgix.net/api/thumbnail/${id}` : null
}

// Gets a GIF from a mux stream URL using our serverless proxy function
export const getMuxGif = (streamUrl) => {
  const id = getMuxId(streamUrl)
  return id ? `https://boon-mux-proxy.imgix.net/api/gif/${id}` : null
}
