import React, { useEffect, useReducer, useRef } from 'react'
import './PostAttachmentModal.scss'
import PageModal from 'components/PageModal'
import PostAttachmentView from 'components/PostAttachmentView'
import { useSelector } from 'react-redux'
import { getMe } from 'store/selectors'
import { useGetPostApi } from 'api'
import { postReducer, postReducerMerge } from 'components/ProfileFeed'
import { fromJS, List, Map } from 'immutable'
import { cableConnect } from 'store/utils/cable'
import Spinner from 'components/Spinner'
import { useTemporaryModalUrl } from 'utils'

const PostAttachmentModal = ({
  postId,
  postUrl,
  onClose,
  onPostAttachmentUpdate,
}) => {
  useTemporaryModalUrl(postUrl, onClose)
  const socketConnectionRef = useRef(null)
  const me = useSelector((state) => getMe(state))
  const meId = me.get('id')
  const { data: postData } = useGetPostApi({
    postId,
  })
  const [posts, dispatchPosts] = useReducer(postReducer, Map())
  const post = posts.get(postId)

  useEffect(() => {
    if (postData != null) {
      dispatchPosts(postReducerMerge(List([postData])))
    }
  }, [postData])

  useEffect(() => {
    const run = async () => {
      // Connect to socket
      socketConnectionRef.current = await cableConnect()
      socketConnectionRef.current.subscriptions.create(
        {
          channel: 'PostChannel',
          post_id: postId,
        },
        {
          rejected: () => {
            console.log('We were rejected')
          },
          received: (data) => {
            dispatchPosts(fromJS(data))
          },
        }
      )
    }

    if (postId) {
      run()
    }

    return () => {
      if (socketConnectionRef.current != null) {
        socketConnectionRef.current.disconnect()
      }
    }
  }, [meId, postId])

  const renderInner = () => {
    if (post == null) {
      return <Spinner light small />
    } else {
      return (
        <PostAttachmentView
          post={post}
          hostId={post.getIn(['host', 'id'])}
          dispatchPosts={dispatchPosts}
          onClose={onClose}
          onPostAttachmentUpdate={onPostAttachmentUpdate}
        />
      )
    }
  }

  return (
    <PageModal className="PostAttachmentModal" onClose={onClose}>
      {renderInner()}
    </PageModal>
  )
}

export default PostAttachmentModal
