import React, { useState, useRef } from 'react'
import './EventInsightsMenu.scss'
import {
  getEventUrl,
  useCopyToClipboard,
  useMe,
  useOutsideAlerter,
} from 'utils'
import { Link } from 'react-router-dom'
import { archiveEvent, deleteEvent, getEvent, unArchiveEvent } from 'api'
import { useToasts } from 'react-toast-notifications'
import { OptionDotsSvg } from 'assets/svg'
import EventPageModal from 'modals/EventPageModal'

const EventInsightsMenu = ({
  eventId,
  eventTitle,
  eventLive,
  eventArchived,
  refreshEvent,
  eventPage = false,
}) => {
  const wrapperRef = useRef(null)
  let [menuOpen, setMenuOpen] = useState(false)
  const { addToast } = useToasts()
  const copyToClipboard = useCopyToClipboard()
  const me = useMe()
  const [showEventModal, setShowEventModal] = useState(false)

  useOutsideAlerter(wrapperRef, menuOpen, () => {
    setMenuOpen(false)
  })

  const eventType = eventLive ? 'event' : 'video'

  const renderEventModal = () => {
    return (
      <EventPageModal
        onClose={() => setShowEventModal(false)}
        userSlug={me.get('id')}
        eventSlug={eventId}
      />
    )
  }

  const downloadEvent = async () => {
    try {
      const event = await getEvent({ id: eventId })
      if (event.get('recordedRendition')) {
        window.open(
          `${event.get('recordedRendition')}?download=${eventId}.mp4`,
          '_blank'
        )
      } else {
        addToast('Could not download the event', { appearance: 'error' })
      }
    } catch (e) {
      addToast('Could not download the event', { appearance: 'error' })
    }
  }

  const onDelete = async () => {
    if (window.confirm(`Are you sure you want to delete "${eventTitle}"?`)) {
      try {
        await deleteEvent({ id: eventId })
      } catch (e) {
        addToast(
          'We were unable to delete this event, please try again later',
          { appearance: 'error' }
        )
        return null
      }

      refreshEvent && refreshEvent()

      addToast(`"${eventTitle}" was successfully deleted!`, {
        appearance: 'success',
      })

      setMenuOpen(false)
    }
  }
  const onUnlist = async () => {
    var aboutTo = eventArchived ? 'list' : 'unlist'
    if (
      window.confirm(`You are about to ${aboutTo} this event. Are you sure?`)
    ) {
      try {
        eventArchived
          ? await unArchiveEvent({ id: eventId })
          : await archiveEvent({ id: eventId })
      } catch (e) {
        addToast(
          `We were unable to ${aboutTo} this event, please try again later`,
          { appearance: 'error' }
        )
        return null
      }

      refreshEvent && refreshEvent()

      addToast(`"${eventTitle}" was successfully ${aboutTo}ed!`, {
        appearance: 'success',
      })

      setMenuOpen(false)
    }
  }

  const onCopy = async () => {
    try {
      const event = await getEvent({ id: eventId })
      copyToClipboard(getEventUrl(event))
    } catch (e) {
      addToast('Could not copy the event url', { appearance: 'error' })
    }
  }

  return (
    <div className="EventInsightsMenu" ref={wrapperRef}>
      {showEventModal && renderEventModal()}
      <OptionDotsSvg
        className="event-insights-header-menu-button"
        onClick={() => setMenuOpen(!menuOpen)}
      />
      {menuOpen && (
        <div className="event-insights-header-menu-wrapper">
          <div className="event-insights-header-menu">
            <ul className="event-insights-header-menu-list">
              {!eventPage && (
                <li onClick={() => setShowEventModal(true)}>
                  Show {eventType}
                </li>
              )}
              <li>
                <Link to={`/${me.get('id')}/${eventId}/edit`}>
                  Edit {eventType}
                </Link>
              </li>
              <li>
                <div className="link" onClick={onCopy}>
                  Copy link
                </div>
              </li>
              <li onClick={onDelete}>Delete {eventType}</li>
              {!eventLive && <li onClick={downloadEvent}>Download</li>}
              <li onClick={onUnlist}>
                {eventArchived ? 'Make public' : 'Unlist'}
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  )
}

export default EventInsightsMenu
