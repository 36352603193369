import React from 'react'
import './BoonInputAddonSelect.scss'
import { ChevronDownSvg } from 'assets/svg'
import { useField } from 'formik'

export const BoonInputAddonSelect = ({ field, disabled, children }) => {
  return (
    <div className="BoonInputAddonSelect">
      <select {...field} disabled={disabled} tabIndex="-1">
        {children}
      </select>
      <ChevronDownSvg />
    </div>
  )
}

export const BoonFormikInputAddonSelect = (props) => {
  const [field, meta] = useField(props)
  return <BoonInputAddonSelect field={field} meta={meta} {...props} />
}
