import { DateTime } from 'luxon'

// Human-readable date formatter
export const getFormattedActivityDate = (dateTime, precise = true) => {
  const parsed = DateTime.fromISO(dateTime)
  const diffSeconds = parsed.diffNow('seconds').seconds
  const diffDays = parsed.diffNow('days').days

  if (diffSeconds > -60) {
    return 'Just now'
  } else if (diffDays < -6) {
    return precise ? parsed.toFormat('MMM d, H:mm') : parsed.toFormat('MMM d')
  } else {
    return parsed.toRelative({ style: 'narrow' })
  }
}
