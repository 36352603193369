import React, { useCallback, useEffect } from 'react'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import './UpdateSubscriptionPaymentPage.scss'
import Spinner from 'components/Spinner'
import queryString from 'query-string'
import { useCreateCheckoutSessionApi } from 'api'

const UpdateSubscriptionPaymentPage = () => {
  const { search } = useLocation()
  const history = useHistory()
  const {
    params: { subscriptionId },
  } = useRouteMatch()
  const { redirect_url: redirectUrl } = queryString.parse(search, {
    decode: false,
  })

  const {
    data: checkoutSession,
    error: checkoutSessionError,
  } = useCreateCheckoutSessionApi({
    data: {
      subscriptionId,
      redirectUrl,
    },
  })

  const onGoBackClick = useCallback(() => {
    if (redirectUrl) {
      history.push(redirectUrl)
    } else {
      history.goBack()
    }
  }, [history, redirectUrl])

  const checkoutSessionUrl = checkoutSession?.get('url')
  useEffect(() => {
    if (checkoutSessionUrl != null) {
      window.location.href = checkoutSessionUrl
    }
  }, [checkoutSessionUrl])

  const renderInner = () => {
    const error = checkoutSessionError
    if (error != null) {
      return (
        <div className="update-subscription-payment-page-box">
          <div className="update-subscription-payment-page-error">
            <h1 className="update-subscription-payment-page-title">
              An error occurred
            </h1>
            <div className="input-error">
              {error?.response?.data?.message ?? error?.message}
            </div>
            <button
              className="button secondary update-subscription-payment-page-error-back"
              onClick={onGoBackClick}
            >
              Go back
            </button>
          </div>
        </div>
      )
    }

    return (
      <div className="update-subscription-payment-page-loading">
        <h1 className="update-subscription-payment-page-loading-title">
          Redirecting you to checkout
        </h1>
        <Spinner small />
      </div>
    )
  }

  return <div className="UpdateSubscriptionPaymentPage">{renderInner()}</div>
}

export default UpdateSubscriptionPaymentPage
