import React from 'react'
import './InsightsTablePagination.scss'

import { ChevronDownSvg } from 'assets/svg'

import clsx from 'clsx'
import { useSelector } from 'react-redux'

const InsightsTablePagination = ({ changePage, totalPages, page }) => {
  const browser = useSelector((state) => state.get('browser'))

  if (!changePage || page > totalPages || totalPages <= 1) {
    return null
  }

  let startNumbers = [1]
  let middleNumbers = ['...']
  let endNumbers = [totalPages]
  let pageNumbers

  if (browser.greaterThan.md) {
    if (totalPages > 6) {
      let isFirstPages = [1, 2, 3].includes(page)
      let isLastPages = [totalPages - 2, totalPages - 1, totalPages].includes(
        page
      )
      if (isFirstPages && !isLastPages) {
        startNumbers.push(2)
        startNumbers.push(3)
        if (page === 3) {
          startNumbers.push(4)
        }
      } else if (isLastPages && !isFirstPages) {
        endNumbers.unshift(totalPages - 1)
        endNumbers.unshift(totalPages - 2)
        if (page === totalPages - 2) {
          endNumbers.unshift(totalPages - 3)
        }
      } else {
        middleNumbers.unshift(page + 1)
        middleNumbers.unshift(page)
        middleNumbers.unshift(page - 1)
        middleNumbers.unshift('...')
      }
      pageNumbers = startNumbers.concat(middleNumbers).concat(endNumbers)
    } else {
      pageNumbers = Array.from(Array(totalPages), (_, i) => i + 1)
    }
  } else {
    pageNumbers = [page]
  }

  return (
    <div className="InsightsTablePagination">
      <ChevronDownSvg
        className={clsx('insight-table-pagination-prev', {
          disabled: page === 1,
        })}
        onClick={() => page !== 1 && changePage(page - 1)}
        data-testid="pagination-previous"
      />
      {pageNumbers.map((n, idx) => {
        if (n === '...') {
          return (
            <span
              key={idx}
              className="insight-table-pagination-dots"
              data-testid="pagination-dots"
            >
              ...
            </span>
          )
        } else {
          return (
            <span
              key={idx}
              className={clsx('insight-table-pagination-number', {
                active: n === page,
              })}
              onClick={() => changePage(n)}
              data-testid={`pagination-number-${n}`}
            >
              {n}
            </span>
          )
        }
      })}
      <ChevronDownSvg
        className={clsx('insight-table-pagination-next', {
          disabled: page === totalPages,
        })}
        onClick={() => page !== totalPages && changePage(page + 1)}
        data-testid="pagination-next"
      />
    </div>
  )
}

export default InsightsTablePagination
