// Generates a URL friendly slug from a text string
export const sluggify = (text) =>
  text
    .toLowerCase()
    .trim()
    .replace(/ /g, '-')
    .replace(/[^a-z0-9-]/g, '')

// Capitalizes a string
export const capitalize = (word) => {
  return word
    .replace(/_/g, ' ')
    .replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()))
}

/* eslint-disable */
// Generates a random UUID V4
export const uuidv4 = () => {
  if (window?.crypto?.getRandomValues != null) {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        c ^
        (window.crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    )
  } else {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        const r = (Math.random() * 16) | 0,
          v = c == 'x' ? r : (r & 0x3) | 0x8 // eslint-disable-line
        return v.toString(16)
      }
    )
  }
}
/* eslint-enable */
