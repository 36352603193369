import ReactGA from 'react-ga4'

const catchErrors = (f) => {
  try {
    f()
  } catch {
    // Pass
  }
}

// Event Flow Chart: https://www.draw.io/#G19NC7QLabG_wLTLKC13z8DEjPlmJVMjZc

// -- Generic

// Sign Up Modal
export const trackSignUpModal = () => {
  catchErrors(() =>
    ReactGA.event({
      category: 'Conversion',
      action: 'Sign Up Modal',
    })
  )
}

// Sign Up Modal Email
export const trackSignUpModalEmail = () => {
  catchErrors(() =>
    ReactGA.event({
      category: 'Conversion',
      action: 'Sign Up Modal Email',
    })
  )
}

// Sign Up Modal Facebook
export const trackSignUpModalFacebook = () => {
  catchErrors(() =>
    ReactGA.event({
      category: 'Conversion',
      action: 'Sign Up Modal Facebook',
    })
  )
}

// Sign Up Modal Facebook
export const trackSignUpModalFacebookConfirmEmail = () => {
  catchErrors(() =>
    ReactGA.event({
      category: 'Conversion',
      action: 'Sign Up Modal Facebook Confirm Email',
    })
  )
}

// -- Creators

// Landing Page
export const trackCreatorLandingInit = () => {
  catchErrors(() =>
    ReactGA.event({
      category: 'Conversion',
      action: 'Creator Landing Init',
    })
  )
}

export const trackCreatorLandingSignUp = () => {
  catchErrors(() => {
    window.fbq('track', 'Lead', {
      value: 1,
      currency: 'DKK',
    })
  })
  catchErrors(() =>
    ReactGA.event({
      category: 'Conversion',
      action: 'Creator Signed Up',
    })
  )
}

// Direct
export const trackCreatorDirectInit = () => {
  catchErrors(() =>
    ReactGA.event({
      category: 'Conversion',
      action: 'Creator Direct Init',
    })
  )
}

export const trackCreatorDirectSignUp = () => {
  catchErrors(() => {
    window.fbq('track', 'Lead', {
      value: 1,
      currency: 'DKK',
    })
  })
  catchErrors(() =>
    ReactGA.event({
      category: 'Conversion',
      action: 'Creator Direct Signed Up',
    })
  )
}

// Registration
export const trackCreatorRegistrationInit = () => {
  catchErrors(() => {
    window.fbq('track', 'InitiateCheckout', {
      value: 2,
      currency: 'DKK',
    })
  })
  catchErrors(() =>
    ReactGA.event({
      category: 'Conversion',
      action: 'Creator Registration Init',
    })
  )
}

export const trackCreatorRegistrationComplete = () => {
  catchErrors(() => {
    window.fbq('track', 'CompleteRegistration', {
      value: 3,
      currency: 'DKK',
    })
  })
  catchErrors(() =>
    ReactGA.event({
      category: 'Conversion',
      action: 'Creator Completed Registration',
    })
  )
}

// Create event
export const trackCreatorCreateEventInit = () => {
  catchErrors(() =>
    ReactGA.event({
      category: 'Conversion',
      action: 'Creator Started Event Creation',
    })
  )
}

export const trackCreatorCreateEventComplete = () => {
  catchErrors(() =>
    ReactGA.event({
      category: 'Conversion',
      action: 'Creator Completed Event Creation',
    })
  )
}

export const trackCreatorCategory = (category) => {
  const trackingName = `LeadCategory${category}`

  catchErrors(() => {
    window.fbq('track', trackingName, {
      value: 0.1,
      currency: 'DKK',
    })
  })
  catchErrors(() => {
    ReactGA.event({
      category: 'Conversion',
      action: trackingName,
    })
  })
}

export const trackCreatorLaunchPage = () => {
  catchErrors(() => {
    window.fbq('track', 'SubmitApplication', {
      value: 4,
      currency: 'DKK',
    })
  })
  catchErrors(() => {
    ReactGA.event({
      category: 'Conversion',
      action: 'Creator Launched Page',
    })
  })
}

export const trackCreatorRequestADemo = () => {
  catchErrors(() => {
    window.fbq('track', 'Contact', {
      value: 0.5,
      currency: 'DKK',
    })
  })
  catchErrors(() => {
    ReactGA.event({
      category: 'Conversion',
      action: 'Creator Demo Init',
    })
  })
}

// Event lifecycle

// -- Users

export const trackSignUp = () => {
  catchErrors(() => {
    window.fbq('track', 'ViewContent')
  })
  catchErrors(() =>
    ReactGA.event({
      category: 'Conversion',
      action: 'Sign Up',
    })
  )
}

export const trackPPVPurchaseComplete = () => {
  catchErrors(() => {
    window.fbq('track', 'Purchase', {
      value: 5,
      currency: 'DKK',
    })
  })
  catchErrors(() =>
    ReactGA.event({
      category: 'Conversion',
      action: 'Purchase Complete',
    })
  )
}

export const trackSubscriptionPurchaseComplete = () => {
  catchErrors(() => {
    window.fbq('track', 'Subscribe', {
      value: 5,
      currency: 'DKK',
    })
  })
  catchErrors(() =>
    ReactGA.event({
      category: 'Conversion',
      action: 'Subscription Purchase Complete',
    })
  )
}
