import React, { useCallback, useState } from 'react'
import {
  createProductApi,
  getImageUploadUrl,
  updateProductApi,
  upsertPlansApi,
} from 'api'
import { Prompt } from 'react-router-dom'
import { Field, Form, Formik, useFormikContext } from 'formik'
import { BoonFormikTextField } from 'components/BoonInputs'
import clsx from 'clsx'
import {
  displayPrice,
  fixImageOrientation,
  getApiError,
  uploadToS3,
} from 'utils'
import './CreatorMembershipUpsertProduct.scss'
import StyledApiErrorMessage from 'components/StyledApiErrorMessage'
import { useSelector } from 'react-redux'
import { getMe } from 'store/selectors'
import * as Yup from 'yup'
import NamedAnimatedModal from 'components/NamedAnimatedModal'
import { CameraSvg } from 'assets/svg'
import { useToasts } from 'react-toast-notifications'
import { useLockBodyScroll, useUnmount } from 'react-use'
import { MarkdownTextEditorFormik } from 'components/MarkdownTextEditor/MarkdownTextEditor'
import { BackgroundImage } from 'components/Image'
import {
  getUpsertPlansFromFormValues,
  INTERVAL_ANNUALLY_ACTIVE_KEY,
  INTERVAL_ANNUALLY_DISCOUNT_KEY,
  INTERVAL_QUARTERLY_ACTIVE_KEY,
  INTERVAL_QUARTERLY_DISCOUNT_KEY,
  INTERVAL_SEMI_ANNUALLY_ACTIVE_KEY,
  INTERVAL_SEMI_ANNUALLY_DISCOUNT_KEY,
} from 'components/CreatorMembershipUpsert/creatorMembershipUpsertUtils'

const CreatorMembershipUpsertProductValidation = Yup.object().shape({
  amount: Yup.number().required('Required'),
  currency: Yup.string()
    .matches(
      /(dkk|sek|nok|usd|gbp|eur|cad|jpy|chf)/,
      'You must select a currency'
    )
    .typeError('You must select a currency')
    .required('Required'),
  description: Yup.string().required('Required'),
})

export const CreatorMembershipUpsertProduct = ({
  product,
  productsRefresh,
  onProductUpsert,
}) => {
  const { addToast } = useToasts()
  const me = useSelector((state) => getMe(state))
  const meCurrency = me.get('currency')
  const edit = product != null

  const onSubmit = async (values, { setSubmitting, setErrors }) => {
    const { name, description, productImageId } = values

    const productValues = {
      name,
      description,
      productImageId,
    }
    let productId = product?.get('id')

    if (edit) {
      // Update product
      try {
        await updateProductApi({ productId, data: productValues })
      } catch (e) {
        setErrors(getApiError(e))
        setSubmitting(false)
        return
      }
    } else {
      // Create product
      try {
        const product = await createProductApi({ data: productValues })
        productId = product.get('id')
      } catch (e) {
        setErrors(getApiError(e))
        setSubmitting(false)
        return
      }
    }

    const plans = getUpsertPlansFromFormValues(values)
    await upsertPlansApi({ productId, data: { plans } })

    productsRefresh()
    onProductUpsert()
    if (edit) {
      addToast('Membership successfully updated', { appearance: 'success' })
    } else {
      addToast('Membership successfully created', { appearance: 'success' })
    }
  }

  let initialValues
  if (product != null) {
    const monthlyPlan = product
      .get('plans')
      .find((p) => p.get('active') && p.get('base'))
    const amount = monthlyPlan?.get('amount')

    const quarterlyPlan = product
      .get('plans')
      .find((p) => p.get('active') && p.get('intervalCount') === 3)
    const semiAnnualPlan = product
      .get('plans')
      .find((p) => p.get('active') && p.get('intervalCount') === 6)
    const annualPlan = product
      .get('plans')
      .find(
        (p) =>
          p.get('active') &&
          p.get('intervalCount') === 1 &&
          p.get('interval') === 'year'
      )

    initialValues = {
      name: product.get('name'),
      description: product.get('description'),
      amount: amount != null ? amount / 100 : '',
      currency: product.getIn(['plans', 0, 'currency']),
      productImageUrl: product.get('productImageUrl'),
      [INTERVAL_QUARTERLY_ACTIVE_KEY]: quarterlyPlan?.get('active') ?? false,
      [INTERVAL_QUARTERLY_DISCOUNT_KEY]: quarterlyPlan?.get('discount') ?? 20,
      [INTERVAL_SEMI_ANNUALLY_ACTIVE_KEY]:
        semiAnnualPlan?.get('active') ?? false,
      [INTERVAL_SEMI_ANNUALLY_DISCOUNT_KEY]:
        semiAnnualPlan?.get('discount') ?? 30,
      [INTERVAL_ANNUALLY_ACTIVE_KEY]: annualPlan?.get('active') ?? false,
      [INTERVAL_ANNUALLY_DISCOUNT_KEY]: annualPlan?.get('discount') ?? 40,
    }
  } else {
    initialValues = {
      description: '',
      amount: '',
      currency: meCurrency,
      [INTERVAL_QUARTERLY_ACTIVE_KEY]: true,
      [INTERVAL_QUARTERLY_DISCOUNT_KEY]: 20,
      [INTERVAL_SEMI_ANNUALLY_ACTIVE_KEY]: true,
      [INTERVAL_SEMI_ANNUALLY_DISCOUNT_KEY]: 30,
      [INTERVAL_ANNUALLY_ACTIVE_KEY]: true,
      [INTERVAL_ANNUALLY_DISCOUNT_KEY]: 40,
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={CreatorMembershipUpsertProductValidation}
    >
      <CreatorMembershipUpsertProductForm edit={edit} />
    </Formik>
  )
}

export const CreatorMembershipUpsertProductModal = ({
  active,
  onClose,
  product,
  productsRefresh,
}) => {
  useLockBodyScroll(active)
  useUnmount(() => {
    document.body.style.overflow = null
  })
  const onCloseHandler = useCallback(() => {
    if (window.confirm('Are you sure you want to abandon your changes?')) {
      onClose()
    }
  }, [onClose])

  const onProductUpsert = useCallback(() => {
    onClose()
  }, [onClose])

  return (
    <NamedAnimatedModal
      active={active}
      onClose={onCloseHandler}
      title={product ? 'Edit membership' : 'Add membership'}
      className="CreatorMembershipUpsertProductModal"
    >
      <CreatorMembershipUpsertProduct
        product={product}
        productsRefresh={productsRefresh}
        onProductUpsert={onProductUpsert}
      />
    </NamedAnimatedModal>
  )
}

const CreatorMembershipUpsertProductForm = ({ edit }) => {
  const {
    isSubmitting,
    dirty,
    submitCount,
    setFieldValue,
    values,
  } = useFormikContext()
  const [isUploadingImage, setUploadingImage] = useState(false)

  return (
    <Form className="CreatorMembershipUpsertProductForm">
      <Prompt
        when={dirty && submitCount === 0}
        message="You have unsaved changes, are you sure you want to leave?"
      />

      <label
        className="creator-membership-upsert-product-form-cover"
        htmlFor="membershipCover"
      >
        <BackgroundImage
          className="creator-membership-upsert-product-form-cover-image"
          width={88}
          height={88}
          src={values.productImageUrl}
        />

        <div
          className={clsx(
            'creator-membership-upsert-product-form-photo-button',
            'creator-membership-upsert-product-form-cover-upload',
            {
              loading: isUploadingImage,
            }
          )}
        >
          <CameraSvg />
        </div>
      </label>
      <input
        type="file"
        onChange={async (e) => {
          try {
            setUploadingImage(true)
            const inputFile = e.target.files[0]
            const file = await fixImageOrientation(inputFile)
            const { url, urlFields, photoId } = await getImageUploadUrl({
              filename: inputFile.name,
            })
            await uploadToS3({
              url,
              urlFields,
              file,
            })

            setFieldValue('productImageUrl', URL.createObjectURL(file))
            setFieldValue('productImageId', photoId)
            setUploadingImage(false)
          } catch (e) {
            setUploadingImage(false)
          }
        }}
        id="membershipCover"
      />

      <BoonFormikTextField
        label="Title"
        name="name"
        placeholder="Add title"
        required
      />

      <div className="creator-membership-upsert-product-pricing">
        <div className="creator-membership-upsert-product-pricing-name">
          Monthly
        </div>
        <div className="creator-membership-upsert-product-pricing-adjustment">
          <BoonFormikTextField
            type="text"
            name="amount"
            prepend={
              <div className="creator-membership-upsert-product-pricing-currency">
                {values.currency.toUpperCase()}
              </div>
            }
          />
        </div>
        <div className="creator-membership-upsert-product-pricing-result">
          Per month
        </div>
      </div>

      <div className="creator-membership-upsert-product-price-helper">
        You can always change the base price, however members will remain on the
        price they subscribed for.
      </div>

      <CreatorMembershipPricing
        name="Quarterly"
        activeKey={INTERVAL_QUARTERLY_ACTIVE_KEY}
        discountKey={INTERVAL_QUARTERLY_DISCOUNT_KEY}
        interval={3}
      />
      <CreatorMembershipPricing
        name="Semi-Annually"
        activeKey={INTERVAL_SEMI_ANNUALLY_ACTIVE_KEY}
        discountKey={INTERVAL_SEMI_ANNUALLY_DISCOUNT_KEY}
        interval={6}
      />
      <CreatorMembershipPricing
        name="Annually"
        activeKey={INTERVAL_ANNUALLY_ACTIVE_KEY}
        discountKey={INTERVAL_ANNUALLY_DISCOUNT_KEY}
        interval={12}
      />

      <div className="creator-membership-upsert-product-price-helper">
        If you change your discounts, then members already subscribing will
        continue at their current discount until they choose to change plan or
        cancel their membership subscription.
      </div>

      <MarkdownTextEditorFormik name="description" />

      <StyledApiErrorMessage />

      <button
        type="submit"
        disabled={isSubmitting || !dirty}
        className={clsx(
          'button primary loader creator-membership-upsert-product-form-submit',
          {
            loading: isSubmitting,
          }
        )}
      >
        {edit ? 'Save changes' : 'Add membership'}
      </button>
    </Form>
  )
}

const CreatorMembershipPricing = ({
  name,
  activeKey,
  discountKey,
  interval,
}) => {
  const { values } = useFormikContext()
  const active = values[activeKey]
  const discountPercentage = parseInt(values[discountKey])
  const basePrice = parseFloat(values.amount)
  const currency = values.currency

  const discountMultiplier = (100 - discountPercentage) / 100

  const monthlyPrice = Math.floor(basePrice * discountMultiplier)
  const upfrontPrice = Math.floor(basePrice * interval * discountMultiplier)

  const monthlyPriceText = `${displayPrice(monthlyPrice, currency)} per month`
  const upfrontPriceText = `or ${displayPrice(
    upfrontPrice,
    currency
  )} every ${interval} months`

  return (
    <div
      className={clsx('creator-membership-upsert-product-pricing', {
        inactive: !active,
      })}
    >
      <div className="creator-membership-upsert-product-pricing-name">
        <Field
          id={activeKey}
          name={activeKey}
          type="checkbox"
          className="boon-checkbox"
        />
        <label htmlFor={activeKey}>{name}</label>
      </div>
      <div className="creator-membership-upsert-product-pricing-adjustment">
        <BoonFormikTextField
          type="text"
          name={discountKey}
          append={
            <div className="creator-membership-upsert-product-pricing-discount">
              %
            </div>
          }
        />
      </div>
      {!isNaN(monthlyPrice) && (
        <div className="creator-membership-upsert-product-pricing-result">
          <b>{monthlyPriceText}</b>
          <span>{upfrontPriceText}</span>
        </div>
      )}
    </div>
  )
}
