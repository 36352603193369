import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { useCallback, useEffect, useState } from 'react'

// Local storage key for coupon code storage
const getLocalStorageCouponCodeKey = (userSlug) => `promo-${userSlug}`

// Used on pages where coupon codes might be in the URL, to save them
export const useCatchCouponCode = (userSlug) => {
  const { search } = useLocation()
  const { promo } = queryString.parse(search)

  useEffect(() => {
    if (promo && userSlug != null) {
      window.sessionStorage.setItem(
        getLocalStorageCouponCodeKey(userSlug),
        promo
      )
    }
  }, [promo, userSlug])
}

// Retrieves saved coupon code for use on checkout pages and banners
export const useRetrieveCouponCode = (userSlug) => {
  const getCouponCode = () => {
    return window.sessionStorage.getItem(getLocalStorageCouponCodeKey(userSlug))
  }

  const [couponCode, setCouponCode] = useState(getCouponCode())

  const removeCouponCode = useCallback(() => {
    window.sessionStorage.removeItem(getLocalStorageCouponCodeKey(userSlug))
    setCouponCode(null)
  }, [userSlug, setCouponCode])

  return { couponCode, removeCouponCode }
}
