import React from 'react'
import { POST_ATTACHMENT_TYPES } from 'store/constants/post'
import PostCommentBodyAttachmentPhoto from 'components/PostComment/PostCommentBodyAttachmentPhoto'

const PostCommentBodyAttachment = ({ comment, requireAccess }) => {
  const attachment = comment.get('attachment')
  const attachmentType = comment.get('attachmentType')
  const attachmentAccess = comment.get('attachmentAccess')
  const commentId = comment.get('id')
  const hostId = comment.getIn(['host', 'id'])
  if (attachment == null || attachmentType == null) {
    return null
  }

  switch (attachmentType) {
    case POST_ATTACHMENT_TYPES.PHOTO:
      return (
        <PostCommentBodyAttachmentPhoto
          photo={attachment}
          access={attachmentAccess}
          commentId={commentId}
          hostId={hostId}
          productIds={requireAccess}
        />
      )
    default:
      return null
  }
}

export default PostCommentBodyAttachment
