import React, { useEffect } from 'react'
import { Prompt } from 'react-router'
import './UploadForm.scss'
import { Form, useFormikContext } from 'formik'
import UploadVideoField from 'components/UploadVideoField'
import { BoonFormikTextField } from 'components/BoonInputs'
import clsx from 'clsx'
import { sluggify, useExitPrompt } from 'utils'
import StyledApiErrorMessage from 'components/StyledApiErrorMessage'
import { useSelector } from 'react-redux'
import { getMe } from 'store/selectors'
import AvailabilityPanel from './AvailabilityPanel'
import BoonFormGroup from 'components/BoonInputs/BoonFormGroup'
import UploadImageField from 'components/UploadImageField'
import CategorySelector from 'components/CategorySelector'
import VisibilityPanel from 'components/VisibilityPanel'
import { getVisibilitySubmitButtonText } from 'components/VisibilityPanel/visibilityUtils'

const UploadForm = ({ edit, published }) => {
  const {
    values,
    errors,
    setFieldValue,
    isValid,
    isSubmitting,
    touched,
    submitCount,
    initialValues,
  } = useFormikContext()
  const me = useSelector((state) => getMe(state))

  useEffect(() => {
    if (!touched.slug && values.title && !edit) {
      const slug = sluggify(values.title)
      setFieldValue('slug', slug)
    }
  }, [values.title, touched.slug, setFieldValue, edit])

  const shouldPromptExit = !isSubmitting && Object.keys(touched).length !== 0
  useExitPrompt(shouldPromptExit)

  return (
    <Form className="UploadForm">
      <Prompt
        message="Are you sure you want to leave without saving?"
        when={shouldPromptExit}
      />
      <div className="upload-form-section">
        <BoonFormGroup
          label="Title"
          helperText="Keep it short and sweet."
          required
        >
          <BoonFormikTextField type="text" name="title" dark />
        </BoonFormGroup>

        <BoonFormGroup
          label="Video page link"
          helperText="This will be used to generate the link for your video."
          required
        >
          <BoonFormikTextField
            type="text"
            name="slug"
            dark
            prepend={`https://boon.tv/${me?.get('username') || '<username>'}/`}
          />
        </BoonFormGroup>

        <BoonFormGroup label="Description" required>
          <BoonFormikTextField name="description" multiline dark />
        </BoonFormGroup>

        <BoonFormGroup label="Categories" className="upload-form-categories">
          <CategorySelector />
        </BoonFormGroup>
      </div>

      <div className="upload-form-section">
        <div className="upload-form-section-title">Video</div>
        <BoonFormGroup
          label="Video file"
          helperText="We generally accept any and all video files, most commonly .mp4, .mov and .avi."
          required
        >
          <UploadVideoField
            videoId={values.recordedVideoUploadId}
            videoUrl={values.recordedVideoUrl}
            onSetId={(id) => setFieldValue('recordedVideoUploadId', id)}
            onSetUrl={(url) => setFieldValue('recordedVideoUrl', url)}
            slim
          />
          {errors.recordedVideoUploadId && touched.recordedVideoUploadId && (
            <div className="input-error">{errors.recordedVideoUploadId}</div>
          )}
        </BoonFormGroup>

        <BoonFormGroup
          label="Cover image"
          helperText="We recommend an aspect ratio of 16:9. If no image is provided, the first frame of the video will be used."
        >
          <UploadImageField
            imageId={values.coverImageId}
            imageUrl={values.coverImageUrl}
            onSetId={(id) => setFieldValue('coverImageId', id)}
            onSetUrl={(url) => setFieldValue('coverImageUrl', url)}
          />
        </BoonFormGroup>
      </div>

      <div className="upload-form-section">
        <AvailabilityPanel edit={edit} />
      </div>

      <div className="upload-form-section">
        <VisibilityPanel edit={edit} />
      </div>

      <StyledApiErrorMessage />
      {submitCount > 0 && !isValid && errors.message == null && (
        <div className="input-error">
          There were errors in the form, see above
        </div>
      )}
      <div className="upload-form-actions">
        <button
          type="submit"
          disabled={isSubmitting}
          className={clsx('button primary loader', {
            loading: isSubmitting,
          })}
        >
          {getVisibilitySubmitButtonText(
            'video',
            edit,
            initialValues.visibility,
            values.visibility
          )}
        </button>
      </div>
    </Form>
  )
}

export default UploadForm
