import { useSelector } from 'react-redux'
import { getMe } from 'store/selectors'

// Fetches the `me` redux store object
export const useMe = () => {
  return useSelector((state) => getMe(state))
}

// Fetches the `browser` redux store object
export const useBrowser = () => {
  return useSelector((state) => state.get('browser'))
}
