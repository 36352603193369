import React, { useRef } from 'react'
import './OptionsMenu.scss'
import { useOutsideAlerter } from 'utils'
import { OptionDotsSvg } from 'assets/svg'
import clsx from 'clsx'

const OptionsMenu = ({ open, className, children, setOpen, onClick, dark }) => {
  const wrapperRef = useRef()
  useOutsideAlerter(wrapperRef, open, () => {
    setOpen(false)
  })

  return (
    <div
      className={clsx('OptionsMenu', className, { dark })}
      ref={wrapperRef}
      onClick={(e) => {
        //e.preventDefault()
        e.stopPropagation()

        if (onClick) {
          onClick(e)
        }
      }}
    >
      <OptionDotsSvg
        className="options-menu-button"
        onClick={(e) => {
          setOpen(!open)
        }}
      />
      <OptionsMenuBox open={open} children={children} />
    </div>
  )
}

export default OptionsMenu

export const OptionsMenuBox = ({ open, children, onClick }) => {
  return (
    <div className="OptionsMenuBox" onClick={onClick}>
      <div className={clsx('options-menu-box-content', { open })}>
        {open && children}
      </div>
    </div>
  )
}
