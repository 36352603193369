import React, { useEffect } from 'react'
import './UploadPage.scss'
import UploadForm from './UploadForm'
import { useSelector } from 'react-redux'
import { NarrowContainer } from 'components/Grid'
import * as Yup from 'yup'
import { CREATOR_STATUS } from 'store/constants'
import { Helmet } from 'react-helmet-async'
import { asyncModal, getApiError, getEventUrl } from 'utils'
import { createEvent } from 'api'
import { getMe, isAuthenticated } from 'store/selectors'
import { URL_FRONT_PAGE, URL_LEAD_QUALIFICATION } from 'urls'
import EventHostHeader from 'components/EventHostHeader'
import { useHistory } from 'react-router-dom'
import HeaderProfile from 'components/HeaderProfile'
import {
  availabilityInitialValues,
  availabilityParseValues,
  availabilityValidations,
} from 'routes/UploadPage/availabilityUtils'
import { Formik } from 'formik'
import {
  shouldPromptForPublish,
  updateEventVisibility,
  visibilityInitialValues,
} from 'components/VisibilityPanel/visibilityUtils'
import EventPublishModal from 'modals/EventPublishModal'
import { useToasts } from 'react-toast-notifications'
import EventVisibilityModal from 'modals/EventVisibilityModal'

const UploadPageValidation = Yup.object().shape({
  recordedVideoUploadId:
    window.Cypress == null && Yup.string().nullable().required('Required'),
  title: Yup.string().required('Required'),
  slug: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
  ...availabilityValidations,
})

const UploadPage = () => {
  const history = useHistory()
  const hasAuth = useSelector((state) => isAuthenticated(state))
  const me = useSelector((state) => getMe(state))
  const { addToast } = useToasts()

  useEffect(() => {
    if (hasAuth) {
      if (me.get('creatorStatus') !== CREATOR_STATUS.CREATOR) {
        history.replace(URL_LEAD_QUALIFICATION)
      }
    } else {
      history.push(URL_FRONT_PAGE)
    }
  }, [history, me, hasAuth])

  const handleFormSubmit = async (values, { setSubmitting, setErrors }) => {
    let notifyFollowers
    if (shouldPromptForPublish(initialValues.visibility, values.visibility)) {
      const publishResult = await asyncModal(
        ({ active, onSubmit, onDismiss }) => (
          <EventPublishModal
            active={active}
            values={values}
            onClose={onDismiss}
            onPublish={onSubmit}
          />
        )
      )

      if (publishResult.error) {
        return null
      }

      notifyFollowers = publishResult.result
    }

    const data = {
      title: values.title,
      slug: values.slug,
      ...availabilityParseValues(values),
      description: values.description,
      recordedVideoUploadId: values.recordedVideoUploadId,
      coverImageId: values.coverImageId,
      categoryIds: values.categoryIds,
      live: false,
    }

    return createEvent({ data })
      .then(async (event) => {
        try {
          const updatedEvent = await updateEventVisibility(
            event.get('id'),
            initialValues.visibility,
            values.visibility,
            initialValues.publishAt,
            values.publishAt,
            notifyFollowers
          )

          if (updatedEvent) {
            asyncModal(({ active, onSubmit }) => (
              <EventVisibilityModal
                active={active}
                onClose={onSubmit}
                event={updatedEvent}
              />
            ))
          }
        } catch (e) {
          addToast(`Could change visibility on the video`, {
            appearance: 'error',
          })
        }

        history.replace(getEventUrl(event))
        setSubmitting(false)
      })
      .catch((e) => {
        setSubmitting(false)
        setErrors(getApiError(e))
      })
  }

  const initialValues = {
    title: '',
    slug: '',
    description: '',
    ...availabilityInitialValues(null, me),
    ...visibilityInitialValues(null),
    live: false,
    recordedVideoUploadId: null,
    recordedVideoUrl: null,
    coverImageId: null,
    coverImageUrl: null,
    categoryIds: [],
  }

  return (
    <div className="UploadPage">
      <HeaderProfile user={me} />
      <Helmet>
        <title>Upload video | Boon</title>
      </Helmet>
      <EventHostHeader onDemand />
      <NarrowContainer>
        <div className="upload-page-title">Upload video</div>
        <Formik
          initialValues={initialValues}
          validationSchema={UploadPageValidation}
          onSubmit={handleFormSubmit}
        >
          <UploadForm />
        </Formik>
      </NarrowContainer>
    </div>
  )
}

export default UploadPage
