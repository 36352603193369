import React, { useContext, useState } from 'react'
import { Col, Row } from 'components/Grid'
import InsightsConnectedNumberWidget from 'components/InsightsNumberWidget/InsightsConnectedNumberWidget'
import {
  currencyPrefixDataMapper,
  genericChartDataMapper,
  usePrefixedChartDataLabelMapper,
} from 'routes/InsightsPage/insightsUtils'
import { useMe } from 'utils'
import { useGetApi } from 'api'
import InsightsNumberWidget from 'components/InsightsNumberWidget'
import InsightsConnectedChartWidget from 'components/InsightsChartWidget/InsightsConnectedChartWidget'
import { INSIGHTS_PERIODS_NAMES } from 'routes/InsightsPage/insightsConstants'
import InsightsFilters from 'routes/InsightsPage/InsightsFilters'
import { viewsChartDataMapper } from 'routes/InsightsPage/InsightsVideosPage'
import InsightsContext from 'routes/InsightsPage/InsightsContext'

const participantsNumberWidgetDataMapper = (d) => ({
  value: d['meta']['pagination']['count'],
  previousValue: 0,
})

const barChartDataMapper = (data) =>
  Object.keys(data).length > 0
    ? Object.entries(data).map(([key, value]) => ({ x: key, y: value }))
    : [{ x: 0, y: 0 }]

const EventInsightsOverviewPage = ({ eventId, currency, currencySymbol }) => {
  const me = useMe()
  const prefixedChartDataLabelMapper = usePrefixedChartDataLabelMapper(
    currencySymbol
  )
  const userId = me.get('id')
  const { period, queryParams } = useContext(InsightsContext)
  const { data, loading, error } = useGetApi({
    url: `insights/members/details/${eventId}`,
  })

  const [soldEmpty, setSoldEmpty] = useState(false)
  const [incomeEmpty, setIncomeEmpty] = useState(false)

  const { views, ratings } = data ?? {}

  return (
    <div className="EventInsightsOverviewPage">
      <Row className="insights-members-top">
        {!soldEmpty && (
          <Col lg={3} md={6}>
            <InsightsConnectedNumberWidget
              title={'Tickets sold'}
              url={`events/${eventId}/participants`}
              timeScale={'All time'}
              dataMapper={participantsNumberWidgetDataMapper}
              removeEmpty={true}
              setEmpty={setSoldEmpty}
              customNumberFormatter={Intl.NumberFormat()}
            />
          </Col>
        )}
        {!incomeEmpty && (
          <Col lg={3} md={6}>
            <InsightsConnectedNumberWidget
              title={'Ticket income'}
              url={`income_stats/${userId}/revenue_ppv?event_id=${eventId}`}
              timeScale={'All time'}
              prefix={currencyPrefixDataMapper}
              removeEmpty={true}
              setEmpty={setIncomeEmpty}
            />
          </Col>
        )}
        <Col lg={3} md={6}>
          <InsightsNumberWidget
            title={'Views'}
            value={views}
            loading={loading}
            error={error}
            timeScale="All time"
          />
        </Col>
        <Col lg={3} md={6}>
          <InsightsNumberWidget
            title={'Ratings'}
            value={ratings}
            loading={loading}
            error={error}
            timeScale="All time"
          />
        </Col>
      </Row>

      <InsightsFilters />

      <Row className="insights-members-charts">
        <Col lg={4}>
          <InsightsConnectedChartWidget
            title={`Income (${currencySymbol})`}
            timeScale={INSIGHTS_PERIODS_NAMES[period]}
            url={`income_stats/${userId}/revenue_ppv_timeseries?event_id=${eventId}&${queryParams}`}
            dataMapper={genericChartDataMapper}
            labels={prefixedChartDataLabelMapper}
            chartType={'bar'}
          />
        </Col>
        <Col lg={4}>
          <InsightsConnectedChartWidget
            title="Views"
            timeScale={INSIGHTS_PERIODS_NAMES[period]}
            url={`insights/members/total_views_histogram?event_id=${eventId}&${queryParams}`}
            dataMapper={viewsChartDataMapper}
            chartType={'bar'}
          />
        </Col>
        <Col lg={4}>
          <InsightsConnectedChartWidget
            title="Ratings"
            timeScale={INSIGHTS_PERIODS_NAMES[period]}
            url={`events/${eventId}/feedback_items_sum?${queryParams}`}
            dataMapper={barChartDataMapper}
            chartType={'bar'}
          />
        </Col>
      </Row>
    </div>
  )
}

export default EventInsightsOverviewPage
