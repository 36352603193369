import React, { useRef, useState } from 'react'
import './PostCardLock.scss'
import { POST_ACCESS, POST_ATTACHMENT_TYPES } from 'store/constants/post'
import { CheckSvg, LockOpenSvg, LockSvg, PublicSvg } from 'assets/svg'
import { displayPrice, useOutsideAlerter } from 'utils'
import clsx from 'clsx'
import { useSelector } from 'react-redux'
import { selectActiveSubscriptions } from 'store/selectors'
import { getBillingName } from 'components/ProductCard/planUtils'

const PostCardLock = ({ post, onNoAccess, className, dark }) => {
  const wrapperRef = useRef()
  const [open, setOpen] = useState(false)
  useOutsideAlerter(wrapperRef, open, () => {
    setOpen(false)
  })
  const isMobile = window?.matchMedia('(hover: none)')?.matches

  const renderLock = () => {
    if (post.get('public')) {
      return <PublicSvg className="post-card-lock-button-public" />
    } else if (post.get('access') === POST_ACCESS.READ) {
      return <LockSvg className="post-card-lock-button-locked" />
    } else {
      return <LockOpenSvg className="post-card-lock-button-unlocked" />
    }
  }

  return (
    <div
      className={clsx('PostCardLock', { open, dark }, className)}
      ref={wrapperRef}
      onMouseLeave={() => !isMobile && setOpen(false)}
    >
      <div
        className="post-card-lock-button"
        onMouseEnter={() => !isMobile && setOpen(true)}
        onClick={() => setOpen(!open)}
      >
        {renderLock()}
      </div>
      {open && <PostCardLockMenu post={post} onNoAccess={onNoAccess} />}
    </div>
  )
}

export default PostCardLock

const PostCardLockMenu = React.memo(({ post, onNoAccess }) => {
  const hasAccess = post.get('access') !== POST_ACCESS.READ
  const isFree =
    post.get('attachmentType') === POST_ATTACHMENT_TYPES.EVENT &&
    post.getIn(['attachment', 'isFree'])
  const activeSubscriptions = useSelector((state) =>
    selectActiveSubscriptions(state)
  )
  const products = post.get('products')

  const renderOptions = () => {
    let productOptions = products.map((product) => {
      const plan = product.getIn(['plans', 0])
      if (plan == null) {
        return null
      }

      const isSubscribed = activeSubscriptions.find(
        (s) => s.getIn(['product', 'id']) === product.get('id')
      )

      const renderPrice = () => {
        if (isSubscribed) {
          return <CheckSvg className="post-card-lock-menu-option-purchased" />
        } else {
          if (hasAccess) {
            return null
          }

          return (
            <>
              {displayPrice(
                parseFloat(plan.get('amount')) / 100,
                plan.get('currency')
              )}
              /{getBillingName(plan)}ly
            </>
          )
        }
      }

      return (
        <div className="post-card-lock-menu-option" key={product.get('id')}>
          <div className="post-card-lock-menu-option-name">
            {product.get('name') || 'Membership'}
          </div>
          <div className="post-card-lock-menu-option-price">
            {renderPrice()}
          </div>
        </div>
      )
    })

    if (post.get('attachmentType') === POST_ATTACHMENT_TYPES.EVENT) {
      const event = post.get('attachment')
      const eventOption = (
        <div className="post-card-lock-menu-option" key="ppv">
          <div className="post-card-lock-menu-option-name">Ticket</div>
          <div className="post-card-lock-menu-option-price">
            {event.get('isFree') === true
              ? 'FREE'
              : displayPrice(
                  parseFloat(event.get('price')),
                  event.get('currency')
                )}
          </div>
        </div>
      )
      productOptions = productOptions.insert(0, eventOption)
    }

    if (post.get('public')) {
      const publicOption = (
        <div className="post-card-lock-menu-option" key="public">
          <div className="post-card-lock-menu-option-name">Public</div>
        </div>
      )
      productOptions = productOptions.insert(0, publicOption)
    } else if (productOptions.size === 0) {
      const loggedInOption = (
        <div className="post-card-lock-menu-option" key="logged-in">
          <div className="post-card-lock-menu-option-name">
            All logged in users
          </div>
        </div>
      )
      productOptions = productOptions.insert(0, loggedInOption)
    }

    return productOptions
  }

  return (
    <div className="PostCardLockMenu">
      <div className="post-card-lock-menu-header">
        <div className="post-card-lock-menu-header-title">
          Who can see this post?
        </div>
      </div>
      {renderOptions()}
      {!hasAccess && !isFree && (
        <button
          className="button primary post-card-lock-menu-cta"
          onClick={() => onNoAccess('Unlock to get access')}
        >
          Unlock
        </button>
      )}
    </div>
  )
})
