import React from 'react'

const InsightsTableClearFilter = ({ hasActiveFilter, clearFilters }) => {
  if (hasActiveFilter) {
    return (
      <span
        className="insight-table-widget-clear-filters"
        onClick={clearFilters}
      >
        Clear filters
      </span>
    )
  }

  return null
}

export default InsightsTableClearFilter
