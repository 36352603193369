import React from 'react'
import './PostCardAttachmentPoll.scss'
import Poll from 'components/Poll/Poll'

const PostCardAttachmentPoll = ({
  poll,
  post,
  access,
  onNoAccess,
  showAttachmentModal,
  onPostAttachmentUpdate,
}) => {
  return (
    <div className="PostCardAttachmentPoll">
      <Poll
        poll={poll}
        access={access}
        onNoAccess={onNoAccess}
        onVoteClick={showAttachmentModal}
        onPostAttachmentUpdate={onPostAttachmentUpdate}
      />
    </div>
  )
}

export default PostCardAttachmentPoll
