import React, { useCallback, useEffect, useState } from 'react'
import { Form, Formik, useFormikContext } from 'formik'

import NamedAnimatedModal from 'components/NamedAnimatedModal'
import * as Yup from 'yup'
import {
  CheckBoxCheckedSvg,
  CheckBoxUncheckedSvg,
  RadioCheckedSvg,
  RadioUncheckedSvg,
} from 'assets/svg'
import './CouponModal.scss'
import {
  BoonFormikInputAddonSelect,
  BoonFormikTextField,
} from 'components/BoonInputs'
import clsx from 'clsx'
import { getApiError } from 'utils'
import { createCoupon, updateCoupon } from 'api'
import { useSelector } from 'react-redux'
import { getMe } from 'store/selectors'

const CouponModal = ({
  active,
  onClose,
  onCouponAction,
  products,
  coupon: existingCoupon,
  isMobile,
}) => {
  const [isDeactivating, setIsDeactivating] = useState(false)
  const [isValuesPresent, setIsValuesPresent] = useState(false)
  const isEdit = existingCoupon != null
  const handleClose = useCallback(
    (e) => {
      if (
        isValuesPresent &&
        !window.confirm('Are you sure you want to close this window?')
      ) {
        return null
      }
      onClose(e)
    },
    [isValuesPresent, onClose]
  )

  return (
    <NamedAnimatedModal
      active={active}
      className={clsx('CouponModal', { browser: !isMobile })}
      title={isEdit ? 'Edit coupon' : 'Create coupon'}
      overlay
      onClose={handleClose}
    >
      <CouponActionsModalInner
        onCouponAction={onCouponAction}
        setIsValuesPresent={setIsValuesPresent}
        isEdit={isEdit}
        isDeactivating={isDeactivating}
        setIsDeactivating={setIsDeactivating}
        existingCoupon={existingCoupon}
        products={products}
      />
    </NamedAnimatedModal>
  )
}

export default CouponModal

const CouponActionsModalInner = ({
  onCouponAction,
  isEdit,
  isDeactivating,
  setIsDeactivating,
  existingCoupon,
  products,
  setIsValuesPresent,
}) => {
  const couponId = existingCoupon != null && existingCoupon.get('id')
  const me = useSelector((state) => getMe(state))

  const onDeactivate = useCallback(async () => {
    if (
      !window.confirm(
        `Are you sure you want to ${
          existingCoupon.get('status') === 'active' ? 'deactivate' : 'activate'
        } the coupon ${existingCoupon.get('name')}?`
      )
    ) {
      return null
    }

    try {
      await updateCoupon({
        couponId,
        data: {
          status:
            existingCoupon.get('status') === 'active' ? 'inactive' : 'active',
        },
      })
      onCouponAction()
    } catch (e) {
      alert('Updating failed, please try again')
    }
    setIsDeactivating(false)
  }, [couponId, onCouponAction, existingCoupon, setIsDeactivating])

  const onSubmit = async (values, { setSubmitting, setErrors }) => {
    try {
      setSubmitting(true)
      if (isEdit && !isDeactivating) {
        const coupon = await updateCoupon({ couponId, data: values })
        onCouponAction(coupon)
      } else if (!isDeactivating && !isEdit) {
        const coupon = await createCoupon({
          data: {
            ...values,
            amountOff:
              values.amountOff?.length > 0
                ? parseFloat(values.amountOff) * 100
                : '',
            currency: values.percentOff?.length > 0 ? null : values.currency,
          },
        })
        onCouponAction(coupon)
      } else {
        await onDeactivate()
      }
    } catch (e) {
      setErrors(getApiError(e))
    }
    setSubmitting(false)
  }

  const initialValues = isEdit
    ? {
        name: existingCoupon.get('name'),
        code: existingCoupon.get('code'),
        amountOff: existingCoupon.get('amountOff'),
        percentOff: existingCoupon.get('percentOff'),
        productIds: existingCoupon.get('appliesTo'),
        duration: existingCoupon.get('duration'),
        durationInMonths: existingCoupon.get('durationInMonths'),
        currency: existingCoupon.get('currency'),
      }
    : {
        name: '',
        code: '',
        amountOff: '',
        percentOff: '',
        productIds: [],
        duration: 'once',
        durationInMonths: '',
        currency: me.get('currency'),
      }

  const ModalActionSchemaCreate = Yup.object().shape({
    name: Yup.string()
      .required('Required')
      .max(30, 'Field is too long. Maximum length is 30'),
    code: Yup.string()
      .required('Required')
      .max(30, 'Field is too long. Maximum length is 30')
      .matches(/^(\S+$)/, 'Not allow white space'),
    amountOff: Yup.number()
      .typeError('Only numbers')
      .moreThan(0, 'Only positive numbers.'),
    percentOff: Yup.number()
      .typeError('Only numbers')
      .moreThan(0, 'Only positive numbers.')
      .lessThan(101, 'This value should be less than 101'),
    durationInMonths: Yup.number()
      .typeError('Only whole numbers')
      .integer('The duration cell can only contain whole numbers'),
  })

  const ModalActionSchemaUpdate = Yup.object().shape({
    name: Yup.string()
      .required('Required')
      .max(30, 'Field is too long. Maximum length is 30'),
    code: Yup.string()
      .required('Required')
      .max(30, 'Field is too long. Maximum length is 30'),
  })

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={
        isEdit ? ModalActionSchemaUpdate : ModalActionSchemaCreate
      }
    >
      <CouponModalForm
        products={products}
        setIsValuesPresent={setIsValuesPresent}
        isEdit={isEdit}
        me={me}
        setIsDeactivating={setIsDeactivating}
        existingCoupon={existingCoupon}
        discountType={isEdit ? !!existingCoupon.get('percentOff') : true}
      />
    </Formik>
  )
}

const CouponModalForm = ({
  me,
  products,
  isEdit,
  existingCoupon,
  setIsDeactivating,
  setIsValuesPresent,
  discountType = true,
}) => {
  const { values, setFieldValue, dirty, isSubmitting } = useFormikContext()
  const [isPercentDiscount, setIsPercentDiscount] = useState(discountType)
  const [isAppliesToAll, setIsAppliesToAll] = useState(true)
  const [checkedProducts, setCheckedProducts] = useState([])

  useEffect(() => {
    setFieldValue('productIds', checkedProducts)
  }, [checkedProducts, setFieldValue])

  useEffect(() => {
    if (values.code || values.name || values.amountOff || values.percentOff) {
      setIsValuesPresent(true)
    } else {
      setIsValuesPresent(false)
    }
  }, [values, setIsValuesPresent])

  const checkPercentage = () => {
    if (values.amountOff > 100) {
      setFieldValue('percentOff', 100)
    }
  }

  const renderCouponProductsNames = () => {
    if (existingCoupon.get('productNames').size) {
      return (
        <ul className="coupon-modal-form-edit-applies-to-ul">
          {existingCoupon.get('productNames').map((name) => {
            return <li key={name}>{name}</li>
          })}
        </ul>
      )
    } else {
      return null
    }
  }

  const renderCouponAppliesTo = () => {
    if (existingCoupon.get('productNames').size) {
      return 'Specific memberships'
    } else {
      return 'All'
    }
  }

  const renderProducts = () => {
    return products.map((product) => {
      const keyId = product.get('id')
      const productId = product.get('stripeProductId')

      return (
        <div
          key={keyId}
          className="coupon-modal-form-product-wrapper"
          onClick={() => {
            checkedProducts.includes(productId)
              ? setCheckedProducts(
                  checkedProducts.filter((id) => id !== productId)
                )
              : setCheckedProducts([...checkedProducts, productId])
          }}
        >
          {checkedProducts.includes(productId) ? (
            <CheckBoxCheckedSvg className="coupon-modal-form-product-checkbox-svg checked" />
          ) : (
            <CheckBoxUncheckedSvg className="coupon-modal-form-product-checkbox-svg unchecked" />
          )}
          <p>{product.get('name')}</p>
        </div>
      )
    })
  }

  const renderRadio = (value) => {
    if (isPercentDiscount === value) {
      return (
        <RadioCheckedSvg className="coupon-modal-form-type-radio-svg checked" />
      )
    } else {
      return (
        <RadioUncheckedSvg className="coupon-modal-form-type-radio-svg unchecked" />
      )
    }
  }

  const renderRadioProducts = (value) => {
    if (isAppliesToAll === value) {
      return (
        <RadioCheckedSvg className="coupon-modal-form-applies-to-svg checked" />
      )
    } else {
      return (
        <RadioUncheckedSvg className="coupon-modal-form-applies-to-svg unchecked" />
      )
    }
  }

  const isPercentageAmount = () => {
    return existingCoupon.get('percentOff') > 0
  }

  const renderCouponDuration = () => {
    const months = existingCoupon.get('durationInMonths')

    if (months > 0) {
      return `${months} month${months !== 1 && 's'}`
    } else {
      return `${existingCoupon.get('duration')}`
    }
  }

  return (
    <Form autoComplete="off">
      <div className="coupon-modal-form-name-wrapper">
        <BoonFormikTextField
          name="name"
          label="Name"
          field={{
            'data-lpignore': true,
          }}
          underText="This will appear on customers’ receipts and invoices."
        />
      </div>
      <div className="coupon-modal-form-code-wrapper">
        <BoonFormikTextField
          name="code"
          label="Code"
          field={{
            'data-lpignore': true,
          }}
        />
      </div>
      <div className="coupon-modal-form-type-wrapper">
        <p className="coupon-modal-form-type-title">Type</p>
        {isEdit ? (
          <div className="coupon-modal-form-type-edit">
            <p>
              {isPercentageAmount()
                ? 'Percentage discount'
                : 'Fixed amount discount'}
            </p>
          </div>
        ) : (
          <div className="coupon-modal-form-type-radio">
            <div
              className="coupon-modal-form-type-value"
              onClick={() => {
                setIsPercentDiscount(true)
                checkPercentage()
                setFieldValue('amountOff', '')
              }}
            >
              {renderRadio(true)} <p>Percentage discount</p>
            </div>
            <div
              className="coupon-modal-form-type-value"
              onClick={() => {
                setIsPercentDiscount(false)
                setFieldValue('percentOff', '')
              }}
            >
              {renderRadio(false)} <p>Fixed amount discount</p>
            </div>
          </div>
        )}
      </div>
      {isEdit ? (
        <div className="coupon-modal-form-edit-discount-wrapper">
          <p className="coupon-modal-form-edit-discount-title">
            {isPercentageAmount()
              ? 'Percentage discount'
              : 'Fixed amount discount'}
          </p>
          <p className="coupon-modal-form-edit-discount-text">
            {isPercentageAmount()
              ? parseFloat(existingCoupon.get('percentOff'))
              : parseFloat(existingCoupon.get('amountOff')) / 100}
            {isPercentageAmount()
              ? '%'
              : ` ${existingCoupon.get('currency').toUpperCase()}`}
          </p>
        </div>
      ) : (
        <div className="coupon-modal-form-discount-value-wrapper">
          <BoonFormikTextField
            name={isPercentDiscount ? 'percentOff' : 'amountOff'}
            label={
              isPercentDiscount
                ? 'Percentage discount'
                : 'Fixed amount discount'
            }
            append={isPercentDiscount ? '%' : null}
            prepend={
              isPercentDiscount ? null : me.get('currency').toUpperCase()
            }
            type="text"
            min={0}
            max={isPercentDiscount ? 100 : null}
            addonMainColor
          />
        </div>
      )}
      <div className="coupon-modal-form-applies-to-wrapper">
        <p className="coupon-modal-form-applies-to-title">Applies To</p>
        {isEdit ? (
          <p className="coupon-modal-form-edit-applies-to-value">
            {renderCouponAppliesTo()}
          </p>
        ) : (
          <div className="coupon-modal-form-applies-to-radio">
            <div
              className="coupon-modal-form-applies-to-value"
              onClick={() => {
                setIsAppliesToAll(true)
                setCheckedProducts([])
              }}
            >
              {renderRadioProducts(true)} <p>All</p>
            </div>
            <div
              className="coupon-modal-form-applies-to-value"
              onClick={() => setIsAppliesToAll(false)}
            >
              {renderRadioProducts(false)} <p>Specific memberships</p>
            </div>
          </div>
        )}
        {!isAppliesToAll && !isEdit && (
          <div className="coupon-modal-form-applies-to-products-wrapper">
            {renderProducts()}
          </div>
        )}
        {isEdit && renderCouponProductsNames()}
      </div>
      <div className="coupon-modal-form-duration-select">
        <p className="coupon-modal-form-duration-title">Coupon duration</p>
        {isEdit ? (
          <p className="coupon-modal-form-edit-duration-value">
            {renderCouponDuration()}
          </p>
        ) : (
          <BoonFormikInputAddonSelect
            type="text"
            name="duration"
            label="Coupon duration"
          >
            <option value="once">Once</option>
            <option value="repeating">Repeating</option>
            <option value="forever">Forever</option>
          </BoonFormikInputAddonSelect>
        )}
        {values.duration === 'repeating' && !isEdit && (
          <BoonFormikTextField
            name="durationInMonths"
            type="text"
            append="Month"
            addonMainColor
          />
        )}
        {!isEdit && (
          <p className="coupon-modal-form-duration-help-text">
            This determines how long this coupon will apply once redeemed.
          </p>
        )}
      </div>
      <button
        type="submit"
        className={clsx('button primary load coupon-modal-submit', {
          loading: isSubmitting,
        })}
        disabled={!dirty || isSubmitting}
      >
        {isEdit ? 'Save changes' : 'Create'}
      </button>
      {isEdit && (
        <button
          type="deactivate"
          onClick={() => setIsDeactivating(true)}
          className="button secondary coupon-modal-edit-deactivate"
        >
          Deactivate coupon
        </button>
      )}
    </Form>
  )
}
