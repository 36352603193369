export const POST_ATTACHMENT_TYPES = {
  EVENT: 'Event',
  PHOTO: 'Photo',
  DOCUMENT: 'Document',
  POLL: 'Poll',
}
export const POST_ATTACHMENT_ACCESS = {
  NONE: 'NONE',
}

export const POST_ACCESS = {
  READ: 'READ',
  FULL: 'FULL',
  ADMIN: 'ADMIN',
}

export const POST_ACCESS_LEVELS = {
  READ: 0,
  FULL: 1,
  ADMIN: 2,
}

export const POST_COMMENT_ACCESS = {
  NONE: 'NONE',
  WRITE: 'WRITE',
  FULL: 'FULL',
}

export const POST_COMMENT_ACCESS_LEVELS = {
  NONE: 0,
  WRITE: 1,
  FULL: 2,
}

export const POLL_THEMES = {
  DEFAULT: 'default',
  NEXT_LIVE: 'next_live',
  TELL_ME: 'tell_me',
  ASK_ME: 'ask_me',
}

export const POLL_THEME_NAMES = {
  [POLL_THEMES.DEFAULT]: 'Poll',
  [POLL_THEMES.NEXT_LIVE]: 'Next Live',
  [POLL_THEMES.TELL_ME]: 'Tell Me',
  [POLL_THEMES.ASK_ME]: 'Ask Me',
}
