import React, { useState } from 'react'
import './UpcomingLiveEventsContainer.scss'

import UpcomingLiveEvent from 'components/UpcomingLiveEvent'
import { ChevronDownSvg } from 'assets/svg'

import { useMe } from 'utils'
import { useGetLatestLiveEventsApi } from 'api'

const EVENTS_TO_SHOW = 3

const UpcomingLiveEventsContainer = ({ user, filters }) => {
  const me = useMe()
  const params = {}
  const [showAll, setShowAll] = useState(false)

  const renderButton = () => {
    return (
      <span
        className="button secondary upcoming-live-show-more-button"
        onClick={() => setShowAll(true)}
      >
        Show more
        <ChevronDownSvg />
      </span>
    )
  }
  if (filters.category_id) {
    params['category_id'] = filters.category_id
  }
  if (filters.product_id) {
    params['product_id'] = filters.product_id
  }

  const { data: latestLiveEvents } = useGetLatestLiveEventsApi(
    { userId: user.get('id'), params: params },
    [me?.get('id')]
  )

  if (!latestLiveEvents || latestLiveEvents.size === 0) {
    return ''
  }

  const shownEvents = showAll
    ? latestLiveEvents
    : latestLiveEvents.slice(0, EVENTS_TO_SHOW)

  return (
    <div className="UpcomingLiveEventsContainer">
      <div className="upcoming-live-events-container-title">Upcoming Live</div>
      {shownEvents.map((event) => (
        <UpcomingLiveEvent key={event.get('id')} event={event} />
      ))}
      {!showAll && shownEvents.size !== latestLiveEvents.size && renderButton()}
    </div>
  )
}

export default UpcomingLiveEventsContainer
