export const getCurrencySymbol = (currency) => {
  currency = currency ? currency.toUpperCase() : currency
  switch (currency) {
    case 'USD':
      return '$'
    case 'GBP':
      return '£'
    case 'EUR':
      return '€'
    default:
      return currency != null ? currency + ' ' : ''
  }
}

export const displayPrice = (price, currency) => {
  return `${getCurrencySymbol(currency)}${price}`
}
