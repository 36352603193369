import React, { useRef, useLayoutEffect, useState, useCallback } from 'react'
import './PostCommentBody.scss'
import { getFormattedActivityDate, useMe } from 'utils'
import clsx from 'clsx'
import PostCommentCompose from 'components/PostCommentCompose'
import { ThumbUpSvg } from 'assets/svg'
import { deleteLikeApi, createLikeApi, deleteCommentApi } from 'api'
import { Map } from 'immutable'
import {
  setReplyAttributes,
  setCommentAttributes,
  postReducerDeleteComment,
  postReducerDeleteReply,
} from 'components/ProfileFeed/postUtils'
import PostCommentBodyAttachment from 'components/PostComment/PostCommentBodyAttachment'
import { OptionsMenuBox } from 'components/OptionsMenu'
import Linkify from 'components/Linkify'
import RelationAvatar from 'components/RelationAvatar/RelationAvatar'

const PostCommentBody = ({
  postId,
  hostId,
  comment,
  reply,
  compose,
  onReplyClick,
  children,
  dispatchPosts,
  requireAccess,
}) => {
  const commentId = comment.get('id')
  const commentParentId = comment.get('parentId')
  const [likeError, setLikeError] = useState(false)
  const [showMore, setShowMore] = useState(false)
  const me = useMe()
  const liked = comment.get('liked')
  const likesCount = comment.get('likesCount')
  const composeRef = useRef()
  const avatarSize = reply ? 20 : 30

  const onLikeClick = async () => {
    if (!requireAccess()) {
      return null
    }

    try {
      if (liked) {
        await deleteLikeApi({ id: comment.get('id'), type: 'Comment' })
      } else {
        await createLikeApi({ id: comment.get('id'), type: 'Comment' })
      }
    } catch (e) {
      setLikeError(true)
      setTimeout(() => {
        setLikeError(false)
      }, 1500)
      return null
    }

    if (reply) {
      dispatchPosts(
        setReplyAttributes({
          postId,
          commentId: comment.get('parentId'),
          replyId: comment.get('id'),
          reply: Map({
            liked: !liked,
          }),
        })
      )
    } else {
      dispatchPosts(
        setCommentAttributes({
          postId,
          commentId: comment.get('id'),
          comment: Map({
            liked: !liked,
          }),
        })
      )
    }
  }

  const onDeleteClick = useCallback(async () => {
    setShowMore(false)

    if (window.confirm('Are you sure you want to delete this comment?')) {
      try {
        await deleteCommentApi({ commentId })

        if (reply) {
          dispatchPosts(
            postReducerDeleteReply({
              postId,
              commentId: commentParentId,
              replyId: commentId,
            })
          )
        } else {
          dispatchPosts(
            postReducerDeleteComment({
              postId,
              commentId: commentId,
            })
          )
        }
      } catch (e) {
        alert('We encountered an error deleting this comment, please try again')
      }
    }
  }, [setShowMore, commentId, dispatchPosts, commentParentId, postId, reply])

  useLayoutEffect(() => {
    if (compose && composeRef?.current != null) {
      composeRef.current.focus()
    }
  }, [compose, composeRef])

  const renderLikeCopy = () => {
    if (likeError) {
      return 'Error, please try again'
    }

    return likesCount > 0 ? likesCount : 'Like'
  }

  const displayMoreButton =
    me?.get('id') === comment.getIn(['user', 'id']) || me?.get('id') === hostId

  const message = comment
    .get('message')
    .split('^^^')
    .map((msg, idx) => {
      if (/\^\^/.test(msg)) {
        let [name] = msg.split('^^')
        return <strong key={idx}>{name}</strong>
      } else {
        return msg
      }
    })

  return (
    <div className={clsx('PostCommentBody', { reply })}>
      <RelationAvatar
        width={avatarSize}
        height={avatarSize}
        url={comment.getIn(['user', 'profileImageUrl'])}
        userId={comment.getIn(['user', 'id'])}
        className="post-comment-body-user-avatar"
        hostId={hostId}
      />
      <div className="post-comment-body-inner">
        <div className="post-comment-body-header">
          <div className="post-comment-body-user-name">
            {comment.getIn(['user', 'name'])}
          </div>
          <div className="post-comment-body-date">
            {getFormattedActivityDate(comment.get('createdAt'))}
          </div>
        </div>
        <div className="post-comment-body-message">
          <Linkify>{message}</Linkify>
        </div>
        <PostCommentBodyAttachment
          comment={comment}
          requireAccess={requireAccess}
        />
        <div className="post-comment-actions">
          <div
            className={clsx('post-comment-actions-action', {
              active: liked,
              error: likeError,
            })}
            onClick={onLikeClick}
          >
            <ThumbUpSvg className="post-comment-actions-action-icon" />
            {renderLikeCopy()}
          </div>
          <div className="post-comment-actions-action" onClick={onReplyClick}>
            Reply
          </div>
          {displayMoreButton && (
            <div
              className="post-comment-actions-action"
              onClick={() => setShowMore(!showMore)}
            >
              More
            </div>
          )}
          {displayMoreButton && (
            <OptionsMenuBox open={showMore}>
              <div onClick={onDeleteClick}>Delete</div>
            </OptionsMenuBox>
          )}
        </div>
        {children}
        {compose && (
          <div className="post-comment-compose">
            <PostCommentCompose
              hostId={hostId}
              postId={postId}
              commentId={comment.get('id')}
              small
              innerRef={composeRef}
              requireAccess={requireAccess}
              dispatchPosts={dispatchPosts}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default PostCommentBody
