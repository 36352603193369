import React from 'react'
import RelationsMenuMembership from './RelationsMenuMembership'

const RelationsMenuMembershipsTable = ({ user }) => {
  if (user.get('memberships').size === 0) {
    return null
  }

  return (
    <div className="relations-menu-modal-memberships">
      <h3 className="relations-menu-modal-memberships-membership-header">
        Memberships
      </h3>
      {user
        .get('memberships')
        .sortBy((membership) => membership.get('status'))
        .map((membership, idx) => (
          <RelationsMenuMembership membership={membership} key={idx} />
        ))}
    </div>
  )
}

export default RelationsMenuMembershipsTable
