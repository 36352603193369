import React, { useState, Suspense } from 'react'
import './BoonInputAddonEmoji.scss'
import { useField } from 'formik'
import Modal from 'components/Modal'
import { WrappedEmojiPicker } from 'components/ReactionButton/ReactionButton'
import Spinner from 'components/Spinner'

export const BoonInputAddonEmoji = ({ field }) => {
  const [active, setActive] = useState(false)

  const onEmojiSelect = (e) => {
    field.onChange({ target: { name: field.name, value: e.native } })
    setActive(false)
  }

  return (
    <div className="BoonInputAddonEmoji">
      <Suspense
        fallback={
          <div className="boon-input-addon-emoji-loader">
            <Spinner tiny light />
          </div>
        }
      >
        {active && (
          <Modal
            className="BoonInputAddonEmojiModal"
            overlay
            onClose={() => setActive(false)}
          >
            <WrappedEmojiPicker
              emoji="heart_eyes"
              title="Icon"
              onSelect={onEmojiSelect}
              native
              theme="dark"
            />
          </Modal>
        )}
      </Suspense>

      <div
        className="boon-input-addon-emoji-value"
        onClick={() => setActive(true)}
      >
        {field.value}
      </div>
    </div>
  )
}

export const BoonFormikInputAddonEmoji = (props) => {
  const [field, meta] = useField(props)
  return <BoonInputAddonEmoji field={field} meta={meta} {...props} />
}
