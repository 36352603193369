import React, { useEffect } from 'react'
import './PageModal.scss'
import Modal from 'components/Modal'
import clsx from 'clsx'
import CloseSvg from 'assets/svg/CloseSvg'

const PageModal = ({ onClose, children, className }) => {
  const _onClose = (e) => {
    e.stopPropagation()
    onClose(e)
  }

  useEffect(() => {
    const listener = (e) => {
      if (e.keyCode === 27) {
        onClose()
      }
    }

    window.addEventListener('keydown', listener)

    return () => window.removeEventListener('keydown', listener)
  }, [onClose])

  useEffect(() => {
    document.body.style.overflow = 'hidden'
    return () => (document.body.style.overflow = null)
  }, [])

  return (
    <Modal onClose={_onClose} className={clsx('PageModal', className)}>
      {children}
      <div className="page-modal-close" onClick={_onClose}>
        <CloseSvg className="page-modal-close-icon" />
      </div>
    </Modal>
  )
}

export default PageModal
