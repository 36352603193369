import React, { useCallback, useLayoutEffect } from 'react'
import './PostComments.scss'
import PostComment from 'components/PostComment/PostComment'
import PostCommentCompose from 'components/PostCommentCompose'
import { POST_COMMENT_ACCESS } from 'store/constants/post'

const PostComments = ({
  post,
  dispatchPosts,
  loadMoreComments,
  commentInputRef,
  loadingMoreComments,
  moreCommentsError,
  onNoAccess,
  showCompose,
  unpackReplyId,
}) => {
  const loadedCount = post
    .get('comments')
    .reduce(
      (acc, comment) =>
        (comment.get('replies') ? comment.get('replies').size : 0) + 1 + acc,
      0
    )
  const totalCount = post.get('commentsCount')
  const moreComments = totalCount > loadedCount
  const commentDiff = totalCount - loadedCount
  const hasAccess = post.get('commentsAccess') !== POST_COMMENT_ACCESS.NONE

  const requireAccess = useCallback(() => {
    if (!hasAccess) {
      onNoAccess('Unlock to comment on this post')
      return false
    }

    return true
  }, [hasAccess, onNoAccess])

  const renderComments = () => {
    return post
      .get('comments')
      .map((comment) => (
        <PostComment
          key={comment.get('id')}
          postId={post.get('id')}
          hostId={post.getIn(['host', 'id'])}
          comment={comment}
          dispatchPosts={dispatchPosts}
          requireAccess={requireAccess}
          unpackReplyId={unpackReplyId}
        />
      ))
  }

  const renderMoreCommentsCopy = () => {
    if (!moreComments) {
      return null
    }

    if (loadingMoreComments) {
      return (
        <div className="post-comments-more loading">
          Loading more comments...
        </div>
      )
    }

    if (moreCommentsError) {
      return (
        <div className="post-comments-more error" onClick={loadMoreComments}>
          {moreCommentsError?.message}, click here to try again
        </div>
      )
    }

    return (
      <div className="post-comments-more" onClick={loadMoreComments}>
        View {commentDiff} more {commentDiff > 1 ? 'comments' : 'comment'}
      </div>
    )
  }

  useLayoutEffect(() => {
    if (showCompose) {
      if (commentInputRef?.current != null) {
        commentInputRef.current.focus({
          preventScroll: true,
        })
      }
    }
  }, [commentInputRef, showCompose])

  if (!hasAccess) {
    return null
  }

  return (
    <div className="PostComments">
      {(showCompose || post.get('comments').size > 0) && (
        <div className="post-comments-border" />
      )}
      {renderMoreCommentsCopy()}
      {renderComments()}
      {showCompose && (
        <div className="post-comments-compose">
          <PostCommentCompose
            hostId={post.getIn(['host', 'id'])}
            postId={post.get('id')}
            innerRef={commentInputRef}
            requireAccess={requireAccess}
            dispatchPosts={dispatchPosts}
          />
        </div>
      )}
    </div>
  )
}

export default PostComments
