import React from 'react'
import { useBrowser } from 'utils'

import './RelationsMenuModal.scss'
import RelationsMenuUserTable from './RelationsMenuUserTable'
import RelationsMenuMembershipsTable from './RelationsMenuMembershipsTable'
import RelationsMenuTransactionTable from './RelationsMenuTransactionTable'
import MenuModal from 'components/MenuModal'
import CloseSvg from 'assets/svg/CloseSvg'
import Spinner from 'components/Spinner'

import { useGetMembersApi } from 'api'

export const RELATION_TYPE = {
  RELATION: 'relation',
  TRANSACTION: 'transaction',
}

const RelationsMenuModal = ({
  hostId,
  userId,
  onClose,
  type,
  transactionId,
}) => {
  const browser = useBrowser()

  const { data: user } = useGetMembersApi({
    hostId,
    userId,
    type,
    disabled: hostId === null || userId === null,
  })

  const renderType = () => {
    switch (type) {
      case RELATION_TYPE.RELATION:
        return <RelationsMenuMembershipsTable user={user} />
      case RELATION_TYPE.TRANSACTION:
        return (
          <RelationsMenuTransactionTable
            user={user}
            transactionId={transactionId}
          />
        )
      default:
        return null
    }
  }

  const renderLatestFeedback = () => {
    if (!user.get('latest_video_feedback')) {
      return null
    }

    return (
      <div className="relations-menu-modal-feedback">
        <h3 className="relations-menu-modal-feedback-header">
          Latest feedback
        </h3>
        <div className="relations-menu-modal-feedback-feedback">
          {user.get('latest_video_feedback')}
        </div>
      </div>
    )
  }

  const renderInner = () => {
    return (
      <>
        <div className="relations-menu-modal-header">
          <div className="relations-menu-modal-name">{user.get('name')}</div>
          <div className="relations-menu-modal-close" onClick={onClose}>
            <CloseSvg className="relations-menu-modal-close-icon" />
          </div>
        </div>
        <RelationsMenuUserTable user={user} />
        {renderLatestFeedback()}
        {renderType()}
      </>
    )
  }

  const active = userId !== null

  return (
    <MenuModal
      className="RelationsMenuModal"
      active={active}
      onClose={onClose}
      direction={browser.greaterThan.md ? 'right' : 'bottom full-screen'}
    >
      {active && user ? renderInner() : <Spinner />}
    </MenuModal>
  )
}

export default RelationsMenuModal
