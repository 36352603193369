import { useHistory } from 'react-router-dom'
import { AUTH_MODAL_STATE } from 'store/constants'
import { useMount, useLocation } from 'react-use'
import { requireAuth } from 'store/utils/awaitStore'
import { URL_FRONT_PAGE } from 'urls'
import queryString from 'query-string'

const SignInPage = () => {
  const location = useLocation()
  const history = useHistory()

  useMount(async () => {
    const { redirect_url, await_auth } = queryString.parse(location.search)

    const url = redirect_url || URL_FRONT_PAGE
    const wait = true //await_auth != null

    if (await requireAuth(AUTH_MODAL_STATE.SIGN_IN, wait)) {
      history.replace(url)
    } else {
      history.replace(wait ? URL_FRONT_PAGE : url)
    }
  })

  return null
}

export default SignInPage
