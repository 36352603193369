import React, { useCallback, useReducer, useState, useEffect } from 'react'
import './Videos.scss'
import { getApiError, useInfiniteScrollBody, useMe } from 'utils'
import { getUserEventsApi, getUserEventsProgressApi } from 'api'
import EventCard from 'components/EventCard'
import EmptyList from 'components/EmptyList'
import Spinner from 'components/Spinner'
import { Map, List } from 'immutable'
import { useAsync } from 'react-use'

const eventReducerClear = () =>
  Map({
    type: 'CLEAR',
  })

const eventReducerMerger = (events) =>
  Map({
    type: 'MERGE',
    events,
  })

const eventReducer = (state, action) => {
  switch (action.get('type')) {
    case 'MERGE':
      return state.concat(action.get('events'))
    case 'CLEAR':
      return List()
    default:
      return List()
  }
}

const Videos = ({ user, filters, dispatchFilter, setPostCount }) => {
  const userId = user.get('id')
  const me = useMe()
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)
  const [hasMore, setHasMore] = useState(false)
  const [events, dispatchEvents] = useReducer(eventReducer, List())
  const [paginationPage, setPaginationPage] = useState(0)
  const meId = me.get('id')
  const [page, bottomRef] = useInfiniteScrollBody({ hasMore })
  const [progresses, setProgresses] = useState(null)

  useEffect(() => {
    const run = async () => {
      // Fetch initial data
      setLoading(true)
      setError(null)
      try {
        const data = await getUserEventsApi({
          userSlug: userId,
          params: {
            ...filters,
          },
        })
        dispatchEvents(eventReducerMerger(data.get('events')))
        setHasMore(data.getIn(['meta', 'next']) !== null)
        setPaginationPage(data.getIn(['meta', 'next']))
        setPostCount(data.getIn(['meta', 'count']))
        meId &&
          setProgresses(await getUserEventsProgressApi({ hostId: userId }))
        setLoading(false)
      } catch (e) {
        setError(getApiError(e))
        setLoading(false)

        return null
      }
    }

    dispatchEvents(eventReducerClear())
    run()
  }, [meId, userId, filters, setPostCount])

  const loadMore = useCallback(async () => {
    setError(null)
    let data
    try {
      data = await getUserEventsApi({
        userSlug: userId,
        params: {
          page: paginationPage,
          ...filters,
        },
      })
    } catch (e) {
      setError(getApiError(e))
      setLoading(false)
      return null
    }

    dispatchEvents(eventReducerMerger(data.get('events')))
    setHasMore(data.getIn(['meta', 'next']) !== null)
    setPaginationPage(data.getIn(['meta', 'next']))
  }, [userId, paginationPage, filters])

  useAsync(async () => {
    if (page === 0) {
      return null
    }

    loadMore()
  }, [page])

  if (loading && !error) {
    return (
      <div className="Videos videos-loading">
        <Spinner small />
      </div>
    )
  }

  const renderVideos = () => {
    return events.map((event) => (
      <EventCard
        key={event.get('id')}
        event={event}
        progress={progresses && progresses.get(event.get('id'))}
      />
    ))
  }

  if (events.size === 0) {
    return <EmptyList filters={filters} dispatchFilter={dispatchFilter} />
  }

  return (
    <div className="Videos">
      {renderVideos()}
      <div ref={bottomRef}></div>
    </div>
  )
}

export default Videos
