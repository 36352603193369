export const CREATOR_STATUS = {
  USER: 'user',
  INTERESTED: 'interested',
  CREATOR: 'creator',
}

export const PROFILE_STATUS = {
  DRAFT: 'draft',
  PUBLISHED: 'published',
}

export const HOST_ACCESS = {
  WRITE: 'WRITE',
  NONE: 'NONE',
  ADMIN: 'ADMIN',
}
