import React, { useState } from 'react'
import './EventPublishModal.scss'
import NamedPinnedModal from 'components/NamedPinnedModal'
import { CheckSvg } from 'assets/svg'
import { displayPrice } from 'utils'
import { EVENT_VISIBILITY } from 'store/constants'

const EventPublishModal = ({ active, values, onClose, onPublish }) => {
  const [notifyFollowers, setNotifyFollowers] = useState(true)

  const handlePublish = () => {
    onPublish(notifyFollowers)
  }

  console.log(values)

  const renderPayPerView = () => {
    if (
      values.price == null ||
      values.price === '' ||
      !values.payPerViewChecked
    ) {
      return null
    }

    return (
      <div className="event-publish-modal-availability-item">
        <CheckSvg className="event-publish-modal-availability-item-icon" />
        <div className="event-publish-modal-availability-item-inner">
          <div className="event-publish-modal-availability-item-title">
            Pay-per-view
          </div>
          <div className="event-publish-modal-availability-item-property">
            {displayPrice(values.price, values.currency)}
          </div>
        </div>
      </div>
    )
  }

  const renderFree = () => {
    if (values.availabilityPick === 'free') {
      return (
        <div className="event-publish-modal-availability-item">
          <CheckSvg className="event-publish-modal-availability-item-icon" />
          <div className="event-publish-modal-availability-item-inner">
            <div className="event-publish-modal-availability-item-title">
              Free
            </div>
            <div className="event-publish-modal-availability-item-property">
              Available to all followers and members
            </div>
          </div>
        </div>
      )
    }
  }

  const renderMembership = () => {
    if (values.productIds.length > 0) {
      return (
        <div className="event-publish-modal-availability-item">
          <CheckSvg className="event-publish-modal-availability-item-icon" />
          <div className="event-publish-modal-availability-item-inner">
            <div className="event-publish-modal-availability-item-title">
              Membership
            </div>
            <div className="event-publish-modal-availability-item-property">
              {values.productIds.length} membership plans
            </div>
          </div>
        </div>
      )
    }
    /*return values.get('products').map((product) => {
      const plan = product
        .get('plans')
        .filter((plan) => plan.get('active'))
        .first()

      if (plan == null) {
        return null
      }

      return (
        <div className="event-publish-modal-availability-item">
          <CheckSvg className="event-publish-modal-availability-item-icon" />
          <div className="event-publish-modal-availability-item-inner">
            <div className="event-publish-modal-availability-item-title">
              {product.get('name')}
            </div>
            <div className="event-publish-modal-availability-item-property">
              {displayPrice(plan.get('amount') / 100, plan.get('currency'))}
            </div>
          </div>
        </div>
      )
    })*/
  }

  return (
    <NamedPinnedModal
      active={active}
      title={
        values.visibility === EVENT_VISIBILITY.SCHEDULED
          ? 'Schedule'
          : 'Publish'
      }
      onClose={onClose}
      className="EventPublishModal"
    >
      <div className="event-publish-modal-description">
        When published, your {values.live ? 'event' : 'video'} will be listed on
        your profile.
      </div>
      <div className="event-publish-modal-availability">
        <div className="event-publish-modal-availability-title">
          Availability
        </div>
        {renderFree()}
        {renderPayPerView()}
        {renderMembership()}
      </div>

      <input
        id="notifyFollowers"
        type="checkbox"
        className="boon-checkbox"
        checked={notifyFollowers}
        onChange={(e) => setNotifyFollowers(e.target.checked)}
      />
      <label
        className="event-publish-modal-notify-followers-label"
        htmlFor="notifyFollowers"
      >
        Notify followers by email
        <br />
        <div className="event-publish-modal-notify-followers-label-small">
          Some followers might have email notifications disabled.
        </div>
      </label>

      <button
        className={'button primary event-publish-modal-confirm'}
        onClick={handlePublish}
      >
        Confirm
      </button>
    </NamedPinnedModal>
  )
}

export default EventPublishModal
