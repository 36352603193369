import * as Yup from 'yup'
import { yupTestPriceCurrency } from 'utils'

export const availabilityInitialValues = (event, me) => {
  const defaultCurrency = me?.get('currency') || 'dkk'

  if (event) {
    return {
      availabilityPick: event.get('isFree') === true ? 'free' : 'paid',
      payPerViewChecked:
        event.get('price') !== null ||
        (!event.get('isFree') && event.get('productIds').size === 0),
      currency: event.get('currency') ?? defaultCurrency,
      price: event.get('price') != null ? parseFloat(event.get('price')) : null,
      seats: event.get('seats'),
      productIds: event.get('productIds').toArray(),
      availableOnDemand: event.get('live')
        ? event.get('availableOnDemand')
        : true,
    }
  } else {
    return {
      availabilityPick: 'paid',
      payPerViewChecked: true,
      currency: defaultCurrency,
      price: '',
      seats: '',
      productIds: [],
      availableOnDemand: true,
    }
  }
}
export const availabilityParseValues = (values) => {
  return {
    free: values.availabilityPick === 'free',
    currency: values.payPerViewChecked ? values.currency : null,
    price: values.payPerViewChecked ? parseFloat(values.price) : null,
    productIds: values.productIds,
    seats:
      values.payPerViewChecked || values.availabilityPick === 'free'
        ? parseInt(values.seats) || 0
        : 0,
    availableOnDemand: values.payPerViewChecked
      ? values.availableOnDemand
      : values.availabilityPick === 'free',
  }
}
export const availabilityValidations = {
  currency: Yup.mixed().when(['availabilityPick', 'payPerViewChecked'], {
    is: (availabilityPick, payPerViewChecked) =>
      availabilityPick === 'paid' && payPerViewChecked,
    then: Yup.string()
      .matches(
        /(dkk|sek|nok|usd|gbp|eur|cad|jpy|chf)/,
        'You must select a currency'
      )
      .typeError('You must select a currency')
      .required('Required'),
  }),
  price: Yup.mixed().when(['availabilityPick', 'payPerViewChecked'], {
    is: (availabilityPick, payPerViewChecked) =>
      availabilityPick === 'paid' && payPerViewChecked,
    then: Yup.number()
      .min(1, 'Min 1')
      .typeError('Must be a number')
      .test(...yupTestPriceCurrency)
      .required('Required'),
  }),
  seats: Yup.mixed().when(['availabilityPick', 'payPerViewChecked', 'live'], {
    is: (availabilityPick, ppv, live) =>
      availabilityPick === 'paid' && ppv && live,
    then: Yup.number()
      .min(1, 'Min 1 seat')
      .max(10000, 'Max 10,000 seats')
      .typeError('Must be a number')
      .required('Required'),
  }),
  productIds: Yup.mixed().when(['availabilityPick', 'payPerViewChecked'], {
    is: (availabilityPick, payPerViewChecked) =>
      availabilityPick === 'paid' && !payPerViewChecked,
    then: Yup.array()
      .min(1, 'Either membership or pay-per-view must be selected')
      .required('Required'),
  }),
}
