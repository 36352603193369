import React from 'react'
import './CreatorSetupPayout.scss'
import { Col, Row } from 'components/Grid'
import CreatorSettingsPayoutAccount from 'routes/CreatorSettingsPage/CreatorSettingsPayoutAccount'
import { URL_CREATOR_SETUP_PAYOUT } from 'urls'
import CreatorSettingsHeader from 'routes/CreatorSettingsPage/CreatorSettingsHeader'
import ProfileCompletion from './ProfileCompletion'

const CreatorSetupPayout = ({ user, triedLaunching }) => {
  return (
    <div className="CreatorSetupPayout">
      <CreatorSettingsHeader
        title="Payout"
        description="Manage your income with your personal payout account."
      />
      <Row>
        <Col xs={12} lg={9}>
          <CreatorSettingsPayoutAccount returnUrl={URL_CREATOR_SETUP_PAYOUT} />
        </Col>
        <Col xs={12} lg={3}>
          <ProfileCompletion user={user} triedLaunching={triedLaunching} />
        </Col>
      </Row>
    </div>
  )
}

export default CreatorSetupPayout
