import React, { useContext, useCallback } from 'react'
import './InsightsVideosPage.scss'
import { Col, Row } from 'components/Grid'

import InsightsConnectedNumberWidget from 'components/InsightsNumberWidget/InsightsConnectedNumberWidget'
import InsightsConnectedChartWidget from 'components/InsightsChartWidget/InsightsConnectedChartWidget'
import InsightsConnectedTableWidget from 'components/InsightsTableWidget/InsightsConnectedTableWidget'

import InsightsFilters from 'routes/InsightsPage/InsightsFilters'
import InsightsContext from 'routes/InsightsPage/InsightsContext'
import EventInsightsMenu from 'routes/InsightsPage/EventInsightsMenu'
import { INSIGHTS_PERIODS_NAMES } from 'routes/InsightsPage/insightsConstants'

import { URL_EVENT_INSIGHTS_OVERVIEW } from 'urls'
import { useHistory } from 'react-router-dom'
import { useMe, getMuxThumbnail } from 'utils'

const numberWidgetDataMapper = ({ value, previousValue }, ratio) => ({
  value: value / ratio,
  previousValue: previousValue / ratio,
})

const totalViewTimeDataMapper = (data) => numberWidgetDataMapper(data, 3600.0)
const avgViewTimeDataMapper = (data) => numberWidgetDataMapper(data, 60.0)

export const viewsChartDataMapper = (data) => {
  return data.map(({ key_as_string, doc_count }) => ({
    x: new Date(key_as_string),
    y: doc_count,
  }))
}

const chartWidgetDataMapper = (data, ratio) => {
  return Object.keys(data).map((key) => ({
    x: new Date(key),
    y: parseFloat((data[key] / ratio).toFixed(2)),
  }))
}

const totalViewTimeChartDataMapper = (data) =>
  chartWidgetDataMapper(data, 3600.0)
const avgViewTimeChartDataMapper = (data) => chartWidgetDataMapper(data, 60.0)

const InsightsVideosPage = () => {
  const { period, queryParams } = useContext(InsightsContext)
  const history = useHistory()
  const me = useMe()
  const meId = me.get('id')
  const clickableRow = useCallback(
    (object) => {
      history.push(URL_EVENT_INSIGHTS_OVERVIEW(meId, object.id))
    },
    [history, meId]
  )

  const renderNumberWidgets = () => {
    return (
      <Row>
        <Col lg={4}>
          <InsightsConnectedNumberWidget
            title="Views"
            timeScale="Last 30 days"
            url="insights/members/total_views"
          />
        </Col>
        <Col lg={4}>
          <InsightsConnectedNumberWidget
            title="Total View Time"
            timeScale="Last 30 days"
            url="insights/members/total_view_time"
            suffix={' hours'}
            dataMapper={totalViewTimeDataMapper}
          />
        </Col>
        <Col lg={4}>
          <InsightsConnectedNumberWidget
            title="Avg. View Time"
            timeScale="Last 30 days"
            url="insights/members/average_view_time"
            suffix={' minutes'}
            dataMapper={avgViewTimeDataMapper}
          />
        </Col>
      </Row>
    )
  }

  const renderCharts = () => {
    return (
      <Row className="insights-members-charts">
        <Col lg={4}>
          <InsightsConnectedChartWidget
            title="Views"
            timeScale={INSIGHTS_PERIODS_NAMES[period]}
            url={`insights/members/total_views_histogram?${queryParams}`}
            dataMapper={viewsChartDataMapper}
            chartType={'bar'}
          />
        </Col>
        <Col lg={4}>
          <InsightsConnectedChartWidget
            title="Total View Time (hours)"
            timeScale={INSIGHTS_PERIODS_NAMES[period]}
            url={`insights/members/total_view_time_histogram?${queryParams}`}
            dataMapper={totalViewTimeChartDataMapper}
            chartType={'bar'}
          />
        </Col>
        <Col lg={4}>
          <InsightsConnectedChartWidget
            title="Avg. View Time (minutes)"
            timeScale={INSIGHTS_PERIODS_NAMES[period]}
            url={`insights/members/average_view_time_histogram?${queryParams}`}
            dataMapper={avgViewTimeChartDataMapper}
            chartType={'bar'}
          />
        </Col>
      </Row>
    )
  }

  const renderSelectors = () => {
    return <InsightsFilters filterProducts />
  }

  const renderTable = () => {
    const headers = [
      { key: 'title', type: 'video', active: true },
      { key: 'duration', type: 'timespan', active: true },
      {
        key: 'published_at',
        name: 'Published Date',
        type: 'date',
        active: true,
      },
      { key: 'views', type: 'integer', active: true },
      { key: 'finishes', type: 'integer', active: true },
      { key: 'viewers', type: 'integer', active: true },
      {
        key: 'total_view_time',
        name: 'Total View Time',
        type: 'timespan',
        active: false,
      },
      {
        key: 'avg_view_time',
        name: 'Avg. View Time',
        type: 'timespan',
        active: true,
      },
      {
        key: 'avg_percent_watched',
        name: 'Avg. % Watched',
        type: 'percent',
        active: true,
      },
      { key: 'avg_rating', name: 'Avg. Rating', type: 'rating', active: true },
    ]

    const availableFilters = [
      { value: 'Live', active: false },
      { value: 'Uploaded', key: 'video', active: false },
    ]

    const dataMapper = (data) => {
      data.events = data.events.map((event) => {
        let imageUrl =
          event.cover_image_url ||
          getMuxThumbnail(event.trailer_video_url) ||
          getMuxThumbnail(event.cover_video_url)
        event.title = [event.title, imageUrl]
        return event
      })

      return data
    }

    return (
      <Row>
        <Col xs={12}>
          <InsightsConnectedTableWidget
            title="Videos"
            baseUrl={`insights/members/videos`}
            dataKey={'events'}
            dataMapper={dataMapper}
            headers={headers}
            defaultFilters={availableFilters}
            defaultSortBy={'published_at desc'}
            clickableRow={clickableRow}
            optionsMenu={(rowObj) => (
              <EventInsightsMenu
                eventId={rowObj['id']}
                eventTitle={rowObj['title'][0]}
                eventLive={rowObj['live']}
                eventArchived={rowObj['archived']}
              />
            )}
          />
        </Col>
      </Row>
    )
  }

  return (
    <div className="InsightsVideosPage">
      {renderNumberWidgets()}
      {renderSelectors()}
      {renderCharts()}
      {renderTable()}
    </div>
  )
}

export default InsightsVideosPage
