import React, { useCallback, useState } from 'react'
import './EventPageModal.scss'
import PageModal from 'components/PageModal'
import NewEventPage from 'routes/EventPage/components/NewEventPage'
import { getMe } from 'store/selectors'
import { useGetEventApi } from 'api'
import Spinner from 'components/Spinner'
import LoadingError from 'components/LoadingError'
import { useSelector } from 'react-redux'
import { URL_EVENT } from 'urls'
import { useTemporaryModalUrl } from 'utils'
import CheckoutModal from 'modals/CheckoutModal'

const EventPageModal = ({ userSlug, eventSlug, onClose }) => {
  const eventUrl = URL_EVENT(userSlug, eventSlug)
  useTemporaryModalUrl(eventUrl, onClose)
  const me = useSelector((state) => getMe(state))
  const [checkoutOpen, setCheckoutOpen] = useState(false)
  const { data: event, error, refresh: refreshEvent } = useGetEventApi(
    {
      userSlug,
      eventSlug,
    },
    [me?.get('id')]
  )

  const onNoAccess = useCallback(() => {
    setCheckoutOpen(true)
  }, [setCheckoutOpen])

  const renderInner = () => {
    if (error) {
      return <LoadingError refresh={refreshEvent} />
    }

    if (event == null) {
      return <Spinner light />
    }

    const user = event.get('host')

    return (
      <>
        <CheckoutModal
          active={checkoutOpen}
          title={`Unlock ${event.get('title')}`}
          event={event}
          hostId={user.get('id')}
          onClose={() => setCheckoutOpen(false)}
          productIds={event.get('productIds')}
        />
        <NewEventPage
          event={event}
          refreshEvent={refreshEvent}
          user={user}
          userSlug={userSlug}
          onClose={onClose}
          onNoAccess={onNoAccess}
        />
      </>
    )
  }

  return (
    <PageModal onClose={onClose} className="EventPageModal">
      {renderInner()}
    </PageModal>
  )
}

export default EventPageModal
