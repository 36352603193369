import React from 'react'
import './PayoutListItem.scss'
import { DateTime } from 'luxon'
import { displayPrice } from 'utils'
import { Link } from 'react-router-dom'
import { URL_ACCOUNTING_NEW_PAYOUT, URL_ACCOUNTING_PAYOUT } from 'urls'
import clsx from 'clsx'
import { getAccountingNumberFormatter } from './AccountingConstants'

const PayoutListItem = React.memo(({ payout, active }) => {
  var numberFormatter = getAccountingNumberFormatter()

  const date =
    payout.get('arrivalDate') == null
      ? 'Next payout'
      : DateTime.fromISO(payout.get('arrivalDate')).toFormat('MMM d, yyyy')
  const amount =
    payout.get('amount') != null &&
    displayPrice(
      numberFormatter.format(payout.get('amount') / 100),
      payout.get('currency')
    )

  const renderStatus = () => {
    switch (payout.get('status')) {
      case 'paid':
        return (
          <span className="payout-list-item-header-status-tag payout-list-item-header-status-tag-paid">
            Paid
          </span>
        )

      case 'in_transit':
        return (
          <span className="payout-list-item-header-status-tag payout-list-item-header-status-tag-pending">
            In Transit
          </span>
        )

      default:
        return (
          <span className="payout-list-item-header-status-tag payout-list-item-header-status-tag-pending">
            Pending
          </span>
        )
    }
  }

  return (
    <Link
      className={clsx('PayoutListItem', { active })}
      to={
        payout.get('newInvoice')
          ? URL_ACCOUNTING_NEW_PAYOUT(payout.get('id'))
          : URL_ACCOUNTING_PAYOUT(payout.get('id'))
      }
    >
      <div className="payout-list-item-header">
        <div className="payout-list-item-header-date">{date}</div>
        <div className="payout-list-item-header-status">{renderStatus()}</div>
        <div className="payout-list-item-header-amount">{amount}</div>
      </div>
    </Link>
  )
})

export default PayoutListItem
