import React, { useCallback, useState } from 'react'
import './PostComment.scss'
import PostCommentBody from './PostCommentBody'
import PostCommentReply from './PostCommentReply'

const PostComment = React.memo(
  ({
    postId,
    hostId,
    comment,
    dispatchPosts,
    requireAccess,
    unpackReplyId = false,
  }) => {
    const [viewAll, setViewAll] = useState(
      () =>
        unpackReplyId &&
        comment?.get('replies')?.some((r) => r?.get('id') === unpackReplyId)
    )
    const replies = comment.get('replies')
    const repliesCount = replies.size

    const renderReplies = () => {
      if (repliesCount === 0) {
        return null
      }

      const replyComponents = (viewAll
        ? replies
        : replies.take(1)
      ).map((reply) => (
        <PostCommentReply
          key={reply.get('id')}
          postId={postId}
          hostId={hostId}
          reply={reply}
          onReplyClick={onReplyClick}
          dispatchPosts={dispatchPosts}
          requireAccess={requireAccess}
        />
      ))

      return <div className="post-comment-replies">{replyComponents}</div>
    }

    const onReplyClick = useCallback(() => {
      if (requireAccess()) {
        setViewAll(true)
      }
    }, [requireAccess, setViewAll])

    return (
      <div className="PostComment" id={`comment-${comment.get('id')}`}>
        <PostCommentBody
          postId={postId}
          hostId={hostId}
          comment={comment}
          compose={viewAll}
          onReplyClick={onReplyClick}
          dispatchPosts={dispatchPosts}
          requireAccess={requireAccess}
        >
          {repliesCount > 1 && !viewAll && (
            <div className="post-comment-view-all" onClick={onReplyClick}>
              View {repliesCount - 1} more{' '}
              {repliesCount - 1 === 1 ? 'reply' : 'replies'}
            </div>
          )}
          {renderReplies()}
        </PostCommentBody>
      </div>
    )
  }
)

export default PostComment
