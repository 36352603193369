import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import 'components/InsightsGenericFeedbackCard/InsightsGenericFeedbackCard.scss'
import Avatar from 'components/Avatar/Avatar'
import { DateTime } from 'luxon'
import { asyncModal } from 'utils'
import NamedAnimatedModal from 'components/NamedAnimatedModal'
import clsx from 'clsx'
import { ChevronDownSvg } from 'assets/svg'

const InsightsGenericFeedbackCard = ({
  modalTitle,
  user,
  date,
  headline,
  headlineLabel,
  headlineLink = null,
  onNameClick = null,
  children,
}) => {
  const history = useHistory()

  const openModal = useCallback(() => {
    asyncModal(({ active, onSubmit }) => (
      <NamedAnimatedModal
        className="InsightsGenericFeedbacModal"
        title={modalTitle}
        active={active}
        onClose={onSubmit}
      >
        <InsightsGenericFeedbackContent
          user={user}
          date={date}
          headline={headline}
          headlineLabel={headlineLabel}
          headlineLink={headlineLink}
          onNameClick={onNameClick}
          history={history}
          onClose={onSubmit}
        >
          {children}
        </InsightsGenericFeedbackContent>
      </NamedAnimatedModal>
    ))
  }, [
    modalTitle,
    user,
    date,
    headline,
    headlineLabel,
    headlineLink,
    onNameClick,
    history,
    children,
  ])

  return (
    <div className="InsightsGenericFeedbackCard" onClick={openModal}>
      <InsightsGenericFeedbackContent
        user={user}
        date={date}
        headline={headline}
        headlineLabel={headlineLabel}
        headlineLink={headlineLink}
        history={history}
        onNameClick={onNameClick}
      >
        {children}
      </InsightsGenericFeedbackContent>
    </div>
  )
}

export default InsightsGenericFeedbackCard

const InsightsGenericFeedbackContent = ({
  user,
  date,
  headline,
  headlineLabel,
  headlineLink = null,
  onNameClick = null,
  history = null,
  onClose = null,
  children,
}) => {
  const handleOnClick = (event) => {
    if (headlineLink) {
      onClose && onClose()
      event.preventDefault()
      event.stopPropagation()
      history && history.push(headlineLink)
    }
  }

  return (
    <div className="InsightsGenericFeedbackContent">
      <div className="insights-feedback-content-date">
        {DateTime.fromISO(date).toRelative()}
      </div>
      <div className="insights-feedback-content-header">
        <Avatar
          url={user.get('profileImageUrl')}
          userId={user.get('id')}
          className="insights-feedback-content-avatar"
        />
        <div className="insights-feedback-content-right">
          <div
            className={clsx('insights-feedback-content-name', {
              link: onNameClick !== null,
            })}
            onClick={onNameClick}
          >
            {user.get('name')}
            <ChevronDownSvg />
          </div>
        </div>
      </div>
      <div className="insights-feedback-content-headline-label">
        {headlineLabel}
      </div>
      <div
        className={clsx('insights-feedback-content-headline', {
          link: headlineLink !== null,
        })}
        onClick={handleOnClick}
      >
        {headline}
        {headlineLink !== null && <ChevronDownSvg />}
      </div>
      <div className="insights-feedback-content-body">{children}</div>
    </div>
  )
}
