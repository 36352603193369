import React, { useCallback, useState } from 'react'
import './ProductCard.scss'
import {
  asyncModal,
  displayPrice,
  getIsUserAdmin,
  getSubscribePlanUrl,
  getApiError,
} from 'utils'
import { Link, useHistory } from 'react-router-dom'
import { requireAuth } from 'store/utils/awaitStore'
import { AUTH_MODAL_STATE } from 'store/constants'
import clsx from 'clsx'
import { useSelector } from 'react-redux'
import {
  selectIsSubscribedProduct,
  selectProductSubscription,
} from 'store/selectors'
import OptionsMenu from 'components/OptionsMenu'
import { URL_CREATOR_SETTINGS_MEMBERSHIP, URL_MANAGE_SUBSCRIPTIONS } from 'urls'
import CancelSubscriptionModal from 'modals/CancelSubscriptionModal'
import { useToasts } from 'react-toast-notifications'
import { getBillingName } from 'components/ProductCard/planUtils'
import LimitedReactMarkdown from 'components/LimitedReactMarkdown'
import { moveProductApi, deleteProductApi } from 'api'
import { Image } from 'components/Image'
import { RadioCheckedSvg, RadioUncheckedSvg } from 'assets/svg'

const ProductCard = ({
  product,
  productsAmount,
  user,
  refresh,
  onEdit,
  collapsible,
  collapsed,
  onExpand,
}) => {
  const productId = product.get('id')
  const hasMembers = product.get('hasMembers')
  const history = useHistory()
  const [descriptionCollapsed, setDescriptionCollapsed] = useState(true)
  const isSubscribed = useSelector((state) =>
    selectIsSubscribedProduct(productId)(state)
  )
  const subscription = useSelector((state) =>
    selectProductSubscription(productId)(state)
  )
  const isAdmin = getIsUserAdmin(user)

  const onCtaClick = async (planId) => {
    if (isSubscribed) {
      history.push(URL_MANAGE_SUBSCRIPTIONS)
    } else if (
      await requireAuth(
        AUTH_MODAL_STATE.SIGNUP,
        true,
        'Sign up to complete your purchase'
      )
    ) {
      history.push(getSubscribePlanUrl(user, planId, window.location.pathname))
    }
  }

  const plan = product
    .get('plans')
    .find((f) => f.get('active') && f.get('base'))

  if (plan == null) {
    return null
  }

  const extraPlan = product
    .get('plans')
    .sortBy((p) => -p.get('discount'))
    .find((f) => !f.get('base') && f.get('active'))

  const renderCta = () => {
    if (isSubscribed) {
      return (
        <div className="product-card-cta product-card-cta-active">Active</div>
      )
    }

    return (
      <button
        className="button creator-primary product-card-cta"
        onClick={() => onCtaClick(plan.get('id'))}
        disabled={isAdmin}
      >
        Join
      </button>
    )
  }
  const renderDiscountLink = () => {
    if (subscription && subscription.getIn(['plan', 'interval']) === 'year') {
      return (
        <div className="product-card-discount-link">{`You are saving ${extraPlan.get(
          'discount'
        )}% by paying ${getBillingName(extraPlan)}ly`}</div>
      )
    } else if (plan) {
      return (
        <div
          className="product-card-discount-link"
          onClick={() => onCtaClick(extraPlan.get('id'))}
        >
          {`Save up to ${
            Math.round(extraPlan.get('discount') * 10) / 10
          }% – See all plans`}
        </div>
      )
    }
  }

  const renderHeader = () => {
    const Radio = collapsed ? RadioUncheckedSvg : RadioCheckedSvg

    return (
      <div
        className="product-card-header"
        onClick={() => (collapsed ? onExpand(productId) : null)}
      >
        <Image
          className="product-card-image"
          width={56}
          height={56}
          src={product.get('productImageUrl') || user.get('profileImageUrl')}
        />
        <div className="product-card-header-info">
          <div className="product-card-title">{product.get('name')}</div>
          <div className="product-card-price">
            {displayPrice(
              parseFloat(plan.get('amount')) / 100,
              plan.get('currency')
            )}
            <span className="product-card-price-interval">
              /{plan.get('interval')}
            </span>
          </div>
        </div>
        {collapsible && <Radio className="product-card-collapsible-radio" />}
        {(isSubscribed || isAdmin) && (
          <ProductCardOptions
            product={product}
            productsAmount={productsAmount}
            refresh={refresh}
            isSubscribed={isSubscribed}
            isAdmin={isAdmin}
            onEdit={onEdit}
            hasMembers={hasMembers}
          />
        )}
      </div>
    )
  }

  return (
    <div
      className={clsx('ProductCard', {
        collapsible: collapsible,
        collapsed: collapsed,
        'description-collapsed': descriptionCollapsed,
      })}
    >
      <div className="product-card-wrapper">
        {renderHeader()}

        {renderCta()}
        <div className="product-card-description">
          <LimitedReactMarkdown>
            {product.get('description')}
          </LimitedReactMarkdown>
        </div>

        {descriptionCollapsed && (
          <div
            className="product-card-expander"
            onClick={() => setDescriptionCollapsed(false)}
          >
            Show more
          </div>
        )}

        {extraPlan && renderDiscountLink()}
      </div>
    </div>
  )
}

export default ProductCard

const ProductCardOptions = ({
  product,
  productsAmount,
  isSubscribed,
  isAdmin,
  onEdit,
  refresh,
  hasMembers,
}) => {
  const { addToast } = useToasts()
  const onCanceled = useCallback(() => {
    addToast('The membership has been canceled', { appearance: 'success' })
  }, [addToast])

  const productId = product.get('id')
  const [open, setOpen] = useState(false)
  const subscription = useSelector((state) =>
    selectProductSubscription(productId)(state)
  )

  const handleCancel = async () => {
    await asyncModal(({ active, onDismiss }) => (
      <CancelSubscriptionModal
        active={active}
        subscription={subscription}
        onClose={onDismiss}
        onCanceled={onCanceled}
      />
    ))
  }

  const onDelete = async () => {
    deleteProductApi({ id: product.get('id') })
      .then(() => {
        addToast('The membership has been deleted', { appearance: 'success' })
        refresh && refresh()
      })
      .catch((error) => {
        addToast(
          `An error occurred while trying to delete membership: ${
            getApiError(error).message
          }`,
          { appearance: 'error' }
        )
      })
  }

  const ProductMover = () => {
    if (!isAdmin || productsAmount < 2) {
      return ''
    }

    const onMove = async (direction) => {
      await moveProductApi({ id: product.get('id'), direction })
      refresh && refresh()
    }

    return (
      <div className="product-card-menu-move-menu">
        {product.get('order') !== 0 && (
          <div onClick={() => onMove('up')} className="link">
            Move up
          </div>
        )}
        {product.get('order') !== productsAmount - 1 && (
          <div onClick={() => onMove('down')} className="link">
            Move down
          </div>
        )}
      </div>
    )
  }

  return (
    <OptionsMenu className="ProductCardOptions" open={open} setOpen={setOpen}>
      {isSubscribed && subscription && (
        <div className="link" onClick={handleCancel}>
          Cancel
        </div>
      )}
      {isAdmin &&
        (onEdit ? (
          <div
            className="link"
            onClick={() => {
              setOpen(false)
              onEdit(product)
            }}
          >
            Edit
          </div>
        ) : (
          <Link to={URL_CREATOR_SETTINGS_MEMBERSHIP}>Edit</Link>
        ))}
      {isAdmin && !hasMembers && (
        <div className="link" onClick={onDelete}>
          Delete
        </div>
      )}
      {isAdmin && <ProductMover />}
    </OptionsMenu>
  )
}
