import React, { useState } from 'react'
import clsx from 'clsx'
import { downloadBlobApi } from 'api'

const InsightsTableDownloadButton = ({
  downloadUrl,
  fileName = 'data.csv',
}) => {
  const [isDownloading, setIsDownloading] = useState(false)

  const downloadData = async () => {
    setIsDownloading(true)
    downloadBlobApi({ url: downloadUrl }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      setIsDownloading(false)
    })
  }

  return (
    <button
      className={clsx(
        'button secondary insights-table-widget-button insights-table-widget-button-download',
        {
          loading: isDownloading,
        }
      )}
      disabled={isDownloading}
      onClick={() => downloadData()}
    >
      Download CSV
    </button>
  )
}

export default InsightsTableDownloadButton
