import React, { useState, useRef, Suspense } from 'react'
import './ReactionButton.scss'
import { InsertEmojiSvg } from 'assets/svg'
import { useOutsideAlerter } from 'utils'
import clsx from 'clsx'
import Modal from 'components/Modal'
import { useSelector } from 'react-redux'
import { isAuthenticated } from 'store/selectors'
import { requireAuth } from 'store/utils/awaitStore'
export const WrappedEmojiPicker = React.lazy(() =>
  import('./WrappedEmojiPicker')
)

const ReactionButton = ({ onReactionSelected, overlay, big }) => {
  const containerRef = useRef()
  const [active, setActive] = useState(false)
  const hasAuth = useSelector((state) => isAuthenticated(state))
  useOutsideAlerter(containerRef, active && !overlay, () => {
    setActive(false)
  })

  const onOpenClick = (e) => {
    e.stopPropagation()
    if (!hasAuth) {
      return requireAuth()
    }

    setActive(!active)
  }

  const _onReactionSelected = (reaction) => {
    setActive(false)
    onReactionSelected(reaction.native)
  }

  const renderPicker = () => {
    if (!active) {
      return null
    }

    const component = (
      <WrappedEmojiPicker
        emoji="heart_eyes"
        title="Reactions"
        onSelect={_onReactionSelected}
        native
        theme="dark"
      />
    )
    if (overlay) {
      return (
        <Modal
          className="ReactionButtonOverlay"
          overlay
          onClose={() => setActive(false)}
        >
          {component}
        </Modal>
      )
    } else {
      return component
    }
  }

  return (
    <div
      className={clsx('ReactionButton', { overlay, big })}
      ref={containerRef}
      onClick={(e) => e.stopPropagation()}
    >
      <Suspense
        fallback={<button className="reaction-button-button loading" />}
      >
        <button className="reaction-button-button" onClick={onOpenClick}>
          <InsertEmojiSvg className="reaction-button-button-icon" />
        </button>
        {renderPicker()}
      </Suspense>
    </div>
  )
}

export default ReactionButton
