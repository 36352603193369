import React, { useCallback, useEffect, useReducer, useState } from 'react'
import ProfileFeed, {
  postReducer,
  postReducerClear,
  postReducerDeletePost,
  postReducerMerge,
  setPostAttributes,
} from 'components/ProfileFeed'
import Gallery from 'components/Gallery'
import { getApiError, useInfiniteScrollBody } from 'utils'
import { getUserPostsApi } from 'api'
import { Map } from 'immutable'
import { useSelector } from 'react-redux'
import { getMe } from 'store/selectors'
import { useAsync } from 'react-use'
import Spinner from 'components/Spinner'
import { NAVIGATION_TABNAMES } from 'routes/UserPage/components/NavigationTabConstants'
import './PostList.scss'
import FilesGrid from 'components/FilesGrid'

const PostList = ({
  user,
  postType,
  filters,
  dispatchFilter,
  hasAccess,
  onNoneAccessPostClick,
  setPostCount,
}) => {
  const userId = user.get('id')
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)
  const [hasMore, setHasMore] = useState(false)
  const [posts, dispatchPosts] = useReducer(postReducer, Map())
  const [orderPostsBy, setOrderPostsBy] = useState('created_at')
  const [orderPostsByCursor, setOrderPostsByCursor] = useState(null)
  const me = useSelector((state) => getMe(state))
  const meId = me.get('id')
  const [page, bottomRef] = useInfiniteScrollBody({ hasMore })

  const onPostDeleted = useCallback(
    ({ postId }) => {
      dispatchPosts(postReducerDeletePost({ postId }))
    },
    [dispatchPosts]
  )

  const onPostUpdated = useCallback(
    ({ postId, post }) => {
      dispatchPosts(setPostAttributes({ postId, post }))
    },
    [dispatchPosts]
  )

  useEffect(() => {
    const run = async () => {
      // Fetch initial data
      setLoading(true)
      setError(null)
      try {
        const data = await getUserPostsApi({
          userId,
          params: {
            order_by_field: orderPostsBy,
            ...filters,
          },
        })
        setPostCount(data.getIn(['meta', 'total']))
        dispatchPosts(postReducerMerge(data.get('posts')))
        setHasMore(
          data.getIn(['meta', 'count']) === data.getIn(['meta', 'items']) &&
            data.getIn(['meta', 'count']) > 0
        )
        setOrderPostsByCursor(data.getIn(['meta', 'orderByCursor']))
        setLoading(false)
      } catch (e) {
        setError(getApiError(e))
        setLoading(false)

        return null
      }
    }

    dispatchPosts(postReducerClear())
    run()
  }, [meId, userId, filters, postType, setPostCount, orderPostsBy])

  const loadMore = useCallback(async () => {
    setError(null)
    let data
    try {
      data = await getUserPostsApi({
        userId,
        params: {
          order_by_field: orderPostsBy,
          order_by_cursor: orderPostsByCursor,
          ...filters,
        },
      })
    } catch (e) {
      setError(getApiError(e))
      setLoading(false)
      return null
    }

    dispatchPosts(postReducerMerge(data.get('posts')))
    setHasMore(
      data.getIn(['meta', 'count']) === data.getIn(['meta', 'items']) &&
        data.getIn(['meta', 'count']) !== 0
    )
    setOrderPostsByCursor(data.getIn(['meta', 'orderByCursor']))
  }, [userId, orderPostsByCursor, filters, orderPostsBy])

  useAsync(async () => {
    if (page === 0) {
      return null
    }

    loadMore()
  }, [page])

  const renderComponent = () => {
    if (loading) {
      return (
        <div className="post-list-loading">
          <Spinner small />
        </div>
      )
    }
    if (error) {
      return (
        <div className="post-list-error">
          <div className="post-list-error-message">{error?.message}</div>
          <div className="post-list-error-retry">
            <span onClick={loadMore}>Click here to try again</span>.
          </div>
        </div>
      )
    }

    switch (postType) {
      case NAVIGATION_TABNAMES.ACTIVITY:
        return (
          <ProfileFeed
            user={user}
            posts={posts}
            showCompose={true}
            onPostDeleted={onPostDeleted}
            onPostUpdated={onPostUpdated}
            dispatchPosts={dispatchPosts}
            filters={filters}
            dispatchFilter={dispatchFilter}
            orderPostsBy={orderPostsBy}
            setOrderPostsBy={setOrderPostsBy}
          />
        )
      case NAVIGATION_TABNAMES.PHOTOS:
        return (
          <Gallery
            user={user}
            showCompose={true}
            posts={posts}
            onPostDeleted={onPostDeleted}
            onPostUpdated={onPostUpdated}
            dispatchPosts={dispatchPosts}
            onNoneAccessPostClick={onNoneAccessPostClick}
            filters={filters}
            dispatchFilter={dispatchFilter}
          />
        )
      case NAVIGATION_TABNAMES.POLLS:
        return 'Not implemented yet'
      case NAVIGATION_TABNAMES.FILES:
        return (
          <FilesGrid
            user={user}
            posts={posts}
            dispatchPosts={dispatchPosts}
            filters={filters}
            dispatchFilter={dispatchFilter}
            onNoneAccessPostClick={onNoneAccessPostClick}
            me={me}
          />
        )
      case NAVIGATION_TABNAMES.VIDEOS:
        return 'Should not render here'
      default:
        return 'Unknown page'
    }
  }
  return (
    <div className="PostList">
      {renderComponent()}
      <div ref={bottomRef}></div>
    </div>
  )
}
export default PostList
