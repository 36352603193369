import React from 'react'
import { useBrowser } from 'utils'

import './ProfileMenuMobileModal.scss'
import MenuModal from 'components/MenuModal'
import ProfileMenu from 'components/ProfileMenu/ProfileMenu'
import CloseSvg from 'assets/svg/CloseSvg'

const ProfileMenuMobileModal = ({ user, active, onClose, refreshUser }) => {
  const browser = useBrowser()

  return (
    <MenuModal
      className="ProfileMenuMobileModal"
      active={active}
      onClose={onClose}
      direction={browser.greaterThan.md ? 'left' : 'bottom full-screen'}
    >
      <ProfileMenu
        user={user}
        refreshUser={refreshUser}
        onClick={() => onClose()}
      />
      <div className="profile-menu-mobile-modal-close" onClick={onClose}>
        <CloseSvg className="profile-menu-mobile-modal-close-icon" />
      </div>
    </MenuModal>
  )
}

export default ProfileMenuMobileModal
