import React, { useState } from 'react'
import './InsightsTableWidget.scss'

import { useSelector } from 'react-redux'

import InsightsGenericWidget from 'components/InsightsGenericWidget/InsightsGenericWidget'
import InsightsTablePagination from 'components/InsightsTableWidget/InsightsTablePagination'
import InsightsTableHeaders from 'components/InsightsTableWidget/InsightsTableHeaders'
import InsightsTableData from 'components/InsightsTableWidget/InsightsTableData'
import InsightsTableMeta from 'components/InsightsTableWidget/InsightsTableMeta'
import InsightsTableSearch from 'components/InsightsTableWidget/InsightsTableSearch'
import InsightsTableFilters from 'components/InsightsTableWidget/InsightsTableFilters'
import InsightsTableColumnSelector from 'components/InsightsTableWidget/InsightsTableColumnSelector'
import InsightsTableClearFilter from 'components/InsightsTableWidget/InsightsTableClearFilter'
import InsightsTableMobileData from 'components/InsightsTableWidget/InsightsTableMobileData'

import Spinner from 'components/Spinner'
import { ErrorOutlineSvg } from 'assets/svg'
import InsightsTableDownloadButton from 'components/InsightsTableWidget/InsightsTableDownloadButton'

const InsightsTableWidget = ({
  data,
  headers: allHeaders,
  error,
  loading,
  searching,
  onSearch,
  title,
  filters,
  setFilters,
  timeScale = '',
  sortBy = null,
  changeSortBy = null,
  page = null,
  totalPages = null,
  totalResults = null,
  changePage = null,
  clickableRow = null,
  optionsMenu = null,
  csvUrl,
}) => {
  const browser = useSelector((state) => state.get('browser'))
  if (!browser.greaterThan.md && allHeaders) {
    allHeaders = allHeaders.map((header, idx) => {
      return { ...header, active: [0, 1].includes(idx) }
    })
  }
  const [headers, setHeaders] = useState(allHeaders)
  const [searchTerm, setSearchTerm] = useState('')

  const clearFilters = () => {
    setFilters &&
      setFilters(
        filters.slice().map((filter) => {
          return { ...filter, active: false }
        })
      )
    onSearch && onSearch(null)
    setSearchTerm('')
  }

  const renderContent = () => {
    if (error) {
      return (
        <div className="insights-table-widget-error">
          <ErrorOutlineSvg className="insights-table-widget-error-icon" />
          <div>Loading failed</div>
          <div>Please try again later</div>
        </div>
      )
    } else if (loading && !data && !headers) {
      return <Spinner className="insights-table-widget-loading" light />
    } else {
      const ClearFilter = ({ title }) => {
        return (
          <InsightsTableClearFilter
            title={title}
            hasActiveFilter={
              (filters && filters.filter((f) => f.active).length !== 0) ||
              searchTerm !== ''
            }
            clearFilters={clearFilters}
          />
        )
      }

      if (browser.greaterThan.md) {
        return (
          <>
            <div className="insights-table-widget-controls">
              <InsightsTableSearch
                setSearchTerm={setSearchTerm}
                searchTerm={searchTerm}
                searching={searching}
                onSearch={onSearch}
              />
              <InsightsTableFilters
                headers={headers}
                setHeaders={setHeaders}
                filters={filters}
                setFilters={setFilters}
              />
              <InsightsTableDownloadButton
                downloadUrl={csvUrl}
                fileName={`${title.toLowerCase()}.csv`}
              />
            </div>

            <div className="insights-table-widget-meta-filters">
              <InsightsTableMeta
                loading={loading}
                page={page}
                totalPages={totalPages}
                totalResults={totalResults}
                results={data && data.length}
              />
              <ClearFilter />
            </div>
            <InsightsTableColumnSelector
              headers={headers}
              setHeaders={setHeaders}
            />
            <table className="insights-table">
              <InsightsTableHeaders
                loading={loading}
                headers={headers}
                changeSortBy={changeSortBy}
                sortBy={sortBy}
                optionsMenu={optionsMenu}
              />
              <InsightsTableData
                loading={loading}
                headers={headers}
                data={data}
                clickableRow={clickableRow}
                optionsMenu={optionsMenu}
              />
            </table>
            <InsightsTablePagination
              changePage={changePage}
              page={page}
              totalPages={totalPages}
            />
          </>
        )
      } else {
        return (
          <>
            <InsightsTableFilters filters={filters} setFilters={setFilters} />
            <InsightsTableSearch
              setSearchTerm={setSearchTerm}
              searchTerm={searchTerm}
              searching={searching}
              onSearch={onSearch}
            />
            <div className="insights-table-widget-meta-filters">
              <InsightsTableMeta
                loading={loading}
                page={page}
                totalPages={totalPages}
                totalResults={totalResults}
                results={data && data.length}
              />
              <ClearFilter />
            </div>
            <InsightsTableColumnSelector
              headers={headers}
              setHeaders={setHeaders}
            />
            <InsightsTableMobileData
              loading={loading}
              headers={headers}
              data={data}
              clickableRow={clickableRow}
            />
            <InsightsTablePagination
              changePage={changePage}
              page={page}
              totalPages={totalPages}
            />
          </>
        )
      }
    }
  }

  return (
    <InsightsGenericWidget title={title} timeScale={timeScale}>
      <div className="InsightsTableWidget">{renderContent()}</div>
    </InsightsGenericWidget>
  )
}

export default InsightsTableWidget
