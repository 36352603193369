import React, { useContext, useMemo, useState } from 'react'
import './InsightsOverviewPage.scss'
import { Col, Row } from 'components/Grid'
import InsightsFilters from 'routes/InsightsPage/InsightsFilters'
import InsightsContext from 'routes/InsightsPage/InsightsContext'
import InsightsConnectedPaginationList from 'components/InsightsGenericFeedbackCard/InsightsConnectedPaginationList'
import InsightsVideoFeedbackCard from 'components/InsightsVideoFeedbackCard'
import { useSelector } from 'react-redux'
import InsightsCancelFeedbackCard from 'components/InsightsCancelFeedbackCard'
import InsightsGenericWidget from 'components/InsightsGenericWidget'
import {
  URL_CONTACT,
  URL_EXTERNAL_FAQ,
  URL_EXTERNAL_REFERRAL_PROGRAM,
  URL_EVENT_INSIGHTS_OVERVIEW,
} from 'urls'
import { ArrowCornerSvg } from 'assets/svg'
import { useMe } from 'utils'
import InsightsConnectedNumberWidget from 'components/InsightsNumberWidget/InsightsConnectedNumberWidget'
import {
  INSIGHTS_PERIODS,
  INSIGHTS_PERIODS_NAMES,
} from 'routes/InsightsPage/insightsConstants'
import {
  currencyPrefixDataMapper,
  genericChartDataMapper,
  usePrefixedChartDataLabelMapper,
} from 'routes/InsightsPage/insightsUtils'
import InsightsConnectedChartWidget from 'components/InsightsChartWidget/InsightsConnectedChartWidget'
import { viewsChartDataMapper } from 'routes/InsightsPage/InsightsVideosPage'
import RelationsMenuModal, {
  RELATION_TYPE,
} from 'routes/InsightsPage/components/RelationsMenuModal'

const InsightsOverviewPage = ({ user, currency, currencySymbol }) => {
  const me = useMe()
  const userId = me.get('id')
  const prefixedChartDataLabelMapper = usePrefixedChartDataLabelMapper(
    currencySymbol
  )
  const [menuModalUserId, setMenuModalUserId] = useState(null)
  const { period, queryParams } = useContext(InsightsContext)
  const browser = useSelector((state) => state.get('browser'))
  const feedbackItemCount = useMemo(() => {
    if (browser.greaterThan.xl) {
      return 6
    } else if (browser.greaterThan.lg) {
      return 4
    } else if (browser.greaterThan.sm) {
      return 2
    } else {
      return 1
    }
  }, [browser])

  return (
    <div className="InsightsOverviewPage">
      <Row className="insights-members-top">
        <Col lg={3} md={6}>
          <InsightsConnectedNumberWidget
            title={'Active members'}
            url={`member_stats/${userId}/active_members`}
            timeScale={'Total'}
          />
        </Col>
        <Col lg={3} md={6}>
          <InsightsConnectedNumberWidget
            title="Income"
            url={`income_stats/${userId}/revenue_total`}
            timeScale={INSIGHTS_PERIODS_NAMES[INSIGHTS_PERIODS.THIRTY_DAYS]}
            prefix={currencyPrefixDataMapper}
          />
        </Col>
        <Col lg={3} md={6}>
          <InsightsConnectedNumberWidget
            title="MRR"
            url={`income_stats/${userId}/mrr`}
            timeScale="Now"
            prefix={currencyPrefixDataMapper}
          />
        </Col>
        <Col lg={3} md={6}>
          <InsightsConnectedNumberWidget
            title="Views"
            timeScale="Last 30 days"
            url="insights/members/total_views"
          />
        </Col>
      </Row>

      <InsightsFilters />

      <Row>
        <Col xs={12}>
          <InsightsConnectedChartWidget
            title="Active Members"
            timeScale={INSIGHTS_PERIODS_NAMES[period]}
            url={`member_stats/${userId}/active_members_timeseries?${queryParams}`}
            dataMapper={genericChartDataMapper}
          />
        </Col>
      </Row>

      <Row>
        <Col lg={4}>
          <InsightsConnectedChartWidget
            title={`Income (${currencySymbol})`}
            timeScale={INSIGHTS_PERIODS_NAMES[period]}
            url={`income_stats/${userId}/revenue_total_timeseries?${queryParams}`}
            dataMapper={genericChartDataMapper}
            labels={prefixedChartDataLabelMapper}
            chartType={'bar'}
          />
        </Col>
        <Col lg={4}>
          <InsightsConnectedChartWidget
            title={`MRR (${currencySymbol})`}
            timeScale={INSIGHTS_PERIODS_NAMES[period]}
            url={`income_stats/${userId}/mrr_timeseries?${queryParams}`}
            dataMapper={genericChartDataMapper}
            labels={prefixedChartDataLabelMapper}
          />
        </Col>
        <Col lg={4}>
          <InsightsConnectedChartWidget
            title="Views"
            timeScale={INSIGHTS_PERIODS_NAMES[period]}
            url={`insights/members/total_views_histogram?${queryParams}`}
            dataMapper={viewsChartDataMapper}
            chartType={'bar'}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <InsightsConnectedPaginationList
            title="Video Feedback"
            baseUrl="profile/feedback_items"
            dataKey="feedbackItems"
            items={feedbackItemCount}
            renderContent={(feedbackItem) => (
              <Col xs={12} sm={6} lg={3} xl={2} key={feedbackItem.get('id')}>
                <InsightsVideoFeedbackCard
                  headline={feedbackItem.getIn(['event', 'title'])}
                  headlineLink={URL_EVENT_INSIGHTS_OVERVIEW(
                    userId,
                    feedbackItem.getIn(['event', 'id'])
                  )}
                  feedback={feedbackItem.get('description')}
                  rating={feedbackItem.get('rating')}
                  user={feedbackItem.get('user')}
                  date={feedbackItem.get('createdAt')}
                  onNameClick={(event) => {
                    event.preventDefault()
                    event.stopPropagation()
                    setMenuModalUserId(feedbackItem.getIn(['user', 'id']))
                  }}
                />
              </Col>
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <InsightsConnectedPaginationList
            title="Cancel Feedback"
            baseUrl="subscription_feedbacks"
            dataKey="subscriptionFeedbacks"
            items={feedbackItemCount}
            renderContent={(feedbackItem) => (
              <Col xs={12} sm={6} lg={3} xl={2} key={feedbackItem.get('id')}>
                <InsightsCancelFeedbackCard
                  headline={feedbackItem.get('productName')}
                  cancelFeedback={feedbackItem.get('otherText')}
                  cancelReasons={feedbackItem.get('cancelReasons')}
                  user={feedbackItem.get('user')}
                  date={feedbackItem.get('createdAt')}
                  onNameClick={(event) => {
                    event.preventDefault()
                    event.stopPropagation()
                    setMenuModalUserId(feedbackItem.getIn(['user', 'id']))
                  }}
                />
              </Col>
            )}
          />
        </Col>
      </Row>
      <Row className="insights-footer">
        <Col lg={4}>
          <InsightsGenericWidget title="Referral Program">
            <p>
              Invite fellow Creators and earn 5% of the revenue they generate
              for one year.
            </p>
            <p>
              YOUR INVITE CODE: <strong>{me.get('referralCode')}</strong>
            </p>
            <div className="insights-footer-links">
              <a
                href={URL_EXTERNAL_REFERRAL_PROGRAM}
                target="_blank"
                rel="noopener noreferrer"
              >
                <ArrowCornerSvg className="insights-footer-icons" />
                Learn More
              </a>
            </div>
          </InsightsGenericWidget>
        </Col>
        <Col lg={4}>
          <InsightsGenericWidget title="Your Success is our Success!">
            <p>
              Do you have any questions or need some good advice? We're here to
              help.
            </p>

            <div className="insights-footer-links">
              <a
                href={URL_EXTERNAL_FAQ}
                target="_blank"
                rel="noopener noreferrer"
              >
                <ArrowCornerSvg className="insights-footer-icons" />
                Frequently Asked Questions
              </a>
              <br />
              <a href={URL_CONTACT} target="_blank" rel="noopener noreferrer">
                <ArrowCornerSvg className="insights-footer-icons" />
                Contact our Team
              </a>
            </div>
          </InsightsGenericWidget>
        </Col>
      </Row>

      <RelationsMenuModal
        hostId={userId}
        userId={menuModalUserId}
        onClose={() => setMenuModalUserId(null)}
        type={RELATION_TYPE.RELATION}
      />
    </div>
  )
}

export default InsightsOverviewPage
