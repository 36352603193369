import React from 'react'
import { connect as formikConnect, getIn } from 'formik'
import { capitalize } from 'utils'

const StyledApiErrorMessage = ({ formik: formikProps }) => {
  const error =
    getIn(formikProps.errors, 'message') || getIn(formikProps.errors, 'error')

  if (error != null) {
    if (typeof error === 'string') {
      return <div className="input-error">{error}</div>
    } else {
      return (
        <div className="input-error">
          <ul>
            {Object.entries(error).map(([key, value], idx) => {
              return (
                <li key={idx}>
                  {capitalize(key)} {value}
                </li>
              )
            })}
          </ul>
        </div>
      )
    }
  }

  return null
}

export default formikConnect(StyledApiErrorMessage)
