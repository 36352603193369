import React from 'react'
import clsx from 'clsx'

import './InsightsGenericWidget.scss'

const InsightsGenericWidget = ({ title, timeScale, className, children }) => {
  return (
    <div className={clsx('InsightsGenericWidget', className)}>
      <div className="insights-generic-widget-header">
        <div className="insights-generic-widget-header-title">{title}</div>
        <div className="insights-generic-widget-header-timescale">
          {timeScale}
        </div>
      </div>
      {children}
    </div>
  )
}

export default InsightsGenericWidget
