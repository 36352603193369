import React from 'react'
import './EventButton.scss'
import EventButtonSoldOut from 'components/EventButton/EventButtonSoldOut'
import EventButtonAttending from 'components/EventButton/EventButtonAttending'
import EventButtonPurchaseable from 'components/EventButton/EventButtonPurschaseable'
import EventButtonUnavailable from 'components/EventButton/EventButtonUnavailable'
import EventButtonFreePurchaseable from 'components/EventButton/EventButtonFreePurchaseable'
import EventButtonFreePurchaseableLive from 'components/EventButton/EventButtonFreePurchaseableLive'
import EventButtonArchived from 'components/EventButton/EventButtonArchived'

export const EVENT_BUTTON_TYPE = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
}

const EventButton = ({ event, className, type, refreshEvent }) => {
  const renderButton = () => {
    switch (event.get('attendingState')) {
      case 'soldOut':
        return <EventButtonSoldOut className={className} />
      case 'attending':
        return (
          <EventButtonAttending
            event={event}
            className={className}
            type={type}
          />
        )
      case 'purchaseable':
        return event.get('isFree') === true ? (
          <EventButtonFreePurchaseable
            event={event}
            className={className}
            type={type}
            refreshEvent={refreshEvent}
          />
        ) : (
          <EventButtonPurchaseable
            event={event}
            className={className}
            type={type}
          />
        )
      case 'purchaseableLive':
        return event.get('isFree') === true ? (
          <EventButtonFreePurchaseableLive
            event={event}
            className={className}
            type={type}
            refreshEvent={refreshEvent}
          />
        ) : (
          <EventButtonPurchaseable
            event={event}
            className={className}
            type={type}
          />
        )
      case 'notOnDemand':
        return <EventButtonUnavailable className={className} />
      case 'access':
        return ''
      case 'archived':
        return <EventButtonArchived className={className} type={type} />
      default:
        return ''
    }
  }

  return <div className="EventButton">{renderButton()}</div>
}

export default EventButton
