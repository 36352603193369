import React, { useCallback } from 'react'
import './PostCardAttachmentEvent.scss'
import { getEvent } from 'api'
import EventCard from 'components/EventCard'

const PostCardAttachmentEvent = ({ event, onPostAttachmentUpdate }) => {
  const eventId = event.get('id')
  const refreshEvent = useCallback(async () => {
    const newEvent = await getEvent({ id: eventId })
    onPostAttachmentUpdate(newEvent)
  }, [eventId, onPostAttachmentUpdate])

  return (
    <div className="PostCardAttachmentEvent">
      <EventCard event={event} refreshEvent={refreshEvent} />
    </div>
  )
}

export default PostCardAttachmentEvent
