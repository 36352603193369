import React, { useState } from 'react'
import { followUser, unFollowUser } from 'api'
import { AUTH_MODAL_STATE } from 'store/constants'
import { requireAuth } from 'store/utils/awaitStore'
import { useSelector, useDispatch } from 'react-redux'
import { getIsFollowing, getMe } from 'store/selectors'
import { unFollowUserStore, followUserStore } from 'store/actions'
import clsx from 'clsx'
import { CheckSvg, AddAlertSvg, CancelSvg } from 'assets/svg'
import './FollowButton.scss'

const FollowButton = ({ user, className }) => {
  const dispatch = useDispatch()
  const me = useSelector((state) => getMe(state))
  const isFollowing = useSelector((state) =>
    getIsFollowing(user.get('id'))(state)
  )
  const [hoverFollowing, setHoverFollowing] = useState(false)

  if (me?.get('id') === user.get('id')) {
    return null
  }

  const handleFollow = async (e) => {
    e.preventDefault()
    if (!(await requireAuth(AUTH_MODAL_STATE.SIGNUP, true))) {
      return null
    }

    await followUser({ userId: user.get('id') })
    dispatch(followUserStore(user))
  }
  const handleUnfollow = async (e) => {
    e.preventDefault()
    if (!(await requireAuth(AUTH_MODAL_STATE.SIGNUP, true))) {
      return null
    }

    await unFollowUser({ userId: user.get('id') })
    dispatch(unFollowUserStore(user))
  }

  if (isFollowing) {
    return (
      <button
        onMouseOver={() => setHoverFollowing(true)}
        onMouseOut={() => setHoverFollowing(false)}
        onClick={handleUnfollow}
        className={clsx('FollowButton button secondary following', className, {
          unfollow: hoverFollowing,
        })}
      >
        {hoverFollowing ? (
          <>
            <CancelSvg /> Unfollow
          </>
        ) : (
          <>
            <CheckSvg /> Following
          </>
        )}
      </button>
    )
  }
  return (
    <button
      onClick={handleFollow}
      className={clsx('FollowButton button secondary', className)}
    >
      <AddAlertSvg /> Follow
    </button>
  )
}

export default FollowButton
