import React from 'react'
import './CouponAppliedBanner.scss'
import { useRetrieveCouponCode } from 'utils'

const CouponAppliedBanner = ({ userSlug }) => {
  const { couponCode, removeCouponCode } = useRetrieveCouponCode(userSlug)

  if (couponCode == null) {
    return null
  }

  return (
    <div className="CouponAppliedBanner">
      <b>{couponCode}</b>&nbsp;–&nbsp;Promotion applied at checkout
      <span onClick={removeCouponCode} className="coupon-applied-banner-remove">
        (Remove promotion)
      </span>
    </div>
  )
}

export default CouponAppliedBanner
