import React, { useEffect, useState } from 'react'
import { useGetPlanCheckout, useGetPlanCheckoutApi } from 'api'
import { isAuthenticated } from 'store/selectors'
import { useSelector } from 'react-redux'
import Spinner from 'components/Spinner'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { getApiError, useGetInviteCode, useRetrieveCouponCode } from 'utils'
import LoadingError from 'components/LoadingError'
import SubscribePageHeader from 'routes/SubscribePage/SubscribePageHeader'
import './SubscribePage.scss'
import SubscribePagePlanSelector from 'routes/SubscribePage/SubscribePagePlanSelector'
import SubscribePageSummary from 'routes/SubscribePage/SubscribePageSummary'
import SubscribePageActions from 'routes/SubscribePage/SubscribePageActions'
import { URL_USER } from 'urls'
import queryString from 'query-string'

const SubscribePage = () => {
  const {
    params: { userSlug, planId },
  } = useRouteMatch()
  const {
    couponCode: currentCouponCode,
    removeCouponCode,
  } = useRetrieveCouponCode(userSlug)
  const location = useLocation()
  const { redirect_url: redirectUrl } = queryString.parse(location.search)
  const [couponCode] = useState(currentCouponCode)
  const { inviteCode } = useGetInviteCode(userSlug)
  const history = useHistory()
  const hasAuth = useSelector((state) => isAuthenticated(state))
  const [newCheckout, setNewCheckout] = useState()
  const [newCode, setNewCode] = useState('')

  const {
    data: checkout,
    error: checkoutError,
    refresh: checkoutRefresh,
  } = useGetPlanCheckoutApi({
    planId,
    disabled: planId == null,
    data: {
      couponCode,
      inviteCode: inviteCode && inviteCode.get('code'),
    },
  })

  useEffect(() => {
    if (!hasAuth && userSlug != null) {
      history.replace(URL_USER(userSlug))
    }
  }, [userSlug, hasAuth, history])

  useEffect(() => {
    if (
      checkout != null &&
      couponCode != null &&
      checkout.get('couponError') != null
    ) {
      removeCouponCode()
    }
  }, [checkout, couponCode, removeCouponCode])

  if (checkoutError) {
    if (
      checkoutError?.response?.status === 422 ||
      checkoutError?.response?.status === 418
    ) {
      history.push(URL_USER(userSlug))
      alert(checkoutError?.response?.data?.message)
    }

    return <LoadingError refresh={checkoutRefresh} />
  }

  if (checkout == null) {
    return (
      <div className="SubscribePage">
        <Spinner />
      </div>
    )
  }

  const OnSubmit = async (values, { setSubmitting, setErrors }) => {
    setSubmitting(true)
    try {
      const checkoutWithCoupon = await useGetPlanCheckout({
        planId,
        data: {
          couponCode: values.newCode,
        },
        disabled: planId == null,
      })
      setNewCheckout(checkoutWithCoupon)
      setNewCode(values.newCode)
    } catch (e) {
      setErrors(getApiError(e))
    }
    setSubmitting(false)
  }

  const OnDeleteCoupon = async () => {
    try {
      const checkoutWithCoupon = await useGetPlanCheckout({
        planId,
        disabled: planId == null,
      })
      setNewCheckout(checkoutWithCoupon)
      setNewCode('')
    } catch (e) {
      alert(getApiError(e))
    }
  }

  return (
    <div className="SubscribePage">
      <div className="subscribe-page-container">
        <SubscribePageHeader checkout={newCheckout ? newCheckout : checkout} />
        <SubscribePagePlanSelector
          checkout={newCheckout ? newCheckout : checkout}
          redirectUrl={redirectUrl}
        />
        <SubscribePageSummary
          checkout={newCheckout ? newCheckout : checkout}
          couponCode={newCode ? newCode : couponCode}
          onDelete={OnDeleteCoupon}
          onSubmit={OnSubmit}
        />
        <SubscribePageActions
          checkout={newCheckout ? newCheckout : checkout}
          couponCode={newCode ? newCode : couponCode}
          removeCouponCode={removeCouponCode}
          inviteCode={inviteCode && inviteCode.get('code')}
          redirectUrl={redirectUrl}
          userSlug={userSlug}
        />
      </div>
    </div>
  )
}

export default SubscribePage
