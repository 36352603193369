import React, { useState, useEffect, useRef } from 'react'
import './Giffer.scss'
import { useMount } from 'react-use'
import clsx from 'clsx'

const Giffer = ({ src, playing }) => {
  return (
    <div
      className={clsx('Giffer', { playing })}
      style={{ backgroundImage: playing && `url(${src})` }}
    ></div>
  )
}

export default Giffer

export const useGiffer = ({ autoplay, src }) => {
  const [playing, setPlaying] = useState(false)
  const timeout = useRef()

  useMount(() => {
    if (autoplay) {
      const gif = new Image()
      gif.onload = () => {
        setPlaying(true)
      }
      gif.src = src
    }
  })

  useEffect(() => {
    clearTimeout(timeout.current)
    if (playing) {
      timeout.current = setTimeout(() => {
        setPlaying(false)
      }, 3000)
    }
  }, [playing])

  const onMouseEnter = () => setPlaying(true)
  const onMouseLeave = () => setPlaying(false)

  return {
    onMouseEnter,
    onMouseLeave,
    playing,
  }
}
