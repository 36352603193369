import React, { useEffect, useState } from 'react'
import './SubscribePagePlanSelectorItem.scss'
import { displayPrice } from 'utils'
import Avatar from 'components/Avatar/Avatar'
import clsx from 'clsx'
import Spinner from 'components/Spinner'

const SubscribePagePlanSelectorItem = ({
  active,
  onClick,
  plan,
  inviteCode,
}) => {
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (active) {
      setLoading(false)
    }
  }, [active, setLoading])

  const changePlan = () => {
    if (!active) {
      setLoading(true)
      onClick()
    }
  }

  const formatPrice = (price) => {
    return price / 100
  }

  const renderBasePrice = (plan) => {
    if (plan.get('base')) {
      return (
        displayPrice(formatPrice(plan.get('amount')), plan.get('currency')) +
        ' per month'
      )
    }

    return (
      displayPrice(
        formatPrice(plan.get('monthlyPrice')),
        plan.get('currency')
      ) + ' per month'
    )
  }

  const renderLoyaltyPrice = (plan) => {
    return `${plan.get('lockInMonths')} months - ${displayPrice(
      formatPrice(plan.get('monthlyPrice')),
      plan.get('currency')
    )} per month`
  }

  const renderInviteCode = () => {
    if (!inviteCode) {
      return null
    }
    return (
      <div className="subscribe-page-plan-selector-item-invite-code">
        <Avatar
          url={inviteCode.getIn(['user', 'profileImageUrl'])}
          userId={inviteCode.getIn(['user', 'id'])}
          className="subscribe-page-plan-selector-item-invite-code-avatar"
        />
        50% off your first month
      </div>
    )
  }

  return (
    <div
      className={clsx('SubscribePagePlanSelectorItem', {
        active,
      })}
      onClick={changePlan}
    >
      <div>{loading && <Spinner tiny />}</div>
      <div className="subscribe-page-plan-selector-item-header">
        <div className="subscribe-page-plan-selector-item-name">
          {plan.get('base') || plan.get('billingName') !== 'monthly'
            ? `${
                plan.get('billingName').charAt(0).toUpperCase() +
                plan.get('billingName').slice(1)
              } plan`
            : `Loyalty plan`}
        </div>
        <div className="subscribe-page-plan-selector-item-discount">
          {plan.get('discount') > 0 &&
            `Save ${Math.round(plan.get('discount') * 10) / 10}%`}
        </div>
      </div>
      <div className="subscribe-page-plan-selector-item-amount">
        {plan.get('billingName') !== 'monthly'
          ? renderBasePrice(plan)
          : plan.get('base')
          ? renderBasePrice(plan)
          : renderLoyaltyPrice(plan)}
      </div>
      {renderInviteCode()}
    </div>
  )
}

export default SubscribePagePlanSelectorItem
