import React from 'react'
import Modal from 'components/Modal'
import './MenuModal.scss'
import clsx from 'clsx'
import { CSSTransition } from 'react-transition-group'
import { useLockBodyScroll, useUnmount } from 'react-use'

const MenuModal = ({
  onClose,
  className,
  children,
  active,
  direction = 'right',
}) => {
  useLockBodyScroll(active)

  useUnmount(() => {
    document.body.style.overflow = null
  })

  return (
    <CSSTransition
      in={active}
      timeout={250}
      appear={true}
      mountOnEnter
      unmountOnExit
      classNames="MenuModal"
    >
      <Modal
        onClose={onClose}
        overlay
        className={clsx('MenuModal', className, direction)}
      >
        <div className="menu-modal-content">{children}</div>
      </Modal>
    </CSSTransition>
  )
}

export default MenuModal
