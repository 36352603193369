import React from 'react'
import './PostCardAttachmentHeader.scss'
import {
  POST_ATTACHMENT_ACCESS,
  POST_ATTACHMENT_TYPES,
} from 'store/constants/post'
import PostCardLock from 'components/PostCard/PostCardLock'
import { AttachFileSvg, InsertPhotoSvg } from 'assets/svg'
import clsx from 'clsx'

const PostCardAttachmentHeader = ({ post, onNoAccess, dark }) => {
  const attachment = post.get('attachment')
  const attachmentType = post.get('attachmentType')
  const attachmentAccess = post.get('attachmentAccess')
  if (
    attachment == null ||
    attachmentType == null ||
    attachmentType === POST_ATTACHMENT_TYPES.EVENT ||
    attachmentType === POST_ATTACHMENT_TYPES.POLL
  ) {
    return null
  }

  const hasAccess = attachmentAccess !== POST_ATTACHMENT_ACCESS.NONE

  const renderAttachmentType = () => {
    switch (attachmentType) {
      case POST_ATTACHMENT_TYPES.PHOTO:
        return (
          <div className="post-card-attachment-header-type">
            <InsertPhotoSvg className="post-card-attachment-header-type-icon" />
            <div className="post-card-attachment-header-type-name">Photo</div>
          </div>
        )
      case POST_ATTACHMENT_TYPES.DOCUMENT:
        return (
          <div className="post-card-attachment-header-type">
            <AttachFileSvg className="post-card-attachment-header-type-icon" />
            <div className="post-card-attachment-header-type-name">File</div>
          </div>
        )
      default:
        return null
    }
  }

  return (
    <div className={clsx('PostCardAttachmentHeader', { dark })}>
      {renderAttachmentType()}
      <div className="post-card-attachment-header-right">
        {hasAccess && (
          <PostCardLock post={post} onNoAccess={onNoAccess} dark={dark} />
        )}
      </div>
    </div>
  )
}

export default PostCardAttachmentHeader
