import React, { useEffect } from 'react'
import './SettingsPage.scss'
import { useHistory, useRouteMatch } from 'react-router-dom'
import SettingsHeader from './SettingsHeader'
import { Col, NarrowContainer, Row } from 'components/Grid'
import {
  URL_CREATOR_SETTINGS_PAYOUT,
  URL_CREATOR_SETTINGS_PROFILE,
  URL_FRONT_PAGE,
  URL_SETTINGS_ACCOUNT,
  URL_SETTINGS_BUSINESS_LEGACY,
  URL_SETTINGS_NOTIFICATIONS,
  URL_SETTINGS_PROFILE_LEGACY,
} from 'urls'
import { useSelector } from 'react-redux'
import { isAuthenticated } from 'store/selectors'
import SettingsAccount from './SettingsAccount'
import SettingsNotifications from './SettingsNotifications'

const SettingsPage = () => {
  const { path } = useRouteMatch()
  const history = useHistory()
  const hasAuth = useSelector((state) => isAuthenticated(state))

  useEffect(() => {
    if (!hasAuth) {
      history.push(URL_FRONT_PAGE)
    }
  }, [history, hasAuth])

  const renderPage = () => {
    switch (path) {
      case URL_SETTINGS_PROFILE_LEGACY:
        history.replace(URL_CREATOR_SETTINGS_PROFILE)
        return null
      case URL_SETTINGS_BUSINESS_LEGACY:
        history.replace(URL_CREATOR_SETTINGS_PAYOUT)
        return null
      case URL_SETTINGS_ACCOUNT:
        return <SettingsAccount />
      case URL_SETTINGS_NOTIFICATIONS:
        return <SettingsNotifications />
      default:
        history.replace(URL_SETTINGS_ACCOUNT)
        return null
    }
  }

  return (
    <div className="SettingsPage">
      <NarrowContainer>
        <Row className="justify-content-center">
          <Col xs={12} xl={8} className="settings-page-col">
            <div className="settings-page-title">Profile Settings</div>
            <SettingsHeader />
            {renderPage()}
          </Col>
        </Row>
      </NarrowContainer>
    </div>
  )
}

export default SettingsPage
