import React from 'react'
import './LocalHeader.scss'
import CloseSvg from 'assets/svg/CloseSvg'
import HeaderControls from 'components/HeaderControls'

const LocalHeader = ({ title, close, onClose }) => {
  const renderLeft = () => {
    if (close) {
      return (
        <div className="local-header-close" onClick={onClose}>
          <CloseSvg className="local-header-close-icon" />
          <span className="local-header-close-text">Close</span>
        </div>
      )
    } else {
      return <div className="local-header-title">{title}</div>
    }
  }

  return (
    <div className="LocalHeader" onClick={(e) => e.stopPropagation()}>
      {renderLeft()}
      <HeaderControls />
    </div>
  )
}

export default LocalHeader
