import React, { forwardRef } from 'react'
import { BoonTextField } from 'components/BoonInputs/BoonTextField'

const DatePickerInput = ({ onClick, field, value, ...props }, _ref) => {
  return (
    <BoonTextField
      field={{
        ...field,
        onClick,
        readOnly: true,
        value,
      }}
      {...props}
    />
  )
}

export default forwardRef(DatePickerInput)
