import { useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'

export const usePushHistoryWithSavedState = () => {
  const location = useLocation()
  const history = useHistory()

  const push = useCallback(
    (url, search, stayScrolled) => {
      url = url || location.pathname

      const { productId, categoryId } = queryString.parse(location.search)

      const query = {
        productId:
          search.productId === null ? null : search.productId || productId,
        categoryId:
          search.categoryId === null ? null : search.categoryId || categoryId,
      }

      // Remove all false, null or undefined entries
      Object.keys(query).forEach((key) => query[key] || delete query[key])

      url = `${url}?${queryString.stringify(query)}`

      history.push(url, { stayScrolled })
    },
    [location, history]
  )

  return push
}
