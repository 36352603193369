import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import './Header.scss'
import {
  isAuthenticated,
  selectorHeaderDark,
  selectorIsSudo,
  selectorNotFound,
} from 'store/selectors'
import clsx from 'clsx'
import { LogoSvg } from 'assets/svg'
import { URL_FRONT_PAGE } from 'urls'
import { useEffectOnce } from 'react-use'
import HeaderControls from 'components/HeaderControls'
import HeaderProduct from 'components/Header/HeaderProduct'

const hiddenPaths = [
  /^\/\S+\/\S+\/watch$/,
  /^\/\S+\/subscribe/,
  /^\/\S+\/\S+\/checkout/,
  /^\/sudo$/,
]
const transparentPaths = [/^\/explore$/, /^\/creator$/, /^\/product/]
const fullLogoPaths = [
  /^\/$/,
  /^\/creator$/,
  /^\/explore$/,
  /^\/creator-settings/,
  /^\/relations$/,
  /^\/content$/,
  /^\/accounting/,
  /^\/following$/,
  /^\/purchases$/,
  /^\/settings/,
  /^\/manage-subscriptions$/,
  /^\/about$/,
  /^\/contact$/,
  /^\/product/,
  /^\/qualification/,
  /^\/creator/,
  /^\/invoices$/,
  /^\/invite-friends$/,
  /^\/reset-password$/,
]
const landingPagePaths = [
  /^\/$/,
  /^\/creator$/,
  /^\/product/,
  /^\/explore$/,
  /^\/about$/,
]

const Header = () => {
  const { pathname } = useLocation()
  const [hasScrolled, setHasScrolled] = useState(false)
  const hasAuth = useSelector(isAuthenticated)
  const isSudo = useSelector(selectorIsSudo())
  const headerDark = useSelector(selectorHeaderDark())
  const isNotFound = useSelector(selectorNotFound())
  const isWhite = !headerDark

  useEffectOnce(() => {
    const listener = () => {
      setHasScrolled(window.scrollY > 0)
    }

    window.addEventListener('scroll', listener)

    return () => window.removeEventListener('scroll', listener)
  })

  if (hiddenPaths.find((r) => pathname.match(r) != null)) {
    return null
  }

  const isTransparent =
    transparentPaths.find((r) => pathname.match(r)) != null ||
    (pathname === '/' && !hasAuth)

  const fullLogo = fullLogoPaths.find((r) => pathname.match(r) != null)

  const landingPage =
    landingPagePaths.find((r) => pathname.match(r) != null) != null

  return (
    <div
      className={clsx('Header', {
        white: isWhite,
        scrolled: hasScrolled,
        transparent: isTransparent,
        sudo: isSudo,
      })}
    >
      <div className="header-wrapper">
        <div className={clsx('header-container')}>
          <div className="header-logo">
            <Link to={URL_FRONT_PAGE}>
              {(fullLogo || isNotFound) && <LogoSvg />}
            </Link>
          </div>
          <div className="header-right">
            <HeaderControls white={isWhite} landingPage={landingPage} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
