import React from 'react'
import { DateTime } from 'luxon'
import './EventStatus.scss'
import { isEventDraft, isEventScheduled, useRerenderInterval } from 'utils'

export const EventStatus = ({ event, long = false }) => {
  useRerenderInterval(long ? 1000 : 60 * 1000)

  if (!event) {
    return null
  }

  const renderLiveDuration = () => {
    if (isEventScheduled(event)) {
      return <span className="event-status-pending">Scheduled</span>
    }

    if (isEventDraft(event)) {
      return <span className="event-status-pending">Draft</span>
    }

    const dateTime = DateTime.fromISO(
      event.get('startTime') ||
        event.get('publishedAt') ||
        event.get('createdAt')
    )
    const seconds = Math.floor(dateTime.diffNow('seconds').seconds)

    if (seconds < -(event.get('duration') * 60)) {
      if (event.get('duration') == null) {
        return null
      }

      const minutes = event.get('duration')

      return <span className="duration">{minutes} min</span>
    }

    if (seconds <= 0) {
      return <span className="event-status-live-text">Live Now</span>
    }

    if (seconds <= 24 * 60 * 60) {
      return <span className="event-status-live-text">Live Soon</span>
    }

    return (
      <span className="event-status-live-text">
        Live {dateTime.toRelativeCalendar()}
      </span>
    )
  }

  return (
    <div className="EventStatus">
      {renderLiveDuration()}
      {event.get('isFree') === true && (
        <span className="event-card-free-badge">Free</span>
      )}
    </div>
  )
}

export default EventStatus
