import React, { useCallback } from 'react'
import './SettingsNotifications.scss'
import {
  createNotificationSubscription,
  notificationSubscriptionEmailSubscribe,
  notificationSubscriptionEmailUnsubscribe,
  useGetNotificationSubscriptionsApi,
} from 'api'
import Spinner from 'components/Spinner'
import ToggleButton from 'components/ToggleButton'
import { useMe } from 'utils'
import { Map } from 'immutable'

const NOTIFICATION_SUBSCRIPTION_TYPES = {
  'comment.created': 'Receive emails about new relevant comments',
  'post.created': 'Receive emails about new posts',
  'event.created':
    'Receive emails when creator publishes new events and videos',
  'event.reminder': 'Receive reminders about upcoming events',
}

const SettingsNotifications = () => {
  const me = useMe()
  const meId = me.get('id')
  const {
    data: notificationSubscriptions,
    refresh: notificationSubscriptionsRefresh,
  } = useGetNotificationSubscriptionsApi({ userId: meId })

  const onNotificationSubscriptionChange = useCallback(
    async (key, value, subscriptionId) => {
      //try {
      if (subscriptionId) {
        if (value) {
          await notificationSubscriptionEmailSubscribe({
            userId: meId,
            subscriptionId,
          })
        } else {
          await notificationSubscriptionEmailUnsubscribe({
            userId: meId,
            subscriptionId,
          })
        }
      } else {
        const data = {
          subscription: {
            key,
            subscribing: true,
            subscribing_to_email: value,
          },
        }
        await createNotificationSubscription({ userId: meId, data })
      }
      notificationSubscriptionsRefresh()
      /*} catch (e) {
      alert('An error occurred, please contact support')
    }*/
    },
    [meId, notificationSubscriptionsRefresh]
  )

  const renderNotificationSubscriptions = () => {
    const types = Map(NOTIFICATION_SUBSCRIPTION_TYPES)
      .map((message, key) => {
        if (notificationSubscriptions == null) {
          return (
            <div
              className="settings-notifications-subscriptions-item"
              key={key}
            >
              <div className="settings-notifications-subscriptions-item-message">
                {message}
              </div>
              <div className="settings-notifications-subscriptions-item-toggle">
                <Spinner tiny light />
              </div>
            </div>
          )
        }

        const subscription = notificationSubscriptions.find(
          (s) => s.get('key') === key
        )
        const isSubscribed =
          subscription == null || subscription.get('subscribingToEmail')

        return (
          <div className="settings-notifications-subscriptions-item" key={key}>
            <div className="settings-notifications-subscriptions-item-message">
              {message}
            </div>
            <ToggleButton
              value={isSubscribed}
              onChange={(value) =>
                onNotificationSubscriptionChange(
                  key,
                  value,
                  subscription ? subscription.get('id') : null
                )
              }
              className="settings-notifications-subscriptions-item-toggle"
            />
          </div>
        )
      })
      .valueSeq()

    return <div className="settings-notifications-subscriptions">{types}</div>
  }

  return (
    <div className="SettingsNotifications">
      {renderNotificationSubscriptions()}
    </div>
  )
}

export default SettingsNotifications
