import React from 'react'
import './BalanceTransactionList.scss'
import { DateTime } from 'luxon'
import { displayPrice } from 'utils'
import { TicketSvg, RecurringSvg } from 'assets/svg'
import { getAccountingNumberFormatter } from './AccountingConstants'

const BalanceTransactionList = React.memo(({ balanceTransactions }) => {
  const numberFormatter = getAccountingNumberFormatter()

  const items = balanceTransactions
    .sortBy((b) => b.get('created'))
    .filter((b) => b.get('stripeType') !== 'payout')
    .map((balanceTransaction) => {
      let itemName = 'Unknown'
      const createdAt = DateTime.fromISO(
        balanceTransaction.get('created')
      ).toFormat('MMM d, yyyy')
      const user = balanceTransaction.getIn(
        ['source', 'charge', 'invoice', 'user', 'name'],
        ''
      )

      const currency = balanceTransaction.get('currency')
      const formatCurrency = (amount) =>
        displayPrice(numberFormatter.format(amount / 100), currency)

      const amount = formatCurrency(balanceTransaction.get('amount'))
      const net = formatCurrency(balanceTransaction.get('net'))
      const vat = formatCurrency(balanceTransaction.get('invoiceTax'))
      const boonFee = formatCurrency(balanceTransaction.get('invoiceBoonFee'))
      const paymentFee = formatCurrency(
        balanceTransaction
          .get('invoiceStripeFees')
          .reduce((t, f) => t + f.get('amount'), 0)
      )

      const subscription = balanceTransaction.getIn([
        'source',
        'charge',
        'invoice',
        'subscription',
      ])
      const event = balanceTransaction.getIn([
        'source',
        'charge',
        'invoice',
        'event',
      ])
      const stripeType = balanceTransaction.get('stripeType')

      if (subscription) {
        itemName = (
          <>
            <RecurringSvg /> Subscription
          </>
        )
      } else if (event != null) {
        itemName = (
          <>
            <TicketSvg /> {event.get('title')}
          </>
        )
      } else if (stripeType === 'refund') {
        itemName = 'Refund'
      } else if (stripeType === 'adjustment') {
        itemName = 'Adjustment'
      }

      return (
        <tr key={balanceTransaction.get('id')}>
          <td className="balance-transaction-list-breakable-column">
            {itemName}
          </td>
          <td className="balance-transaction-list-breakable-column">{user}</td>
          <td>{createdAt}</td>
          <td>{amount}</td>
          <td>{vat}</td>
          <td>{paymentFee}</td>
          <td>{boonFee}</td>
          <td>{net}</td>
        </tr>
      )
    })

  return (
    <div className="BalanceTransactionList">
      <table className="balance-transaction-list-table">
        <thead>
          <tr>
            <th>Item</th>
            <th>User</th>
            <th>Date</th>
            <th>Amount</th>
            <th>VAT</th>
            <th>Payment fees</th>
            <th>Boon fee</th>
            <th>Net revenue</th>
          </tr>
        </thead>
        <tbody>{items}</tbody>
      </table>
    </div>
  )
})

export default BalanceTransactionList
