import React from 'react'

import InsightsTableDataElement from 'components/InsightsTableWidget/InsightsTableDataElement'

const InsightsTableMobileData = ({ headers, data, loading, clickableRow }) => {
  if (!data) {
    return null
  }

  let selectedHeaderIndex =
    headers.slice(1).findIndex(({ active }) => active) + 1

  return data.map((rowObj, idx) => {
    return (
      <div key={idx} className="insights-table-widget-mobile-data">
        <div
          className="insights-table-widget-mobile-data-element"
          onClick={() => clickableRow && clickableRow(rowObj)}
        >
          <InsightsTableDataElement
            loading={loading}
            data={rowObj}
            header={headers[0]}
          />
        </div>
        {selectedHeaderIndex !== 0 && (
          <div
            className="insights-table-widget-mobile-data-element"
            onClick={() => clickableRow && clickableRow(rowObj)}
          >
            <InsightsTableDataElement
              loading={loading}
              data={rowObj}
              header={headers[selectedHeaderIndex]}
            />
          </div>
        )}
      </div>
    )
  })
}

export default InsightsTableMobileData
