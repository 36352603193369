import React, { useEffect } from 'react'
import './InvoicesPage.scss'
import { useGetReceiptsApi } from 'api'
import { NarrowContainer } from 'components/Grid'
import Spinner from 'components/Spinner'
import { DownloadSvg } from 'assets/svg'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { isAuthenticated } from 'store/selectors'
import { URL_INVOICES } from 'urls'
import { DateTime } from 'luxon'
import PurchasesPageHeader from 'components/PurchasesPageHeader'

const InvoicesPage = () => {
  const history = useHistory()
  const hasAuth = useSelector((state) => isAuthenticated(state))
  const { data: receipts } = useGetReceiptsApi({}, [])

  useEffect(() => {
    if (!hasAuth) {
      history.push(`/sign-in?await_auth=true&redirect_url=${URL_INVOICES}`)
    }
  }, [hasAuth, history])

  const renderReceipts = () => {
    if (receipts == null) {
      return <Spinner />
    }

    if (receipts.size === 0) {
      return (
        <div className="invoices-page-empty">
          You currently have no downloadable receipts.
        </div>
      )
    }

    return receipts
      .filter((i) => i.get('receiptUrl') != null)
      .sortBy((i) => i.get('created'))
      .reverse()
      .map((i) => {
        const dateTime = DateTime.fromISO(i.get('created'))
        const name = i.getIn(['invoice', 'subscription'])
          ? 'Membership'
          : i.getIn(['invoice', 'event', 'title'])

        return (
          <div className="invoices-page-item" key={i.get('id')}>
            <div className="invoices-page-item-date">
              {dateTime.toFormat('MMM d yyyy')}
            </div>
            <div className="invoices-page-item-name">{name}</div>
            <div className="invoices-page-item-download">
              <a
                href={i.get('receiptUrl')}
                target="_blank"
                rel="noopener noreferrer"
                className="button secondary small"
              >
                <DownloadSvg /> Download Receipt
              </a>
            </div>
          </div>
        )
      })
  }

  return (
    <div className="InvoicesPage">
      <NarrowContainer>
        <PurchasesPageHeader />
        {renderReceipts()}
      </NarrowContainer>
    </div>
  )
}

export default InvoicesPage
