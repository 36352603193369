import React from 'react'
import './NamedAnimatedModal.scss'
import AnimatedModal from 'components/AnimatedModal'
import clsx from 'clsx'
import CloseSvg from 'assets/svg/CloseSvg'

const NamedAnimatedModal = ({
  active,
  onClose,
  title,
  className,
  children,
}) => {
  return (
    <AnimatedModal
      active={active}
      onClose={onClose}
      className={clsx('NamedAnimatedModal', className)}
      overlay
    >
      <div className="named-animated-modal-header">
        <div className="named-animated-modal-header-title">{title}</div>
        <CloseSvg
          className="named-animated-modal-header-close"
          onClick={onClose}
        />
      </div>
      <div
        className="named-animated-modal-content"
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        {children}
      </div>
    </AnimatedModal>
  )
}

export default NamedAnimatedModal
