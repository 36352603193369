let BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5000'
let FACEBOOK_APPID = process.env.REACT_APP_FACEBOOK_APPID || '295925571330825'
let MUX_DATA_ENV_KEY =
  process.env.REACT_APP_MUX_DATA_ENV_KEY || 'uu9s27le22run1kpfgetuauu5'
let STRIPE_PUBLISHABLE_KEY =
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ||
  'pk_test_lFQTNXMdtSayiJOnutYrgThK006AvIJZle'
let STRIPE_CLIENT_ID =
  process.env.REACT_APP_STRIPE_CLIENT_ID ||
  'ca_Gked0kYeb4pWvvTqHoEoBVqp1CkQDbDr'
let INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID || 'tmvxgvst'
let SENTRY_DSN =
  process.env.REACT_APP_SENTRY_DSN ||
  'https://91c0009f35cc4c37b449b96240c28227@sentry.io/1490613'
let APP_SEARCH_KEY =
  process.env.REACT_APP_APP_SEARCH_KEY || 'search-spndfbcqnm3ovax5i86pz2sq'
let APP_SEARCH_ENGINE_NAME =
  process.env.REACT_APP_APP_SEARCH_ENGINE_NAME || 'creators-test'
let APP_SEARCH_ENDPOINT_BASE =
  process.env.REACT_APP_APP_SEARCH_ENDPOINT_BASE ||
  'https://de72447d5da24122b71217d157514157.ent-search.eu-west-1.aws.cloud.es.io'

const ENVIRONMENTS = {
  STAGING: 'staging',
  PRODUCTION: 'production',
  DEVELOPMENT: 'development',
}
let ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT || ENVIRONMENTS.DEVELOPMENT
let SENTRY_ENVIRONMENT = process.env.REACT_APP_SENTRY_ENVIRONMENT || ENVIRONMENT

let FACEBOOK_INIT_PROPS = {
  appId: FACEBOOK_APPID,
  version: '4.0',
  xfbml: false,
}

// BACKEND_URL = 'https://boon-api-feature-free-e-9hqn0g.herokuapp.com'
BACKEND_URL = `${BACKEND_URL}/v1`

export {
  BACKEND_URL,
  ENVIRONMENTS,
  ENVIRONMENT,
  FACEBOOK_APPID,
  FACEBOOK_INIT_PROPS,
  MUX_DATA_ENV_KEY,
  STRIPE_PUBLISHABLE_KEY,
  STRIPE_CLIENT_ID,
  INTERCOM_APP_ID,
  SENTRY_DSN,
  SENTRY_ENVIRONMENT,
  APP_SEARCH_KEY,
  APP_SEARCH_ENGINE_NAME,
  APP_SEARCH_ENDPOINT_BASE,
}
