import React, { useCallback, useState } from 'react'
import { useGetCouponCodesApi, useGetProductsApi } from 'api'
import './CreatorCoupons.scss'
import { AddSvg } from 'assets/svg'
import CouponOptions from '../CouponOptions'
import CouponModal from 'modals/CouponModal'
import { useSelector } from 'react-redux'
import clsx from 'clsx'
import { useCopyToClipboard, useMe } from 'utils'

const CreatorCoupons = () => {
  const browser = useSelector((state) => state.get('browser'))
  const isMobile = browser.lessThan.lg
  const me = useMe()
  const copyToClipboard = useCopyToClipboard()
  const [addOpen, setAddOpen] = useState(false)
  const {
    data: coupons,
    loading,
    refresh: couponsRefresh,
    error: couponsError,
  } = useGetCouponCodesApi()
  const { data: products } = useGetProductsApi()

  const onCouponAction = useCallback(async () => {
    setAddOpen(false)
    await couponsRefresh()
  }, [couponsRefresh, setAddOpen])

  const renderCoupons = () => {
    if (coupons == null) {
      return null
    }

    return coupons.map((coupon) => {
      const isPercent = () => {
        return parseFloat(coupon.get('percentOff')) > 0
      }

      const months = coupon.get('durationInMonths')

      return (
        <div key={coupon.get('id')}>
          <div
            className={clsx('creator-coupons-coupon-content', {
              stretch: !isMobile,
              space: isMobile,
            })}
          >
            <p className="creator-coupons-coupon-name content-overflow">
              {coupon.get('name')}
            </p>
            {!isMobile && (
              <>
                <p className="creator-coupons-coupon-code content-overflow">
                  {coupon.get('code')}
                </p>
                <p className="creator-coupons-coupon-terms content-overflow">
                  {isPercent()
                    ? parseFloat(coupon.get('percentOff'))
                    : parseFloat(coupon.get('amountOff')) / 100}
                  {!isPercent() && ` ${coupon.get('currency').toUpperCase()}`}
                  {isPercent() ? '%' : ''} off{' '}
                  {coupon.get('duration') === 'repeating'
                    ? `for ${months} month${months !== 1 && 's'}`
                    : coupon.get('duration')}
                </p>
                <p className="creator-coupons-coupon-redemptions content-overflow">
                  {coupon.get('timesRedeemed') || 0}
                </p>
                <p className="creator-coupons-coupon-status content-overflow">
                  {coupon.get('status').charAt(0).toUpperCase() +
                    coupon.get('status').slice(1)}
                </p>
              </>
            )}
            <CouponOptions
              coupon={coupon}
              onCouponAction={couponsRefresh}
              copyToClipboard={copyToClipboard}
              me={me}
            />
          </div>
          <div className="creator-coupons-coupon-breaker" />
        </div>
      )
    })
  }

  if (couponsError) {
    return (
      <div className="CreatorCoupons">
        <div className="creator-coupon-error">
          Could not load coupons,{' '}
          <span onClick={couponsRefresh}>click here to try again</span>.
        </div>
      </div>
    )
  }

  return (
    <div className="CreatorCoupons">
      <CouponModal
        active={addOpen}
        onClose={() => setAddOpen(false)}
        onCouponAction={onCouponAction}
        products={products}
        isMobile={isMobile}
      />
      <div className="creator-coupons-title-wrapper">
        <p className="creator-coupons-title-text">Coupons</p>
        <div
          className="creator-coupons-add-button"
          onClick={() => setAddOpen(true)}
        >
          <AddSvg /> New
        </div>
      </div>
      <div className="creator-coupons-wrapper">
        <div
          className={clsx('creator-coupons-table-wrapper', {
            stretch: !isMobile,
            space: isMobile,
          })}
        >
          <p className="creator-coupons-coupon-title">Name</p>
          {!isMobile && (
            <>
              <p className="creator-coupons-code-title">Code</p>
              <p className="creator-coupons-term-title">Terms</p>
              <p className="creator-coupons-redemption-title">Redemptions</p>
              <p className="creator-coupons-status-title">Status</p>
            </>
          )}
        </div>
        <div className="creator-coupons-border-line" />
        {loading && <div className="category-selector-loader" />}
        <div className="creator-coupons-content">
          {!loading && renderCoupons()}
        </div>
      </div>
    </div>
  )
}

export default CreatorCoupons
