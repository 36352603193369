import React from 'react'
import './HeaderBurger.scss'
import MenuModal from 'components/MenuModal'
import CloseSvg from 'assets/svg/CloseSvg'
import HeaderMenuContent from './HeaderMenuContent'

const HeaderBurger = ({ isOpen, setIsOpen }) => {
  return (
    <MenuModal
      active={isOpen}
      className="HeaderBurger"
      onClose={() => setIsOpen(false)}
    >
      <div className="header-burger-header">
        <CloseSvg
          className="header-burger-header-close"
          onClick={() => setIsOpen(false)}
        />
      </div>

      <div className="header-burger-wrapper" onClick={() => setIsOpen(!isOpen)}>
        <HeaderMenuContent creatorMenu />
      </div>
    </MenuModal>
  )
}

export default HeaderBurger
