import React, { useCallback, useContext } from 'react'
import './InsightsFilters.scss'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import InsightsContext from 'routes/InsightsPage/InsightsContext'
import {
  INSIGHTS_PERIODS,
  INSIGHTS_PERIODS_NAMES,
} from 'routes/InsightsPage/insightsConstants'
import { useMe } from 'utils'
import { useGetUserApi } from 'api'
import { URL_CREATOR_SETTINGS_MEMBERSHIP } from 'urls'

const InsightsFilters = ({ filterProducts }) => {
  const me = useMe()
  const { data: user } = useGetUserApi({ userId: me.get('id') })
  const hasProducts = user != null && user.get('hasMembership')
  const { period, setPeriod, productsFilter, setProductsFilter } = useContext(
    InsightsContext
  )
  const browser = useSelector((state) => state.get('browser'))
  const showProductsFilter =
    filterProducts && hasProducts && browser.greaterThan.lg

  return (
    <div className="InsightsFilters">
      {showProductsFilter && (
        <InsightsSelect value={productsFilter} onChange={setProductsFilter}>
          <option value="">All memberships</option>
          {user.get('products').map((product) => (
            <option value={product.get('id')} key={product.get('id')}>
              {product.get('name')}
            </option>
          ))}
        </InsightsSelect>
      )}
      {!hasProducts && (
        <Link
          className="insights-add-membership-button button large"
          to={URL_CREATOR_SETTINGS_MEMBERSHIP}
        >
          Add Membership
        </Link>
      )}
      <div className="insights-filters-right">
        <InsightsSelect value={period} onChange={setPeriod}>
          <option value={INSIGHTS_PERIODS.TOTAL}>
            {INSIGHTS_PERIODS_NAMES.TOTAL}
          </option>
          <option value={INSIGHTS_PERIODS.THIRTY_DAYS}>
            {INSIGHTS_PERIODS_NAMES.THIRTY_DAYS}
          </option>
        </InsightsSelect>
      </div>
    </div>
  )
}

export default InsightsFilters

const InsightsSelect = ({ value, onChange, children }) => {
  const _onChange = useCallback(
    (e) => {
      onChange(e.target.value === '' ? null : e.target.value)
    },
    [onChange]
  )

  return (
    <select
      className="InsightsSelect"
      value={value == null ? '' : value}
      onChange={_onChange}
    >
      {children}
    </select>
  )
}
