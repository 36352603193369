// Local URLs
export const URL_DOMAIN = 'https://boon.tv'
export const URL_FRONT_PAGE = '/'
export const URL_CREATOR = '/creator'
export const URL_CREATOR_SETUP = '/creator/setup'
export const URL_CREATOR_SETUP_PROFILE = '/creator/setup/profile'
export const URL_CREATOR_SETUP_PAYOUT = '/creator/setup/payout'
export const URL_CREATOR_SETUP_MEMBERSHIP = '/creator/setup/membership'
export const URL_TERMS_AND_CONDITIONS = '/terms'
export const URL_CONTACT = '/contact'
export const URL_ABOUT = '/about'
export const URL_SCHEDULE = '/schedule'
export const URL_UPLOAD = '/upload'
export const URL_FOLLOWING = '/following'
export const URL_PURCHASES = '/purchases'
export const URL_INVOICES = '/invoices'
export const URL_INVITE_FRIENDS = '/invite-friends'

export const URL_SETTINGS = '/settings'
export const URL_SETTINGS_PROFILE_LEGACY = '/settings/profile'
export const URL_SETTINGS_BUSINESS_LEGACY = '/settings/business'
export const URL_SETTINGS_ACCOUNT = '/settings/account'
export const URL_SETTINGS_NOTIFICATIONS = '/settings/notifications'

export const URL_CREATOR_SETTINGS = '/creator-settings'
export const URL_CREATOR_SETTINGS_PROFILE = '/creator-settings/profile'
export const URL_CREATOR_SETTINGS_PAYOUT = '/creator-settings/payout'
export const URL_CREATOR_SETTINGS_MEMBERSHIP = '/creator-settings/membership'
export const URL_CREATOR_SETTINGS_CATEGORIES = '/creator-settings/categories'
export const URL_CREATOR_SETTINGS_COUPONS = '/creator-settings/coupons'

export const URL_LEAD_QUALIFICATION = '/qualification'
export const URL_LEAD_QUALIFICATION_CATEGORIES = '/qualification/categories'
export const URL_LEAD_QUALIFICATION_SOCIAL = '/qualification/social'
export const URL_LEAD_QUALIFICATION_PROFILE = '/qualification/profile'

export const URL_INSIGHTS_OVERVIEW = '/insights'
export const URL_INSIGHTS_MEMBERS = '/insights/members'
export const URL_INSIGHTS_INCOME = '/insights/income'
export const URL_INSIGHTS_VIDEOS = '/insights/videos'
export const URL_EVENT_INSIGHTS_OVERVIEW = (userSlug, eventSlug) =>
  `/${userSlug}/${eventSlug}/insights`
export const URL_EVENT_INSIGHTS_OVERVIEW_ROUTE =
  '/:userSlug/:eventSlug/insights'
export const URL_EVENT_INSIGHTS_SALES = (userSlug, eventSlug) =>
  `${URL_EVENT_INSIGHTS_OVERVIEW(userSlug, eventSlug)}/sales`
export const URL_EVENT_INSIGHTS_SALES_ROUTE = `${URL_EVENT_INSIGHTS_OVERVIEW_ROUTE}/sales`
export const URL_EVENT_INSIGHTS_VIEWS = (userSlug, eventSlug) =>
  `${URL_EVENT_INSIGHTS_OVERVIEW(userSlug, eventSlug)}/views`
export const URL_EVENT_INSIGHTS_VIEWS_ROUTE = `${URL_EVENT_INSIGHTS_OVERVIEW_ROUTE}/views`

export const URL_POST = (userSlug, postId) => `/${userSlug}/posts/${postId}`
export const URL_EVENT = (userSlug, eventSlug) => `/${userSlug}/${eventSlug}`
export const URL_EVENT_CHECKOUT = (userSlug, eventSlug) =>
  `/${userSlug}/${eventSlug}/checkout`
export const URL_EVENT_CHECKOUT_PAYMENT = (userSlug, eventSlug) =>
  `/${userSlug}/${eventSlug}/checkout/payment`
export const URL_EVENT_CHECKOUT_SUCCESS = (userSlug, eventSlug) =>
  `/${userSlug}/${eventSlug}/checkout/success`
export const URL_USER = (userSlug) => `/${userSlug}`
export const URL_UPDATE_SUBSCRIPTION_PAYMENT = (userSlug, subscriptionId) =>
  `/${userSlug}/update-subscription-payment/${subscriptionId}`
export const URL_SUBSCRIBE = (userSlug) => `/${userSlug}/subscribe`
export const URL_SUBSCRIBE_PLAN = (userSlug, planId) =>
  `/${userSlug}/subscribe/${planId}`
export const URL_SUBSCRIBE_PLAN_CHECKOUT = (userSlug, planId) =>
  `/${userSlug}/subscribe/${planId}/checkout`
export const URL_SUBSCRIBE_PLAN_SUCCESS = (userSlug, planId) =>
  `/${userSlug}/subscribe/${planId}/success`
export const URL_EVENT_WATCH = (userSlug, eventSlug) =>
  `/${userSlug}/${eventSlug}/watch`
export const URL_EVENT_EDIT = (userSlug, eventSlug) =>
  `/${userSlug}/${eventSlug}/edit`
export const URL_EVENT_OVERVIEW = (userSlug, eventSlug) =>
  `/${userSlug}/${eventSlug}/overview`
export const URL_INVITE_FULL = (userSlug, code) =>
  `${URL_DOMAIN}/${userSlug}/inv/${code}`
export const URL_NOT_FOUND = `/404`
export const URL_MANAGE_SUBSCRIPTIONS = '/manage-subscriptions'
export const URL_ACCOUNTING = () => `/accounting`
export const URL_ACCOUNTING_PAYOUT = (payoutId) => `/accounting/${payoutId}`
export const URL_ACCOUNTING_NEW_PAYOUT = (payoutId) =>
  `/accounting/new/${payoutId}`

export const URL_EXTERNAL_HELP = 'https://help.boon.tv/en/'
export const URL_EXTERNAL_TERMS_AND_CONDITIONS =
  'https://www.notion.so/boontv/Terms-Conditions-42d65adc1397498c9db6c9214c7d3328'
export const URL_EXTERNAL_PRIVACY_POLICY =
  'https://www.notion.so/boontv/Privacy-Policy-9a441b2215734191809ac87094559674'
export const URL_EXTERNAL_REFERRAL_PROGRAM =
  'https://help.boon.tv/en/articles/4843089-creator-referral-program'
export const URL_EXTERNAL_FAQ = 'https://help.boon.tv/en/'
export const URL_EXTERNAL_CAREERS = 'https://boon-tv.workable.com/'
export const URL_EXTERNAL_NET_CREATOR_REVENUE =
  'https://help.boon.tv/en/articles/4852375-paying-the-boon-fees'
export const URL_EXTERNAL_REQUEST_DEMO = '/requestdemo'
export const URL_EXTERNAL_BLOG = 'https://blog.boon.tv/'

// Product Feature pages
export const URL_FEATURE_PAGE = '/product/page'
export const URL_FEATURE_MEMBERSHIP = '/product/membership'
export const URL_FEATURE_VIDEO = '/product/video'
export const URL_FEATURE_COMMUNITY = '/product/community'
export const URL_FEATURE_INSIGHTS = '/product/insights'
export const URL_FEATURE_PAYOUT = '/product/payout'

// Redirect urls
export const URL_FACEBOOK_REDIRECT = () =>
  window.location.origin + `/facebook-redirect`

// Emails
export const EMAIL_HELLO_TEXT = 'hello@boon.tv'
export const EMAIL_HELLO = `mailto:${EMAIL_HELLO_TEXT}`
export const EMAIL_SUPPORT_TEXT = 'support@boon.tv'
export const EMAIL_SUPPORT = `mailto:${EMAIL_SUPPORT_TEXT}`
export const EMAIL_SALES_TEXT = 'sales@boon.tv'
export const EMAIL_SALES = `mailto:${EMAIL_SALES_TEXT}`

// Social Media
export const URL_FACEBOOK = 'https://www.facebook.com/helloboontv'
export const URL_INSTAGRAM = 'https://www.instagram.com/boon__tv/'
export const URL_LINKEDIN = 'https://www.linkedin.com/company/boon-tv/'
export const URL_TWITTER = 'https://twitter.com/Boon__tv'

// Our apps
export const URL_APP_IOS =
  'https://apps.apple.com/dk/app/boon-video/id1478888793'
export const URL_APP_ANDROID =
  'https://play.google.com/store/apps/details?id=video.boon'

// 3rd party
export const URL_OBS_DOWNLOAD = 'https://obsproject.com/da/download'
