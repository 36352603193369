import React, { useCallback, useState } from 'react'
import './PostOptions.scss'
import OptionsMenu from 'components/OptionsMenu'
import { deletePostApi, updatePostApi } from 'api'
import { PostComposeUpdate } from 'components/PostCompose'
import { getPostUrl, useCopyToClipboard } from 'utils'

const PostOptions = ({ post, onPostDeleted, onPostUpdated, hostId, dark }) => {
  const postId = post.get('id')
  const [open, setOpen] = useState(false)
  const [editActive, setEditActive] = useState(false)
  const copyToClipboard = useCopyToClipboard()

  const onDelete = useCallback(async () => {
    if (!window.confirm(`Are you sure you want to delete the this post?`)) {
      return null
    }

    try {
      await deletePostApi({ postId })
      onPostDeleted({ postId })
    } catch (e) {
      alert('Deletion failed, please try again')
    }

    setOpen(false)
  }, [postId, onPostDeleted])

  const onEdit = () => {
    setEditActive(true)
    setOpen(false)
  }

  const onEditClose = useCallback(() => {
    setEditActive(false)
  }, [setEditActive])

  const togglePinPost = async () => {
    let newPost = await updatePostApi({
      postId: postId,
      data: {
        pinned: !post.get('pinned'),
      },
    })
    onPostUpdated({ postId: newPost.get('id'), post: newPost })
  }

  return (
    <>
      {editActive && (
        <PostComposeUpdate
          post={post}
          onClose={onEditClose}
          onPostUpdated={onPostUpdated}
          hostId={hostId}
        />
      )}
      <OptionsMenu
        open={open}
        setOpen={setOpen}
        className="PostOptions"
        dark={dark}
      >
        <div className="link" onClick={togglePinPost}>
          {post.get('pinned') ? 'Unpin' : 'Pin'} post
        </div>
        <div className="link" onClick={() => copyToClipboard(getPostUrl(post))}>
          Copy link
        </div>
        {onPostUpdated && (
          <div className="link" onClick={onEdit}>
            Edit post
          </div>
        )}
        {onPostDeleted && (
          <div className="link" onClick={onDelete}>
            Delete post
          </div>
        )}
      </OptionsMenu>
    </>
  )
}

export default PostOptions
