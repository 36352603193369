import React from 'react'
import { withContentRect } from 'react-measure'
import constructUrl from 'react-imgix/lib/constructUrl'
import extractQueryParams from 'react-imgix/lib/extractQueryParams'
import targetWidths from 'react-imgix/lib/targetWidths'
import findClosest from 'react-imgix/lib/findClosest'

// From: https://raw.githubusercontent.com/imgix/react-imgix/master/src/react-imgix-bg.jsx

const PACKAGE_VERSION = 'custom'

const findNearestWidth = (actualWidth) => findClosest(actualWidth, targetWidths)

const toFixed = (dp, value) => +value.toFixed(dp)

const BackgroundImpl = (props) => {
  const {
    measureRef,
    contentRect,
    imgixParams = {},
    disableLibraryParam,
    src,
    children,
    className = '',
    onClick,
    wrapper: Wrapper = 'div',
  } = props
  const { w: forcedWidth, h: forcedHeight } = imgixParams
  const hasDOMDimensions = contentRect.bounds.top != null
  const htmlAttributes = props.htmlAttributes || {}
  const dpr = toFixed(2, imgixParams.dpr || global.devicePixelRatio || 1)

  const { width, height } = (() => {
    const bothWidthAndHeightPassed = forcedWidth != null && forcedHeight != null

    if (bothWidthAndHeightPassed) {
      return { width: forcedWidth, height: forcedHeight }
    }

    if (!hasDOMDimensions) {
      return { width: undefined, height: undefined }
    }
    const ar = contentRect.bounds.width / contentRect.bounds.height

    const neitherWidthNorHeightPassed =
      forcedWidth == null && forcedHeight == null
    if (neitherWidthNorHeightPassed) {
      const width = findNearestWidth(contentRect.bounds.width)
      const height = Math.ceil(width / ar)
      return { width, height }
    }
    if (forcedWidth != null) {
      const height = Math.ceil(forcedWidth / ar)
      return { width: forcedWidth, height }
    } else if (forcedHeight != null) {
      const width = Math.ceil(forcedHeight * ar)
      return { width, height: forcedHeight }
    }
  })()
  const isReady = width != null && height != null

  const commonProps = {
    ...htmlAttributes,
  }

  if (!isReady) {
    return (
      <Wrapper
        {...commonProps}
        className={`react-imgix-bg-loading ${className}`}
        ref={measureRef}
      >
        {children}
      </Wrapper>
    )
  }

  const renderedSrc = (() => {
    const [rawSrc, params] = extractQueryParams(src)
    const srcOptions = {
      ...params,
      fit: 'crop',
      ...imgixParams,
      ...(disableLibraryParam ? {} : { ixlib: `react-${PACKAGE_VERSION}` }),
      width,
      height,
      dpr,
    }

    return constructUrl(rawSrc, srcOptions)
  })()

  const style = {
    ...htmlAttributes.style,
    backgroundImage: `url(${renderedSrc})`,
    backgroundSize:
      (htmlAttributes.style || {}).backgroundSize !== undefined
        ? htmlAttributes.style.backgroundSize
        : 'cover',
  }

  return (
    <Wrapper
      {...commonProps}
      className={className}
      ref={measureRef}
      style={style}
      onClick={onClick}
    >
      {children}
    </Wrapper>
  )
}
const Background = withContentRect('bounds')(BackgroundImpl)

export { Background, BackgroundImpl as __BackgroundImpl }
