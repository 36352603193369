import React, { forwardRef } from 'react'
import './ChannelCarouselControls.scss'
import { WithStore } from 'pure-react-carousel'

const ChannelCarouselControlsInner = (
  { currentSlide, totalSlides, visibleSlides, carouselStore },
  _ref
) => {
  if (totalSlides <= visibleSlides) {
    return null
  }

  const pageCount = Math.ceil(totalSlides / visibleSlides)
  const currentPage = Math.floor(currentSlide / visibleSlides)

  const renderPages = () => {
    return Array.from(Array(pageCount)).map((_, i) => (
      <div
        className="channel-carousel-controls-page"
        key={i}
        onClick={() => carouselStore.setStoreState({ currentSlide: i })}
      />
    ))
  }

  return (
    <div className="ChannelCarouselControls">
      <div className="channel-carousel-controls-pages">
        {renderPages()}
        <div
          className="channel-carousel-controls-position"
          style={{ left: `calc(${(currentPage / pageCount) * 100}% + 4px)` }}
        />
      </div>
    </div>
  )
}

const ChannelCarouselControls = WithStore(
  forwardRef(ChannelCarouselControlsInner),
  (state) => ({
    currentSlide: state.currentSlide,
    totalSlides: state.totalSlides,
    visibleSlides: state.visibleSlides,
  })
)

export default ChannelCarouselControls
