import React from 'react'
import './PostCommentReply.scss'
import PostCommentBody from './PostCommentBody'

const PostCommentReply = ({
  postId,
  hostId,
  reply,
  onReplyClick,
  dispatchPosts,
  requireAccess,
}) => {
  return (
    <div className="PostCommentReply" id={`comment-${reply.get('id')}`}>
      <PostCommentBody
        postId={postId}
        hostId={hostId}
        comment={reply}
        reply
        onReplyClick={onReplyClick}
        dispatchPosts={dispatchPosts}
        requireAccess={requireAccess}
      />
    </div>
  )
}

export default PostCommentReply
