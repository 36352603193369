import React, { useState } from 'react'
import './ChangeSubscriptionModal.scss'
import NamedPinnedModal from 'components/NamedPinnedModal'
import CheckoutPaymentPrice from 'components/Checkout/CheckoutPaymentPrice'
import { changeSubscription } from 'api'
import { asyncModal, getApiError } from 'utils'
import CancelSubscriptionModal from 'modals/CancelSubscriptionModal'
import clsx from 'clsx'

const ChangeSubscriptionModal = ({
  subscription,
  active,
  onClose,
  onChanged,
  onCanceled,
}) => {
  const [selectedPlanId, setSelectedPlanId] = useState(
    subscription.getIn(['plan', 'id'])
  )
  const onPlanChange = (planId) => {
    setSelectedPlanId(planId)
  }
  const onSubmit = async () => {
    try {
      await changeSubscription({
        oldPlanId: subscription.getIn(['plan', 'id']),
        newPlanId: selectedPlanId,
      })
      onChanged()
      onClose()
    } catch (e) {
      console.log(getApiError(e))
    }
  }

  const handleCancel = async () => {
    onClose()
    await asyncModal(({ active, onDismiss }) => (
      <CancelSubscriptionModal
        active={active}
        subscription={subscription}
        onClose={onDismiss}
        onCanceled={onCanceled}
      />
    ))
  }

  return (
    <NamedPinnedModal
      title="Change Billing"
      active={active}
      onClose={onClose}
      className="ChangeSubscriptionModal"
    >
      <div className="change-subscription-modal-description">
        Change how your membership is billed
      </div>
      <CheckoutPaymentPrices
        prices={subscription.get('alternativePlans')}
        selectedPlanId={selectedPlanId}
        onPlanChange={(planId) => onPlanChange(planId)}
      />
      Your new billing will take affect immediately. Value of remaining days in
      existing membership is deducted.
      <button
        className={clsx(
          'button primary change-subscription-modal-confirm-button',
          {
            disabled: subscription.getIn(['plan', 'id']) === selectedPlanId,
          }
        )}
        onClick={onSubmit}
      >
        Confirm Change
      </button>
      <div className="change-subscription-modal-cancel" onClick={handleCancel}>
        Cancel Membership
      </div>
    </NamedPinnedModal>
  )
}

export default ChangeSubscriptionModal

const CheckoutPaymentPrices = ({ prices, selectedPlanId, onPlanChange }) => {
  if (prices == null) {
    return null
  }

  return prices
    .sort((x, y) => y.get('base') - x.get('base'))
    .map((price) => (
      <CheckoutPaymentPrice
        active={price.get('id') === selectedPlanId}
        onClick={() => onPlanChange(price.get('id'))}
        price={price}
      />
    ))
}
