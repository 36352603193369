import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { URL_FACEBOOK_REDIRECT, URL_FRONT_PAGE } from 'urls'
import { signInFacebookStore } from 'store/utils/auth'
import Spinner from 'components/Spinner'
import { useMount } from 'react-use'
import queryString from 'query-string'

const FacebookRedirectPage = () => {
  const location = useLocation()
  const history = useHistory()
  const { code: exchangeCode, state: returnUrl } = queryString.parse(
    location.search
  )

  const handleRedirect = () => {
    if (returnUrl) {
      history.replace(returnUrl)
    } else {
      history.replace(URL_FRONT_PAGE)
    }
  }

  useMount(async () => {
    try {
      await signInFacebookStore({
        exchangeCode,
        redirectUri: URL_FACEBOOK_REDIRECT(),
      })
      handleRedirect()
    } catch (e) {
      alert('Facebook login failed, please try again later')
      handleRedirect()
    }
  })

  return (
    <>
      <Spinner light />
    </>
  )
}

export default FacebookRedirectPage
