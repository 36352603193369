import React, { useState } from 'react'
import './ForgotPasswordView.scss'
import { Formik, Form } from 'formik'
import clsx from 'clsx'
import * as Yup from 'yup'
import StyledApiErrorMessage from 'components/StyledApiErrorMessage'
import { forgotPassword } from 'api'
import { BoonFormikTextField } from 'components/BoonInputs'
import { getApiError } from 'utils'
import { ForwardInboxSvg } from 'assets/svg'

const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
})

const ForgotPasswordView = ({ onClose }) => {
  const [success, setSuccess] = useState(false)
  const [email, setEmail] = useState(null)

  const renderSuccess = () => {
    if (success) {
      return (
        <>
          <ForwardInboxSvg />
          <p>A link to reset your password has been sent to {email}</p>
          <div className="button primary" onClick={onClose}>
            Close
          </div>
        </>
      )
    }

    return null
  }

  const renderForm = () => {
    if (success) {
      return null
    }

    return (
      <Formik
        initialValues={{ email: '' }}
        validationSchema={ForgotPasswordSchema}
        onSubmit={(values, { setSubmitting, setErrors }) => {
          forgotPassword({ email: values.email })
            .then(() => {
              setSuccess(true)
            })
            .catch((e) => {
              setErrors(getApiError(e))
            })
            .then(() => {
              setEmail(values.email)
              setSubmitting(false)
            })
        }}
      >
        {({ isSubmitting, errors }) => (
          <Form>
            <p>
              Enter the email address associated with your account, and we'll
              email you a link to reset your password.
            </p>
            <BoonFormikTextField type="email" name="email" label="Email" />
            <button
              type="submit"
              disabled={isSubmitting}
              className={clsx('button primary medium loader', {
                loading: isSubmitting,
              })}
            >
              Send reset link
            </button>
            <StyledApiErrorMessage />
          </Form>
        )}
      </Formik>
    )
  }

  return (
    <section className="ForgotPasswordView">
      {renderForm()}
      {renderSuccess()}
    </section>
  )
}

export default ForgotPasswordView
