import React from 'react'
import './EventButton.scss'
import clsx from 'clsx'
import { EVENT_BUTTON_TYPE } from 'components/EventButton/EventButton'

const EventButtonArchived = ({ className, type }) => {
  return (
    <button className={clsx('button button-archived', className)}>
      {type === EVENT_BUTTON_TYPE.LARGE
        ? 'Event has been unlisted'
        : 'Unlisted'}
    </button>
  )
}

export default EventButtonArchived
