import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import './EventPage.scss'
import { getMe } from 'store/selectors'
import { Helmet } from 'react-helmet-async'
import { useGetEventApi } from 'api'
import Spinner from 'components/Spinner'
import NewEventPage from './NewEventPage'
import LoadingError from 'components/LoadingError'
import { getEventUrl, useCatchCouponCode, useDarkHeader } from 'utils'
import NotFoundPage from 'routes/NotFoundPage'
import CheckoutModal from 'modals/CheckoutModal'
import FollowerModal from 'modals/FollowerModal'
import { useHistory } from 'react-router'
import { useRouteMatch } from 'react-router-dom'

const EventPage = () => {
  const {
    params: { userSlug, eventSlug },
  } = useRouteMatch()
  useCatchCouponCode(userSlug)
  useDarkHeader()
  const history = useHistory()
  const me = useSelector((state) => getMe(state))
  const [checkoutOpen, setCheckoutOpen] = useState(false)
  const [showFollowerModal, setFollowOpen] = useState(false)
  const { data: event, error, refresh: refreshEvent } = useGetEventApi(
    {
      userSlug,
      eventSlug,
    },
    [me?.get('id')]
  )

  const notAvailable =
    event &&
    ['soldOut', 'archived', 'notOnDemand'].includes(event.get('attendingState'))
  const onNoAccess = useCallback(() => {
    if (notAvailable) {
      return null
    } else if (event.get('isFree') === true) {
      setFollowOpen(true)
    } else {
      setCheckoutOpen(true)
    }
  }, [setCheckoutOpen, setFollowOpen, event, notAvailable])

  if (error) {
    if (error?.response?.status === 404) {
      return <NotFoundPage />
    } else {
      return (
        <LoadingError
          refresh={() => {
            refreshEvent()
          }}
        />
      )
    }
  }

  if (event == null) {
    return (
      <div className="EventPage">
        <Spinner light />
      </div>
    )
  }

  const user = event.get('host')

  return (
    <div className="EventPage">
      <Helmet>
        <title>
          {event.get('title')} - {event.getIn(['host', 'name'])} | Boon
        </title>
      </Helmet>
      <CheckoutModal
        active={checkoutOpen}
        title={`Unlock ${event.get('title')}`}
        event={event}
        hostId={user.get('id')}
        onClose={() => setCheckoutOpen(false)}
        productIds={event.get('productIds')}
      />
      <FollowerModal
        active={showFollowerModal}
        event={event}
        host={event.get('host')}
        onClose={() => setFollowOpen(false)}
        refreshEvent={refreshEvent}
        redirectOnFollow={() => history.replace(getEventUrl(event))}
      />
      <NewEventPage
        event={event}
        refreshEvent={refreshEvent}
        user={user}
        userSlug={userSlug}
        onNoAccess={onNoAccess}
        withHeader
      />
    </div>
  )
}

export default EventPage
