import React, { useCallback } from 'react'
import './SubscribePageActions.scss'
import { createCheckoutSessionApi, createSubscriptionApi } from 'api'
import { useApiWrapper } from 'utils'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import { URL_SUBSCRIBE_PLAN_SUCCESS, URL_USER } from 'urls'

const SubscribePageActions = ({
  checkout,
  couponCode,
  removeCouponCode,
  inviteCode,
  redirectUrl,
  userSlug,
}) => {
  const {
    runApiCall: getCheckoutSession,
    error: checkoutError,
    loading: checkoutLoading,
  } = useApiWrapper(createCheckoutSessionApi)
  const {
    runApiCall: createFreeSubscription,
    error: freeError,
    loading: freeLoading,
  } = useApiWrapper(createSubscriptionApi)

  const onPayClick = useCallback(async () => {
    const result = await getCheckoutSession({
      planId: checkout.getIn(['plan', 'id']),
      inviteCode,
      couponCode,
      redirectUrl,
    })
    if (result.data != null) {
      removeCouponCode()
      window.location.href = result.data.get('url')
    }
  }, [
    getCheckoutSession,
    checkout,
    inviteCode,
    couponCode,
    redirectUrl,
    removeCouponCode,
  ])

  const onFreeClick = useCallback(async () => {
    const result = await createFreeSubscription({
      data: {
        planId: checkout.getIn(['plan', 'id']),
        couponCode,
      },
    })
    if (result.data != null) {
      removeCouponCode()
      window.location.href =
        URL_SUBSCRIBE_PLAN_SUCCESS(userSlug, checkout.getIn(['plan', 'id'])) +
        '?redirect_url=' +
        redirectUrl
    }
  }, [
    createFreeSubscription,
    checkout,
    couponCode,
    removeCouponCode,
    redirectUrl,
    userSlug,
  ])

  const renderAction = () => {
    if (checkout.get('noPayment') === true) {
      return (
        <button
          className={clsx('button primary loader subscribe-page-actions-pay', {
            freeLoading,
          })}
          onClick={onFreeClick}
        >
          Confirm free membership
        </button>
      )
    } else {
      return (
        <button
          className={clsx('button primary loader subscribe-page-actions-pay', {
            checkoutLoading,
          })}
          onClick={onPayClick}
        >
          Continue to Payment
        </button>
      )
    }
  }

  const error = checkoutError ?? freeError
  const cancelUrl = redirectUrl || URL_USER(userSlug)

  return (
    <div className="SubscribePageActions">
      {error && <div className="input-error">{error?.message}</div>}
      {renderAction()}
      <Link to={cancelUrl} className="subscribe-page-actions-cancel">
        Cancel
      </Link>
    </div>
  )
}

export default SubscribePageActions
