import {
  URL_EVENT,
  URL_EVENT_CHECKOUT,
  URL_EVENT_CHECKOUT_PAYMENT,
  URL_EVENT_CHECKOUT_SUCCESS,
  URL_EVENT_EDIT,
  URL_EVENT_OVERVIEW,
  URL_POST,
  URL_SUBSCRIBE_PLAN,
  URL_SUBSCRIBE_PLAN_CHECKOUT,
  URL_SUBSCRIBE_PLAN_SUCCESS,
  URL_USER,
} from 'urls'
import { getEventSlug } from 'utils/event'
import { getUserSlug } from 'utils/user'

/*
  Generic URL helper functions that takes data objects and URLs
*/

export const getPostUrl = (post) => {
  const userSlug = getUserSlug(post.get('host'))
  const postId = post.get('id')
  return URL_POST(userSlug, postId)
}

export const getEventUrl = (event) => {
  const userSlug = getUserSlug(event.get('host'))
  const eventSlug = getEventSlug(event)
  return URL_EVENT(userSlug, eventSlug)
}

export const getEventEditUrl = (event) => {
  const userSlug = getUserSlug(event.get('host'))
  const eventSlug = getEventSlug(event)
  return URL_EVENT_EDIT(userSlug, eventSlug)
}

export const getEventOverviewUrl = (event) => {
  const userSlug = getUserSlug(event.get('host'))
  const eventSlug = getEventSlug(event)
  return URL_EVENT_OVERVIEW(userSlug, eventSlug)
}

export const getUserUrl = (user, url = URL_USER) => {
  const userSlug = getUserSlug(user)
  return url(userSlug)
}

export const getSubscribePlanUrl = (user, planId, redirectUrl = null) => {
  const userSlug = getUserSlug(user)
  return (
    URL_SUBSCRIBE_PLAN(userSlug, planId) +
    (redirectUrl != null ? `?redirect_url=${redirectUrl}` : '')
  )
}

export const getSubscribePlanCheckoutUrl = (user, planId) => {
  const userSlug = getUserSlug(user)
  return URL_SUBSCRIBE_PLAN_CHECKOUT(userSlug, planId)
}

export const getSubscribePlanSuccessUrl = (user, planId) => {
  const userSlug = getUserSlug(user)
  return URL_SUBSCRIBE_PLAN_SUCCESS(userSlug, planId)
}

export const getEventCheckoutUrl = (event) => {
  const userSlug = getUserSlug(event.get('host'))
  const eventSlug = getEventSlug(event)
  return URL_EVENT_CHECKOUT(userSlug, eventSlug)
}

export const getEventCheckoutPaymentUrl = (event) => {
  const userSlug = getUserSlug(event.get('host'))
  const eventSlug = getEventSlug(event)
  return URL_EVENT_CHECKOUT_PAYMENT(userSlug, eventSlug)
}

export const getEventCheckoutSuccessUrl = (event) => {
  const userSlug = getUserSlug(event.get('host'))
  const eventSlug = getEventSlug(event)
  return URL_EVENT_CHECKOUT_SUCCESS(userSlug, eventSlug)
}
