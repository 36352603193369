import React, { useState, useEffect } from 'react'
import './AddSocialModal.scss'
import NamedPinnedModal from 'components/NamedPinnedModal'
import { SETUP_SOCIAL_ITEMS } from './CreatorSetupForm'
import { BoonTextField } from 'components/BoonInputs'

const AddSocialModal = ({
  active,
  onClose,
  values,
  setFieldValue,
  externallyChosenSocial,
}) => {
  const [chosenSocial, setChosenSocial] = useState(null)
  const [value, setValue] = useState('')

  useEffect(() => {
    if (externallyChosenSocial != null) {
      setChosenSocial(externallyChosenSocial)
    }
  }, [setChosenSocial, externallyChosenSocial])

  const handleClose = () => {
    setChosenSocial(null)
    setValue('')
    onClose()
  }

  const onSubmit = () => {
    if (!value) {
      return null
    }

    setFieldValue(chosenSocial, value)
    handleClose()
  }

  const renderSocialItems = () => {
    return SETUP_SOCIAL_ITEMS.map(({ key, name, icon: Icon }) => {
      let handle = values[key]
      if (!handle) {
        return (
          <div
            className="add-social-item"
            onClick={() => setChosenSocial(key)}
            key={key}
          >
            <div className="add-social-item-icon">
              <Icon />
            </div>
            <div className="add-social-item-details">
              <div className="add-social-item-name">{name}</div>
            </div>
          </div>
        )
      }

      return null
    })
  }

  const renderForm = () => {
    const chosenSocialObject = SETUP_SOCIAL_ITEMS.find(
      (o) => o.key === chosenSocial
    )

    return (
      <div className="add-social-form">
        <BoonTextField
          field={{
            value: value,
            name: chosenSocial,
            onChange: (e) => setValue(e.target.value),
            onKeyDown: (e) => {
              if (e.keyCode === 13 && e.shiftKey === false) {
                onSubmit()
              }
            },
          }}
          label={`${chosenSocialObject.name} Link`}
          helperText={
            chosenSocial !== 'homepageUrl'
              ? 'Write your SoMe URL to add a link to your profile.'
              : 'Write the URL of your website.'
          }
          placeholder={chosenSocialObject.placeholder}
        />

        <button
          className="button primary add-social-form-submit"
          onClick={onSubmit}
          disabled={!value}
        >
          Add link
        </button>
      </div>
    )
  }

  const renderContent = () => {
    if (chosenSocial) {
      return renderForm()
    } else {
      return <div className="add-social-list">{renderSocialItems()}</div>
    }
  }

  return (
    <NamedPinnedModal
      title="Social channel"
      active={active}
      onClose={handleClose}
    >
      <div className="AddSocialModal">{renderContent()}</div>
    </NamedPinnedModal>
  )
}

export default AddSocialModal
