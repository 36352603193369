import { getEventVisibility } from 'utils'
import { EVENT_VISIBILITY } from 'store/constants'
import { DateTime } from 'luxon'
import * as Yup from 'yup'
import { draftEventApi, publishEventApi, scheduleEventApi } from 'api'

export const visibilityInitialValues = (event) => {
  const defaultDate = DateTime.local()
    .plus({ days: 1 })
    .set({ minute: 0, second: 0 })
    .toJSDate()

  if (event) {
    return {
      visibility: getEventVisibility(event),
      publishAt: event.get('publishAt')
        ? new Date(event.get('publishAt'))
        : defaultDate,
    }
  } else {
    return {
      visibility: EVENT_VISIBILITY.DRAFT,
      publishAt: defaultDate,
    }
  }
}

export const visibilityValidations = {
  publishAt: Yup.mixed().when('visibility', {
    is: EVENT_VISIBILITY.SCHEDULED,
    then: Yup.date()
      .min(new Date(), 'Must be in the future')
      .required('Required'),
  }),
}

export const shouldPromptForPublish = (initialVisibility, nextVisibility) => {
  return (
    initialVisibility === EVENT_VISIBILITY.DRAFT &&
    nextVisibility !== EVENT_VISIBILITY.DRAFT &&
    initialVisibility !== nextVisibility
  )
}

export const updateEventVisibility = async (
  eventId,
  initialVisibility,
  nextVisibility,
  initialPublishAt,
  publishAt,
  notifyFollowers
) => {
  const data = {
    notifyFollowers,
    publishAt,
  }

  if (
    initialVisibility === EVENT_VISIBILITY.SCHEDULED &&
    nextVisibility === EVENT_VISIBILITY.SCHEDULED &&
    initialPublishAt !== publishAt
  ) {
    return scheduleEventApi({ eventId, data })
  }

  if (initialVisibility === nextVisibility) {
    return
  }

  // Update via API
  if (nextVisibility === EVENT_VISIBILITY.SCHEDULED) {
    return scheduleEventApi({ eventId, data })
  } else if (nextVisibility === EVENT_VISIBILITY.PUBLISHED) {
    return publishEventApi({ eventId, data })
  } else if (nextVisibility === EVENT_VISIBILITY.DRAFT) {
    return draftEventApi({ eventId })
  }
}

export const getVisibilitySubmitButtonText = (
  noun,
  edit,
  previousVisibility,
  visibility
) => {
  if (
    previousVisibility === EVENT_VISIBILITY.PUBLISHED ||
    (edit && previousVisibility === visibility)
  ) {
    return 'Save changes'
  } else {
    switch (visibility) {
      case EVENT_VISIBILITY.DRAFT:
        return 'Save draft'
      case EVENT_VISIBILITY.SCHEDULED:
        return `Schedule ${noun}`
      case EVENT_VISIBILITY.PUBLISHED:
        return `Publish ${noun}`
      default:
        return 'Save changes'
    }
  }
}
