import React from 'react'
import ContentLoader from 'react-content-loader'

const InsightsContentLoader = ({
  children,
  width,
  height,
  speed = 2,
  viewBox = `0 0 ${width} ${height}`,
  backgroundColor = '#e0e0e0',
  foregroundColor = '#cccccc',
  testId = 'content-loader',
}) => {
  return (
    <ContentLoader
      width={width}
      height={height}
      speed={speed}
      viewBox={viewBox}
      backgroundColor={backgroundColor}
      foregroundColor={foregroundColor}
      data-testid={testId}
    >
      {children}
    </ContentLoader>
  )
}

export default InsightsContentLoader
