import React from 'react'
import './PostCardReason.scss'
import { getFormattedActivityDate } from 'utils'

const PostCardReason = ({ post }) => {
  const reason = post.get('lastActivityReason')
  if (reason == null) {
    return null
  }

  const date = getFormattedActivityDate(post.get('lastActivityAt'))

  return (
    <div className="PostCardReason">
      {reason} {date}
    </div>
  )
}

export default PostCardReason
