import { useCallback } from 'react'
import { INSIGHTS_PERIODS } from 'routes/InsightsPage/insightsConstants'
import { DateTime } from 'luxon'
import { getCurrencySymbol } from 'utils'

export const getInsightsQueryObject = (period, productsFilter) => {
  return {
    resolution: period === INSIGHTS_PERIODS.THIRTY_DAYS ? 'day' : 'month',
    from_date:
      period === INSIGHTS_PERIODS.THIRTY_DAYS
        ? DateTime.utc().minus({ days: 30 }).toISODate()
        : undefined,
    product_id: productsFilter != null ? productsFilter : undefined,
  }
}

export const currencyPrefixDataMapper = (data) =>
  `${getCurrencySymbol(data.currency)}`

export const usePrefixedChartDataLabelMapper = (prefix) =>
  useCallback((datum) => `${prefix} ${datum}`, [prefix])

export const genericChartDataMapper = (data) =>
  data.map((h) => ({ x: new Date(h[0]), y: h[1] }))
