import { useCallback, useMemo, useRef, useState } from 'react'
import { useInterval, uuidv4 } from 'utils'
import axios from 'axios'
import { useUnmount } from 'react-use'

export const useVideoPlayStats = ({
  viewerId,
  hostId,
  eventId,
  videoUrl,
  live,
  disabled,
}) => {
  const playerRef = useRef(null)
  const viewId = useMemo(() => uuidv4(), [])
  const lastTime = useRef(0)
  const [started, setStarted] = useState(false)

  const sendData = useCallback(
    async (data) => {
      if (disabled) {
        return null
      }

      try {
        console.log('Sending stats: ', data)
        await axios.post('/api/videoevents', { events: [data] })
      } catch (e) {
        // Pass
      }
    },
    [disabled]
  )

  const onPlay = useCallback(
    (e, player) => {
      if (started) {
        return null
      }

      playerRef.current = player
      setStarted(true)

      sendData({
        type: 'video_play_started',
        live,
        viewId,
        viewerId,
        hostId,
        eventId,
        videoUrl,
        userAgent: navigator.userAgent,
      })
    },
    [viewerId, hostId, eventId, videoUrl, live, sendData, viewId, started]
  )

  useInterval(
    () => {
      if (playerRef.current == null) {
        return null
      }

      try {
        const time = playerRef.current.currentTime()
        if (time !== lastTime.current) {
          sendData({
            type: 'video_play_progress',
            viewId,
            progress: Math.round(time),
          })
        }

        lastTime.current = time
      } catch (e) {
        // Pass
      }
    },
    started ? 10_000 : null
  )
  useUnmount(() => {
    if (started) {
      if (playerRef.current == null) {
        return null
      }

      try {
        const time = playerRef.current.currentTime()
        sendData({
          type: 'video_play_progress',
          viewId,
          progress: Math.round(time),
        })
      } catch (e) {
        // Pass
      }
    }
  })

  return { onPlay }
}
