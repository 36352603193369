import React from 'react'
import clsx from 'clsx'
import './HeaderMenu.scss'
import { useOutsideAlerter } from 'utils'
import HeaderMenuContent from './HeaderMenuContent'

const HeaderMenu = ({ isOpen, setIsOpen, menuRef }) => {
  useOutsideAlerter(menuRef, isOpen, () => {
    setIsOpen(false)
  })

  return (
    <div className={clsx('HeaderMenu', { open: isOpen })}>
      <div className="header-menu-content" onClick={() => setIsOpen(false)}>
        <HeaderMenuContent creatorMenu />
      </div>
    </div>
  )
}

export default HeaderMenu
