import React from 'react'
import './ProfileCompletion.scss'
import { useFormikContext } from 'formik'
import { CircleOutlineCheckedSvg, CircleOutlineSvg } from 'assets/svg'
import { fromJS } from 'immutable'
import { useSelector } from 'react-redux'
import { getMe } from 'store/selectors'
import { URL_CREATOR_SETUP_PAYOUT, URL_CREATOR_SETUP_PROFILE } from 'urls'
import { HashLink } from 'react-router-hash-link'
import { useLocation } from 'react-router-dom'
import clsx from 'clsx'
import { scrollWithOffset } from 'utils'

export const ProfileCompletionCriteria = [
  [
    'Set your page name',
    ({ user, errors }) => errors.name == null && user.get('name')?.length > 0,
    URL_CREATOR_SETUP_PROFILE,
    'profileName',
  ],
  [
    'Choose a page URL',
    ({ user, errors }) =>
      errors.username == null && user.get('username')?.length > 0,
    URL_CREATOR_SETUP_PROFILE,
    'profileUsername',
  ],
  [
    'Upload a profile photo',
    ({ user }) =>
      user.get('profileImageUrl')?.length > 0 &&
      user.get('profileImageUrl') !==
        'https://s3.eu-central-1.amazonaws.com/boon-email.prod/img/avatar-placeholder.png',
    URL_CREATOR_SETUP_PROFILE,
    'profilePhoto',
  ],
  [
    'Upload a cover photo',
    ({ user }) => user.get('coverImageUrl')?.length > 0,
    URL_CREATOR_SETUP_PROFILE,
    'profileCover',
  ],

  [
    'Add headline',
    ({ user, errors }) => errors.title == null && user.get('title')?.length > 0,
    URL_CREATOR_SETUP_PROFILE,
    'profileTitle',
  ],
  [
    'Add description',
    ({ user }) => user.get('bio')?.length > 0,
    URL_CREATOR_SETUP_PROFILE,
    'profileDescription',
  ],

  [
    'Set default currency',
    ({ user }) => user.get('currency') != null,
    URL_CREATOR_SETUP_PROFILE,
    null,
  ],

  [
    'Set up Payout Account',
    ({ me }) => me.get('hasPayoutAccount') === true,
    URL_CREATOR_SETUP_PAYOUT,
    null,
  ],
]

const ProfileCompletion = ({ user, triedLaunching }) => {
  const { pathname } = useLocation()
  const me = useSelector((state) => getMe(state))
  const context = useFormikContext()
  if (context?.values) {
    user = user.merge(fromJS(context?.values))
  }
  const errors = context?.errors ?? {}

  const renderCriteria = () => {
    return ProfileCompletionCriteria.map(([name, validation, url, anchor]) => {
      const result = validation({ user, errors, me })

      if (result) {
        return (
          <div
            className="profile-completion-criteria profile-completion-criteria-checked"
            key={name + 'checked'}
          >
            <div classname="profile-completion-criteria-header">
              <CircleOutlineCheckedSvg />
              {name}
            </div>
          </div>
        )
      } else {
        return (
          <div className="profile-completion-criteria" key={name}>
            <div classname="profile-completion-criteria-header">
              <CircleOutlineSvg />
              {name}
            </div>
            <HashLink
              scroll={scrollWithOffset(80)}
              to={anchor ? `${url === pathname ? '' : url}#${anchor}` : url}
              className="profile-completion-criteria-action"
            >
              Add now
            </HashLink>
          </div>
        )
      }
    })
  }

  return (
    <div
      className={clsx('ProfileCompletion', {
        'tried-launching': triedLaunching,
      })}
    >
      <div className="profile-completion-section">
        <div className="profile-completion-section-header">
          Build your profile
        </div>
        <div className="profile-completion-criteria-list">
          {renderCriteria()}
        </div>
      </div>
    </div>
  )
}

export default ProfileCompletion
