import React, { Component, Suspense, useEffect } from 'react'
import {
  BrowserRouter,
  Route,
  Switch,
  useHistory,
  useLocation,
  withRouter,
} from 'react-router-dom'
import { connect, useDispatch, useSelector } from 'react-redux'
import Header from 'components/Header'
import { ENVIRONMENT, ENVIRONMENTS } from 'appConstants'
import ReactGA from 'react-ga4'
import * as Sentry from '@sentry/browser'
import AuthModal from 'modals/AuthModal'
import { getMe, isAuthenticated, isHydrated } from 'store/selectors'
import Footer from 'components/Footer'
import ResetPasswordPage from 'routes/ResetPasswordPage'
import EventEditPage from 'routes/EventEditPage'
import ForgotPasswordPage from 'routes/ForgotPasswordPage'
import SignUpPage from 'routes/SignUpPage'
import SignInPage from 'routes/SignInPage'
import NotFoundPage from 'routes/NotFoundPage'
import UserPage from 'routes/UserPage'
import SchedulePage from 'routes/SchedulePage'
import EventPage from 'routes/EventPage'
import AboutPage from 'routes/AboutPage'
import SettingsPage from 'routes/SettingsPage'
import FacebookRedirectPage from 'routes/FacebookRedirectPage'
import CreatorPage from 'routes/CreatorPage'
import CreatorSetupPage from 'routes/CreatorSetupPage'
import { getMeObject, useGetEventApi, useGetUserApi } from 'api'
import { getEventUrl, getUserUrl } from 'utils'
import {
  URL_CREATOR_SETTINGS,
  URL_CREATOR_SETTINGS_MEMBERSHIP,
  URL_CREATOR_SETTINGS_CATEGORIES,
  URL_CREATOR_SETTINGS_PAYOUT,
  URL_CREATOR_SETTINGS_PROFILE,
  URL_CREATOR_SETTINGS_COUPONS,
  URL_CREATOR_SETUP,
  URL_CREATOR_SETUP_MEMBERSHIP,
  URL_CREATOR_SETUP_PAYOUT,
  URL_CREATOR_SETUP_PROFILE,
  URL_EVENT,
  URL_EVENT_INSIGHTS_OVERVIEW,
  URL_EVENT_INSIGHTS_OVERVIEW_ROUTE,
  URL_EVENT_INSIGHTS_SALES_ROUTE,
  URL_EVENT_INSIGHTS_VIEWS_ROUTE,
  URL_EXTERNAL_HELP,
  URL_EXTERNAL_NET_CREATOR_REVENUE,
  URL_EXTERNAL_PRIVACY_POLICY,
  URL_EXTERNAL_TERMS_AND_CONDITIONS,
  URL_FEATURE_COMMUNITY,
  URL_FEATURE_INSIGHTS,
  URL_FEATURE_MEMBERSHIP,
  URL_FEATURE_PAGE,
  URL_FEATURE_PAYOUT,
  URL_FEATURE_VIDEO,
  URL_INSIGHTS_INCOME,
  URL_INSIGHTS_MEMBERS,
  URL_INSIGHTS_OVERVIEW,
  URL_INSIGHTS_VIDEOS,
  URL_INVOICES,
  URL_LEAD_QUALIFICATION,
  URL_LEAD_QUALIFICATION_CATEGORIES,
  URL_LEAD_QUALIFICATION_PROFILE,
  URL_LEAD_QUALIFICATION_SOCIAL,
  URL_NOT_FOUND,
} from 'urls'
import CreatorSignUpPage from 'routes/CreatorSignUpPage'
import SubscribePage from 'routes/SubscribePage'
import FrontPage from 'routes/FrontPage'
import Spinner from 'components/Spinner'
import ManageSubscriptionsPage from 'routes/ManageSubscriptionsPage/ManageSubscriptionsPage'
import StripeActivateAccountPage from 'routes/StripeActivateAccountPage'
import UploadPage from 'routes/UploadPage/UploadPage'
import EventCheckoutPage from 'routes/EventCheckoutPage/EventCheckoutPage'
import LoadingError from 'components/LoadingError'
import SudoPage from 'routes/SudoPage'
import ContactPage from 'routes/ContactPage'
import AccountingPage from 'routes/AccountingPage/AccountingPage'
import PayoutPage from 'routes/AccountingPage/PayoutPage'
import NewPayoutPage from 'routes/AccountingPage/NewPayoutPage'
import CustomerChat from 'components/CustomerChat'
import CreatorSettingsPage from 'routes/CreatorSettingsPage'
import UserDashboard from 'routes/UserDashboard'
import { useMount } from 'react-use'
import { getIsAuthenticated } from 'store/utils/awaitStore'
import { updateMe } from 'store/actions'
import PurchasesPage from 'routes/PurchasesPage'
import LeadQualificationPage from 'routes/LeadQualificationPage'
import InvoicesPage from 'routes/InvoicesPage'
import UpdateSubscriptionPaymentPage from 'routes/UpdateSubscriptionPaymentPage'
import TestSignInPage from 'routes/TestSignInPage'
import { useToasts } from 'react-toast-notifications'
import InsightsPage from 'routes/InsightsPage'
import { ReactComponent as BoonCircularCircleClipPath } from 'assets/svg/CircularSquareSvg.svg'
import PageFeaturePage from 'routes/FeaturePages/PageFeaturePage'
import MembershipFeaturePage from 'routes/FeaturePages/MembershipFeaturePage'
import VideoFeaturePage from 'routes/FeaturePages/VideoFeaturePage'
import CommunityFeaturePage from 'routes/FeaturePages/CommunityFeaturePage'
import InsightsFeaturePage from 'routes/FeaturePages/InsightsFeaturePage'
import PayoutFeaturePage from 'routes/FeaturePages/PayoutFeaturePage'
import InviteFriendsPage from 'routes/InviteFriendsPage/InviteFriendsPage'
import CheckoutSuccessPage from 'routes/CheckoutSuccessPage'

const ScrollToTop = withRouter(({ children, location: { pathname } }) => {
  const location = useLocation()
  // Each page change
  useEffect(() => {
    if (!location?.state?.stayScrolled) {
      window.scrollTo(0, 0)
    }
  }, [pathname, location])

  return children
})

const Tracking = withRouter(({ children, location: { pathname } }) => {
  const hasAuth = useSelector((state) => isAuthenticated(state))
  const me = useSelector((state) => getMe(state))

  useEffect(() => {
    Sentry.configureScope((scope) => {
      if (hasAuth) {
        scope.setUser({
          id: me.get('id'),
          name: me.get('name'),
        })
      } else {
        scope.setUser({})
      }
    })

    if (ENVIRONMENT === ENVIRONMENTS.PRODUCTION && hasAuth) {
      ReactGA.set({ userId: me.get('id') })
    }

    /*
    // Canny
    try {
      if (window.Canny != null) {
        if (hasAuth) {
          window.Canny('identify', {
            appID: '5fc8de815ecde00cc66b6a9a',
            user: {
              // Replace these values with the current user's data
              email: me.get('email'),
              name: me.get('name'),
              id: me.get('id'),

              // These fields are optional, but recommended:
              avatarURL: me.get('profileImageUrl'),
              created: me.get('createdAt'),
            },
          })
        } else {
          window.Canny('identify', {
            appID: '5fc8de815ecde00cc66b6a9a',
            user: null,
          })
        }
      }
    } catch (e) {
      console.error(e)
    }
    */
  }, [hasAuth, me])

  useEffect(() => {
    if (ENVIRONMENT === ENVIRONMENTS.PRODUCTION) {
      const path = window.location.pathname + window.location.search
      ReactGA.send({ hitType: 'pageview', page: path })
    }
  }, [pathname])

  return children
})

const redirectUrlBuilder = (url) => {
  return withRouter(({ history, match }) => {
    history.replace(typeof url === 'function' ? url(match.params) : url)
    return null
  })
}

const redirectUrlExternalBuilder = (url) => () => {
  window.location = url
  return null
}

const WatchRedirect = ({ match }) => {
  const history = useHistory()
  history.replace(URL_EVENT(match.params.userSlug, match.params.eventSlug))
  return null
}

const RedirectEvent = ({ match }) => {
  const history = useHistory()
  const { data: event, error, refresh } = useGetEventApi({
    eventId: match.params.id,
  })

  if (event != null) {
    history.replace(getEventUrl(event))
  }

  if (error) {
    if (error?.response?.status === 404) {
      history.replace(URL_NOT_FOUND)
    } else {
      return <LoadingError refresh={refresh} />
    }
  }

  return <Spinner light />
}

const RedirectUser = ({ match }) => {
  const history = useHistory()
  const { data: user, error, refresh } = useGetUserApi({
    userId: match.params.id,
  })

  if (user != null) {
    history.replace(getUserUrl(user))
  }

  if (error) {
    if (error?.response?.status === 404) {
      history.replace(URL_NOT_FOUND)
    } else {
      return <LoadingError refresh={refresh} />
    }
  }

  return <Spinner light />
}

const InitialFetch = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const isAuthenticated = useSelector((state) => getIsAuthenticated(state))

  useMount(async () => {
    if (
      isAuthenticated &&
      location.pathname !== '/sudo' &&
      location.pathname !== '/test-sign-in'
    ) {
      try {
        const apiMe = await getMeObject()
        dispatch(updateMe(apiMe))
      } catch (e) {
        addToast(
          'We were unable to load some information, check your internet connection',
          { appearance: 'error' }
        )
      }
    }
  })

  return null
}
class App extends Component {
  render() {
    if (!this.props.isHydrated) {
      return null
    }

    return (
      <BrowserRouter>
        <ScrollToTop>
          <Tracking>
            <InitialFetch />
            <div className="App">
              <Header />
              <Suspense fallback={<Spinner light />}>
                <Switch>
                  <Route
                    path="/facebook-redirect"
                    exact
                    component={FacebookRedirectPage}
                  />
                  <Route path="/" exact component={FrontPage} />

                  <Route
                    path="/creator"
                    exact
                    render={redirectUrlBuilder('/')}
                  />
                  <Route
                    path="/fitness"
                    exact
                    render={redirectUrlBuilder('/')}
                  />
                  <Route
                    path="/craftsmanship"
                    exact
                    render={redirectUrlBuilder('/')}
                  />
                  <Route
                    path="/coaching"
                    exact
                    render={redirectUrlBuilder('/')}
                  />
                  <Route path="/music" exact render={redirectUrlBuilder('/')} />
                  <Route path="/acast" exact render={redirectUrlBuilder('/')} />
                  <Route path="/explore" render={redirectUrlBuilder('/')} />

                  {/* Product feature pages */}
                  {/*
                  <Route
                    path={URL_FEATURE_PAGE}
                    exact
                    component={PageFeaturePage}
                  />
                  <Route
                    path={URL_FEATURE_MEMBERSHIP}
                    exact
                    component={MembershipFeaturePage}
                  />
                  <Route
                    path={URL_FEATURE_VIDEO}
                    exact
                    component={VideoFeaturePage}
                  />
                  <Route
                    path={URL_FEATURE_COMMUNITY}
                    exact
                    component={CommunityFeaturePage}
                  />
                  <Route
                    path={URL_FEATURE_INSIGHTS}
                    exact
                    component={InsightsFeaturePage}
                  />
                  <Route
                    path={URL_FEATURE_PAYOUT}
                    exact
                    component={PayoutFeaturePage}
                  />
                  */}
                  <Route path="/following" exact component={UserDashboard} />
                  <Route path="/purchases" exact component={PurchasesPage} />
                  <Route path={URL_INVOICES} exact component={InvoicesPage} />
                  <Route
                    path="/help"
                    exact
                    render={redirectUrlExternalBuilder(URL_EXTERNAL_HELP)}
                  />
                  <Route
                    path={URL_CREATOR_SETUP}
                    exact
                    component={CreatorSetupPage}
                  />
                  <Route
                    path={URL_CREATOR_SETUP_PROFILE}
                    exact
                    component={CreatorSetupPage}
                  />
                  <Route
                    path={URL_CREATOR_SETUP_PAYOUT}
                    exact
                    component={CreatorSetupPage}
                  />
                  <Route
                    path={URL_CREATOR_SETUP_MEMBERSHIP}
                    exact
                    component={CreatorSetupPage}
                  />
                  <Route
                    path="/creator/sign-up"
                    exact
                    component={CreatorSignUpPage}
                  />
                  <Route path="/about" exact component={AboutPage} />
                  <Route path="/contact" exact component={ContactPage} />
                  <Route path="/support" exact component={ContactPage} />
                  <Route
                    path="/terms"
                    exact
                    render={redirectUrlExternalBuilder(
                      URL_EXTERNAL_TERMS_AND_CONDITIONS
                    )}
                  />
                  <Route
                    path="/privacy"
                    exact
                    render={redirectUrlExternalBuilder(
                      URL_EXTERNAL_PRIVACY_POLICY
                    )}
                  />
                  <Route
                    path="/referral-program"
                    exact
                    render={redirectUrlExternalBuilder(
                      URL_EXTERNAL_PRIVACY_POLICY
                    )}
                  />
                  <Route
                    path="/net-creator-revenue"
                    exact
                    render={redirectUrlExternalBuilder(
                      URL_EXTERNAL_NET_CREATOR_REVENUE
                    )}
                  />
                  <Route path="/upload" exact component={UploadPage} />
                  <Route
                    path="/stripe-activate-account"
                    exact
                    component={StripeActivateAccountPage}
                  />
                  <Route path="/accounting" exact component={AccountingPage} />
                  <Route
                    path="/accounting/:payoutId"
                    exact
                    component={PayoutPage}
                  />
                  <Route
                    path="/accounting/new/:payoutId"
                    exact
                    component={NewPayoutPage}
                  />
                  <Route path="/settings" exact component={SettingsPage} />
                  <Route
                    path="/settings/profile"
                    exact
                    component={SettingsPage}
                  />
                  <Route
                    path="/settings/business"
                    exact
                    component={SettingsPage}
                  />
                  <Route
                    path="/settings/account"
                    exact
                    component={SettingsPage}
                  />
                  <Route
                    path="/settings/notifications"
                    exact
                    component={SettingsPage}
                  />
                  <Route
                    path="/settings/members"
                    exact
                    component={SettingsPage}
                  />
                  <Route
                    path={URL_CREATOR_SETTINGS}
                    exact
                    component={CreatorSettingsPage}
                  />
                  <Route
                    path={URL_CREATOR_SETTINGS_PROFILE}
                    exact
                    component={CreatorSettingsPage}
                  />
                  <Route
                    path={URL_CREATOR_SETTINGS_PAYOUT}
                    exact
                    component={CreatorSettingsPage}
                  />
                  <Route
                    path={URL_CREATOR_SETTINGS_MEMBERSHIP}
                    exact
                    component={CreatorSettingsPage}
                  />
                  <Route
                    path={URL_CREATOR_SETTINGS_CATEGORIES}
                    exact
                    component={CreatorSettingsPage}
                  />
                  <Route
                    path={URL_CREATOR_SETTINGS_COUPONS}
                    exact
                    component={CreatorSettingsPage}
                  />
                  <Route
                    path={URL_LEAD_QUALIFICATION}
                    exact
                    component={LeadQualificationPage}
                  />
                  <Route
                    path={URL_LEAD_QUALIFICATION_CATEGORIES}
                    exact
                    component={LeadQualificationPage}
                  />
                  <Route
                    path={URL_LEAD_QUALIFICATION_SOCIAL}
                    exact
                    component={LeadQualificationPage}
                  />
                  <Route
                    path={URL_LEAD_QUALIFICATION_PROFILE}
                    exact
                    component={LeadQualificationPage}
                  />
                  <Route
                    path={URL_INSIGHTS_MEMBERS}
                    exact
                    component={InsightsPage}
                  />
                  <Route
                    path={URL_INSIGHTS_INCOME}
                    exact
                    component={InsightsPage}
                  />
                  <Route
                    path={URL_INSIGHTS_VIDEOS}
                    exact
                    component={InsightsPage}
                  />
                  <Route
                    path={URL_INSIGHTS_OVERVIEW}
                    component={InsightsPage}
                  />
                  <Route
                    path="/user/287"
                    render={redirectUrlBuilder(
                      '/user/c8ee59d9-8475-4eef-b722-1f38d78942fb'
                    )}
                  />
                  <Route
                    path="/dashboard"
                    render={redirectUrlBuilder('/insights')}
                  />
                  <Route
                    path="/relations"
                    render={redirectUrlBuilder('/insights/members')}
                  />
                  <Route
                    path="/settings/members"
                    render={redirectUrlBuilder('/insights/members')}
                  />
                  <Route path="/schedule" exact component={SchedulePage} />
                  <Route path="/reset-password" component={ResetPasswordPage} />
                  <Route
                    path="/forgot-password"
                    component={ForgotPasswordPage}
                  />
                  <Route path="/sign-up" component={SignUpPage} />
                  <Route path="/sign-in" component={SignInPage} />
                  <Route
                    path="/manage-subscriptions"
                    component={ManageSubscriptionsPage}
                  />
                  <Route path="/invite-friends" component={InviteFriendsPage} />
                  <Route path="/sudo" component={SudoPage} />
                  <Route path="/test-sign-in" component={TestSignInPage} />
                  <Route path="/404" exact component={NotFoundPage} />
                  <Route path="/event/:id" exact component={RedirectEvent} />
                  <Route
                    path="/event/:id/watch"
                    exact
                    component={RedirectEvent}
                  />
                  <Route path="/user/:id" exact component={RedirectUser} />
                  <Route path="/:userSlug" exact component={UserPage} />
                  <Route
                    path="/:userSlug/nav/:tab"
                    exact
                    component={UserPage}
                  />
                  <Route
                    path="/:userSlug/host-videos"
                    exact
                    component={UserPage}
                  />
                  <Route path="/:userSlug/about" exact component={UserPage} />
                  <Route
                    path="/:userSlug/offerings"
                    exact
                    component={UserPage}
                  />
                  <Route
                    path="/:userSlug/categories/:categorySlug"
                    exact
                    component={UserPage}
                  />
                  <Route
                    path="/:userSlug/attachments/:attachmentType"
                    exact
                    component={UserPage}
                  />
                  <Route
                    path="/:userSlug/posts/:postId"
                    exact
                    component={UserPage}
                  />
                  <Route
                    path="/:userSlug/posts/:postId/comments/:commentId"
                    exact
                    component={UserPage}
                  />
                  <Route
                    path="/:userSlug/conversations"
                    exact
                    component={UserPage}
                  />
                  <Route
                    path="/:userSlug/conversations/:topicId"
                    exact
                    component={UserPage}
                  />
                  <Route
                    path="/:userSlug/subscribe/:planId"
                    exact
                    component={SubscribePage}
                  />
                  <Route
                    path="/:userSlug/subscribe/:planId/success"
                    exact
                    component={CheckoutSuccessPage}
                  />
                  <Route
                    path="/:userSlug/update-subscription-payment/:subscriptionId"
                    exact
                    component={UpdateSubscriptionPaymentPage}
                  />
                  <Route
                    path="/:userSlug/inv/:inviteCode"
                    exact
                    component={UserPage}
                  />
                  <Route
                    path="/:userSlug/:eventSlug"
                    exact
                    component={EventPage}
                  />
                  <Route
                    path="/:userSlug/:eventSlug/checkout"
                    exact
                    component={EventCheckoutPage}
                  />
                  <Route
                    path="/:userSlug/:eventSlug/checkout/success"
                    exact
                    component={CheckoutSuccessPage}
                  />
                  <Route
                    path="/:userSlug/:eventSlug/edit"
                    exact
                    component={EventEditPage}
                  />
                  <Route
                    path="/:userSlug/:eventSlug/overview"
                    exact
                    render={redirectUrlBuilder(({ userSlug, eventSlug }) =>
                      URL_EVENT_INSIGHTS_OVERVIEW(userSlug, eventSlug)
                    )}
                  />
                  <Route
                    path="/:userSlug/:eventSlug/watch"
                    exact
                    component={WatchRedirect}
                  />
                  <Route
                    path={URL_EVENT_INSIGHTS_OVERVIEW_ROUTE}
                    exact
                    component={InsightsPage}
                  />
                  <Route
                    path={URL_EVENT_INSIGHTS_SALES_ROUTE}
                    exact
                    component={InsightsPage}
                  />
                  <Route
                    path={URL_EVENT_INSIGHTS_VIEWS_ROUTE}
                    exact
                    component={InsightsPage}
                  />
                  <Route component={NotFoundPage} />
                </Switch>
              </Suspense>
              <AuthModal />
              <CustomerChat />
            </div>
            <Footer />
            <BoonCircularCircleClipPath />
          </Tracking>
        </ScrollToTop>
      </BrowserRouter>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    isHydrated: isHydrated(state),
  }
}

export default connect(mapStateToProps)(App)
