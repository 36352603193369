import React, { useEffect, useState } from 'react'
import { NarrowContainer } from 'components/Grid'
import { useMount } from 'react-use'
import {
  URL_CREATOR,
  URL_CREATOR_SETUP_PROFILE,
  URL_CREATOR_SETUP_PAYOUT,
  URL_CREATOR_SETUP_MEMBERSHIP,
  URL_FRONT_PAGE,
} from 'urls'
import { useSelector } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { isAuthenticated, getMe } from 'store/selectors'
import { CREATOR_STATUS, PROFILE_STATUS } from 'store/constants'
import { getUserUrl } from 'utils'
import { useGetUserApi, launchProfileApi, getMeObject } from 'api'
import './CreatorSetupPage.scss'
import Spinner from 'components/Spinner'
import CreatorSetupProfile from './CreatorSetupProfile'
import CreatorSetupPayout from './CreatorSetupPayout'
import CreatorSetupMenu from './CreatorSetupMenu'
import CreatorSetupMembership from './CreatorSetupMembership'
import { ProfileCompletionCriteria } from './ProfileCompletion'
import { Link } from 'react-router-dom'
import { updateMeStore } from 'store/utils/auth'
import { trackCreatorLaunchPage } from 'tracking'

const CreatorSetupPage = () => {
  const { path } = useRouteMatch()
  const history = useHistory()
  const hasAuth = useSelector((state) => isAuthenticated(state))
  const me = useSelector((state) => getMe(state))
  const browser = useSelector((state) => state.get('browser'))
  const [triedLaunching, setTriedLaunching] = useState(false)
  const { data: user, setMappedData: setUserData } = useGetUserApi({
    userId: me.get('id'),
  })
  const launchable =
    user != null &&
    ProfileCompletionCriteria.every(([, validation]) =>
      validation({ user, me, errors: {} })
    )

  useMount(() => {
    if (!hasAuth) {
      history.push(URL_CREATOR)
    }
  })

  useEffect(() => {
    if (user != null) {
      if (
        user.get('creatorStatus') === CREATOR_STATUS.CREATOR &&
        user.get('profileStatus') === PROFILE_STATUS.PUBLISHED
      ) {
        history.replace(getUserUrl(user))
      }
    }
  }, [user, history])

  const onLaunch = async () => {
    setTriedLaunching(true)

    if (launchable) {
      await launchProfileApi()

      const newMe = await getMeObject()
      updateMeStore({ me: newMe })
      history.push(URL_FRONT_PAGE)
      trackCreatorLaunchPage()
    } else {
      try {
        const el = document.querySelector('.ProfileCompletion')
        el.setAttribute('highlight', true)

        setTimeout(() => {
          el.removeAttribute('highlight')
        }, 1000)

        if (browser.lessThan.xl) {
          el.scrollIntoView({ behavior: 'smooth' })
        }
      } catch (e) {
        alert('Not all steps are completed, please see the checklist')
      }
    }
  }

  if (user == null) {
    return <Spinner light />
  }

  const renderPage = () => {
    switch (path) {
      case URL_CREATOR_SETUP_PROFILE:
        return (
          <CreatorSetupProfile
            user={user}
            triedLaunching={triedLaunching}
            setUserData={setUserData}
          />
        )
      case URL_CREATOR_SETUP_PAYOUT:
        return (
          <CreatorSetupPayout user={user} triedLaunching={triedLaunching} />
        )
      case URL_CREATOR_SETUP_MEMBERSHIP:
        return (
          <CreatorSetupMembership user={user} triedLaunching={triedLaunching} />
        )
      default:
        history.replace(URL_CREATOR_SETUP_PROFILE)
        return null
    }
  }

  return (
    <div className="CreatorSetupPage">
      <NarrowContainer>
        <CreatorSetupMenu>
          <div className="creator-setup-page-actions">
            <Link to={getUserUrl(user)} className="button secondary small">
              Preview page
            </Link>
            <button className="button primary small" onClick={onLaunch}>
              Launch page
            </button>
          </div>
        </CreatorSetupMenu>
        {renderPage()}
      </NarrowContainer>
    </div>
  )
}

export default CreatorSetupPage
