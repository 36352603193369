import React, { useState } from 'react'
import 'modals/LiveGuideModal/LiveGuideModal.scss'
import AnimatedModal from 'components/AnimatedModal'
import CloseSvg from 'assets/svg/CloseSvg'
import { URL_APP_ANDROID, URL_APP_IOS, URL_OBS_DOWNLOAD } from 'urls'
import { Image } from 'components/Image'
import DownloadOnAppStore from 'assets/img/creator-page/DownloadOnAppStore.png'
import DownloadOnGooglePlay from 'assets/img/creator-page/DownloadOnGooglePlay.png'
import clsx from 'clsx'
import ToggleButton from 'components/ToggleButton'
import { DownloadSvg } from 'assets/svg'
import { useGetEventApi } from 'api'
import Spinner from 'components/Spinner'

const LiveGuideModal = ({ active, onClose, eventId }) => {
  const { data: event } = useGetEventApi({ eventId })
  const [advanced, setAdvanced] = useState(false)

  const renderAdvanced = () => {
    if (!advanced) {
      return null
    }

    if (event == null) {
      return <Spinner small />
    }

    return (
      <div className="live-guide-modal-advanced">
        <div className="live-guide-modal-description">
          For advanced produtions, you need OBS streaming software. Stream with
          multiple cameras and more.
        </div>
        <a
          className="live-guide-modal-textbutton"
          href={URL_OBS_DOWNLOAD}
          target="_blank"
          rel="noopener noreferrer"
        >
          <DownloadSvg /> Get OBS streaming software
        </a>
        <div className="live-guide-modal-advanced-keys">
          <div className="live-guide-modal-advanced-keys-key">
            <span className="live-guide-modal-advanced-keys-key-header">
              Server:
            </span>
            {event.getIn(['liveStream', 'primaryServer'])}
          </div>
          <div className="live-guide-modal-advanced-keys-key">
            <span className="live-guide-modal-advanced-keys-key-header">
              Stream key:
            </span>
            {event.getIn(['liveStream', 'streamName'])}
          </div>
        </div>
      </div>
    )
  }

  return (
    <AnimatedModal
      active={active}
      onClose={onClose}
      overlay
      className="LiveGuideModal"
    >
      <div className="live-guide-modal-top">
        <div className="live-guide-modal-header">
          <div className="live-guide-modal-header-title">How to go live</div>
          <CloseSvg
            className="live-guide-modal-header-close"
            onClick={onClose}
          />
        </div>
        <div className="live-guide-modal-description">
          Download our mobile app to live stream your event using your
          smartphone as camera. Get it now!
        </div>
        <div className="live-guide-modal-apps">
          <a href={URL_APP_IOS} target="_blank" rel="noopener noreferrer">
            <Image src={DownloadOnAppStore} />
          </a>
          <a href={URL_APP_ANDROID} target="_blank" rel="noopener noreferrer">
            <Image src={DownloadOnGooglePlay} />
          </a>
        </div>
        <div className="live-guide-modal-toggle-advanced">
          <div
            className={clsx('live-guide-modal-toggle-advanced-label', {
              active: advanced,
            })}
            onClick={(e) => {
              e.stopPropagation()
              setAdvanced(!advanced)
            }}
          >
            Advanced
          </div>
          <ToggleButton value={advanced} onChange={setAdvanced} />
        </div>
      </div>
      {renderAdvanced()}
    </AnimatedModal>
  )
}

export default LiveGuideModal
