import React from 'react'
import './UserAboutPage.scss'
import { getMuxThumbnail } from 'utils'
import ReactPlayer from 'react-player'
import clsx from 'clsx'
import UserSocial from './UserSocial'
import LimitedReactMarkdown from 'components/LimitedReactMarkdown'

const UserAboutPage = ({ user }) => {
  const videoUrl = user.get('coverVideoUrl')
  const poster = getMuxThumbnail(videoUrl)
  const hasVideo = videoUrl != null

  const renderMedia = () => {
    return (
      <ReactPlayer
        url={videoUrl}
        width="100%"
        height="auto"
        playing
        controls
        playsinline
        config={{ file: { attributes: { poster } } }}
        className="user-about-box-video"
      />
    )
  }

  return (
    <div className="UserAboutPage">
      {hasVideo && <div className="user-about-box-media">{renderMedia()}</div>}
      <div
        className={clsx('user-about-box-container', { 'no-video': !hasVideo })}
      >
        <div className="user-about-box-info">
          <div className="user-about-box-description">
            <LimitedReactMarkdown>{user.get('bio')}</LimitedReactMarkdown>
          </div>
          <UserSocial user={user} />
        </div>
      </div>
    </div>
  )
}

export default UserAboutPage
