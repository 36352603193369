import React from 'react'
import './PlayButton.scss'
import { PlayRoundedSvg } from 'assets/svg'
import clsx from 'clsx'

const PlayButton = ({ className, ...props }) => {
  return (
    <div className={clsx('PlayButton', className)} {...props}>
      <PlayRoundedSvg />
    </div>
  )
}

export default PlayButton
