import React, { useState } from 'react'
import './PostCardMessage.scss'
import Linkify from 'components/Linkify'
import { POST_ACCESS } from 'store/constants/post'
import UnlockButton from 'components/UnlockButton'

const PostCardMessage = ({ post, onNoAccess }) => {
  const MESSAGE_TRIM_LENGTH = 400
  const hasAccess = post.get('access') !== POST_ACCESS.READ
  const message = post.get('message')

  const [showMore, setShowMore] = useState(false)
  const [scrollPosition, setScrollPosition] = useState(0)

  if (!hasAccess && message == null && post.get('attachmentType') == null) {
    return (
      <div className="PostCardMessage">
        <div className="post-card-message-hidden">
          <div
            className="post-card-message-hidden-line"
            style={{ width: '90%' }}
          />
          <div
            className="post-card-message-hidden-line"
            style={{ width: '100%' }}
          />
          <div
            className="post-card-message-hidden-line"
            style={{ width: '95%' }}
          />
          <div
            className="post-card-message-hidden-line"
            style={{ width: '50%' }}
          />
        </div>
        <UnlockButton
          className="post-card-message-unlock"
          onClick={onNoAccess}
        />
      </div>
    )
  }

  if (message == null || message === '') {
    return null
  }

  const isLongText = (message) => {
    return message.length > MESSAGE_TRIM_LENGTH
  }

  const trimText = (message) => {
    if (showMore || !isLongText(message)) {
      return message
    }

    message = message.substring(0, MESSAGE_TRIM_LENGTH)
    return (
      message.substring(0, Math.min(message.length, message.lastIndexOf(' '))) +
      '…'
    )
  }

  const showMoreClick = () => {
    setShowMore(true)
    setScrollPosition(window.pageYOffset)
  }

  const showLessClick = () => {
    setShowMore(false)
    window.scrollTo(0, scrollPosition)
  }

  return (
    <div className="PostCardMessage">
      <Linkify>{trimText(message)}</Linkify>
      {isLongText(message) && !showMore && (
        <span
          className="post-card-message-show-hide-link more"
          onClick={showMoreClick}
        >
          Show more
        </span>
      )}
      {isLongText(message) && showMore && (
        <div
          className="post-card-message-show-hide-link"
          onClick={showLessClick}
        >
          Show less
        </div>
      )}
    </div>
  )
}

export default PostCardMessage
