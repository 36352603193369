import React from 'react'

import { Link } from 'react-router-dom'
import './HeaderControlsBurgerMenuModal.scss'
import { LogoSvg } from 'assets/svg'
import CloseSvg from 'assets/svg/CloseSvg'
import MenuModal from 'components/MenuModal'
import HeaderControlsAccordionMenu from 'components/HeaderControls/HeaderControlsAccordionMenu'
import { URL_FRONT_PAGE } from 'urls'
import { signOutStore } from 'store/utils/auth'

const HeaderControlsBurgerMenuModal = ({
  active,
  hasAuth,
  isCreator,
  onClose,
  handleSignIn,
  onCtaClick,
}) => {
  return (
    <MenuModal
      className="HeaderControlsBurgerMenuModal"
      active={active}
      onClose={onClose}
      direction={'right full-screen'}
    >
      <LogoSvg className="header-controls-burger-menu-modal-logo" />
      <div
        className="header-controls-burger-menu-modal-close"
        onClick={onClose}
      >
        <CloseSvg className="header-controls-burger-menu-modal-close-icon" />
      </div>
      <div className="header-controls-burger-menu-modal-menu">
        {hasAuth && (
          <>
            <Link
              className="button primary light profile-button"
              to={URL_FRONT_PAGE}
            >
              Profile
            </Link>
            <div
              className="button primary light signout-button"
              onClick={() => {
                onClose()
                signOutStore()
              }}
            >
              Sign out
            </div>
          </>
        )}
        {!hasAuth && (
          <div
            className="button primary light login-button"
            onClick={handleSignIn}
          >
            Log in
          </div>
        )}
        {!isCreator && (
          <div className="button primary light cta-button" onClick={onCtaClick}>
            Apply for partnership
          </div>
        )}
        <HeaderControlsAccordionMenu onClose={onClose} />
      </div>
    </MenuModal>
  )
}

export default HeaderControlsBurgerMenuModal
