import React, { useEffect } from 'react'
import './PollForm.scss'
import { Field, FieldArray, useFormikContext } from 'formik'
import { BoonFormikTextField, BoonTextField } from 'components/BoonInputs'
import CloseSvg from 'assets/svg/CloseSvg'
import { AddSvg } from 'assets/svg'
import { POLL_THEMES } from 'store/constants/post'
import DatePicker from 'react-datepicker'
import DatePickerInput from 'components/EventEditForm/DatePickerInput'
import { usePrevious } from 'react-use'

const PollForm = ({ removeAttachment }) => {
  const {
    values,
    setFieldValue,
    setFieldTouched,
    touched,
    errors,
  } = useFormikContext()
  const pollTheme = values.pollTheme || POLL_THEMES.DEFAULT
  const forcePollCanAddMoreOptions =
    pollTheme === POLL_THEMES.ASK_ME || pollTheme === POLL_THEMES.TELL_ME
  const allOptionsOptional =
    pollTheme === POLL_THEMES.TELL_ME || pollTheme === POLL_THEMES.ASK_ME
  const previousAllOptionsOptional = usePrevious(allOptionsOptional)

  useEffect(() => {
    if (forcePollCanAddMoreOptions) {
      setFieldValue('pollCanAddMoreOptions', true)
    }
  }, [forcePollCanAddMoreOptions, setFieldValue])

  useEffect(() => {
    if (allOptionsOptional && !previousAllOptionsOptional) {
      setFieldValue(
        'pollOptions',
        values.pollOptions.filter((p) => p !== '')
      )
    } else if (
      !allOptionsOptional &&
      previousAllOptionsOptional &&
      values.pollOptions.length === 0
    ) {
      setFieldValue('pollOptions', ['', ''])
    }
  }, [allOptionsOptional, previousAllOptionsOptional, setFieldValue, values])

  const renderOptions = (arrayHelpers) => {
    return values.pollOptions.map((_, index) => {
      return (
        <div className="poll-form-option" key={index}>
          <BoonFormikTextField
            type="text"
            dark
            name={`pollOptions.${index}`}
            placeholder={`Option ${index + 1}`}
          />

          {(index > 1 || allOptionsOptional) && (
            <CloseSvg
              className="poll-form-option-remove"
              onClick={() => arrayHelpers.remove(index)}
            />
          )}
        </div>
      )
    })
  }

  return (
    <div className="PollForm">
      <div className="poll-form-wrapper">
        <div className="poll-form-inner">
          <div className="poll-form-header">
            <CloseSvg
              className="poll-form-header-remove"
              onClick={removeAttachment}
            />
          </div>

          <div className="poll-form-section-header">Question</div>
          <BoonTextField
            field={{
              value: values.pollMessage,
              onChange: (e) => setFieldValue('pollMessage', e.target.value),
            }}
            name="message"
            placeholder="Question"
          />

          <div className="poll-form-section-header">Options</div>
          <FieldArray render={renderOptions} name="pollOptions" />
          <div
            className="poll-form-options-add"
            onClick={() =>
              setFieldValue('pollOptions', [...values.pollOptions, ''])
            }
          >
            <AddSvg /> Add option
          </div>

          {!forcePollCanAddMoreOptions && (
            <div>
              <Field
                id="pollCanAddMoreOptions"
                name="pollCanAddMoreOptions"
                type="checkbox"
                className="boon-dark-checkbox"
              />
              <label htmlFor="pollCanAddMoreOptions">
                Users can add options
              </label>
            </div>
          )}

          <div style={{ display: 'none' }}>
            <Field
              id="pollMultipleVotesPerUser"
              name="pollMultipleVotesPerUser"
              type="checkbox"
              className="boon-dark-checkbox"
            />
            <label htmlFor="pollMultipleVotesPerUser">
              Users can vote for multiple options
            </label>
          </div>

          <div className="poll-form-end-date">
            <DatePicker
              showTimeSelect
              timeIntervals={15}
              timeFormat="HH:mm"
              dateFormat="yyyy/MM/dd HH:mm"
              className="poll-form-calendar"
              customInput={
                <DatePickerInput
                  field={{
                    name: 'pollEndTime',
                  }}
                  meta={{
                    touched: touched.pollEndTime,
                    error: errors.pollEndTime,
                  }}
                  label="End Date"
                />
              }
              popperPlacement="top-start"
              minDate={new Date()}
              onChange={(date) => {
                setFieldTouched('pollEndTime')
                setFieldValue('pollEndTime', date)
              }}
              selected={values.pollEndTime}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PollForm
