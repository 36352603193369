import React from 'react'
import './PostAttachmentViewPoll.scss'
import Poll from 'components/Poll/Poll'

const PostAttachmentViewPoll = ({
  poll,
  access,
  onNoAccess,
  onPostAttachmentUpdate,
}) => {
  return (
    <div className="PostAttachmentViewPoll">
      <div className="post-attachment-view-poll-wrapper">
        <Poll
          poll={poll}
          access={access}
          onNoAccess={onNoAccess}
          interactive
          onPostAttachmentUpdate={onPostAttachmentUpdate}
        />
      </div>
    </div>
  )
}

export default PostAttachmentViewPoll
