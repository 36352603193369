import React from 'react'
import './EventInsightsPage.scss'
import { useRouteMatch } from 'react-router-dom'
import EventInsightsOverviewPage from 'routes/InsightsPage/EventInsightsOverviewPage'
import {
  URL_EVENT_INSIGHTS_OVERVIEW_ROUTE,
  URL_EVENT_INSIGHTS_SALES_ROUTE,
  URL_EVENT_INSIGHTS_VIEWS_ROUTE,
} from 'urls'
import EventInsightsSalesPage from 'routes/InsightsPage/EventInsightsSalesPage'
import EventInsightsViewsPage from 'routes/InsightsPage/EventInsightsViewsPage'
import EventInsightsHeader from 'routes/InsightsPage/EventInsightsHeader'
import { useGetEventApi } from 'api'

const EventInsightsPage = ({ currency, currencySymbol }) => {
  const {
    path,
    params: { userSlug, eventSlug },
  } = useRouteMatch()
  const { data: event, refresh: refreshEvent } = useGetEventApi({
    userSlug,
    eventSlug,
  })

  const renderPage = () => {
    if (event == null) {
      return null
    }

    const eventId = event.get('id')

    switch (path) {
      case URL_EVENT_INSIGHTS_SALES_ROUTE:
        return (
          <EventInsightsSalesPage
            eventId={eventId}
            currency={currency}
            currencySymbol={currencySymbol}
          />
        )
      case URL_EVENT_INSIGHTS_VIEWS_ROUTE:
        return (
          <EventInsightsViewsPage
            eventId={eventId}
            currency={currency}
            currencySymbol={currencySymbol}
          />
        )
      case URL_EVENT_INSIGHTS_OVERVIEW_ROUTE:
      default:
        return (
          <EventInsightsOverviewPage
            eventId={eventId}
            currency={currency}
            currencySymbol={currencySymbol}
          />
        )
    }
  }

  return (
    <div className="EventInsightsPage">
      <EventInsightsHeader
        event={event}
        userSlug={userSlug}
        eventSlug={eventSlug}
        refreshEvent={refreshEvent}
      />
      {renderPage()}
    </div>
  )
}

export default EventInsightsPage
