import React, { useRef, useState } from 'react'
import './EventVisibilityModal.scss'
import AnimatedModal from 'components/AnimatedModal'
import CloseSvg from 'assets/svg/CloseSvg'
import {
  asyncModal,
  getEventUrl,
  getMuxThumbnail,
  isEventPublished,
  isEventScheduled,
} from 'utils'
import {
  AlarmOnSvg,
  ArrowCornerSvg,
  CheckRoundSvg,
  ClipboardSvg,
} from 'assets/svg'
import { BackgroundImage } from 'components/Image'
import { BoonTextField } from 'components/BoonInputs'
import { DateTime } from 'luxon'
import LiveGuideModal from 'modals/LiveGuideModal'

const EventVisibilityModal = ({ active, onClose, event }) => {
  const linkRef = useRef(null)
  const url = `https://boon.tv${getEventUrl(event)}`
  const noun = event.get('live') ? 'Event' : 'Video'
  const eventCover =
    event.get('coverImageUrl') ||
    getMuxThumbnail(event.get('trailerVideoUrl')) ||
    getMuxThumbnail(event.get('coverVideoUrl'))
  const notifyFollowers = event.get('notifyFollowers')
  const [copied, setCopied] = useState(false)

  const handleCopyLink = () => {
    linkRef.current.select()
    linkRef.current.setSelectionRange(0, 99999) /*For mobile devices*/

    document.execCommand('copy')
    window.getSelection().removeAllRanges()
    setCopied(true)

    setTimeout(() => setCopied(false), 1500)
  }

  const handleLiveGuide = () => {
    asyncModal(({ active, onSubmit }) => (
      <LiveGuideModal
        active={active}
        onClose={onSubmit}
        eventId={event.get('id')}
      />
    ))
  }

  const getTitle = () => {
    if (isEventScheduled(event)) {
      return `${noun} is scheduled`
    } else if (isEventPublished(event)) {
      return `${noun} is published`
    }
  }

  const Icon = isEventScheduled(event) ? AlarmOnSvg : CheckRoundSvg

  console.log(event.toJS())

  return (
    <AnimatedModal
      active={active}
      onClose={onClose}
      overlay
      className="EventVisibilityModal"
    >
      <div className="event-visibility-modal-top">
        <div className="event-visibility-modal-header">
          <div className="event-visibility-modal-header-title">
            {getTitle()}
          </div>
          <CloseSvg
            className="event-visibility-modal-header-close"
            onClick={onClose}
          />
        </div>
        <div className="event-visibility-modal-result">
          <Icon className="event-visibility-modal-result-icon" />
          <div className="event-visibility-modal-result-title">
            {isEventScheduled(event)
              ? `${noun} will be published on your page on ${DateTime.fromISO(
                  event.get('publishAt')
                ).toFormat('MMM d, H:mm')}.`
              : `Your ${noun.toLowerCase()} page is now available.`}
          </div>
        </div>
        {notifyFollowers && (
          <div className="event-visibility-modal-result">
            <Icon className="event-visibility-modal-result-icon" />
            <div className="event-visibility-modal-result-title">
              We will notify your members by email.
            </div>
          </div>
        )}
      </div>
      <div className="event-visibility-modal-bottom">
        <div className="event-visibility-modal-bottom-title">Share link</div>
        <div className="event-visibility-modal-card">
          <div className="event-visibility-modal-card-left">
            <BackgroundImage
              className="event-visibility-modal-card-cover"
              src={eventCover}
            />
          </div>
          <div className="event-visibility-modal-card-content">
            <div className="event-visibility-modal-card-cover-title">
              {event.get('title')}
            </div>
          </div>
        </div>
        <BoonTextField
          field={{ value: url, ref: linkRef, readOnly: true }}
          append={
            copied ? (
              <CheckRoundSvg />
            ) : (
              <ClipboardSvg onClick={handleCopyLink} />
            )
          }
        />

        {event.get('live') && (
          <div className="event-visibility-modal-tips">
            <div className="event-visibility-modal-bottom-title">
              Good to know
            </div>
            <div
              className="event-visibility-modal-tips-item"
              onClick={handleLiveGuide}
            >
              <ArrowCornerSvg /> How to go live
            </div>
          </div>
        )}
      </div>
    </AnimatedModal>
  )
}

export default EventVisibilityModal
