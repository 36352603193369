import React, { useRef } from 'react'
import './PostCardEventSpecific.scss'
import PostCardHeader from './PostCardHeader'
import PostCardEngagement from './PostCardEngagement'
import PostCardActions from './PostCardActions'
import PostComments from 'components/PostComments'
import PostCardEventSpecificContent from './PostCardEventSpecificContent'
import { POST_COMMENT_ACCESS } from 'store/constants/post'

export const PostCardEventSpecific = ({
  post,
  event,
  user,
  dispatchPosts,
  refreshEvent,
  onNoAccess,
}) => {
  const commentInputRef = useRef()
  const hasAccess =
    post && post.get('commentsAccess') !== POST_COMMENT_ACCESS.NONE

  return (
    <div className="PostCardEventSpecific">
      <PostCardHeader
        post={post}
        event={event}
        onNoAccess={onNoAccess}
        showOptions
      />
      <PostCardEventSpecificContent
        event={event}
        user={user}
        refreshEvent={refreshEvent}
      />
      {post && <PostCardEngagement post={post} specific={true} />}
      {post && (
        <PostCardActions
          post={post}
          dispatchPosts={dispatchPosts}
          loadMoreComments={() => null}
          commentInputRef={commentInputRef}
          onNoAccess={onNoAccess}
          specific={true}
        />
      )}
      {post && (
        <PostComments
          post={post}
          dispatchPosts={dispatchPosts}
          loadMoreComments={() => null}
          commentInputRef={commentInputRef}
          onNoAccess={onNoAccess}
          showCompose={hasAccess}
        />
      )}
    </div>
  )
}
