import React, { useEffect } from 'react'
import './LightModal.scss'
import Modal from 'components/Modal'
import CloseSvg from 'assets/svg/CloseSvg'

const LightModal = ({ onClose, children }) => {
  useEffect(() => {
    const listener = (e) => {
      if (e.keyCode === 27) {
        e.stopPropagation()
        onClose()
      }
    }

    window.addEventListener('keydown', listener, true)

    return () => window.removeEventListener('keydown', listener, true)
  }, [onClose])

  return (
    <Modal onClose={onClose} overlay className="LightModal">
      <div className="light-modal-wrapper">
        <div className="light-modal-header">
          <CloseSvg className="light-modal-header-close" onClick={onClose} />
        </div>
        <div className="light-modal-content">{children}</div>
      </div>
    </Modal>
  )
}

export default LightModal
