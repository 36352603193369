import React from 'react'
import './PostComposeUpdate.scss'
import { updatePostApi } from 'api'
import clsx from 'clsx'
import { getApiError } from 'utils'
import { Formik } from 'formik'
import PostComposeForm from './PostComposeForm'
import { POST_ATTACHMENT_TYPES } from 'store/constants/post'

const PostComposeUpdate = ({ post, onPostUpdated, onClose, hostId }) => {
  const setModalActive = (active) => {
    if (!active) {
      if (
        window.confirm(
          'You have unsaved changes, are you sure you want to leave?'
        )
      ) {
        onClose()
      }
    }
  }

  const onSubmit = async (values, { setSubmitting, setErrors, resetForm }) => {
    let newPost
    try {
      newPost = await updatePostApi({
        postId: post.get('id'),
        data: {
          ...values,
        },
      })
    } catch (e) {
      setErrors(getApiError(e))
      setSubmitting(false)
      return
    }

    onPostUpdated({ postId: newPost.get('id'), post: newPost })
    setSubmitting(false)
    resetForm()
    onClose()
  }

  const initialValues = {
    message: post.get('message'),
    attachmentType: post.get('attachmentType'),
    attachmentId: post.get('attachmentId'),
    attachmentPhotoUrl:
      post.get('attachmentType') === POST_ATTACHMENT_TYPES.PHOTO &&
      post.getIn(['attachment', 'fullPath']),
    attachmentDocumentFilename:
      post.get('attachmentType') === POST_ATTACHMENT_TYPES.DOCUMENT &&
      post.getIn(['attachment', 'originalFilename']),
  }

  return (
    <div className={clsx('PostComposeUpdate')}>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        <PostComposeForm
          isEdit
          modalActive
          setModalActive={setModalActive}
          hostId={hostId}
        />
      </Formik>
    </div>
  )
}

export default PostComposeUpdate
