import React, { useState } from 'react'
import { useBrowser, usePushHistoryWithSavedState } from 'utils'
import './CategoryCardContainer.scss'
import { ChevronDownSvg, ClearSvg } from 'assets/svg'
import NamedAnimatedModal from 'components/NamedAnimatedModal'
import CategoryCard from 'components/CategoryCard'
import FeedFilter from 'components/FeedFilter/FeedFilter'

import { NAVIGATION_TABNAMES } from 'routes/UserPage/components/NavigationTabConstants'
import clsx from 'clsx'
import { useGetUserCategoriesApi } from 'api'

const CategoryCardContainer = ({
  userSlug,
  filters,
  activeFilters,
  dispatchFilter,
  postCount,
  setPostCount,
  activeTab,
  isCreator,
}) => {
  const { data: categories } = useGetUserCategoriesApi({
    userSlug,
    productId: filters.product_id,
    attachmentType: filters.attachment_type,
  })
  const [modalOpen, setModalOpen] = useState(false)
  const browser = useBrowser()
  const pushHistory = usePushHistoryWithSavedState()
  const categoriesToShow = browser.lessThan.md
    ? 1
    : (activeTab === NAVIGATION_TABNAMES.VIDEOS && browser.lessThan.xl) ||
      activeTab === NAVIGATION_TABNAMES.ACTIVITY
    ? 2
    : 5
  const activeCategoryId = activeFilters.get('categoryId')
  const activeCategory =
    activeCategoryId &&
    categories?.find((category) => category.get('id') === activeCategoryId)
  const handleOnClick = (key) => {
    setPostCount(null)
    dispatchFilter({ group: 'categoryId', key })
    setModalOpen(false)
    pushHistory(null, { categoryId: key }, true)
  }

  const sortedCategories = categories?.sort((a, b) => {
    if (a.get('recentPostsCount') === b.get('recentPostsCount')) {
      return a.get('name').localeCompare(b.get('name'))
    }
    return (b.get('recentPostsCount') || 0) - (a.get('recentPostsCount') || 0)
  })

  const fetchPostCount = (category) => {
    return category.get('postsCount')
  }

  const renderModal = () => {
    return (
      <NamedAnimatedModal
        active={modalOpen}
        className="CategoriesModal"
        overlay
        onClose={() => setModalOpen(false)}
        title={'Categories'}
      >
        <div className="categories-modal-container">
          {sortedCategories.map((category, idx) => (
            <CategoryCard
              key={idx}
              icon={category.get('emoji')}
              title={category.get('name')}
              recentAmount={category.get('recentPostsCount')}
              postAmount={fetchPostCount(category)}
              onClick={() => handleOnClick(category.get('id'))}
            />
          ))}
        </div>

        <button
          className="button creator-primary filter-done-button"
          onClick={() => setModalOpen(false)}
        >
          Done
        </button>
      </NamedAnimatedModal>
    )
  }

  const renderActiveHeader = () => {
    if (activeTab === NAVIGATION_TABNAMES.ACTIVITY) {
      return null
    }

    let activeTabName = activeTab.toLowerCase()

    if (!activeCategoryId) {
      if (postCount === null) {
        return
      }

      if (postCount === 1) {
        activeTabName = activeTabName.replace(/s$/, '')
      }

      return (
        <div className="category-card-container-active-title">
          {postCount} {activeTabName}
        </div>
      )
    }

    const categoryPostCount = fetchPostCount(activeCategory)
    if (!categoryPostCount || categoryPostCount === 0) {
      return
    }

    if (categoryPostCount === 1) {
      activeTabName = activeTabName.replace(/s$/, '')
    }

    return (
      <div className="category-card-container-active-title">
        {categoryPostCount} {activeTabName}
      </div>
    )
  }

  const renderButton = () => {
    if (activeCategoryId) {
      return (
        <button
          className="button secondary category-card-container-button"
          onClick={() => handleOnClick(null)}
        >
          <span className="emoji">{activeCategory.get('emoji')}</span>
          <span className="name">{activeCategory.get('name')}</span>
          <ClearSvg />
        </button>
      )
    }

    return (
      <button className="button secondary" onClick={() => setModalOpen(true)}>
        Categories
        <ChevronDownSvg />
      </button>
    )
  }

  const renderRecent = () => {
    if (activeCategoryId) {
      return null
    }

    if (
      ![NAVIGATION_TABNAMES.ACTIVITY, NAVIGATION_TABNAMES.VIDEOS].includes(
        activeTab
      )
    ) {
      return null
    }

    return (
      <div className="category-card-container-categories">
        {sortedCategories.slice(0, categoriesToShow).map((category, idx) => (
          <CategoryCard
            key={idx}
            icon={category.get('emoji')}
            title={category.get('name')}
            recentAmount={category.get('recentPostsCount')}
            postAmount={fetchPostCount(category)}
            onClick={() => handleOnClick(category.get('id'))}
          />
        ))}
        {sortedCategories.size > categoriesToShow && (
          <CategoryCard
            title={`+ ${sortedCategories.size - categoriesToShow} more`}
            onClick={() => setModalOpen(true)}
          />
        )}
      </div>
    )
  }

  const renderContainer = () => {
    if (categories == null) {
      return null
    }

    return (
      <>
        <div className="category-card-container-button-group">
          {categories.size > 0 ? renderButton() : <div></div>}
          {activeTab === NAVIGATION_TABNAMES.VIDEOS && (
            <FeedFilter
              categories={categories}
              activeFilters={activeFilters}
              dispatchFilter={dispatchFilter}
              isCreator={isCreator}
              activeTab={activeTab}
              postCount={postCount}
            />
          )}
        </div>
        {categories.size > 0 && renderRecent()}
        {renderActiveHeader()}
      </>
    )
  }

  return (
    <div
      className={clsx(
        'CategoryCardContainer',
        `category-card-container-${activeTab.toLowerCase()}`
      )}
    >
      {renderContainer()}
      {categories?.size > 0 && renderModal()}
    </div>
  )
}

export default CategoryCardContainer
